import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withContext } from '../Context/context';
import { withPageState } from '../Context/PageState';
import { lowWeaponStats } from '../../services/attributeService';
import { enchantmentEffectApplies } from '../../services/weaponService';

const WeaponButton = (props) => {

    const [disabled, setDisabled] = useState(false);
    const [target, setTarget] = useState(0);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (props.disabled){
            setDisabled(true);
        }
        else setDisabled(target === 0);
    },[props.disabled, target, props.providerData?.augmenting])

    useEffect(() => {
        calculateTarget();
    },[props.modifier, props.item, props.entity, props.entity.enchantments, calculateTarget])

    function rollDice(event) {
        setClicked(true);
        event.stopPropagation();
        props.diceRoller.setDice({d100: 1, d10: 1})
    }

    function calculateTarget() {
        var value = 0;
        var minimum = 5;
        if (props.item.skill) {
            value = props.item.skill.value + ((props.entity.categoryModifiers && props.entity.categoryModifiers[props.item.skill.skillCategory]) || 0);
            var statMultiplier = lowWeaponStats(props.providerData.character, props.item.weapon);
            if (statMultiplier < 1) {
                value += Math.ceil((value * statMultiplier) * -1);;
            }
    
            if (props.entity.enchantments) {
                var fieldName = props.type.toLowerCase() + "Multiplier";
                props.entity.enchantments.forEach(enchantment => {
                    enchantment.effects.forEach(effect => {
                        if (effect.name === fieldName) {
                            let valid = enchantmentEffectApplies(enchantment, effect, props.item.weapon);
                            if (valid) {
                                value = Math.floor(value * effect.value);
                                if (effect.value === 0) {
                                    minimum = 0;
                                }
                            }
                        }
                    }) 
                });
                fieldName = props.type.toLowerCase() + "Modifier";
                if (fieldName === "attack/defendModifier" || fieldName === "hitModifier") {fieldName = "attackModifier"} // hack for Spirit Combat
                props.entity.enchantments.forEach(enchantment => {
                    enchantment.effects.forEach(effect => {
                        if (effect.name === fieldName) {
                            let valid = enchantmentEffectApplies(enchantment, effect, props.item.weapon);
                            if (valid) {
                                value += effect.value;
                            }
                        }
                    })
                });
                var index = props.entity.enchantments.findIndex(enchantment => (enchantment.name === "Augmentation" && enchantment.target === props.item.skill.name))
                if (index < 0 && value > 0) {
                    value += props.providerData?.augmenting.modifier || props.augmenting?.modifier ||  props.item.augment || 0;
                }
            }
            if (props.item.skill.name !== "Spirit Combat") {
                if (props.providerData.character.maxEnc < props.providerData.currentEncumbrance) {
                    var encPenalty = ((props.providerData.character.maxEnc - props.providerData.currentEncumbrance) * 5);
                    value += encPenalty
                }
            }
            if (props.providerData.augmenting.type === "skills") {
                setDisabled(true);
            }
            if (props.providerData.character.despondent) {
                value -= 10;
            }
            var mod = props.modifier || 0;
            if (mod === "-") mod = 0;
            value += mod;
            if (value < minimum) {value = minimum}
        }
        setTarget(value);
    }

    useEffect(() => {
        if (clicked) {
            setClicked(false);
            var result = -1;
            if (props.diceRoller.diceResults.d100 === 0 && props.diceRoller.diceResults.d10 === 10) {
                result = 100;
            }
            else if (props.diceRoller.diceResults.d10 === 10) {
                result = props.diceRoller.diceResults.d100 + 0
            } 
            else {
                result = props.diceRoller.diceResults.d100 + props.diceRoller.diceResults.d10
            } 
            var type = props.type === "Attack" ? "hit" : props.type?.toLowerCase();
            var title = props.item.weapon.name + " " + props.type || "";

            props.processResult({
                target: target, 
                result: result, 
                item: props.item.weapon, 
                augment: props.augment, 
                type: type,
                title: title
            });
        }
    },[props.diceRoller && props.diceRoller.diceResults])

    return(
        <div className = {disabled ? globalStyles.disabledButton : props.className} style={{...props.style}} onClick={props.pageState.editMode || disabled ? null : rollDice}>
            {React.cloneElement(props.children, {...props, disabled: disabled, target:target})}
        </div>
    )
}
export default withPageState(withContext(WeaponButton));