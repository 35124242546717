export default function logError(firebase, error, info, authUser) {
    // var errorString = JSON.stringify(error.stack);
    if (info.indexOf("http://localhost:3000") < 0) {
        firebase
        .diagnostics()
        .add({
            error: error.message,
            stack: error.stack || "",
            componentStack: info.componentStack || "",
            errorDate: new Date(),
            user: authUser.uid,
        })
    }
}