
function getNames(items) {
    let names = [];
    if (items && items.length > 0) {
        items.forEach(item => {
            names.push(item.name + (item.variant ? " (" + item.variant + ")" : ""));
        })
    }
    return names;
}

export default getNames;