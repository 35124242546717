import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import PowerRuneCard from './powerRuneCard';

const PowerRunes = (props) => {
    const [selectedCount, setSelectedCount] = useState(0)
    const [powerRunes, setPowerRunes] = useState([]);
    const [hover, setHover] = useState();

    useEffect(() => {
        var count = 0;
        var runes = [...props.powerRunes];
        if (props.builder.runes.powerRunes.length > 0) {
            runes = [...props.builder.runes.powerRunes]
        }
        runes.forEach(rune => {
            if (rune.first.startingValue === 75 || rune.second.startingValue === 75) {
                count++;
            }
        })
        setPowerRunes(runes);
        setSelectedCount(count);
    }, [props.powerRunes])

    function advance() {
        var updatedBuilder = {...props.builder};
        var updatedRunes = [...powerRunes];
        updatedBuilder.runes.powerRunes = updatedRunes;
        props.update(updatedBuilder, true);
    }

    function selectRune(index, first) {
        var updatedPowerRunes = [...powerRunes];
        if (first) {
            if (updatedPowerRunes[index].first.startingValue === 75) {
                setSelectedCount(selectedCount -1); 
                updatedPowerRunes[index].first.value = 50;
                updatedPowerRunes[index].second.value = 50;
                updatedPowerRunes[index].first.startingValue = 50;
                updatedPowerRunes[index].second.startingValue = 50;
            }
            else if (updatedPowerRunes[index].first.startingValue === 25) {
                updatedPowerRunes[index].first.value = 75;
                updatedPowerRunes[index].first.startingValue = 75;
                updatedPowerRunes[index].second.value = 25;
                updatedPowerRunes[index].second.startingValue = 25;
            }
            else {
                if (selectedCount < 2) {
                    setSelectedCount(selectedCount +1); 
                    updatedPowerRunes[index].first.value = 75;
                    updatedPowerRunes[index].first.startingValue = 75;
                    updatedPowerRunes[index].second.value = 25;
                    updatedPowerRunes[index].second.startingValue = 25;
                }
            }
        }
        else {
            if (updatedPowerRunes[index].second.startingValue === 75) {
                setSelectedCount(selectedCount -1); 
                updatedPowerRunes[index].first.value = 50;
                updatedPowerRunes[index].second.value = 50;
                updatedPowerRunes[index].first.startingValue = 50;
                updatedPowerRunes[index].second.startingValue = 50;
            }
            else if (updatedPowerRunes[index].second.startingValue === 25) {
                updatedPowerRunes[index].first.value = 25;
                updatedPowerRunes[index].first.startingValue = 25;
                updatedPowerRunes[index].second.value = 75;
                updatedPowerRunes[index].second.startingValue = 75;
            }
            else {
                if (selectedCount < 2) {
                    setSelectedCount(selectedCount +1); 
                    updatedPowerRunes[index].first.value = 25;
                    updatedPowerRunes[index].first.startingValue = 25;
                    updatedPowerRunes[index].second.value = 75;
                    updatedPowerRunes[index].second.startingValue = 75;
                }
            }
        }
        setPowerRunes(updatedPowerRunes);
    }

    return (
        <div>
        <div style={{display: "flex"}}>

            </div>
            <div style={{marginTop: "30px"}}>
                <div id="runeValues" style={{display:"flex", width: "100%"}}>
                    {powerRunes.map((pair, index) => {
                        var marginRight = "20px";
                        if (index === 3) marginRight = "180px"
                        return (
                            <div key={index} style={{marginRight: marginRight, width: "20%", maxWidth: "140px"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid #cdcdcd", backgroundColor: pair.first.startingValue === 75 ? "#fff": "#454645", borderRadius:"15px", opacity: pair.first.startingValue === 25 ? 0.3 : 1}} onMouseEnter={() => setHover({...pair.first, sortOrder: index})} onMouseLeave={() => {setHover(null)}} onClick={() => selectRune(index, true)}>
                                    <img style={{width:"100%"}} src={pair.first.startingValue === 75 ? "https://storage.googleapis.com/runequest/runes/" + pair.first.image:"https://storage.googleapis.com/runequest/runes/white-" + pair.first.image}  />
                                </div>
                                <div style={{fontSize: "15px", display:"flex", justifyContent: "center", marginTop: "10px"}}>{pair.first.name} {pair.first.value}%</div>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", fontSize: "15px"}}>
                                    <img style={{width: "15%", display:"flex", justifyContent: "center"}} src={"https://storage.googleapis.com/runequest/runes/rune-connector.png"} />
                                </div>
                                <div style={{fontSize: "15px", display:"flex", justifyContent: "center", marginBottom: "10px"}}>{pair.second.name} {pair.second.value}%</div>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", border: "2px solid #cdcdcd", backgroundColor: pair.second.startingValue === 75 ? "#fff": "#454645", borderRadius:"15px", opacity: pair.second.startingValue === 25 ? 0.3 : 1}} onMouseEnter={() => setHover({...pair.second, sortOrder: index})} onMouseLeave={() => {setHover(null)}} onClick={() => selectRune(index, false)}>
                                    <img style={{width:"100%"}} src={pair.second.startingValue === 75 ? "https://storage.googleapis.com/runequest/runes/" + pair.second.image:"https://storage.googleapis.com/runequest/runes/white-" + pair.second.image}  />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {hover ? 
                <div style={{position: "fixed", top: "310px", left: "40%"}}>
                <PowerRuneCard item={hover} ></PowerRuneCard>
                </div>
            : 
                null
            }
                    <div style={{marginLeft: "20px", display: "flex", flexDirection: "row-reverse", marginTop: "40px"}}>
                        {selectedCount >= 2 ? 
                            <div>
                                    <Button onClick={advance} size="large" variant="contained" style={{border: "0", marginLeft:"10px", width: "180px", height: "60px", borderRadius: "10px"}} color="primary">Continue to Bonus Points</Button>
                            </div>
                        : null
                        }
                    </div>
        </div>
    );
}

export default PowerRunes;