import React from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@mui/material';

const ConfirmationDialog = (props) => {

    return (
        <Dialog open={props.open} maxWidth="xs" >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <div>{"Are you sure you want to delete this " + props.type + " from your character?"}</div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.cancel}>
                Cancel
                </Button>
                <Button onClick={props.confirm}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;