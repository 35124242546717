import React, {useState, useEffect} from 'react';
import { withPageState } from '../Context/PageState';
import { withContext } from '../Context/context';
import globalStyles from '../Styles/styles.module.css';
import deleteIcon from '../../icons/delete-icon-blue.svg';
import { FormControl, Checkbox } from '@mui/material';
import { displayDiceRolled } from '../../services/dieRollService';
import DeleteDialog from '../Dialogs/deleteDialog';

const InventoryWeapon = (props) => {

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(props.item.equipped);
    const [skill, setSkill] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [buttonTitle, setButtonTitle] = useState("Add");
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        props.weaponSkills.forEach(skill => {
            if (skill.parentId === props.item.parentId) {
                setSkill(skill.value + props.categoryModifier);
            }
        })
    },[props.weaponSkills]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.select(props.item, event.target.checked)
    };

    const handleClick = () => {
        setDisabled(true)
        setButtonTitle("Added");
        props.add(props.item);
    }

    function deleteWeapon() {
        setConfirm(true);
    }

    function confirmDelete() {
        props.delete({...props.item});
        setConfirm(false);
    }

    return (
        <>
            <div className={globalStyles.row} style={{display:"flex", height: "24px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px", justifyContent: "space-between"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <div style={{width:"150px", alignItems: "center", display: "flex"}}>
                            <div>{props.item.title || props.item.name}</div>
                        </div>
                        <div style={{width:"60px", display:"flex", alignItems: "center", justifyContent:"center"}}>{skill}%</div>
                        <div style={{width:"60px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.ENC || props.item.enc}</div>
                        {props.pageState.editMode ? 
                            <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                                <img style={{width: "20px", cursor: "pointer", marginLeft: "10px"}} onClick={deleteWeapon} src={deleteIcon} />
                            </div>
                        : 
                            <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                                <FormControl variant="outlined" 	>
                                    <Checkbox checked={checked} color='primary' onChange={handleChange} />
                                </FormControl>
                            </div>
                        }
                    </div>
                    <button disabled={disabled} className={disabled ? globalStyles.disabledButton : globalStyles.activeButton} style={{borderRadius: "3px", padding: "2px 5px", width: "60px"}} onClick={handleClick}>{buttonTitle}</button>
                </div>
            </div>
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="weapon" />
        </>
    )
}

export default withContext(withPageState(InventoryWeapon));