import React from 'react';
import globalStyles from '../Styles/styles.module.css';
import FireRune from '../../images/fire-rune-white-40x40.png'
import WaterRune from '../../images/water-rune-white-40x40.png'
import EarthRune from '../../images/earth-rune-white-40x40.png'
import AirRune from '../../images/air-rune-white-40x40.png'
import DarknessRune from '../../images/darkness-rune-white-40x40.png'
import MoonRune from '../../images/moon-rune-white-40x40.png'
import ManRune from '../../images/man-rune-white-40x40.png'
import FertilityRune from '../../images/fertility-rune-white-40x40.png'
import DeathRune from '../../images/death-rune-white-40x40.png'
import HarmonyRune from '../../images/harmony-rune-white-40x40.png'
import DisorderRune from '../../images/disorder-rune-white-40x40.png'
import TruthRune from '../../images/truth-rune-white-40x40.png'
import IllusionRune from '../../images/illusion-rune-white-40x40.png'
import StasisRune from '../../images/stasis-rune-white-40x40.png'
import MovementRune from '../../images/movement-rune-white-40x40.png'
import BeastRune from '../../images/beast-rune-white-40x40.png'
import SpiritRune from '../../images/spirit-rune.png'
import DragonewtRune from '../../images/dragonewt-rune.png'
import ChaosRune from '../../images/chaos-rune-white-40x40.png'

const RuneDiceButton = (props) => {
    const style = {
        borderRadius: 3,
        boxSizing:"border-box",
        border: 0,
        color: 'white',
        height: "80px",
    };
    var runeImage; 

    switch (props.rune) {
        case "Fire/Sky":
        case "FireSky":
            runeImage=FireRune;
            break;
        case "Water":
            runeImage=WaterRune;
            break;
        case "Earth":
            runeImage = EarthRune;
            break;
        case "Air":
            runeImage = AirRune;
            break;
        case "Darkness":
            runeImage = DarknessRune;
            break;
        case "Moon":
            runeImage = MoonRune;
            break;
        case "Man":
            runeImage = ManRune;
            break;
        case "Fertility":
            runeImage = FertilityRune;
            break;
        case "Death":
            runeImage = DeathRune;
            break;
        case "Harmony":
            runeImage = HarmonyRune;
            break;
        case "Disorder":
            runeImage = DisorderRune;
            break;
        case "Truth":
            runeImage = TruthRune;
            break;
        case "Illusion":
            runeImage = IllusionRune;
            break;
        case "Stasis":
            runeImage = StasisRune;
            break;
        case "Movement":
            runeImage = MovementRune;
            break;
        case "Beast":
            runeImage = BeastRune;
            break;
        case "Spirit":
            runeImage = SpiritRune;
            break;
        case "Dragonewt":
            runeImage = DragonewtRune;
            break;
        case "Chaos":
            runeImage = ChaosRune;
            break;
        default:
            runeImage = "";
            break;
      }
    
    const displayRuneDetails = () => {
        return (
            <>
            <div style={{height: "32px", display:"flex", alignItems:"center", justifyContent: "center"}} onClick={props.rollDice} >
                <img src={runeImage} alt={props.rune + " rune"} style={{marginRight: "10px", width: "20px"}} />
                <div style={{marginRight: "10px"}}>{props.rune}</div>
                {props.target + "%"}
            </div>
            </>
        )
    }

    if (props.disabled) {
        return (
            <div >
                {displayRuneDetails()}
            </div>
        )
    }
    else {
        return (
            <div onClick={props.rollDice} className={globalStyles.blueDiceButton} style={{display:"flex", justifyContent:"space-between", alignitems: "center"}}>
                {displayRuneDetails()}
            </div>
        )

    }
}

export default RuneDiceButton