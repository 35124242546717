import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import BuilderPage from '../Builder';
import SignUpPage from '../SignUp';
import CampaignList from '../Campaigns';
import Campaign from '../Campaigns/campaignPage';
import EncounterList from '../Encounters';
import EncounterPage from '../Encounters/encounterPage';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import { PageStateContext } from '../Context/PageState';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withAuthentication } from '../Session';
import Characters from '../Characters';
import Character from '../Characters/character/characterDisplay';
import CharacterSheet from '../Characters/characterSheet/characterDisplay';
import { ErrorBoundary } from 'react-error-boundary';
import fallbackComponent from '../../utils/fallbackComponent';
import logError from '../../utils/logError';
import { withFirebase } from '../Firebase';
import styles from './app.module.css';
import { diceHandler } from '../Dice/dice';
import { someTealFunction } from '../Dice/teal';
import { dice_initialize } from '../../services/diceService';
import rollSound from '../../sounds/rollD100.mp3';
import InvitePage from '../Invite';
import CustomContent from '../Custom';

const theme = createTheme({
	typography: {
	  body1: {
		fontFamily: "Trebuchet MS, Roboto, Helvetica, Arial, sans-serif",
		fontWeight: 400,
		fontSize: "15px"
	  }
	},
	palette: {
	  primary: { main: '#2F588A' }, // 
	  secondary: { main: '#8a612f' }, // 
	  warning: {main: '#a05889'}, // 
	  error: { main: '#851113' }
	},
	overrides: {
	},
});
  
const App = (props) => {

	const [editMode, setEditMode] = useState(false);
    const [showClass, setShowClass] = useState(globalStyles.hideCanvas);
    const [hidden, setHidden] = useState(false);
    const [diceResults, setDiceResults] = useState({});
    const [rolling, setRolling] = useState(false);
    const [diceData, setDiceData] = useState(
        {
            "d4": 0,
            "d6": 0,
            "d8": 0,
            "d10": 0,
            "d12": 0,
            "d20": 0,
            "d100": 0,
        }
    );

    useEffect(() => { 
        if (hidden) {
            hideCanvas();
        }
    },[hidden])

    useEffect(() => {
        if (diceData.d4 === 0 && diceData.d6 === 0 && diceData.d8 === 0 && diceData.d10 === 0 && diceData.d12 === 0 && diceData.d100 === 0 ) {return}
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("mouseup", true, true);
        clickRef.current?.dispatchEvent(evt)
        showCanvas();
        setRolling(true);
        let beat = new Audio(rollSound);
        beat.play();
    },[diceData])


    const initializeMain = () => {
        try {
            someTealFunction();
            diceHandler();
            dice_initialize(document.body, setDiceResults);
        }
        catch(error) {}
    };

    let diceDataArr = Object.entries(diceData);
    let thrownValues = "";
    diceDataArr.map((item, index) => {
        if (item[1] > 0) {
            if (thrownValues === "")
                thrownValues += (item[1] + item[0])
            else {
                let tempStr = ' + ' + item[1] + item[0]
                thrownValues += tempStr
            }
        }
    })

    useEffect(() => {
        initializeMain();
    }, []);

    useEffect(() => {
        setRolling(false);
        if (Object.keys(diceResults).length > 0) {
            if (hidden) {
                setHidden(false);
            }
            else {
                setShowClass(showClass===globalStyles.fadeOut ? globalStyles.fadeOut2 : globalStyles.fadeOut)
            }
            setDiceResults({})
            setDiceData(        {
                "d4": 0,
                "d6": 0,
                "d8": 0,
                "d10": 0,
                "d12": 0,
                "d20": 0,
                "d100": 0,
            }
        )
        }
    },[diceResults])

    const clickRef = useRef(null);

    function hideCanvas() {
        if (!rolling) {
            setShowClass(globalStyles.hideCanvas);
        }
    }

    function showCanvas() {
        setShowClass(globalStyles.showCanvas);
    }

	return(
		<AuthUserContext.Consumer>
			{authUser =>
				<ErrorBoundary FallbackComponent={fallbackComponent} onError={(error, info) => logError(props.firebase, error, info, authUser)}>
				<PageStateContext.Provider value={{editMode, setEditMode}}>
					<ThemeProvider theme={theme}>
						<Router>
							<div style={{position: 'relative', display: 'flex', justifyContent:"center"}}>
								<Navigation authUser={authUser} />
								<Routes>
									{authUser ? 
										<>
											{/* <Route path={ROUTES.CUSTOM} element={<CustomContent authUser={authUser} />} /> */}
											<Route path={ROUTES.CHARACTERS} element={<Characters />} />
											<Route path={ROUTES.ENCOUNTERS} element={<EncounterList />} />
											<Route path={ROUTES.ENCOUNTER + "/:uid"} element={<EncounterPage />} />
											<Route path={ROUTES.BUILDER + "/:uid"} element={<BuilderPage authUser={authUser} diceRoller={{diceResults:diceResults, setDice:setDiceData, setHidden: setHidden}} />} />
											<Route path={ROUTES.SIGN_UP} element={<SignUpPage authUser={authUser} />} />
											<Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage authUser={authUser} />} />
											<Route path={ROUTES.HOME} element={<HomePage authUser={authUser} />} />
                                            <Route path={ROUTES.INVITE + "/:uid"} element={<InvitePage authUser={authUser} />} />
											<Route path={ROUTES.ACCOUNT} element={AccountPage} />
											<Route path={ROUTES.ADMIN} element={<AdminPage authUser={authUser} />} />
											<Route path={ROUTES.CHARACTERSHEET + "/:uid"} element={<CharacterSheet authUser={authUser} />} />
											{authUser.roles[ROLES.MEMBER] ?  
												<Route path={ROUTES.CHARACTER + "/:uid"} element={<Character authUser={authUser} diceRoller={{diceResults:diceResults, setDice:setDiceData, setHidden: setHidden}}  />} />
											: 
												<Route path={ROUTES.CHARACTER + "/:uid"} element={<CharacterSheet authUser={authUser} />} />
											}
											{authUser.roles[ROLES.MEMBER] ?  
												<Route path={ROUTES.CAMPAIGNS} element={<CampaignList authUser={authUser} foo="fpp" diceRoller={{diceResults:diceResults, setDice:setDiceData, setHidden: setHidden}} />} />
											: 
												null
											}
											<Route path={ROUTES.CAMPAIGN + "/:uid"} element={<Campaign authUser={authUser} diceRoller={{diceResults:diceResults, setDice:setDiceData, setHidden: setHidden}}  />} />
											<Route path="*" element={<HomePage authUser={authUser} />} replace={true} />
										</>
									: 
										<Route path="*" element={<LandingPage />} />
									}
								</Routes>
								<div className={`${showClass}`}>
									<div className={styles.diceContainer}>
										<div id="info_div" style={{ display: "none" }}>
											<div className={styles.center_field} style={{display: "none"}}>
												<span id="label"></span>
											</div>
										</div>
										<div id="selector_div" style={{ display: "none" }}>
											<div className={styles.center_field} style={{display: "none"}}>
												<input style={{display: "none"}} type="text" id="set" defaultValue={thrownValues} ></input><br />
												<button id="clear">clear</button>
												<button ref={clickRef} style={{ marginLeft: "0.6em" }} id="throw" >throw</button>
											</div>
										</div>
										<div onClick={hideCanvas} id="canvas"></div>
									</div>
								</div>
							</div>
						</Router>
					</ThemeProvider>
				</PageStateContext.Provider>
			</ErrorBoundary>
			}
		</AuthUserContext.Consumer>
	)
}
 
export default withFirebase(withAuthentication(App));