import React, { useState, useEffect } from 'react';
import compareValues from '../../../utils/compareValues';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ProfileImage from '../../Characters/controls/profileImage';

const genders = ["Male", "Female", "Neither", "Both"];
const ages = [16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49];
const hands = ["Right", "Left"];
const seasons = ["Sea Season", "Fire Season", "Earth Season", "Dark Season", "Storm Season"]
const weeks = ["Disorder Week", "Harmony Week", "Death Week", "Fertility Week", "Stasis Week", "Movement Week", "Illusion Week", "Truth Week"]
const days = ["Freezeday", "Waterday", "Clayday", "Windsday", "Fireday", "Wildday", "Godday"];
const maleImages = ["male2", "male3", "male4", "male5", "male7", "male8", "male9", "male10", "male11", "male12", "male13", "male14", "male15", "male16", "male17", "male18", "male19", "male20", "male21", "male22", "male23", "male24"];
const femaleImages = ["female3", "female4", "female5", "female6", "female7", "female8", "female9", "female10", "female11", "female12", "female13", "female14", "female16", "female17", "female18", "female19", "female20", "female22", "female23", "female24", "female25"];

const PersonalInfo = (props) => {
    const [images, setImages] = useState([]);
    const [hover, setHover] = useState(-1);
    const [tribeNames, setTribeNames] = useState([]);
    const [otherTribe, setOtherTribe] = useState("");
    const [info, setInfo] = useState(null)

    // useEffect(() => {
    //     if (props.builder.personalInfo ) {
    //         console.log('props.builder.personalInfo', props.builder.personalInfo)
    //         setInfo({...props.builder.personalInfo});
    //     }
    //     switch (props.builder.homeland.name) {
    //         case "Prax": 
    //             setTribeNames([props.builder.tribe.name]);
    //             break; 
    //         case "Grazelands" : 
    //             setTribeNames(["Pure Horse People"]);
    //             break; 
    //         default: 
    //             setTribeNames(props.builder.homeland.tribeNames);
    //             break;
    //     }
    // },[props.builder]);

    useEffect(() => {
        switch (props.builder.homeland.name) {
            case "Prax": 
                setTribeNames([props.builder.tribe.name]);
                break; 
            case "Grazelands" : 
                setTribeNames(["Pure Horse People"]);
                break; 
            default: 
                setTribeNames(props.builder.homeland.tribeNames);
                break;
        }
        if (props.builder.personalInfo && props.builder.personalInfo.gender) {
            setInfo(props.builder.personalInfo);
            var gender = props.builder.personalInfo.gender;
            if (gender === "Male") {
                setImages([...maleImages]);
            }
            else if (gender === "Female") {
                setImages([...femaleImages]);
            }
            else {
                setImages([...maleImages, ...femaleImages]);
            }
        }
        else {
            var season = calculateSeason();
            var week = calculateWeek();
            var random = Math.floor(Math.random()*(7));
            var newInfo = {
                firstName: "", 
                lastName: "",
                handedness:"Right",
                gender: "",
                image: "https://storage.googleapis.com/runequest/profiles/default.png",
                age: 21,
                season: "",
                week: "",
                day: "",
                tribeName: "Tribal Name",
                clanName: "",
                family: {
                    father: "",
                    mother: "",
                    siblings: [],
                }
            };
            newInfo.season = season;
            newInfo.week = week;
            newInfo.day = days[random];
            newInfo.family.father = getName("male");
            newInfo.family.mother = getName("female")
            newInfo.family.siblings = getSiblings(newInfo);
            if (props.builder.homeland.name === "Prax"){
                newInfo.tribeName = props.builder.tribe.name;
            }
            else if (props.builder.homeland.name == "Grazelands") {
                newInfo.tribeName = "Pure Horse People";
            }
            else {
                var random = Math.floor(Math.random()*props.builder.homeland.tribeNames.length);
                newInfo.tribeName = props.builder.homeland.tribeNames[random];
            }
            setInfo(newInfo);
        }
    }, [])

    useEffect(() => {
        checkForCompleteness();
    },[info]);

    function getName(type) {
        var names = [];
        if (type == "male") {
            names = [...props.builder.homeland.maleNames];
        }
        else if (type === "female") {
            names = [...props.builder.homeland.femaleNames];
        }
        else {
            names = [...props.builder.homeland.maleNames, ...props.builder.homeland.femaleNames]
        }
        var random = Math.floor(Math.random()*(names.length));
        return names[random];
    }

    function getSiblings(info) {
        var youngest = info.age;
        var oldest = info.age;
        var siblings = [];
        var random = Math.floor(Math.random()*(6));
        for (var i = 0; i < random; i++) {
            var genderRoll = Math.floor(Math.random()*(6));
            var gender = genderRoll < 3 ? "male" : "female"
            var ageRoll = Math.floor(Math.random()*(6));
            var siblingAge = 0;
            var name = getName(gender);
            while(true) {
                if (siblings.findIndex(sibling => sibling.name === name) > -1) {
                    name = getName(gender);
                }
                else {
                    break;
                }
            }
            if (ageRoll < 3) {
                youngest -= 2;
                siblingAge = youngest;
            }
            else {
                oldest += 2;
                siblingAge = oldest;
            }
            var relation = gender === "male" ? "Brother" : "Sister";
            var birthYear = 1625 - siblingAge;
            if (props.builder.parent.dead && birthYear >= props.builder.parent.yearDied) {
                relation = gender === "male" ? "Half-brother" : "Half-sister";
            }
            var fateRoll = Math.ceil(Math.random()*(20));
            var fate = "";
            if (siblingAge > 16) {
                if (fateRoll <= 10) {fate = "Alive and married"}
                else if (fateRoll <= 14) {fate = "Alive and unmarried"}
                else if (fateRoll <= 17) {fate = "Dead (was married)"}
                else {fate = "Dead (never married)"}
            }
            else {
                if (fateRoll <= 17) {fate = "Alive and unmarried"}
                else {fate = "Dead (never married)"}
            }
            siblings.push({
                name: name, 
                age: siblingAge,
                relation: relation,
                fate: fate,
            })
        }
        siblings.sort(compareValues('age', 'desc')); 
        return siblings;
    }

    function calculateSeason(){
        var seasonRune = {value: 0}
        props.builder.runes.elementalRunes.forEach(rune => {
            if (rune.name !== "Moon") {
                if (rune.value > seasonRune.value) {
                    seasonRune = rune;
                }
            }
        });
        switch (seasonRune.name) {
            case "Air" :
                return "Storm Season";
            case "Darkness" :
                return "Dark Season";
            case "Earth" : 
                return "Earth Season"; 
            case "Fire/Sky" : 
                return "Fire Season";
            case "Water": 
                return "Sea Season";
            default: 
                return "";
        }
    };

    function calculateWeek(){
        var weekRune = {value: 0}
        props.builder.runes.powerRunes.forEach(rune => {
            if (rune.first.name !== "Man") {
                if (rune.first.value > weekRune.value) {
                    weekRune = rune.first;
                }
                else if (rune.second.value > weekRune.value) {
                    weekRune = rune.second;
                }
            }
        });
        return weekRune.name + " Week";
    };

    function modifyImage(image) {
        var modifiedInfo = {...info};
        modifiedInfo.image = image;
        setInfo(modifiedInfo);
        var updatedBuilder = {...props.builder};
        updatedBuilder.personalInfo = {...modifiedInfo};
        props.update(updatedBuilder, false);
    }

    function modifyInfo(event, field) {
        var modifiedInfo = {...info};
        modifiedInfo[field] = event.target.value;
        if (field === "gender") {
            setHover(-1);
            if (event.target.value === "Male") {
                setImages([...maleImages]);
            }
            else if (event.target.value === "Female") {
                setImages([...femaleImages]);
            }
            else {
                setImages([...maleImages, ...femaleImages]);
            }
            modifiedInfo.firstName = getName(event.target.value.toLowerCase())
      }
        setInfo(modifiedInfo);
        var updatedBuilder = {...props.builder};
        updatedBuilder.personalInfo = {...modifiedInfo};
        props.update(updatedBuilder, false);
}

    function modifyFamilyInfo(event, field, index) {
        var modifiedInfo = {...info};
        if (field === "siblings") {
            modifiedInfo.family.siblings[index].name = event.target.value;
        }
        else {
            modifiedInfo.family[field] = event.target.value;
        }
        setInfo(modifiedInfo);
        var updatedBuilder = {...props.builder};
        updatedBuilder.personalInfo = {...modifiedInfo};
        props.update(updatedBuilder, false);
    }

    function checkForCompleteness() {
        if (info && info.gender && info.firstName && info.image) {
            props.disableNext(false);
        }
        else {
            props.disableNext(true);
        }
    }

    if (info) {
        return (
            <div>
                <div style={{display:"flex", width: "100%", flexWrap: "wrap"}}>
                <div style={{paddingTop: "20px", color: "#121312", width: "540px", marginRight: "20px"}}>
                    <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 0px 10px 16px", height: "100px"}}>
                        <div>Gender, Age &amp; Handedness</div>
                        <div style={{display:"flex", justifyContent:"flex-start", textAlign:"center", alignItems: "center", marginLeft: "20px", borderRadius:"5px"}}>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">Gender</InputLabel>
                                    <Select labelId="occupation-label" label="Gender" id="occupation" onChange={(event) => modifyInfo(event, "gender")} value={info.gender}>
                                    {
                                        genders.map(gender => {
                                            return (
                                                <MenuItem key={gender} value={gender}>{gender}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    {/* <InputLabel id="age-label">Age</InputLabel> */}
                                    <TextField type="number" label="Age" color="primary" value={info.age} onChange={(event) => {modifyInfo(event, "age")}} variant="outlined"></TextField>
                                </FormControl>
                            </div>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">Handedness</InputLabel>
                                    <Select labelId="occupation-label" label="Handedness" id="occupation" onChange={(event) => modifyInfo(event, "handedness")} value={info.handedness}>
                                    {
                                        hands.map(handedness => {
                                            return (
                                                <MenuItem key={handedness} value={handedness}>{handedness}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <TextField inputProps={{style: {fontSize: "16px", width:"250px", height: "30px", padding: "3px", marginLeft: "40px"}}} placeholder="Enter first name" color="primary" value={info.firstName} onChange={modifyInfo} variant="outlined" size="small">{info.firstName}</TextField> */}
                        </div>
                    </div>
                    <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 0px 10px 16px", marginTop: "20px"}}>
                        <div>Tribe &amp; Clan</div>
                        <div style={{display:"flex", justifyContent:"flex-start", textAlign:"center", alignItems: "center", marginLeft: "20px", borderRadius:"5px"}}>
                            <div  style={{width: "160px", height: "80px", display:"flex", alignItems: "center", marginRight: "10px"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">{["Esrolia", "Lunar Tarsh"].indexOf(props.builder.homeland.name) > -1 ? "Select City" : "Select Tribe"}</InputLabel>
                                    <Select labelId="occupation-label" label="Day" id="occupation" onChange={(event) => modifyInfo(event, "tribeName")} value={info.tribeName}>
                                    {
                                        tribeNames && tribeNames.map(tribeName => {
                                            return (
                                                <MenuItem key={tribeName} value={tribeName}>{tribeName}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{alignItems: "center", display: "flex", width: "160px", marginRight: "10px"}}>
                               <TextField id="tribe-name" label="Other Tribe" disabled={info.tribeName !== "Other"} inputProps={{style: {fontSize: "16px", width: "160px"}}} placeholder="Enter Tribal Name" color="primary" value={otherTribe} onChange={(event) => setOtherTribe(event.target.value)} variant="outlined">{otherTribe}</TextField>
                            </div>
                            <div style={{alignItems: "center", display: "flex", width: "160px"}}>
                               <TextField inputProps={{style: {fontSize: "16px", width: "160px"}}} label="Clan (optional)" placeholder="Clan Name" color="primary" value={info.clanName} onChange={(event) => modifyInfo(event, "clanName")} variant="outlined">{info.clanName}</TextField>
                            </div>
                            {/* <TextField inputProps={{style: {fontSize: "16px", width:"250px", height: "30px", padding: "3px", marginLeft: "40px"}}} placeholder="Enter first name" color="primary" value={info.firstName} onChange={modifyInfo} variant="outlined" size="small">{info.firstName}</TextField> */}
                        </div>
                    </div>
                    <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 0px 10px 16px", marginTop: "20px"}}>
                        <div>Date of birth</div>
                        <div style={{display:"flex", justifyContent:"flex-start", textAlign:"center", alignItems: "center", marginLeft: "20px", borderRadius:"5px"}}>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">Day</InputLabel>
                                    <Select labelId="occupation-label" label="Day" id="day" onChange={(event) => modifyInfo(event, "day")} value={info.day}>
                                    {
                                        days.map(day => {
                                            return (
                                                <MenuItem key={day} value={day}>{day}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">Week</InputLabel>
                                    <Select labelId="occupation-label" label="Week" id="week" onChange={(event) => modifyInfo(event, "week")} value={info.week}>
                                    {
                                        weeks.map(week => {
                                            return (
                                                <MenuItem key={week} value={week}>{week}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            <div  style={{width: "180px", height: "80px", display:"flex", alignItems: "center"}}>
                                <FormControl style={{width:"160px"}} variant="outlined">
                                    <InputLabel id="occupation-label">Season</InputLabel>
                                    <Select labelId="occupation-label" label="Season" id="season" onChange={(event) => modifyInfo(event, "season")} value={info.season}>
                                    {
                                        seasons.map(season => {
                                            return (
                                                <MenuItem key={season} value={season}>{season}</MenuItem>
                                            )            
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <TextField inputProps={{style: {fontSize: "16px", width:"250px", height: "30px", padding: "3px", marginLeft: "40px"}}} placeholder="Enter first name" color="primary" value={info.firstName} onChange={modifyInfo} variant="outlined" size="small">{info.firstName}</TextField> */}
                        </div>
                    </div>
                    {info.family ? 
                    <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 0px 10px 16px", marginTop: "20px"}}>
                        <div>Family</div>
                        <div style={{display:"flex", justifyContent:"flex-start", textAlign:"center", alignItems: "center", marginLeft: "20px", borderRadius:"5px", marginTop: "10px", width: "620px"}}>
                            <div style={{alignItems: "center", display: "flex", width: "240px"}}>
                                <div style={{marginRight: "10px", width:"80px"}}>{props.builder.parent.title === "father" && props.builder.parent.dead ? "Father (dead): " : "Father: "}</div>
                                <TextField inputProps={{style: {fontSize: "16px", height: "30px", padding: "3px"}}} placeholder="Father's Name" color="primary" value={info.family.father} onChange={(event) => modifyFamilyInfo(event, "father")} variant="outlined" size="small">{info.family.father}</TextField>
                            </div>
                            <div style={{alignItems: "center", display: "flex", width: "240px"}}>
                            <div style={{marginRight: "10px", width:"80px"}}>{props.builder.parent.title === "mother" && props.builder.parent.dead ? "Mother (dead): " : "Mother: "}</div>
                               <TextField inputProps={{style: {fontSize: "16px", height: "30px", padding: "3px"}}} placeholder="Mother's Name" color="primary" value={info.family.mother} onChange={(event) => modifyFamilyInfo(event, "mother")} variant="outlined" size="small">{info.family.mother}</TextField>
                            </div>
                        </div>
                        <div style={{marginTop: "30px", marginLeft: "20px"}}>
                            <div style={{marginBottom: "20px"}}>Siblings</div>
                            {info.family.siblings.length > 0 ? 
                                info.family.siblings.map((sibling, index) => {
                                    return (
                                        <div key={index} style={{display:"flex", marginTop: "10px", alignItems: "center"}}>
                                            <div style={{width: "240px"}}>
                                                <TextField inputProps={{style: {fontSize: "16px", width:"180px", height: "30px", padding: "3px"}}} placeholder="Father's Name" color="primary" value={info.family.siblings[index].name} onChange={(event) => modifyFamilyInfo(event, "siblings", index)} variant="outlined" size="small">{info.family.siblings[index].name}</TextField>
                                            </div>
                                            <div style={{width: "60px"}}>{sibling.age}</div>
                                            <div style={{width: "120px"}}>{sibling.relation}</div>
                                            <div style={{width: "160px"}}>{sibling.fate}</div>
                                        </div>
                                    )
                                })
                            :
                            <div style={{marginTop: "10px"}}>None</div>
                            }
                        </div>
                    </div>
                    : 
                    null
                    }
                </div>
                {info.gender ? 
                <div style={{paddingTop: "20px", color: "#121312", width: "540px"}}>
                    <div style={{backgroundColor: "#b3722a", color: "#fff", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 10px", height: "100px"}}>
                        <div style={{fontSize: "20px"}}>Character Name</div>
                        <div style={{display:"flex", justifyContent:"flex-start", textAlign:"center", alignItems: "center", marginLeft: "20px", borderRadius:"5px", marginTop: "18px"}}>
                            <div style={{alignItems: "center", display: "flex", width: "380px"}}>
                                <div style={{marginRight: "10px"}}>First Name: </div>
                                <TextField inputProps={{style: {fontSize: "16px", width:"160px", color: "#fff", height: "30px", padding: "3px"}}} placeholder="First name" color="primary" value={info.firstName} onChange={(event) => modifyInfo(event, "firstName")} variant="outlined" size="small">{info.firstName}</TextField>
                            </div>
                            <div style={{alignItems: "center", display: "flex", width: "380px"}}>
                            <div style={{marginRight: "10px"}}>Second Name (optional): </div>
                               <TextField inputProps={{style: {fontSize: "16px", width:"160px", color: "#fff", height: "30px", padding: "3px"}}} placeholder="Second name" color="primary" value={info.lastName} onChange={(event) => modifyInfo(event, "lastName")} variant="outlined" size="small">{info.lastName}</TextField>
                            </div>
                        </div>
                    </div>
                    <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 10px", marginTop: "20px"}}>
                        <div>Character Portrait</div>
                        <ProfileImage builder profileImage={info.image} gender={info.gender} updateImage={modifyImage} />
                    </div>
                </div>
                : 
                null
                }
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default PersonalInfo;