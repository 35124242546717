import React, { useState, useEffect } from 'react';
import styles from '../builder.module.css'
import OptionsRadioGroup from '../controls/optionsRadioGroup';

const HomelandWeapons = (props) => {

    const [homelandWeapons, setHomelandWeapons] = useState([]);
    const [stats, setStats] = useState({STR: 0, DEX: 0})
    const [lowStats, setLowStats] = useState(false);

    useEffect(() => {
        var updatedStats = {};
        props.builder.stats.details.forEach(stat => {
            if (stat.name === "STR" || stat.name === "DEX"){
                updatedStats[stat.name] = stat.baseValue + stat.statBonus;
            }
        });
        setStats(updatedStats);
    },[props.builder])

    useEffect(() => {
        if (props.homeland.weapons) {
            var weapons = [];
            props.homeland.weapons.forEach(weaponGroup => {
                if (weaponGroup.selected && weaponGroup.selected.name) {
                    weapons.push(weaponGroup);
                }
                else if (weaponGroup.options.length == 1) {
                    weapons.push(weaponGroup);
                }
                else if (weaponGroup.options.length > 1) {
                    weaponGroup.selected = {};
                    weapons.push(weaponGroup);
                }
            });
            setHomelandWeapons(weapons);
            checkForCompleteness(weapons);
        }
    }, [props.homeland.name]);

    function selectOption(selected, index) {
        var updatedWeapons = [...homelandWeapons];
        updatedWeapons[index].selected = selected;
        checkForCompleteness(updatedWeapons);
        setHomelandWeapons(updatedWeapons);
    }

    function checkForCompleteness(updatedWeapons) {
        var complete = true;
        updatedWeapons.forEach(weapon => {
            if (weapon.options.length > 1 && !weapon.selected.name) {
                complete = false;
            }
        });
        if (complete) {
            props.complete(updatedWeapons);
        }
    }

    return (
        <div style={{borderRadius: "10px"}}>
        <div style={{backgroundColor: "#8a612f", color: "#fff", paddingLeft: "5px", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent:"center", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>{props.title}</div>
        {                    
                homelandWeapons.map((item, index) => {
                    if (item.options.length > 1) {
                        return (
                            <div className={styles.row} style={{display:"flex", alignItems: "center"}} key={index}>
                                <OptionsRadioGroup key={index} item={item} selected={props.homeland} weapons stats={stats} update={selectOption} index={index} setLowStats={setLowStats} />
                            </div>
                            )
                    }
                    else if (item.options.length == 1) {
                        return (
                            <div className={styles.row} key={index}>
                                <div style={{display:"flex", height: "30px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", paddingLeft: "10px"}}>
                                    <div style={{alignItems: "center", display: "flex"}}>
                                        <div style={{display:"flex", width: "320px"}}>
                                            <div>{item.options[0].name}</div>
                                        </div>
                                    </div>
                                    <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{item.bonus}</div>
                                </div>
                            </div>
                        )
                    }
                })
            }
            {
                lowStats ? 
                    <div style={{marginTop: "20px", color: "#851113"}}>** Insufficient STR and/or DEX</div>
                : 
                null
            }                    
        </div>
        );
}

export default HomelandWeapons;