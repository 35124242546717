import React from 'react'
import globalStyles from '../Styles/styles.module.css';

const AttackResultsTable = (props) => {
    return (
        <div style={{backgroundColor: "f0f0f0", width: "1060px", height: "800px"}}>
            <div style={{backgroundColor: "#676767", color: "#fff", display:"flex", textAlign:"center", fontSize: "13px", padding: "10px"}}>
                <div style={{width: "120px"}}></div>
                <div style={{width: "180px"}}>Critical Parry</div>
                <div style={{width: "180px"}}>Special Parry</div>
                <div style={{width: "180px"}}>Normal Parry</div>
                <div style={{width: "180px"}}>Failed Parry</div>
                <div style={{width: "180px"}}>Fumbled Parry</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Critical Attack vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage.<br /><br /> <b>Defender’s</b> parrying weapon takes 1 HP damage if rolled damage is greater than its current HP. Any excess damage goes to affected hit location.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls special damage.<br /><br /> <b>Defender’s</b> parrying weapon takes 1 HP damage if rolled damage is greater than its current HP. Any excess damage goes to adjacent hit location, with no armor protection.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls special damage.<br /><br /> <b>Defender’s</b> parrying weapon HP reduced by the damage rolled. Any excess damage goes to adjacent hit location, with no armor protection.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> does maximum special damage. <br /><br /> <b>Defender</b> takes damage, with no armor protection.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> automatically hits, does maximum special damage. <br /><br /> <b>Defender</b> takes damage, with no armor protection, and rolls on Fumbles table</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Special Attack vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s normal damage. Attacking weapon’s HP is reduced by 1 HP if damage over its current HP.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage.<br /><br /> <b>Defender’s</b> parrying weapon takes 1HP damage if damage more than its current HP. Any excess damage goes to the affected hit location.</div> 
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls special damage.<br /><br /> <b>Defender’s</b> parrying weapon takes damage over its HP, with same amount of damage going to adjacent hit location.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls special damage. <br /><br /> <b>Defender</b> takes damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls special damage. <br /><br /> <b>Defender</b> takes damage and rolls on Fumbles table.</div> 
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Normal Attack vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s special damage. Attacking weapon’s HP is reduced by any damage over its current HP.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s normal damage. Attacking weapon’s HP is reduced by 1 HP if damage over its current HP.</div> 
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage.<br /><br /> <b>Defender's</b> parrying weapon takes 1HP damage if damage more than its current HP. Any excess damage goes to the affected hit location.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage. <br /><br /> <b>Defender</b> takes damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage. <br /><br /> <b>Defender</b> takes damage and rolls on Fumbles table.</div> 
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Failed Attack vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s special damage. Attacking weapon’s HP reduced by the damage rolled.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s special damage. Attacking weapon’s HP reduced by any damage over its current HP.</div> 
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> parried or deflected.<br /><br /> <b>Defender</b> rolls parrying weapon’s normal damage. Attacking weapon’s HP reduced by 1 HP if damage over its current HP.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> misses. <br /><br /> <b>Defender</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls normal damage. <br /><br /> <b>Defender</b> takes damage and rolls on Fumbles table.</div> 
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Fumbled Attack vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.<br /><br /> <b>Defender</b> rolls parrying weapon’s special damage. Attacking weapon’s HP reduced by the damage rolled.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.<br /><br /> <b>Defender</b> rolls parrying weapon’s special damage. Attacking weapon’s HP reduced by the damage over its current HP.</div> 
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.<br /><br /> <b>Defender</b> rolls parrying weapon’s normal damage. Attacking weapon’s HP reduced by 1 HP if damage over its current HP.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table. <br /><br /> <b>Defender</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table. <br /><br /> <b>Defender</b> rolls on Fumbles table.</div> 
            </div>
       </div>
    )
}

export default AttackResultsTable;