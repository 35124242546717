import React, {useState, useEffect} from 'react';
import { Button, FormControlLabel, Switch } from '@mui/material';
import EditBoxMulti from '../Controls/editBoxMulti';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import EditBox from '../Controls/editBoxSingle';

const AddLogEntryDialog = (props) => {

    const [editorContent, setEditorContent] = useState()
    const [note, setNote] = useState()
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (props.index > -1) {
            setNote(props.currentNote.content);
        }
    },[props.currentNote])

    function addNote() {
        props.update(note, title, props.index);
        props.close();
    }

    function onChange(value) {
        setEditorContent(value);
        var foo = draftToHtml(convertToRaw(value.getCurrentContent()))
        setNote(foo);
    }

    return (
        <div style={{overflowX: "hidden", height: "600px"}}>
            <div style={{padding: "10px", backgroundColor: "#fff"}}>
                <div style={{display: "flex", alignItems: "center", backgroundColor: "#fff", fontSize: "16px"}}>
                    <div style={{marginRight: "10px"}}>Title: </div>
                    <EditBox width="760px" value={title} onChange={setTitle} placeholder="Title" />
                </div>
                <hr style={{margin: "10px 0px"}} />
                <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between", height: "434px", backgroundColor: "#fff"}}>
                    <Editor
                        editorState={editorContent}
                        placeholder='Remember, this entry will be visible to all players in your campaign'
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onChange}
                    />          
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", padding: "20px", height: "40px"}}>
                    <Button onClick={addNote} style={{width: "100px"}} color="primary" variant="contained">{props.index > -1 ? "Edit": "Add"}</Button>
                    <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    );
}
export default AddLogEntryDialog;