import React from 'react'
import globalStyles from '../Styles/styles.module.css';

const AbilityResultsTable = (props) => {
    return (
        <div style={{backgroundColor: "f0f0f0", width: "1060px", height: "800px"}}>
            <div style={{backgroundColor: "#676767", color: "#fff", display:"flex", textAlign:"center", fontSize: "16px"}}>
                <div style={{width: "170px"}}>Ability</div>
                <div style={{width: "170px"}}>Critical</div>
                <div style={{width: "170px"}}>Special</div>
                <div style={{width: "170px"}}>Success</div>
                <div style={{width: "170px"}}>Failure</div>
                <div style={{width: "170px"}}>Fumble</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>6-00</div>
                <div style={{width: "170px"}}>96-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>6-7</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>96-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>8-10</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>96-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>11-12</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>13-17</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>18-22</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>23-27</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>28-29</div>
                <div style={{width: "170px"}}>1</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>30</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>97-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>31-32</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>33-37</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-7</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>38-42</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-8</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>43-47</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-9</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>48-49</div>
                <div style={{width: "170px"}}>1-2</div>
                <div style={{width: "170px"}}>1-10</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>50</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-10</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>98-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>51-52</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-10</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>53-57</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-11</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>58-62</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-12</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>63-67</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-13</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>68-69</div>
                <div style={{width: "170px"}}>1-3</div>
                <div style={{width: "170px"}}>1-14</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>70</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-14</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>99-00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>71-72</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-14</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>73-77</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-15</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>78-82</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-16</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>83-87</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-17</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>88-89</div>
                <div style={{width: "170px"}}>1-4</div>
                <div style={{width: "170px"}}>1-18</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>90-92</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-18</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>93-95</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-19</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>Per ability</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>96-97</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-19</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>98-102</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-20</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>103-107</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-21</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>108-109</div>
                <div style={{width: "170px"}}>1-5</div>
                <div style={{width: "170px"}}>1-22</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>110-112</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>1-22</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>113-117</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>1-23</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>118-122</div>
                <div style={{width: "170px"}}>1-6</div>
                <div style={{width: "170px"}}>1-24</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px"}}>
                <div style={{width: "170px"}}>higher</div>
                <div style={{width: "170px"}}>5% ability</div>
                <div style={{width: "170px"}}>20% ability</div>
                <div style={{width: "170px"}}>1-95</div>
                <div style={{width: "170px"}}>96-00</div>
                <div style={{width: "170px"}}>00</div>
            </div>
       </div>
    )
}

export default AbilityResultsTable;