import React, {useState, useEffect} from 'react';
import DiceButton from './diceButton';
import RuneDiceButton from './runeDiceButton';
import { withPageState } from '../Context/PageState';
import { BorderRight } from '@mui/icons-material';

const RuneSpellButton = (props) => {

    const [item, setItem] = useState(props.item);
    const [castingRune, setCastingRune] = useState(null);
    const [target, setTarget] = useState(0);

    useEffect(() => {
        setItem({...props.item});
    },[props.item]);

    useEffect(() => {
        var index = props.spellcaster.runes.findIndex(item => item.name === props.rune);
        if (index > -1) {
            setCastingRune({...props.spellcaster.runes[index]});
            setTarget(props.spellcaster.runes[index].value + (props.modifier || 0));
        }
    },[props.rune, props.modifier])

    function processResult(roll) {
        props.processResult({...roll, castingRune: castingRune})
    }

    if (props.pageState.editMode) {
        return (
            <div></div>
            )
    }
    else {

        return (
            <div style={{width: "140px"}}>
                <DiceButton diceRoller={props.diceRoller} style={{width: "140px", borderRadius: "5px"}} disabled={props.disabled} type="Rune Spell" entity={props.spellcaster} processResult={processResult} item={props.item} modifier={props.modifier} target={target}>
                    <RuneDiceButton rune={props.rune}  />
                </DiceButton>
            </div>
)
    }
}

export default withPageState(RuneSpellButton)