import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css';
import styles from './encounters.module.css';
import CharacterContext from '../Context/context';
import { Card, CardContent, Typography, Tabs, Tab, Box } from '@mui/material';
import { withFirebase } from '../Firebase';
import Characteristics from '../Characteristics/characteristics';
import Skills from './skills';
import Passions from './passions';
import Runes from './runes';
import {postMessage} from '../../services/dieRollService';
import Weapons from './weapons';
import WeaponButtonTile from './tiles/weaponButtonTile';
import * as SAVE from '../../constants/save';
import AbilityTile from './tiles/abilityRow';
import StatTile from './tiles/statTile';
import MagicPoints from './magicPoints';
import HitPoints from './hitPoints';
import SpiritMagic from '../Magic/spiritMagic';
import { calculateMove } from '../../services/characterService';
import RuneMagic from '../Magic/runeMagic';
import CultHeaderBar from '../Magic/cultHeaderBar';
import StorageCrystalPanel from '../MagicItems/storageCrystalPanel';
import BoundSpirit from '../MagicItems/boundSpirit';
import { getAvailableMagicPoints } from '../../services/magicPointsService';
import { processRuneSpell, setSpellDefaults } from '../../services/spellsService';
import { processSpiritSpell } from '../../services/spellsService';
import HitPointTable from '../Controls/hitPointTable';
import AbilityTileContextMenu from '../Abilities/abilityTileContextMenu';
import WeaponTileContextMenu from '../Combat/weaponTileContextMenu';
import CharacteristicTileContextMenu from '../Characteristics/characteristicTileContextMenu';
import SpiritSpellRow from './tiles/spiritSpellRow';
import SpiritSpellTileContextMenu from '../Magic/spiritSpellTileContextMenu';
import RuneSpellTileContextMenu from '../Magic/runeSpellTileContextMenu';
import BoundSpiritContextMenu from '../MagicItems/boundSpiritContextMenu';
import EditBox from '../Controls/editBoxSingle';
import { withPageState } from '../Context/PageState';
import InventoryButton from '../Inventory/inventoryButton';
import InventoryContextPanel from '../Inventory/inventoryContextPanel';
import EnchantmentsButton from '../Enchantments/enchantmentsButton';
import EnchantmentsContextPanel from '../Enchantments/enchantmentsContextPanel';
import DetailsPanel from '../Controls/detailsPanel';

const NPCCard = (props) => {
	const [timeout, setTimeout] = useState(-1440);
	const [cache, setCache] = useState(null);
	const [uid, setUid] = useState();
	const[npc, setNpc] = React.useState();
	const [campaign, setCampaign] = useState()
	const [value, setValue] = React.useState(0);
	const [totalPoints, setTotalPoints] = useState(0);
	const [augmenting, setAugmenting] = useState({
        state: "",
        value: 0
    });
    const [panelItem, setPanelItem] = useState({
        type: "ability", 
        item: {
            name: "Battle", value: 60, variant: "", cult: null
        }
    })
    const [panelVisibility, setPanelVisibility] = useState("hidePanel");
    const [panelPosition, setPanelPosition] = useState(0);
    const [panelHeight, setPanelHeight] = useState(800);
    const [panelMargin, setPanelMargin] = useState(-400)
	const [updated, setUpdated] = useState(false);

    useEffect(() => {
        var width = window.innerWidth;
        if (width > 1928) {
            setPanelPosition(((width - 1200)/2));
        }
        else setPanelPosition(364);
        if (window.innerHeight < 850) {
            setPanelHeight(window.innerHeight)
            setPanelMargin((window.innerHeight * -0.5) + 50)
        }
    },[window.innerWidth])

	useEffect(() => {
		if (props.saveMode && updated) {
			props.saveNPC(npc);
			setUpdated(false);
		}
	},[props.saveMode])

	useEffect(() => {
		setNpc({...props.npc})
		setUid(props.npc.id)
	},[props.npc]);

	function handleNameChange(value) {
		var updatedCharacter = {...npc};
		updatedCharacter.name.first = value
		update(updatedCharacter);
	}

	useEffect(() => {
		if (props.augmenting.entityId === npc?.id) {
			setAugmenting(props.augmenting);
		}
	},[props.augmenting])
    
    useEffect(() => {
		if (npc) {
			var points = getAvailableMagicPoints(npc);
			setTotalPoints(points.current);
		}
    },[npc && npc.magicPoints]);

	function update(updatedNpc) {
		props.firebase
		.npc(updatedNpc.id)
		.update(updatedNpc)
		.then( function() {
		});
		setNpc(updatedNpc);
	}

	function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
	}
 

    function applyRuneSpell(dieRoll, action){
        if (action.type) {
            var updates = processRuneSpell(dieRoll, action, npc, props.firebase, action.targetType ? action.targetType : "npc", campaign)
            updateFields(updates, SAVE.YES);
        }
    }

    function applySpiritSpell(dieRoll, action){
            var updates = processSpiritSpell(dieRoll, action, npc, props.firebase, action.targetType ? action.targetType : "npc", campaign)
			
			var updatedSpell = setSpellDefaults(action);
			var npcSpells = [...npc.spiritSpells];
			var index = npcSpells.findIndex(spell => spell.id === updatedSpell.id);
			if (index > -1) {
				npcSpells[index] = updatedSpell;
			}
			updates.push({fieldName: "spiritSpells", value: npcSpells})
		
            updateFields(updates, SAVE.YES);
    }

    function processSpell(dieRoll, action, title) {
        if (action.type === "spirit") {
            applySpiritSpell(dieRoll, action);
        }
        else {
            applyRuneSpell(dieRoll, action);
        }
		var userDetails = {
			uid: authUser.uid,
			firstName: "GM"
		} 
		postMessage(userDetails, dieRoll, props.firebase, npc, action)
		props.processResult(dieRoll, action, title, npc)
    }
   
    function updateFields(updates, persist, noCache) {
        // console.log('update fields', updates, persist)
        var updatedCharacter = {...npc};
        updates.forEach(update => {
            updatedCharacter[update.fieldName] = update.value;
        })
        if (!noCache) {
            setNpc(updatedCharacter);
        }
        if (persist === SAVE.DELAYED) {
            delayedSave(updates)
        }
        else if (persist === SAVE.YES){
            var saveObjects = {}
            updates.forEach(update => {
                saveObjects[update.fieldName] = update.value;
            })
            saveUpdatedFields(saveObjects);
        }
		else {
			setUpdated(true);
		}
    }

    function delayedSave(updates) {
        var updatedCache = {...cache};
        updates.forEach(update => {
            updatedCache[update.fieldName] = update.value;
        })
        setCache(updatedCache);

        window.clearTimeout(timeout)
        var interval = window.setTimeout(function() {
            saveUpdatedFields(updatedCache);
        }, 1000);
        setTimeout(interval);
}

    function saveUpdatedFields(updatedFields) {
        props.firebase
        .npc(uid)
        .update(updatedFields)
        .then( function() {
            setCache({});
        });
    }

    const closePanel = () => {
        setPanelVisibility("hidePanel");
        setPanelItem({type: "", item: {name: ""}})
    }

    const displayPanelItem = (displayDetails) => {
        if (displayDetails.type === "Weapon" || displayDetails.type === "Spirit Combat") {
            if (panelItem.item.weapon && panelItem.item.weapon.id === displayDetails.item.weapon.id) {
                setPanelVisibility("hidePanel");
                setPanelItem({type: "", item: {name: ""}})
            }
            else {
                setPanelItem(displayDetails)
                setPanelVisibility("showPanel")
            }
        }
        else {
            if (panelItem.item && panelItem.item.id && displayDetails.item && panelItem.item.id === displayDetails.item.id) {
                setPanelVisibility("hidePanel");
                setPanelItem({type: "", item: {name: ""}})
            }
            else {
                setPanelItem(displayDetails)
                setPanelVisibility("showPanel")
            }
        }
    }

	function displayWeapons() {
		return (
			<div style={{fontSize:"13px"}}>
				<div style={{padding: "5px", borderRadius: "5px", fontFamily: "Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", marginTop: "5px"}}>
					<Weapons diceRoller={props.diceRoller} displayItem={displayPanelItem} style={{height: "86px", overflow: "auto", lineHeight: 1.6}} tile={<WeaponButtonTile />} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} processDamage={(result) => props.processDamage(result, npc)} update={updateFields} entity={npc} />
				</div>
			</div>
		);
	}
	
	function displaySpiritMagic() {
		return (
			<div style={{fontSize:"13px"}}>
				<div style={{border: "2px solid #2F588A", borderRadius: "5px", fontFamily: "Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", marginTop: "5px", lineHeight: 1.4}}>
					<SpiritMagic diceRoller={props.diceRoller} entityHeight="112px" element={<SpiritSpellRow />} displayItem={displayPanelItem} availableMagicPoints={totalPoints} stylesheet={styles} spellcaster={npc} processResult={processSpell} update={updateFields}  />
				</div>
			</div>
		);
	}

	if (npc) {
		var authUser = props.authUser; 
        var type = "npc";
		return (
			<CharacterContext.Provider value={{augmenting, setAugmenting, authUser, character: npc, alliedCharacters: props.alliedCharacters, activeEnemies: props.activeEnemies, type}}>
				<Card style={{border: "2px solid #8a612f", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", margin: "2px", height: "420px", padding: "3px",display: 'flex'}} >
					<div style={{display: "flex", flexDirection: "column"}}>
						<CardContent style={{width: "384px", 	flex: '1 0 auto', padding: "0px" }}>
							<div className={globalStyles.panel} style={{fontSize:"13px", height: "250px", overflow: "auto"}} >
								{props.pageState.editMode ? 
									null
								: 
									<div style={{marginLeft: "4px", float: "right"}}>
										<HitPointTable hitPoints={npc.hitPoints} update={updateFields}/>
									</div>
								}
								<div style={{}}>
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										{props.pageState.editMode ? 
											<div style={{width: "260px"}}>
												<EditBox width="240px" size="sm" onChange={handleNameChange} value={npc.name.first} >{npc.name.first}</EditBox>
											</div>
										:
											<div style={{color:"#b3722a", fontSize:"16px", fontWeight:"700"}}>{npc.name.first}</div>
										}
										{/* {props.pageState.editMode ?  */}
										<div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
											<EnchantmentsButton npc width="23px" character={npc} user={authUser} update={(updateFields)} displayPanel={() => displayPanelItem({type: "Enchantments", item: {}})} />
											<InventoryButton style={{marginLeft: "10px"}} npc width="24px" character={npc} displayPanel={() => displayPanelItem({type: "Inventory", item: {}})} />
										</div>
										{/* : null} */}

									</div>

									<div style={{fontWeight:"bold", fontSize:"13px", margin:"5px 0px"}}>{npc.type}</div>
									<Characteristics diceRoller={props.diceRoller} tile={<StatTile />} entity={npc} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} displayItem={displayPanelItem} update={updateFields} pageState={props.pageState} />
									<div style={{display:"flex",alignItems:"center", alignItems:"center", marginTop: "3px"}}>
										<HitPoints entity={npc} update={updateFields} />
										<div style={{width: "10px"}} />
										<MagicPoints entity={npc} update={updateFields}/>
										<div style={{width: "10px"}} />
										<div style={{display:"flex", alignItems: "center"}}>
											<div style={{fontWeight: "bold"}}>Move:&nbsp;</div>
											<div>{calculateMove(npc)}</div>
										</div>
									</div>
									<div style={{}}>
										<div style={{display:"flex", flexWrap: "wrap", alignItems: "center"}}>
											{/* <div style={{fontWeight:"bold"}}>Skills:&nbsp;</div> */}
											<Skills diceRoller={props.diceRoller} displayItem={displayPanelItem} title="Skills: " processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={update} entity={npc} tile={<AbilityTile />} />
										</div>
										<div style={{display:"flex", flexWrap: "wrap", alignItems: "center"}}>
											{/* <div style={{fontWeight:"bold"}}>Runes:&nbsp;</div> */}
											<Runes diceRoller={props.diceRoller} displayItem={displayPanelItem} title="Runes: " processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={update} entity={npc} tile={<AbilityTile />} />
										</div>
										<div style={{display:"flex", flexWrap: "wrap", alignItems: "center", fontSize: "13px"}}>
											{/* <div style={{fontWeight:"bold"}}>Passions:&nbsp;</div> */}
											<Passions diceRoller={props.diceRoller} style={{}} displayItem={displayPanelItem} title="Passions: " processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={update} entity={npc} tile={<AbilityTile />} />
										</div>
									</div>
								</div>
							</div>
							<Typography variant="subtitle1" color="textSecondary">
								<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312"}}>
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="simple tabs example">
										<Tab style={{fontSize: "14px", minWidth: "75px", color: "#121312", padding: "6px"}} label="Combat" {...a11yProps(0)} />
										<Tab style={{fontSize: "14px", minWidth: "85px", color: "#121312", padding: "6px"}} label="Spirit magic" {...a11yProps(1)} />
										{npc.cults.length > 0 ? 
											<Tab style={{fontSize: "14px", minWidth: "85px", color: "#121312", padding: "6px"}} label="Rune magic" {...a11yProps(2)} />
										: 
											null
										}
										{npc.boundSpirits.length > 0 || npc.matrices.length > 0 || npc.crystals.length > 0 ? 
											<Tab style={{fontSize: "14px", minWidth: "85px", color: "#121312", padding: "6px"}} label="Magic Items" {...a11yProps(3)} />
										: 
											null
										}
									</Tabs>
									</Box>
									{value === 0 ? 
										<div style={{padding: "0px"}} value={value} index={0}>
											{displayWeapons()}      
										</div>
									: null}
									{value === 1 ? 
										<div style={{padding: "0px"}} value={value} index={1}>
											{displaySpiritMagic()}
										</div>
									: null}
									{value === 2 ? 
										<div style={{padding: "0px"}} value={value} index={2}>
											<div className={globalStyles.panel} style={{height: "128px", overflow: "auto", fontSize: "13px", lineHeight: 1.6}}>
												<RuneMagic diceRoller={props.diceRoller} entityHeight="120px" displayItem={displayPanelItem} character={npc} processResult={processSpell} update={updateFields} headerBar={<CultHeaderBar character={npc} />} />
											</div>
										</div>
									: null}
									{value === 3 ? 
										<div style={{padding: "0px"}} value={value} index={3}>
											<div className={globalStyles.panel} style={{height: "128px", overflow: "auto", fontSize: "13px", lineHeight: 1.6}}>
												{npc.boundSpirits.length > 0 ? 
													npc.boundSpirits.map((spirit, index) => {
														return (
															<div key={index} style={{backgroundColor: "#fff", border: "2px solid #2F588A", margin: "10px 0px", borderRadius: "5px"}}>
																<BoundSpirit diceRoller={props.diceRoller} element={<SpiritSpellRow />} displayItem={displayPanelItem} entity={npc} availableMagicPoints={totalPoints} stylesheet={styles} spirit={spirit} processResult={processSpell} update={updateFields} />
															</div>
														)
												})
												: 
													null
												}
												{npc.crystals.length > 0 ? 
													<StorageCrystalPanel displayItem={displayPanelItem} entity={npc} update={updateFields} />
												: 
													null
												}
											</div>
										</div>
									: null}
								</div>
							</Typography>
						</CardContent>

						<div className={globalStyles.contextBar} style={{right: panelPosition, top: "50vh"}}>
                                <div id="contextPanel" className={globalStyles[panelVisibility]} style={{width: "360px", borderRadius: "5px", border: "2px solid #fff", fontSize: "15px", height: panelHeight + "px", left: "auto", marginTop: panelMargin + "px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-panel-background.png)"}}>
								{panelItem && panelItem.type === "Skill" || panelItem && panelItem.type === "Rune" || panelItem && panelItem.type === "Passion" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
											<AbilityTileContextMenu diceRoller={props.diceRoller} panelItem={panelItem}  processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Weapon" ?
									<>
										<DetailsPanel red npcName={npc.name.first} panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
											<WeaponTileContextMenu diceRoller={props.diceRoller} panelItem={panelItem} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={updateFields} processDamage={(result) => props.processDamage(result, npc)} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Stat" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
											<CharacteristicTileContextMenu diceRoller={props.diceRoller} panelItem={panelItem} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={updateFields} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Spirit Spell" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
											<SpiritSpellTileContextMenu diceRoller={props.diceRoller}  element={<SpiritSpellRow />} spellcaster={npc} panelItem={panelItem} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={updateFields} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Rune Spell" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={panelItem} close={closePanel} panelHeight={panelHeight}>
											<RuneSpellTileContextMenu diceRoller={props.diceRoller} spellcaster={npc} panelItem={panelItem} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} update={updateFields} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Inventory" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={{item: {name: "Inventory"}, type: "inventory"}} close={closePanel} panelHeight={panelHeight}>
											<InventoryContextPanel entity={npc} update={updateFields} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Enchantments" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={{item: {name: "Enchantments"}, type: "enchantments"}} close={closePanel} panelHeight={panelHeight}>
											<EnchantmentsContextPanel character={npc} update={(updateFields)} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								panelItem && panelItem.type === "Bound Spirit" ?
									<>
										<DetailsPanel npcName={npc.name.first} panelItem={{item: {name: "Bound Spirit" + props.panelItem.item ? " (" + props.panelItem.item.name?.first + ")" : ""}, type: "inventory"}} close={closePanel} panelHeight={panelHeight}>
											<BoundSpiritContextMenu diceRoller={props.diceRoller} spellcaster={npc} panelItem={panelItem} processResult={(dieRoll, action, title) => props.processResult(dieRoll, action, title, npc)} processDamage={(result) => props.processDamage(result, npc)} update={updateFields} displayItem={displayPanelItem} entity={panelItem.entity || npc} close={closePanel} />
										</DetailsPanel>
									</>
								: 
								null
								}
							</div>
						</div>
					</div>
				</Card>
			</CharacterContext.Provider>
		);

	}
	else {return null;}
	}

	export default withPageState(withFirebase(NPCCard));