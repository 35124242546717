import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withAuthorization, withEmailVerification, AuthUserContext } from '../Session';
import Campaign from './campaign';

const CampaignPage = (props) => {

    const [uid, setUid] = useState();

    const params = useParams()

    useEffect(() => {
        var uid = params.uid;
        setUid(uid);
    }, [params]);

        return(
            <div id="campaignPage" style={{width: "100%"}}>
                <AuthUserContext.Consumer>
                    {authUser => (
                        <Campaign authUser={authUser} uid={uid} diceRoller={props.diceRoller} />
                    )}
                </AuthUserContext.Consumer>
            </div>
        );
}
export default withEmailVerification(withAuthorization(CampaignPage));