import React, {useState, useEffect} from 'react';
import { withContext } from '../Context/context';
import * as SAVE from '../../constants/save';
import SpellService from '../../services/spellsService';
import { getAvailableMagicPoints } from '../../services/magicPointsService';
import { withFirebase } from '../Firebase';
import Characteristics from '../Characteristics/characteristics';
import StatRow from './tiles/statRow';
import HitPointTable from '../Controls/hitPointTable';
import Skills from '../Characters/character/skillsPanel';
import AbilityRow from '../Abilities/npcAbilityRow'
import Weapons from '../Characters/character/weapons';
import WeaponRow from '../MagicItems/tiles/weaponButtonTile';
import SpiritCombat from '../Characters/character/spiritCombatPanel';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';

const BoundSpiritContextMenu = (props) => {

    const [spirit, setSpirit] = useState(props.panelItem.item);
    const [points, setPoints] = useState(props.panelItem.item.points);
    const [availableMagicPoints, setAvailableMagicPoints] = useState(0);
    const [pinned, setPinned] = useState(false);

    useEffect(() => {
        setSpirit(props.panelItem.item);
        setPoints(props.panelItem.item.points);
    },[props.panelItem.item]);

    useEffect(() => {
        var points = getAvailableMagicPoints(props.spellcaster)
        setAvailableMagicPoints(points);
    },[props.spellcaster.magicPoints])

    const update = (updates) => {
        var index = props.providerData.character.boundSpirits.findIndex(item => item.id === spirit.id);
        var updatedSpirits = [...props.providerData.character.boundSpirits];
        var updatedSpirit = {...updatedSpirits[index]};
        updatedSpirit[updates.fieldName] = updates.value;
        updatedSpirits[index] = updatedSpirit;
        props.update([{fieldName: "boundSpirits", value: updatedSpirits}], SAVE.YES);
    }

    function displayItem (details) {
        return props.displayItem({...details, entity: spirit})
    }

    return (
        <>
            <div style={{padding: "10px", height: "450px", fontSize: "14px"}}>
                <div style={{padding: "5px 0px 10px"}}>
                    {spirit.type + " bound into " + spirit.location}
                </div>
                <Characteristics diceRoller={props.diceRoller} tile={<StatRow diceRoller={props.diceRoller} />} processResult={props.processResult} update={props.update} entity={{...props.panelItem.item}} displayItem={displayItem} />
                <hr style={{width:"100%"}} />
                <div style={{display:"flex", justifyContent: "space-between"}}>
                    {spirit.skills ? 
                        <div style={{width: "50%"}}>
                            <div style={{fontWeight:"bold"}}>Skills:&nbsp;</div>
                            <Skills diceRoller={props.diceRoller} processResult={props.processResult} displayItem={displayItem} update={update} entity={spirit} tile={<AbilityRow diceRoller={props.diceRoller} />} />
                        </div>
                    : 
                    null
                    }
                    {spirit.hitPoints ? 
                        <HitPointTable hitPoints={props.panelItem.item.hitPoints} update={update} />
                    : 
                    null
                    }
                </div>
                {spirit.weapons ? 
                    <div style={{fontSize:"13px", height: "126px"}}>
                        <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
                            <div id="header" style={{background: "rgb(133,17,19)", background: "linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)", padding: "10px", fontSize: "18px", textAlign: "center", color: "#fff"}}>Weapons</div>
                            <Weapons diceRoller={props.diceRoller} tile={<WeaponRow diceRoller={props.diceRoller} />} processResult={props.processResult} processDamage={props.processDamage} update={props.update} entity={spirit} type="familiar" displayItem={displayItem} />
                        </div>
                    </div>
                : 
                <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
                    <div id="header" style={{background: "rgb(133,17,19)", background: "linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)", marginBottom: "10px", padding: "10px", fontSize: "18px", textAlign: "center", color: "#fff"}}>Spirit Combat</div>
                    <SpiritCombat diceRoller={props.diceRoller} displayItem={displayItem} processResult={props.processResult} processDamage={props.processDamage} update={props.update} entity={spirit} />
                </div>
            }
                <div>
                    {spirit.notes}
                </div>
            </div>
        </>
    )
}
export default withContext(BoundSpiritContextMenu);