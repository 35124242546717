import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import compareValues from '../../utils/compareValues';
import RuneSpell from './runeSpell';

const RuneSpells = (props) => {

    const [runeSpells, setRuneSpells] = useState();

    useEffect(() => {
        defaultSort();
    },[props.spells, props.pageState.editMode]);

    function defaultSort() {
        var sorted = props.spells && props.spells.sort(compareValues('name'));
        if (props.pageState.editMode) {
            var nonCommon = sorted.filter(function(item) {
                return !item.common;
            })
            setRuneSpells(nonCommon);
        }
        else {
            setRuneSpells(sorted);
        }
    }

    if (runeSpells) {
        return(
            <div style={{...props.style}}>
                <div className={globalStyles.panel} style={{maxHeight:"580px", overflow: "auto"}}>
                    {runeSpells.map((item, index) => {
                        return (
                            <div key={index} >
                                <RuneSpell diceRoller={props.diceRoller} spell={item} totalPoints={props.totalPoints} cult={props.cult} spellcaster={props.spellcaster} character={props.character} processResult={props.processResult} update={props.update} displayItem={props.displayItem} powGain={props.powGain} />
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default withPageState(RuneSpells);