import React, { useState, useEffect } from 'react';
import { FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { styled } from "@mui/system";

const WhiteRadio = styled(Radio, {
    name: "WhiteRadio",
    slot: "Wrapper"
    })({
    root: {
        marginLeft: "30px",
        color: "#2F588A",
        "&$checked": {
            color: "fff"
        }
        },
    checked: {}
  });

const CultPassion = (props) => {

    const [selected, setSelected] = useState(-1);

    useEffect(() => {
        setSelected(props.selected || -1)
    },[props.selected]);

    function handleChange(event) {
        setSelected(parseInt(event.target.value));
        props.selectPassion(event.target.value)
    }

    if (props.passions) {
        return (
            <div style={{backgroundColor: "#cdcdcd", border: "2px solid #2f588a", borderRadius: "10px", padding: "20px 5px", marginTop: "10px", }}>
                <div style={{padding: "0px 10px 10px", fontSize: "20px", }}>Select a favoured passion (optional)</div>
                <div style={{display:"flex", marginRight: "10px"}}>
                    <FormControl>
                        <RadioGroup aria-label={"Select" } row style={{}} value={parseInt(selected)} name="events" onChange={handleChange}>
                            {
                            props.passions.map((passion, index) => {
                                var label = passion.name;
                                if (passion.variant) label += " (" + passion.variant + ")"
                                return (
                                        <FormControlLabel key={index} value={index} control={<WhiteRadio />} label={label} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default CultPassion;