import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import EditBox from "../../Controls/editBoxSingle";
import EditBoxMulti from "../../Controls/editBoxMulti";
import { Button } from "@mui/material";
import compareValues from "../../../utils/compareValues";

const EventHistory = (props) => {

    const [year, setYear] = useState(0);
    const [outcome, setOutcome] = useState("");

    useEffect(() => {
        if (props.title === "grandparent"){
            setYear(1582)
        }
        else if (props.title === "parent"){
            setYear(1608)
        }
        else {setYear(1622)}
    },[props.title])

    const getYear = (entryYear) => {
        if (entryYear === "Interim Years 1603 - 1604") {
            return 1603;
        }
        else {
            return parseInt(entryYear);
        }
    }

    function addEvent() {
        var updatedHistory = {...props.history};
        updatedHistory.events.push({
            year: year,
            outcomes: [outcome]
        })
        props.update([{fieldName: "history", value: updatedHistory}]);
    }

    return (
        <aside style={{margin:"5px", border: "2px solid #2F588A", backgroundColor: "#fff", borderRadius: "10px", padding: "10px", ...props.style}}>
            <div style={{margin: "10px 0px", fontSize: "20px"}}>{"Your " + props.title + "'s history"}</div>
            {props.pageState.editMode ? 
                <>
                    <div style={{display: "flex", alignItems: "center", fontSize: "16px", marginBottom: "10px"}}>
                        <div style={{margin: "0px 10px"}}>Year: </div>
                        <EditBox width="100px" number value={year} onChange={(value) => setYear(value)} placeholder="Year" />
                    </div>
                    <div style={{display: "flex", alignItems: "center", fontSize: "16px"}}>
                        <div style={{marginRight: "10px"}}>Event: </div>
                        <EditBoxMulti rows={4} value={outcome} onChange={(value) => setOutcome(value)} />
                        <Button variant="contained" color="primary" title="Add" style={{width: "140px", marginLeft: "10px"}} onClick={addEvent}>Add Event</Button>
                    </div>
                </>
            : 
            null
            }
            {props.history && props.history.events && [...props.history.events].sort(compareValues('year', 'desc')).map((entry, index) => {
                var entryYear = getYear(entry.year);
                if (entryYear >= props.startYear && entryYear < props.endYear){
                    return (
                        <div key={index}>
                            <div key={index} style={{marginBottom: "10px", fontSize: "18px"}}>{entry.year}</div>
                            {entry.outcomes.map((outcome, index) => {
                                if (outcome.indexOf("died of other causes") < 0) {
                                    return (
                                        <div key={index} style={{marginLeft: "10px", marginBottom: "5px"}}>{outcome}</div>
                                    )
                                }
                            })}
                        </div>
                    )
                }
            })}
        </aside>
    )
}

export default withPageState(EventHistory)