import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import compareValues from '../../utils/compareValues';
import RuneSpells from './runeSpells';
import {getResultType} from '../../services/dieRollService';
import { deductMagicPoints } from '../../services/magicPointsService';
import { deductRunePoints } from '../../services/runePointsService';
import { setSpellDefaults } from '../../services/spellsService';
import * as SAVE from '../../constants/save';
import RuneSpell from './runeSpell'
import { withContext } from '../Context/context';
import RunePointIncrementor from '../Controls/runePointIncrementor';
import CultHeaderBar from '../Characters/character/cultHeaderBar';

const Cult = (props) => {

    const [runeSpells, setRuneSpells] = useState([]);
    const [cult, setCult] = useState(props.cult);
    const [spellcaster, setSpellCaster] = useState(props.providerData.character);
    const [totalPoints, setTotalPoints] = useState(-1);
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.cult) {
            setCult(props.cult);
            var sorted = props.cult.runeSpells.sort(compareValues('name'));
            var results = [];
            sorted.forEach((item) => {
                if (!favouritesOnly || favouritesOnly && item.favourite) {
                    results.push(item);
                }
            })
            setRuneSpells(results);
            setSpellCaster({...props.providerData.character});
        }

    },[props.cult, props.providerData.character, favouritesOnly]);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.runeMagic.favourites)
        }
    },[props.providerData.character.configuration]);
    
    useEffect(() => {
        var points = 0;
        props.cult.runePoints.forEach(character => {
            points += character.current;
        })
        setTotalPoints(points);
    },[props.cult]);

    function processSpell(dieRoll, spellDetails, title) {
        var updates = [];

        props.processResult(dieRoll, spellDetails, title);
        var updatedCharacter = {...props.providerData.character};
        var resultType = getResultType(dieRoll.target, dieRoll.result);
        var spells = {...runeSpells};
        var magicPointsSpent = (spellDetails.boost || 0) + (spellDetails.runeSpellMagicPoints || 0)

        if (spellDetails) {
            if (spellDetails.remember) {
                spells = setSpellDefaults(spellDetails, spells);
            }
            if (! resultType.isSuccess) {
                if (resultType.name === "Fumble") {
                    var updatedCults = [...props.providerData.character.cults];
                    var updatedCult = deductRunePoints(spellDetails);
                    var index = updatedCharacter.cults.findIndex(item => item.name === props.cult.name);
                    updatedCults[index] = updateCult;
                    updates.push({fieldName: "cults", value: updatedCults});
                }
                if (magicPointsSpent > 0) {
                    updatedCharacter = deductMagicPoints(updatedCharacter, 1);
                    updates.push({fieldName: "magicPoints", value: updatedCharacter.magicPoints});
                }
            }
            else {
                if (magicPointsSpent > 0) {
                    updatedCharacter = deductMagicPoints(updatedCharacter, magicPointsSpent);
                    updates.push({fieldName: "magicPoints", value: updatedCharacter.magicPoints});
                }
                var updatedCults = [...props.providerData.character.cults];
                var updatedCult = deductRunePoints(spellDetails);
                var index = updatedCharacter.cults.findIndex(item => item.name === props.cult.name);
                updatedCults[index] = updatedCult;
                updates.push({fieldName: "cults", value: updatedCults});

                var index = updatedCharacter.runes.findIndex(rune => rune.name === spellDetails.rune);
                if (index > -1) {
                    updatedCharacter.runes[index].hasSucceeded = true;
                    updates.push({fieldName: "runes", value: updatedCharacter.runes});
                }
            }
            props.update(updates, SAVE.DELAYED);
        }
    }
        
    function setSelectedSpells(newSpells) {
        var updatedSpells = [...newSpells]
        var updatedCult = {...cult};
        updatedCult.runeSpells.forEach(spell => {
            if (spell.common) updatedSpells.push({...spell});
        })
        updatedCult.runeSpells = updatedSpells;
        updateCult(updatedCult, SAVE.NO);
    }

    function deleteCult(deletedCult, persist) {
        var updatedCults = [...props.providerData.character.cults];
        var index = updatedCults.findIndex(item => item.name === cult.name);
        if (cult.runePoints.length === 0 || (cult.runePoints.length === 1 && cult.runePoints[0].entityId === props.providerData.character.id)) {
            updatedCults.splice(index, 1);
            props.update([{fieldName: "cults", value: updatedCults}], persist);
        }
        else {
            var updatedCult = {...updatedCults[index]};
            var pointsIndex = updatedCult.runePoints.findIndex(item => item.entityId === props.providerData.character.id)
            updatedCult.runePoints.splice(pointsIndex, 1);
            props.update([{fieldName: "cults", value: updatedCults}], persist);
        }
    }

    function updateCult(updatedCult, persist) {
        var updatedCults = [...props.providerData.character.cults];
        var index = updatedCults.findIndex(item => item.name === updatedCult.name);
        if (props.spirit) {
        }
        else {
            updatedCults[index] = updatedCult;
        }
        props.update([{fieldName: "cults", value: updatedCults}], persist);
    }

    function displaySpells(spells) {
        if (spells && spellcaster) {
            return (
                <RuneSpells diceRoller={props.diceRoller} totalPoints={totalPoints} cult={cult} index={props.key} spells={spells} setSelectedSpells={setSelectedSpells} processResult={props.processResult} spellcaster={spellcaster} character={props.character} update={props.update} displayItem={props.displayItem} />
            );
        }
    }

    if (cult) {
        return(
            <>
                {React.cloneElement(props.headerBar, {...props, character: props.providerData.character, entity: spellcaster,  updateCult: updateCult,  delete: deleteCult, update: props.update})}
                {/* <CultHeaderBar cult={cult} entity={spellcaster} updateCult={updateCult} delete={deleteCult} update={props.update} /> */}
                <div style={{padding: "5px"}} >
                {props.providerData.type === "npc" ? 
                    props.pageState.editMode ? 
                        <div style={{display:"flex", fontWeight: "bold", paddingBottom: "5px"}}>
                                        <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                                        <div style={{width: "80px", textAlign: "center"}}>Stackable</div>
                                        <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                        </div>
                    : 
                    <div style={{display:"flex", padding:"2px 5px 2px 0px", fontWeight:"700", fontSize:"13px"}}>
                        <div style={{width:"154px", marginLeft :"10px"}}>Name</div>
                        <div style={{width:"80px", textAlign:"center"}}>Stackable</div>
                        <div style={{width:"30px", textAlign:"center"}}>Pts</div>
                        <div style={{width:"30px", textAlign: "center", marginLeft: "10px"}}>SR</div>
                        <div style={{width:"40px", textAlign:"center", marginLeft: "20px"}}>Cast</div>
                    </div>
                :
                    props.pageState.editMode ? 
                        <div style={{display:"flex", fontWeight: "bold", paddingBottom: "5px"}}>
                                        <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                                        <div style={{width: "80px", textAlign: "center"}}>Stackable</div>
                                        <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                        </div>
                    : 
                        <div style={{display:"flex", fontWeight: "bold", paddingBottom: "5px"}}>
                            <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                            <div style={{width: "30px", textAlign: "center"}}>Pts</div>
                            <div style={{width: "70px", textAlign: "center"}}>Stackable</div>
                            <div style={{width: "30px", textAlign: "center"}}>SR</div>
                            <div style={{width: "40px", textAlign: "right", marginLeft: "8px"}}>Cast</div>
                        </div>
                    }

                    {displaySpells(runeSpells)}
                    {props.providerData.type === "character" ? 
                        <div>
                            {props.providerData.character.boundSpirits.map((spirit, index) => {
                                if (spirit.cult === cult.name) {
                                    return (
                                        <div key={index}>
                                                {spirit.runeSpells && spirit.runeSpells.map((spell, spellIndex) => {
                                                return (
                                                    <RuneSpell diceRoller={props.diceRoller} key={spellIndex} totalPoints={totalPoints} cult={cult} storage={props.storage} spellcaster={spellcaster} character={props.character} spell={spell} processResult={processSpell} updateSpell={props.update} update={props.update} displayItem={props.displayItem} powGain={props.powGain} />
                                                )
                                            })}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    : 
                    <div>
                    {props.providerData.character.boundSpirits.map((spirit, index) => {
                        if (spirit.cult === cult.name) {
                            return (
                                <div key={index} >
                                    <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", fontSize: "13px", backgroundColor: "#2F588A", color: "#fff", width: "100%", padding: "2px"}}>
                                        <div>{spirit.name.first} spells</div>
                                        <RunePointIncrementor style={{marginRight: "5px"}} cult={{...cult}} update={updateCult} entityId={spirit.id} />
                                    </div>
                                    {spirit.runeSpells && spirit.runeSpells.map((spell, spellIndex) => {
                                        return (
                                            <RuneSpell diceRoller={props.diceRoller} key={spellIndex +100} tile={props.tile} totalPoints={totalPoints} cult={cult} storage={props.storage} spellcaster={spellcaster} character={props.character} spell={spell} processResult={processSpell} updateSpell={props.update} update={props.update} displayItem={props.displayItem} powGain={props.powGain} />
                                        )
                                    })}

                                </div>
                            )
                        }
                    })}
                </div>
            }
            </div>
            </>
        );
    }
    else {
        return null;
    }
}

export default withContext(withPageState(Cult));