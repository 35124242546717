import React, {useState, useEffect} from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import rollSound from '../../../sounds/rollD100.mp3';
import { Button } from '@mui/material';

const StatBlock = (props) => {

    const [stat, setStat] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [ones, setOnes] = useState(0);
    const [currentValue, setCurrentValue] = useState(0);

    useEffect(() => {
        setStat(props.stat);
    },[props.stat.bonus])

    useEffect(() => {
        setStat(props.stat);
        setCurrentValue(props.stat.currentValue)
    },[props.stat])

    useEffect(() => {
        if (clicked) {
            setClicked(false);
            var result = 0;
            var rolls = [];
            var ones = 0;

            for (let key in props.diceRoller.diceResults) {
                result += props.diceRoller.diceResults[key];
                rolls.push({type: 'd6', roll: props.diceRoller.diceResults[key]})
                if (props.diceRoller.diceResults[key] === 1) {
                    ones ++;
                }
            }
            setOnes(ones);

            result += stat.modifier;
            result += currentValue;
            if (props.builder.homeland.characteristics) {
                props.builder.homeland.characteristics.forEach(characteristic => {
                    if (characteristic.name === stat.name) {
                        result += characteristic.bonus;
                    }
                });
            }
            var updatedStat = {...stat};
            updatedStat.baseValue = updatedStat.currentValue = result;
            setCurrentValue(result);
            setStat(updatedStat);
            props.update(updatedStat, result, []);
        }
    },[props.diceRoller.diceResults])

    
    function incrementStat(value) {
        var updatedStat = {...stat};
        if (updatedStat.currentValue + value <= 18) {
            if (updatedStat.currentValue + value >= updatedStat.baseValue) {
                updatedStat.currentValue += value;
                setStat(updatedStat);
                props.increment(updatedStat, value);
            }
        }
    }
    function rollDice(event, stat) {
        var dice = {d4: 0, d6: stat.quantity, d8: 0, d10: 0, d20: 0}
        setClicked(true);
        event.stopPropagation();
        props.diceRoller.setDice(dice)
        props.setRolling(stat.name);
    }

    function rerollOneDiceOnly(event) {
        var dice = {d4: 0, d6: ones, d8: 0, d10: 0, d20: 0}
        setClicked(true);
        event.stopPropagation();
        props.diceRoller.setDice(dice)
        setCurrentValue(currentValue - ones - (stat.modifier || 0));
    }

    if (!stat) {return null;}
    if (stat.baseValue) {
        return (
            <div key={stat.name} style={{minWidth: "140px"}}>
                <div>
                    <div style={{fontSize: "19px", backgroundColor: "#fff", width: "100%", color: "#121312", display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center", border: "5px solid #2F588A", borderRadius: "10px", fontWeight:"bold"}}>
                        <div style={{width: "58px", height: "70px", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center"}}>
                            <div>{stat.name}</div>
                            {stat.currentValue + (stat.bonus || 0)}
                        </div>
                    </div>
                    {props.rerollOnes && ones && props.rolling === stat.name ? 
                    <Button onClick={(event) => rerollOneDiceOnly(event)} size="large" variant="contained" style={{margin: "5px", backgroundColor: "#851113", color: "#fff", borderRadius: "10px"}} >Reroll 1's</Button>
                    : null}
                    </div>
                { props.enableIncrement ?
                    <div key={stat.name} style={{fontSize: "26px", marginLeft: "6px", display:"flex", justifyContent: "center", alignItems: "center", backgroundColor: "#cdcdcd", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", height: "50px"}}>
                        {stat.currentValue > stat.baseValue ? 
                        <div>
                            <RemoveCircleOutlineIcon onClick={() => incrementStat(-1)} style={{width: "30px", height: "30px", color: "#851113"}}/>
                        </div>
                        : 
                        <div style={{width: "30px"}}></div>
                        }
                        {stat.currentValue < 21 && props.pointsRemaining > 0 ? 
                        <div>
                            <AddCircleOutlineIcon onClick={() => incrementStat(1)} style={{width: "30px", height: "30px", color: "#2F588A"}}/>
                        </div>
                        : 
                        <div style={{width: "30px"}}></div>
                        }
                    </div>
                : null
                }
            </div>
        )
    }
    else {
        return (
            <div key={stat.name} style={{minWidth: "140px"}}>
                <Button onClick={(event) => rollDice(event, stat)} size="large" variant="contained" style={{padding: "20px", margin: "5px", fontSize: "18px", border: "5px solid #2F588A", borderRadius: "10px"}} color="primary">Roll {stat.name}</Button>
            </div>
        )
    }

}
export default StatBlock;