import React from 'react';
import { withPageState } from '../../Context/PageState';
import globalStyles from '../../Styles/styles.module.css';
import DiceButton from '../../Buttons/diceButton';

const SpiritSpellRow = (props) => {

    function displayItem() {
        props.displayItem({type: "Spirit Spell", item: props.item})
    }

    function processResult(roll) {
        return props.processResult(roll, roll.item.points, 0)
    }
   
    const classNameGenerator = (...classes)=>{
        return classes.join(" ");
    }

    if (props.pageState.editMode) {
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div id={props.item.name} style={{width:"190px"}}>{props.item.name}</div>
                <div id={props.item.name} style={{width:"80px", textAlign: "center"}}>{props.item.variable ? "Yes" : "No"}</div>
                <div id={props.item.name} style={{width:"70px", textAlign: "center"}}>{props.item.points}</div>
                </div>
            )
    }
    else {
        return (
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", margin: "2px"}} onClick={displayItem} >
                <div style={{width: "154px"}}>{props.item.name}</div>
                <div style={{width: "80px", textAlign: "center"}}>{props.item.variable ? "Yes": "No"}</div>
                <div style={{width: "30px", textAlign: "center"}}>{props.item.points}</div>
                <div style={{width: "30px", textAlign: "center", marginLeft: "10px"}}>{props.item.strikeRank}</div>
                <div style={{border: "1px solid #2F588A", borderRadius: "3px", width: "40px", textAlign: "right", cursor: "pointer", marginLeft: "20px", height: "18px"}}>
                    <DiceButton type="Spirit Spell" diceRoller={props.diceRoller} item={props.item} processResult={processResult} disabled={props.disabled} >
                        <ButtonValue />
                    </DiceButton>
                </div>
            </div>

        )
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}


export default withPageState(SpiritSpellRow);