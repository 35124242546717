import { formatEnchantment } from './/enchantmentsService';
import { isEqual } from 'lodash';
import { calculateDamageBonus } from './/attributeService';
import { getCategoryModifiers } from './/skillsService';
import { calculateStrikeRanks } from './/attributeService';
import { calculateHitPoints } from './/hitPointsService';
import { calculateMaxEncumbrance } from './/attributeService';
import { calculateSpiritCombatDamage } from './/attributeService';
import { saveCharacter } from './characterService';
import { getResultType } from './dieRollService';
import { deductMagicPoints } from './magicPointsService';
import { deductRunePoints } from './runePointsService';
import * as WeaponData from '../data/weapons.json';
import { v4 as uuidv4 } from 'uuid';
import { getRuneSpell, getSpiritSpell } from './dataService';

const allWeapons = {...WeaponData};

export function processRuneSpell(dieRoll, spellDetails, character, firebase, targetType, campaign) {
    var resultType = getResultType(dieRoll.target, dieRoll.result);
    var updates = [];
    if (spellDetails && spellDetails.spell) {
        var effects = [];
        let spell = getRuneSpell(spellDetails.spell.parentId, campaign)
        if (spell) {
            effects = spell.effects;
        }
        var updatedCults = [...character.cults];
        var index = -1;
        
        var magicPointsSpent = (spellDetails.boost || 0) + (spellDetails.runeSpellMagicPoints || 0)
        if (resultType.isSuccess) {
            if ((! spellDetails.ignoreEffects) && spellDetails.targetCharacter) { // the spell has enchantments to add
                var updatedCharacter = {...spellDetails.targetCharacter}
                if (updatedCharacter.id !== character.id) {
                    firebase[targetType](updatedCharacter.id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            var character = {...doc.data()};
                            character.id = doc.id;
                            updates = applySpellEffects(character, spellDetails, effects)
                            saveCharacter(firebase, updatedCharacter.id, updates, targetType);
                        }
                    });
                }
                else {
                    updates = applySpellEffects(updatedCharacter, spellDetails, effects)
                }
            }
            if (magicPointsSpent) {
                var spellcaster = deductMagicPoints(character, spellDetails.spellcaster, magicPointsSpent);
                updates.push({fieldName: "magicPoints", value: spellcaster.magicPoints});
            }
            if (spellDetails.cult ) { //not a matrix
                var updatedCult = resultType.name === "Critical" ? {...spellDetails.cult} : deductRunePoints(spellDetails);
                index = updatedCults.findIndex(item => item.id === spellDetails.cult.id);
                if (index > -1) {
                    if (spellDetails.remember) {
                        var updatedSpell = setSpellDefaults(spellDetails);
                        let spellIndex = updatedCult.runeSpells.findIndex(obj => obj.id === updatedSpell.id)
                        updatedCult.runeSpells[spellIndex] = updatedSpell;
                    }
                    updatedCults[index] = updatedCult;
                    updates.push({fieldName: "cults", value: updatedCults});
                }
            }
        }
        else if (spellDetails.cult ){ //not a matrix

            if (resultType.name === "Fumble") {
                var updatedCult = deductRunePoints(spellDetails);
                index = updatedCults.findIndex(item => item.id === spellDetails.cult.id);
                if (index > -1) {
                    if (spellDetails.remember) {
                        var updatedSpell = setSpellDefaults(spellDetails);
                        let spellIndex = updatedCult.runeSpells.findIndex(obj => obj.id === updatedSpell.id)
                        updatedCult.runeSpells[spellIndex] = updatedSpell;
                    }
                    updatedCults[index] = updatedCult;
                    updates.push({fieldName: "cults", value: updatedCults});
                }
                if (magicPointsSpent > 0 && spellDetails.deductMP) {
                    spellcaster = deductMagicPoints(spellcaster, magicPointsSpent);
                    updates.push({fieldName: "magicPoints", value: spellcaster.magicPoints});
                }
            }
            else {
                if (magicPointsSpent > 0 ) {
                    var updatedCharacter = deductMagicPoints(character, 1);
                    updates.push({fieldName: "magicPoints", value: updatedCharacter.magicPoints});
                }
                if (index > -1) {
                    let updatedCult = {...spellDetails.cult}
                    if (spellDetails.remember) {
                        var updatedSpell = setSpellDefaults(spellDetails);
                        let spellIndex = updatedCult.runeSpells.findIndex(obj => obj.id === updatedSpell.id)
                        updatedCult.runeSpells[spellIndex] = updatedSpell;
                    }
                    updatedCults[index] = updatedCult;
                    updates.push({fieldName: "cults", value: updatedCults});
                }
            }
        }
    }
    return updates;
}

export function processSpiritSpell(dieRoll, spellDetails, character, firebase, targetType, campaign)  {
    var resultType = getResultType(dieRoll.target, dieRoll.result);
    var updates = [];
    if (spellDetails && spellDetails.spell) {
        var effects = [];
        let spell = getSpiritSpell(spellDetails.spell.parentId, campaign)
        if (spell) {
            effects = spell.effects;
        }
        if (resultType.isSuccess) {
            if ((! spellDetails.ignoreEffects) && spellDetails.targetCharacter) { // the spell has enchantments to add
                var updatedCharacter = {...spellDetails.targetCharacter}
                if (updatedCharacter.id !== character.id) {
                    firebase[targetType](updatedCharacter.id)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            var character = {...doc.data()};
                            character.id = doc.id;
                            updates = applySpellEffects(character, spellDetails, effects)
                            saveCharacter(firebase, updatedCharacter.id, updates, targetType);
                        }
                    });
                }
                else {
                    updates = applySpellEffects(updatedCharacter, spellDetails, effects)
                }
            }
            if (! spellDetails.ignoreMPLoss) {
                var magicPointCost = spellDetails.boost + (spellDetails.type === "spirit" ? spellDetails.points : 0); 
                var spellcaster = deductMagicPoints(character, spellDetails.spellcaster, magicPointCost);
                updates.push({fieldName: "magicPoints", value: spellcaster.magicPoints});
            }
        }
        if (spellDetails.remember) {
            var updatedSpell = setSpellDefaults(spellDetails);
            let spellIndex = character.spiritSpells.findIndex(obj => obj.id === updatedSpell.id)
            character.spiritSpells[spellIndex] = updatedSpell;
            updates.push({fieldName: "spiritSpells", value: character.spiritSpells});
        }
    }
    return updates;
};

export function applySpellEffects(updatedCharacter, spellDetails, effects)  {
    var updates = [];
    effects.forEach(effect => {
        if (effect.name === "addWeapon") {
            var updatedWeapons = [...updatedCharacter.weapons];
            var index = updatedWeapons.findIndex(obj => obj.parentId === effect.item.id);
            if (index < 0) {
                var weaponIndex = allWeapons.data.findIndex(obj => obj.id === effect.item.id);
                if (weaponIndex > -1) {
                    var newWeapon = allWeapons.data[weaponIndex];
                    updatedWeapons.push(
                        {
                            id: uuidv4(),
                            parentId: effect.item.id,
                            weaponSkillId : effect.weaponSkill,
                            name: newWeapon.name,
                            class: newWeapon.class,
                            damage: {...newWeapon.damage},
                            equipped: true,
                            hidden: false,
                            hitPoints: {...newWeapon.hitPoints},
                            sr: newWeapon.sr,
                            title: newWeapon.title || newWeapon.name,
                            type: newWeapon.type,
                        }
                    )
                }
            }
            else {
                updatedWeapons[index].hidden = false;
            }
            updates.push({fieldName: "weapons", value: updatedWeapons})
            updatedCharacter.weapons = updatedWeapons;

            index = updatedCharacter.weaponSkills.findIndex(obj => obj.parentId === effect.weaponSkill);
            if (index < 0) {
                var updatedSkills = [...updatedCharacter.weaponSkills]
                var skillIndex = allWeapons.data.findIndex(obj => obj.id === effect.weaponSkill);
                if (skillIndex > -1) {
                    var newWeapon = allWeapons.data[skillIndex];
                    updatedSkills.push(
                        {
                            id: uuidv4(),
                            category: newWeapon.category,
                            hasSucceeded: false,
                            name: newWeapon.name,
                            parentId: effect.weaponSkill,
                            skillCategory: "Manipulation",
                            value: newWeapon.baseValue
                        }                    
                    )
                    updates.push({fieldName: "weaponSkills", value: updatedSkills})
                    updatedCharacter.weaponSkills = updatedSkills;
                }
            }
        }
    })
    var enchantment = formatEnchantment(updatedCharacter, spellDetails, effects);
    var updatedEnchantments = [...updatedCharacter.enchantments]
    var existing = {};
    if (spellDetails.selection) {
        existing = updatedEnchantments.findIndex((item => item.spell === spellDetails.spell.name && item.target === spellDetails.selection.title)); // get any existing enchantment
    }
    else {
        existing = updatedEnchantments.findIndex((item => item.spell === spellDetails.spell.name))
    }
    if (existing > -1 && updatedEnchantments[existing].points > spellDetails.points) {
        return updates;
    }
    if (existing > -1) {
        updatedEnchantments[existing] = enchantment;
    }
    else {
        updatedEnchantments.push(enchantment);
    }
    updates.push({fieldName: "enchantments", value: updatedEnchantments});

    var updatedCharacteristics = updatedCharacter.characteristics;
    var result = SpellService.updateStats(updatedCharacter.characteristics, enchantment);

    if (result.updated) {
        updatedCharacteristics = result.updatedStats;
        updates.push({fieldName: "characteristics", value: updatedCharacteristics});

        var newDamageBonus = calculateDamageBonus(updatedCharacteristics)
        if (! isEqual(updatedCharacter.damageBonus, newDamageBonus)) {  
            updates.push({fieldName: "damageBonus", value: newDamageBonus})
        }

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (spellDetails.spell.name === "Coordination") {
            updatedModifiers = SpellService.processCoordination(updatedCharacteristics, updatedModifiers)
        }
        else {
            updatedCharacter.enchantments.forEach(item => {
                if (item.name === "Coordination"){
                    updatedModifiers = SpellService.processCoordination(updatedCharacteristics, updatedModifiers)
                }
            })
        }
        if (! isEqual(updatedCharacter.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedHitPoints = calculateHitPoints(updatedCharacteristics, updatedCharacter.hitPoints);
        if (! isEqual(updatedCharacter.hitPoints, updatedHitPoints)) {
            updates.push({fieldName: "hitPoints", value: updatedHitPoints})
        }

        var updatedStrikeRanks = calculateStrikeRanks(updatedCharacteristics);
        if (! isEqual(updatedCharacter.strikeRanks, updatedStrikeRanks)) {
            updates.push({fieldName: "strikeRanks", value: updatedStrikeRanks})
        }

        var updatedSpiritCombat = {...updatedCharacter.spiritCombat};
        updatedSpiritCombat.damage = calculateSpiritCombatDamage(updatedCharacteristics);
        if (! isEqual(updatedCharacter.spiritCombat, updatedSpiritCombat)) {
            updates.push({fieldName: "spiritCombat", value: updatedSpiritCombat})
        }

        var updatedEncumbrance = calculateMaxEncumbrance(updatedCharacteristics);
        if (! isEqual(updatedCharacter.maxEnc, updatedEncumbrance)) {
            updates.push({fieldName: "maxEnc", value: updatedEncumbrance});
        }
    }
    return updates;
};


const SpellService = {

    processSpell :function(dieRoll, spellDetails, character, firebase, targetType)  {
        var resultType = getResultType(dieRoll.target, dieRoll.result);
        var updates = [];
        var type = spellDetails.type || "spirit";

        if (spellDetails) {
            if (resultType.isSuccess) {
                if ((!spellDetails.ignoreEffects) && spellDetails.targetCharacter) { // the spell has enchantments to add
                    var updatedCharacter = {...spellDetails.targetCharacter}
                    if (updatedCharacter.id !== character.id) {
                        firebase[targetType](updatedCharacter.id)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                var character = {...doc.data()};
                                character.id = doc.id;
                                updates = applySpellEffects(character, spellDetails)
                                saveCharacter(firebase, updatedCharacter.id, updates, targetType);
                            }
                        });
                    }
                    else {
                        updates = applySpellEffects(updatedCharacter, spellDetails)
                    }
                }
                if (spellDetails.deductMP) {
                    var magicPointCost = spellDetails.boost + (spellDetails.type === "spirit" ? spellDetails.points : 0); 
                    var spellcaster = deductMagicPoints(character, spellDetails.spellcaster, magicPointCost);
                    updates.push({fieldName: "magicPoints", value: spellcaster.magicPoints});
                }
                if ((type === "rune") && spellDetails.cult ){
                    var updatedCult = deductRunePoints(spellDetails);
                    var updatedCults = [...character.cults];
                    var index = updatedCults.findIndex(item => item.name === spellDetails.cult.name);
                    if (index > -1) {
                        updatedCults[index] = updatedCult;
                        updates.push({fieldName: "cults", value: updatedCults});
                    }
                }
            }
            else if ((type === "rune") && spellDetails.cult ){

                if (resultType.name === "Fumble") {
                    var updatedCults = [...character.cults];
                    var updatedCult = deductRunePoints(spellDetails);
                    var index = updatedCults.findIndex(item => item.name === spellDetails.cult.name);
                    if (index > -1) {
                        updatedCults[index] = updatedCult;
                        updates.push({fieldName: "cults", value: updatedCults});
                    }
                    if (spellDetails.boost > 0 && spellDetails.deductMP) {
                        spellcaster = deductMagicPoints(spellcaster, spellDetails.boost);
                        updates.push({fieldName: "magicPoints", value: spellcaster.magicPoints});
                    }
                    }
                else if (spellDetails.boost > 0 && spellDetails.deductMP) {
                    var updatedCharacter = deductMagicPoints(character, 1);
                    updates.push({fieldName: "magicPoints", value: updatedCharacter.magicPoints});
                }
            }
        }
        return updates;
    },

    processCoordination: function(updatedCharacteristics, updatedModifiers) {
        var totalStat = updatedCharacteristics.DEX.baseValue + (updatedCharacteristics.DEX.statBonus || 0) + (updatedCharacteristics.DEX.enchantmentBonus || 0);
        if ([8,12,16,20,24].indexOf(totalStat) > -1) {
            updatedModifiers.Agility += 5;
            updatedModifiers.Manipulation += 5;
            updatedModifiers.Stealth += 5;
        }
        return updatedModifiers;
    },
    
    updateStats: function(characteristics, enchantment) {
        var statEnchantment = {};
        var updatedCharacteristics = {...characteristics};
        var statsUpdated = false;
        enchantment.effects.forEach(effect => {
            if (effect.name === "statMultiplier" || effect.name === "statModifier") {
                statsUpdated = true;
                var bonus = 0;
                var stat = updatedCharacteristics[effect.item.id];
                if (!stat.enchantments) stat.enchantments = [];
                if (!stat.enchantmentBonus) stat.enchantmentBonus = 0;

                if (effect.name === "statMultiplier" ) {
                    bonus = (stat.baseValue + (stat.statBonus || 0)) * (effect.value -1);
                    statEnchantment = {name: enchantment.name, value: bonus};
                }
                else if (effect.name === "statModifier") {
                    bonus = effect.value;
                    statEnchantment = {name: enchantment.name, value: bonus};
                }
                stat.enchantments.push(statEnchantment)
                stat.enchantmentBonus += bonus;
            }
        })
        var result = {updatedStats: updatedCharacteristics, updated: statsUpdated}
        return result;
    }

}
export default SpellService;


export function setSpellDefaults(spellDetails) {
    var updatedSpell = {id: spellDetails.spell.id, parentId: spellDetails.spell.parentId, name: spellDetails.spell.name, points: spellDetails.spell.points, target: spellDetails.spell.target };
    if (spellDetails.spell.variant) {updatedSpell.variant = spellDetails.spell.variant}
    if (spellDetails.spell.variable) {updatedSpell.variable = spellDetails.spell.variable}
    if (spellDetails.spell.stackable) {updatedSpell.stackable = spellDetails.spell.stackable}
    if (spellDetails.spell.runes) {updatedSpell.runes = spellDetails.spell.runes}
    if (spellDetails.remember) {
        updatedSpell.default = {
            characterId: spellDetails.targetCharacter.id,
        };
        if (spellDetails.selection) {
            updatedSpell.default.selection = spellDetails.selection.title;
        }
    }
    return updatedSpell;
}