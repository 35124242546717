import React, {useState, useEffect} from 'react';
import StrengthTile from './strengthTile';
import ConstitutionTile from './constitutionTile';
import SizeTile from './sizeTile';
import DexterityTile from './dexterityTile';
import IntelligenceTile from './intelligenceTile';
import PowerTile from './powerTile';
import CharismaTile from './charismaTile';
import { withContext } from '../Context/context';
import { getStatValue } from '../../services/attributeService';
import CharacteristicTileContextMenuPanel from './characteristicTileContextMenuPanel';

const CharacteristicTileContextMenu = (props) => {

    return (
        <>
        {props.panelItem.item.name === "STR" ? 
           <StrengthTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </StrengthTile>
       : 
       null}
        {props.panelItem.item.name === "CON" ? 
           <ConstitutionTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </ConstitutionTile>
       : 
       null}
       {props.panelItem.item.name === "SIZ" ? 
           <SizeTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </SizeTile>
       : 
       null}
       {props.panelItem.item.name === "DEX" ? 
           <DexterityTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </DexterityTile>
       : 
       null}
       {props.panelItem.item.name === "INT" ? 
           <IntelligenceTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </IntelligenceTile>
       : 
       null}
       {props.panelItem.item.name === "POW" ? 
           <PowerTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </PowerTile>
       : 
       null}
       {props.panelItem.item.name === "CHA" ? 
           <CharismaTile update={props.update} entity={props.entity} close={props.close}>
                <CharacteristicTileContextMenuPanel npcName={props.npcName} diceRoller={props.diceRoller} panelItem={props.panelItem} processResult={props.processResult} pinned={props.pinned} setPinned={props.setPinned} />
           </CharismaTile>
       : 
       null}
   </>
)
}
export default withContext(CharacteristicTileContextMenu);