import React, { useState } from 'react';
import globalStyles from '../Styles/styles.module.css';
import CancelIcon from '../../icons/cancel-icon.svg';
import DialogBox from '../Dialogs/dialogBox';
import CancelEditsDialog from '../Dialogs/cancelEditsDialog';

export default function CancelButton(props) {

    const [open, setOpen] = useState(false);

    function confirm(loseChanges) {
        if (loseChanges) {
            props.cancelChanges();
        }
        setOpen(false);
    }
   
    return (
        <>
            <div className={globalStyles.floatingButton} onClick={() => setOpen(true)} style={{width:"50px", cursor: "pointer", height:"50px", borderRadius:"25px", backgroundColor:"#851113", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                <img style={{width:"40px"}} src={CancelIcon} title="Cancel changes" />
            </div>
                <CancelEditsDialog type={props.type} open={open} confirm={confirm} />
        </>
    );
}