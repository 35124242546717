import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css'
import { withPageState } from '../Context/PageState';
import compareValues from '../../utils/compareValues';
import WeaponTile from './weaponTile';
import { withContext } from '../Context/context';

const Weapons = (props) => {
    const [currentWeapons, setCurrentWeapons] = useState([]);
    const [displayWeapons, setDisplayWeapons] = useState([]);
    const [favourite, setFavourite] = useState(false);

    useEffect(() => {
        if (props.entity.configuration) {
            setFavourite(props.entity.configuration.weapons.favourites)
        }
        defaultSort();
    },[props.entity.weapons, props.entity.enchantments, props.pageState.editMode, favourite]);

    function update(item, persist) {
        var updatedWeapons = [...props.entity.weapons];
        var index = updatedWeapons.findIndex(weapon => weapon.id === item.id);
        if (index > -1) {
            updatedWeapons[index] = item;
            props.update([{fieldName: "weapons", value: updatedWeapons}], persist);
        }
    }

    function updateSkill(skill, persist) {
        var updatedSkills = [...props.entity.weaponSkills];
        var index = updatedSkills.findIndex(item => item.name === skill.name);
        if (index > -1) {
            updatedSkills[index] = skill;
            props.update([{fieldName: "weaponSkills", value: updatedSkills}], persist);
        }
    }

    function defaultSort() {
        var updatedWeapons = [];
        props.entity.weapons.forEach(weapon => {
            var updatedWeapon = {...weapon};
            var index = props.entity.weaponSkills.findIndex(skill => skill.name === weapon.name);
            updatedWeapon.value = props.entity.weaponSkills[index].value + (props.entity.categoryModifiers.Manipulation || 0);
            updatedWeapon.sortKey = updatedWeapon.class + (999-updatedWeapon.value);
            updatedWeapons.push(updatedWeapon);
        })
        if (props.pageState.editMode) {
            setCurrentWeapons(updatedWeapons)
        }
        else {
            var sorted = updatedWeapons.sort(compareValues('sortKey', "asc"));
            setCurrentWeapons(sorted);
            var results = [];
            sorted.forEach((item) => {
                if (!favourite || favourite && item.favourite) {
                    results.push(item);
                }
            });
            setDisplayWeapons(results);
        }
    }

    const updateFavourites = (updates, newValue) => {
        setFavourite(newValue);
        props.update(updates)
    }

    return(
        <div style={{}}>
            {props.type === "familiar" ? 
                <>
                    <div style={{display:"flex", fontWeight: "bold", padding: "5px"}}>
                        <div style={{width: "120px"}}>Weapon</div>
                        <div style={{width: "30px", textAlign: "center"}}>SR</div>
                        <div style={{width: "80px", textAlign: "center"}}>Attack</div>
                        <div style={{width: "80px", textAlign: "center"}}>Damage</div>
                    </div>
                </>
            : 
            props.providerData.type === "character" ? 
                <>
                    <div style={{display:"flex", fontWeight: "bold", padding: "5px"}}>
                        <div style={{width: "160px"}}>Weapon</div>
                        <div style={{width: "60px", textAlign: "center"}}>SR</div>
                        <div style={{width: "60px", textAlign: "center"}}>Attack</div>
                        <div style={{width: "60px", textAlign: "center", marginLeft: "10px"}}>Parry</div>
                        <div style={{width: "90px", textAlign: "center"}}>Damage</div>
                    </div>
                </>
            : 
                <>
                    <div style={{display:"flex", fontWeight: "bold", padding: "0px 5px"}}>
                        <div style={{width: "95px"}}>Weapon</div>
                        <div style={{width: "30px", textAlign: "center"}}>SR</div>
                        <div style={{width: "90px", textAlign: "center"}}>Attack / Parry</div>
                        <div style={{width: "80px", textAlign: "center"}}>Damage</div>
                        <div style={{width: "80px", textAlign: "center"}}>HP</div>
                    </div>
                </>
            }   
            {props.pageState.editMode ? 
                <div style={props.style}>
                    {currentWeapons && currentWeapons.map((item, index) => {
                        return (
                            <WeaponTile key={index} diceRoller={props.diceRoller} item={item} update={update} updateSkill={updateSkill} processResult={props.processResult} processDamage={props.processDamage} entity={props.entity}>
                                    {React.cloneElement(props.tile, {...props, displayItem: props.displayItem, item: item, update: update, updateSkill:updateSkill, entity:props.entity })}
                            </WeaponTile>
                        )
                    })}
                </div>
            : 
                <div className={globalStyles.panel} style={{...props.style}}>
                    {displayWeapons && displayWeapons.map((item, index) => {
                        if (item.equipped) {
                            return (
                                <WeaponTile diceRoller={props.diceRoller} key={index} item={item} update={update} updateSkill={updateSkill} processResult={props.processResult} processDamage={props.processDamage} entity={props.entity} displayItem={props.displayItem} >
                                    {React.cloneElement(props.tile, {...props, displayItem: props.displayItem, item: item, processResult: props.processResult, update: update, updateSkill:updateSkill, processDamage:props.processDamage, entity:props.entity })}
                                </WeaponTile>
                            )
                        }
                    })}
                </div>
            }
        </div>
    );
}

export default withPageState(withContext(Weapons));