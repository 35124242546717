import React, {useState, useEffect} from 'react';
import { withFirebase } from '../Firebase';
import EditBox from '../Controls/editBoxSingle';
import Button from '@mui/material/Button';

const AccountSettings = (props) => {

    const [user, setUser] = useState(null);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        props.firebase
        .user(props.authUser.uid)
        .get()
        .then(function(doc) {
            if (doc.exists) {
                var item = {...doc.data()};
                item.id = doc.id;
                setUser(item);
            }
        })
    },[])

    useEffect(() => {
        if (user && user.email && user.firstName) {
            setDisabled(false)
        }
        else {setDisabled(true)}
    }, [user])

    function handleChange(field, value) {
        var updatedUser = {...user};
        updatedUser[field] = value;
        setUser(updatedUser);
    } 

    function saveChanges() {
        props.firebase
        .user(props.authUser.uid)
        .set({...user})
        .then( function() {
            if (user.email !== props.authUser.email) {
                props.firebase.doEmailUpdate(props.authUser.email, user.email, user.password);
            }
            props.close()
        });
    }

    if (user) {
        return (
			<div style={{padding: "5px"}}>
				<div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
					<EditBox label="First name:" labelMargin="30px" value={user.firstName} width="260px" onChange={(value) => handleChange("firstName", value)} />
				</div>
				<div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px"}}>
					<EditBox  label="Email address:" value={user.email} onChange={(value) => handleChange("email", value)} width="260px" />
				</div>
                {user.email === props.authUser.email ? 
                null
                : 
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px"}}>
                        <EditBox labelMargin="41px" type="password" label="Password:" value={user.password || ""} onChange={(value) => handleChange("password", value)} width="260px" />
                    </div>
                }
                <div style={{margin: "10px 0px", width: "100%", display:"flex", flexDirection: "row-reverse"}}>
                    <Button disabled={disabled} color="primary" variant='contained' onClick={saveChanges}>Save changes</Button>
                </div>
			</div>
        )
    }
    else {
        return null;
    }
}

export default withFirebase(AccountSettings);