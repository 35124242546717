import React from 'react';
import {withContext} from '../Context/context';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateStrikeRanks } from '../../services/attributeService';
import { isEqual } from 'lodash';
import { statEnchantments } from '../../services/enchantmentsService';

const DexterityTile = (props) => {

    const statName = "DEX";

    function update(characteristic, save) {

        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics[statName] = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedStrikeRanks = calculateStrikeRanks(updatedCharacteristics);
        if (! isEqual(props.entity.strikeRanks, updatedStrikeRanks)) {
            updates.push({fieldName: "strikeRanks", value: updatedStrikeRanks})
        }
        props.update(updates, save);
    }

    var enchantmentModifier = statEnchantments(props.entity, statName)
    return (
        <>
            {React.cloneElement(props.children, {...props, update: update, enchantmentModifier: enchantmentModifier})}
        </>  
    )
}

export default withContext(DexterityTile)