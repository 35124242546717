import * as allWeapons from '../../data/weapons.json';
import { v4 as uuidv4 } from 'uuid';

const weaponsData = {...allWeapons};

  const generateWeapons = (weaponsList, experience, characteristics, categoryModifier) => {
    var generatedWeapons = [];
    var weaponSkills = [];

    if (weaponsList && weaponsList.length > 0) {
        weaponsList.forEach((weapon) => {
            var newWeapon; 
            if (weapon.shield) {
                newWeapon = generateShield(weapon, characteristics);
            }
            else if (weapon.alternates) {
                newWeapon = generateAlternateWeapon(weapon, generatedWeapons);
            }
            else {
                newWeapon = generateNewWeapon(weapon)
            }
            if (newWeapon) {
                var weaponSkill = generateWeaponSkill(newWeapon, experience, weapon.primary, weapon.baseValue, categoryModifier);
                weaponSkills.push(weaponSkill);
                generatedWeapons.push(newWeapon);
            }
        });
    }
    return {weapons: generatedWeapons, skills: weaponSkills};
  }

const generateWeaponSkill = (newWeapon, experience, primary, baseValue, categoryModifier) => {
    var weaponSkill = {
        id: uuidv4(),
        name: newWeapon.name,
        parentId: newWeapon.parentId,
        skillCategory: "Manipulation",
        value:(baseValue || 20) + experience.bonus,
    }
    var multiplier = Math.ceil(Math.random()*(3));
    if (primary) {
        multiplier += 3;
    }
    else {
        multiplier += Math.ceil(Math.random()*(3));
    }
    weaponSkill.value += (multiplier * 5);
    if ((weaponSkill.value + categoryModifier) > experience.maxSkill) {
        {weaponSkill.value = experience.maxSkill - categoryModifier}
    }
    return weaponSkill;
}

  const generateNewWeapon = (weapon) => {
    var generatedNumber = Math.ceil(Math.random()*(100));
    if (generatedNumber <= weapon.possession) {
        var index = weaponsData.data.findIndex(obj => obj.id === weapon.parentId)
        if (index > -1) {
            var selectedWeapon = weaponsData.data[index];
            var newWeapon = {
                id: uuidv4(),
                parentId: weapon.parentId, 
                name: weapon.name,
                title: weapon.name,
                class: selectedWeapon.class,
                damage: {...selectedWeapon.damage},
                equipped: true,
                hitPoints: {...selectedWeapon.hitPoints},
                sr: selectedWeapon.sr,
            };
            return newWeapon
        }
    }
  }

const generateShield = (shields, characteristics) => {
    var newShield;

    shields.weapons.forEach(shield => {
        if (!newShield) {
            if (characteristics.STR.baseValue >= shield.minSTR) {
                newShield = generateNewWeapon(shield);
            }
        }
    })
    return newShield;
}

const generateAlternateWeapon = (alternates, generatedWeapons) => {
    var newWeapon; 

    alternates.weapons.forEach(weapon => {
        if (!newWeapon) {
            if (generatedWeapons.findIndex(obj => obj.parentId === weapon.parentId) < 0) {
                newWeapon = generateNewWeapon(weapon)
            }
        }
    })

    return newWeapon;
}

export default generateWeapons;
