import React, { useState, useEffect } from 'react';
import compareValues from '../../../utils/compareValues';
import RuneSpellPanel from '../../Magic/runeSpellPanel';
import { getRuneSpell } from '../../../services/dataService';

const CultSpells = (props) => {

    const [selectedCult, setSelectedCult] = useState();
    const [runeSpells, setRuneSpells] = useState([]);

    useEffect(() => {
        if (props.cult) {
            setSelectedCult({...props.cult});
            var spells = [];
            props.cult.runeSpells.forEach(runeSpell => {
                var newSpell = {name: runeSpell.name, parentId: runeSpell.parentId};
                if (!newSpell.id) {
                    newSpell.id = newSpell.parentId;
                }
				if (runeSpell.variant) {
					newSpell.variant = runeSpell.variant;
				}
                const spellDetails = getRuneSpell(runeSpell.parentId, props.campaign)
				newSpell.points = spellDetails.points;
				newSpell.stackable = spellDetails.stackable;
				newSpell.runes = spellDetails.runes;
				newSpell.description = spellDetails.description;
				newSpell.range = spellDetails.range;
				newSpell.duration = spellDetails.duration;
				spells.push(newSpell);

            })
            setRuneSpells(spells.sort(compareValues('name')));
            props.isComplete(props.cult.selectedRuneSpells.length >= 3);
        }
    },[props.cult]);

    function updateRuneSpells(selectedSpells) {
        props.update(selectedSpells);
    }

    if (selectedCult) {
        return (
            <>
                <div style={{backgroundColor: "#cdcdcd", border: "2px solid #2F588A", borderRadius: "10px", padding: "20px 5px", width: "99%"}}>
                    <div style={{padding:"0px 10px 20px", fontSize: "20px", display: "flex"}}>
                        <div >Select three Rune Magic spells</div>
                        <div style={{marginLeft: "10px"}}>{"(spells remaining: " + (3-(props.cult.selectedRuneSpells.length || 0)) + ")"}</div>
                    </div>
                    <RuneSpellPanel campaign={props.campaign} cult={props.cult} availableSpells={runeSpells} characterRunes={props.characterRunes} currentSpells={props.cult.selectedRuneSpells} setSpells={()=> {}} availableSpellCount={3} select={updateRuneSpells} />
                </div>
            </>
        );
    }
    else {
        return null;
    }
}

export default CultSpells;