import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

export default function ForgotPasswordDialog(props) {

  const [email, setEmail] = useState('');
  const [errorMsg, setErrorMsg] = useState();

  const handleOkClick = event => {
    const userEmail = email

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(userEmail) ) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
    }
    else {
      setErrorMsg({message: "Invalid email address"})
      return
        // invalid email, maybe show an error to the user.
    }

    props.firebase
      .doPasswordReset(userEmail)
      .then(() => {
        setEmail('');
      })
      .catch(error => {
        console.log({ error });
      });

    event.preventDefault();
    props.closeDialog(email);
  };

  const handleChange = event => {
    setEmail(event.target.value);
  };

  const style = {
    borderRadius: 3,
    boxSizing:"border-box",
    border: 0,
    color: 'white',
    height: "56px",
    width:"300px"
  };

  const isInvalid = email === '';

  return (
    <div style={{padding: "20px"}}>
      <h2>Forgotten your password?</h2>
      <p>Don't worry, we'll soon have you back in the game. Enter your email address in the box below and click 'Reset password' - we'll send you a link with instructions on how to reset your password.</p>
    <div style={{display:"flex"}}>
      <TextField style={style} type="email" label="Enter email address" variant="outlined" value={email} onChange={handleChange} 
          color="primary">{email}
      </TextField>
      <div style={{display:"flex", flexDirection: "row-reverse", marginLeft:"20px"}}>
      <Button onClick={handleOkClick} disabled={isInvalid} color="primary" variant="contained">Reset password</Button>
    </div>

        </div>
        {errorMsg && <p>{errorMsg.message}</p>}
      </div>
    );
    }