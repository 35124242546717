import React, { useEffect } from 'react';
import { withPageState } from '../../Context/PageState';
import { withFirebase } from '../../Firebase';
import SpiritMagic from '../../Magic/spiritMagic';
import RuneMagic from '../../Magic/runeMagic';
import RuneMagicMatrices from '../../MagicItems/runeMagicMatrices';
import StorageCrystalPanel from '../../MagicItems/storageCrystalPanel';
import PoweredCrystalPanel from '../../MagicItems/poweredCrystalPanel';
import BoundSpiritPanel from '../../MagicItems/boundSpiritPanel';
import CultSpiritPanel from '../../MagicItems/cultSpiritPanel';
import * as SAVE from '../../../constants/save';
import { processRuneSpell } from '../../../services/spellsService';
import { processSpiritSpell } from '../../../services/spellsService';
import SpiritSpellRow from '../tiles/spiritSpellRow';
import ExoticItemPanel from '../../MagicItems/exoticItemPanel';
import SpiritMagicMatrices from '../../MagicItems/spiritMagicMatrices';
import CultHeaderBar from '../../Magic/cultHeaderBar';
import GiftsGeasesPanel from '../../Magic/giftsGeasesPanel';
import { withContext } from '../../Context/context';

const Magic = (props) => {

    function applyRuneSpell(dieRoll, action, title){
        props.processResult(dieRoll, action, title);
        if (action.type) {
            var updates = processRuneSpell(dieRoll, action, props.character, props.firebase, action.targetType ? action.targetType : "character", props.providerData.campaign)
            props.update(updates, SAVE.YES);
        }
    }

    function applySpiritSpell(dieRoll, action, title){
        props.processResult(dieRoll, action, title);
        // if (action.type) {
            var updates = processSpiritSpell(dieRoll, action, props.character, props.firebase, action.targetType ? action.targetType : "character", props.providerData.campaign)
            props.update(updates, SAVE.YES);
        // }
    }

    function powGain(gained) {
        if (gained) {
            props.update([{fieldName: "powGain", value: true}], SAVE.DELAYED);
        }
    }

    function processSpell(dieRoll, action, title) {
        if (action.type === "spirit") {
            applySpiritSpell(dieRoll, action, title);
        }
        else {
            applyRuneSpell(dieRoll, action, title);
        }
    }

    return (

        <div style={{display: "flex", padding: "0px 5px"}}>
            <div style={{minWidth: "392px"}}>
                <div style={{border: "2px solid #2F588A", borderRadius: "5px"}}>
                    <RuneMagic diceRoller={props.diceRoller} headerBar={<CultHeaderBar />} entityHeight="613px" character={props.character} stylesheet={props.stylesheet} processResult={applyRuneSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                </div>
                <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
                    <RuneMagicMatrices diceRoller={props.diceRoller} character={props.character} stylesheet={props.stylesheet} processResult={applyRuneSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                </div>
            </div>

            <div style={{minWidth: "392px"}}>
                {props.pageState.editMode ? 
                <>
                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", height: "255px", marginLeft: "5px"}}>
                        <SpiritMagic diceRoller={props.diceRoller} element={<SpiritSpellRow />} entityHeight="215px" stylesheet={props.stylesheet} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                    </div>
                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", height: "242px", marginLeft: "5px", marginTop: "10px"}}>
                        <SpiritMagicMatrices diceRoller={props.diceRoller} element={<SpiritSpellRow />} entityHeight="212px" character={props.character} stylesheet={props.stylesheet} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                    </div>
                </>
                : 
                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", maxHeight: "540px", marginLeft: "5px"}}>
                        <SpiritMagic diceRoller={props.diceRoller} element={<SpiritSpellRow />} entityHeight="500px" stylesheet={props.stylesheet} processResult={applySpiritSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                    </div>
                }

                <StorageCrystalPanel character={props.character} stylesheet={props.stylesheet} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                <PoweredCrystalPanel character={props.character} stylesheet={props.stylesheet} update={props.update} displayItem={props.displayItem} powGain={powGain} />
            </div>

            <div style={{minWidth: "392px"}}>
                <BoundSpiritPanel diceRoller={props.diceRoller} element={<SpiritSpellRow />} character={props.character} stylesheet={props.stylesheet} processResult={processSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                <CultSpiritPanel diceRoller={props.diceRoller} element={<SpiritSpellRow />} character={props.character} stylesheet={props.stylesheet} processResult={processSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                <ExoticItemPanel element={<SpiritSpellRow />} character={props.character} stylesheet={props.stylesheet} processResult={processSpell} update={props.update} displayItem={props.displayItem} powGain={powGain} />
                <GiftsGeasesPanel character={props.character} />
            </div>

        </div>
    );
}

export default withPageState(withFirebase(withContext(Magic)));    