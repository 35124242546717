import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getAllPassions } from '../../services/dataService';
import { withContext } from '../Context/context';
import DropDown from '../Controls/dropDown';
import EditBox from '../Controls/editBoxSingle';
import deleteIcon from '../../icons/delete-icon-red.svg'

const EditPassionsDialog = (props) => {

	const [selected, setSelected] = useState({name: "Select"});
	const [value, setValue] = useState(60);
    const [variant, setVariant] = useState("");
    const [passions, setPassions] = useState([]);
  
    useEffect(() => {
        if (!selected.variable) {
            setVariant("");
        }
    },[selected]);
  
    useEffect(() => {
        if (props.selected) {
            setPassions([...props.selected])
        }
    },[props.selected]);

    function addPassion(){
        let updatedPassions = [...passions];
        let newPassion = {name: selected.name, parentId: selected.id};
        if (variant) {
            newPassion.variant = variant;
        }
        updatedPassions.push(newPassion);
        setPassions(updatedPassions)
    }
  
    function modifyFocus(event){
        setFocus(event.target.value);
    }

	function handleOkClick(){
        props.update(passions);
		props.close();
	}

	function handleCancelClick(){
		props.close();
	}

	function deleteItem(event, item){
        let index = passions.findIndex(obj => obj.parentId === item.parentId);
        if (index > -1) {
            let updatedPassions = [...passions];
            updatedPassions.splice(index, 1);
            setPassions(updatedPassions);
        }
	}
	 
    function updateVariant(value) {
        setVariant(value);
    }

    return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", padding: "10px"}}>
            <div>
                <div style={{display:"flex", alignItems: "center"}}>
                    <DropDown values={getAllPassions(props.customContent)} style={{height: "32px"}} onChange={setSelected} />
                    <div style={{width:"275px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
                        <EditBox disabled={!selected.variable} height="28px" width="200px" value={variant} onChange={updateVariant} />
                    </div>
                    <Button onClick={addPassion} style={{width: "60px", height: "30px"}} color="primary" variant='contained'>Add</Button>
                </div>
                <div style={{height: "160px"}}>
                    <div style={{padding: "10px 0px", marginTop: "20px", fontWeight: "bold", borderTop : "2px solid #2f588a"}}>Selected Passions</div>
                    {passions.map((passion, index) => {
                        return (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <div>{passion.name + (passion.variant ? " (" + passion.variant + ")" : "")}</div>
                                <div style={{cursor: "pointer"}} onClick={(event) => deleteItem(event, passion)}>
                                    <img src={deleteIcon} style={{height: "20px"}} />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div style={{display:"flex", flexDirection: "row-reverse", padding: "10px", alignItems:"flex-end"}}>
                    <Button type="submit" onClick={handleOkClick} style={{marginTop:"30px", width: "100px"}} variant="contained">Ok</Button>
                    <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
                </div>
            </div>
		</div>
	);
};

export default withContext(EditPassionsDialog);