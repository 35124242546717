import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css'
import AddCrystalDialog from './crystalDialog';
import StorageCrystal from './storageCrystal';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';
import PanelHeader from '../Controls/panelHeader';

const StorageCrystalPanel = (props) => {

    const [crystals, setCrystals] = useState([]);

    useEffect(() => {
        if (props.providerData.character) {
            setCrystals([...props.providerData.character.crystals]);
        }
    },[props.providerData.character])

    function addCrystal(newCrystal) {
        var updatedCrystals = [...props.providerData.character.crystals];
        updatedCrystals.push(newCrystal);
        var updates = [{fieldName: "crystals", value: updatedCrystals}];
        var updatedMagicPoints = [...props.providerData.character.magicPoints];
        var mpIndex = updatedMagicPoints.findIndex(item => item.entityId === newCrystal.id);
        if (mpIndex < 0) {
            updatedMagicPoints.push({
                entityId: newCrystal.id,
                type: "POW storage crystal",
                total: newCrystal.POW,
                current: newCrystal.POW,
            })
        }
        else {

            updatedMagicPoints[mpIndex] = {...updatedMagicPoints[mpIndex], total: newCrystal.POW, current: newCrystal.POW}
        }      
        updates.push({fieldName: "magicPoints", value: updatedMagicPoints});
        props.update(updates, SAVE.NO);
    }

    return (
        <>
            <div style={{border: "2px solid #2F588A", borderRadius: "5px", height: "219px", marginLeft: "5px", marginTop: "10px"}}>
                {props.providerData.type === "character" ? 
                    <PanelHeader title="POW Storage Crystals" addTitle="Add POW storage crystal" addItem={addCrystal} width="450px">
                        <AddCrystalDialog />
                    </PanelHeader>
                    : 
                    null
                }
                { crystals && crystals.length > 0 ? 
                    <div className={globalStyles.panel} style={{height:"178px", overflow: "auto"}}>
                        {crystals.map((item, index) => {
                            return (
                                <StorageCrystal character={props.providerData.character} key={index} crystal={item} availableMagicPoints={props.availableMagicPoints} storage={props.storage} spells={[item.spell]} processResult={props.processSpell} update={props.update}  />
                            )
                        })}

                    </div>
                :
                <div style={{marginTop: "10px", padding: "5px"}}>
                        You do not currently have any POW storage crystals
                    </div>  
                }
            </div>
        </>
    );
}
export default withContext(StorageCrystalPanel);