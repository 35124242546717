import { v4 as uuidv4 } from 'uuid';

const generateSkills = (skillsList, experience) => {
    var generatedSkills = [];

    if (skillsList && skillsList.length > 0) {
        skillsList.forEach((skill) => {
            var newSkill; 
            if (skill.alternates) {
                newSkill = generateAlternateSkill(skill, experience);
            }
            else {
                newSkill = generateNewSkill(skill, experience)
            }
            if (newSkill) {
                generatedSkills.push(newSkill);
            }
        });
    }
    return generatedSkills;
  }

  const generateNewSkill = (skill, experience) => {
    var generatedNumber = Math.ceil(Math.random()*(100));
    if (generatedNumber <= skill.possession) {
        var newSkill = {
            id: uuidv4(),
            parentId: skill.parentId, 
            value:(skill.baseValue || 15) + experience.bonus,
            name: skill.name, 
            skillCategory: skill.skillCategory,
        };
        if (skill.variant) {newSkill.variant = skill.variant}
        var multiplier = Math.ceil(Math.random()*(3));
        if (skill.primary) {
            multiplier += 3;
        }
        else {
            multiplier += Math.ceil(Math.random()*(3));
        }
        newSkill.value += (multiplier * 5);
        return newSkill;
    }
  }

const generateAlternateSkill = (alternates, experience) => {
    var newSkill; 

    alternates.skills.forEach(skill => {
        if (!newSkill) {
            newSkill = generateNewSkill(skill, experience)
        }
    })

    return newSkill;
}

export default generateSkills;
