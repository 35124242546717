import React from 'react';
import CloseIcon from '../../icons/close-icon.svg';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import globalStyles from '../Styles/styles.module.css';

export default function EditButton(props) {
   
    return (
        <div style={{position: "relative", display: "flex"}}>
            <div title="Edit character" onClick={() => {props.onClick(true)}} style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:"#851113", cursor: "pointer", color:"#fff", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                    <ModeEditIcon style={{height:"30px", width:"30px", color: "#fff"}}/>
            </div>
            {props.imported ?
                <div className={globalStyles.moveFromBottomFade} style={{padding: "10px", width: "300px", color: "#fff", backgroundColor: "#851113", bottom: window.innerHeight < 870 ? "0px" : "", position: "absolute", marginLeft: "60px", borderRadius: "10px"}}>
                    <div style={{position: "absolute", right: "10px", top: "10px", cursor: "pointer"}} title="Close">
                        <div style={{display:"flex", alignItems: "center"}}>
                            <img style={{width: "25px", marginLeft: "10px"}} src={CloseIcon} onClick={props.close} />
                        </div>
                    </div>
                    <div style={{margin: "20px 0px"}}>Your RuneQuesting character has been created using a basic template. Click the button on the left to enter edit mode, where you can then update that template to match your existing character.</div> 
                </div>
            : 
            null}
        </div>
    );
}