import React, { useState, useEffect, useRef } from 'react';
import * as SAVE from '../../constants/save';
import { withPageState } from '../Context/PageState';

const WeaponTile = (props) => {

    const [item, setItem] = useState();
    const [menuShown, setMenuShown] = useState(false);
    const ref = useRef();

    useEffect(() => {
        var index = props.entity.weaponSkills.findIndex(skill => skill.name === props.item.name);
        if (index > -1) {
            var skill = props.entity.weaponSkills[index];
            var item = {weapon:props.item, skill: skill}
            setItem(item);
        }
    },[props.entity, props.item]);

    function modifyHitPoints(value) {
        var updatedItem = {...item};
        updatedItem.weapon.hitPoints.current += value;
        if (updatedItem.weapon.hitPoints.current >= updatedItem.weapon.hitPoints.total) {
            updatedItem.weapon.hitPoints.current = updatedItem.weapon.hitPoints.total
        }
        props.update(updatedItem.weapon, SAVE.DELAYED);
    }

    function processDamageResult(dicePool, result, location, diceRolled) {
        setMenuShown(false);
        props.processDamage(dicePool, result, location, diceRolled);
    }

    function processResult(roll){
        setMenuShown(false);
        var verb = roll.type == "parry" ? "parried" : roll.type;
        var messageText={critical: " made a critical " + roll.type + " with {pronoun} " + item.weapon.name, special: " achieved a special " + roll.type + " with {pronoun} " + item.weapon.name, success: " successfully " + verb + " with {pronoun} " + item.weapon.name, failure: " failed to " + roll.type + " with {pronoun} " + item.weapon.name, fumble: " attempted to " + roll.type + " with {pronoun} " + item.weapon.name + " but fumbled"};
        var dieRoll = {target: roll.target, result: roll.result, augmentRoll: roll.augment, messageText: messageText}
        props.processResult(dieRoll, {...roll.item, type:"weaponSkills"}, roll.title);
    }
    
    function handleValueChange(value) {
        var updatedItem = {...item};
        updatedItem.skill.value = value;
        setItem(updatedItem);
        props.updateSkill(updatedItem.skill, SAVE.NO);
    }

    if (item) {
        return (
            <>
                <div ref={ref}>
                    {React.cloneElement(props.children, {...props, item: item, modifyHitPoints: modifyHitPoints, handleValueChange: handleValueChange, processResult: processResult, processDamageResult: processDamageResult, update: props.update})}
                </div>
            </>  
        )
    }
    else {
        return null;
    }      
}

export default withPageState(WeaponTile);    