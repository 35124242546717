import React, { useState, useEffect } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button, MenuItem, Select } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { withFirebase } from '../Firebase';

const CampaignCharacters = (props) => {

	const [requests, setRequests] = useState([]);
    const [characters, setCharacters] = useState([...props.characters]);

    useEffect(() => {
        setCharacters([...props.characters])
    },[props.characters])

    useEffect(() => {
        var campaignRequests = [];
        props.firebase
        .requests()
        .where("campaignId", "==", props.campaign.id)
        .get()
        .then((collection) => {
            collection.forEach((docRequest) => {
                var campaignRequest = docRequest.data();
                campaignRequest.id = docRequest.id
                campaignRequests.push(campaignRequest);
            })
            setRequests(campaignRequests);
        })
    },[props.campaign.id])

    function deleteRequest(requestId){
        props.firebase
        .request(requestId)
        .delete()
        .then(() =>  {
            var updatedRequests = [];
            requests.forEach((request) => {
                if (request.id !== requestId) {
                updatedRequests.push(request);
                }
            });
            setRequests(updatedRequests);
        });
    }
 
    function acceptRequest(requestor) {

        props.firebase
        .character(requestor.pcid)
        .get()
        .then(doc => {
            if (doc.exists) {
                var updatedCharacter = doc.data();
                updatedCharacter.campaignId = props.campaign.id
                props.firebase
                .character(requestor.pcid)
                .set(updatedCharacter)
                .then(function() {
                    var updatedCharacters = [...characters]
                    updatedCharacters.push({...updatedCharacter});
                    setCharacters(updatedCharacters);
                    props.firebase
                    .request(requestor.id)
                    .delete()
                    .then(function() {
                        var updatedRequests = [...requests]
                        var index = updatedRequests.findIndex(obj => obj.id === requestor.id)
                        if (index > -1) {
                            updatedRequests.splice(index, 1);
                            setRequests(updatedRequests);
                        }
                    })
                })
            }
        })
      }
    
    function getRequestorInnerHTML(character) {
        var innerHTML = {__html: `<h2>Are you sure you wish to remove the request for ${character.name}?</h2>
          <p>To confirm the deletion, type the name of the character in the box below, and then click 'Delete' </p>
          `};
        return innerHTML
      }
       
    return (
        <div style={{padding: "10px"}}>
            <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>Player Characters</div>
            </div>
            <div style={{display: "flex", alignItems: "flex-start"}}>
                { characters && characters.length > 0 ? 
                    characters.map((character, index) => {
                    return (
                        <a key={index} target='_blank' href={"/charactersheet/" + character.id} style={{color: "#121312"}}>
                            <div style={{border: "2px solid #2f588a", borderRadius: "10px", margin: "10px 7px", paddingTop: "10px", maxWidth: "150px"}}>
                                <img style={{height: "200px"}} src={character.profileImage} />
                                <div style={{height: "60px", padding: "5px 0px"}}>
                                    <div style={{textAlign: "center"}}>{(character.name.first + (character.name.last ? " " + character.name.last : "")) + (character.playerName ? " (" + character.playerName + ")" : "")} </div>
                                </div>
                            </div>
                        </a>
                    )
                })
                :
                <div style={{padding: "10px", display: "flex", alignItems: "center"}}>
                    <div>To add player characters to your campaign, use the Add Player button</div>
                    <PersonAddIcon style={{width: "24px", height: "24px", margin: "0px 5px"}} />
                    <div> to the left to provide players with the necessary link to join the campaign.</div>
                </div>
            }
            </div>
            {requests && requests.length > 0 ? 
            <>
                <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>Requests To Join</div>
                </div>
                <div style={{display: "flex", alignItems: "flex-start"}}>
                { requests.map((request, index) => {
                    return (
                            <div style={{border: "2px solid #2f588a", borderRadius: "10px", margin: "10px 5px", paddingTop: "10px", maxWidth: "150px", cursor: "pointer"}}>
                                <img style={{height: "200px", opacity: 0.6}} src={request.profileImage} />
                                <div style={{height: "60px", padding: "5px 0px"}}>
                                    <div style={{textAlign: "center"}}>{(request.name.first + (request.name.last ? " " + request.name.last : "")) + (request.playerName ? " (" + request.playerName + ")" : "")} </div>
                                    <div style={{textAlign: "center"}}></div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <Button style={{width: "50px", height: "30px", marginRight: "20px"}} color='primary' variant="contained" onClick={() => acceptRequest(request)}>Yes</Button>
                                        <Button style={{width: "50px", height: "30px"}} color='error' variant="contained" onClick={() => deleteRequest(request.id)}>No</Button>
                                    </div>
                                </div>
                            </div>
                    )
                })}
            </div>
            </>
            : 
            null}
            {props.campaign.uid === props.authUser.uid ? 
            <>
                <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>Non Player Characters</div>
                </div>
                <h1>Coming Soon!</h1>
            </>
            : 
            null}
        </div>
    )
}

export default withFirebase(CampaignCharacters);