import React, {useState, useEffect} from 'react';
import { withContext } from '../Context/context';
import globalStyles from '../Styles/styles.module.css';
import { FormControl, Checkbox } from '@mui/material';
import { displayDiceRolled } from '../../services/dieRollService';

const WeaponListItem = (props) => {

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [damage, setDamage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [buttonTitle, setButtonTitle] = useState("Add");
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        setChecked(props.selected);
        var characteristics = props.providerData.character.characteristics;
        var stats = {STR: characteristics.STR.baseValue + (characteristics.STR.statBonus || 0), DEX: characteristics.DEX.baseValue + (characteristics.DEX.statBonus || 0), }
        var lowStats = props.item.minSTR > stats.STR;
        if (!lowStats) {
            if (props.item.minDEX > stats.DEX) {
                if ((stats.STR - props.item.minSTR) < ((props.item.minDEX - stats.DEX) *2)) {
                    lowStats = true;
                }
            }
        }
        if (lowStats) {
            setRestricted(lowStats);
            props.setRestricted(lowStats);    
        }  
    },[props.selected, props.item])

    useEffect(() => {
        setDamage(displayDiceRolled([props.item.damage]))
    },[props.item.damage])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.select(props.item, event.target.checked)
    };

    const handleClick = () => {
        setDisabled(true)
        setButtonTitle("Added");
        props.add(props.item);
    }

    return (
        <>
            <div className={globalStyles.row} style={{display:"flex", height: "24px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px", justifyContent: "space-between"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        {props.noSelect ? 
                            null
                        : 
                            <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                                <FormControl variant="outlined" 	>
                                    <Checkbox checked={checked} color='primary' onChange={handleChange} />
                                </FormControl>
                            </div>
                        }
                        <div style={{cursor: "pointer", display:"flex", color: restricted ? "#851113":"#121312"}} onClick={() => setOpen(!open)}>
                            <div style={{minWidth:"220px", alignItems: "center", display: "flex"}}>
                                <div>{props.item.title || props.item.name}{restricted ? " **": ""}</div>
                            </div>
                            <div style={{width:"60px", display:"flex", alignItems: "center", marginLeft: "30px"}}>{damage}</div>
                            <div style={{width:"90px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.ENC || props.item.enc}</div>
                            <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", width: "400px"}}>{props.item.description}</div>
                        </div>
                    </div>
                    <button disabled={disabled} className={disabled ? globalStyles.disabledButton : globalStyles.activeButton} style={{borderRadius: "3px", padding: "2px 5px", width: "60px"}} onClick={handleClick}>{buttonTitle}</button>
                </div>
            </div>
            {open ?
                <div style={{minHeight:"80px", backgroundColor: "#fff", margin: "2px", padding: "10px"}}>
                    <div style={{display:"flex", color:"#2F588A"}}>
                        <div style={{display:"flex"}}>
                            <div><b>Weapon:</b></div>
                            <div style={{marginLeft: "10px"}}><b>{props.item.name}</b></div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Category:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.category}</div>
                        </div>
                    </div>
                    <div style={{display:"flex", color:"#2F588A", marginTop: "10px"}}>
                        <div style={{display:"flex"}}>
                            <div><b>STR:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.minSTR}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>DEX:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.minDEX}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Damage:</b></div>
                            <div style={{marginLeft: "10px"}}>{damage}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Hit Points:</b></div> 
                            <div style={{marginLeft: "10px"}}>{props.item.hitPoints.current}/{props.item.hitPoints.total}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>ENC:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.enc}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Length:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.length}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>SR:</b></div>
                            <div style={{marginLeft: "10px"}}>{props.item.sr}</div>
                        </div>
                        {props.item.ROF ? 
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>ROF:</b></div>
                                <div style={{marginLeft: "10px"}}>{props.item.ROF || ""}</div>
                            </div>
                        : 
                            null
                        }
                        {props.item.range ? 
                            <div style={{display:"flex", marginLeft: "20px"}}>
                                <div><b>Range:</b></div>
                                <div style={{marginLeft: "10px"}}>{props.item.range || ""}</div>
                            </div>
                        : 
                            null
                        }
                    </div>
                    <div style={{marginTop: "10px"}} dangerouslySetInnerHTML={{ __html: props.item.description}} />
                    {restricted ? 
                        <div style={{color: "#851113", marginTop: "5px"}}>** Insufficient STR and/or DEX to wield this weapon effectively</div>
                    : 
                    null
                    }
                </div>
                : 
                null
                }
        </>
    )
}

export default withContext(WeaponListItem);