import React, { Component } from 'react';

class Campaign extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            current: null,
        };
      }
    
    ChangeCurrent(campaign) {
        this.setState({
            current: campaign
        })
    }

    getCurrent() {
        return this.state.current;
    }
}

export default Campaign; 