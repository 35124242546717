import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import ElementalRuneCard from './elementalRuneCard';
import {
    DndContext,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
  } from "@dnd-kit/core";
  import {Draggable} from '../../DragAndDrop/draggable';
import {Droppable} from '../../DragAndDrop/droppable';

const clearSelected = {primary: null, secondary: null, tertiary: null}

const ElementalRunes = (props) => {
    const [selected, setSelected] = useState();
    const [selectedCount, setSelectedCount] = useState(0)
    const [cultureRune, setCultureRune] = useState("");
    const [hover, setHover] = useState();
    const [runes, setRunes] = useState([]);

    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    useEffect(() => {
        if (props.builder.runes.elementalRunes.length > 0) {
            setRunes([...props.builder.runes.elementalRunes]);
        }
        else {
            var newRunes = [];
            props.elementalRunes.forEach(rune => {
                var newRune = {...rune};
                newRune.startingValue = 0;
                newRune.value = 0;
                newRunes.push(newRune);
            });
            setRunes(newRunes);
        }
    }, [props.builder.runes, props.elementalRunes])

    useEffect(() => {
        var existingSelected = {...clearSelected};
        var count = 0;
        if (props.builder.runes.primaryRune) {
            existingSelected.primary = {...props.builder.runes.primaryRune};
            count ++;
        }
        if (props.builder.runes.secondaryRune) {
            existingSelected.secondary = {...props.builder.runes.secondaryRune};
            count ++;
        }
        if (props.builder.runes.tertiaryRune) {
            existingSelected.tertiary = {...props.builder.runes.tertiaryRune};
            count ++;
        }
        setSelected(existingSelected);
        setSelectedCount(count);
    }, [props.builder.runes, props.elementalRunes])
 
    useEffect(() => {
        if (props.builder.homeland.name === "Prax") {
            setCultureRune(props.builder.tribe.rune)
        }
        else {
            setCultureRune(props.builder.homeland.rune)
        }
      }, [props.builder.homeland]);

    function handleDragEnd(data) {
        if (data.over) {
            var index = runes.findIndex(rune => rune.name == data.active.id);
            var draggedRune = runes[index]
            var updatedSelected = {...selected};
            if (updatedSelected.primary && updatedSelected.primary.name == draggedRune.name) updatedSelected.primary = null;
            if (updatedSelected.secondary && updatedSelected.secondary.name == draggedRune.name) updatedSelected.secondary = null;
            if (updatedSelected.tertiary && updatedSelected.tertiary.name == draggedRune.name) updatedSelected.tertiary = null;
            updatedSelected[data.over.id] = {...draggedRune};
            setSelected(updatedSelected);
            var updatedRunes = [...runes];
            var count = 0;
            updatedRunes.forEach(rune => {
                if (updatedSelected.primary && rune.name === updatedSelected.primary.name) {
                    rune.startingValue = 60;
                    if (rune.value < 60) rune.value = 60;
                    count ++;
                }
                else if (updatedSelected.secondary && rune.name === updatedSelected.secondary.name) {
                    rune.startingValue = 40;
                    if (rune.value < 40) rune.value = 40;
                    count ++;
                }
                else if (updatedSelected.tertiary && rune.name === updatedSelected.tertiary.name) {
                    rune.startingValue = 20;
                    if (rune.value < 20) rune.value = 20;
                    count ++;
                }
                else {
                    rune.startingValue = 0;
                    rune.value = 0;
                }
                if (rune.name === cultureRune) {
                    rune.startingValue += 10;
                    if (rune.value < rune.startingValue) {
                        rune.value = rune.startingValue;
                    }
                }
            })
            setRunes(updatedRunes);
            setSelectedCount(count);
        }
    }

    function advance() {
        var updatedBuilder = {...props.builder};
        var updatedRunes = [...runes];
        updatedBuilder.runes.primaryRune = selected.primary;
        updatedBuilder.runes.secondaryRune = selected.secondary;
        updatedBuilder.runes.tertiaryRune = selected.tertiary;
        updatedBuilder.runes.elementalRunes = updatedRunes;
        props.update(updatedBuilder, true);
    }

    return (
        <DndContext onDragStart={() => {setHover(null)}} onDragEnd={handleDragEnd} autoScroll={false} sensors={sensors}>
            <div>
                <div style={{marginTop: "30px", position: "relative"}}>
                    <div id="runeValues" style={{display:"flex", justifyContent: "space-around"}}>
                        {props.elementalRunes.map((rune, index) => {
                            var runeImage = "white-" + rune.image;
                            var backgroundColor = "#454645";
                            if (hover && hover.name === rune.name) {
                                runeImage = rune.image;
                                backgroundColor = "#fff";
                            }
                            return (
                                <div key={index} style={{padding: "0px 10px", width: "16%", maxWidth: "140px"}}>
                                    <Draggable id={rune.name} style={{backgroundColor: "#121312", border: "0px"}}>
                                        <div onMouseEnter={() => setHover(rune)} onMouseLeave={() => {setHover(null)}} style={{border: "2px solid #cdcdcd", backgroundColor: backgroundColor, borderRadius:"15px"}}>
                                            <img style={{width:"100%"}} src={"https://storage.googleapis.com/runequest/runes/" + runeImage}  />
                                        </div>
                                    </Draggable>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <h2 style={{textAlign: "center"}}>Drag runes to your selected affinities</h2>
                {selected ? 
                    <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", marginTop: "20px"}}>
                        <div style={{width: "17%", textAlign: "center", maxWidth: "160px"}}>
                            <div>
                                <Droppable id="primary">
                                    <div style={{border: "2px solid #cdcdcd", opacity: selected.primary ? 1 : 0.3, backgroundColor: selected.primary ? "#fff" : "#454645", borderRadius:"15px", margin: "15px"}}>
                                        <img style={{width:"100%"}} src={selected.primary ? "https://storage.googleapis.com/runequest/runes/" + selected.primary.image : "https://storage.googleapis.com/runequest/runes/white-magic-rune-600x600.png"} />
                                    </div>
                                </Droppable>
                            </div>
                            <div style={{fontSize: "20px"}}>
                                <div style={{marginBottom: "5px"}}>Primary Rune</div>
                                {selected.primary ? 
                                    <div>{selected.primary.name}&nbsp;{selected.primary.name === cultureRune ? "70%" : "60%"}</div>
                                : 
                                    null
                                }
                            </div>
                        </div>
                        <div style={{width: "17%", textAlign: "center", maxWidth: "160px"}}>
                            <div>
                                <Droppable id="secondary">
                                    <div style={{border: "2px solid #cdcdcd", opacity: selected.secondary ? 1 : 0.3, backgroundColor: selected.secondary ? "#fff" : "#454645", borderRadius:"15px", margin: "15px"}}>
                                        <img style={{width:"100%"}} src={selected.secondary ? "https://storage.googleapis.com/runequest/runes/" + selected.secondary.image : "https://storage.googleapis.com/runequest/runes/white-magic-rune-600x600.png"} />
                                    </div>
                                </Droppable>
                            </div>
                            <div style={{fontSize: "20px"}}>
                                <div style={{marginBottom: "5px"}}>Secondary Rune</div>
                                {selected.secondary ? 
                                    <div>{selected.secondary.name}&nbsp;{selected.secondary.name === cultureRune ? "50%" : "40%"}</div>
                                    : 
                                    null
                                }
                            </div>
                        </div>
                        <div style={{width: "17%", textAlign: "center", maxWidth: "160px"}}>
                            <Droppable id="tertiary">
                                <div style={{border: "2px solid #cdcdcd", opacity: selected.tertiary ? 1 : 0.3, backgroundColor: selected.tertiary ? "#fff" : "#454645", borderRadius:"15px", margin: "15px"}}>
                                    <img style={{width:"100%"}} src={selected.tertiary ? "https://storage.googleapis.com/runequest/runes/" + selected.tertiary.image : "https://storage.googleapis.com/runequest/runes/white-magic-rune-600x600.png"} />
                                </div>
                            </Droppable>
                            <div style={{fontSize: "20px"}}>
                                <div style={{marginBottom: "5px"}}>Tertiary Rune</div>
                                {selected.tertiary ? 
                                    <div>{selected.tertiary.name}&nbsp;{selected.tertiary.name === cultureRune ? "30%" : "20%"}</div>
                                    : 
                                    null
                                }
                            </div>
                        </div>
                    </div>
                : 
                    null
                }
                    <div style={{marginLeft: "20px", display: "flex", flexDirection: "row-reverse", marginTop: "40px"}}>
                        {selected && selected.primary && selected.secondary && selected.tertiary ? 
                            <div>
                                <Button onClick={advance} size="large" variant="contained" style={{border: "0", marginLeft:"10px", width: "180px", height: "60px", borderRadius: "10px"}} color="primary">Continue to Power Runes</Button>
                            </div>
                        : 
                            null
                        }
                    </div>
                    <div style={{display: "flex", position: "relative"}}>
                    {hover ? 
                        <div style={{position: "absolute", zIndex: 999}}>
                            <ElementalRuneCard item={hover} ></ElementalRuneCard>
                        </div>
                    : null
                    }
                </div>

            </div>
        </DndContext>
    );
}

export default ElementalRunes;