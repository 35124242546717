import React, {useState} from 'react'
import DeleteIcon from '../../icons/delete-icon-white.svg';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const DeleteButton = (props) => {

    const [confirm, setConfirm] = useState(false);

    function confirmDelete() {
        setConfirm(false);
        props.delete(props.item);
    }

    return (
        <>
            {props.imageButton ? 
                <button disabled={props.disabled} style={{backgroundColor: props.disabled ? "#acacac": "#851113", borderRadius: "25px", height: "24px", width: "24px", display: "flex", justifyContent: "center", alignItems: "center", cursor: props.disabled ? "not-allowed": "pointer"}} title={"Delete " + props.type} onClick={() => setConfirm(true)}>
                    <img src={DeleteIcon} style={{width: "20px", height: "20px"}} />
                </button>
            : 
                <Button disabled={props.disabled} onClick={() => setConfirm(true)} style={{minWidth: "100px", backgroundColor: props.disabled ? "#acacac": "#851113", color: "#fff", cursor: props.disabled ? "not-allowed": "pointer"}} variant="contained">{props.title ? props.title : "Delete"}</Button>
            }
            <Dialog open={confirm} maxWidth="xs" >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <div>{"Are you sure you want to delete this " + props.type.toLowerCase() + "?"}</div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setConfirm(false)}>
                    Cancel
                    </Button>
                    <Button onClick={confirmDelete}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default DeleteButton;