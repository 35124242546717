import React, { useState, useEffect } from 'react';
import GrandParent from './grandparent';
import Parent from './parent';
import * as OccupationsData from '../../../data/occupations.json';

const FamilyOccupation = (props) => {
    const [grandparent, setGrandparent] = useState();
    const [parent, setParent] = useState();
    const [occupations, setOccupations] = useState([]);

    useEffect(() => {
        var allOccupations = {...OccupationsData}
        setOccupations(allOccupations.data);
    },[]);

    useEffect(() => {
        if (! props.builder.grandparent ) {
            if (occupations && occupations.length > 0) {
                var grandparent = {relative: "", homeland: props.builder.homeland, occupation: null, tribe: props.builder.tribe};
                var random = Math.floor(Math.random()*(props.builder.homeland.occupations.length));
                var occupation = props.builder.homeland.occupations[random];
                var index = occupations.findIndex(occ => occ.name === occupation);
                grandparent.occupation = occupations[index];
                setGrandparent(grandparent);
                updateGrandparent(grandparent)
            }
        }
        else {
            setGrandparent({...props.builder.grandparent})
        }
    },[occupations]);

    useEffect(() => {
        if (! props.builder.parent && !parent ) {
            if (occupations && occupations.length > 0) {
                var newParent = {relative: "", homeland: props.builder.homeland, occupation: null, tribe: props.builder.tribe};
                var random = Math.floor(Math.random()*(props.builder.homeland.occupations.length));
                var occupation = props.builder.homeland.occupations[random];
                var index = occupations.findIndex(occ => occ.name === occupation);
                newParent.occupation = occupations[index];
                updateParent(newParent)
            }
        }
        else {
            setParent({...props.builder.parent})
        }
    },[props.builder.parent, occupations])

    useEffect(() => {
        if (props.builder.parent ) {
            setParent(props.builder.parent);
            if (props.builder.parent.relative) {
                props.disableNext(false);
            }
        }
    },[props.builder.parent]);

    function updateGrandparent(gp){
        setGrandparent(gp);
        var updatedBuilder = {...props.builder}
        updatedBuilder.grandparent = gp;
        props.update(updatedBuilder, false);
    }

    function updateParent(p) {
        setParent(p);
        var updatedBuilder = {...props.builder}
        updatedBuilder.parent = p;
        props.update(updatedBuilder);
    }

    if (occupations) {
        return (
            <div>
                <div style={{display:"flex", flexWrap: "wrap", fontSize: "15px", paddingTop: "20px", justifyContent: "space-between"}}>
                    <GrandParent occupations={occupations} homelands={props.homelands} grandparent={grandparent} homeland={props.builder.homeland} tribe={props.builder.tribe} update={updateGrandparent} />
                    {grandparent && grandparent.relative ? 
                        <Parent occupations={occupations} homelands={props.homelands} parent={parent} homeland={props.builder.homeland} tribe={props.builder.tribe} update={updateParent} />
                    : 
                        null
                    }
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default FamilyOccupation;