import React, {useState, useEffect} from 'react';
import DiceButton from './diceButton';
import SmallDiceButton from './smallDiceButton';

const SpiritSpellButton = (props) => {

    const [item, setItem] = useState(props.item);

    useEffect(() => {
        setItem({...props.item});
    },[props.item]);

    if (props.pageState.editMode) {
        return (
            <div></div>
            )
    }
    else {
        return (
            <DiceButton diceRoller={props.diceRoller} item={item} style={{borderRadius: "5px"}} disabled={props.disabled} type="Spirit Spell" processResult={props.processResult} entity={props.entity} modifier={props.modifier}>
                <SmallDiceButton />
            </DiceButton>
        )
    }
}

export default SpiritSpellButton