import React, { useState} from 'react';
import globalStyles from '../Styles/styles.module.css'
import ExperienceIcon from '../../icons/learn-icon.svg';

export default function ExperienceButton(props) {

    return (
        <>
            <div onClick={props.displayPanel} className={globalStyles.floatingButton}>
                <img style={{width:"40px"}} src={ExperienceIcon} title="Roll for experience" />
            </div>
        </>
);
}