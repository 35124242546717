import React, {useState, useEffect} from "react";
import HideIconWhite from '../../../icons/hide-icon-white.svg'
import SeeIcon from '../../../icons/see-icon.svg'

const HideButton = (props) => {

    const [hidden, setHidden] = useState(props.item.hidden);

    const handleClick = () => {
        var newResult = !hidden
        setHidden(newResult);
        props.update(newResult);
    }

    return (
        <div style={{backgroundColor: hidden ? "#fff" : "#2f588a", color: hidden ? "#121312":"#fff", height: "22px", width: "22px", margin: "2px 0px", cursor: "pointer"}} onClick={handleClick}>
            <img alt={(hidden ? "Show " : "Hide ") + props.name}  title={(hidden ? "Show " : "Hide ") + props.name}  src={hidden ? SeeIcon : HideIconWhite} style={{width: "22px", height: "22px"}} />
        </div>
    )
}

export default HideButton;