import numberGenerator from './numberGenerator';

const generateCharacteristics = (culture, experience) => {
    let results = {};
    culture.characteristics.forEach((characteristic) => {
        var minValue = (characteristic.quantity * 3) -1;
        var maxValue = (characteristic.quantity * 6) - characteristic.quantity;
        if (characteristic.name === "STR" && culture.minSTR) minValue = culture.minSTR - characteristic.modifier;
        if (minValue <= 0) minValue = 1;
        if (["POW", "CHA"].indexOf(characteristic.name) > -1) maxValue = 99;
        if (characteristic.name === "DEX" && culture.minDEX) minValue = culture.minDEX;
        if (characteristic.name === "POW" && experience.minPOW) minValue = experience.minPOW;
        var result = numberGenerator(characteristic, minValue, maxValue);
        result += characteristic.modifier;
        var minStat = (Math.ceil(characteristic.quantity * 3.5) + characteristic.modifier -1) + experience.characteristicsBonus;
        if (result < minStat) {
            result += experience.characteristicsBonus;
        }
        if (result < minStat) {
            result = minStat;
        }
        results[characteristic.name] = {
            name: characteristic.name, 
            statBonus: 0,
            baseValue: result,
            enchantments: [],
            enchantmentBonus: 0,
            fullName: characteristic.fullName
        };
    });
    
    return results;
}

export default generateCharacteristics;