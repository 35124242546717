import React from 'react';
import {withContext} from '../Context/context';
import { calculateDamageBonus } from '../../services/attributeService';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateStrikeRanks } from '../../services/attributeService';
import { calculateHitPoints } from '../../services/hitPointsService';
import { isEqual } from 'lodash';

const SizeTile = (props) => {

    function update(characteristic, save) {

        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.SIZ = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var newDamageBonus = calculateDamageBonus(updatedCharacteristics)
        if (! isEqual(props.entity.damageBonus, newDamageBonus)) {
            updates.push({fieldName: "damageBonus", value: newDamageBonus})
        }

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedHitPoints = calculateHitPoints(updatedCharacteristics, props.entity.hitPoints);
        if (! isEqual(props.entity.hitPoints, updatedHitPoints)) {
            updates.push({fieldName: "hitPoints", value: updatedHitPoints})
        }

        var updatedStrikeRanks = calculateStrikeRanks(updatedCharacteristics);
        if (! isEqual(props.entity.strikeRanks, updatedStrikeRanks)) {
            updates.push({fieldName: "strikeRanks", value: updatedStrikeRanks})
        }
        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(SizeTile)