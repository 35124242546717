import React from 'react';

const CharacterContext = React.createContext();

export const withContext = Component => props => (
  <CharacterContext.Consumer>
    {data => <Component {...props} providerData={data} />}
  </CharacterContext.Consumer>
);

export default CharacterContext;
