import { v4 as uuidv4 } from 'uuid';

const generatePassions = (passionsList, experience) => {
    var generatedPassions = [];

    if (passionsList && passionsList.length > 0) {
        passionsList.forEach((passion) => {
            var newPassion; 
            if (passion.alternates) {
                newPassion = generateAlternatePassion(passion, experience);
            }
            else {
                newPassion = generateNewPassion(passion, experience)
            }
            if (newPassion) {
                generatedPassions.push(newPassion);
            }
        });
    }
    return generatedPassions;
  }

  const generateNewPassion = (passion, experience) => {
    var generatedNumber = Math.ceil(Math.random()*(100));
    var bonus = experience.bonus ? experience.bonus / 2 : 0;
    if (generatedNumber <= passion.possession) {
        var newPassion = {
            id: uuidv4(),
            parentId: passion.parentId, 
            value:50 + bonus,
            name: passion.name, 
            variant: passion.variant || ""
        };
        newPassion.value += Math.ceil(Math.random()*(4)) * 5;
        return newPassion;
    }
  }

const generateAlternatePassion = (alternates, experience) => {
    var newPassion; 

    alternates.passions.forEach(passion => {
        if (!newPassion) {
            newPassion = generateNewPassion(passion, experience)
        }
    })

    return newPassion;
}

export default generatePassions;