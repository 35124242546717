import React, { useState, useEffect } from 'react';
import { CssBaseline, Typography, Container, Paper, Button } from '@mui/material';
import globalStyles from '../Styles/styles.module.css'
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import CampaignCard from './campaignCard';
import { AuthUserContext } from '../Session';
import DialogBox from '../Dialogs/dialogBox';
import AddCampaignDialog from './addCampaignDialog';

const CampaignList = (props) => {
  const [campaigns, setCampaigns] = useState();
  const [open, setOpen] = useState(false);
        
  useEffect(() => {
    document.title = "RuneQuesting: Campaigns"
},[]);

  function addCampaign(newCampaign, authUser) {
    var newCampaignId = "";
    
    newCampaign.uid = authUser.uid;
    newCampaign.players = [
      authUser.uid
    ];
    newCampaign.campaigns = [];
    props.firebase
    .campaigns()
    .add(newCampaign)
    .then(function(docRef) {
      newCampaignId =  docRef.id;
      newCampaign.id = newCampaignId;
      var updatedCampaigns = [...campaigns];
      updatedCampaigns.push(newCampaign);
      setCampaigns(updatedCampaigns);
    });
    setOpen(false);
  }

  return (
    <div style={{display: "flex", backgroundColor: "#2F588A", justifyContent: "center", width: "100%", height:"100%", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px"}}>
      <div className={globalStyles.campaignBanner}></div>
      <AuthUserContext.Consumer>
        {authUser => (
          campaigns ? 
            <React.Fragment>
              <CssBaseline />
              <Container maxWidth="lg">
                <Paper elevation={3}>
                <Typography component="div" style={{backgroundColor:"#121312", height: '100vh', padding:"20px", overflowY:"auto" }}>
                  <div style={{marginTop: "40px"}}>

                    <Button onClick={() => createCampaign(authUser)} style={{minWidth:"10em"}} size="large" variant="contained" color="primary">Create a campaign</Button>
                    <div className={globalStyles.horizontalLine}></div>
                    <div >
                      <div  style={{display:"flex", flexWrap:"wrap", justifyContent:"left", overflow:"hidden", }}>
                        {campaigns.map((item) => {
                          return (
                            <div key={item.id}>
                              <div style={{width:"480", height:"450"}}>
                                <div style={{ height: 'auto', width:'auto', marginRight:"20px" }}>
                                <CampaignCard campaign={item} innerHTML={getInnerHTML(item)} showDelete={showDelete(item, authUser)} deleteCampaign={deleteCampaign}></CampaignCard>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </Typography>
                </Paper>
              </Container>
              <DialogBox content={dialogContent()} maxWidth='sm' isOpen={open} handleClose={handleDialogClose} subtitle='' title='Create Campaign'>
                <AddCampaignDialog handleDialogClose={handleDialogClose} addCampaign={(newCampaign) => addCampaign(newCampaign, authUser)}></AddCampaignDialog>
              </DialogBox>
            </React.Fragment>
          : 
            getCampaigns(authUser)
        )}
      </AuthUserContext.Consumer>
    </div>
  );

  function showDelete(item, authUser){
    if (item.uid === authUser.uid) return true
    else return false;
  }

  function getInnerHTML(campaign) {
    var innerHTML = {__html: `<h2>Are you sure you wish to delete your campaign '${campaign.name}'?</h2>
    <p>Once a campaign has been deleted it cannot be retrieved, so please be sure before you go ahead </p>
    <p><b>Note that this will not delete the characters who are part of this campaign</b></p>
    <p>To confirm the deletion, type the name of the campaign in the box below, and then click 'Delete' </p>
      `};
    return innerHTML
  }
  
  function deleteCampaign(campaignId){
    props.firebase
      .campaigns()
      .doc(campaignId)
      .delete()
      .then(authUser => {
        var updatedCampaigns = [];
        campaigns.forEach((campaign) => {
          if (campaign.id !== campaignId) {
            updatedCampaigns.push(campaign);
          }
        });
        setCampaigns(updatedCampaigns);
      });
  }

  function createCampaign(authUser) {
    setOpen(true);
  }
      
  function handleDialogClose(){
    setOpen(false);
  }

  function getCampaigns(authUser){
    if (authUser){

      var campaigns = [];
      props.firebase
      .campaigns()
      .where("players","array-contains-any", [authUser.uid])
      .get()
      .then(querySnapshot => 
          {
              querySnapshot.forEach(function(doc) {
                const id = doc.id;
                const data = doc.data();
                var campaign = {id, ...data}
                  campaigns.push(campaign);
              });
              setCampaigns(campaigns);
          });
    }
  }

  function dialogContent() {
    return (
      <div>Fubar</div>
    )
  }
}

export default withFirebase(withEmailVerification(withAuthorization(CampaignList)));