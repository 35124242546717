import React, {useState} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import BoundSpirit from './boundSpirit';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';
import PanelHeader from '../Controls/panelHeader';
import SpiritDialog from './spiritDialog';

const BoundSpiritPanel = (props) => {

    function deleteSpirit(spirit) {
        var updates = [];
        var index = props.providerData.character.boundSpirits.findIndex(item => item.id === spirit.id);
        if (index > -1) {
            var updatedSpirits = [...props.providerData.character.boundSpirits];
            updatedSpirits.splice(index, 1);
            updates.push({fieldName: "boundSpirits", value: updatedSpirits});
            var updatedMagicPoints = [...props.providerData.character.magicPoints];
            var magicPointIndex = updatedMagicPoints.findIndex(item => item.entityId === spirit.id);
            updatedMagicPoints.splice(magicPointIndex, 1);
            updates.push({fieldName: "magicPoints", value: updatedMagicPoints});
            props.update(updates, SAVE.NO)
        }
    }

    function addSpirit() {

    }

    function displaySpirits(type) {
        return (
            props.providerData.character.boundSpirits.map((spirit, index) => {
                if (spirit.type === type) {
                    return (
                        <BoundSpirit diceRoller={props.diceRoller} element={props.element} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} stylesheet={props.stylesheet} key={index} entity={props.providerData.character} spirit={spirit} processResult={props.processResult} update={props.update} delete={deleteSpirit} powGain={props.powGain} />
                    )
                }
            })
        )
    }

    return (
        <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginLeft: "5px", }}>
            {props.providerData.type === "character" ? 
                <PanelHeader title="Bound & Allied Spirits" addTitle="Add spirit" addItem={addSpirit} width="900px">
                    <SpiritDialog type="Bound spirit" update={props.update} entity={props.providerData.character} />
                </PanelHeader>
            : 
                null
            }
            <div className={globalStyles.panel} style={{minHeight: "150px", maxHeight:"460px", overflow: "auto"}}>
                {props.providerData.character.boundSpirits && props.providerData.character.boundSpirits.length > 0 ? 
                    <>
                        {displaySpirits("Allied spirit")}
                        {displaySpirits("Fetch")}
                        {displaySpirits("Bound spirit")}
                    </>
                : 
                <div style={{marginTop: "10px", padding: "5px"}}>
                    <div>You do not currently have any bound or allied spirits</div>
                </div>  
                }               
            </div>
        </div>
    )
}
export default withContext(withPageState(BoundSpiritPanel));