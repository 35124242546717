import React from 'react';
import globalStyles from '../Styles/styles.module.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const DialogBox =({
        isOpen,
        size,
        handleClose,
        children,
        style,
        width,
        title,
        hide,
        closeButton
    }) => {

  function stopClick(event){
      event.stopPropagation();
  }

  return (
    <>
        <Dialog sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                width: "100%",
                display: hide ? "none" : "block",
                maxWidth: width || "880px",  // Set your width here
                },
            },
            }}onClose={handleClose} onClick={stopClick} maxWidth={size} fullWidth={true} aria-labelledby="customized-dialog-title" open={isOpen}>
            <div style={{minHeight: "50px", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", color: "#fff", fontSize: "20px", display:"flex", alignItems: "center", justifyContent:"center", position:"relative"}}>
                <div style={{display: "flex", padding: "10px"}}>
                    <div style={{display:"flex", justifyContent:"center", alignItems: "center", width: "100%", flexWrap: "wrap"}}>
                        {title}
                    </div>
                </div>
                {closeButton ? 
                    <div style={{position: "absolute", top: "6px", right: "20px"}} onClick={handleClose}>
                        <div title="Close" style={{backgroundColor: "#f0f0f0", color: "#2F588A", borderRadius: "20px", fontSize: "24px", width: "40px", height: "40px", cursor: "pointer", border: "1px solid #2F588A", display: "flex", justifyContent: "center", alignItems: "center"}}>X</div>
                    </div>
                : 
                    null
                }
            </div>
            <div className={globalStyles.panel} style={{color: "#121312", height: "100%", borderRadius: "0px 0px 10px 10px", overflow: "auto"}}>
                <DialogContent style={{...style, fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", borderRadius: "0px 0px 10px 10px", padding: "0px", border: "5px solid #2F588A", backgroundColor:"#cdcdcd", fontSize: "14px", overflow:"hidden"}}>
                    {React.cloneElement(children, {isOpen})}
                </DialogContent>
            </div>
        </Dialog>
        </>
    )
}

export default DialogBox;