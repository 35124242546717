import React, { useState, useEffect } from 'react';
import {withContext} from '../Context/context';
import { withPageState } from '../Context/PageState';
import DialogBox from '../Dialogs/dialogBox';
import DamageDialog from '../Dialogs/damageDialog';
import * as SAVE from '../../constants/save';
import { applyDamage } from '../../services/hitPointsService';
import {addArmourPiece} from '../../services/inventoryService';
import AddItemButton from './buttons/addItemButton';
import AddArmourDialog from './dialogs/addArmourDialog';

const HitLocationPanel = (props) => {

    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState();
    const [armourEnchantment, setArmourEnchantment] = useState(0);
    const [defense, setDefense] = useState(props.providerData.character.defense || 0);

    useEffect(() => {
        props.providerData.character.hitPoints.locations.forEach(location => {
            var index = props.providerData.character.armour.findIndex(obj => (obj.covers.find(item => item === location.name) && obj.equipped));
            if (index < 0) {
                location.armour = {}
            }
            else {
                location.armour = {...props.providerData.character.armour[index]};
            }
        })
    },[props.providerData.character.armour])

    useEffect(() => {
        var armour = 0;
        var defense = 0;
        if (props.providerData.character.enchantments) {
            props.providerData.character.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.name === "armourModifier") {
                        armour += effect.value;
                    }
                    if (effect.name === "defenseModifier") {
                        defense += effect.value;
                    }
                }) 
            });

        }
        setArmourEnchantment(armour);
        setDefense(defense);

    },[props.providerData.character.enchantments ])

    function openDialog(location) {
        if (!props.readOnly) {
            setLocation(location);
            setOpen(true);
        }
    }

    function closeDialog() {
        setOpen(false);
    }

	const update = (item, change, ignoreArmour, fallingDamage, modifier) => {
        var updates = [];
        var updatedHitPoints = {...props.providerData.character.hitPoints};
        updatedHitPoints.locations.forEach(loc => {
            if (loc.name === item.name) {
                if (modifier !== loc.modifier) {
                    loc.modifier = modifier;
                }
            }
        })
        updates.push({fieldName: "hitPoints", value: updatedHitPoints});
        if (change !== 0) {
            var updatedHitPoints = applyDamage({...props.providerData.character.hitPoints}, item, change, ignoreArmour, fallingDamage, armourEnchantment)
            updates.push({fieldName: "hitPoints", value: updatedHitPoints});

            var conditions = [];
            var result = getCurrent(location);
            if (result <= (location.hitPoints * -2)) {
                conditions.push({name: location.conditions[2].name, severity: location.conditions[2].severity})
            }
            else if (result <= (location.hitPoints * -1)) {
                conditions.push({name: location.conditions[1].name, severity: location.conditions[1].severity})
            }
            else if (result <= 0) {
                conditions.push({name: location.conditions[0].name, severity: location.conditions[0].severity})
            }

            if (conditions.length > 0) {
                updates.push({fieldName: "conditions", value: conditions})
            }
        }
        else 
        props.update(updates, SAVE.YES)
		closeDialog();
	}

    function getCurrent(location) {
        var result = location.hitPoints;
        if (location.wounds && location.wounds.length > 0) {
            var damage = location.wounds.reduce(function(a,b) {return a + b;})
            result -= damage;
        }
        return result;
    }

    function addArmour(armourPiece) {
        var updates = addArmourPiece(armourPiece, props.providerData.character)
        props.update(updates, SAVE.NO)
    }

    return (
        <div style={{position: "relative", fontSize: "20px"}}>
            <div>
                <div style={{backgroundImage: "url('https://storage.googleapis.com/runequest/runes/man-rune-600x600.png')", backgroundRepeat: "no-repeat", height: "240px", width:"240px", border: "2px solid #2F588A", borderRadius: "10px", position: "relative", padding: "5px", backgroundSize: "contain"}}></div>
                {getCurrent(props.providerData.character.hitPoints.locations[0]) <= 0 ? 
                <div style={{position: "absolute", top: 161, left:157, width: "110px"}}><img src="https://storage.googleapis.com/runequest/hitLocations/right-leg-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[1]) <= 0 ? 
                <div style={{position: "absolute", top: 164, left:20}}><img src="https://storage.googleapis.com/runequest/hitLocations/left-leg-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[2]) <= 0 ? 
                <div style={{position: "absolute", top: 132, left:90}}><img src="https://storage.googleapis.com/runequest/hitLocations/abdomen-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[3]) <= 0 ? 
                <div style={{position: "absolute", top: 97, left:86}}><img src="https://storage.googleapis.com/runequest/hitLocations/chest-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[4]) <= 0 ? 
                <div style={{position: "absolute", top: 94, left:164, width: "110px"}}><img src="https://storage.googleapis.com/runequest/hitLocations/right-arm-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[5]) <= 0 ? 
                <div style={{position: "absolute", top: 94, left:8}}><img src="https://storage.googleapis.com/runequest/hitLocations/left-arm-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                {getCurrent(props.providerData.character.hitPoints.locations[6]) <= 0 ? 
                <div style={{position: "absolute", top: 9, left:79, width: "126px"}}><img src="https://storage.googleapis.com/runequest/hitLocations/head-critical.png" style={{width: "75%"}} /></div>
                :
                null
                }
                <div style={{position: "absolute", top:20, left:30, fontSize:"24px"}}>L</div>
                <div style={{position: "absolute", top:20, left:232, fontSize:"24px"}}>R</div>
                {props.providerData.character.hitPoints && props.providerData.character.hitPoints.locations ? 
                    props.providerData.character.hitPoints.locations.map((location, index) => {
                        return(
                            <div key={index} style={{fontSize: "15px", position: "absolute", top:location.top, left:location.left, borderRadius: "5px", display:"flex", cursor: props.readOnly ? "default": "pointer", border: "1px solid #2f588a", backgroundColor: props.readOnly ? "#fff" : "#2F588A", color: props.readOnly ? "#121312" : "#fff", padding: "2px"}} onClick={() => openDialog(location)}>{((location.armour.points || 0) + (location.naturalArmour || 0) + armourEnchantment)}/{getCurrent(location)}</div>
                        );
                    })
                :
                null
                }
                    <div style={{position: "absolute", top: "210px", left: "76px"}}>
                        {
                            props.pageState.editMode ? 
                                <div style={{display: "flex", alignItems: "center", fontSize: "16px"}}>
                                    <div >Add Armor</div>
                                    <AddItemButton title="Add armor" addTitle="Add armor" addItem={addArmour} iconColour="#121312" width="900px" style={{marginRight: "5px"}}>
                                        <AddArmourDialog />
                                    </AddItemButton>

                                </div>
                            : 
                            defense > 0 ? 
                                <div style={{color: "#851113", fontSize: "20px", fontWeight: "bold"}}>-{defense}% to hit</div>
                            :
                            null}
                    </div>
            </div>

            <div>
                <DialogBox isOpen={open} width="600px" title="Apply Damage/Healing">
                    <DamageDialog close={closeDialog} enchantments={props.providerData.character.enchantments} location={location} update={update} />
                </DialogBox>
            </div>
        </div>
    )
}

export default withContext(withPageState(HitLocationPanel));