import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import HomelandCards from '../step1Homeland/homelandCards';
import DialogBox from '../../Dialogs/dialogBox';
import SelectTribeDialog from './selectTribeDialog';

const SelectHomelandDialog = (props) => {

    const [selected, setSelected] = useState();
    const [open, setOpen] = useState(false);
    const [tribe, setTribe] = useState(null);

    useEffect(() => {
        setSelected(props.homeland);
    },[props.homeland]);

    useEffect(() => {
        setTribe(props.tribe)
    },[props.tribe]);

    function handleSelect(homeland) {
        setSelected(homeland);
        if (homeland.name === "Prax") {
            setOpen(true);
        }
        else {
            setTribe(null)
        }
    }
	  
	function handleOkClick(){
        props.close();
        props.handleSelect(selected, tribe);
	}

    function selectTribe(selectedTribe) {
        setTribe(selectedTribe);
        setOpen(false);
    }

    return (
        <div style={{padding: "10px"}}>	
            <HomelandCards homelands={props.homelands} selected={selected} tribe={tribe} size={{width: "260px", height: "178px", fontSize: "20px"}} handleSelect={handleSelect} />
            <div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
                <Button onClick={handleOkClick} disabled={!selected} id="diceButton" color="primary" variant="contained" style={{width: "100px"}}>Ok</Button>
                <Button onClick={() => {props.close()}} id="diceButton" color="primary" variant="outlined" style={{marginRight:"30px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
            </div>
            {open ? 
            <DialogBox isOpen={open}>
                <SelectTribeDialog homeland={selected} tribe={tribe} close={()=> setOpen(false)} select={selectTribe}></SelectTribeDialog>
            </DialogBox>
            : 
            null
            }
       </div>
    );
}

export default SelectHomelandDialog;