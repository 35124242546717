import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cult from './cult';
import { withPageState } from '../Context/PageState';
import AddCultDialog from './cultDialog';
import * as SAVE from '../../constants/save';
import RuneSpell from './runeSpell';
import { withContext } from '../Context/context';
import PanelHeader from '../Controls/panelHeader';

const RuneMagic = (props) => {

    const addCult = (selectedCult, rank) => {
        var updatedCults = [...props.providerData.character.cults];
        updatedCults.push({...selectedCult, id: uuidv4()});
        props.update([{fieldName: "cults", value: updatedCults}], SAVE.NO); 
    };

    function deleteCult(cult) {
        var updatedCults = [...props.providerData.character.cults];
        var index = updatedCults.findIndex(item => item.name === cult.name);
        if (index > -1) {
            updatedCults.splice(index, 1);
            props.update([{fieldName: "cults", value: updatedCults}], SAVE.NO); 
        }
    }

    return(
        <>
            {props.providerData.type === "character" ? 
                <PanelHeader title={props.pageState.editMode ? "Cults": "Rune Magic"} addTitle="Add cult" addItem={addCult} width="920px">
                    <AddCultDialog entity={props.providerData.character} update={props.update} />
                </PanelHeader>
            : 
                null
            }
            <div >
                {props.providerData.character.cults.map((cult, index) => {
                    return (
                        <Cult diceRoller={props.diceRoller} key={index} headerBar={props.headerBar} cult={cult} update={props.update} character={props.character} processResult={props.processResult} deleteCult={deleteCult} displayItem={props.displayItem} powGain={props.powGain} />
                    )
                })}
                {props.providerData.type === "character" ? 
                    null
                : 
                    <>
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", fontSize: "13px", backgroundColor: "#2F588A", color: "#fff", padding: "2px"}}>
                        <div>Matrices</div>
                    </div>
                    {props.providerData.character.matrices.map((matrix, index) => {
                        if (matrix.runeSpells) {
                            return (
                                <div key={index} style={{display:"flex"}}>
                                    {matrix.runeSpells.map((spell, spellIndex) => {
                                        return (
                                            <RuneSpell diceRoller={props.diceRoller} key={spellIndex} matrix={matrix} storage={props.storage} spellcaster={props.providerData.character} character={props.character} spell={spell} processResult={props.processResult} update={props.update} displayItem={props.displayItem} powGain={props.powGain} />
                                        )
                                    })}
                                </div>
                            )
                        }
                    })}
                </>
            }
            </div>
        </>
    );
}

export default withContext(withPageState(RuneMagic));