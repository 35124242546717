import React from "react";
import styles from '../builder.module.css'

const SmallHomelandCard = (props) => {

    return (
        <div onClick={() => props.select("pf")} className={props.selected ? styles.selected : styles.unselected} style={{opacity: props.obscure ? 0.5 : 1}} >
            <img src={props.imageSrc} style={{height: "100px", width: "100px", borderRadius: "5px", backgroundColor: "#cdcdcd"}} />
            <div style={{padding: "0px 15px", fontSize:"16px", width: "150px"}}>
                <div style={{marginTop: "-10px", color: "#2F588A", marginBottom: "10px"}}>{props.title}</div>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", height: "80px"}}>
                    {props.tribe && props.tribe.name ? 
                        <div>
                            <div>{props.homeland.name}</div>
                            <div>({props.tribe.name})</div>
                        </div>
                        : 
                        <div>{props.homeland.name}</div>
                    }
                    {props.changeText ? 
                        <div style={{fontSize: "14px"}}> (click to change)</div>
                    : 
                    null
                    }
                </div>  
            </div>
        </div>
    )
}

export default SmallHomelandCard