import React, { useEffect, useState } from 'react';
import globalStyles from '../../Styles/styles.module.css';
import { withPageState } from '../../Context/PageState';
import DialogBox from '../../Dialogs/dialogBox';
import SpiritCombatDamageDialog from '../../Combat/spiritCombatDamageDialog';
import * as SAVE from '../../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import Incrementor from '../../Controls/incrementor';
import { withContext } from '../../Context/context';
import DiceButton from '../../Buttons/diceButton';
import DamageButton from '../../Buttons/damageButton';
import EditBox from '../../Controls/editBoxSingle';

const SpiritCombatPanel = (props) => {

    const [skill, setSkill] = useState(null);
    const [spiritCombat, setSpiritCombat] = useState();
    const [magicPoints, setMagicPoints] = useState();
    const [open, setOpen] = useState(false);
    const [enchantments, setEnchantments] = useState([]);

    useEffect(() => {
        var enchantments = [];
        var modifier = 0;
        var updatedSpiritCombat = {...props.providerData.character.spiritCombat} || {}
        if (props.providerData.character.enchantments) {
            props.providerData.character.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.name === "spiritArmourModifier") {
                        modifier += effect.value;
                    }
                })
            });

        }
        if (modifier > 0) {
            updatedSpiritCombat.armour = modifier;
        }
        setSpiritCombat(updatedSpiritCombat)
        setEnchantments(enchantments);

    },[props.providerData.character.enchantments ])

    useEffect(() => {
        var spiritCombatSkill = {name: "Spirit Combat", skillCategory: "Magic", parentId: "605f0fc0-bea7-11ea-afe0-c72b6ad5d25a"}
        if (props.entity.skills) {
            var index = props.entity.skills.findIndex(skill => skill.name === "Spirit Combat");
            if (index > -1) {
                spiritCombatSkill = props.entity.skills[index];
            }
            else {
                spiritCombatSkill.value = props.entity.characteristics.POW.baseValue * 5;
                spiritCombatSkill.id = uuidv4();
            }
        }
        else {
            spiritCombatSkill.value = props.entity.characteristics.POW.baseValue * 5;
            spiritCombatSkill.id = uuidv4();
        }
        setSkill(spiritCombatSkill);
    },[props.entity]);

    useEffect(() => {
        var index = props.providerData.character.magicPoints.findIndex(item => item.entityId === props.entity.id)
        setMagicPoints(props.providerData.character.magicPoints[index]);
    },[props.providerData.character.magicPoints, props.entity]);

    function applyDamage(change, ignoreArmour) {
        var damage = ignoreArmour ? change : change - spiritCombat.armour;
        if (damage > 0) {
            var updatedCharacter = {...props.providerData.character};
            var index = updatedCharacter.magicPoints.findIndex(item => item.entityId === props.entity.id)
            if (damage > updatedCharacter.magicPoints[index].current) damage = updatedCharacter.magicPoints[index].current;
            updatedCharacter.magicPoints[index].current -= damage
            props.update([{fieldName: "magicPoints", value: updatedCharacter.magicPoints}], SAVE.YES)
        }
    }

    function handleValueChange(value) {
        var updatedSkill = {...skill};
        updatedSkill.value = value;
        setSkill(updatedSkill);
        update(updatedSkill, SAVE.NO)
    }

    function update(item, persist) {
        var updatedSkills = [...props.entity.skills];
        var index = updatedSkills.findIndex(skill => skill.name === "Spirit Combat");
        updatedSkills[index] = item
        props.update([{fieldName: "skills", value: updatedSkills}], persist)
    }

    function modifyArmour(value, event) {
        event.stopPropagation();
        var updatedItem = {...spiritCombat};
        if (!updatedItem.armour) updatedItem.armour = 0;
        updatedItem.armour += value;
        if (updatedItem.armour < 0) {
            updatedItem.armour = 0
        }
        setSpiritCombat(updatedItem);
        props.update([{fieldName: "spiritCombat", value: updatedItem}], SAVE.DELAYED);
    }

    function processResult(roll, hidden) {
        var messageText={critical: " achieved a critical success with {pronoun} Spirit Combat skill", special: " achieved a special success with {pronoun} Spirit Combat skill", success: " succeeded with {pronoun} Spirit Combat skill", failure: " failed with {pronoun} Spirit Combat skill", fumble: " fumbled {pronoun} Spirit Combat skill"};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment, hidden: hidden}
        props.processResult(dieRoll, {...roll.item, type:"skills"}, "Spirit Combat Skill")
    }

    function displayItem() {
        var item = {weapon: spiritCombat, skill: skill}
        props.displayItem({type: "Spirit Combat", item: item})
    }

    function processDamage(diceRolls, result, location, diceRolled) {
        props.processDamage(diceRolls, result, location, diceRolled, props.entity.spiritCombat);
    }

    if (spiritCombat) {
        if (props.pageState.editMode) {
            return (
                <>
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", padding: "2px"}} >
                <div onClick={displayItem} style={{display:"flex", alignItems: "center"}}>
                    <div style={{width: "160px"}}>{skill.name}</div>
                    <div style={{width: "60px"}} />
                </div>
                <div style={{width: "60px", display:"flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "50px"}}>
                        <EditBox size="sm" number value={skill.value} onChange={handleValueChange} />
                    </div>
                </div>
            </div>
                </>
            )
        }
        else {
            return(
                <div style={{marginLeft: "10px"}}>
                    <div style={{margin: "2px"}}> 
                        <div style={{display: "flex"}}>
                            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}}>
                            <div className={globalStyles.expandableTile} style={{cursor: "zoom-in"}} onClick={displayItem}>
                                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", width: "200px", padding: "2px", cursor: "zoom-in"}}>
                                        <div>Attack/Defend</div>
                                        <div className={globalStyles.diceButton} style={{border: "1px solid #2F588A", borderRadius: "3px", textAlign:"center", width: "50px", padding: "2px", cursor: "pointer"}}>
                                            <DiceButton diceRoller={props.diceRoller} item={skill} processResult={processResult} entity={props.entity} >
                                                <ButtonValue />
                                            </DiceButton>
                                        </div>
                                    </div>
                                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", width: "200px", padding: "2px", marginTop: "10px"}}>
                                        <div>Damage</div>
                                        {/* <div style={{backgroundColor: "#851113", color: "#fff", borderRadius: "3px", padding: "2px", width: "90px", textAlign:"center"}}> */}
                                            <div className={globalStyles.damageButton} style={{width: "90px", cursor: "pointer", border: "1px solid #851113", borderRadius: "3px", padding: "2px", width: "90px", textAlign:"center", marginLeft: "10px"}}>
                                                <DamageButton diceRoller={props.diceRoller} processDamage={processDamage} entity={props.entity} weapon={props.entity.spiritCombat} />
                                            </div>
                                        {/* </div> */}
                                    </div>
                                    <div style={{padding: "2px", marginTop: "10px"}}>
                                        <Incrementor style={{width: "200px"}} label="Spiritual Armor:" currentValue={spiritCombat.armour} increment={modifyArmour} />
                                    </div>
                            </div>
                                <div style={{position: "relative", marginTop: "-5px"}}>
                                    <div style={{fontSize: "15px", position: "absolute", top:"44px", left:"-20px", borderRadius: "5px", display:"flex", cursor:"pointer", zIndex: 99, backgroundColor: "#2F588A", color: "#fff", padding: "2px"}} onClick={() => setOpen(true)}>{spiritCombat.armour || 0}/{magicPoints.current || 0}</div>
                                    <div style={{backgroundImage: "url('https://storage.googleapis.com/runequest/runes/spirit-rune-100x100.png')", backgroundRepeat: "no-repeat", height: "90px", width:"90px", marginRight: "-10px", position: "relative", padding: "5px", backgroundSize: "contain"}}></div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <DialogBox isOpen={open} width="600px" title="Apply Spirit Combat Damage">
                            <SpiritCombatDamageDialog close={() => setOpen(false)} enchantments={enchantments} update={applyDamage} />
                        </DialogBox>
                    </div>
                </div>
            );
        }
    }
    else {
        return null;
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}

export default withContext(withPageState(SpiritCombatPanel));