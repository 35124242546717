import { v4 as uuidv4 } from 'uuid';
import { getRuneSpell } from '../../services/dataService';

const generateCult = (cult, experience, npcId, campaign) => {
    var generatedCults = [];
    var points = experience.runePoints + Math.ceil(Math.random()*(3));
    var runes = [];
    cult.runes.forEach(rune => {
        runes.push(rune.name);
    })
    var spells = selectSpells(cult, points, runes, campaign)
    var newCult = {
        id: uuidv4(),
        parentId: cult.parentId, 
        name: cult.name,
        pantheon: (cult.pantheons && cult.pantheons[0]) || "",
        rank: "Initiate",
        runes: runes,
        runeSpells: spells
    };
    newCult.runePoints = [
        {
            current: points,
            total: points,
            type: "Character",
            entityId: npcId
        }
    ];
    generatedCults.push(newCult);
    return generatedCults;


    function createSpell(spell, campaign) {
        const spellDetails = getRuneSpell(spell.parentId, campaign)
        if (spellDetails) {
            var newSpell = {...spell};
            newSpell.id = uuidv4();
            newSpell.points = spellDetails.points;
            newSpell.runes = [...spellDetails.runes];
            newSpell.stackable = spellDetails.stackable || false;
            if (spellDetails.target) {
                newSpell.target = {...spellDetails.target}
            }
            return newSpell
        }
    }

    function selectSpells(cult, spellCount, runes, campaign){
        var runeSpells = [];
        cult.commonSpells.forEach((commonSpell) => {
            var newSpell = createSpell(commonSpell, campaign);
            if (newSpell) {
                newSpell.common = true;
                newSpell.runes = [...runes]
                runeSpells.push(newSpell);
            }
        });
        var failsafe = 0;
        var availableSpells = [...cult.runeSpells]
        var count = 0;
        while (count < spellCount) {
            failsafe ++;
            var randomNumber = Math.ceil(Math.random()*(100));
            availableSpells.forEach((runeSpell) => {
                if (randomNumber <= runeSpell.possession) {
                    if (runeSpells.findIndex(obj => obj.parentId === runeSpell.parentId) < 0) {
                        var newSpell = createSpell(runeSpell, campaign);
                        runeSpells.push(newSpell);
                        count ++;
                        randomNumber = 999;
                    }
                }
                if (count >= availableSpells.length) count = 999;
            });
            if (failsafe > 100) {
                return runeSpells;
            }          
        }
        return runeSpells;
    }
  }

export default generateCult;