import React from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";

const DeleteDialog = (props) => {

    return (
        <Dialog open={props.confirm} maxWidth="xs" >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <div>{"Are you sure you want to delete this " + props.type + "?"}</div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => props.setConfirm(false)}>
                Cancel
                </Button>
                <Button onClick={props.delete}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog;