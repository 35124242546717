import React, {useState, useEffect} from 'react';
import GiftListItem from './giftListItem';

export default function GiftList(props) {

    const [gifts, setGifts] = useState([]);

    useEffect(() => {
        if (props.cult.gifts) {
            setGifts([...props.cult.gifts]);
        }
    },[props.cult.gifts]);

    if (gifts) {
        return (
            <>
            
                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "18px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Gifts</div>
                <div style={{height: "400px"}}>
                    <div style={{display:"flex", fontSize: "15px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                        {props.noSelect ? 
                            null
                        : 
                            <div style={{width:"80px"}}>{props.selectionTitle || "Select"}</div>
                        }
                        <div style={{width:"600px", marginLeft:"5px", textAlign:"left"}}>Gift</div>
                        <div style={{width:"80px", marginLeft:"5px", textAlign:"center"}}>Geases</div>
                    </div>

                    {gifts.map((item, index) => {
                        var disabled = (props.selectedGifts && props.selectedGifts.length >= props.max) ? true : false; 
                        var xxx = props.selectedGifts.findIndex(obj => obj.id === item.id);
                        var gift = {...item, checked: xxx > -1 ? true: false}
                        if (xxx > -1 && props.selectedGifts[xxx].selected) {
                            gift.selected = {...props.selectedGifts[xxx].selected};
                        }
                            return (
                                <GiftListItem key={index} disabled={disabled} index={index} gift={gift} cult={props.cult} select={props.select} max={props.max} checked={props.selectedGifts.findIndex(item => item.id === gift.id) > -1} />
                            )
                        })
                    }
                </div>
            </>

        )
    }
    else {
        return null;
    }
}