import React, {useState, useEffect, useRef} from "react";
import Incrementor from "../Controls/incrementor";
import * as SAVE from '../../constants/save';
import styles from './encounters.module.css'
import {getAvailableMagicPoints} from '../../services/magicPointsService';
import {incrementAvailableMagicPoints} from '../../services/magicPointsService';
import {decrementAvailableMagicPoints} from '../../services/magicPointsService';

const MagicPoints = (props) => {

    const [open, setOpen] = useState(false);
    const [magicPoints, setMagicPoints] = useState(0);
    const pointsBox = useRef(null)

    useEffect(() => {
        var points = getAvailableMagicPoints(props.entity);
        setMagicPoints(points);
    },[props.entity.magicPoints])

    const increment = (value) => {
        var updatedCharacter = {...props.entity};
        var updates = [];
        if (value > 0 && magicPoints.current < magicPoints.total) {
            var updatedMagicPoints = {...magicPoints};
            updatedMagicPoints.current += value; 
            setMagicPoints(updatedMagicPoints);
            updates = incrementAvailableMagicPoints(updatedCharacter, 1)
        }
        else if (value < 0 && magicPoints.current > 0) {
            var updatedMagicPoints = {...magicPoints};
            updatedMagicPoints.current -= value; 
            setMagicPoints(updatedMagicPoints);
            updates = decrementAvailableMagicPoints(updatedCharacter, 1)
        }
        props.update(updates, SAVE.DELAYED)
    }

    const display = () => {
        setOpen(true);
    }

    return (
        <div style={{position: "relative"}}>
            <div className={styles.diceButton} style={{display:"flex", backgroundColor: open ? "#bcd0e8" : ""}} onClick={display}>
                <div style={{fontWeight: "bold"}}>MPs:&nbsp;</div> 
                <div style={{marginLeft: "5px"}}>{magicPoints?.current}/{magicPoints?.total}</div>
            </div>
            <form ref={pointsBox} onMouseOver={() => pointsBox.current.focus()} onBlur={() => {setOpen(false)}} tabIndex={0}>
                {open ? 
                    <div style={{backgroundColor: "#fff", padding: "10px", border: "1px solid #2f588a", borderRadius: "5px", position: "absolute", zIndex:30}}>
                        <Incrementor label="Magic points: " currentValue={magicPoints[0]?.current} totalValue={magicPoints[0]?.total} increment={increment} />
                    </div>
                : 
                null
                }
            </form>
        </div>
    )
}

export default MagicPoints;