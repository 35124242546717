import { getStatValue } from "./attributeService";

function getCategoryModifiers(characteristics) {

    var stats = {
        STR: characteristics.STR, 
        SIZ:characteristics.SIZ,
        DEX:characteristics.DEX,
        INT:characteristics.INT,
        POW:characteristics.POW,
        CHA:characteristics.CHA
    }

    var modifiers = {
        Agility: getAgilityBonus(stats),
        Communication: getCommunicationBonus(stats),
        Knowledge: getKnowledgeBonus(stats),
        Magic: getMagicBonus(stats),
        Manipulation: getManipulationBonus(stats),
        Perception: getPerceptionBonus(stats),
        Stealth: getStealthBonus(stats)
    }
    return modifiers;
}

function getValue(stat) {
    if (stat) {
        var value = getStatValue(stat);
        value += (stat.enchantmentModifier || 0)
        return value;        
    }
    else {
        return 0;
    }
}

function getAgilityBonus(stats) {
    var value = getMinorModifier(getValue(stats.STR), 1);
    value += getMinorModifier(getValue(stats.SIZ), -1);
    value += getMinorModifier(getValue(stats.POW), 1);
    value += getMajorModifier(getValue(stats.DEX), 1);
    return value;
}

function getStealthBonus(stats) {
    var value = getMinorModifier(getValue(stats.POW), -1);
    value += getMajorModifier(getValue(stats.SIZ), -1);
    value += getMajorModifier(getValue(stats.DEX), 1);
    value += getMajorModifier(getValue(stats.INT), 1);
    return value;
}

function getManipulationBonus(stats) {
    var value = getMinorModifier(getValue(stats.STR), 1);
    value += getMinorModifier(getValue(stats.POW), 1);
    value += getMajorModifier(getValue(stats.INT), 1);
    value += getMajorModifier(getValue(stats.DEX), 1);
    return value;
}

function getCommunicationBonus(stats) {
    var value = getMinorModifier(getValue(stats.INT), 1);
    value += getMinorModifier(getValue(stats.POW), 1);
    value += getMajorModifier(getValue(stats.CHA), 1);
    return value;
}
function getKnowledgeBonus(stats) {
    var value = getMinorModifier(getValue(stats.POW), 1);
    value += getMajorModifier(getValue(stats.INT), 1);
    return value;
}

function getPerceptionBonus(stats) {
    var value = getMinorModifier(getValue(stats.POW), 1);
    value += getMajorModifier(getValue(stats.INT), 1);
    return value;
}

function getMagicBonus(stats) {
    var value = getMinorModifier(getValue(stats.CHA), 1);
    value += getMajorModifier(getValue(stats.POW), 1);
    return value;
}

function getMinorModifier(stat, modifier) {
    if (stat === 0) return 0;
    if (stat <= 4) return -5 * modifier;
    if (stat <=16) return 0;

    var index = 20; 
    var value  = 5;
    var safety = 0;
    while (true) {
        if (safety > 999) return null;
        if (stat <= index) {
            return value * modifier;
        }
        else {
            index += 4;
            value += 5;
        }
        safety++;
    }
}

function getMajorModifier(stat, modifier) {
    if (stat === 0) return 0;
    if (stat <= 4) return -10 * modifier;
    if (stat <=8) return -5 * modifier;
    if (stat <=12) return 0;

    var index = 16; 
    var value  = 5;
    var safety = 0;
    while (true) {
        if (safety > 999) return null;
        if (stat <= index) {
            return value * modifier;
        }
        else {
            index += 4;
            value += 5;
        }
        safety++;
    }
}


export {getCategoryModifiers}