import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import * as ROUTES from '../../constants/routes';
import EditBox from '../Controls/editBoxSingle';
import PasswordForget from '../PasswordForget';

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

const SignInFormBase = (props) => {

    const [state, setState] = useState({...INITIAL_STATE})
    const [error, setError] = useState(null);
    let navigate = useNavigate();

    const onSubmit = event => {
    const { email, password } = state;

    props.firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => {
        setState({ ...INITIAL_STATE });
        navigate(ROUTES.HOME);
    })
    .catch(error => {
        setError(error);
    });

    event.preventDefault();
    };

    const onChange = (name, value) => {
        var updatedState = {...state};
        updatedState[name] = value;
        setState(updatedState);
    };

    return (
        <div style={{margin: "0 auto", backgroundColor: "dedede", padding: "20px"}}>
            <div style={{fontSize: "32px", margin: "20px 0px"}}>Sign in</div>
            <form onSubmit={onSubmit}>
                <div style={{marginBottom: "20px"}}>
                    <EditBox autoFocus width="338px" size="xl" fieldLabel="Email address" name="email" value={state.email} onChange={(value) => onChange("email", value)} />
                </div>
                <div>
                    <EditBox width="338px" size="xl" fieldLabel="Password" name="password" type="password" value={state.password} onChange={(value) => onChange("password", value)} />
                </div>
                <div style={{display:"flex", flexDirection: "row-reverse", justifyContent:"space-between", marginTop: "20px"}}>
                    <Button style={{width:"140px", height: '40px'}} type="submit" color="primary" variant="contained">Sign in </Button>
                    <PasswordForget />
                </div>
            </form>
            {error && <p>{error.message}</p>}
        </div>
    );
}

export default SignInFormBase;