import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import DialogBox from '../Dialogs/dialogBox';
import DialogButtons from '../Dialogs/dialogButtons';

const ChapterContextPanel = (props) => {

	const [chapters, setChapters] = useState([]);
	const [chapter, setChapter] = useState({});
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.campaign.chapters) {
			setChapters([...props.campaign.chapters]);
		}
	},[props.campaign.chapters])

	function editChapter(chapter) {
		setChapter(chapter);
		setOpen(true);
	}

	function update(name) {
		var updatedChapter = {...chapter};
		updatedChapter.name = name;
		setChapter(updatedChapter);
	}

	function editChapter(chapter) {
		setChapter(chapter);
		setOpen(true);
	}

	function addChapter() {
		setChapter({});
		setOpen(true);
	}

	function updateChapter() {
		setOpen(false);
		if (chapter && chapter.name) {
			var updatedChapters = [...chapters];
			var index = updatedChapters.findIndex(obj => obj.id === chapter.id);
			if (index > -1) {
				updatedChapters[index] = {...chapter}
				setChapters(updatedChapters);
			}
			else {
				updatedChapters.push({name: chapter.name, id: uuidv4(), adventures: [], });
				setChapters(updatedChapters)
			}
			var updatedCampaign= {...props.campaign};
			updatedCampaign.chapters = updatedChapters;
			props.update(updatedCampaign, true);
		}
	}

    return (
		<>
			<div style={{fontSize: "14px", lineHeight: "1"}}>
				<div style={{height: "800px", overflow: "auto", padding: "5px"}}>
				<h3>Chapters</h3>
						<div>To assist with the organization of your game, RuneQuesting divides each campaign into logical blocks of time, called chapters. Chapters might be each year in the game world e.g. '1625', '1626' etc, or broken up by geographical location e.g. 'Adventures in Jonstown', or 'Exploring the Rubble'. Each chapter will consist of a number of adventures, and so grouping these adventures into logical collections with a descriptive name will help to make the running of your game that much smoother. </div>
				<div>
					<hr />
					<div style={{margin: "10px 0px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
						<div>Add new chapter:</div>
					<Button onClick={addChapter} style={{width: "100px"}} color="primary" variant="contained">Add</Button>
					</div>
					<hr />
				<div id="header" style={{display: "flex", alignItems: "center", fontWeight: "bold", marginTop: "10px"}}>
					<div style={{width: "400px"}}>Name</div>
				</div>
				<div className={globalStyles.panel}  style={{height: "500px", overflow: "auto"}}>
				{chapters.map((chapter, index) => {
						return(
							<div key={index} id="row" style={{display: "flex", alignItems: "center", padding: "10px 0px"}}>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
									<div style={{width: "400px"}}>{chapter.name}</div>
									<ModeEditIcon style={{color: "#2f588a", height: "24px", width: "24px", cursor: "pointer"}} onClick={() => editChapter(chapter)} />
								</div>
							</div>
						)
					})}
				</div>
			</div>
				</div>
			</div>
			<DialogBox isOpen={open} width="500px" title={chapter && chapter.id ? "Edit Chapter Name" : "Add Chapter"}>
				<>

				<div style={{padding: "20px"}}>
					<EditBox width="430px" value={chapter.name} onChange={update} placeholder="Chapter name" autoFocus />
				</div>
				<DialogButtons handleOkClick={updateChapter} handleCancelClick={() => setOpen(false)} />
				</>
			</DialogBox>
		</>
    )
}
export default withFirebase(ChapterContextPanel);