import React, {useState, useEffect} from 'react';
import { withContext } from '../Context/context';
import CharacterRuneSpellRow from '../Characters/tiles/runeSpellRow';
import NPCRuneSpellRow from '../Encounters/tiles/runeSpellRow';

export const RuneSpellButtonTile = (props) => {

    const [item, setItem] = useState(props.item);
    const tile = props.element ? props.element : props.providerData.type === "character" ? <CharacterRuneSpellRow /> : <NPCRuneSpellRow />;

    useEffect(() => {
        setItem({...props.item});
    },[props.item]);


    var strikeRank = 1;
    strikeRank += item.boost || 0;

    return(
        React.cloneElement(tile, {...props, item: item, strikeRank: strikeRank })
    )
}

export default withContext(RuneSpellButtonTile);