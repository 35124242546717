import React, { useState} from 'react';
import ResetIcon from '../../../icons/reset-icon.svg';
import globalStyles from '../../Styles/styles.module.css'
import DialogBox from '../../Dialogs/dialogBox';
import ResetDialog from '../dialogs/resetDialog';

export default function ResetButton(props) {
    const [open, setOpen] = useState(false);
   
    return (
        <>
            <div onClick={() => setOpen(true)} className={globalStyles.floatingButton}>
                <img style={{width:"40px"}} src={ResetIcon} title="Reset your character" alt="Reset button" />
            </div>
            <DialogBox isOpen={open} size="sm" title="Reset character bonuses">
                <ResetDialog update={props.update} displayMessage={props.displayMessage} character={props.character} user={props.user} close={() => setOpen(false)} />
            </DialogBox>
        </>
    );
}