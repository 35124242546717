import React, { useState, useEffect } from 'react';
import globalStyles from '../../Styles/styles.module.css';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import {calculateDamageBonus, calculateSpiritCombatDamage, calculateMagicPoints, calculateStrikeRanks, calculateMaxEncumbrance} from '../../../services/attributeService';
import {calculateHitPoints, getHealingRate} from '../../../services/hitPointsService';
import {getCategoryModifiers} from '../../../services/skillsService';
import StatBlock from './statBlock';
import {Button, Switch} from '@mui/material';

const Characteristics = (props) => {

    const [statDetails, setStatDetails] = useState([]);
    const [modifiers, setModifiers] = useState({})
    const [totalStatValue, setTotalStatValue] = useState(0);
    const [pointsRemaining, setPointsRemaining] = useState(0);
    const [complete, setComplete] = useState(false);
    const [showClass, setShowClass] = useState();
    const [primary, setPrimary] = useState("");
    const [secondary, setSecondary] = useState("");
    const [primaryStats, setPrimaryStats] = useState([]);
    const [secondaryStats, setSecondaryStats] = useState([]);
    const [rerollOnes, setRerollOnes] = useState(false);
    const [rolling, setRolling] = useState("");

    const [attributes, setAttributes] = useState({
        magicPoints: 0,
        hitPoints: 0,
        healingRate: 0,
        damageBonus: {
            quantity: 0,
            dieType: 0
        },
        spiritCombatDamage: {
            quantity: 0,
            dieType: 0,
            modifier: 0
        },
        maxEncumbrance: 0,
        sizSR: 0,
        dexSR: 0
    });

    const [damageRoll, setDamageRoll] = useState({
        result: 0,
        diceRolled: "",
        show: false,
        location: 0
    })

    useEffect(() => {
        setStatDetails([...props.builder.stats.details])
        var characteristics = createCharacteristics(props.builder.stats.details)
        setModifiers(getCategoryModifiers(characteristics));
        updateAttributes(characteristics);
    },[props.builder.stats.details])

    useEffect(() => {
        var count = 0;
        var total = 0;
        var bonusPoints = 0;
        props.builder.stats.details.forEach(stat => {
            total += stat.baseValue;
            if (stat.baseValue > 0) count ++;
            bonusPoints += stat.currentValue - stat.baseValue;

        })
        var available = 92-total;
        if (available > 3) available = 3;
        available -= bonusPoints;

        setTotalStatValue(total);
        setPointsRemaining(available);
        displayRuneOptions();
        setStatDetails(props.builder.stats.details);
        if (count >= 7) {
            setComplete(true);
        }
        setPrimary(props.builder.stats.primary);
        setSecondary(props.builder.stats.secondary);
        if (props.builder.stats.primary && props.builder.stats.secondary) {
            props.disableNext(false);
        }
        if (props.options && props.options.rerollOnes) {
            setRerollOnes(true);
        }
    }, []);

    function createCharacteristics(stats) {
        var characteristics = {};
        stats.forEach(stat => {
            characteristics[stat.name] = stat;
            characteristics[stat.name].statBonus = (characteristics[stat.name].currentValue - characteristics[stat.name].baseValue) + (characteristics[stat.name].bonus || 0)
        })
        return characteristics;
    }

    function calculateCategoryModifiers(characteristics) {
        var mods = getCategoryModifiers(characteristics);
        setModifiers(mods);
        return mods;
    }

    function displayRuneOptions() {
        var primary = [];
        props.builder.runes.elementalRunes.forEach(rune => {
            if (primary.length == 0) {
                primary.push(rune);
            }
            else if (rune.value > primary[0].value) {
                primary = [rune];
            }
            else if (rune.value == primary[0].value) {
                primary.push(rune);
            }
        });
        var primaryValue = primary[0].value;
        var primaryOptions = []
        primary.forEach(primaryRune => {
            primaryOptions.push(primaryRune.characteristic);
        })
        primaryOptions.push("CHA")
        setPrimaryStats(primaryOptions);

        if (primaryOptions.length > 2) {
            setSecondaryStats([...primaryOptions]);
        }
        else {
            var secondary = [];
            props.builder.runes.elementalRunes.forEach(rune => {
                if (rune.value >= primaryValue) {}
                else if (secondary.length == 0 ) {
                    secondary.push(rune);
                }
                else if (rune.value > secondary[0].value) {
                    secondary = [rune];
                }
                else if (rune.value == secondary[0].value) {
                    secondary.push(rune);
                }
            });
            var secondaryOptions = []
            secondary.forEach(secondaryRune => {
                secondaryOptions.push(secondaryRune.characteristic);
            })
            secondaryOptions.push("CHA")
            setSecondaryStats(secondaryOptions);
        }
    }

    function reRollStats() {
        props.disableNext(true)
        setTotalStatValue(0);
        setPointsRemaining(3);
        setPrimary("");
        setSecondary("");
        setStatDetails([      {
            "baseValue": 0,
            "currentValue": 0,
            "description": "An adventurer’s STR represents raw muscular power. STR helps determine your adventurer’s damage bonus, the amount of encumbrance your adventurer can carry and still function normally, what weapons can be used, and influences the starting value for Agility skills (see page 58). STR is used for tests of physical strength.",
            "dieType": 6,
            "modifier": 0,
            "fullName": "Strength",
            "name": "STR",
            "quantity": 3
          },
          {
            "baseValue": 0,
            "currentValue": 0,
            "description": "CON is the measure of the health of your adventurer. It is a component in determining hit points and healing rate (see page 55–56). The CON of your adventurer is used to stay conscious when badly wounded, and to resist disease and poison, and helps determine the amount of encumbrance your adventurer can carry and still function normally.",
            "fullName": "Constitution",
            "dieType": 6,
            "modifier": 0,
            "name": "CON",
            "quantity": 3
          },
          {
            "baseValue": 0,
            "currentValue": 0,
            "fullName": "Size",
            "description": "SIZ measures the mass of your adventurer. It affects your ability to inflict and absorb damage. SIZ is a component in determining hit points and damage bonus: since mass helps increase the force of a blow. SIZ helps determine strike rank in combat. The Adventurer Sizes table (page 52) provides approximate values for height and weight for a humanoid adventurer, but are not intended to be restrictive. These are suggested ranges. You can define your adventurer’s actual height and/or weight higher or lower than these ranges, though generally increasing one should result in a corresponding decrease of the other.",
            "dieType": 6,
            "modifier": 6,
            "name": "SIZ",
            "quantity": 2
          },
          {
            "baseValue": 0,
            "currentValue": 0,
            "fullName": "Dexterity",
            "description": "This is the measure of how fast and accurately your adventurer can perform different functions such as fighting, dodging, and other athletic activities. DEX helps determine strike rank in combat.",
            "dieType": 6,
            "modifier": 0,
            "name": "DEX",
            "quantity": 3
          },
          {
            "baseValue": 0,
            "currentValue": 0,
            "fullName": "Intelligence",
            "description": "INT is a measure of your adventurer’s ability to deal with abstractions and memorization. It is an important characteristic modifier for Knowledge skills. It also determines how many sorcery spells your adventurer may memorize.",
            "dieType": 6,
            "modifier": 6,
            "name": "INT",
            "quantity": 2
          },
          {
            "baseValue": 0,
            "currentValue": 0,
            "description": "POW represents your adventurer’s ability to work magic. It is also the measure of your adventurer’s soul. A high POW shows the favor of the gods and integration with the universe; a low POW shows proportionately less. When casting magic, your POW is often pitted against others’ POW to see if your spells have their desired effect.",
            "fullName": "Power",
            "dieType": 6,
            "modifier": 0,
            "name": "POW",
            "quantity": 3
          },
          {
            "baseValue": 0,
            "fullName": "Charisma",
            "currentValue": 0,
            "description": "CHA is the measure of leadership and strength of personality. This is the ability to say “Follow me!” and find oneself leading a charge. It is more than mere appearance and should not be mistaken for beauty. It helps determine the starting bonus for Communication skills. It also limits how much Rune magic your adventurer can potentially cast and how many spirit magic spells they may possess.",
            "dieType": 6,
            "modifier": 0,
            "name": "CHA",
            "quantity": 3
          }]);
        setComplete(false);
    }

    function handleChange(event, primaryValue) {
        var stat = event.target.value;
        if (primaryValue) {
            if (primary) {
                modifyStat(primary, 0);
            }
            modifyStat(stat, 2)
            setPrimary(stat);

            if (secondary) {
                props.disableNext(false);
                var updatedBuilder = {...props.builder};
                updatedBuilder.stats.primary = stat;
                updatedBuilder.stats.secondary = secondary;
                props.update(updatedBuilder, true);
            }
        }
        else {
            if (secondary) {
                modifyStat(secondary, 0);
            }
            modifyStat(stat, 1)
            setSecondary(stat);
            if (primary) {
                props.disableNext(false);
                var updatedBuilder = {...props.builder};
                updatedBuilder.stats.primary = primary;
                updatedBuilder.stats.secondary = stat;
                props.update(updatedBuilder, true);
            }
        }
    }

    function updateAttributes(characteristics) {
        var updatedAttributes = {...attributes};
        updatedAttributes.magicPoints = calculateMagicPoints(characteristics);
        updatedAttributes.hitPoints = calculateHitPoints(characteristics, props.builder.hitPoints);
        updatedAttributes.healingRate = getHealingRate(characteristics);
        updatedAttributes.damageBonus = calculateDamageBonus(characteristics);
        updatedAttributes.spiritCombatDamage = calculateSpiritCombatDamage(characteristics);
        updatedAttributes.maxEncumbrance = calculateMaxEncumbrance(characteristics);
        var strikeRanks = calculateStrikeRanks(characteristics);
        updatedAttributes.sizSR = strikeRanks.SIZ;
        updatedAttributes.dexSR = strikeRanks.DEX;
        setAttributes(updatedAttributes);
    }

    function incrementStat(modifiedStat, value) {

        if (pointsRemaining == 3 && value < 0) return;
        if (pointsRemaining == 0 && value > 0) return;
        var updatedStatDetails = [...statDetails]
        var index = updatedStatDetails.findIndex(stat => stat.name === modifiedStat.name);
        var stat = updatedStatDetails[index] 
        if (stat.currentValue + value >= stat.baseValue) {
            stat.currentValue += value;
        }
        setPointsRemaining(pointsRemaining - value);
        var characteristics = createCharacteristics(updatedStatDetails);
        calculateCategoryModifiers(characteristics);
        setStatDetails(updatedStatDetails);
        updateBuilder(updatedStatDetails)
 }

    function modifyStat(statName, value) {
        var updatedStatDetails = [...statDetails]
        var index = updatedStatDetails.findIndex(stat => stat.name === statName);
        updatedStatDetails[index].bonus = value;
        if (((updatedStatDetails[index].currentValue + updatedStatDetails[index].bonus) > 18) && updatedStatDetails[index].currentValue > updatedStatDetails[index].baseValue) {
            var diff = updatedStatDetails[index].currentValue - updatedStatDetails[index].baseValue;
            updatedStatDetails[index].currentValue = updatedStatDetails[index].baseValue;
            setPointsRemaining(pointsRemaining + diff)
       }
        setStatDetails(updatedStatDetails);
        updateBuilder(updatedStatDetails)
    }
    
    function updateBuilder(updatedStatDetails) {
        var updatedBuilder = {...props.builder};
        updatedBuilder.stats.details = updatedStatDetails;
        var characteristics = createCharacteristics(props.builder.stats.details)
        var mods = getCategoryModifiers(characteristics);
        setModifiers(mods);
        updateAttributes(characteristics);
        updatedBuilder.categoryModifiers = mods;
        props.update(updatedBuilder, true)
    }

    function displayRuneBonuses(primaryBonus, width) {
        return (
            <div style={{fontSize: "15px", backgroundColor: "#fff", color: "#121312", marginTop: "20px", padding: "10px", border: "2px solid #2f588a", borderRadius: "10px", maxWidth: "300px"}}>
            <FormControl component="fieldset">
                <div style={{display: "flex", flexWrap: "wrap"}}>Select {primaryBonus ? "primary" : "secondary"} rune bonus</div>
                <RadioGroup aria-label="gender" row name="gender1" color="primary" value={primaryBonus ? primary : secondary} onChange={(event) => handleChange(event, primaryBonus)}>
                    { primaryBonus ? 
                        primaryStats.map(stat => {
                            if (secondary === stat) {}
                            else {
                                return (
                                    <FormControlLabel key={stat} value={stat} control={<Radio color='primary' />} label={"Select " + stat} />
                                )
                            }
                        })
                    : 
                        secondaryStats.map(stat => {
                            if (primary === stat) {}
                            else {
                                return (
                                    <FormControlLabel key={stat} value={stat} control={<Radio color='primary' />} label={"Select " + stat} />
                                )
                            }
                        })
                    }
                </RadioGroup>
            </FormControl>
        </div>
        )

    }

    function updateRerollOption(checked) {
        setRerollOnes(checked)
        props.setOptions("rerollOnes", checked);
    }
    
    function updateStat(stat, result, diceRolled) {
        var updatedStatDetails = [...statDetails];
        var index = updatedStatDetails.findIndex(item => item.name === stat.name);
        updatedStatDetails[index] = stat;
        setStatDetails(updatedStatDetails);
        var damage = {result: result, diceRolled: diceRolled, show: true, location:0};
        setDamageRoll(damage);
        setShowClass(showClass===globalStyles.fadeOut ? globalStyles.fadeOut2 : globalStyles.fadeOut);
        var count = 0;
        updatedStatDetails.forEach((updatedStat) => {
            if (updatedStat.baseValue > 0) {
                count++;
            }
        });
        
        var updatedBuilder = {...props.builder};
        updatedBuilder.stats.details = updatedStatDetails;
        if (count >= 7) {
            var total = 0;
            updatedStatDetails.forEach(stat => {
                total += stat.baseValue
            })
            setComplete(true);
            setTotalStatValue(total)
            var characteristics = createCharacteristics(props.builder.stats.details)
            var mods = getCategoryModifiers(characteristics);
            setModifiers(mods);
            updateAttributes(characteristics);
            updatedBuilder.categoryModifiers = mods;
            }
        else {
        }
        props.update(updatedBuilder, true)
    }

    var statModifiers = props.builder.homeland.characteristics ? props.builder.homeland.characteristics : null;
    var disabled = !complete;
    if (statDetails && statDetails.length > 0) {
    return (
        <div style={{marginRight: "10px", width: "100%"}}>
            <div style={{display: "flex", alignItems: "flex-start", position: "relative"}}>
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{border: "2px solid #2F588A", borderRadius: "5px", padding: "5px", width: "200px", marginTop: "10px", marginRight: "20px"}}>
                        <div style={{fontSize: "16px"}}>Options</div>
                        <div style={{marginTop: "5px", display: "flex", alignItems: "center"}}>
                            <div>Re-roll 1's</div>
                            <FormControlLabel style={{marginLeft: "20px"}}
                                control={<Switch checked={rerollOnes} color="primary" onChange={(event) => updateRerollOption(event.target.checked)} />}
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", marginTop: "8px"}}>
                        <Button onClick={() => reRollStats()} style={{width: "140px", height: "75px", backgroundColor: "#851113", color: "#fff", cursor: "pointer"}} variant="contained">Reroll all characteristics</Button>

                    </div>
                </div>
            </div>

            <div style={{marginTop: "10px", width: "100%"}}>
                <div id="runeValues" style={{display:"flex", width: "100%", justifyContent: "space-between"}}>
                    {statDetails.map((stat, index) => {
                        return (
                            <StatBlock key={index} stat={stat} builder={props.builder} rolling={rolling} setRolling={setRolling} diceRoller={props.diceRoller} increment={incrementStat} update={updateStat} rerollOnes={rerollOnes} enableIncrement={(totalStatValue < 93) && complete} pointsRemaining={pointsRemaining} />
                        )
                    })}
                </div>
                { complete ?
                    <div>
                        {totalStatValue < 93 ?
                            <div style={{display: "flex"}}>
                                <div style={{display:"flex", alignItems:"center", backgroundColor: "#cdcdcd", color: "#121312", marginTop: "20px", padding: "10px", paddingRight: "0px", border: "3-px solid #676767", borderRadius: "10px", flexWrap: "wrap"}}>
                                    <div style={{width: "260px"}}>{"You may add " + (pointsRemaining === 1 ? " 1 additional point " : (" up to " + pointsRemaining + " additional points ")) + "to your characteristics"}</div>
                                        <div style={{textAlign:"center", padding: "10px"}}> Points remaining</div>
                                        <div  style={{backgroundColor: pointsRemaining > 0 ? "#fff" : "#cdcdcd", color: pointsRemaining > 0 ? "#851113" : "#121312", fontSize: "24px", padding:"20px", borderRadius:"36px", textAlign:"center", marginRight: "30px", width: "28px"}}>{pointsRemaining}</div>
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    {displayRuneBonuses(true, "360px")}
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    {displayRuneBonuses(false, "360px")}
                                </div>
                            </div>
                        :
                            <div style={{display: "flex"}}>
                                <div>
                                    {displayRuneBonuses(true, "535px")}
                                </div>
                                <div style={{marginLeft: "10px"}}>
                                    {displayRuneBonuses(false, "535px")}
                                </div>
                            </div>
                        }
                        <div style={{fontSize: "18px", backgroundColor: "#8a612f", color: "#fff", marginTop: "20px", border: "2px solid #cdcdcd", borderRadius: "10px"}}>
                            <div style={{display:"flex", flexWrap: "wrap"}}>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Magic Points: </div>
                                    <div>{attributes.magicPoints}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Hit Points: </div>
                                    <div>{attributes.hitPoints.total}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Healing Rate: </div>
                                    <div>{attributes.healingRate}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Max Encumbrance: </div>
                                    <div>{attributes.maxEncumbrance}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>SIZ SR: </div>
                                    <div>{attributes.sizSR}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>DEX SR: </div>
                                    <div>{attributes.dexSR}</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Damage Bonus: </div>
                                    {attributes.damageBonus && attributes.damageBonus.quantity > 0 ? 
                                        <div>{attributes.damageBonus.quantity}D{attributes.damageBonus.dieType}</div>
                                    : 
                                        null
                                    }
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Spirit Combat Damage: </div>
                                    {attributes.spiritCombatDamage ? 
                                        <div>{attributes.spiritCombatDamage.quantity}D{attributes.spiritCombatDamage.dieType}{(attributes.spiritCombatDamage.modifier ? "+" + attributes.spiritCombatDamage.modifier : "")}</div>
                                    : 
                                        null
                                    }
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Agility modifier: </div>
                                    <div>{modifiers.Agility}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Communication modifier: </div>
                                    <div>{modifiers.Communication}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Knowledge modifier: </div>
                                    <div>{modifiers.Knowledge}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Magic modifier: </div>
                                    <div>{modifiers.Magic}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Manipulation modifier: </div>
                                    <div>{modifiers.Manipulation}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Perception modifier: </div>
                                    <div>{modifiers.Perception}%</div>
                                </div>
                                <div style={{width: "250px", margin: "5px 10px", display:"flex"}}>
                                    <div style={{fontWeight:"bold", marginRight: "10px"}}>Stealth modifier: </div>
                                    <div>{modifiers.Stealth}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
                }
            </div>
        </div>
    );
        }
        else {return null}
}

export default Characteristics;