import React, { useState } from 'react';
import { Button } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';

const disabledButton = {
    backgroundColor:"#cdcdcd",
    color:"#343434"
}

const enabledButton = {
    backgroundColor:"#a71d21",
    color:"#fff"
}

const ConfirmDeleteDialog = (props) => {
    const [itemName, setItemName] = useState('');
    const [button, setButton] = useState(disabledButton);

    function handleChange(itemName){
        setItemName(itemName);
        if (itemName === props.itemName) {
            setButton(enabledButton)
        }
        else {
            setButton(disabledButton)
        }
    };
    
    const isInvalid = itemName !== props.itemName

    return (
        <div style={{padding: "10px"}}>
            <div dangerouslySetInnerHTML={props.innerHTML}></div>
            <div style={{display:"flex"}}>
                <EditBox label="Type name:" autoFocus value={itemName} onChange={handleChange} />
            </div>
            <div style={{display:"flex", flexDirection: "row-reverse", marginLeft:"20px", marginTop: "10px"}}>
                <Button onClick={props.delete} disabled={isInvalid} style={{backgroundColor:button.backgroundColor, color:button.color}} variant="contained">Delete</Button>
                <Button onClick={props.closeDialog} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    );
}

export default ConfirmDeleteDialog