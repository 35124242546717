import React, { useEffect, useState } from 'react';
import {withContext} from '../Context/context';
import { Button, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import ManRune from '../../images/man-rune-270x360.png'
import EditBox from '../Controls/editBoxSingle';

const CharacterPickerDialog = (props) => {
	const [selectedCharacter, setSelectedCharacter] = useState({name:""});
	const [message, setMessage] = useState();
	const [remember, setRemember] = useState(true);
	const [magicPoints, setMagicPoints] = useState(0);

    const [characters, setCharacters] = useState([]);

	useEffect(() => {
		if (props.spell.target && props.spell.target.costsMagicPoints) {
			setMagicPoints(1);
		}
	},[]);

    useEffect(() => {
		if (props.providerData) {
			setCharacters([...props.providerData.alliedCharacters]);
		}
		else {
			setCharacters([...props.castingDetails.alliedCharacters]);
		}
    }, [props.providerData, props.castingDetails]);

	useEffect(() => {
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + ". Select the character you wish to cast this spell on.")
	},[]);

	useEffect(() => {
		if (props.spell.default) {
			if (props.spell.default.characterId === props.character.id) {
				selectCharacter(props.character)
			}
			else {
				characters.forEach(character => {
					if (character.id === props.spell.default.characterId) {
						selectCharacter(character);
					}
				});
			}
		}
		else {
			selectCharacter(props.character)
		}
	},[props.character, characters]);
	  
	function handleOkClick(){
        props.processSpell(remember, selectedCharacter, magicPoints);
	}

	function selectCharacter(character){
		var characterNotChanged = true;
		var itemIndex = -1;
		if (selectedCharacter) {
			if (character.id != selectedCharacter.id) {
				itemIndex = -1;
				characterNotChanged = false;
			}
		}
		var name = character.name.first === props.character.name.first ? "yourself" : character.name.first; 
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		var newMessage = "You are casting " + spellName + " on " + name;
		setMessage(newMessage);
		setSelectedCharacter(character);
	}

	function selectOther(){
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + " on a different character.");
		setSelectedCharacter(null);
	}

	function handleCancelClick(){
		props.close();
	}

	function handleRememberChange() {
		setRemember(!remember)
	}

	function updateMagicPoints(value) {
		if (value > 0) {
			setMagicPoints(value);
		}
	}

	if (props.character && props.spell && characters) {
		var style = {border: "5px solid #454545", margin:"5px", opacity: 0.4};
		if (selectedCharacter && props.character.id === selectedCharacter.id) {
			style = {border: "10px solid #2F588A", margin: "0px", opacity: 1};
		}
		return (
			<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", minHeight: "590px", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
	            <div style={{position:"relative", padding: "10px", height: "100%"}}>
					<div style={{display:"flex"}}>
						<div onClick={() => selectCharacter(props.character)} >
							<img src={props.character.profileImage || "https://storage.googleapis.com/runequest/bestiary/default.png"} style={{...style, width:"180px", borderRadius: "5px"}} />
						</div>
						<div style={{display:"flex", flexWrap: "wrap", flexDirection:"column", height: "260px"}}>
							{characters.map((character, index) => {
								var highlight = selectedCharacter && character.id === selectedCharacter.id;
								var otherBorder = highlight ? "5px solid #2F588A" : "3px solid #454545";
								return (
									<div key={index} style={{margin: "5px", height:"110px", textAlign:"center"}} onClick={() => selectCharacter(character)}>
										<img src={character.profileImage || "https://storage.googleapis.com/runequest/bestiary/default.png"} style={{height:"80px", borderRadius:"5px", border:otherBorder, opacity: highlight ? 1 : 0.4}} />
										<div style={{width:"80px", textAlign:"center"}}>{character.name.first + " " + character.name.last}</div>
									</div>
								)
							})}
							<div key="other" style={{margin: "5px", height:"110px", textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center"}} onClick={selectOther}>
								<div style={{width:"60px", height: "80px", borderRadius:"5px", border: selectedCharacter === null ? "5px solid #2F588A" : "3px solid #454545", backgroundColor: selectedCharacter === null ? "#fff" : "#c0c0c0", display:"flex", alignItems:"center"}} >
									<img src={ManRune} style={{width: "60px" }}/>
								</div>
								<div style={{textAlign:"center", marginTop: "5px"}}>Other</div>
							</div>
						</div>
					</div>
					<div style={{marginTop:"20px", height: "40px"}}>{message}</div>
					{props.spell.target && props.spell.target.costsMagicPoints ? 
						<div style={{display: "flex", alignItems: "center"}}>
							<div style={{marginRight: "10px"}}>The strength of this rune spell is based on magic points. Enter the number of magic points you wish to spend on this spell: </div>
							<EditBox number width="60px" value={magicPoints} onChange={updateMagicPoints} />
						</div>
					: 
					null}
				</div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "10px", alignItems:"flex-end"}}>
					<Button type="submit" onClick={handleOkClick} style={{width: "100px", color:"#fff"}} color="primary" variant="contained">Ok</Button>
					<Button type="submit" onClick={handleCancelClick} style={{width: "100px", marginRight: "20px", backgroundColor: "#bbbbbb"}} color="primary" variant="outlined">Cancel</Button>
					{props.providerData.type === "character" ? 
					<FormControl variant="outlined" 	>
						<FormControlLabel control={<Checkbox color='primary' checked={remember} onChange={handleRememberChange} />} label="Remember this selection"/>
					</FormControl>
					: null}

				</div>
			</div>
		);
	}
	else {
		return null;
	}
}

export default withContext(CharacterPickerDialog);