import React, {useState, useEffect} from 'react';
import { withPageState } from '../Context/PageState';
import globalStyles from '../Styles/styles.module.css';
import { FormControl, Checkbox } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-blue.svg';
import DeleteDialog from '../Dialogs/deleteDialog';

const InventoryArmourListItem = (props) => {
    
    const [checked, setChecked] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setChecked(props.item.equipped);
    },[props.item.equipped])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.select(props.item, event.target.checked)
    };

    function deleteItem() {
        setConfirm(true);
    }

    function confirmDelete() {
        props.delete({...props.item});
        setConfirm(false);
    }

    return (
        <>
            <div className={globalStyles.row} style={{display:"flex", height: "24px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px"}}>
                    <div style={{minWidth:"150px", alignItems: "center", display: "flex"}}>
                        <div>{props.item.material} {props.item.type}</div>
                    </div>
                    <div style={{width:"60px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.points}</div>
                    <div style={{width:"60px", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.ENC}</div>
                    {props.pageState.editMode ? 
                        <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                            <img style={{width: "20px", cursor: "pointer", marginLeft: "10px"}} onClick={deleteItem} src={deleteIcon} />
                        </div>
                    : 
                        <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                            <FormControl variant="outlined" 	>
                                <Checkbox checked={checked} color='primary' onChange={handleChange} />
                            </FormControl>
                        </div>
                    }
                </div>
                <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="armour" />
            </div>
        </>
    )
}

export default withPageState(InventoryArmourListItem);