import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material';
import Selector from '../Controls/selector2';
import { withFirebase } from '../Firebase';
import Encounter from '../Encounters/encounter';

const Encounters = (props) => {

    const [encounters, setEncounters] = useState([])
    const [currentEncounter, setCurrentEncounter] = useState({})

    useEffect(() => {
        props.campaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
                        setEncounters([...adventure.encounters])
                        if (props.campaign.currentEncounterId) {
                            adventure.encounters.forEach(encounter => {
                                if (encounter.id === props.campaign.currentEncounterId) {
                                    setCurrentEncounter({...encounter});
                                }
                            })
                        }
                    }
                })
            }
        })
    },[props.campaign])

    function displayEncounters() {
        props.displayItem({type: "Encounters", item: {name: "Encounter"}})
    }

    function setEncounter(item) {
        setCurrentEncounter({...item});
        var updatedCampaign = {...props.campaign}
        updatedCampaign.currentEncounterId = item.id;
        props.update(updatedCampaign);
    }

    return (
        <>
            <div style={{padding: "10px", paddingTop: "0px"}}>
                <>
                    <div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <Selector style={{width: "600px"}} labelMargin="25px" value={currentEncounter && currentEncounter.name} values={encounters} onSelect={setEncounter} label="Current encounter: " />
                            <Button style={{width: "165px"}} color="primary" variant='contained' onClick={() => displayEncounters()}>Edit Encounters</Button>
                        </div>
                    </div>
                </>
            </div>
            <div style={{padding: "0px"}}>
                <Encounter saveMode={props.saveMode} saveNPC={props.saveNPC} encounter={currentEncounter} activeEnemies={props.activeEnemies} diceRoller={props.diceRoller} augmenting={{}} authUser={props.authUser} processResult={props.processResult} processDamage={props.processDamage} />
            </div>
        </>
    )
}

export default withFirebase(Encounters);