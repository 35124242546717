import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import RuneSpellList from '../Lists/runeSpellList';
import compareValues from '../../utils/compareValues';

const RuneSpellPanel = (props) => {

	const [availableSpells, setAvailableSpells] = useState([]);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (props.complete) {
			props.save([...selected]);
		}
	},[props.complete, selected])

	useEffect(() => {
		if (props.currentSpells) {
			setSelected([...props.currentSpells])
		}	
	}, [props.currentSpells])

	useEffect(() => {
		if (props.availableSpells) {
			var spells = []; 
			if (selected && selected.length > 0) {
				spells = [...selected]
				props.availableSpells.forEach((spell) => {
					var index = selected.findIndex(obj => obj.parentId === spell.id);
					if (index < 0) {
						spells.push({...spell, parentId: spell.id, id: uuidv4()});
					}
					else {
						if (spell.variant) {
							var duplicate = {...spell, parentId: spell.id, id: uuidv4()};
							spells.push(duplicate);
						}
					}
				});
			}
			else {
				props.availableSpells.forEach(spell => {
					spells.push({...spell, parentId: spell.id, id: uuidv4()});
				})
			}
			spells.sort(compareValues('name'));
			setAvailableSpells(spells);
		}
	},[props.availableSpells, selected]);

	function selectSpells(spell, checked) {
		var updatedSelected = [...selected];
		if (checked) {
			if (props.matrix) {updatedSelected = []}
			var newSpell = {id: spell.id, parentId: spell.parentId, name: spell.name, points: spell.points, runes: spell.runes, stackable: spell.stackable, target: spell.target || {}};
			if (spell.variant) {newSpell.variant = spell.variant}
			updatedSelected.push(newSpell);
		}
		else {
			var index = selected.findIndex(obj => obj.id === spell.id);
			updatedSelected.splice(index, 1);
		}
		setSelected(updatedSelected);
		props.select(updatedSelected);
	}
	
	function amendSpell(spell) {
		var updatedSpells = [...availableSpells];
		var updatedCurrent = [...selected];
		var index = -1;
		if (spell.variant) {
			index = updatedSpells.findIndex(obj => obj.id === spell.id)
			if (index > -1) {
				updatedSpells[index] = {...spell};
			}
			else {
				updatedSpells.push({...spell})
			}
			setAvailableSpells(updatedSpells);
	
			index = updatedCurrent.findIndex(obj => obj.id === spell.id)
			if (index > -1) {
				updatedCurrent[index] = {...spell};
			}
			else {
				updatedCurrent.push({...spell})
			}
			setSelected(updatedCurrent);
			props.select(updatedCurrent);
	
		}
		else {
			index = updatedSpells.findIndex(obj => obj.id === spell.id)
			updatedSpells[index] = {...spell};
			setAvailableSpells(updatedSpells);
			index = updatedCurrent.findIndex(obj => obj.id === spell.id)
			updatedCurrent[index] = {...spell};
			setSelected(updatedCurrent);
			props.select(updatedCurrent);
		}
	}

	return (
		<RuneSpellList campaign={props.campaign} matrix={props.matrix} characterRunes={props.characterRunes} selected={selected} spells={availableSpells} select={selectSpells} update={amendSpell} availableSpellCount={props.availableSpellCount - selected.length} cult={props.cult} />
	);
};

export default RuneSpellPanel;