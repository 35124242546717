import React, {useState, useEffect} from 'react';
import { withFirebase } from '../Firebase';
import profileIconWhite from '../../icons/profile-icon-white.svg'
import profileIcon from '../../icons/profile-icon.svg'
import AccountSettings from './accountSettings';

const ProfileButton = (props) => {

	const [show, setShow] = useState(false);
	const [showSettings, setShowSettings] = useState(false);

	return (
		<div style={{position: "relative", width: "400px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
			<img style={{width: "36px", height: "36px", backgroundColor: show ? "#fff" : "transparent", borderRadius: "50px"}} src={show ? profileIcon : profileIconWhite} onClick={() => {setShow(!show)}} />
			{show ? 
				<div style={{position: "absolute", top: "42px", right: "-28px", backgroundColor: "#232323", color: "#fff", width: "400px"}}>
					<div style={{padding: "20px 10px", borderBottom: "1px solid #fff", width: "100%", cursor: "pointer"}} onClick={() => setShowSettings(!showSettings)}>Account Settings</div>
					{showSettings ? 
						<AccountSettings authUser={props.authUser} close={() => setShow(false)} />
					: null
					}
					<div style={{padding: "20px 10px", borderTop: "1px solid #fff", width: "100%", cursor: "pointer"}} onClick={props.firebase.doSignOut}>Sign Out</div>
				</div>
			: 
			null}
		</div>

	)

		};

export default withFirebase(ProfileButton);
