export function getStatValue(stat) {
    if (stat) {
        return Math.floor(stat.baseValue + (stat.statBonus || 0) + (stat.enchantmentBonus || 0));
    }
    else {
        return 0;
    }
}

export function lowWeaponStats(character, weapon) {
    var statMultiplier = 1;
    var STR = getStatValue(character.characteristics.STR)
    var DEX = getStatValue(character.characteristics.DEX)
    if (STR <  weapon.minSTR) {
        statMultiplier = 0.5;
    }
    else if (DEX <  weapon.minDEX) {
        if (DEX + Math.floor((STR - weapon.minSTR) / 2) <  weapon.minDEX) {
            statMultiplier = 0.5;
        }
    }
    return statMultiplier;
}

export function calculateDamageBonus(characteristics) {
    if (!characteristics) return null;
    var damageStats = getStatValue(characteristics.STR) + getStatValue(characteristics.SIZ);

    switch (true) {
        case (damageStats <= 12): return {quantity: -1, dieType: 4}
        case (damageStats <= 24): return {quantity: 0, dieType: 0}
        case (damageStats <= 32): return {quantity: 1, dieType: 4}
        case (damageStats <= 40): return {quantity: 1, dieType: 6}
        default:
        var bonus = 56;
        var diceCount = 2;
        var safety = 0;
        while (true) {
            if (safety > 997) return null;
            if (damageStats <= bonus) {
                return {quantity: diceCount, dieType: 6}
            }
            else {
                bonus += 16;
                diceCount++;
            }
            safety++;
        }
    }
}

export function calculateMagicPoints(characteristics) {
    return getStatValue(characteristics.POW);
}

export function calculateSpiritCombatDamage(characteristics) {
    if (!characteristics || characteristics.length === 0) return null;
    var damageStats = getStatValue(characteristics.POW) + getStatValue(characteristics.CHA);
    switch (true) {
        case (damageStats <= 12): return {quantity: 1, dieType: 3, modifier: 0, bonus: 0}
        case (damageStats <= 24): return {quantity: 1, dieType: 6, modifier: 0, bonus: 0}
        case (damageStats <= 32): return {quantity: 1, dieType: 6, modifier: 1, bonus: 0}
        case (damageStats <= 40): return {quantity: 1, dieType: 6, modifier: 3, bonus: 0}
        case (damageStats <= 56): return {quantity: 2, dieType: 6, modifier: 3, bonus: 0}
        default:
        var bonus = 56;
        var diceCount = 2;
        var modifier = 3;
        var safety = 0;
        while (true) {
            if (safety > 999) return null;
            if (damageStats <= bonus) {
                return {quantity: diceCount, dieType: 6, modifier: modifier, bonus: 0}
            }
            else {
                bonus += 16;
                diceCount++;
                modifier ++;
            }
            safety++;
        }
    }
}

export function calculateSizSR(characteristics) {
    if (characteristics) {
        var SIZ = getStatValue(characteristics.SIZ);
        switch(true) {
            case (SIZ <= 6): return 3;
            case (SIZ <= 14): return 2;
            case (SIZ <= 21): return 1;
            default: return 0;
        }
    }
    else {
        return 0;
    }
}
export function calculateDexSR(characteristics) {
    if (characteristics) {
        var DEX = getStatValue(characteristics.DEX)
        switch (true) {
            case (DEX <= 5): return 5;
            case (DEX <= 8): return 4;
            case (DEX <= 12): return 3;
            case (DEX <= 15): return 2;
            case (DEX <= 18): return 1;
            default: return 0;
        }
    }
    else {
        return 0;
    }
}

export function calculateStrikeRanks(characteristics) {
    var strikeRanks = {
        DEX: calculateDexSR(characteristics),
        SIZ: calculateSizSR(characteristics)
    };
    return strikeRanks;
}

export function calculateMaxEncumbrance(characteristics) {
    if (characteristics) {
        var average = Math.floor((getStatValue(characteristics.STR) + getStatValue(characteristics.CON)) / 2);
        return average > getStatValue(characteristics.STR) ? getStatValue(characteristics.STR) : average;
    }
    else {
        return 0;
    }
}

export function calculateCurrentEncumbrance(character) {
    var enc = 0;
    character.armour.forEach(item => {
        if (item.equipped) {
            enc += (item.ENC || item.enc || 0);
        }
    })
    character.weapons.forEach(item => {
        if (item.equipped) {
            enc += (item.ENC || item.enc || 0);
        }
    });
    return Math.floor(enc);
}
