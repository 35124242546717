import { Button } from '@mui/material';
import React from 'react';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';

const Admin = (props) => {

  function fixCharacters() {
    props.firebase
    .characters()
    .get()
    .then(dataset => {
      dataset.forEach(doc => {
        var character = doc.data();
        if (character.complete ){

          character.cults.forEach(cult => {
            if (cult.parentId === "6eabc060-dc9c-11ea-94c4-d57ef2b3543d") {
              //Orlanth Adventurous
              console.log('Fixing Orlanth Adventurous for ', character.id)
              cult.parentId = "99f6515c-bf28-4ec1-8412-c0f9d5fac72c";
              cult.name = "Orlanth";
              if (cult.subcults) {
                cult.subcults.push(
                  {
                    "name": "Orlanth Adventurous",
                    "parentId": "6eabc060-dc9c-11ea-94c4-d57ef2b3543d"
                  }
                )
              }
              else {
                cult.subcults = [
                  {
                    "name": "Orlanth Adventurous",
                    "parentId": "6eabc060-dc9c-11ea-94c4-d57ef2b3543d"
                  }
                ]
              }
            }
            if (cult.parentId === "6ebd4c90-dc9c-11ea-94c4-d57ef2b3543d") {
              //Orlanth Thunderous
              console.log('Fixing Orlanth Thunderous for ', character.id)
              cult.parentId = "99f6515c-bf28-4ec1-8412-c0f9d5fac72c";
              cult.name = "Orlanth";
              if (cult.subcults) {
                cult.subcults.push(
                  {
                    "name": "Orlanth Thunderous",
                    "parentId": "6ebd4c90-dc9c-11ea-94c4-d57ef2b3543d"
                  }
                )
              }
              else {
                cult.subcults = [
                  {
                    "name": "Orlanth Thunderous",
                    "parentId": "6ebd4c90-dc9c-11ea-94c4-d57ef2b3543d"
                  }
                ]
              }
            }
          })





          props.firebase
          .character(character.id)
          .set(character)
          .then(() => {})
        }
      })
    })
  }

  function addIdTo (items) {
    if (items && items.length > 0) {
      items.forEach(item => {
        if (! item.id) {
          item.id = uuidv4();
        }
      });
      return items;
    }
    else {
      return [];
    }
  }

  return (
    <div style={{margin: "200px"}}>
      <h1>Admin</h1>
      <div >
        <Button title = "Fix characters" onClick={fixCharacters} variant='contained' color="primary" style={{backgroundColor: "#2f588a", color: "#fff", padding: "10px"}}>Fix Characters</Button>
      </div>
    </div>
  )
}
 
export default withFirebase(Admin);