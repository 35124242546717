import React, {useState, useEffect} from "react";
import { withContext } from "../Context/context";
import styles from './controls.module.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import DialogBox from '../Dialogs/dialogBox';
import DamageDialog from '../Dialogs/damageDialog';
import { applyDamage } from '../../services/hitPointsService';
import * as SAVE from '../../constants/save';

const HitPointTable = (props) => {

	const [hitPoints, setHitPoints] = useState(props.hitPoints);
	const [open, setOpen] = useState(false);
	const [location, setLocation] = useState();
	const [armourEnchantment, setArmourEnchantment] = useState(0);
    const [defense, setDefense] = useState(props.providerData.character.defense || 0);

	useEffect(() => {
		setHitPoints(props.hitPoints)
	},[props.hitPoints]);


    useEffect(() => {
        var armour = 0;
        var defense = 0;
        if (props.providerData.character.enchantments) {
            props.providerData.character.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.name === "armourModifier") {
                        armour += effect.value;
                    }
                    if (effect.name === "defenseModifier") {
                        defense += effect.value;
                    }
                }) 
            });

        }
        setArmourEnchantment(armour);
        setDefense(defense);

    },[props.providerData.character.enchantments ])

    function openDialog(location) {
        setLocation(location);
        setOpen(true);
    }

	function getCurrent(location) {
		var result = location.hitPoints;
		if (location.wounds && location.wounds.length > 0) {
			var damage = location.wounds.reduce(function(a,b) {return a + b;})
			result -= damage;
		}
		return result;
	}

	const update = (item, change, ignoreArmour, fallingDamage) => {
		var updatedHitPoints = applyDamage({...hitPoints}, item, change, ignoreArmour, fallingDamage, armourEnchantment)
		props.update([{fieldName: "hitPoints", value: updatedHitPoints}], SAVE.YES);
		setOpen(false);
	}

    return (
        <>
            <TableContainer style={{width:"152px", border: "1px solid #2F588A"}} component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                        <TableCell className={styles.header} style={{padding: "0px", color: "#fff", backgroundColor: "#2F7589", paddingLeft: "2px"}}>Location</TableCell>
                        <TableCell className={styles.header} style={{padding: "0px", color: "#fff", backgroundColor: "#2F7589"}} align="right">D20</TableCell>
                        <TableCell className={styles.header} style={{padding: "0px", color: "#fff", backgroundColor: "#2F7589", paddingRight: "2px"}} align="right">HP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {hitPoints.locations.sort((a, b) => {
                            if (a.from < b.from)
                            return -1;
                            if (a.from > b.from)
                            return 1;
                            return 0;
                        }).map((location) => {
                            var armourPoints = (location.armour?.points || 0) + (location.naturalArmour?.points || 0)
                            var color = getCurrent(location) <= 0 ? "#fff" : "#121312"
                            return(
                                <TableRow className={styles.TableRow} style={{cursor: "pointer", backgroundColor: getCurrent(location) <= 0 ? "#851113" : "#fff"}} key={location.name} onClick={() => openDialog(location)}>
									<TableCell className={styles.tableCell} style={{padding: "0px", paddingLeft: "2px", color: color}} component="th" scope="location">
										{location.name}
									</TableCell>
									<TableCell className={styles.tableCell} style={{padding: "0px", color: color}} align="right">{location.from}-{location.to}</TableCell>
									<TableCell className={styles.tableCell} style={{padding: "0px", paddingRight: "2px", color: color}} align="right">{((armourPoints) + armourEnchantment)}/{getCurrent(location)}</TableCell>
								</TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <DialogBox isOpen={open} width="600px" title="Apply Damage/Healing">
                    <DamageDialog close={() => setOpen(false)} enchantments={hitPoints.enchantments} location={location} update={update} />
                </DialogBox>
            </div>
        </>

    )
}

export default withContext(HitPointTable);