import React, { useEffect, useState } from 'react';
import globalStyles from '../Styles/styles.module.css';
import { Button } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import DialogButtons from '../Dialogs/dialogButtons';
import Selector from '../Controls/selector';
import * as Encounters from '../../data/encounters.json'
import * as TemplateJSON from '../../data/templates.json'
import generateEncounter from '../NPCGenerator/generator'
import { withFirebase } from '../Firebase';
import AirRune from '../../images/air-rune-600x600.png'

const encounterData = {...Encounters}
const templateData = {...TemplateJSON};

const CreateEncounterDialog = (props) => {

	const [title, setTitle] = useState("")
	const [excessive, setExcessive] = useState(true)
	const [disabled, setDisabled] = useState(false)
	const [count, setCount] = useState(1);
	const [experience, setExperience] = useState("");
	const [type, setType] = useState("");
	const [typeId, setTypeId] = useState("");
	const [template, setTemplate] = useState("");
	const [cult, setCult] = useState("");
	const [groups, setGroups] = useState([]);
	const [creating, setCreating] = useState(false);

	useEffect(() => {
		var disabled = true;
		var count = 0;
		groups.forEach(group => {
			count += group.count;
		})
		setExcessive(count > 9);
		if (groups.length > 0 && title && count <= 9 ) {
			disabled = false;
		}
		setDisabled(disabled);
	},[groups, title])

	function update(field, value) {
		var updatedEncounter = {...encounter};
		updatedEncounter[field] = value;
		setEncounter(updatedEncounter);
	}

	function updateStartDate(field, value) {
		var updatedEncounter = {...encounter}
		updatedEncounter.startDate[field] = value;
		setEncounter(updatedEncounter);
	}

	// function handleOkClick() {
		// var updatedEncounters = [...props.encounters];
		// var index = updatedEncounters.findIndex(obj => obj.id === encounter.id);
		// if (index > -1) {
		// 	updatedEncounters[index] = {...encounter};
		// 	props.update(updatedEncounters);
		// }
		// else {
		// 	var updatedEncounter = {...encounter}
		// 	updatedEncounter.id = uuidv4();
		// 	updatedEncounter.encounters = [];
		// 	updatedEncounters.push(updatedEncounter);
		// 	props.update(updatedEncounters);
		// }
	// }

	async function handleGenerateClick(){
		setCreating(true);
		await generateEncounter(groups, props.firebase, props.campaign, props.authUserId)
		.then(encounterId => {
			var newEncounter = {
				name: title, 
				id: encounterId
			}
			var updatedEncounters = [...props.encounters];
			updatedEncounters.push(newEncounter);
			props.update(updatedEncounters, encounterId)
			props.close();
			props.closePanel();
		})
	}

	function selectType(value) {
		encounterData.types.forEach(encounterType => {
			if (encounterType.name === value) {
				setType(value);
				setTypeId(encounterType.id)
			}
		})
	}

	function clearFields() {
		setCount(1)
		setType("Select");
		setExperience("Select")
		setTemplate("Select");
		setCult("Select")
	}

	function addGroup() {
		var updatedGroups = [...groups]
		updatedGroups.push({
			count:count,
			type:type,
			experience:experience,
			template:template,
			cult:cult,
		});
		setGroups(updatedGroups)
	}

	return (
		<div style={{opacity: creating ? 0.6 : 1, }}>
			<div style={{overflowX: "hidden", height: "300px", fontSize: "15px", padding: "10px"}}>
				<div style={{margin: "10px 0px"}}>
					<EditBox style={{backgroundColor: "#fff"}} width="750px" value={title} onChange={setTitle} placeholder="Encounter name" autoFocus />
				</div>
				<fieldset style={{borderRadius:"5px"}}>
					<legend>Encounter Group:</legend>
					<div style={{display: "flex", marginTop: "10px"}}>
						<div style={{display: "flex", alignItems: "center", width: "480px"}}>
							<div style={{marginRight: "18px"}}>Required number of NPCs in this subgroup (max 9): </div>
							<div>
								<EditBox width="80px" number value={count} onChange={setCount} placeholder="Count" />
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginLeft: "4px"}}>
							<Selector style={{width: "220px"}} label="Type:" field="type" values={encounterData.types} value={type} updateSelected={selectType} />
						</div>
					</div>
					<div style={{display: "flex", marginTop: "10px"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							<Selector style={{width: "200px"}} label="Template:" field="template" values={type ? templateData.data.filter(function(obj) {return (obj.parentId === typeId)}):[]} value={template} updateSelected={setTemplate} />
						</div>
						<div style={{display: "flex", alignItems: "center", marginLeft: "10px"}}>
							<Selector style={{width: "200px"}} label="Cult:" field="cult" values={encounterData.cults} value={cult} updateSelected={setCult} />
						</div>
						<div style={{display: "flex", alignItems: "center", marginLeft: "10px"}}>
							<Selector style={{width: "120px"}} label="Experience:" field="experience" values={encounterData.experience} value={experience} updateSelected={setExperience} />
						</div>
					</div>
					<div style={{display: "flex", marginTop: "10px"}}>
					</div>
					<div style={{display: "flex", marginTop: "30px", flexDirection: "row-reverse"}}>
						<div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
							<Button style={{width: "120px"}} color="primary" variant="contained" onClick={addGroup}>Add Group</Button>
						</div>
						<div style={{display: "flex", alignItems: "center"}}>
							<Button style={{width: "120px"}} color="primary" variant="contained" onClick={clearFields}>Clear</Button>
						</div>
					</div>
					
				</fieldset>
			</div>
			<fieldset style={{borderRadius: "5px", minHeight: "100px"}}>
				<legend>Groups</legend>
				<div style={{display: "flex", alignItems: "center", marginBottom:"20px"}}>
					<div style={{width: "100px"}}>Experience</div>
					<div style={{width: "250px"}}>Template</div>
					<div style={{width: "150px"}}>Occupation</div>
					<div style={{width: "200px"}}>Cult</div>
					<div style={{width: "50px"}}>Number</div>
				</div>
				{groups.map((group, index) => {
					return(
						<div key={index} style={{display: "flex", alignItems: "center"}}>
							<div style={{width: "100px"}}>{group.experience}</div>
							<div style={{width: "250px"}}>{group.template}</div>
							<div style={{width: "150px"}}>{group.occupation}</div>
							<div style={{width: "200px"}}>{group.cult}</div>
							<div style={{width: "50px"}}>{group.count}</div>
						</div>
					)
				})}
			</fieldset>
			{excessive ? 
			<div style={{color: "#851113", marginLeft: "5px"}}>No more than 9 NPCs can be added to an encounter</div>
			: null}
			<div style={{borderTop: "2px solid #2f588a", marginTop: "10px"}}>
				<DialogButtons handleOkClick={handleGenerateClick} handleCancelClick={props.close} disabled={disabled} title="Create Encounter" />
			</div>
			{creating ? 
				<div style={{position: "fixed", top: "50%", left: "50%", marginLeft: "-200px", marginTop: "-150px", borderRadius: "10px", width: "400px", height: "300px", paddingTop: "20px"}}>
					<div className={globalStyles.rotate}>
						<img src={AirRune} style={{width: "120px"}} />
					</div>
					<div style={{fontSize: "36px", textAlign: "center", width: "100%", marginTop: "20px", color: "#2f588a", fontWeight: "bold"}}>Generating...</div>
				</div>
			: 
			null}
		</div>
	);
}

export default withFirebase(CreateEncounterDialog);