import React, {useState, useEffect} from "react";
import globalStyles from '../Styles/styles.module.css';
import CloseIcon from '../../icons/close-icon.svg';

const HelpButton = (props) => {

    const [statModifiers, setStatModifiers] = useState();
    const [boxStyle, setBoxStyle] = useState("moveFromTopFade");

    const details = {
        step1: 
            <div style={{padding: "10px"}}>
                <p>Every adventurer has a Homeland, which helps determine initial customs, language and religion. An adventurer's Homeland may be suggested, determined, or restricted by the gamemaster.</p>
                <p>Six human Homelands surrounding Dragon Pass are presented in this book. Each Homeland has associated Rune modifiers, cultural skills, and cultural weapons, all detailed in later sections. Cultural stereotypes have been presented to let players know how outsiders typically perceive members of that culture. Players should feel free to defy expectations and play contrary to stereotypes, if desired.</p>
            </div>,
        step2: 
            <div style={{padding: "10px"}}>
                <p>Select your significant grandparent, their homeland and their occupation. By default, their homeland is the same as your own, but this can be changed if desired. An occupation has been randomly selected, but again this can be change if desired.</p>
                <p>After selecting your significant grandparent, select your significant parent.</p>
            </div>,
        step3: 
            <div style={{padding: "10px"}}>
                <p>Determine what events your grandparent and parent participated in and the results that each event had upon on your adventurer. Start with your grandparent’s birth, then to the year 1582, going entry by entry until the adventurer is born (by default this is the year 1604). After your adventurer’s birth, you will switch to determining events for your parent.</p> 
                <p>You may choose the event or roll to get a random result. When an event indicates that a grandparent or parent is killed (or its equivalent), no further events will occur for that ancestor.</p>
                <p>Some events give the adventurer a Passion, Reputation, or even wealth. Wealth is straightforward, but Passions and Reputation are an additional type of inheritance, attitudes learned from the words and deeds of your parents and their parents.</p>
            </div>,
        step4elemental: 
            <div style={{padding: "10px"}}>
                <p>The six Elemental Runes represent the raw essence of the cosmos. The elements bind adventurers to the conflicts of the Gods War, and are behind their most primordial drives.</p>
                <p>Choose three Elemental Runes to be your primary, secondary, and tertiary Rune affinities. The primary Rune starts at a 60% affinity, the second at 40%, and the third at 20%. The other three Elemental Runes start at 0%, unless otherwise raised.</p>
                <p>To select a rune, drag it over the desired affinity.</p>
                <p><b>**Due to your homeland, you receive a +10% bonus to the {props.cultureRune} rune.** </b></p>
            </div>,
        step4power: 
            <div style={{padding: "10px"}}>
                    <p>The Power Runes represent the strength and nature of an adventurer's personality and their social impulses in Glorantha. They are rooted in the cosmic dualities of the Gods World but also affect the Middle World. These Runes consist of four opposed pairs of Power Runes. Humans also have an opposed pair of Form Runes: Man and Beast. These Runes are handled identically to the Power Runes.</p>
                    <p>The Power and Form Runes are presented in opposed pairs. The total value of the pair must always equal 100%, and when one Rune increases the opposite side always decreases by the same amount.<i> For example, if your adventurer has a Man Rune of 75%, their Beast Rune is 25%.</i></p>
                    <p>From the Power and Form Runes, choose two affinities to start with a value of 75 (with their opposing Runes starting at 25). The other pairs all start with values of 50/50. Click on a rune to select or unselect it.</p>
            </div>,
        step4bonuses: 
            <div style={{padding: "10px"}}>
                    <p>You may now distribute 50 points among any of your adventurer's Rune affinities. Remember that if a Power or Form Rune affinity is raised, the value of its opposing affinity will be reduced so that the total of the two runes equals 100.</p>
                    <p>A value of 80% or more in any Rune indicates that your adventurer is strongly tied to the Rune. The Rune may affect your own control over your adventurer; their actions may be determined by the Rune and contrary to your wishes.</p>
                    <p>To allocate points to an Elemental Rune, drag the slider to the right. To allocate points to a Power Rune, drag the slider in the direction of the desired Rune.</p>
            </div>,
        step5: 
            <div style={{padding: "10px"}}>
                    <p>Each characteristic has a numerical value to determine how potent it is. Human adventurers typically have STR, CON, DEX, POW, and CHA between 3 and 18, and SIZ and INT between 8 and 18. When creating adventurers, these values are usually determined by rolling dice. These values are then modified by the adventurer's two highest Elemental Runes.</p> 
                    <p>The Runes themselves influence an adventurer's characteristics, influencing behavior and development physically, intellectually, spiritually, and even socially. Because of this, each adventurer gets Rune Modifier bonuses to the characteristics corresponding to their primary (highest) and secondary (second-highest) Elemental Rune affinities</p>
                    <p>Alternatively, Charisma can be chosen for either the primary or secondary bonus, but not both.</p>
                    {statModifiers && statModifiers.length > 0 ? 
                    <p><b>Note: As a {props.homeland?.title}, you receive {statModifiers[0].bonus > 0 ? "+" : ""}{statModifiers[0].bonus} to your {statModifiers[0].name} and {statModifiers[1].bonus > 0 ? "+" : ""}{statModifiers[1].bonus} to your {statModifiers[1].name}</b></p>
                    : 
                    null
                    }
            </div>,
        step6: 
            <div style={{padding: "10px"}}>
                <p>Each Homeland has cultural skill bonuses that will now be applied to the skill base values on the adventurer. Where there is a bonus (such as +10%), this value will be added to the existing base chance. A value in parentheses (such as 25), is the skill's new base chance, replacing the existing base chance of the adventurer.</p> 
                <p>Where the available skill is a broad category of weapons (such as 1H Axe), you must pick a weapon within that category (such as Battle Axe).</p>
            </div>,
        step7: 
            <div style={{padding: "10px"}}>
                <p>Occupations include all ways adventurers can feed and support themselves and their families. In Glorantha, most people follow in the occupation of their parents, although some blaze their own paths.</p>
                <p>For the occupational skills and weapons, the listed bonuses will be added to your adventurer. Where more than one bonus could apply to the same skill (e.g., Cultural Weapon +30% and Cultural Weapon +10%), two separate skills must be chosen (e.g., Battle Axe +30% and Broadsword +10%).</p>
                <p>All adventurers can be expected to have the following gear: tunic/blouse, skirt/breeches, sandals/boots, loincloth, warm cloak (hide or woven-grass), headgear/hat/ cap, belt knife, fire-making gear, and a water skin. In addition, each occupation lists the standard equipment that a typical adventurer with that occupation would gain and wear out every year.</p>
            </div>,
        step8: 
            <div style={{padding: "10px"}}>
                <p>Children become adults after a formal initiation ceremony, parts of which are the most closely held magical secrets of the community. Nearly every adult is initiated into the cult of a specific deity. However, more important than being initiated is the deity to which an adventurer is initiated.</p>
                <p>An adventurer should start play as an initiate of a cult. The player should select the cult of a deity that the adventurer has at least one Rune with a rating of at least 50% in common with the deity.</p>
                <p>After selecting a cult and choosing any optional starting skills, select one of the cult starting skills or weapons to receive an additional +20% to that skill, and a second skill to receive an additional +15%. Then, choose three special Rune spells available to the cult and 5 points of spirit magic taught by the cult. Finally, you may select a favored passion of the cult.</p>
            </div>,
        step9: 
            <div style={{padding: "10px"}}>
                <p>Adventurers have personal interests beyond that demanded by their occupation, cult, and culture. Add +25% to any four skills on the adventurer sheet, and +10% to five more skills.</p>
                <p>No skill may be raised to above 100% in this step. If your adventurer has a skill at 100% or greater through a combination of base chance, history, boons, skill category bonus, cultural skill bonus, occupational skill bonuses, and cult skill bonuses, you cannot add additional personal skill bonuses to that skill.</p>
                <p>If applying a personal skill bonus increases a skill beyond 100%, any benefit beyond 100% is discarded and the skill is 100%.</p>
            </div>,
        step10: 
            <div style={{padding: "10px"}}>
                <p>Using the information gathered in the prior steps, it’s time to personalize your adventurer, bringing them to life.</p>
            </div>,
        step11: 
            <div style={{padding: "10px"}}>
                    <p>Finally, each beginning adventurer receives one (or possibly more than one) valuable and colorful possession as a family heirloom. Make up the where and why of this if necessary. </p>
                    <p>If you decide you don't want the item provided, ignore it, or you can try to sell it once play beings (if appropriate).</p>
            </div>
    }

    useEffect(() => {
        if (props.homeland) {
            setStatModifiers(props.homeland.characteristics ? props.homeland.characteristics : null);
        }
    },[props.homeland])

    useEffect(() => {
        if (props.step && props.open) {
            displayHelpPanel();
        }
    },[props.step, props.runeState])

    const displayHelpPanel = () => { 
        var stepName = "step" + props.step;
        if (props.runeState) {
            stepName += props.runeState;
        }
       var element = details[stepName]
       props.display(element);
    }

    const closeBox = () => {
        setBoxStyle("close")
    }

    return (
        <div style={{display: "flex", alignItems: "flex-start"}}>
            <div onClick={displayHelpPanel} style={{backgroundColor: "#fff", borderRadius: "30px", marginRight: "10px", border: "2px solid #851113", fontSize: "30px", color: "#851113", minWidth: "40px", height: "40px", display:"flex", cursor: "pointer", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>?</div>
            <div style={{display: boxStyle === "close" ? "none": "flex", alignItems: "center"}} className={globalStyles[boxStyle]}>
                <div style={{width: 0, height: 0, borderTop: "25px solid transparent", borderBottom: "25px solid transparent", borderRight: "25px solid #2f588a", marginRight: "-2px"}} />
                <div style={{display: "flex", alignItems: "flex-start", height: "40px", borderLeft: 0, padding: "5px", borderRadius: "5px", width: "350px", backgroundColor: "#2f588a", color: "#fff", fontSize: "15px"}}>
                    <div>While building your character, click this button for more details on each step.</div>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <img style={{width: "25px", marginLeft: "10px", cursor: "pointer"}} src={CloseIcon} onClick={closeBox} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpButton;