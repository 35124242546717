import React from "react";
import { withPageState } from "../Context/PageState";
import AddItemButton from "../Characters/buttons/addItemButton";

const PanelHeader = (props) => {

    const startColour = props.startColour || "rgba(47,88,138,1)";
    const endColour = props.endColour || "rgba(47,117,137,1)";

    return (
        <div id="header" style={{background: startColour, background: "linear-gradient(90deg, " + startColour + " 35%, " + endColour + " 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>{props.title}</div>
            <div style={{width: "30px"}}>
                {props.pageState.editMode && props.addTitle ? 
                    <AddItemButton title={props.title} addTitle={props.addTitle} addItem={props.addItem} edit={props.edit} width={props.width}>{props.children}</AddItemButton>
                :
                null
                }
            </div>
        </div>
    )
}

export default withPageState(PanelHeader);