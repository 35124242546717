import * as SkillsData from '../data/skills.json';
import * as PassionsData from '../data/passions.json';
import * as RunesData from '../data/runes.json';
import * as CultsData from '../data/cults.json';
import * as SpiritSpells from '../data/spiritSpells.json';
import * as RuneSpellData from '../data/runeSpells.json';
import * as WeaponsData from '../data/weapons.json';
import * as OccupationsData from '../data/occupations.json';
import * as PantheonsData from '../data/pantheons.json';
import compareValues from '../utils/compareValues';

const allSkills = {...SkillsData}
const allPassions = {...PassionsData}
const allRunes = {...RunesData}
const allCults = {...CultsData}
const allSpiritSpells = {...SpiritSpells}
const allRuneSpells = {...RuneSpellData}
const allWeapons = {...WeaponsData}
const allOccupations = {...OccupationsData}
const allPantheons = {...PantheonsData}

export function getSkill(id, customContent) {
    return getItem(id, allSkills.data, customContent, "skills");
}

export function getSkillByName(name, customContent) {
    return getItemByName(name, allSkills.data, customContent, "skills");
}

export function getAllSkills(customContent) {
    var skills = [...allSkills.data];
    if (customContent && customContent.skills) {
        skills = skills.concat(customContent.skills);
    }
    return skills.sort(compareValues("name"));
}

export function getPassion(id, customContent) {
    return getItem(id, allPassions.data, customContent, "passions");
}

export function getAllPassions(customContent, cultId ) {
    var passions = [...allPassions.data];
    if (customContent && customContent.passions) {
        passions = passions.concat(customContent.passions);
    }
    return passions.sort(compareValues("name"));
}

export function getRune(id, customContent) {
    return getItem(id, allRunes.data, customContent, "runes");
}

export function getRuneByName(name, customContent) {
    return getItemByName(name, allRunes.data, customContent, "runes");
}

export function getAllRunes(customContent) {
    return getAllItems(allRunes.data, customContent, "runes")
}

export function getCult(id, customContent) {
    return getItem(id, allCults.data, customContent, "cults");
}

export function getAllCults(customContent) {
    return getAllItems(allCults.data, customContent, "cults")
}

export function getAllPantheons(customContent) {
    return getAllItems(allPantheons.data, customContent, "pantheons")
}

export function getOccupation(id, customContent) {
    return getItem(id, allOccupations.data, customContent, "occupations");
}

export function getAllOccupations(customContent) {
    return getAllItems(allOccupations.data, customContent, "occupations")
}

export function getSpiritSpell(id, customContent) {
    return getItem(id, allSpiritSpells.data, customContent, "spiritSpells");
}

export function getAllSpiritSpells(customContent, cultId, includeNonPlayable, ) {
    var spells = [...allSpiritSpells.data];
    if (customContent && customContent.spiritSpells) {
        if (includeNonPlayable) {
            spells = spells.concat(customContent.spiritSpells);
        }
        else {
            spells = spells.concat(customContent.spiritSpells).filter(function(spell) {return (!spell.unplayable) });
        }
        spells = spells.filter(function(spell) {return (!spell.cults) || spell.cults.findIndex(obj => obj.id === cultId) });
    }

    return spells.sort(compareValues("name"));
}

export function getRuneSpell(id, customContent) {
    return getItem(id, allRuneSpells.data, customContent, "runeSpells");
}

export function getAllRuneSpells(customContent) {
    var spells = [...allRuneSpells.data];
    if (customContent && customContent.runeSpells) {
        spells = spells.concat(customContent.runeSpells);
    }
    return spells.sort(compareValues("name"));
}

export function getWeapon(id, customContent) {
    return getItem(id, allWeapons.data, customContent, "weapons");
}

export function getWeaponByName(name, customContent) {
    return getItemByName(name, allWeapons.data, customContent, "weapons");
}

export function getAllWeapons(customContent) {
    return getAllItems(allWeapons.data, customContent, "weapons")
}

function getItem(id, data, customContent, field) {
    let item = null;
    let customContentData = [];
    if (customContent && customContent[field]) {
        customContentData = [...customContent[field]]
    }
    let index = data.findIndex(obj => obj.id === id);
    if (index > -1) {
        item = data[index];
    }
    else if (customContentData.length > 0) {
        index = customContentData.findIndex(obj => obj.id === id)
        if (index > -1) {
            item = customContentData[index]
        }
    }
    return item;
}

function getItemByName(name, data, customContent, field) {
    let item = null;
    let customContentData = [];
    if (customContent && customContent[field]) {
        customContentData = [...customContent[field]]
    }
    let index = data.findIndex(obj => obj.name === name);
    if (index > -1) {
        item = data[index];
    }
    else if (customContentData.length > 0) {
        index = customContentData.findIndex(obj => obj.name === name)
        if (index > -1) {
            item = customContentData[index]
        }
    }
    return item;
}

function getAllItems(collection, customContent, field) {
    let items = [...collection]
    if (customContent && customContent[field]) {
        items = items.concat(customContent[field]);
    }
    return items.sort(compareValues("name"));
}
