import React, { Component } from 'react';
import styles from './messages.module.css';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import MessageList from './MessageList';
import { CSSTransition } from 'react-transition-group';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 5,
      show: false,
      isGM: false
    };
  }

  componentDidMount() {
    this.onListenForMessages();
    this.setState({
      isGM: this.props.isGM
    })
  }

  onListenForMessages = () => {
    if (this.props.campaignId) {
    this.setState({ loading: true });
    let start = new Date(Date.now());
    start.setHours(start.getHours() - 1);
    this.unsubscribe = this.props.firebase
      .messages()
      .where("campaignId", "==", this.props.campaignId)
      .where("createdAt", ">", start)
      .limit(50)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach((doc) => {
            messages.push({ ...doc.data(), uid: doc.id });
          });
          this.setState({
            messages: messages,
            loading: false,
            show: true,
          });
        } else {
          this.setState({ messages: null, loading: false });

        }
      });

    }
  };
 
  componentWillUnmount() {
    if (this.props.campaignId) {
      this.unsubscribe();
    }
  }

  render() {
    const { messages } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
                <CSSTransition
                in={this.state.show}
                timeout={1000}
                classNames="alert"
                unmountOnExit
              >
        <div style={{position:"relative"}}>
          <MessageList isGM={this.state.isGM} messages={messages} newMessage={this.state.newMessage}  />
        </div>
          </CSSTransition>

        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);
