
export default function calculateSR(name, character) {
    var value = character.strikeRanks[name];
    if (character.enchantments) {
        character.enchantments.forEach(enchantment => {
            enchantment.effects && enchantment.effects.forEach(effect => {
                if (effect.name === "strikeRankModifier" && effect.item.id === name) {
                    value += effect.value
                }
            })
        });
    }
    if (value < 0) {value = 0;}
    return value;
}
