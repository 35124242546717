import React from 'react';
import { withPageState } from '../Context/PageState';
import CharacteristicTile from './characteristicTile';
import StrengthTile from './strengthTile';
import ConstitutionTile from './constitutionTile';
import SizeTile from './sizeTile';
import DexterityTile from './dexterityTile';
import IntelligenceTile from './intelligenceTile';
import PowerTile from './powerTile';
import CharismaTile from './charismaTile';

const Characteristics = (props) => {

   
    function processResult(roll) {
        var messageText={critical: " achieved a critical success against {pronoun} "  + roll.item.name, special: " achieved a special success against {pronoun} " + roll.item.name, success: " succeeded against {pronoun} " + roll.item.name, failure: " failed against {pronoun} " + roll.item.name, fumble: " fumbled against {pronoun} " + roll.item.name};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText}
        props.processResult(dieRoll, roll.item, roll.title)
    }

    if (props.entity.characteristics) {
        return(
            <div>
                { props.pageState && props.pageState.editMode ? 
                        <div style={{display:"flex", alignItems:"center", flexWrap: "wrap"}}>
                            {props.entity.characteristics.STR ? 
                                <StrengthTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.STR} displayItem={props.displayItem} />
                                </StrengthTile>
                            : 
                            null}
                            {props.entity.characteristics.CON ? 
                                <ConstitutionTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.CON} displayItem={props.displayItem} />
                                </ConstitutionTile>
                            : 
                            null}
                            {props.entity.characteristics.SIZ ? 
                                <SizeTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.SIZ} displayItem={props.displayItem} />
                                </SizeTile>
                            : 
                            null}
                            {props.entity.characteristics.DEX ? 
                                <DexterityTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.DEX} displayItem={props.displayItem} />
                                </DexterityTile>
                            : 
                            null}
                            {props.entity.characteristics.INT ? 
                                <IntelligenceTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.INT} displayItem={props.displayItem} />
                                </IntelligenceTile>
                            : 
                            null}
                            {props.entity.characteristics.POW ? 
                                <PowerTile update={props.update} pageState={props.pageState} entity={props.entity} updateField={props.updateField}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.POW} displayItem={props.displayItem} />
                                </PowerTile>
                            : 
                            null}
                            {props.entity.characteristics.CHA ? 
                                <CharismaTile update={props.update} pageState={props.pageState} entity={props.entity}>
                                    <CharacteristicTile tile={props.tile} stat={props.entity.characteristics.CHA} displayItem={props.displayItem} />
                                </CharismaTile>
                            : 
                            null}
                    </div>
            :
                        <div style={{display:"flex", alignItems:"center", flexWrap: "wrap"}}>
                             {props.entity.characteristics.STR ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.STR} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.CON ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.CON} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.SIZ ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.SIZ} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.DEX ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.DEX} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.INT ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.INT} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.POW ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.POW} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                            {props.entity.characteristics.CHA ? 
                                    <CharacteristicTile diceRoller={props.diceRoller} tile={props.tile}  displayItem={props.displayItem} stat={props.entity.characteristics.CHA} processResult={processResult} update={props.update} entity={props.entity} />
                            : 
                            null}
                        </div>
                }
            </div>
        );
    }
    else {
        return null;
    }
}

export default withPageState(Characteristics);                    