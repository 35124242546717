import React, {useState, useEffect, useRef} from 'react';
import { Button, FormControlLabel, Switch } from '@mui/material';
import ExperienceRows from './experienceRows';
import DiceButton from '../Buttons/diceButton';
import OccupationalSkills from './occupationalSkills';
import SmallDiceButton from '../Buttons/smallDiceButton';
import DialogButtonSingle from '../Dialogs/dialogButtonSingle';
import {withContext} from '../Context/context';
import { withPageState } from '../Context/PageState';
import CloseIcon from '../../icons/close-icon.svg';
import * as SAVE from '../../constants/save';

const ExperienceContextPanel = (props) => {

    const [runes, setRunes] = React.useState();
    const [passions, setPassions] = React.useState();
    const [skills, setSkills] = React.useState();
    const [weaponSkills, setWeaponSkills] = React.useState();
    const [POWGainChoice, setPOWGainChoice] = React.useState(false);
    const [POWGain, setPOWGain] = React.useState(0);
    const [POWResult, setPOWResult] = React.useState("");
    const [updated, setUpdated] = useState(false);
    const [categories, setCategories] = useState({
        runes: false, passions: false, skills: false, weaponSkills: false, POWGain: false, occupationalSkills: false
    })

    useEffect(() => {
        var tempRunes = [];
        var updatedCategories = {...categories};
        props.character.runes.forEach(rune => {
            if (!updatedCategories.runes && rune.hasSucceeded) {
                updatedCategories.runes = true;
            }
            tempRunes.push({...rune}) 
        })
        setRunes(tempRunes);

        var tempPassions = [];
        props.character.passions.forEach(passion => {
            if (!updatedCategories.passions && passion.hasSucceeded) {
                updatedCategories.passions = true;
            }
            tempPassions.push({...passion}) 
        })
        setPassions(tempPassions);

        var tempSkills = [];
        props.character.skills.forEach(skill => {
            if (!updatedCategories.skills && skill.hasSucceeded) {
                updatedCategories.skills = true;
            }
            tempSkills.push({...skill}) 
        })
        setSkills(tempSkills);

        var tempWeaponSkills = [];
        props.character.weaponSkills.forEach(weaponSkill => {
            if (!updatedCategories.weaponSkills && weaponSkill.hasSucceeded) {
                updatedCategories.weaponSkills = true;
            }
            tempWeaponSkills.push({...weaponSkill}) 
        })
        setWeaponSkills(tempWeaponSkills);
        updatedCategories.POWGain = props.character.powGain
        setCategories(updatedCategories);

    }, [props.character]);

    const style = {
        width:"80px",
        marginLeft:"40px"
    };


      
    const displayPOWGain = () => {
        var target = (21 - props.character.characteristics.POW.baseValue) * 5;
        return (
            <div>
                {/* <div style={{display:"flex", fontSize: "14px", padding: "5px 0px", fontWeight:"bold"}}>
                    <div style={{width:"220px", marginLeft:"5px"}}></div>
                    <div style={{width:"90px", textAlign:"center"}}>Success %</div>
                    <div style={{width:"80px"}}>Roll 1D3-1</div>
                    <div style={{width:"120px"}}></div>
                </div> */}

                <div style={{display:"flex", fontSize: "14px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                    <div style={{width:"120px", marginLeft:"5px", textAlign:"left"}}></div>
                    <div style={{width:"66px", textAlign:"center"}}>Success %</div>
                    <div style={{width:"86px"}}>Roll 1D3-1</div>
                    <div style={{width:"80px"}}></div>
                </div>

                <div style={{display: "flex", paddingLeft: "5px", height: "40px", backgroundColor: "#fff", alignItems:"center"}}>
                    <div style={{minWidth:"120px", alignItems: "center", display: "flex"}}>POW Gain Roll</div>
                    <div style={{width:"66px", display:"flex", alignItems: "center", justifyContent:"center"}}>{target}</div>
                    <div style={{width:"86px", display:"flex", alignItems: "center", justifyContent:"flex-end"}}>
                        <FormControlLabel style={{}}
                            control={<Switch checked={POWGainChoice} color='primary' onChange={handlePOWGainChoice} />}
                        />
                    </div>
                    <div style={{width:"80px", display:"flex", alignItems: "center", marginRight: "10px"}}>
                        <DiceButton style={{width: "80px", height: "24px"}} diceRoller={props.diceRoller} disabled={POWResult !== ""} processResult={processPOWGainRoll}  item={{name: "POW Gain", value: target}} entity={props.character}>
                            <SmallDiceButton style={{height: "24px"}} title={target +"%"} />
                        </DiceButton>
                    </div>
                </div>
            </div>
        )
      }

      function handlePOWGainChoice(event) {
        setPOWGainChoice(event.target.checked);
      }

    function processPOWGainRoll(roll) {
        var target = (21 - props.character.characteristics.POW.baseValue) * 5;
        var messageResult = ""
        var updates = [{fieldName: "powGain", value: false}];
        if (roll.result <= target){
            var gain = 1;
            if (POWGainChoice){
                gain = Math.ceil(Math.random()*(3)) -1;
            }
            setPOWResult(roll.result + ", success, you gained " + gain + (gain == 1 ? " point":" points") + " of POW");
            messageResult = " gained " + gain + (gain == 1 ? " point":" points") + " of POW, rolling a " + roll.result;
            if (gain > 0) {
                var updatedCharacteristics = {...props.character.characteristics}
                updatedCharacteristics.POW.baseValue += gain;
                updates.push({fieldName: "characteristics", value: updatedCharacteristics})
                var updatedMagicPoints = [...props.character.magicPoints]
                var index = updatedMagicPoints.findIndex(obj => obj.entityId === props.character.id);
                if (index > -1) {
                    updatedMagicPoints[index].total += gain;
                    updatedMagicPoints[index].current += gain;
                    updates.push({fieldName: "magicPoints", value: updatedMagicPoints})
                }
            }
        }
        else {
            setPOWResult(roll.result + ", failed, you gained no POW on this occasion");
            messageResult = " failed to gain POW on this occasion, rolling a " + roll.result;
        }
        props.update(updates, SAVE.YES)
        props.displayMessage(gain, messageResult, props.user);
    }

    function updateRunes(gain, item, messageResult){
        var updatedRunes = [...runes];
        updatedRunes.forEach(rune => {
            if (rune.name === item.name) {
                rune.value +=gain;
                rune.hasSucceeded = false;
            }
        });
        setUpdated(true);
        setRunes(updatedRunes);
        props.update([{fieldName: "runes", value: updatedRunes}], SAVE.YES)
        props.displayMessage(gain, messageResult, props.user);
    }

    function updatePassions(gain, item, messageResult){
        var updatedPassions = [...passions];
        updatedPassions.forEach(passion => {
            if (passion.name === item.name) {
                passion.value +=gain;
                passion.hasSucceeded = false;
            }
        });
        setUpdated(true);
        setPassions(updatedPassions);
        props.update([{fieldName: "passions", value: updatedPassions}], SAVE.YES)
        props.displayMessage(gain, messageResult, props.user);
    }

    function updateSkills(gain, item, messageResult){
        var updatedSkills = [...skills];
        updatedSkills.forEach(skill => {
            if (skill.id === item.id) {
                skill.value +=gain;
                skill.hasSucceeded = false;
            }
        });
        setUpdated(true);
        setSkills(updatedSkills);
        props.update([{fieldName: "skills", value: updatedSkills}], SAVE.YES)
        props.displayMessage(gain, messageResult, props.user);
    }

    function updatedOccupational(gain, item, messageResult) {
        var index = -1;
        if (item.variant) {
            index = skills.findIndex(skill => skill.name === item.name && skill.variant === item.variant)
        }
        else {
            index = skills.findIndex(skill => skill.name === item.name)
        }
        if (index > -1 ) {
            updateSkills(gain, item, messageResult)
        }
        else {
            updateWeaponSkills(gain, item, messageResult)
        }
    }

    function updateWeaponSkills(gain, item, messageResult){
        var updatedWeaponSkills = [...weaponSkills];
        updatedWeaponSkills.forEach(weaponSkill => {
            if (weaponSkill.name === item.name) {
                weaponSkill.value +=gain;
                weaponSkill.hasSucceeded = false;
            }
        });
        setUpdated(true);
        setWeaponSkills(updatedWeaponSkills);
        props.update([{fieldName: "weaponSkills", value: updatedWeaponSkills}], SAVE.YES)
        props.displayMessage(gain, messageResult, props.user);
    }
 
    const SaveExperience = () => {
        if (updated) {
            var updatedCharacter = {...props.character};
            updatedCharacter.runes = runes;
            updatedCharacter.passions = passions;
            updatedCharacter.skills = skills;
            updatedCharacter.weaponSkills = weaponSkills;
            if (POWGain) {
                updatedCharacter.characteristics.POW.baseValue += POWGain;
                updatedCharacter.magicPoints.total += POWGain;
                updatedCharacter.magicPoints.current += POWGain;
            }
            if (categories.POWGain) {
                updatedCharacter.powGain = (POWResult === "");
            }
            
            props.update(updatedCharacter, true);
        }
        props.close();
    }

    const handleChange = (event, name) => {
        var updatedCategories = {...categories};
        updatedCategories[name] = event.target.checked;
        setCategories(updatedCategories);
        props.update([{fieldName: "powGain", value: event.target.checked}], SAVE.DELAYED)
      };

    return (
        <>
                <div style={{}}>Each ability listed below has been successfully used by this adventurer. Click 'roll' to determine whether the adventurer learns from their experience and increases their knowledge in the ability. By default, any gains will be 1d6, but by clicking on the switch you can amend this so that the increase is automatically set to 3.</div>
                <div>
                    {categories.runes ? 
                        <div>
                            <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Runes</div>
                            <ExperienceRows diceRoller={props.diceRoller} items={props.character.runes} title="Rune" collection="runes" processExperience={updateRunes}></ExperienceRows>
                        </div>
                    : 
                    null}
                    {categories.passions ? 
                        <div>
                            <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Passions</div>
                            <ExperienceRows diceRoller={props.diceRoller} items={props.character.passions} collection="passions" title="Passion" processExperience={updatePassions}></ExperienceRows>
                        </div>
                    : 
                    null}
                    {categories.skills? 
                        <div>
                            <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Skills</div>
                            <ExperienceRows diceRoller={props.diceRoller} items={props.character.skills} collection="skills" title="Skill" processExperience={updateSkills}></ExperienceRows>
                        </div>
                    : 
                    null}
                    {categories.weaponSkills ? 
                        <div>
                            <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>WeaponSkills</div>
                            <ExperienceRows diceRoller={props.diceRoller} items={props.character.weaponSkills} collection="weaponSkills" title="WeaponSkill" processExperience={updateWeaponSkills}></ExperienceRows>
                        </div>
                    : 
                    null}
                    <div>
                        <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px", marginTop: "10px"}}>
                            <div style={{width: "200px"}}>POW Gain Roll</div>
                            <div style={{display:"flex", alignItems: "center"}}>
                                {/* <div style={{fontSize: "14px"}}>Adventurer has earned a POW Gain Roll</div> */}
                                <FormControlLabel style={{marginRight: "0px", marginLeft: "5px", fontSize:"14px"}} label="Eligible"
                                    control={<Switch checked={categories.POWGain} color="default" onChange={(event) => handleChange(event, "POWGain")} />}
                                />
                            </div>
                        </div>
                        {categories.POWGain ? displayPOWGain(): null}
                    </div>
                    <div>
                    <OccupationalSkills diceRoller={props.diceRoller} character={props.character} update={updatedOccupational}></OccupationalSkills>
                    </div>
                </div>
        </>
    );
}
export default withContext(withPageState(ExperienceContextPanel))