import React, { useState, useEffect, useCallback } from 'react';
import * as SAVE from '../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import Selector from '../Controls/selector';
import RuneSpellPanel from './runeSpellPanel';
import EditBox from '../Controls/editBoxSingle';
import DeleteButton from '../Controls/deleteButton';
import * as CultsData from '../../data/cults.json';
import { withContext } from '../Context/context';
import { newWeapon, newWeaponSkill } from '../../services/weaponService';
import { getAllCults, getAllPantheons, getCult, getRuneSpell } from '../../services/dataService';
import getElementalRune from '../../services/runesService';
import MultiSelect from '../Controls/multiSelect';
import { getSpells } from '../../services/cultsService';


const CultDialog = (props) => {

	const [items, setItems] = useState([]);
	const [availableCults, setAvailableCults] = useState([]);
	const [selected, setSelected] = useState("Select");
	const [selectedCult, setSelectedCult] = useState();
	const [pantheon, setPantheon] = useState("");
	const [rank, setRank] = useState("");
    const [complete, setComplete] = useState(false);
    const [runeSpells, setRuneSpells] = useState([]);
	const [runePoints, setRunePoints] = useState(0);
	const [availableSpells, setAvailableSpells] = useState([]);
	const [subcults, setSubcults] = useState([])
	const [selectedSubcults, setSelectedSubcults] = useState([]);
	const ranks = [{name: "Lay Member"}, {name: "Initiate"}, {name: "God Talker"}, {name: "Rune Lord"}, {name: "Rune Priest"}, {name: "Shaman"}];
	const allCults = getAllCults(props.providerData.campaign).filter(function(cult) {return !cult.restricted && !cult.parentCultId})

	useEffect(() => {
		if (!props.cult) {
			var cults = [];
			allCults.forEach(cult => {
				cults.push(cult);
			});
			var newCults = cults.filter(function(e) {
				var pos = props.entity.cults.findIndex(obj => obj.name === e.name);
				return pos < 0;
			});
			setItems(newCults);
		}
	},[]);

	useEffect(() => {
		if (props.cult) {
			setPantheon(props.cult.pantheon)
			var cults=[props.cult];
			setAvailableCults(cults)
			setRank(props.cult.rank);
			setRunePoints(props.cult.runePoints[0].total);
			var spells = [];
			allCults.forEach(cult => {
				if (cult.id === props.cult.parentId) {
					setSelectedCult({...props.cult, subtitle: cult.subtitle, description: cult.description, husbandProtectors: cult.husbandProtectors});
					if (cult.subcults) {
						let subcults = [];
						cult.subcults.cults.forEach(subcult => {
							subcults.push(subcult.name);
						})
						setSubcults(subcults);
						if (props.cult.subcults) {
							let subcults = [];
							props.cult.subcults.forEach(subcult => {
								subcults.push({...getCult(subcult.parentId, props.providerData.campaign)});
							});
							setSelectedSubcults(subcults);
						}

					}
				}
			});
			props.cult.runeSpells.forEach(spell => {
				var knownSpell = {id: spell.id, parentId: spell.parentId, points: spell.points, stackable: spell.stackable, runes: spell.runes, target: spell.target || {}, name: spell.name};
				if (spell.variant) {knownSpell.variant = spell.variant}
				const spellDetails = getRuneSpell(spell.parentId, props.providerData.campaign);
				knownSpell = {...knownSpell, description: spellDetails.description, duration: spellDetails.duration, range: spellDetails.range};
				if (spellDetails.common) {knownSpell.common = true;}
				spells.push(knownSpell)
			})
			setRuneSpells(spells);
		}
	},[props.cult])

	useEffect(() => {
		var cults = [];
		items.forEach(item => {
			if (item.pantheons.indexOf(pantheon) > -1) {
				cults.push(item);
			}
		  });

		  setAvailableCults(cults);
		},[pantheon])

	useEffect(() => {
		if (selectedCult) {
			let cult = getCult(selectedCult.parentId, props.providerData.campaign)
			if (cult) {
				var spells = getSpells(cult, selectedSubcults, props.providerData.campaign)
				setAvailableSpells(spells);
			}
		}
	},[selectedCult, selectedSubcults])
	
	function selectCult(value) {
		setSelected(value);
		const index = items.findIndex(item => item.name === value);
		let cult = {...items[index], parentId: items[index].id};
		setSelectedCult(cult);
		let subcults = [];
		if (cult.subcults) {
			cult.subcults.cults.forEach(subcult => {
				subcults.push(subcult.name);
			});
		}
		setSubcults(subcults);
		setSelectedSubcults([]);
	}

    const save = useCallback(() => {
		setComplete(false);
		var updates = [];
		var updatedCults = [...props.entity.cults];        
		var newCult = {id: props.cult ? props.cult.id : uuidv4(), parentId: selectedCult.parentId, name: selectedCult.name, pantheon: pantheon, rank: rank, runes: selectedCult.runes}
		if (selectedSubcults.length > 0) {
			let subcults = [];
			selectedSubcults.forEach(subcult => {
				subcults.push({name: subcult.name, parentId: subcult.id});
			})
			newCult.subcults = subcults;
		}
		newCult.runePoints=[{type: "character", entityId: props.entity.id, current: runePoints, total: runePoints}]
		var spells = [];
		runeSpells.forEach(runeSpell => {
			const spellDetails = getRuneSpell(runeSpell.parentId, props.providerData.campaign)
			let runes = [];
			if (spellDetails.runes[0] === "Magic") {
				runes = [...selectedCult.runes];
			}
			else if (spellDetails.runes[0] === "Elemental") {
				runes = getElementalRune(selectedCult.runes);
			}
			else {
				runes = [...spellDetails.runes]
			}

			var spell = {name: runeSpell.name, parentId: runeSpell.parentId, id: runeSpell.id || uuidv4(), points: spellDetails.points, stackable: spellDetails.stackable, target: spellDetails.target || "", runes: runes};
			if (runeSpell.variant) {spell.variant = runeSpell.variant;}
			if (spellDetails.common) {spell.common = true;}
			spellDetails.effects && spellDetails.effects.forEach(effect => {
				if (effect.name === "addWeapon") {
					var updatedWeaponSkills = newWeaponSkill(effect, props.providerData.character);
					updates.push({fieldName: "weaponSkills", value: updatedWeaponSkills});
					var updatedWeapons = newWeapon(effect, props.providerData.character);
					updates.push({fieldName: "weapons", value: updatedWeapons});
				}
			})
			spells.push(spell)
		});
		if (!props.cult) {
			selectedCult.commonRuneSpells.forEach(runeSpell => {
				const spellDetails = getRuneSpell(runeSpell.parentId, props.providerData.campaign)
				spells.push({name: spellDetails.name, parentId: runeSpell.parentId, id: runeSpell.id || uuidv4(), common: true, points: spellDetails.points, stackable: spellDetails.stackable, target: spellDetails.target || "", runes: [...selectedCult.runes]})
				})
		}
		newCult.runeSpells = spells;
        var index = updatedCults.findIndex(item => item.parentId === selectedCult.parentId);
        if (index < 0) {
			updatedCults.push({...newCult, id: uuidv4()});
			updates.push({fieldName: "cults", value: updatedCults})
		}
        else {
            updatedCults[index] = newCult;
			updates.push({fieldName: "cults", value: updatedCults});
        }
		props.update(updates, SAVE.NO);
        props.close()

    })

	function selectRank(value) {
		setRank(value);
	}

	function selectSubcult(values) {
		let selected = [];
		let cult = getCult(selectedCult.parentId);
		values.forEach(value => {
			let index = cult.subcults.cults.findIndex(obj => obj.name === value);
			if (index > -1) { 
				let parentId = cult.subcults.cults[index].parentId
				let subcult = getCult(parentId)
				selected.push(subcult);
			}
		});
		setSelectedSubcults(selected);
	}

	function handleChange(value) {
		setRunePoints(value);
	}

	function handleCancelClick(){
		props.close();
	}

	function handleDeleteClick(deletedCult){
		var updatedCults = [...props.entity.cults]
		var index = updatedCults.findIndex(item => item.id === deletedCult.id);
		updatedCults.splice(index, 1);
        props.update([{fieldName: "cults", value: updatedCults}], SAVE.NO);
		props.close();
	}
	  
	function handleOkClick(){
		setComplete(true);
	}
	
	function namesOf(items) {
		var values = [];
		items.forEach(item => {
			values.push(item.name);
		});
		return values;
	}

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", padding: "10px"}}>
				<div>
					<div style={{display:"flex", margin: "10px 0px", alignItems: "center"}}>
						<div >
							{props.cult ? 
								<div style={{fontWeight: "bold"}}>{"Pantheon: " + props.cult.pantheon}</div>
							: 
								<Selector updateSelected={(value) => setPantheon(value)} values={getAllPantheons()} label="Pantheon:" style={{width: "160px", height: "30px"}} value={pantheon} />
							}
						</div>
						{pantheon ? 
							props.cult ? 
								<div style={{marginLeft: "20px", fontWeight: "bold"}}>{"Cult: " + props.cult.name}</div>
							: 
							<div style={{marginLeft: "20px"}}>
								<Selector updateSelected={selectCult} values={availableCults} label="Cult:" style={{width: "200px", height: "30px"}} value={selected} />
							</div>
						: 
						null
						}
						{
							subcults.length > 0 ? 
								<div style={{display: "flex", alignItems: "center", marginLeft: "10px"}}>
									<div>Subcults:</div>
									<MultiSelect values={subcults} selectedValues={namesOf(selectedSubcults)} onChange={selectSubcult} />
								</div>
							: 
							null
						}
					</div>
					<div style={{display:"flex", margin: "10px 0px", alignItems: "center"}}>
						{selectedCult ? 
							<div style={{marginLeft: "20px", display:"flex", alignItems: "center"}}>
								<Selector updateSelected={selectRank} values={ranks} label="Rank:" style={{width: "160px", height: "30px"}} value={rank} />
								<div style={{marginLeft: "20px"}}>
									<EditBox size="sm" label="Rune Points: " onChange={handleChange} value={runePoints} number width="40px">{runePoints}</EditBox>
								</div>
							</div>
						: 
							null
						}
					</div>
					{selectedCult ? 
						<div>
							<div style={{fontSize: "18px", fontWeight: "bold", marginTop: "20px"}}>
								{selectedCult.name}
							</div>
							<div style={{fontSize: "15px", fontWeight: "bold", marginTop: "10px"}}>
								{selectedCult.subtitle}
							</div>
							<div dangerouslySetInnerHTML={{__html: selectedCult.description}}></div>
                            <RuneSpellPanel campaign={props.providerData.campaign} availableSpells={availableSpells} characterRunes={props.providerData.character.runes} cult={selectedCult} currentSpells={runeSpells} setSpells={()=> {}} complete={complete} save={save} storage={props.entity.characteristics.CHA.baseValue} select={setRuneSpells} />
						</div>
							: 
					null
					}
					<div style={{display:"flex", marginTop:"30px", padding: "20px", alignItems:"flex-end", flexDirection: "row-reverse", justifyContent: "space-between"}}>
						<div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
							<Button disabled={!pantheon || !selectedCult || !rank} type="submit" onClick={handleOkClick} style={{width: "100px"}} color="primary" variant="contained">Ok</Button>
							<Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
						</div>
						<div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
							{props.cult ? 
								<DeleteButton disabled={props.cult.runePoints.length > 1} delete={handleDeleteClick} type="cult" item={props.cult} title="Delete cult" />
							: 
							null
							}
						</div>
					</div>
				</div>
			
		</div>
	);
};

export default withContext(CultDialog);
