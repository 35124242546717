import React, { useEffect, useState } from "react";
import globalStyles from '../../Styles/styles.module.css';
import d10Icon from '../../../icons/d10-white-icon.svg';
import CloseIcon from '../../../icons/close-icon.svg';

const ResultWindow = (props) => {

    const [resultRight, setResultRight] = useState(0);
    const [resultClass, setResultClass] = useState("moveFromBottomFade");

    useEffect(() => {
        var width = window.innerWidth;
        if (width > 1804) {
            var result = (((window.innerWidth -1200)/2) - 300)
            setResultRight(result);
        }
        else {
            setResultRight(0);
        }
    },[window.innerWidth])

    useEffect(() => {
        setResultClass(resultClass === "moveFromBottomFade" ? "moveFromBottomFade2" : "moveFromBottomFade")
    },[props.result, props.rolls])

    function closeResult() {
        setResultClass("hiddenResult")
    }

    return (
        <div className={globalStyles.resultBar} style={{right: resultRight, top:"80vh"}}>
        <div className={globalStyles[resultClass]}>
            <div id="resultPanel" style={{width: "300px", fontSize: "16px", left: "auto", border: "2px solid #fff", borderRadius: "5px"}}>
                {props.result ? 
                    <div style={{...props.result.resultType.style, color: "#fff"}}>
                        <div style={{color: "#fff", backgroundColor: props.result.resultType.style.headerColor, padding: "3px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div>{props.result.title}</div>
                            <img style={{width: "25px", marginLeft: "10px", cursor: "pointer"}} src={CloseIcon} onClick={closeResult} />
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{display:"flex"}}>
                                <img style={{width:"45px", height:"45px"}} src={d10Icon}></img>
                                <img style={{width:"40px", height:"40px"}} src={d10Icon}></img>

                            </div>
                            {props.result.dieRoll.target && props.result.dieRoll.target > 0 ? 
                                <div style={{padding: "10px"}}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div>{"Target: " + props.result.dieRoll.target}</div>
                                        <div style={{marginLeft: "20px"}}>{"Roll: " + props.result.dieRoll.result}</div>
                                    </div>
                                    <div style={{fontSize: "26px", padding: "10px 0px"}}>{props.result.resultType.name}</div>
                                </div>
                                : 
                                <div style={{padding: "10px"}}>
                                    <div style={{fontSize: "26px", padding: "10px 0px"}}>Result: {props.result.dieRoll.result}</div>
                                </div>
                            }
                        </div>
                    </div>
                : 
                <div style={{color: "#fff"}}>
                        <div style={{backgroundColor: "#851113", padding: "3px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div>{props.rolls.title}</div>
                            <img style={{width: "25px", marginLeft: "10px", cursor: "pointer"}} src={CloseIcon} onClick={closeResult} />
                        </div>
                        <div style={{height: "120px", backgroundColor: "#121312"}}>
                            <div style={{display: "flex", alignItems: "center", backgroundColor: "#121312", fontSize: "24px", padding: "10px"}}>
                                <div style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
                                    {props.rolls.rolls.reverse().map((item, index) => {
                                        return (
                                            <div key={index} style={{display: "flex"}}>
                                                <div >{item.type}</div>
                                                {index < props.rolls.rolls.length -1 ? 
                                                    <div>&nbsp;+&nbsp;</div>
                                                    : 
                                                null}
                                            </div>
                                        )
                                    })}
                                    {props.rolls.modifier ? 
                                        <div>&nbsp;+&nbsp;{props.rolls.modifier}</div>
                                    : 
                                    null}
                                    {props.rolls.doubled ? 
                                        <div>&nbsp;doubled</div>
                                    : 
                                    null}
                                </div>
                            </div>
                            <div style={{display: "flex", alignItems: "center", backgroundColor: "#121312", fontSize: "24px", padding: "10px"}}>
                                <div style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
                                    {props.rolls.rolls.map((item, index) => {
                                        return (
                                            <div key={index} style={{display: "flex"}}>
                                                <div >{item.roll}</div>
                                                {index < props.rolls.rolls.length -1 ? 
                                                    <div>&nbsp;+&nbsp;</div>
                                                : 
                                                null}
                                            </div>
                                        )
                                    })}
                                    {props.rolls.modifier ? 
                                        <div>&nbsp;+&nbsp;{props.rolls.modifier}</div>
                                        : 
                                    null}
                                    {props.rolls.doubled ? 
                                        <div>&nbsp;x 2</div>
                                        : 
                                    null}
                                    <div>&nbsp;=&nbsp;</div>
                                    <div style={{backgroundColor: "#fff", color: "#851113", width: "30px", textAlign: "center", borderRadius: "30px", padding: "5px", fontWeight: "bold"}}>{props.rolls.result}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>

    )
}

export default ResultWindow;