import React, {useState, useEffect} from 'react'
import { Button } from '@mui/material';
import AddReputationDialog from './addReputationDialog';
import DialogBox from '../../Dialogs/dialogBox';
import DialogButtonSingle from '../../Dialogs/dialogButtonSingle';
import EditBoxMulti from '../../Controls/editBoxMulti';

const ReputationDialog = (props) => {

    const [background, setBackground] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setBackground(props.character.personalInfo.family.notes);
    },[props.character.personalInfo.family])

    function save() {
        var updatedCharacter = {...props.character};
        updatedCharacter.personalInfo.family.notes = background;
        props.update(updatedCharacter, true);
    }

    return (
        <div style={{height: "480px", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
            <div style={{maxHeight: "480px", overflowY: "auto", overflowX: "hidden", padding: "5px"}}>
                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "2px solid #090909", marginBottom: "10px", height: "36px", display:"flex", alignItems: "center", padding: "0px 5px", borderRadius: "5px", marginTop: "5px"}}>Reputation</div>
                {
                    props.character.background.reputation.map((item, index) => {
                        return (
                            <div key={index} style={{display: "flex", margin: "2px"}}>
                                <div style={{marginRight: "20px"}}>+{item.value}%</div>
                                <div>{item.event}</div>
                            </div>
                        )
                    })
                }
                <div style={{width: "100%", display:"flex", flexDirection:"row-reverse"}}>
                    <Button style={{marginBottom: "10px", height: "32px"}} onClick={() => setOpen(true)} color="primary" variant="contained">Add to Reputation</Button>
                </div>

                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "2px solid #090909", marginBottom: "10px", height: "36px", display:"flex", alignItems: "center", padding: "0px 5px", borderRadius: "5px", marginTop: "5px"}}>History</div>
                {
                    props.character.history.events.map((event, index) => {
                        return (
                            <div key={index} style={{}}>
                                <div style={{fontWeight: "bold"}}>{event.year}</div>
                                {event.outcomes.map((outcome, outcomeIndex) => {
                                    return (
                                        <div key={outcomeIndex}>{outcome}</div>
                                    )
                                })}
                            </div>
                        )
                    })
                }

                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "2px solid #090909", marginBottom: "10px", height: "36px", display:"flex", alignItems: "center", padding: "0px 5px", borderRadius: "5px", marginTop: "5px"}}>Family</div>
                <div style={{display: "flex"}}>
                    <div><b>Father:</b>&nbsp;{props.character.personalInfo.family.father}</div>
                    <div style={{marginLeft: "20px"}}><b>Mother:</b>&nbsp;{props.character.personalInfo.family.mother}</div>
                </div>
                <div style={{marginTop: "10px"}}>
                    <b>Siblings:</b>
                </div>
                {
                    props.character.personalInfo.family.siblings.map((sibling, index) => {
                        return (
                            <div key={index} style={{display: "flex"}}>
                                <div style={{marginLeft: "10px"}}>{sibling.name}</div>
                                <div style={{marginLeft: "10px"}}>{sibling.age}</div>
                                <div style={{marginLeft: "10px"}}>{sibling.relation}</div>
                                <div style={{marginLeft: "10px"}}>{sibling.fate}</div>
                            </div>
                        )
                    })
                }
                <div style={{display:"flex", width: "100%", alignItems: "flex-end", marginTop: "10px"}}>
                    <EditBoxMulti onChange={setBackground} value={background} />
                    <Button style={{marginLeft: "10px", height: "32px"}} onClick={save} color="primary" variant="contained">Save</Button>
                </div>
            </div>
            <DialogButtonSingle closeClick={props.close} />
            <DialogBox isOpen={open}  width="600px" title="Add Reputation">
                <AddReputationDialog update={props.update} character={props.character} close={() => setOpen(false)} />
            </DialogBox>
        </div>
    )
}
export default ReputationDialog;