import React, { useState, useEffect} from 'react';
import styles from './inventory.module.css';
import EquipmentIcon from '../../icons/equipment-icon.svg'
import EncumberedIcon from '../../icons/encumbered-icon.svg'
import { calculateCurrentEncumbrance } from '../../services/attributeService';

export default function InventoryButton(props) {
    const [buttonClass, setButtonClass] = useState(styles.button);
    const [backgroundColor, setBackgroundColor] = useState("#fff");
    const [icon, setIcon] = useState(EquipmentIcon);

    useEffect(() => {
        if (props.character) {
            if (props.character.maxEnc < props.currentEncumbrance ) {
                setButtonClass(`${styles.glowingRedButton} ${styles.roundButton}`);
                setIcon(EncumberedIcon);
                setBackgroundColor("#851113");
            }
            else {
                setButtonClass(styles.button);
                setIcon(EquipmentIcon);
                setBackgroundColor("#fff");
            }
            if (props.npc) {
                setButtonClass("")
            }
        }
    },[props.character, props.currentEncumbrance])

    const handleClick = () => {
        props.displayPanel();
    };

    const width = props.width || "35px";

    return (
        <>
            <div onClick={handleClick} className={buttonClass} style={{...props.style, backgroundColor: backgroundColor, cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", border: props.npc ? "" : "2px solid #2f588a", marginTop: props.npc ? "" : "10px"}}>
                <img style={{width:width}} src={icon} title="Manage inventory" />
            </div>
        </>
    );
}