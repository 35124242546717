export function getResultType(target, result, augmenttype){

    let fumbleCalc = 4;
    let fumbleMod = 80 - target;
    if (fumbleMod <= 0){
        fumbleCalc = 0;
    }
    else {
        fumbleCalc = Math.round(fumbleMod/20);
    }
    if (target < 0) {
        return {name: "D100 Rolled", isSuccess: false, modifier: 0, augmentationLabel: "", style: {border: "2px solid #ffffff", backgroundColor: "#121312", color: "#ffffff", headerColor: "#2F588A", borderRadius: "5px", height: "120px"}};
    }
    if (result === 1 || result <= Math.round(target / 20)) {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Critical", isSuccess: true, modifier: 50, augmentationLabel: "Temporary augmentation bonus of +50% to selected ability", style: {border: "2px solid #ffffff", backgroundColor: "#2F7589", color: "#ffffff", headerColor: "#2F7589", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Critical", isSuccess: true, modifier: 50, augmentationLabel: "Temporary augmentation bonus of +50% to selected ability", style: {border: "2px solid #ffffff", backgroundColor: "#2F7589", color: "#ffffff", headerColor: "#2F7589", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Critical", isSuccess: true, modifier: 50, augmentationLabel: "Augmentation bonus of +50% to selected ability for next roll", style: {border: "2px solid #ffffff", backgroundColor: "#2F7589", color: "#ffffff", headerColor: "#2F7589", borderRadius: "5px", height: "120px"}};
        }
    }
    else if (result === 100 || result >= 100 - fumbleCalc) {
        switch (augmenttype) {
            case "runes" :
                return {name: "Fumble", isSuccess: false, modifier: 0, augmentationLabel: "Rune permanently reduced by [reduction]%. Roll for Psychic Turmoil", style: {border: "2px solid #cdcdcd", backgroundColor: "#851113", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Fumble", isSuccess: false, modifier: 0, augmentationLabel: "Passion permanently reduced by [reduction]%. Roll for Despair", style: {border: "2px solid #cdcdcd", backgroundColor: "#851113", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Fumble", isSuccess: false, modifier: -50, augmentationLabel: "Augmentation penalty of -50% to selected ability for next roll", style: {border: "2px solid #cdcdcd", backgroundColor: "#851113", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
        }
    }
    else if (result >= 96) {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Failure", isSuccess: false, modifier: 0, augmentationLabel: "Rune temporariliy reduced by -20%", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Failure", isSuccess: false, modifier: 0, augmentationLabel: "Augmentation penalty of -10% to all rolls for duration of despondency", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Failure", isSuccess: false, modifier: -20, augmentationLabel: "Augmentation penalty of -20% to selected ability for next roll", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
        }
    }            
    else if (result <= Math.round(target / 5)) {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Special", isSuccess: true, modifier: 30, augmentationLabel: "Temporary augmentation bonus of +30% to selected ability", style: {border: "2px solid #ffffff", backgroundColor: "#2f588a", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Special", isSuccess: true, modifier: 30, augmentationLabel: "Temporary augmentation bonus of +30% to selected ability", style: {border: "2px solid #ffffff", backgroundColor: "#2f588a", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Special", isSuccess: true, modifier: 30, augmentationLabel: "Augmentation bonus of +30% to selected ability for next roll", style: {border: "2px solid #ffffff", backgroundColor: "#2f588a", color: "#ffffff", headerColor: "#121312", borderRadius: "5px", height: "120px"}};
        }
    }            
    else if (result <= target || result <= 5) {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Temporary augmentation bonus of +20% to selected ability", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Temporary augmentation bonus of +20% to selected ability", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Augmentation bonus of +20% to selected ability for next roll", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
        }
    }
    else if (result <= 5) {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Temporary augmentation bonus of +20% to selected ability", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Temporary augmentation bonus of +20% to selected ability", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Success", isSuccess: true, modifier: 20, augmentationLabel: "Augmentation bonus of +20% to selected ability for next roll", style: {border: "2px solid #2f588a", backgroundColor: "#121312", color: "#2f588a", headerColor: "#2f588a", borderRadius: "5px", height: "120px"}};
        }
    }
    else {
        switch (augmenttype) {
            case "runes" : 
                return {name: "Failure", isSuccess: false, modifier: 0, augmentationLabel: "Rune temporariliy reduced by -20%", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
            case "passions" : 
                return {name: "Failure", isSuccess: false, modifier: 0, augmentationLabel: "Augmentation penalty of -10% to all rolls for duration of despondency", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
            default: 
                return {name: "Failure", isSuccess: false, modifier: -20, augmentationLabel: "Augmentation penalty of -20% to selected ability for next roll", style: {border: "2px solid #851113", backgroundColor: "#121312", color: "#851113", headerColor: "#851113", borderRadius: "5px", height: "120px"}};
        }
    }
}

export function postMessage(authUser, dieRoll, firebase, character, item){
    var message = "";
    var resultType = getResultType(dieRoll.target, dieRoll.result);
    var pronoun = character.personalInfo.gender === "Male" ? "his " : character.personalInfo.gender === "Female" ? "her " : "their ";

    switch (resultType.name) {
        case "Critical":
            message = dieRoll.messageText.critical;
            break;
        case "Special":
            message = dieRoll.messageText.special;
            break;
        case "Success":
            message = dieRoll.messageText.success;
            break;
        case "Failure":
            message = dieRoll.messageText.failure;
            break;
        case "Fumble":
            message = dieRoll.messageText.fumble;
            break;
    }
    if (dieRoll.messageText.target) {
        message += " on " + dieRoll.messageText.target;
    }

    if (dieRoll.result.toString()[0] == "8") {
        message += ", rolling an ";
    }
    else {
        message += ", rolling a ";
    }
    message += dieRoll.result;
    message = message.replace("{pronoun}", pronoun);
    var hidden = dieRoll.hidden || false;
    var hiddenText = hidden ? "attempted to roll against " + pronoun + " " + item.name + " skill" : ""
    
    if (character.campaignId) {
        if (dieRoll.result) {
            firebase.messages().add({
                text: message,
                hidden: hidden,
                hiddenText: hiddenText,
                result:resultType.name,
                userId: authUser.uid,
                firstName: authUser.firstName || "",
                characterName: character.name.first,
                campaignId: character.campaignId,
                createdAt: firebase.fieldValue.serverTimestamp(),
            })
            .then(function(docRef) {
            })
            .catch(function(error) {
            });
        }
    }
}


export function postDamageMessage(damageRoll, authUser, firebase, character, isSpiritCombat) {
    var locationName = "";
    if (damageRoll.location > 18) {locationName = "head ";}
    else if (damageRoll.location > 15) {locationName = "left arm ";}
    else if (damageRoll.location > 12) {locationName = "right arm ";}
    else if (damageRoll.location === 12) {locationName = "chest ";}
    else if (damageRoll.location > 8) {locationName = "abdomen ";}
    else if (damageRoll.location > 4) {locationName = "left leg ";}
    else if (damageRoll.location > 0){locationName = "right leg ";}

    if (character.campaignId) {
        if (damageRoll.result && damageRoll.show) {
            var message = "";
            if (isSpiritCombat) {
                message = "inflicted " + damageRoll.result + " points of spirit combat damage";
                if (damageRoll.location) {
                    message += " and, if the target is embodied, " + damageRoll.rolls.length + (damageRoll.rolls.length === 1 ? " point": " points") + " of physical damage to the " + locationName;
                }
            }
            else if (locationName){
              message = "struck the " + locationName +"(" + damageRoll.location + ") for a total of " + damageRoll.result + (damageRoll.result == 1 ? " point of damage" : " points of damage");
            }
    
            firebase.messages().add({
                text: message,
                result:"Damage",
                userId: authUser.uid,
                firstName: authUser.firstName,
                characterName: character.name.first,
                campaignId: character.campaignId,
                createdAt: firebase.fieldValue.serverTimestamp(),
            });
        }
    }
}

export function postDicePoolMessage(dieRoll, authUser, firebase, character, modifier) {
    if (character.campaignId) {
        if (dieRoll && dieRoll.dicePool) {
            var title = displayDiceRolled(dieRoll.dicePool);
            var message = "";
            if (dieRoll.dicePool.d100 > 0 || dieRoll.dicePool.d20 > 0) {
                message = "rolled " + title + " and got a " + dieRoll.result;
            }
            else {
                message = "rolled " + title + (modifier && modifier > 0 ? "+" + modifier : modifier && modifier < 0 ? modifier : "") + " for a total of " + dieRoll.result;
            }
    
            firebase.messages().add({
                text: message,
                result:"Damage",
                userId: authUser.uid,
                firstName: authUser.firstName,
                characterName: character.name.first,
                campaignId: character.campaignId,
                createdAt: firebase.fieldValue.serverTimestamp(),
            });
        }
    }
}

export function displayDiceRolled(dicePool) {
    var title = "";
    var d10 = 0;
    var d8 = 0; 
    var d6 = 0; 
    var d4 = 0;
    var mod = 0;

    if (dicePool) {

        if (dicePool.d100 > 0) {
            title = "1d100";
        }
        if (dicePool.d20 > 0) {
            title = "1d20";
        }
        else {
            Object.keys(dicePool).forEach(die => {
                switch(die) {
                    case "d10":
                        d10 += dicePool.d10 || 0;
                        break;
                    case "d8":
                        d8 += dicePool.d8 || 0;
                        break;
                    case "d6":
                        d6 += dicePool.d6 || 0;
                        break;
                    case "d4":
                        d4 += dicePool.d4 || 0;
                        break;
                    default:
                        break;
                }
            })
            if (d10) title += d10 + "d10";
            if (d8) title = title ? title + "+" + d8 + "d8" : d8 + "d8";
            if (d6) title = title ? title + "+" + d6 + "d6" : d6 + "d6";
            if (d4) title = title ? title + (d4 < 0 ? "" : "+") + d4 + "d4" : d4 + "d4";

            if (mod) {
                title += "+" + mod;
            }
        }
    }
    if (title.toLowerCase() === "1d1001d10") {title = "1d100"}
    return title;
}


export function displayDicePool(weaponDamage, magicDamage){
    var dicePool = [];
    if (weaponDamage){
      var dicePool = [...weaponDamage];
    }
    if (magicDamage) {
        magicDamage.forEach((magicDice) => {
          dicePool.forEach((die) => {
              if (magicDice.dieType === die.dieType){
                  die.quantity += magicDice.quantity;
                  die.modifier += magicDice.modifier;
              }
          })
        })
    }
    return dicePool;
}