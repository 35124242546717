import React, { useEffect, useState } from 'react';
import globalStyles from '../Styles/styles.module.css';
import {withContext} from '../Context/context';
import styles from '../Characters/character.module.css';
import { Button, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import ManRune from '../../images/man-rune-270x360.png'
import { getSpiritSpell } from '../../services/dataService';

const WeaponPickerDialog = (props) => {
	const [selectedCharacter, setSelectedCharacter] = useState({name:""});
	const [selectedItem, setSelectedItem] = useState();
	const [message, setMessage] = useState();
	const [remember, setRemember] = useState(true);
	const [items, setItems] = useState([]);
	const [disabled, setDisabled] = useState(true);
    const [characters, setCharacters] = useState([]);
	const [spell, setSpell] = useState();

    useEffect(() => {
		if (props.providerData) {
			setCharacters([...props.providerData.alliedCharacters]);
		}
		else {
			setCharacters([...props.castingDetails.alliedCharacters]);
		}
    }, [props.providerData, props.castingDetails]);

	useEffect(() => {
		if (props.isOpen) {
			if (!selectedCharacter) {setDisabled(false);}
			else {setDisabled(!(selectedCharacter && selectedItem > -1))}
		}
	},[selectedCharacter, selectedItem])

	useEffect(() => {
		let spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + ". Select the character you wish to cast this spell on.")
		setSpell({...props.spell});
	},[]);

	useEffect(() => {
		if (props.spell.default) {
			if (props.spell.default.characterId === props.providerData.character.id) {
				selectCharacter(props.providerData.character)
			}
			else if (characters) {
				characters.forEach(character => {
					if (character.id === props.spell.default.characterId) {
						selectCharacter(character);
					}
				});
			}
		}
	},[props.providerData.character, characters]);
	  
	function handleOkClick(){
        var selection = (selectedItem > -1) ? items[selectedItem] : null;
        props.processSpell(remember, selectedCharacter, selection);
	}

	function selectCharacter(character){
		var characterNotChanged = true;
		var itemIndex = -1;
		if (selectedCharacter) {
			if (character.id != selectedCharacter.id) {
				itemIndex = -1;
				characterNotChanged = false;
			}
		}
		var name = character.name.first === props.providerData.character.name.first ? "yourself" : character.name.first; 
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		var newMessage = "You are casting " + spellName + " on " + name;
		if (props.weaponSpell) {
			newMessage += ". Now select the target weapon. "
		}
		else {
			newMessage += ". Click Ok to confirm."
		}
		setMessage(newMessage);
		setSelectedCharacter(character);
		if (props.selection) {
			var possibleItems = character[props.selection];

			if (spell && spell.target.filter) {
				possibleItems = possibleItems.filter(function(item) {
                    let valid = true;
					if (spell.target.filter.classes) {
						if ( ! ((item.equipped) && (spell.target.filter.classes.indexOf(item.class) > -1))) {
                            valid = false;
                        }
					}
					if (valid && spell.target.filter.damageTypes) {
						if ( ! ((item.equipped) && (spell.target.filter.damageTypes.indexOf(item.damage.type) > -1) && (!item.hidden))) {
                            valid = false;
                        }
					}
					if (valid && spell.target.filter.categories) {
						if (spell.target.filter.categories.indexOf(item.type) < 0) {
                            valid = false;
                        }
					}
                    return valid;
				});
			}
			setItems(possibleItems);
			if (props.spell.default && props.spell.default.selection && (character.id == props.spell.default.characterId)) {
				itemIndex = possibleItems.findIndex(item => item.title == props.spell.default.selection);
			}
			setSelectedItem(itemIndex);
		}
	}

	function selectItem(index) {
		try {
			setSelectedItem(index);

			var name = selectedCharacter.name.first === props.providerData.character.name.first ? "your" : selectedCharacter.name.first + "'s"; 
			var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
			var item = items[index];

			setMessage("You are casting " + spellName + " on " + name + " " + item.name.toLowerCase() + ". Click Ok to confirm.");
		}
		catch {
		}
	}

	function selectOther(){
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + " on a character outside of your party. Click Ok to confirm.");
		setSelectedCharacter(null);
		setSelectedItem(null);
		setItems(null);
	}

	function handleCancelClick(){
		props.close();
	}

	function handleRememberChange() {
		setRemember(!remember)
	}

	if (props.providerData.character && props.spell && characters) {
		var border = "5px solid #454545";
		var opacity = 0.6;
		if (selectedCharacter && props.providerData.character.id === selectedCharacter.id) {
			border = "5px solid #2f588a";
			opacity = 1;
		}
		return (
			<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", minHeight: "590px", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
	            <div style={{position:"relative", padding: "10px", height: "100%"}}>
			<div>
				<div>
					<div style={{marginBottom:"20px", height: "40px", fontWeight:"bold"}}>{message}</div>
					<div style={{display:"flex"}}>
						<div onClick={() => selectCharacter(props.providerData.character)} >
							<img src={props.providerData.character.profileImage || "https://storage.googleapis.com/runequest/bestiary/default.png"} style={{width:"180px", borderRadius: "5px", border: border, opacity: opacity}} />
						</div>
						<div style={{display:"flex", flexWrap: "wrap", flexDirection:"column", height: "260px"}}>
							{characters.map((character, index) => {
								var highlight = selectedCharacter && character.id === selectedCharacter.id;
								var otherBorder = highlight ? "5px solid #2f588a" : "3px solid #454545";
								return (
									<div key={index} style={{margin: "10px", height:"110px", textAlign:"center", opacity: highlight ? 1 : 0.6}} onClick={() => selectCharacter(character)}>
										<img src={character.profileImage || "https://storage.googleapis.com/runequest/bestiary/default.png"} style={{height:"80px", borderRadius:"5px", border:otherBorder}} />
										<div style={{width:"80px", textAlign:"center"}}>{character.name.first + " " + character.name.last}</div>
									</div>
								)
							})}
							<div key="other" style={{margin: "10px", height:"110px", textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center"}} onClick={selectOther}>
								<div style={{width:"60px", height: "80px", borderRadius:"5px", border: selectedCharacter === null ? "5px solid #2f588a" : "3px solid #454545", backgroundColor: selectedCharacter === null ? "#fff" : "#c0c0c0", display:"flex", alignItems:"center"}} >
									<img src={ManRune} style={{width: "60px" }}/>
								</div>
								<div style={{textAlign:"center", marginTop: "5px"}}>Other</div>
							</div>
						</div>
					</div>
				</div>
				<div className={globalStyles.panel} style={{overflow: "auto", height: "212px"}}>
					{items && items.length > 0 ? 
						items.map((item, index) => {
							if (index === selectedItem) {
								return (
									<div onClick={() => selectItem(-1)} className={styles.selectedItem} key={index} style={{padding: "5px", height: "24px"}}>
										<div>{item.title || item.name}</div>
									</div>
								)
							}
							else {
								return (
									<div onClick={() => selectItem(index)} className={styles.selectItem} key={index} style={{padding: "5px", height:"24px"}}>
										<div style={{cursor: "pointer"}}>{item.title || item.name}</div>
									</div>
								)
							}
					})
					:
					selectedCharacter === null ? 
					null
					:
					<div style={{marginTop: "10px"}}>This character has no valid weapons to receive this spell</div>
					}
				</div>
			</div>
			</div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "10px", alignItems:"flex-end"}}>
					<Button type="submit" disabled={disabled} onClick={handleOkClick} style={{width: "100px", color:"#fff"}} color="primary" variant="contained">Ok</Button>
					<Button type="submit" onClick={handleCancelClick} style={{width: "100px", marginRight: "20px", backgroundColor: "#bbbbbb"}} color="primary" variant="outlined">Cancel</Button>
					{props.providerData.type === "character" ? 
						<FormControl variant="outlined" 	>
							<FormControlLabel control={<Checkbox checked={remember} onChange={handleRememberChange} />} label="Remember this selection"/>
						</FormControl>
					: null}

				</div>
			</div>
		);
	}
	else {
		return null;
	}
}

export default withContext(WeaponPickerDialog);