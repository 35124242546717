import React, { useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DialogBox from '../../Dialogs/dialogBox';

const AddItemButton = (props) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <div onClick={() => setOpen(true)} style={{...props.style, cursor: "pointer", display: "flex", alignItems: "center"}} title={props.addTitle}>
                {props.edit ? 
                    <ModeEditIcon style={{height:"24px", width:"24px", color: "#fff"}} />
                : 
                    <AddCircleOutlineIcon  style={{height:"24px", width:"24px", color: props.iconColour || "#fff"}}/>
                }
            </div>
            <DialogBox isOpen={open} width={props.width ? props.width : "600px"} title={props.title}>
                {React.cloneElement(props.children, {...props, addItem:props.addItem, close: () => setOpen(false)})}
            </DialogBox>
        </>
    )
}

export default AddItemButton