import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { withContext} from '../Context/context';
import { Button } from '@mui/material';
import SpiritSpellPanel from '../Magic/spiritSpellPanel';
import RuneSpellPanel from '../Magic/runeSpellPanel';
import * as SAVE from '../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import EditBoxMulti from '../Controls/editBoxMulti';
import EditBoxSingle from '../Controls/editBoxSingle';
import Selector from '../Controls/selector';
import DeleteButton from '../Controls/deleteButton';
import { getAllRuneSpells, getAllSpiritSpells } from '../../services/dataService';

const types = [{name: "Spirit spell matrix"}, {name: "Rune spell matrix"}];

const MatrixDialog = (props) => {

    const [matrix, setMatrix] = useState({
        type: props.type || "",
        location: ""
    });
    const [disabled, setDisabled] = useState(true);
    const [spiritSpell, setSpiritSpell] = useState(null);
    const [runeSpell, setRuneSpell] = useState(null);

    useEffect(() => {
        var updatedMatrix = {...matrix}
        if (matrix.type === "Rune spell matrix") {
            updatedMatrix.spell = {...runeSpell}
        }
        else {
            updatedMatrix.spell = {...spiritSpell};
        }       
        setMatrix(updatedMatrix);
    },[runeSpell, spiritSpell])

    useEffect(() => {
        if (props.matrix) {
            setMatrix({...props.matrix});
            if (matrix.type === "Rune spell matrix") {
                setRuneSpell(props.matrix.spell)
            }
            else {
                setSpiritSpell(props.matrix.spell)
            }
            updateDisabled(props.matrix)
        }
    },[props.matrix])

    function handleOkClick() {
        if (props.matrix) {
            editMatrix();
        }
        else {
            var matrixId = matrix && matrix.id ? matrix.id : uuidv4(); 
            var newMatrix = {...matrix, id: matrixId}
            if (matrix.type === "Spirit spell matrix") {
                newMatrix.spell = spiritSpell
            }
            else {
                newMatrix.spell = runeSpell;
            }
            props.addItem(newMatrix)
            props.close();
        }
    }

    function update(field, value) {
        var updatedMatrix = {...matrix};
        updatedMatrix[field] = value;
        setMatrix(updatedMatrix);
        updateDisabled(updatedMatrix)
    }

    function updateDisabled(updatedMatrix) {
        var disabled = false; 
        if (updatedMatrix.type === "" || updatedMatrix.type === "Select") {
            disabled = true;
        }
        if (!updatedMatrix.location) disabled=true;
        setDisabled(disabled);
    }

    function handleCancelClick(){
        props.close();
    }
     
	function editMatrix(){
        props.update({...matrix}, SAVE.NO);
		props.close();
	}

    function deleteMatrix(matrix) {
        props.delete(matrix);
        props.close();
    }
    
	const displaySpiritSpells = useMemo(
		() => {
			return (
                <SpiritSpellPanel campaign={props.providerData.campaign} availablePoints={999} availableSpells={getAllSpiritSpells(props.providerData.campaign)} matrix currentSpells={spiritSpell ? [spiritSpell] : []} setSpells={()=> {}} select={(spells) => setSpiritSpell(spells[0])} />
			);
		},
		[spiritSpell]
	);
    
	const displayRuneSpells = useMemo(
		() => {
			return (
                <RuneSpellPanel campaign={props.providerData.campaign} availableSpells={getAllRuneSpells(props.providerData.campaign)} characterRunes={props.providerData.character.runes} matrix currentSpells={runeSpell ? [runeSpell] : []} setSpells={()=> {}} storage={999} select={(spells) => setRuneSpell(spells[0])} />
			);
		},
		[runeSpell]
	);

      return (
        <div style={{minHeight: "800px", overflow: "none", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <div>
                <div style={{display:"flex", padding: "10px"}}>
                    {props.type ? 
                        null
                    : 
                        <div style={{display:"flex", alignItems: "center", marginRight: "20px"}}>
                            <div style={{marginRight: "10px"}}>Matrix type: </div>
                            <Selector updateSelected={(value) => {update("type", value)}} values={types} style={{width: "250px"}} />
                        </div>
                    }
                    <div style={{marginLeft:"10px", display:"flex", alignItems:"center"}}>
                        <EditBoxSingle width="500px" label="Location:" value={matrix.location} autoFocus onChange={(value) => {update("location", value)}} />
                    </div>
                </div>
                <div style={{marginLeft: "10px", width: "670px"}}>
                    <div style={{margin: "10px 0px"}}>Notes:&nbsp;</div>
                    <EditBoxMulti multiline rows={4} value={matrix.notes} onChange={(value) => {update("notes", value)}} />
                </div>
                    {matrix.type === "Spirit spell matrix" ? 
                        displaySpiritSpells
                    :
                    matrix.type === "Rune spell matrix" ? 
                        displayRuneSpells
                    :null
                } 
            </div>
            <div style={{display:"flex", marginTop:"30px", padding: "20px", alignItems:"flex-end", flexDirection: "row-reverse", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        <Button type="submit" disabled={disabled} onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                        <Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
                    </div>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        {props.matrix ? 
                            <DeleteButton delete={deleteMatrix} type="matrix" item={props.matrix} title="Delete Matrix" />
                        : 
                        null
                        }
                    </div>
                </div>
        </div>
    );
    
}
export default withContext(MatrixDialog);