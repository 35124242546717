import React, { useState } from 'react';
import { Button } from '@mui/material';
import { withFirebase } from '../Firebase';
import DialogBox from '../Dialogs/dialogBox';
import SignUpDialog from './signUpDialog';

const SignUpLink = (props) => {

  const [dialog, setDialog] = useState(false);
  const [message, setMessage] = useState();

  function openDialog() {
    setDialog(true);
  }

  function handleDialogClose(result) {
    setDialog(false);
    setMessage("A verification email has been sent to " + result);
  }

  function cancel() {
    setDialog(false);
  }

    return (
      <div style={{width: "350px"}}>
        <div style={{display:"flex", flexDirection: "row-reverse", justifyContent:"space-between", margin:"20px 0px", width: "100%"}}>
        <Button style={{width:"140px", height: '40px'}} onClick={openDialog} variant="contained">Sign up </Button>
        <div style={{display:"flex", alignItems:"center", marginRight: "20px"}}>Don't have an account?</div>
        </div>
      <DialogBox isOpen={dialog} handleClose={handleDialogClose} subtitle='' title='Create Account' width="600px">
        <SignUpDialog firebase={props.firebase} closeDialog={cancel}></SignUpDialog>
      </DialogBox>

      {message ? 
        <div>{message}</div>
        :null
      }
    </div>
      );

}

export default withFirebase(SignUpLink);
