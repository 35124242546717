import React, {useState, useEffect} from 'react';
import globalStyles from '../../Styles/styles.module.css'
import { withContext } from '../../Context/context';
import { withPageState } from '../../Context/PageState';
import DiceButton from '../../Buttons/diceButton';
import { FormControlLabel, Checkbox } from '@mui/material';
import * as SAVE from '../../../constants/save';
import EditBox from '../../Controls/editBoxSingle';

const classNameGenerator = (...classes)=>{
        return classes.join(" ");
    }

export const RuneSpellRow = (props) => {

    const [active, setActive] = useState(false);

    useEffect(() => {
        if (props.matrix) {
            setActive(props.matrix.active || false);
        }
    },[props.matrix])

    var name = props.item.name + " (" + props.item.points;
    var suffix = props.item.points == 1 ? "pt)":  "pts)";
    name += suffix
    var className = globalStyles.diceButton;
    if (props.disabled) {
        className = classNameGenerator(globalStyles.diceButton, globalStyles.disabledButton)
    }
   
    function displayItem() {
        props.displayItem({type: "Rune Spell", item: props.item, matrix: props.matrix})
    }

    function makeActive(checked) {
        setActive(!active);
        var updatedItem = {...props.item};
        updatedItem.active = checked;
        props.update(updatedItem, SAVE.NO)
    }
    
    function handleValueChange(value) {
        var updatedItem = {...props.item};
        updatedItem.points = value;
        updatedItem.active = active;
        props.update(updatedItem, SAVE.NO);
    }

    if (props.pageState.editMode) {
        return (
            <div style={{display:"flex", alignItems: "center", padding: "2px", width: "375px"}} >
                <div style={{width: "190px"}}>{props.item.name} {props.item.variant ? props.item.variant : ""}</div>
                {props.matrix ? 
                    <div style={{display:"flex", alignItems: "center", justifyContent: "center", width: "60px"}}>
                        <FormControlLabel style={{paddingLeft: "30px"}}
                            control={<Checkbox style={{padding: "2px"}} checked={active} color="primary" onChange={(event) => makeActive(event.target.checked)} />}
                        />
                    </div>
                : 
                    <div style={{width: "80px", textAlign: "center"}}>{props.item.stackable ? "Yes": "No"}</div>
                }
                {props.matrix && props.item.stackable ? 
                    <div style={{display:"flex", alignItems: "center", fontWeight:700, marginLeft: "10px"}}>
                        <EditBox size="sm" number value={props.item.points} width="30px" onChange={handleValueChange} />
                    </div>
                : 
                    <div style={{width: "70px", textAlign: "center"}}>{props.item.points}</div>
                }

            </div>
        )
    }
    else {
        return (
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", padding: "2px"}} onClick={displayItem} >
                <div style={{width: "190px"}}>{props.item.name} {props.item.variant ? props.item.variant : ""}</div>
                <div style={{width: "30px", textAlign: "center"}}>{props.item.points}</div>
                {props.matrix ? 
                    <div style={{width: "70px", textAlign: "center"}}>{active ? "Yes": "No"}</div>
                    : 
                    <div style={{width: "70px", textAlign: "center"}}>{props.item.stackable ? "Yes": "No"}</div>
                }
                <div style={{width: "30px", textAlign: "center"}}>1</div>
                <div style={{width: "40px", textAlign: "right", cursor: "pointer", marginLeft: "8px"}}>
                    <DiceButton type="Rune Spell" style={{borderRadius: "3px", border: "1px solid #2f588a", padding: "1px", width: "40px", textAlign: "right", cursor: "pointer"}} diceRoller={props.diceRoller} item={props.item} processResult={props.processResult} disabled={props.disabled} entity={props.entity} >
                        <ButtonValue />
                    </DiceButton>
                </div>
            </div>
    
        )
    }

        function ButtonValue(props) {
            return (
                <div >{(props.target || 0) + "%"}</div>
            )
        }
    }

export default withPageState(withContext(RuneSpellRow));