import React from "react";
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import globalStyles from '../../Styles/styles.module.css';
import AddItemButton from "../buttons/addItemButton";
import AddPassionDialog from "../dialogs/addPassionDialog";
import { Checkbox } from "@mui/material";
import HideButton from "../controls/hideButton";
import EditBox from "../../Controls/editBoxSingle";
import * as SAVE from '../../../constants/save'
import compareValues from '../../../utils/compareValues';

const PassionsPanel = (props) => {
    
    const addPassion = (newPassion) => {
        var currentPassions = [...props.providerData.character.passions]
        var index = currentPassions.findIndex(obj => obj.parentId === newPassion.parentId && (!newPassion.variant || (obj.variant && obj.variant.toLowerCase() === newPassion.variant.toLowerCase())))
        if (index < 0) {
            currentPassions.push(newPassion);
            props.update([{fieldName: "passions", value: currentPassions}], SAVE.NO)
        }
    }

    function displayPassion(item, index) {
        if (item.hidden && ! props.pageState.editMode) return null;
        return(
            <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                {props.pageState.editMode ? 
                    <HideButton item={item} type="passions" name={item.name + (item.variant ? " (" + item.variant + ")": "")} update={(hidden) => props.hideItem("passions", item, hidden)} />
                : 
                    <Checkbox style={{cursor: "default", width: "18px", padding: "1px"}} checked={false} />
                }
                <div style={{width: "280px", marginLeft: "5px"}}>{item.name}{item.variant ? " (" + item.variant + ")" : ""}{(item.hidden ? " (hidden)" : "")}</div>
                <div style={{width:"50px", textAlign: "center"}}></div>
                <div style={{width:"30px", textAlign: "center"}}></div>
                <div style={{width:"30px", textAlign: "center"}}></div>
                <div style={{width:"30px", textAlign: "center"}}></div>
                {props.pageState.editMode ? 
                    <div style={{width: "50px"}}><EditBox number style={props.buttonStyle} value={item.value} onChange={(value) => props.handleAbilityChange(value, item, "passions")}  /> </div>
                : 
                    <div style={{width: "50px"}}>{item.value}%</div>
                }
            </div>
        );
    }

    return (
        <div id="passions">
        <div style={{border: "2px solid #676767", margin: "20px 0px"}}>
        <div style={{background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", justifyContent: "space-between", display:"flex", alignItems:"center"}}>
                <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Passions</div>
                <div style={{width: "30px"}}>
                    {props.pageState.editMode ?
                        <AddItemButton addItem={addPassion} title="Add passion">
                            <AddPassionDialog />
                        </AddItemButton>
                    : 
                    null}
                </div>
            </div>
            { 
                props.providerData.character.passions && props.providerData.character.passions.sort(compareValues('name')).map((item, index) => {
                    return(
                        displayPassion(item, index)
                    )
                })
            }
        </div>
    </div>

    )
}

export default withPageState(withContext(PassionsPanel));