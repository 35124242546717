import React, {useState} from "react";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../constants/routes';
import facebookLogo from '../../images/facebook-logo.png'

const ERROR_CODE_ACCOUNT_EXISTS ='auth/account-exists-with-different-credential';
const ERROR_MSG_ACCOUNT_EXISTS = `An account with an E-Mail address to this social account already exists. Try to login from this account instead and associate your social accounts on your personal account page. `;

const SignInFacebookBase = (props) => {

    const [error, setError] = useState(null);
    let navigate = useNavigate();

    const onSubmit = event => {
        props.firebase
        .doSignInWithFacebook()
        .then(socialAuthUser => {
            return props.firebase.user(socialAuthUser.user.uid).set({
                firstName: socialAuthUser.additionalUserInfo.profile.name,
                email: socialAuthUser.additionalUserInfo.profile.email,
                roles: { MEMBER: "MEMBER"},
            });
        })
        .then(() => {
            setError(null)
            navigate(ROUTES.HOME);
        })
        .catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }
            setError(error)
        });
        event.preventDefault();
    };

    return (
        <div style={{margin: "0 auto", margin: "5px 20px"}}>
            <form onSubmit={onSubmit}>
                <div style={{display:"flex", flexDirection: "row-reverse", justifyContent:"space-between"}}>
                    <Button type="submit" color="primary" variant="outlined" style={{height: "50px", width:"360px", backgroundColor: "#fff", border: "2px solid #2f588a", borderRadius: "5px"}}><div style={{width: "300px", display:"flex", alignItems: "center"}}><img style={{width:"34px", marginRight: "20px"}} src={facebookLogo}></img>Continue with Facebook</div></Button>
                </div>
                {error && <p>{error.message}</p>}
            </form>
        </div>
    );
}

export default SignInFacebookBase;