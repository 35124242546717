import React, { useState, useEffect } from 'react';
import SelectHomelandDialog from '../dialogs/selectHomelandDialog';
import SelectOccupationDialog from '../dialogs/selectOccupationDialog';
import DialogBox from '../../Dialogs/dialogBox';
import SmallBuilderCard from './smallBuilderCard';
import SmallHomelandCard from './smallHomelandCard';

const GrandParent = (props) => {
    const [openHomeland, setOpenHomeland] = useState(false);
    const [openOccupation, setOpenOccupation] = useState(false);
    const [occupations, setOccupations] = useState([]);

    useEffect(() => {
        setOccupations([...props.occupations])
    },[props.occupations]);

    function selectParent(selected){
        var updatedParent = {...props.parent};
        updatedParent.relative = selected;
        updatedParent.title = selected === "mr" ? "mother" : "father"; 
        props.update(updatedParent);
    };

    function changeHomeland() {
        setOpenHomeland(true);
    }

    function changeOccupation() {
        setOpenOccupation(true);
    }

    function selectHomeland(selectedHomeland, selectedTribe) {
        var updatedParent = {...props.parent};
        updatedParent.homeland = selectedHomeland
        updatedParent.tribe = selectedTribe
        props.update(updatedParent);
    }

    function selectOccupation(selectedOccupation) {
        var updatedParent = {...props.parent};
        updatedParent.occupation = selectedOccupation;
        props.update(updatedParent);
    }

    if (props.parent) {
        return (
            <>
                <div style={{textAlign:"center", borderRadius: "10px", border: "5px solid #2F588A", width: "540px", padding: "10px 0px 10px 16px", marginBottom: "20px"}}>
                    <div style={{marginBottom: "10px", fontSize: "20px"}}>Select parent</div>
                    <div style={{display:"flex", margin: "10px"}}>
                            <SmallBuilderCard selected={props.parent.relative === "fr"} select={() => selectParent("fr")} title="Father" imageSrc="https://storage.googleapis.com/runequest/builder/father.png" obscure={props.parent.relative && props.parent.relative != "fr"}/>
                        <div style={{marginLeft: "20px"}}>
                            <SmallBuilderCard selected={props.parent.relative === "mr"} select={() => selectParent("mr")} title="Mother" imageSrc="https://storage.googleapis.com/runequest/builder/mother.png" obscure={props.parent.relative && props.parent.relative != "mr"} />
                        </div>
                    </div>
                    <div style={{display:"flex", margin: "10px"}}>
                            <SmallHomelandCard selected select={() => changeHomeland("props.parent")} ancestor="props.parent" title="Homeland:" homeland={props.parent.homeland} tribe={props.parent.tribe} imageSrc={props.parent.homeland.imageLink} changeText />
                        <div style={{marginLeft: "20px"}}>
                            <SmallBuilderCard selected select={() => changeOccupation("props.parent")} occupation title="Occupation:" name={props.parent.occupation.name} imageSrc={props.parent.occupation.imageLink} changeText />
                        </div>
                    </div>
                </div>
                <DialogBox isOpen={openHomeland} size="sm" title="Select Homeland">
                    <SelectHomelandDialog homelands={props.homelands} close={() => setOpenHomeland(false)} handleSelect={selectHomeland} homeland={props.parent.homeland} tribe={props.parent.tribe} />
                </DialogBox>
                <DialogBox isOpen={openOccupation} size="md" title="Select Occupation">
                    <SelectOccupationDialog occupations={occupations} close={() => setOpenOccupation(false)} handleSelect={selectOccupation} occupation={props.parent.occupation} relative="parent" />
                </DialogBox>
            </>
        );
    }
    else {
        return null;
    }
}

export default GrandParent;