import React, {useState, useEffect, useRef} from "react";
import Incrementor from "../Controls/incrementor";
import * as SAVE from '../../constants/save';
import styles from './encounters.module.css'

const HitPoints = (props) => {

    const [open, setOpen] = useState(false);
    const [hitPoints, setHitPoints] = useState(props.entity.hitPoints);
    const pointsBox = useRef(null)

    useEffect(() => {
        setHitPoints(props.entity.hitPoints);
    },[props.entity.hitPoints]);

    const increment = (value) => {
        var updatedHitPoints = {...hitPoints};
        if (updatedHitPoints?.current + value > updatedHitPoints.total) return;
        updatedHitPoints.current += value;
        props.update([{fieldName: "hitPoints", value: updatedHitPoints}], SAVE.DELAYED)
    }

    return (
        <div style={{position: "relative"}}>
            <div className={styles.diceButton} style={{display:"flex", backgroundColor: open ? "#bcd0e8" : ""}} onClick={() => {setOpen(true)}}>
                <div style={{fontWeight: "bold"}}>HPs:&nbsp;</div> 
                <div style={{marginLeft: "5px"}}>{hitPoints?.current}/{hitPoints?.total}</div>
            </div>
            <form ref={pointsBox} onMouseOver={() => pointsBox.current.focus()} onBlur={() => {setOpen(false)}} tabIndex={0}>
                {open ? 
                    <div style={{backgroundColor: "#fff", padding: "10px", border: "1px solid #2f588a", borderRadius: "5px", position: "absolute"}}>
                        <Incrementor label="Hit points: " currentValue={hitPoints?.current} totalValue={hitPoints?.total} increment={increment} />
                    </div>
                : 
                null
                }
            </form>
        </div>
    )
}

export default HitPoints;