import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { Button } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-red.svg'
import MultiSelect from '../Controls/multiSelect';
import { getAllWeapons, getWeaponByName } from '../../services/dataService';
import EditBox from '../Controls/editBoxSingle';

const EditWeaponsDialog = (props) => {
    
    const [weapons, setWeapons] = useState([]);
    const [weaponItems, setWeaponItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [bonus, setBonus] = useState(10)

    useEffect(() => {
        let existing = [];
        props.selected.forEach(item => {
            let weapon = item.options[0];
            let existingWeapon = {name: weapon.name, parentId: weapon.parentId}
            if (weapon.variant) {
                existingWeapon.variant = weapon.variant;
                existingWeapon.variable = true;
            };
            existingWeapon.bonus = item.bonus || 0;
            existing.push(existingWeapon);
        });
        setSelected([...props.selected]);
    }, [props.selected])
    
    const handleOkClick = () => {
        props.update(selected)
        props.close();
}

	function getNames(items) {
		let names = [];
        if (items && items.length > 0) {
            items.forEach(item => {
                names.push(item.name + (item.variant ? " (" + item.variant + ")" : ""));
            })
        }
		return names;
	}

    function addBonus() {
        let updatedSelected = [...selected];
        let startingWeapon = {bonus: bonus, options: []};
        weaponItems.forEach(item => {
            startingWeapon.options.push(item)
        })
        if (weapons.length > 1) {
            startingWeapon.selected = {};
        }
        updatedSelected.push(startingWeapon);
        setSelected(updatedSelected);
        setWeapons([]);
        setWeaponItems([]);
    }

    function deleteWeapon(index) {
        var updatedSelected = [...selected]
        updatedSelected.splice(index, 1);
        setSelected(updatedSelected)
    }

    function getWeaponNames(weapons) {
        if (weapons && weapons.options) {
            if (weapons.options.length === 1) {
                return getNames(weapons.options);
            }
            else {
                return "Select one of (" + getNames(weapons.options).join(', ') + ")"
            }
        }
    }

    function updateWeapons(values) {
        setWeapons(values);
        let weaponItems = [];
        values.forEach(weaponName => {
            let selectedWeapon = getWeaponByName(weaponName, props.customContent)
            if (selectedWeapon) {
                let weapon = {name: weaponName, parentId: selectedWeapon.id}
                weaponItems.push(weapon)
            }
        });
        setWeaponItems(weaponItems);
    }

    return (
        <div style={{height: "560px"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                <div style={{padding: "10px"}}>
                    <div>
                        <div className={globalStyles.contentLine} style={{justifyContent: "space-between"}}>
                            <div className={globalStyles.contentLine} style={{justifyContent: "space-between"}}>
                                <div style={{width: "120px"}}>Select weapon(s):</div>
                                <MultiSelect style={{width: "360px"}} values={getNames(getAllWeapons(props.customContent).filter(function(weapon) {return !weapon.restricted}))} selectedValues={weapons} onChange={updateWeapons} />
                            </div>
                            <div className={globalStyles.contentLine}>
                                <div style={{margin: "0px 20px 0px 40px"}}>Bonus:</div>
                                <EditBox height="24px" width="60px" style={{height: "24px"}} number value={bonus} onChange={setBonus} />
                            </div>
                            {weaponItems ? 
                                <div style={{marginLeft: "10px"}}><Button disabled={weapons.length === 0} style={{width: "74px"}} onClick={addBonus} variant='contained' color='primary'>Add</Button></div>
                            : 
                            null
                            }
                        </div>
                    </div>
                    <hr style={{margin: "10px 0px"}} />
                    <div>
                        <div style={{fontWeight: "bold"}}>Cult Starting Weapons</div>
                        <div className={globalStyles.contentLine} style={{marginTop: "20px", fontWeight: "bold"}}>
                            <div style={{width: "600px"}}>Weapon options</div>
                            <div style={{width: "100px", textAlign: "center"}}>Bonus</div>
                        </div>
                        {
                            selected.map((weaponOptions, index) => {
                                return (
                                    <div className={globalStyles.contentLine}>
                                        <div style={{width: "600px"}}>{getWeaponNames(weaponOptions)}</div>
                                        <div style={{width: "100px", textAlign: "center"}}>{weaponOptions.bonus}</div>
                                        <div style={{marginRight: "20px", cursor: "pointer"}} onClick={() => deleteWeapon(index)}><img src={deleteIcon} style={{height: "20px"}} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end", borderTop: "2px solid #2f588a"}}>
                    <Button onClick={handleOkClick} style={{minWidth: "100px"}} color="primary" variant="contained">OK</Button>
                    <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "40px"}} >Cancel</Button>
				</div>
            </div>
			{/* <div style={{borderTop: "2px solid #2f588a"}}>
            <DialogButtons handleOkClick={() => handleOkClick()} handleCancelClick={props.close} title={step==="Select" ? "Next" : "OK"} />
			</div> */}
        </div>
    )
}

export default EditWeaponsDialog;