import React, { useState} from 'react';
import globalStyles from '../Styles/styles.module.css'
import NotesIcon from '../../icons/notes-icon.svg';
import DialogBox from '../Dialogs/dialogBox';
import NotesDialog from '../Dialogs/notesDialog';

export default function NotesButton(props) {
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(true);
    };
   
    if (props.campaignId) {
        return (
            <>
                <div onClick={handleClick} className={globalStyles.floatingButton}>
                    <img style={{width:"50px"}} src={NotesIcon} title="Add notes" />
                </div>
                <DialogBox isOpen={open} size="md" title="Player Notes">
                    <NotesDialog update={props.update} displayMessage={props.displayMessage} campaignId={props.campaignId} user={props.user} close={() => setOpen(false)} />
                </DialogBox>
            </>
        );
    }
    else {
        return null;
    }
}