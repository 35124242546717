import React, { useEffect, useState } from "react";
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from "../Context/PageState";
import { v4 as uuidv4 } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import deleteIcon from '../../icons/delete-icon-red.svg'
import DeleteDialog from '../Dialogs/deleteDialog';
import getNames from "../../utils/getNames";

const CustomCults = (props) => {

    const [cults, setCults] = useState([]);
    const [deletedCult, setDeletedCult] = useState();
    const [confirm, setConfirm] = useState(false);

    const defaultNewCult={name: "New Cult", description: "Description", associated: [], commonRuneSpells: [], freeSkills: [], occupations: [], pantheons: [], passions: [], weapons: [], prohibited: [], restricted: false, runeSpells: [], runes: [], skills: [], spiritSpells: [], subtitle: ""}

    useEffect(() => {
        if (props.customContent && props.customContent.cults) {
            setCults([...props.customContent.cults]);
        }
    },[props.customContent]);

    const displayItem = (cult) => {
        props.displayItem({type: "CustomCult", item: cult})
    }

    const createCult = () => {
        let cult = {...defaultNewCult};
        cult.id = uuidv4();
        cult.parentId = cult.id;
        displayItem(cult);
    }

    function deleteItem(event, cult) {
        event.stopPropagation();
        setConfirm(true);
        setDeletedCult(cult);
    }

    function confirmDelete() {
        props.delete(deletedCult);
        setConfirm(false);
    }

    return (
        <div style={{border: "2px solid #2f588a", borderRadius: "5px", width: "100%", maxHeight: "270px"}}>
            <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", height: "28px", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                Custom Cults
                <div style={{width: "30px"}}>
                    {props.pageState.editMode ? 
                        <AddCircleOutlineIcon onClick={createCult} style={{height:"24px", width:"24px", color: "#fff"}}/>
                        :
                    null
                    }
                </div>
            </div>
            <div style={{display: "flex", alignItem: "center", padding: "10px", fontWeight: "bold"}}>
                <div style={{width: "380px"}}>Cult</div>
                <div style={{width: "130px"}}>Pantheon</div>
                <div style={{width: "200px"}}>Associated Cults</div>
                <div style={{width: "80px", textAlign: "center"}}>Playable?</div>
                <div style={{width: "80px", textAlign: "center"}}>Cult Skills</div>
                <div style={{width: "130px", textAlign: "center"}}>Cult Spirit Spells</div>
                <div style={{width: "128px", textAlign: "center"}}>Cult Rune Spells</div>
            </div>
            <div className={globalStyles.panel} style={{maxHeight: "192px", overflow: "auto"}}>
                {cults.map((cult, index) => {
                    var associated = ""
                    cult.associated.forEach((assocCult, assocIndex) => {
                        associated = associated + assocCult.name + (assocIndex < cult.associated.length -1 ? ", ": "");
                    })
                    return (
                        <div key={index} className={globalStyles.row} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{display: "flex", alignItems: "center", height: "20px", padding: "6px 10px", cursor: "pointer"}} onClick={() => displayItem(cult)}>
                                <div style={{width: "380px"}}>{cult.name + (cult.subtitle ? " (" + cult.subtitle + ")" : "")}</div>
                                <div style={{width: "130px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{cult.pantheons.join()}</div>
                                <div style={{width: "200px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{associated}</div>
                                <div style={{width: "80px", textAlign: "center"}}>{cult.restricted ? "No": "Yes"}</div>
                                <div style={{width: "80px", textAlign: "center"}}>{cult.skills.length}</div>
                                <div style={{width: "130px", textAlign: "center"}}>{cult.spiritSpells.length}</div>
                                <div style={{width: "128px", textAlign: "center"}}>{cult.runeSpells.length}</div>
                            </div>
                            {props.pageState.editMode ? 
                                    <div style={{cursor: "pointer", marginRight: "18px"}} onClick={(event) => deleteItem(event, cult)}>
                                        <img src={deleteIcon} style={{height: "20px"}} />
                                    </div>
                                : 
                                null}
                        </div>
                    )
                })}
            </div>
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="cult" />
        </div>
    )
}

export default withPageState(CustomCults)