import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from '../Context/PageState';
import * as SAVE from '../../constants/save';
import { calculateSpellStorage } from '../../services/magicPointsService';
import SpiritMagicEntity from '../Magic/spiritMagicEntity';
import MagicPointIncrementor from '../Controls/magicPointIncrementor';
import RuneSpells from '../Magic/runeSpells';
import RuneSpellRow from '../Characters/tiles/runeSpellRow';
import { withContext } from '../Context/context';
import CultHeaderBar from '../Characters/character/cultHeaderBar';
import SpiritDialog from './spiritDialog';
import DialogBox from '../Dialogs/dialogBox';
import EditIcon from '../../icons/edit-icon-white.svg'
import { getAvailableMagicPoints } from '../../services/magicPointsService';

const BoundSpirit = (props) => {

    const [storage, setStorage] = useState(-1);
    const [cult, setCult] = useState(null);
    const [totalRunePoints, setTotalRunePoints] = useState(null);
    const [spirit, setSpirit] = useState(props.spirit);
    const [open, setOpen] = useState(false);
    const [availableMagicPoints, setAvailableMagicPoints] = useState(0);


    useEffect(() => {
        var spirit = {...props.spirit}
        if (! spirit.strikeRanks) {
            spirit.strikeRanks = {...props.providerData.character.strikeRanks};
        }
        setSpirit(spirit);
    },[props.spirit, props.providerData.character]);

    useEffect(() => {
        var points = getAvailableMagicPoints(props.providerData.character, spirit);
        setAvailableMagicPoints(points.current);
    },[props.providerData.character, spirit]);

    useEffect(() => {
        setStorage(calculateSpellStorage(props.spirit));
    },[props.spirit]);

    useEffect(() => {
        if (props.spirit.cult) {
            var index = props.providerData.character.cults.findIndex(item => item.name === props.spirit.cult.name);
            setCult({...props.providerData.character.cults[index]});
            var points = 0;
            props.providerData.character.cults[index].runePoints.forEach(character => {
                points += character.current;
            })
            setTotalRunePoints(points);
        }
    },[props.spirit, props.providerData.character.cults]);

    function setEntitySpells(newSpells) {
        var index = props.providerData.character.boundSpirits.findIndex(item => item.id === props.spirit.id);
        if (index > -1) {
            var updatedEntities = [...props.providerData.character.boundSpirits]
            var updatedEntity = updatedEntities[index];
            updatedEntity.spiritSpells = newSpells;
            props.update([{fieldName: "boundSpirits", value: updatedEntities}], SAVE.NO);
        }
    }

   function updateCult(updatedCult) {
        var updatedCults = [...props.providerData.character.cults];
        var index = updatedCults.findIndex(item => item.name === cult.name);
        updatedCults[index] = updatedCult;
        props.update([{fieldName: "cults", value: updatedCults}], SAVE.DELAYED);
   }

    function updateEntity(updatedEntity) {
        var index = props.providerData.character.boundSpirits.findIndex(item => item.id === props.spirit.id);
        if (index > -1) {
            var updatedEntities = [...props.providerData.character.boundSpirits]
            updatedEntities[index] = updatedEntity;
            props.update([{fieldName: "boundSpirits", value: updatedEntities}], SAVE.DELAYED);
        }
    }

    function updateSpirit(updates, save) {
        var updatedEntities = [...props.providerData.character.boundSpirits]
        var index = updatedEntities.findIndex(obj => obj.id === props.spirit.id);
        if (index > -1) {
            updates.forEach(update => {
                updatedEntities[index][update.fieldName] = update.value;
            })
            props.update([{fieldName: "boundSpirits", value: updatedEntities}], save || SAVE.NO);
        }
    }

    function update(field, value, save) {
        var fields = field.split(".");
        var index = props.providerData.character.boundSpirits.findIndex(obj => obj.id === props.spirit.id);
        if (index > -1) {
            var updatedEntities = [...props.providerData.character.boundSpirits]
            if (fields.length > 1) {
                updatedEntities[index][fields[0]][fields[1]] = value;
            }
            else {
                updatedEntities[index][field] = value;
            }
            props.update([{fieldName: "boundSpirits", value: updatedEntities}], save || SAVE.NO);
        }
    }

    function deleteCult(cult) {

    }

    function displayItem() {
        if (!props.pageState.editMode) {
            props.displayItem({type: "Bound Spirit", item: spirit})
        }
    }

    function openPanel(event) {
        event.stopPropagation();
        setOpen(true);
    }

    if (spirit) {
        return (
            <>
                <div className={props.pageState.editMode ? null : globalStyles.expandableTile} style={{backgroundColor: "#8a612f", color: "#ffffff", fontSize: "15px", height: "30px", display:"flex", alignItems: "center", justifyContent: "space-between", position: "relative"}} onClick={displayItem}>
                    <div style={{display:"flex", justifyContent: "center", alignItems: "center", paddingLeft: "5px"}}>
                        <div style={{marginRight:"20px"}}>{spirit.name.first}</div>
                    </div>
                    {props.pageState.editMode ? 
                        <div style={{position: "absolute", right: "10px", top: "4px", cursor: "pointer"}} onClick={(event) => openPanel(event)}>
                            <img src={EditIcon} style={{width: "22px"}} alt="Edit matrix" title="Edit spirit spells" />
                        </div>
                    : 
                        <MagicPointIncrementor update={props.update} entityId={spirit.id} magicPoints={props.providerData.character.magicPoints} />
                    }
                </div>
                <div style={{}}>
                    <SpiritMagicEntity diceRoller={props.diceRoller} element={props.element} stylesheet={props.stylesheet} spellcaster={spirit} availableMagicPoints={availableMagicPoints} entity={spirit} displayItem={props.displayItem} setSpells={setEntitySpells} updateEntity={updateEntity} spells={props.spirit.spiritSpells} processResult={props.processResult} update={updateSpirit} open={() => {setOpen(true)}} powGain={props.powGain} />
                    {props.spirit.runeSpells && props.spirit.runeSpells.length > 0 ? 
                        <>
                            <CultHeaderBar cult={cult} entity={props.spirit} updateCult={updateCult} deleteCult={deleteCult} />
                            <div style={{padding: "5px"}} >
                                {props.pageState.editMode ? 
                                    <div style={{display:"flex", fontWeight: "bold", paddingBottom: "3px"}}>
                                        <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                                        <div style={{width: "80px", textAlign: "center"}}>Stackable</div>
                                        <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                                    </div>
                                : 
                                    <div style={{display:"flex", fontWeight: "bold", paddingBottom: "3px"}}>
                                        <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                                        <div style={{width: "30px", textAlign: "center"}}>Pts</div>
                                        <div style={{width: "70px", textAlign: "center"}}>Stackable</div>
                                        <div style={{width: "30px", textAlign: "center"}}>SR</div>
                                        <div style={{width: "40px", textAlign: "right", marginLeft: "8px"}}>Cast</div>
                                    </div>
                                }
                                <RuneSpells diceRoller={props.diceRoller} tile={<RuneSpellRow />}  entity={props.entity} totalPoints={totalRunePoints} knownSpells={props.spirit.runeSpells} cult={cult} displayItem={props.displayItem} spells={props.spirit.runeSpells} setSelectedSpells={(spells) => {update("runeSpells", spells)}} processResult={props.processResult} spellcaster={props.providerData.character} update={props.update} powGain={props.powGain} />
                            </div>
                        </> 
                    : 
                    null
                    }
                </div>
                <DialogBox isOpen={open} size="md" title={"Edit Spirit"}>
                    <SpiritDialog close={() => {setOpen(false)}} update={props.update} entity={props.providerData.character} spirit={spirit} />
                </DialogBox>
            </>
        )
}
    else {
        return null;
    }
}

export default withContext(withPageState(BoundSpirit));