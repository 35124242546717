import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import DropDown from '../../Controls/dropDown';
import { getCult } from '../../../services/dataService';
import runes from '../../Encounters/runes';

const SelectSubcultDialog = (props) => {

    const [subcult, setSubcult] = useState({});
    const [details, setDetails] = useState();
    
    useEffect(() => {
        if (props.currentSubcult) {
            setSubcult(props.currentSubcult)
        }
    },[props.currentSubcult]);
	  
	function handleOkClick(){
        props.handleSelect(subcult);
	}

    function selectSubcult(value) {
        setSubcult(value);
        let details = getCult(value.parentId);
        setDetails(details);
    }

    return (
        <div>
            <div style={{fontSize: "18px", margin: "14px"}}>Select from the following subcults:</div>
                <DropDown style={{width: "220px", marginLeft: "10px"}} values={props.subcults} onChange={selectSubcult} value={subcult.name}>{subcult.name}</DropDown>
                {details ?
                    <div style={{padding: "10px"}}>
                        <div dangerouslySetInnerHTML={{__html: details.description}} />
                        {details.runeSpells ? 
                            <div style={{marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                <div style={{fontWeight: "bold"}}>Special Rune Magic:</div>
                                {details.runeSpells.map((runeSpell, index) => {
                                    return (
                                        <div>&nbsp;{runeSpell.name + (runeSpell.variant ? " (" + runeSpell.variant + ")" : "") + (index === details.runeSpells.length -1 ? "" : ", ")}</div>
                                    )
                                })}
                            </div>
                        : null}
                        {details.spiritSpells ? 
                            <div style={{marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                <div style={{fontWeight: "bold"}}>Special Spirit Magic:</div>
                                {details.spiritSpells.map((spiritSpell, index) => {
                                    return (
                                        <div>&nbsp;{spiritSpell.name + (index === details.spiritSpells.length -1 ? "" : ", ")}</div>
                                    )
                                })}
                            </div>
                        : null}
                        {details.skills ? 
                            <div style={{marginTop: "10px", display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                                <div style={{fontWeight: "bold"}}>Special skills:</div>
                                {details.skills.map((skill, index) => {
                                    return (
                                        <div>&nbsp;{skill.options[0].name + (index === details.skills.length -1 ? "" : ", ")}</div>
                                    )
                                })}
                            </div>
                        : null}
                    </div>
                : 
                null}
                <div></div>
                <hr />
                <div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
                    <Button onClick={handleOkClick} disabled={!subcult} id="diceButton" color="primary" variant="contained" style={{width: "100px"}}>Ok</Button>
                    <Button onClick={() => {props.close()}} id="diceButton" color="primary" variant="outlined" style={{marginRight:"30px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
				</div>
        </div>
    );
}

export default SelectSubcultDialog;