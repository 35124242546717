import React from "react";
import globalStyles from '../Styles/styles.module.css';
import DiceButton from '../Buttons/diceButton';

const AbilityRow = (props) => {
    var skillName = props.item.variant ? props.item.name + " (" + props.item.variant + ")" : props.item.name; 
    return(
        <div className={globalStyles.expandableTile} style={{display:"flex", fontSize: "13px", alignItems: "center", cursor: "zoom-in"}} onClick={displayItem} >
            <div>{skillName + ":"}</div>
            <div className={globalStyles.diceButton} style={{padding: "2px", cursor: "pointer"}}>
                <DiceButton diceRoller={props.diceRoller} item={props.item} processResult={props.processResult} disabled={props.item && props.item.value == 0} entity={props.entity} >
                    <ButtonValue />
                </DiceButton>
            </div>
        </div>
    )

    function displayItem() {
        props.displayItem({type: props.type, item: props.item})
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%, "}</div>
        )
    }
}

export default AbilityRow;