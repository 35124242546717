import React, { useState, useEffect } from 'react';
import { withContext } from '../Context/context';
import globalStyles from '../Styles/styles.module.css';
import { FormControlLabel, Switch } from '@mui/material';
import SmallDiceButton from '../Buttons/smallDiceButton';
import ExperienceDiceButton from './experienceDiceButton';

const ExperienceRow = (props) => {
    const [state, setState] = React.useState(true);
    const [result, setResult] = React.useState("");
    const [succeeded, setSucceeded] = React.useState(true);
    const [gain, setGain] = useState(0);
    const [complete, setComplete] = useState(false);
    const [categoryModifier, setCategoryModifier] = useState(0);
    
    useEffect(() => {
        var category = props.item.skillCategory;
        setCategoryModifier(props.providerData.character.categoryModifiers[category]);
    },[props.item])

    const handleChange = (event) => {
        setState(event.target.checked );
      };

    const processResult = (result, item, increase) => {

        var abilityIncrease = increase;
        var messageResult = "";
        var target = calculateValue();
        result += categoryModifier || 0;
        if (result >= target){
            // increase = state ? Math.ceil(Math.random()*(6)) : 3;
            setGain(increase);

            setResult("You rolled a " + result + ", and have successfully gained " + increase + (increase > 1 ? " points":" point"));
            messageResult = "gained " + increase + (increase > 1 ? " points":" point") + " in " + item.name + (item.variant ? " (" + item.variant + ")": "");
            setSucceeded(true);
        }
        else {
            setResult("You rolled a " + result + ", and have failed to learn anything on this occasion");
            messageResult = "failed to learn from their experience in " + item.name + (item.variant ? " (" + item.variant + ")": "");
            setSucceeded(false);
            abilityIncrease = 0;
        }
        setComplete(true);
        props.processExperience(abilityIncrease, item, messageResult, succeeded);
    }

    function calculateValue() {
        var currentValue = parseInt(props.item.value);
        currentValue += categoryModifier || 0;
        if (currentValue > 100) { currentValue = 100;}
        currentValue += gain;
        return currentValue;
    }

    if (props.item && props.item.hasSucceeded || props.item && props.occupational) {
        return (
            <div>
                <div className={globalStyles.row} style={{display:"flex", fontSize: "14px", height: "32px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312"}}>
                    <div style={{display: "flex", width:"100%", paddingLeft: "5px"}}>
                        <div style={{minWidth:"120px", alignItems: "center", display: "flex"}}>
                            <div>{props.item.name} {props.item.variant ? "(" + props.item.variant + ")" : null}</div>
                        </div>
                        <div style={{width:"66px", display:"flex", alignItems: "center", justifyContent:"center"}}>{calculateValue()}</div>
                        <div style={{width:"86px", display:"flex", alignItems: "center", justifyContent:"flex-end"}}>
                            <FormControlLabel control={<Switch checked={state} color='primary' onChange={handleChange} name={props.item.name} />} />
                        </div>
                        <div style={{width:"80px", display:"flex", alignItems: "center", marginRight: "10px"}}>
                            <ExperienceDiceButton style={{borderRadius: "5px", height: "24px"}} diceRoller={props.diceRoller} processResult={processResult} pageState={props.pageState} disabled={complete || props.rolled} useD6={state} item={props.item}>
                                <SmallDiceButton />
                            </ExperienceDiceButton>
                        </div>
                        {/* {
                            succeeded ? 
                            <div style={{width:"425px", color: "#2F588A", display:"flex", alignItems: "center"}}>{result}</div>
                            : 
                            <div style={{width:"425px", color: "#851113"}}>{result}</div>
                        } */}
                    </div>
                </div>
            </div>

        )
    }
    else {
        return null;
    }
}

export default withContext(ExperienceRow)