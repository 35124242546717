import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import { withContext } from "../../Context/context";
import AddItemButton from "../buttons/addItemButton";
import CrystalDialog from "../../MagicItems/crystalDialog";
import * as SAVE from '../../../constants/save';
import DialogBox from "../../Dialogs/dialogBox";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const CrystalsPanel = (props) => {

    const [crystals, setCrystals] = useState([])
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(-1);

    useEffect(() => {
        setCrystals([...props.providerData.character.crystals]);
    },[props.providerData.character.crystals])

    function addCrystal(newCrystal) {
        var updatedCrystals = [...props.providerData.character.crystals];        
        var index = updatedCrystals.findIndex(item => item.id === newCrystal.id);
        if (index < 0) {
            updatedCrystals.push(newCrystal);
        }
        else {
            updatedCrystals[index] = newCrystal;
        }      
        var updates = [{fieldName: "crystals", value: updatedCrystals}];

        var updatedMagicPoints = [...props.providerData.character.magicPoints];
        updatedMagicPoints.push({
            entityId: newCrystal.id,
            type: "POW storage crystal",
            total: newCrystal.POW,
            current: newCrystal.POW,
        })
        updates.push({fieldName: "magicPoints", value: updatedMagicPoints})
        props.update(updates, SAVE.NO);
    }

    function openDialog(index) {
        setKey(index);
        setOpen(true);
    }

    return (
        <div style={{border: "2px solid #676767", margin: "5px", width: "260px"}}>
            <div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between "}}>
                <div style={{paddingLeft: "5px", fontWeight:"bold"}}>POW Storage Crystals</div>
                <div style={{width: "30px"}}>
                    {props.pageState.editMode ?
                        <AddItemButton title="Add POW Storage Crystal" addTitle="Add crystal" width="452px" addItem={addCrystal}>
                            <CrystalDialog close={() => {setOpen(false)}} update={props.update} />
                        </AddItemButton>
                    : 
                    null}
                </div>
            </div>
            {crystals.length > 0 ? 
            crystals.map((crystal, index) => {
                if (crystal.type === "POW storage crystal") {
                    return (
                        <div key={index}>
                            <div style={{backgroundColor: "#2F588A", padding: "0px 5px", color: "#fff", height: "24px", position: "relative"}}>
                                <div style={{display: "flex", alignItems: "center", height: "24px"}}>
                                    {props.pageState.editMode ? 
                                    <div style={{position: "absolute", right: "6px", top: "0px", cursor: "pointer"}} title="Edit crystal" onClick={() => openDialog(index)}>
                                        <ModeEditIcon style={{height:"22px", width:"22px", color: "#fff"}} />
                                    </div>
                                    : 
                                    null}
                                    <div>{crystal.location}</div>
                                </div>
                                <DialogBox title="Edit POW Storage Crystal" isOpen={open} width="452px" >
                                    <CrystalDialog type="POW storage crystal" close={() => setOpen(false)} update={props.update} crystal={crystals[key]} />
                                </DialogBox>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}>{"POW " + crystal.POW}</div>
                        </div>

                    )
                }
            })
            : 
            <div style={{padding: "10px"}}>None</div>}
        </div>
    )
}

export default withPageState(withContext(CrystalsPanel));