import React, { useEffect, useState } from "react";
import globalStyles from '../Styles/styles.module.css';
import { withPageState } from "../Context/PageState";
import { v4 as uuidv4 } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import deleteIcon from '../../icons/delete-icon-red.svg'
import DeleteDialog from '../Dialogs/deleteDialog';

const CustomSkills = (props) => {

    const[skills, setSkills] = useState([]);
    const [deletedSkill, setDeletedSkill] = useState();
    const [confirm, setConfirm] = useState(false);

    const defaultNewSkill={name: "New Skill", description: "Description", variable: false, baseValue: 10, skillCategory: "Agility", canLearn: true, restricted: false, }

    useEffect(() => {
        if (props.customContent && props.customContent.skills) {
            setSkills([...props.customContent.skills]);
        }
    },[props.customContent]);

    const displayItem = (skill) => {
        props.displayItem({type: "CustomSkill", item: skill})
    }

    const createSkill = () => {
        let skill = {...defaultNewSkill};
        skill.id = uuidv4();
        displayItem(skill);
    }

    function deleteItem(event, cult) {
        event.stopPropagation();
        setConfirm(true);
        setDeletedSkill(cult);
    }

    function confirmDelete() {
        props.delete(deletedSkill);
        setConfirm(false);
    }

    return (
        <div style={{border: "2px solid #2f588a", borderRadius: "5px", width: "100%", maxHeight: "270px"}}>
            <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", height: "28px", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <div>Custom Skills</div>
                <div style={{width: "30px"}}>
                {props.pageState.editMode ? 
                    <AddCircleOutlineIcon onClick={createSkill} style={{height:"24px", width:"24px", color: "#fff"}}/>
                    :
                null
                }
            </div>
            </div>
            <div style={{display: "flex", alignItem: "center", padding: "10px", fontWeight: "bold"}}>
                <div style={{width: "200px"}}>Name</div>
                <div style={{width: "120px", textAlign: "center"}}>Base Value</div>
                <div style={{width: "100px", textAlign: "center"}}>Specialized</div>
                <div style={{width: "150px", textAlign: "center"}}>Skill Category</div>
                <div style={{width: "100px", textAlign: "center"}}>Restricted</div>
                <div style={{width: "100px", textAlign: "center"}}>Restricted To</div>
            </div>
            <div className={globalStyles.panel} style={{height: "192px", overflow: "auto"}}>
                {skills.map((skill, index) => {
                    return (
                        <div key={index} className={globalStyles.row} style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{display: "flex", alignItems: "center", height: "20px", padding: "6px 10px", cursor: "pointer"}} onClick={() => displayItem(skill)}>
                                <div style={{width: "200px"}}>{skill.name}</div>
                                <div style={{width: "120px", textAlign: "center"}}>{skill.baseValue}</div>
                                <div style={{width: "100px", textAlign: "center"}}>{skill.variable ? "Yes": "No"}</div>
                                <div style={{width: "150px", textAlign: "center"}}>{skill.skillCategory}</div>
                                <div style={{width: "100px", textAlign: "center"}}>{skill.restricted ? "Yes": "No"}</div>
                                {skill.restricted ? 
                                    <div style={{width: "100px", textAlign: "center"}}>{skill.cult && skill.cult.name}</div>
                                : null
                                }
                            </div>
                            {props.pageState.editMode ? 
                                    <div style={{cursor: "pointer", marginRight: "18px"}} onClick={(event) => deleteItem(event, skill)}>
                                        <img src={deleteIcon} style={{height: "20px"}} />
                                    </div>
                                : 
                                null}
                        </div>
                    )
                })}
            </div>
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="skill" />
        </div>
    )
}

export default withPageState(CustomSkills);