import React, { useEffect, useState } from 'react';
import { TextField, Button, Switch, FormGroup, FormControlLabel } from '@mui/material';
import BloodDrop from "../../icons/blood-drop.svg";
import Heal from "../../icons/heal.svg";
import Incrementor from '../Controls/incrementor';

export default function DamageDialog(props) {

	const [damage, setDamage] = React.useState('');
	const [healing, setHealing] = React.useState('');
	const [ignoreArmour, setIgnoreArmour] = useState(false);
	const [fallingDamage, setFallingDamage] = useState(false);
	const [modifier, setModifier] = useState(0);

	useEffect(() => {
		if (props.location) {
			setModifier(props.location.modifier);
		}
	},[props.location.modifier])

	function handleDamageChange(event) {
		if (event.target.value) {
		setDamage(parseInt(event.target.value));
		}
		else {
		setDamage('')
		}
	}

	function handleHealingChange(event) {
		if (event.target.value) {
		setHealing(parseInt(event.target.value));
		}
		else {
		setDamage('')
		}
	}

	function handleOkClick(){
		var change = (healing || 0) - (damage || 0);
		props.update(props.location, change, ignoreArmour, fallingDamage, modifier);
	}

	function handleCancelClick(){
		props.close();
	}

	function changeArmour() {
		setIgnoreArmour(!ignoreArmour);
	}

	function changeBlow() {
		setFallingDamage(!fallingDamage);
	}

	function formatNo(number) {
		return (number < 10 ? '0' : '') + number
   }

   	function increment(value) {
		if (((props.location.armour.points || 0) + (props.location.naturalArmour || 0) + modifier + value) >= 0) {
			setModifier(modifier + value);
		}
	}

	function displayEnchantments() {
		var effects = []
		props.enchantments && props.enchantments.forEach(enchantment => {
			enchantment.effects && enchantment.effects.forEach(effect => {
				if (effect.name === "armourModifier") {
					effects.push(enchantment.name)
				}
			})
		})
		var armourEffects = "";
		if (effects.length > 0) {
			effects.forEach((effect, index) => {
				armourEffects += effect;
				if (index < effects.length -1) {
					armourEffects += ", "
				}
			})
		}
		return (
			<div style={{display:"flex"}}>
				{armourEffects}
			</div>
		)
	}

	const style = {
		borderRadius: 5,
		boxSizing:"border-box",
		border: "0px",
		color: "#121312",
		backgroundColor:"#fff",
		width:"80px"
	};

	if (props.location) {
		var armour = props.location.armour && props.location.armour.points ? (props.location.armour.material + " " + props.location.armour.type + " (" + props.location.armour.points + (props.location.armour.points === 1 ? "pt)": "pts)")) : "";
		if (armour && props.location.naturalArmour) {armour += ", "};
		if (props.location.naturalArmour) {armour += (props.location.naturalArmour.points + " pt " + props.location.naturalArmour.name)}
		return (
			<div >
				<h3 style={{textAlign:"center"}}>{props.location.name} ( {formatNo(props.location.from)} - {formatNo(props.location.to)} )</h3>
				<hr style={{width: "90%"}} />
				<div style={{display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "bold"}}>
					<div style={{padding: "10px 20px"}}>Total Armor: </div>
					<div>{((props.location.armour.points || 0) + (props.location.naturalArmour || 0) + modifier)}</div>
				</div>
				<div style={{padding: "0px 20px"}}>
					<div>Armor: {armour || "None"}</div>

					<div style={{display:"flex", padding: "10px 0px"}}>
						<div>Enchantments:&nbsp;</div>
						{displayEnchantments()}
					</div>
					<div style={{display:"flex"}}>
						<div>Modifier:&nbsp;</div>
						<Incrementor currentValue={modifier} increment={increment} />
					</div>
				</div>
				<hr style={{width: "90%"}} />
				<div style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"0px 20px"}}>
					<div style={{color:"#851113", fontSize: "20px", fontWeight:"bold", display:"flex", width:"140px", alignItems:"center", justifyContent:"space-between"}}>
						<img src={BloodDrop} alt="Damage" style={{height: "36px"}} />
						<div>Damage</div>

					</div>
					<FormGroup>
						<FormControlLabel control={<Switch color="primary" checked={ignoreArmour} onChange={changeArmour}></Switch>} label="Ignore armor"></FormControlLabel>
						<FormControlLabel control={<Switch color="primary" checked={fallingDamage} onChange={changeBlow}></Switch>} label="Falling Damage"></FormControlLabel>
					</FormGroup>
					
					<TextField style={{...style, border: "2px solid #851113"}} type="number" autoFocus variant="outlined" size='small' value={damage} onChange={handleDamageChange} 
						color="primary">{damage}
					</TextField>
				</div>
				<hr style={{width: "90%"}} />
				<div style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"0px 20px"}}>
					<div style={{color:"#075035", fontSize: "20px", fontWeight:"bold", display:"flex", width:"140px", alignItems:"center", justifyContent:"space-between"}}>
						<img src={Heal} alt="Heal" style={{height: "36px"}} />
						<div>Healing</div>

					</div>
					<TextField style={{...style, border: "2px solid #075035"}}  type="number" size="small" variant="outlined" value={healing} onChange={handleHealingChange} 
						color="primary">{healing}
					</TextField>
				</div>
				<hr style={{width: "90%"}} />
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px"}}>
					<Button type="submit" onClick={handleOkClick} id="diceButton" color="primary" variant="contained" style={{width: "100px"}}>Ok</Button>
					<Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"30px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
				</div>
			</div>
		);
	}
	else {
		return null;
	}
}