import React, { useState, useEffect } from 'react'
import { Editor } from "react-draft-wysiwyg";
import dayjs from 'dayjs';
import compareValues from '../../utils/compareValues';
import { Button, TextField } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import DeleteDialog from '../Dialogs/deleteDialog';
import AddNoteDialog from '../Dialogs/addNoteDialog';
import AddLogEntryDialog from './addLogEntryDialog';
import deleteIcon from '../../icons/delete-icon-red.svg'
import { v4 as uuidv4 } from 'uuid';

const CampaignLog = (props) => {

    const [notes, setNotes] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentNote, setCurrentNote] = useState(null); 
    const [noteId, setNoteId] = useState("");

    useEffect(() => {
        setNotes([...props.campaign.notes]);
    },[props.campaign])

    function handleChange(event) {
        setSearchTerm(event.target.value);
    }

    function newEntry() {
        setOpen(true);
        setEdit(true);
        setNoteId("");
      }

    function editNote(id) {
        // setOpen(true);
        // setEdit(true);
        
        // var current = {...notes[index]};
        // setCurrentNote(current);
        // setNoteId(id);
    }

    function deleteNote(id) {
        setConfirm(true);
        setNoteId(id)
    }

    function confirmDelete() {
        if (noteId !== "") {
            var updatedCampaign = {...props.campaign}
            var index = updatedCampaign.notes.findIndex(obj => obj.id === noteId)
            if (index > -1) {
                updatedCampaign.notes.splice(index, 1);
                props.update(updatedCampaign);
                setConfirm(false);
            }
        }
    }

    function update(editorContent, title, index) {
        var updatedCampaign = {...props.campaign}
        updatedCampaign.notes.push({
            id: uuidv4(),
            author: "Gerard",
            created: new Date(),
            content: editorContent,
            title: title
        });
        props.update(updatedCampaign);
    }

    return (
        <div style={{padding: "10px"}}>
            <div >
                <div style={{display:"flex", alignItems: "center", justifyContent: "space-between"}}>
                    {props.campaign.uid === props.authUser.uid ? 
                        <div style={{display:"flex"}}>
                            <Button style={{marginRight: "20px"}} onClick={newEntry} color="primary" variant="contained">Add Entry </Button>
                        </div> 
                    : 
                    null}
                    <div style={{display:"flex", alignItems:"center"}}> 
                        <TextField variant="outlined" label="Search" size="small" name="variant" value={searchTerm} color="primary" onChange={handleChange} style={{backgroundColor:"#f0f0f0", borderRadius:"5px", border: "1px solid #2f588a"}} >
                            {searchTerm}
                        </TextField>
                    </div>
                </div>
            </div>

            {notes.sort(compareValues('created', 'desc')).map((note, index) => {
                if (note.content.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                    var noteDate = dayjs(note.created.seconds * 1000);
                    if (!noteDate.isValid()) {
                        noteDate = dayjs();
                    }
                    return (
                        <div key={index} style={{border: "1px solid #2f588a", borderRadius: "5px", marginTop: "10px", padding: "10px"}}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", fontWeight: "bold"}}>
                                <div style={{width: "760px"}}>{note.title}</div>
                                <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                                    <div style={{marginLeft: "20px"}}>{noteDate.toString()}</div>
                                    <div>{note.author}</div>
                                    <div style={{marginRight: "20px", cursor: "pointer"}} onClick={() => deleteNote(note.id)}><img src={deleteIcon} style={{height: "24px"}} /></div>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: note.content}} />
                        </div>
                    )
                }
            })}
            <DialogBox isOpen={open} size="md" title={noteId !== "" ? "Edit Entry" : "New Entry"}>
                <AddLogEntryDialog update={update} close={() => setOpen(false)} currentNote={currentNote} />
            </DialogBox>
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="entry" />
        </div>
    )
}

export default CampaignLog;