import React from 'react';
import { Button, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { calculateDamageBonus } from '../../../services/attributeService';
import { getCategoryModifiers } from '../../../services/skillsService';
import { calculateStrikeRanks } from '../../../services/attributeService';
import { calculateHitPoints } from '../../../services/hitPointsService';
import { calculateMaxEncumbrance } from '../../../services/attributeService';
import { calculateSpiritCombatDamage } from '../../../services/attributeService';
import DialogButtons from '../../Dialogs/dialogButtons';


export default function ResetDialog(props) {
    const [state, setState] = React.useState({
        enchantments: true,
        augmentations: true,
        hitPoints: true,
        magicPoints: true,
        runePoints: true,
        weaponHitPoints: false,
        characteristics: false,
    });
    
    function handleOkClick() {
        var updatedCharacter = {...props.character};
        if (state.enchantments) {
            updatedCharacter.enchantments = [];
            resetCharacteristics(updatedCharacter.characteristics);
            updatedCharacter.weapons.forEach(weapon => {
                if (Object.hasOwn(weapon, "hidden")) {
                    weapon.hidden = true;
                }
            })
        }
        if (state.augmentations) {
            resetAugmentations(updatedCharacter.characteristics)
            resetAugmentations(updatedCharacter.runes)
            resetAugmentations(updatedCharacter.skills)
            resetAugmentations(updatedCharacter.weapons)
        }
        if (state.hitPoints) {
            updatedCharacter.hitPoints.current = updatedCharacter.hitPoints.total;
            updatedCharacter.hitPoints.locations.forEach(location => {
                location.wounds = [];
            });
        }
        if (state.magicPoints) {
            updatedCharacter.magicPoints.forEach(character => {
                character.current = character.total;
            })
        }
        if (state.runePoints) {
            updatedCharacter.cults.forEach(cult => {
                cult.runePoints.forEach(runePoints => {
                    runePoints.current = runePoints.total;
                })
            })
            if (updatedCharacter.boundSpirits && updatedCharacter.boundSpirits.length > 0) {
                updatedCharacter.boundSpirits.forEach(spirit => {
                    if (spirit.runePoints) {
                        spirit.runePoints.current = spirit.runePoints.total;
                    }
                })
            }
        }
        if (state.weaponHitPoints) {
            updatedCharacter.weapons.forEach(weapon => {
                weapon.hitPoints.current = weapon.hitPoints.total;
            })
        }
        if (state.characteristics) {
            updatedCharacter.characteristics.STR.statBonus = 0;
            updatedCharacter.characteristics.CON.statBonus = 0;
            updatedCharacter.characteristics.SIZ.statBonus = 0;
            updatedCharacter.characteristics.DEX.statBonus = 0;
            updatedCharacter.characteristics.INT.statBonus = 0;
            updatedCharacter.characteristics.POW.statBonus = 0;
            updatedCharacter.characteristics.CHA.statBonus = 0;
        }
        
        updatedCharacter.categoryModifiers = getCategoryModifiers(updatedCharacter.characteristics);
        updatedCharacter.damageBonus = calculateDamageBonus(updatedCharacter.characteristics)
        updatedCharacter.hitPoints = calculateHitPoints(updatedCharacter.characteristics, updatedCharacter.hitPoints);
        updatedCharacter.strikeRanks = calculateStrikeRanks(updatedCharacter.characteristics);
        updatedCharacter.maxEnc = calculateMaxEncumbrance(updatedCharacter.characteristics);

        var updatedSpiritCombat = {...updatedCharacter.spiritCombat};
        updatedSpiritCombat.damage = calculateSpiritCombatDamage(updatedCharacter.characteristics);
        updatedCharacter.spiritCombat = updatedSpiritCombat;

        props.update(updatedCharacter, true);
        props.close();
    }

    function resetCharacteristics(characteristics) {
        for (const statName in characteristics) {
            var stat = characteristics[statName];
            stat.enchantments = [];
            stat.enchantmentBonus = 0;
        }
    }

    function resetAugmentations(collection) {
        if (collection && collection.length > 0) {
            collection.forEach(item => {
                if (item.enchantments) {
                    item.enchantments = [];
                }
            })
        }
    }

    function handleCancelClick() {
        props.close();
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", minHeight: "520px", backgroundColor:"#cdcdcd"}}>
            <div style={{position:"relative"}}>
                <div style={{padding: "20px"}}>Select the character changes you wish to clear, and then click Reset.<p><b>Note: resetting characteristics will reset the current value of each characteristic back to its base value, removing any long term adjustments from effects such as diseases or poisons. If you only wish to remove temporary spell bonuses, this is done using the reset enchantments option.</b></p></div>
                <FormGroup>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.enchantments} onChange={handleChange} color="primary" name="enchantments" />} label="Enchantments
                        : remove all magical bonuses assigned to weapons, characteristics and abilities" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.augmentations} onChange={handleChange} color="primary" name="augmentations" />} label="Augmentations: remove all augmentations assigned to weapon skills and other abilities" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.hitPoints} onChange={handleChange} color="primary" name="hitPoints" />} label="Hit Points: reset both location hit points and total hit points back to their full values" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.magicPoints} onChange={handleChange} color="primary" name="magicPoints" />} label="Magic Points: reset the adventurer's magic points as well as those stored in bound spirits and magic items" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.runePoints} onChange={handleChange} color="primary" name="runePoints" />} label="Rune Points: reset the adventurer's rune points back to full, along with those of any allied spirits" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.weaponHitPoints} onChange={handleChange} color="primary" name="weaponHitPoints" />} label="Weapon Hit Points: restore the hit points of all damaged weapons back to their full value" />
                    </div>
                    <div style={{padding: "5px 10px"}}>
                        <FormControlLabel control={<Switch checked={state.characteristics} onChange={handleChange} color="primary" name="characteristics" />} label="Characteristics: remove any bonuses or penalties to characteristics and restore to their base value" />
                    </div>
                </FormGroup>
                <DialogButtons style={{borderTop: "2px solid #2f588a"}} title="Reset" handleOkClick={handleOkClick} handleCancelClick={handleCancelClick} />
            </div>
        </div>

    );
}