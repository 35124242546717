import React, { useState, useEffect } from 'react';
import * as SAVE from '../../constants/save';
import RuneSpellButtonTile from '../Magic/runeSpellButtonTile';
import { withContext } from '../Context/context';

const RuneSpellMatrixTile = (props) => {

    const [item, setItem] = useState(props.item);

    useEffect(() => {
        var spell = {...props.item};
        var POWStat = props.spellcaster.characteristics.POW;
        spell.value = (POWStat.baseValue + (POWStat.statBonus || 0)) * 5;
        setItem(spell);
    },[props.spellcaster, props.item, props.matrix]);

    function update(updatedSpell, persist) {
        var updatedMatrix = {...props.matrix};
        updatedMatrix.spell = updatedSpell;
        updatedMatrix.active = updatedSpell.active;
        props.update(updatedMatrix, persist);
    }
    
    function processResult(roll) {
        var spellName = roll.item.stackable ? roll.item.name + " " + roll.item.points : roll.item.name + (roll.item.variant ? " " + roll.item.variant : ""); 
        var messageText={critical: " achieved a critical success when casting " + spellName, special: " achieved a special success when casting " + spellName, success: " successfully cast " + spellName, failure: " failed to cast " + spellName, fumble: " fumbled when casting " + spellName}
        var dieRoll = {target: roll.target, result: roll.result, augmentRoll: false, messageText: messageText}
        var spellDetails = {type: "rune", spell: roll.item, spellcaster: props.providerData.character, points: roll.item.points, boost: 0, rune: roll.item.favouredRune}
        props.processResult(dieRoll, spellDetails, roll.title);

        if (roll.result <= roll.target) {
            var updatedMatrices = [...props.providerData.character.matrices]
            var index = updatedMatrices.findIndex(item => item.id === props.matrix.id);
            if (index > -1) {
                updatedMatrices[index].active = false;
                props.update([{fieldName: "matrices", value: updatedMatrices}], SAVE.YES)
            }
        }
    }

    if (item) {
        return (
            <RuneSpellButtonTile diceRoller={props.diceRoller} element={props.element} matrix={props.matrix} update={update} disabled={!props.matrix.active} item={item} spellcaster={props.spellcaster} displayItem={props.displayItem} entity={props.entity} processResult={processResult} />
        )
    }
    else {
        return null;
    }
}
export default withContext(RuneSpellMatrixTile);