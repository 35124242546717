import React from 'react';

export const ReputationTile = (props) => {
    return (
        <button style={{cursor: props.disabled ? "not-allowed": "pointer", width: "120px", height: "40px", background: "linear-gradient(rgba(225, 225, 225, 0.2) 50%, rgba(225, 225, 225, 0) 50.01%), #2F7589", padding: "0px", margin: "1px", alignItems:"center", borderRadius: "5px",  border: "2px solid #2F588A", color: "#fff", borderRadius: "5px"}} onClick={props.rollDice}>
                <div style={{display:"flex", justifyContent: "center", alignItems:"center", padding: "4px", height: "30px"}}>
                    <div style={{display:"flex", alignItems:"center", marginRight: "10px", fontSize:"14px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                        {props.item.name }:
                        {props.target}%
                    </div>
                </div>
        </button>
    )
}