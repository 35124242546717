import React, { useState, useEffect } from 'react'
import globalStyles from '../Styles/styles.module.css';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import EditButton from '../Buttons/editButton';
import { withPageState } from '../Context/PageState';
import SaveIcon from '../../icons/white-save-icon.svg';
import CancelButton from '../Buttons/cancelButton';
import CustomContent from './customContent';

const Custom = (props) => {

    const [customContent, setCustomContent] = useState()
    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.firebase
            .customContents()
            .where("uid", "==", props.authUser.uid)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(function (doc) {
                    const id = doc.id;
                    const data = doc.data();
                    var content = { id, ...data }
                    setCustomContent(content);
                    console.log('content is ', content)
                    console.log('id is ', content.id)
                });
            });
    }, [])

    function saveContent(updatedContent) {
        updatedContent.modified = new Date();
        props.firebase
            .customContent(updatedContent.id)
            .set(updatedContent)
            .then(function () {
            });
    }

    function changeMode() {
        var mode = props.pageState.editMode;
        props.pageState.setEditMode(!mode);
    }

    const saveChanges = () => {
        saveContent(customContent);
        changeMode();
    }

    const cancelChanges = () => {
        setOpen(false);
        changeMode();
        props.firebase
            .customContent(customContent.id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setCustomContent({ ...doc.data(), id: doc.id });
                }
            });
    }

    return (
        <div id="customPage" style={{ width: "100%", height: "100%" }}>
            <AuthUserContext.Consumer>
                {authUser => (
                    condition(authUser) ?
                        <>
                            <div style={{ color: "#001e23", height: "200px", width: "50px", borderRadius: "10px", position: "fixed", top: "60px", left: "0px", zIndex: "999" }}>
                                {props.pageState.editMode ?
                                    <div style={{ color: "#001e23", height: "200px", width: "50px", borderRadius: "10px", position: "fixed", top: "200px", left: "0px", zIndex: "999" }}>
                                        <div className={globalStyles.floatingButton} onClick={saveChanges} style={{ width: "50px", cursor: "pointer", height: "50px", borderRadius: "25px", backgroundColor: "#2F7589", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                            <img style={{ width: "30px" }} src={SaveIcon} title="Save changes" />
                                        </div>
                                        <CancelButton cancelChanges={cancelChanges} type="custom" />
                                    </div>
                                    :
                                    <EditButton user={authUser} onClick={() => props.pageState.setEditMode(true)} />
                                }
                            </div>
                            <CustomContent content={customContent} panelState={open} />
                        </>
                        :
                        null
                )}
            </AuthUserContext.Consumer>
        </div>
    )
}

const condition = authUser => !!authUser;

export default withPageState(withFirebase(Custom));