import React, { useState, useEffect } from 'react';
import {withContext} from '../../Context/context';
import DialogBox from '../../Dialogs/dialogBox';
import SelectEnemyDialog from '../../Dialogs/selectEnemyDialog';
import OpposedPOWDialog from '../opposedPOWDialog';

const EnemySpell = (props) => {
    const [POW, setPOW] = useState(0);
    const [open, setOpen] = useState(false);
    const [openOpposed, setOpenOpposed] = useState(false);
    const [processingOpposed, setProcessingOpposed] = useState(false);
    const [targetEnemy, setTargetEnemy] = useState(null);
    const [opposingPOW, setOpposingPOW] = useState(0);
    const [spellDetails, setSpellDetails] = useState();
    const [diceRoller, setDiceRoller] = useState({
        diceResults: {},
        setDice: setDice
    });

    useEffect(() => {
        var POWStat = props.spellcaster.characteristics.POW;
        setPOW(POWStat.baseValue + (POWStat.statBonus || 0));
    },[props.spellcaster.characteristics]);

    useEffect(() => {
        if (props.diceRoller && Object.keys(props.diceRoller.diceResults).length > 0) {
            setDiceRoller({...props.diceRoller, processingOpposed: processingOpposed});
        }
        else {setDiceRoller({
            diceResults: {},
            setDice: setDice
        });}
    },[props.diceRoller.diceResults])

    function setDice() {
        setOpen(true);
    }
    function processSpell(target) {
        setTargetEnemy(target)
        setOpen(false);
        props.diceRoller.setDice({d100: 1, d10: 1})
    }
    function processOpposed(opposingPOW) {
        setOpposingPOW(opposingPOW);
        setOpenOpposed(false);
        setProcessingOpposed(true);
        props.diceRoller.setDice({d100: 1, d10: 1})
    }

    function processResult(dieRoll, spellDetails, title) {
        var updatedDieRoll = {...dieRoll};
        var gender = props.providerData.character.personalInfo.gender === "Male" ? "his target" : props.providerData.character.personalInfo.gender === "Female" ? "her target" : "their target";
        updatedDieRoll.messageText.target = gender;
        if (processingOpposed) {
            processOpposedPOWRoll(dieRoll, spellDetails);
        }
        else {
            if (dieRoll.result <= dieRoll.target && ! spellDetails.spell.notOpposed) {
                setOpenOpposed(true);
                setSpellDetails(spellDetails);
            }
            props.processResult(updatedDieRoll, spellDetails, title)
        }
        setDiceRoller({
            diceResults: {},
            setDice: setDice
        })
        setProcessingOpposed(false);

    }

    function processOpposedPOWRoll(dieRoll, spellDetails) {
        spellDetails.targetCharacter = {...targetEnemy}
        spellDetails.targetType = props.providerData.type === "character" ? "npc" : "character";
        var target = ((POW - opposingPOW) * 5) + 50;
        var augmentedTarget = props.providerData.augmenting.modifier ? target + props.providerData.augmenting.modifier : target;
        var messageText={critical: " achieved a critical success when overcoming {pronoun} opponents POW", special: " achieved a special success when overcoming {pronoun} opponents POW", success: " successfully overcame  {pronoun} opponents POW", failure: " failed to overcome {pronoun} opponents POW", fumble: " fumbled when attempting to overcome {pronoun} opponents POW"}
        var updatedDieRoll = {...dieRoll, target: augmentedTarget, augmentRoll: false, messageText: messageText}
        setOpenOpposed(false);
        props.powGain(updatedDieRoll.result <= updatedDieRoll.target);
        props.processResult(updatedDieRoll, {...spellDetails, ignoreMPLoss: true}, "Opposed POW Roll");
    }

    return (
        <div>
            {React.cloneElement(props.children, {...props, diceRoller: diceRoller, processResult})}
            <DialogBox isOpen={open} width="700px" title={"Select Spell Target "} >
                <SelectEnemyDialog castingDetails={props.castingDetails} character={props.providerData.character} spell={props.item} processSpell={processSpell} close={() => setOpen(false)} />
            </DialogBox>
            <DialogBox isOpen={openOpposed} width="500px" title={"Opposed POW Roll "} >
                <OpposedPOWDialog processPOWRoll={processOpposed} targetEnemy={targetEnemy} spell={props.item} close={() => setOpenOpposed(false)} />
            </DialogBox>
        </div>
    )
}

export default withContext(EnemySpell)