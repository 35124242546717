import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import * as ROUTES from '../../constants/routes';
import EditBox from '../Controls/editBoxSingle';

const SignUpDialog = (props) => {

  const INITIAL_STATE = {
    firstName: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
  };
  const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

  const [state, setState] = useState(INITIAL_STATE);
  const navigate = useNavigate();

  const handleOkClick = event => {
    const email = state.email
    var firstName = state.firstName;
    props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        props.firebase.user(authUser.user.uid).set({
          firstName,
          email,
          roles: { MEMBER: "MEMBER"},
        })
        .then(() => {
          return props.firebase.doSendEmailVerification();
        })
        .then(() => {
          navigate(ROUTES.HOME);
        })
        .catch(error => {
          console.log('error creating user', error.code, error.message)
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          setState({error: error });
          setTimeout(function() {
            props.closeDialog(email);
            navigate(ROUTES.HOME);
          }, 500)
        });
      });

  };

  function handleChange(name, value){
    var updatedState = {...state};
    updatedState[name] = value;
    setState(updatedState);
  };


  const style = {
    borderRadius: 3,
    boxSizing:"border-box",
    border: 0,
    color: 'white',
    height: "56px",
    width:"250px",
    margin:"20px"
  };

  const {
    firstName,
    email,
    passwordOne,
    passwordTwo,
    isAdmin,
    error,
  } = state;
  
  const isInvalid =
  passwordOne !== passwordTwo ||
  passwordOne === '' ||
  email === '' ||
  firstName === '';

  return (
    <div style={{padding: "20px"}}>
		<div style={{display:"flex"}}>
			<div>
				<EditBox width="240px" fieldLabel="First name" size="lg" name="firstName" onChange={(value) => handleChange("firstName", value)} />
			</div>
			<div style={{marginLeft: "20px"}}>
				<EditBox width="240px" fieldLabel="Email address" size="lg" name="email" onChange={(value) => handleChange("email", value)} />
			</div>
		</div>
		<div style={{display:"flex", marginTop: "20px"}}>
		<div>
				<EditBox width="240px" type="password" fieldLabel="Password" size="lg" name="passwordOne" onChange={(value) => handleChange("passwordOne", value)} />
			</div>
			<div style={{marginLeft: "20px"}}>
				<EditBox width="240px" type="password" fieldLabel="Confirm password" size="lg" name="passwordTwo" onChange={(value) => handleChange("passwordTwo", value)} />
			</div>
		</div>
		<div style={{display:"flex", flexDirection: "row-reverse", marginLeft:"20px", marginTop: "20px"}}>
			<Button onClick={handleOkClick} disabled={isInvalid} color="primary" variant="contained">Sign up</Button>
      <Button onClick={props.closeDialog} color="primary" variant="outlined" style={{marginRight:"30px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
		</div>

		{error && <p>{error.message}</p>}
		</div>
		);
    }

    export default SignUpDialog;