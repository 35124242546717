import React, { useEffect, useState } from 'react';
import { withPageState } from '../../Context/PageState';
import { withContext } from '../../Context/context';
import * as SAVE from '../../../constants/save';
import Incrementor from '../../Controls/incrementor';

const HitPoints = (props) => {

    const [hitPoints, setHitPoints] = useState();

    useEffect(() => {
        setHitPoints(props.providerData.character.hitPoints);
    },[props.providerData.character.hitPoints]);

    function modifyHitPoints(value) {
        var updatedHitPoints = {...props.providerData.character.hitPoints};
        updatedHitPoints.current += value;
        if (updatedHitPoints.current > updatedHitPoints.total) {
            updatedHitPoints.current = updatedHitPoints.total;
        }
        else {
            props.update([{fieldName: "hitPoints", value: updatedHitPoints}], SAVE.DELAYED);
        }
    }

    if (hitPoints) {
        return (
            <div style={{justifyContent: "center", display:"flex", alignItems: "center"}}>
                <div>Hit Pts: </div>
                <Incrementor style={props.style} increment={modifyHitPoints} currentValue={hitPoints.current} totalValue={hitPoints.total} />
            </div>
        )
    }
    else return null;
}

export default withPageState(withContext(HitPoints));