import { v4 as uuidv4 } from 'uuid';

export function addArmourPiece(armourPiece, entity) {
    var updates = [];
    var updatedArmour = [...entity.armour]
    updatedArmour.push(
        {
            id: uuidv4(), 
            parentId: armourPiece.id,
            ENC: armourPiece.ENC,
            covers: [...armourPiece.covers],
            equipped: true,
            material: armourPiece.material,
            stealth: armourPiece.stealth,
            points: armourPiece.points,
            type: armourPiece.type,
        });
    updates.push({fieldName: "armour", value: updatedArmour});
    var updatedHitPoints = {...entity.hitPoints}
    updatedHitPoints.locations.forEach(location => {
        var index = updatedArmour.findIndex(obj => (obj.covers.indexOf(location.name) > -1) && obj.equipped);
        if (index < 0) {
            location.armour = {}
        }
        else {
            location.armour = {
                id: updatedArmour[index].id,
                material: updatedArmour[index].material,
                points: updatedArmour[index].points,
                type: updatedArmour[index].type,
            };
        }
    })
    updates.push({fieldName: "hitPoints", value: updatedHitPoints})
    return updates;
}
