import React from "react";
import { Button } from "@mui/material";

const DialogButtons = (props) => {

    return (
        <div style={{...props.style, display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", padding: "20px", justifyContent:"space-between"}}>
            <div style={{display:"flex", flexDirection:"row-reverse"}}>
                <Button disabled={props.disabled || false} onClick={props.handleOkClick} style={{minWidth: "100px"}} color="primary" variant="contained">{props.title || "Ok"}</Button>
                <Button onClick={props.handleCancelClick} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default DialogButtons;