import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { withFirebase } from '../Firebase';
import DeleteIcon from '../../icons/delete-icon-white.svg';
import DialogBox from '../Dialogs/dialogBox';
import ConfirmDeleteDialog from '../Dialogs/confirmDeleteDialog';

 const CharacterCard = (props) => {

    const [open, setOpen] = useState(false);

    function openDialog(event) {
        event.preventDefault();
        event.stopPropagation();
        setOpen(true);
    }

    function handleDialogClose(result) {
        setOpen(false);
    }

    function deleteCharacter(event) {
        event.preventDefault();
        event.stopPropagation();
        props.deleteCharacter(props.character.id);
        setOpen(false);
    }
        
    function getInnerHTML() {
        var innerHTML = {__html: `<h2>Are you sure you wish to delete ${props.character.name.first + " " + props.character.name.last}?</h2>
        <p>Once a character has been deleted it cannot be retrieved, so please be sure before you go ahead </p>
        <p>To confirm the deletion, type the name of the character in the box below, and then click 'Delete' </p>
        `};
        return innerHTML
    }

    if (props.character) {
        var cultName = "";
        if (props.character.cults && props.character.cults.length > 0) {
            props.character.cults.forEach((cult) => {
                if (cult.name !== "Common Rune Spells") {
                    if (cultName) {
                        cultName += " & " + cult.name;
                    }
                    else {
                        cultName = cult.name;
                    }
                }
            })
        }
        return (
            <div style={{marginTop:"30px", width: "360px", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", color: "#fff", border: "2px solid #2f588a", borderRadius: "5px"}} >
                <div style={{display: "flex"}} >
                    <img style={{width: "150px", height: "180px", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px"}} src={props.character.profileImage} title={props.character.name.first} />
                    <div style={{padding: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "190px"}}>
                        <div>
                            <Typography component="h5" variant="h5">
                                {props.character.name?.first || null}&nbsp;{props.character.name?.last || null}
                            </Typography>
                            <Typography>
                                {props.character.background?.culture || null}&nbsp;{props.character.occupation?.name || null}
                            </Typography>
                            {
                                props.character.cults && props.character.cults.length > 0 ? 
                                <div>Initiate of {cultName}</div>
                            :
                                null
                            }
                        </div>
                        <div>
                            {
                            props.showDelete ? 
                                <div style={{display:"flex", flexDirection: "column-reverse", alignItems:"end"}}>
                                    <img onClick={openDialog} style={{width:"20px"}} src={DeleteIcon} title={"Delete " + props.character.name.first} />
                                </div>
                            :
                                null
                            }

                        </div>
                    </div>
                </div>
                <DialogBox isOpen={open} handleClose={handleDialogClose} width="600px" title={"Delete " + props.character.name.first}>
                    <ConfirmDeleteDialog itemName={props.character.name.first + (props.character.name.last ? " " + props.character.name.last : "")} innerHTML={getInnerHTML()} type="Character" delete={deleteCharacter} closeDialog={handleDialogClose}></ConfirmDeleteDialog>
                </DialogBox>
            </div>
        );
    }
    else {return null;}
}

export default withFirebase(CharacterCard);