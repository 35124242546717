import React from 'react';
import {withContext} from '../Context/context';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateSpiritCombatDamage } from '../../services/attributeService';
import { isEqual } from 'lodash';

const CharismaTile = (props) => {

    function update(characteristic, save) {
        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.CHA = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedSpiritCombat = {...props.entity.spiritCombat};
        updatedSpiritCombat.damage = calculateSpiritCombatDamage(updatedCharacteristics);
        if (! isEqual(props.entity.spiritCombat, updatedSpiritCombat)) {
            updates.push({fieldName: "spiritCombat", value: updatedSpiritCombat})
        }

        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(CharismaTile)