import React from 'react';
import { withPageState } from '../../Context/PageState';
import globalStyles from '../../Styles/styles.module.css';
import DiceButton from '../../Buttons/diceButton';

const SpiritSpellRow = (props) => {

    function displayItem() {
        props.displayItem({type: "Spirit Spell", item: props.item, matrix: props.matrix})
    }
   
    const classNameGenerator = (...classes)=>{
        return classes.join(" ");
    }

    if (props.pageState.editMode) {
        return (
            <div style={{display:"flex", alignItems: "center", padding: "2px"}} >
                <div style={{width: "190px"}}>{props.item.name}{props.item.variant ? " " + props.item.variant : ""}</div>
                <div style={{width: "80px", textAlign: "center"}}>{props.item.variable ? "Yes": "No"}</div>
                <div style={{width: "70px", textAlign: "center"}}>{props.item.points}</div>
            </div>
        )
    }
    else {
        return (
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", padding: "2px"}} onClick={displayItem} >
                <div style={{width: "190px"}}>{props.item.name}{props.item.variant ? " " + props.item.variant : ""}</div>
                <div style={{width: "30px", textAlign: "center"}}>{props.item.points}</div>
                <div style={{width: "70px", textAlign: "center"}}>{props.item.variable ? "Yes": "No"}</div>
                <div style={{width: "30px", textAlign: "center"}}>{props.item.strikeRank}</div>
                <div style={{marginLeft: "8px", width: "40px"}} className={props.disabled ? classNameGenerator(globalStyles.disabledButton, globalStyles.diceButton) : globalStyles.diceButton}>
                    <DiceButton type="Spirit Spell" diceRoller={props.diceRoller} style={{border: "1px solid #2F588A", borderRadius: "3px", padding: "1px", width: "40px", textAlign: "right", cursor: "pointer"}} item={props.item} processResult={props.processResult} disabled={props.disabled} >
                        <ButtonValue />
                    </DiceButton>
                </div>
            </div>

        )
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}

export default withPageState(SpiritSpellRow);