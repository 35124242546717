import React from 'react'
import globalStyles from '../Styles/styles.module.css';

const SpiritCombatFumblesTable = (props) => {
    return (
        <div style={{backgroundColor: "f0f0f0", width: "1060px", height: "400px"}}>
            <div style={{backgroundColor: "#676767", color: "#fff", display:"flex", fontSize: "13px", padding: "10px"}}>
                <div style={{width: "120px"}}>D100</div>
                <div style={{width: "900px"}}>Nature and Effect on Fumbler</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>1-15</div>
                <div style={{width: "900px"}}>Combatant confuses realities; loses all actions next round.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>16-30</div>
                <div style={{width: "900px"}}>Combatant begins to dream; loses next two melee rounds while attacking imaginary enemies.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>31-45</div>
                <div style={{width: "900px"}}>Combatant confuses self with target; does spirit combat damage to self.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>46-60</div>
                <div style={{width: "900px"}}>Combatant hallucinates; if discorporate, may attack any random nearby target; if in the Middle World, misses next 1D3 melee rounds negotiating with imaginary foe.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>61-75</div>
                <div style={{width: "900px"}}>Combatant’s energies become unfocused; lose 1D6 magic points.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>76-79</div>
                <div style={{width: "900px"}}>Combatant begins to cry uncontrollably, and has trouble seeing. Combatant’s Spirit Combat skill for the next two melee rounds is halved.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>80-89</div>
                <div style={{width: "900px"}}>Spirit combat energies penetrate the veil; combatant takes 1D3 hit points damage to a random hit location.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>90</div>
                <div style={{width: "900px"}}>One magic item (chosen at random) is damaged beyond repair due to outpouring of magical energies.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>91-95</div>
                <div style={{width: "900px"}}>Energies affect the combatant’s senses. Roll 1D6: on a 1: Combatant’s legs become numb and the combatant falls; 2: Combatant’s arms become numb and they drop anything they are holding; 3–4: Combatant loses control of their bladder; 5: Combatant loses control of their bowels; 6: Combatant falls unconscious (remaining magic points are retained).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>96-98</div>
                <div style={{width: "900px"}}>
                    <div><b>Non-shaman: </b>Combatant’s body and spirit become separated; combatant discorporates and may only be reunited by a successful Spirit Travel roll or the intervention of a shaman. The gamemaster should determine the physical effects of long-term discorporation.</div>
                    <div><b>Shaman: </b>Re-roll twice.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>99-100</div>
                <div style={{width: "900px"}}>Combatant loses all faith and believes that their only viable choice is to surrender; spirit combat ends.</div>
            </div>
       </div>
    )
}

export default SpiritCombatFumblesTable;
