import React, { useState, useEffect } from 'react';
import { withPageState } from '../../Context/PageState';
import globalStyles from '../../Styles/styles.module.css';
import { withContext } from '../../Context/context';
import AddRuneDialog from "../dialogs/addNewRuneDialog";
import * as SAVE from '../../../constants/save'
import PanelHeader from '../../Controls/panelHeader';
import DiceButton from '../../Buttons/diceButton';
import EditBox from '../../Controls/editBoxSingle';

const Runes = (props) => {
    const [allRunes, setAllRunes] = useState([]);
    const [elementalRunes, setElementalRunes] = useState([]);
    const [formRunes, setFormRunes] = useState([]);
    const [powerRunes, setPowerRunes] = useState({})

    useEffect(() => {
        var elemental = [];
        var form = [];
        var powers = {}
        props.providerData.character.runes.forEach(rune => {
            if ((!props.pageState.editMode) && rune.value <= 0) return;
            if (rune.type === "Elemental") {
                elemental.push({...rune});
            }
            else if (["Plant", "Dragonewt", "Spirit", "Chaos"].indexOf(rune.name) > -1) {
                form.push({...rune});
            }
            else {
                powers[rune.name.toLowerCase()] = {...rune}
            }
        })
        setPowerRunes(powers);
        setElementalRunes(elemental);
        setFormRunes(form);
    },[props.providerData.character.configuration, props.pageState.editMode, props.providerData.character.runes]);

    useEffect(() => {
        setAllRunes(props.providerData.character.runes);
    },[props.providerData.character.runes]);
    
    const addRune = (newRune) => {
        var updatedRunes = [...allRunes]
        var index = updatedRunes.findIndex(obj => obj.parentId === newRune.parentId)
        if (index < 0) {
            updatedRunes.push(newRune);
            props.update([{fieldName: "runes", value: updatedRunes}], SAVE.NO)
        }
    }

    function processResult(roll) {
        var messageText={critical: " achieved a critical success with {pronoun} " + " " + roll.item.name + " Rune", special: " achieved a special success with {pronoun} " + " " + roll.item.name + " Rune", success: " succeeded with {pronoun} " + " " + roll.item.name + " Rune", failure: " failed with {pronoun} " + " " + roll.item.name + " Rune", fumble: " fumbled {pronoun} "  + " " + roll.item.name + " Rune"};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment}
        props.processResult(dieRoll, {...roll.item, type: "runes"}, roll.title)
    }

    function displayItem(rune) {
        props.displayItem({type: "Rune", item: rune})
    }
    
    function handleValueChange(newValue, rune) {
        var updatedValue = newValue;
        var updatedRunes = [...props.providerData.character.runes];
        var index = updatedRunes.findIndex(obj => obj.name === rune.name);
        if (index > -1) {
            updatedRunes[index].value = newValue;
            props.update([{fieldName: "runes", value: updatedRunes}], SAVE.NO);
        }
    }

    function displayRuneButton(rune) {
        if (props.pageState.editMode) {
            if (rune) {
                return(
                    <EditBox size="sm" number name={rune.name} value={rune.value} width="34px" onChange={(value) => handleValueChange(value, rune)} />
                )
            }
        }
        else {
            return (
                <DiceButton diceRoller={props.diceRoller} style={{border: "1px solid #2F588A", borderRadius: "3px", padding: "2px", minWidth: "40px", textAlign: "right", cursor: "pointer", height: "16px"}} item={rune} type="Rune" processResult={processResult} entity={props.entity}>
                    <ButtonValue />
                </DiceButton>
            )
        }
    }

    return(
        <div style={{border: "2px solid #2F588A", borderRadius: "5px", position: "relative"}}>
            <PanelHeader title="Runes" addTitle="Add rune" addItem={addRune}>
                <AddRuneDialog />
            </PanelHeader>
            <div style={{display: "flex", flexWrap: "wrap", padding: "10px 5px", zIndex: 20}}>
                {
                    elementalRunes.map((rune, index) => {
                        if (rune.name) {
                            var runeName = rune.name === "Fire/Sky" ? "fire" : rune.name.toLowerCase();
                            return (
                                <div className={globalStyles.expandableTile} key={index} style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "140px", margin: "3px 6px"}} >
                                    <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/" + runeName + "-rune-40x40.png"} onClick={() => displayItem(rune)} />
                                    <div style={{margin: "0px 5px"}} onClick={() => displayItem(rune)}>{rune.name}</div>
                                    {displayRuneButton(rune)}
                                </div>
                            )
                        }
                    })
                }

            </div>
            <hr />
            <div style={{display: "flex", alignItems: "center", flexDirection: "column", position: "relative", width: "100%", height: "185px"}}>
                <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center", marginBottom: "5px"}} >
                    <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/man-rune-40x40.png"} onClick={() => displayItem(powerRunes.man)} />
                    <div style={{margin: "0px 10px"}} onClick={() => displayItem(powerRunes.man)}>Man</div>
                    {displayRuneButton(powerRunes.man)}
                    </div>
                <div style={{display: "flex", alignItems: "center", margin: "2px 0px"}}>
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                    {displayRuneButton(powerRunes.fertility)}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.fertility)}>
                            <div style={{margin: "0px 12px", width: "70px"}}>Fertility</div>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/fertility-rune-40x40.png"} />
                        </div>
                    </div>
                    <div style={{borderTop: "2px solid #ababab", margin: "0px 10px", width: "100px"}} />
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.death)}>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/death-rune-40x40.png"} />
                            <div style={{margin: "0px 12px", width: "70px"}}>Death</div>
                        </div>
                        {displayRuneButton(powerRunes.death)}
                        </div>
                </div>
                <div style={{display: "flex", alignItems: "center", margin: "2px 0px"}}>
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                    {displayRuneButton(powerRunes.harmony)}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.harmony)}>
                            <div style={{margin: "0px 12px", width: "70px"}}>Harmony</div>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/harmony-rune-40x40.png"} />
                        </div>
                    </div>
                    <div style={{borderTop: "2px solid #ababab", margin: "0px 10px", width: "100px"}} />
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.disorder)}>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/disorder-rune-40x40.png"} onClick={() => displayItem(powerRunes.disorder)} />
                            <div style={{margin: "0px 12px", width: "70px"}}>Disorder</div>
                        </div>
                        {displayRuneButton(powerRunes.disorder)}
                        </div>
                </div>
                <div style={{display: "flex", alignItems: "center", margin: "2px 0px"}}>
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                    {displayRuneButton(powerRunes.truth)}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.truth)}>
                            <div style={{margin: "0px 12px", width: "70px"}}>Truth</div>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/truth-rune-40x40.png"} />
                        </div>
                    </div>
                    <div style={{borderTop: "2px solid #ababab", margin: "0px 10px", width: "100px"}} />
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.illusion)}>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/illusion-rune-40x40.png"} />
                            <div style={{margin: "0px 12px", width: "70px"}}>Illusion</div>
                        </div>
                        {displayRuneButton(powerRunes.illusion)}
                        </div>
                </div>
                <div style={{display: "flex", alignItems: "center", margin: "2px 0px"}}>
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                    {displayRuneButton(powerRunes.stasis)}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.stasis)}>
                            <div style={{margin: "0px 12px", width: "70px"}}>Stasis</div>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/stasis-rune-40x40.png"} />
                        </div>
                    </div>
                    <div style={{borderTop: "2px solid #ababab", margin: "0px 10px", width: "100px"}} />
                    <div className={globalStyles.expandableTile} style={{display: "flex", alignItems: "center"}} >
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => displayItem(powerRunes.movement)}>
                            <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/movement-rune-40x40.png"} />
                            <div style={{margin: "0px 12px", width: "70px"}}>Movement</div>
                        </div>
                        {displayRuneButton(powerRunes.movement)}
                        </div>
                </div>
                <div className={globalStyles.expandableTile} style={{display: "flex", marginTop: "5px", alignItems: "center"}} >
                    <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/beast-rune-40x40.png"} onClick={() => displayItem(powerRunes.beast)} />
                    <div style={{margin: "0px 10px"}} onClick={() => displayItem(powerRunes.beast)}>Beast</div>
                    {displayRuneButton(powerRunes.beast)}
                </div>
                
                <div style={{height: "112px", borderRight: "2px solid #ababab", position: "absolute", left: "0px", top: "30px", width: "49%", zIndex: -1}} />
            </div>
            {formRunes && formRunes.length > 0 ?
            <>
                <hr />
                <div style={{display: "flex", flexWrap: "wrap", padding: "10px 5px", zIndex: 20}}>
                    {
                        formRunes.map((rune, index) => {
                            if (rune.name) {
                                return (
                                    <div className={globalStyles.expandableTile} key={index} style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "140px", margin: "3px 6px"}} >
                                        <img style={{width: "24px", height: "24px"}} src={"https://storage.googleapis.com/runequest/runes/" + rune.name.toLowerCase() + "-rune-40x40.png"} onClick={() => displayItem(rune)} />
                                        <div style={{margin: "0px 5px"}} onClick={() => displayItem(rune)}>{rune.name}</div>
                                        {displayRuneButton(rune)}
                                    </div>
                                )
                            }
                        })
                    }

                </div>
            </>
            : 
            null}
        </div>
    );

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}

export default withContext(withPageState(Runes));