import React, { useState, useEffect } from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import compareValues from '../../../utils/compareValues';

const OptionsDropdown = (props) => {
    
    const [selected, setSelected] = useState({name: ""});
    const [options, setOptions] = useState([]);
    const [lowStats, setLowStats] = useState(false);

    useEffect(() => {
        setOptions([...props.item.options].sort(compareValues('name')));
        if (selected && props.item.options.findIndex(option => option.name === selected.name) < 0) {
            setSelected({name: ""})
        }
    }, [props.item.options]);

    useEffect(() => {
        if (props.item.selected && props.item.selected.name) {
            setSelected(props.item.selected)
        }
        else {
            setSelected({name: ""})
        }
    }, [props.item.selected]);

    function handleChange(event) {
        options.forEach((option) => {
            if (option.name === event.target.value) {
                setSelected(option);
                props.update(option, props.index);
                if (props.stats) {
                    var minSTR = option.minSTR || 0;
                    var minDEX = option.minDEX || 0;
                    var lowStats = minSTR > props.stats.STR;
                    if (!lowStats) {
                        if (minDEX > props.stats.DEX) {
                            if ((props.stats.STR - minSTR) < ((minDEX - props.stats.DEX) *2)) {
                                lowStats = true;
                            }
                        }
                    }
                    setLowStats(lowStats)
                    props.setLowStats(lowStats);
                }
            }
        });
    }

    var border = selected.name ? "3px solid transparent" : "3px solid #851113";
    return (
        <div style={{display:"flex", minHeight: "30px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px", backgroundColor:"#fff"}}>
            <div style={{display: "flex", paddingLeft: "5px", margin: "2px", width: "100%", borderRadius: "5px", border: border}}>
                <div  style={{width: "320px", height: "60px", display:"flex", alignItems: "center"}}>
                    <FormControl variant="standard" style={{width:"300px"}}>
                    <InputLabel shrink id="occupation-label">{props.item.title}</InputLabel>
                        <Select labelId="occupation-label" id="occupation" style={{minWidth:"12em", height: "30px", color: lowStats ? "#851113" : "#121312"}}  onChange={handleChange} value={selected.name}>
                        {
                            options.map(option => {
                                if (option.name == selected.name || (props.selected && props.selected.indexOf(option.name) < 0)) {
                                    return (
                                        <MenuItem key={option.name} value={option.name}>{option.name} {option.variant ? option.variant : null}</MenuItem>
                                    )            
                                }
                            })
                        }
                        </Select>
                    </FormControl>
                </div>
                <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{props.item.bonus}</div>
            </div>
        </div>
    )
}

export default OptionsDropdown;