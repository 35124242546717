import React, {useState, useEffect} from 'react';
import rollSound from '../../sounds/rollD100.mp3';
import {withContext} from '../Context/context';
import { enchantmentEffectApplies } from '../../services/weaponService';

const DamageButton = (props) => {

    const [buttonTitle, setButtonTitle] = useState("");
    const [dicePool, setDicePool] = useState();
    const [modifier, setModifier] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [special, setSpecial] = useState(false);
    const [dieTypeCount, setDieTypeCount] = useState(0);

    useEffect(() => {
        if (!props.weapon.damage) return;

        var pool = [];
        var magicDamage = calculateMagicalDamage();
        var weaponDamage = {...props.weapon.damage};
        if (weaponDamage.stackable) {
            weaponDamage.quantity = props.points;
        }
        if (props.entity.enchantments) {
            props.entity.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.item) {
                        if (effect.item.id === props.weapon.id) {
                            if (effect.name === "damage") {
                                weaponDamage = {...effect.value};
                            }
                        }
                    }
                })
            })
        }
        if (props.type === "Magical only") {
            pool = magicDamage;
        }
        else if (props.type === "Special") {
            var special = props.calculateSpecialDice(weaponDamage);
            pool = [...magicDamage, ...special];
            setSpecial(true);
        }
        else if (props.type === "Critical") {
            var critical = props.calculateCriticalDice(weaponDamage);
            pool = [...magicDamage, ...critical];
            setSpecial(true);
        }
        else {
            var normal = [weaponDamage];
            var dmgBonus = {...props.entity.damageBonus}
            if (dmgBonus) {
                dmgBonus.dieType *= props.weapon.damage.bonus;
                normal.push(dmgBonus);
                pool = [...normal, ...magicDamage]
            }
        }
        
        setDicePool(pool);
        if (pool.length <= 0) {
            setDisabled(true);
        }
        else {
            var mod = 0;
            pool.forEach(die => {
                mod += die.modifier || 0
            });
            setModifier(mod);
            setDisabled(false);
        }
    },[props.entity, props.weapon, props.type, props.points])

    useEffect(() => {
        createButtonTitle()
    }, [dicePool])

    useEffect(() => {
        if (clicked) {
            setClicked(false);
            var result = 0;
            var rolls = [];
            var d3 = 0;
            var d2 = 0;
            var doubled = false;
            dicePool.forEach((die) => {
                switch(die.dieType) {
                    case 6:
                        if (die.doubled) doubled = true; // hack for spirit combat special damage
                        break;
                    case 3:
                        d3 += die.quantity;
                        break;
                    case 2:
                        d2 += die.quantity;
                        break;
                    default:
                        break;
                    }
            })

            for (let key in props.diceRoller.diceResults) {
                if (key === "d20") {
                    // return;
                }
                else if (key.startsWith("d6")) {
                    if (d3 > 0) {
                        result += Math.ceil(props.diceRoller.diceResults[key]/2);
                        rolls.push({type: 'd3', roll: Math.ceil(props.diceRoller.diceResults[key]/2)})
                        d3 --;
                    }
                    else {
                        result += props.diceRoller.diceResults[key];
                        rolls.push({type: 'd6', roll: props.diceRoller.diceResults[key]})
                    }
                }
                else if (key.startsWith("d4")) {
                    if (d2 > 0) {
                        result += Math.ceil(props.diceRoller.diceResults[key]/2);
                        rolls.push({type: 'd2', roll: Math.ceil(props.diceRoller.diceResults[key]/2)})
                        d2 --;
                    }
                    else {
                        result += props.diceRoller.diceResults[key];
                        rolls.push({type: 'd4', roll: props.diceRoller.diceResults[key]})
                    }
                }
                else if (key.startsWith("d8")) {
                    result += props.diceRoller.diceResults[key];
                    rolls.push({type: 'd8', roll: props.diceRoller.diceResults[key]})
                }
                else {
                    result += props.diceRoller.diceResults[key];
                    rolls.push({type: key.substring(0,3), roll: props.diceRoller.diceResults[key]})
                }
            }
            result += modifier || 0;
            if (doubled) {result *= 2}
            var location = props.diceRoller.diceResults.d20;
            props.processDamage({
                rolls: rolls, 
                result: result, 
                doubled: doubled,
                location: location, 
                weapon: props.weapon,
                modifier: modifier, 
                title: (props.weapon.title || props.weapon.name) + " Damage"
            });
        }
    },[props.diceRoller.diceResults])

    function createButtonTitle() {
        var title = "";

        if (dicePool) {

            var d10 = 0;
            var d8 = 0; 
            var d6 = 0; 
            var d4 = 0;
            var d3 = 0;
            var d2 = 0;
            var mod = 0;

            dicePool.forEach((die) => {
                switch(die.dieType) {
                    case 10:
                        d10 += die.quantity;
                        break;
                    case 8:
                        d8 += die.quantity;
                        break;
                    case 6:
                        d6 += die.quantity;
                        break;
                    case 4:
                        d4 += die.quantity;
                        break;
                    case 3:
                        d3 += die.quantity;
                        break;
                    case 2:
                        d2 += die.quantity;
                        break;
                    default:
                        break;
                    }
                    if (die.modifier) {
                        mod += die.modifier;
                    }
            })
            var dieTypes = 0;
            if (d10) {title += d10 + "d10"; dieTypes++;}
            if (d8) {title = title ? title + "+" + d8 + "d8" : d8 + "d8"; dieTypes++;}
            if (d6) {title = title ? title + "+" + d6 + "d6" : d6 + "d6"; dieTypes++;}
            if (d4) {title = title ? title + (d4 < 0 ? "" : "+") + d4 + "d4" : d4 + "d4"; dieTypes++;}
            if (d3) {title = title ? title + "+" + d3 + "d3" : d3 + "d3"; dieTypes++;}
            if (d2) {title = title ? title + "+" + d2 + "d2" : d2 + "d2"; dieTypes++;}

            if (mod) {
                title += (mod < 0 ? "" : "+") + mod;
            }
            if (title) {
                setButtonTitle(title);  
            }
            else {
                setButtonTitle("None");  
                setDisabled(true)
            }
            setDieTypeCount(dieTypes);
        }
    }
    
    function calculateMagicalDamage() {
        var magicPool = [];
        if (props.entity.enchantments) {
            props.entity.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.name === "damageMultiplier") {
                        let valid = enchantmentEffectApplies(enchantment, effect, props.weapon)
                        if (valid) {
                            var multiplier = effect.value
                            magicPool.push( {
                                quantity: ((props.weapon.damage.quantity * multiplier) - props.weapon.damage.quantity),
                                dieType: props.weapon.damage.dieType,
                                modifier: ((props.weapon.damage.modifier * multiplier) - props.weapon.damage.modifier),
                            });
                        }
                    }
                })
            });
            props.entity.enchantments.forEach(enchantment => {
                enchantment.effects.forEach(effect => {
                    if (effect.name === "damageModifier") {
                        let valid = enchantmentEffectApplies(enchantment, effect, props.weapon)
                        if (valid) {
                            magicPool.push( {...effect.value});
                        }
                    }

                })
                if (enchantment.damageModifier && (enchantment.item.id === props.weapon.id || enchantment.target === "Character")) {
                    magicPool.push( {...enchantment.damageModifier});
                }
            });
        }
        return magicPool;
    }

    function rollDice(event) {
        if (props.weapon.title === "Spirit Combat") {
            if (special) {
                var dice = {d4: 0, d6: 0, d8: 0, d10: 0, d20: 1}
            }
            else {
                var dice = {d4: 0, d6: 0, d8: 0, d10: 0, d20: 0}
            }
        }
        else {
            var dice = {d4: 0, d6: 0, d8: 0, d10: 0, d20: 1}
        }
        dicePool.forEach(die => {
            var dieType = die.dieType;
            if (dieType < 4) dieType *=2;
            var dieName = "d" + dieType;
            dice[dieName] += die.quantity;
        })
        setClicked(true);
        event.stopPropagation();
        props.diceRoller.setDice(dice)
    }

    if (props.noButton) {
        return (
            <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={(event) => rollDice(event, dicePool)}>
                <div style={props.style} >{buttonTitle}</div>
            </div>
        )
    }
    else {
        if (disabled) {
            return (
                <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", backgroundColor: "#acacac", color: "#fff", borderRadius: "8px", justifyContent: "center", cursor: "not-permitted"}} >
                    <div style={props.style} >{buttonTitle || "None"}</div>
                </div>
            )
        }
        else {
            return (
                <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center", fontSize: dieTypeCount > 2 ? "13px" : "15px"}} onClick={(event) => rollDice(event, dicePool)}>
                    <div style={props.style} >{buttonTitle}</div>
                </div>
            )
        }
    }
}

export default withContext(DamageButton)
