import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withFirebase } from '../Firebase';
import * as SAVE from '../../constants/save';
import ModifierBox from '../Controls/modifierBox';
import SpellAdjuster from './spellAdjuster';
import SpiritSpell from './spiritSpell';
import { processSpiritSpell } from '../../services/spellsService';
import { getAvailableMagicPoints } from '../../services/magicPointsService';
import SpiritSpellButton from '../Buttons/spiritSpellButton';
import { withContext } from '../Context/context';
import DamageButton from '../Buttons/damageButton';
import calculateSR from '../../services/strikeRankService';
import { getSpiritSpell } from '../../services/dataService';

const SpiritSpellTileContextMenu = (props) => {

    const [modifier, setModifier] = useState(0);
    const [boost, setBoost] = useState(0);
    const [points, setPoints] = useState(props.panelItem.item.points);
    const [availableMagicPoints, setAvailableMagicPoints] = useState(0);
    const [item, setItem] = useState(props.panelItem.item);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [casters, setCasters] = useState([]);
    const [spell, setSpell] = useState(null)
    const locationElement = useRef();
    const notesElement = useRef();
    const castingElement = useRef();

    useEffect(() => {
        setPoints(props.panelItem.item.points);
        setItem(props.panelItem.item);
        setBoost(0);
        setPoints(props.panelItem.item.points);
        setSpell(getSpiritSpell(props.panelItem.item.parentId, props.providerData.campaign));
    },[props.panelItem.item]);

    useEffect(() => {
        var spellcasters = [props.spellcaster];
        if (props.spellcaster.id != props.providerData.character.id) {spellcasters.push(props.providerData.character)}
        if (props.providerData.character.boundSpirits.length > 0) {
            props.providerData.character.boundSpirits.forEach(spirit => {
                if (spirit.type === "Allied spirit" || spirit.type === "Fetch") {
                    if (spirit.id !== props.spellcaster.id) {
                        spellcasters.push(spirit);
                    }
                }
            });
        }
        setCasters(spellcasters);
    },[props.spellcaster])

    useEffect(() => {
        var points = getAvailableMagicPoints(props.providerData.character, props.spellcaster)
        setAvailableMagicPoints(points.current);
    },[props.providerData.character.magicPoints, props.spellcaster])

    useEffect(() => {
        var height = 145;
        height += props.panelItem.matrix ? 53 : 0;
        height += props.panelItem.item.stackable ? 45 : 0;
        height += locationElement.current ? locationElement.current.clientHeight + 15 : 0;
        height += notesElement.current ? notesElement.current.clientHeight + 15 : 0;
        height += castingElement.current.clientHeight + 15;
        setDescriptionHeight(800 - height);
    },[props.panelItem.item])

    const boostSpell = (value) => {
        var newBoost = boost + value;
        if (newBoost < 0) return;
        if ((newBoost + points) > availableMagicPoints) return; 
        setBoost(newBoost);
    }

    const adjustVariable = (value) => {
        var newPoints = points + value;
        if (newPoints < 1) return;
        if (newPoints > item.points) return;
        if (boost > 0 && ((newPoints + boost) > availableMagicPoints)) return;
        setPoints(newPoints);
    }
    
    function processSpell(dieRoll, details, title){
        var action = {...details, points: points, boost: boost, spellcaster: props.spellcaster}
        var updates = processSpiritSpell(dieRoll, action, props.providerData.character, props.firebase, "character", props.providerData.campaign)
        props.update(updates, SAVE.DELAYED);
        props.processResult(dieRoll, action, title);
    }

    function update(updatedSpell, persist) {
        if (props.panelItem.parent) {
            if (props.panelItem.parent.type === "matrix") {
                var updatedMatrices = props.spellcaster.matrices;
                var index = updatedMatrices.findIndex(obj => obj.id === props.panelItem.parent.id);
                updatedMatrices[index].spell = updatedSpell;
                props.update([{fieldName: "matrices", value: updatedMatrices}], SAVE.YES)
            }
        }
        else {
            var updatedSpells = props.spellcaster.spiritSpells;
            var index = updatedSpells.findIndex(obj => obj.id === updatedSpell.id);
            updatedSpells[index] = updatedSpell;
            props.update([{fieldName: "spiritSpells", value: updatedSpells}])
        }
    }

    function powGain(gained) {
        if (gained) {
            props.update([{fieldName: "powGain", value: true}], SAVE.DELAYED);
        }
    }

    var dexSR = props.spellcaster.strikeRanks ? calculateSR("DEX", props.spellcaster) : calculateSR("DEX", props.providerData.character);

    return (
        <>
            <div style={{height: "450px", fontSize: "14px"}}>
                {item.variable ? 
                    <SpellAdjuster title={"Cast spell at " + points + " points"} adjustUp={() => adjustVariable(1)} adjustDown={() => adjustVariable(-1)} />
                : 
                null
                }
                <SpellAdjuster title={"Boost spell by " + boost + " magic points"} adjustUp={() => boostSpell(1)} adjustDown={() => boostSpell(-1)} />
                <ModifierBox number handleChange={(value) => setModifier(value)} />
                <hr style={{width:"100%"}} />
                <div ref={castingElement} >
                    <div>Cast spell at SR:&nbsp;{dexSR + points + boost}</div>

                    {casters.map((caster,index) => {
                        return (
                            <div key={index} style={{display: "flex", justifyContent: "space-between", padding: "5px 0px", alignItems: "center"}}>
                                <div>Spell is cast by&nbsp;{caster.name.first}</div>
                                <div style={{width: "120px", borderRadius: "5px"}}>
                                    <SpiritSpell diceRoller={props.diceRoller} castingDetails={props.castingDetails} character={props.providerData.character} element={<SpiritSpellButton diceRoller={props.diceRoller} modifier={modifier} />} spellcaster={caster} availableMagicPoints={availableMagicPoints} item={{...item, points: points}} processResult={processSpell} update={update} powGain={powGain} />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {spell && spell.damage ?
                    <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems: "center"}}>
                        <div>Damage: </div>
                        <div className={globalStyles.largeDamageButton} style={{width: "120px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <DamageButton processDamage={props.processDamage} weapon={spell} entity={props.providerData.character} diceRoller={props.diceRoller} />
                        </div>
                    </div>
                : 
                null
                }
                <hr style={{width:"100%"}} />
                {props.panelItem.parent && props.panelItem.parent.type === "matrix" ?
                    <>
                        <div ref={locationElement}>
                            <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>Matrix location: </div>
                            <div>{props.panelItem.matrix.location}</div>
                        </div>
                        <hr style={{width:"350px"}} />
                        <div ref={notesElement}>
                            <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>Notes: </div>
                            <div>{props.panelItem.matrix.notes}</div>
                        </div>
                        <hr style={{width:"350px"}} />
                    </>
                    :
                    null}
                    <div className={globalStyles.panel} style={{height: descriptionHeight + "px", overflow: "auto"}}>
                    <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{item.name}</div>
                    <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px", lineHeight: "1"}}>
                        <div dangerouslySetInnerHTML={{__html: spell && spell.description}}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withContext(withFirebase(SpiritSpellTileContextMenu));