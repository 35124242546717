import React, {useEffect, useState} from 'react';
import ExperienceRow from './experienceRow';
import { FormControlLabel, Switch } from '@mui/material';

const OccupationalSkills = (props) => {

    const [occupationalSkills, setOccupationalSkills] = useState([]);
    const [occCount, setOccCount] = useState(4);
    const [occRolled, setoccRolled] = useState(false);
    const [skills, setSkills] = useState(props.character.skills);
    const [weapons, setWeapons] = useState(props.character.weaponSkills);
    const [display, setDisplay] = useState(false);
 
    useEffect(() => {
        setSkills(props.character.skills);
    },[props.character.skills])

    useEffect(() => {
        var occupationRolls = [];
        props.character.occupation.skills.forEach(skill => {
            var index = -1;
            if (skill.variable) {
                index = skills.findIndex(item => (item.name === skill.name && item.variant === skill.variant));
            }
            else {
                index = skills.findIndex(item => item.name === skill.name);
            }
            if (index > -1) {
                var characterSkill = skills[index];
                if (! characterSkill.hasSucceeded) {
                    occupationRolls.push({...characterSkill});
                }
            }
        })
        props.character.occupation.weaponSkills && props.character.occupation.weaponSkills.forEach(weaponSkill => {
            var index = weapons.findIndex(item => item.name === weaponSkill.name);
            if (index > -1) {
                var characterSkill = weapons[index];
                if (! characterSkill.hasSucceeded) {
                    occupationRolls.push({...characterSkill});
                }
            }
        })
        setOccupationalSkills(occupationRolls);
      }, []);
    
      useEffect(() => {
        const fetchData = async () => {
          setWeapons(props.character.weaponSkills);
        };
     
        fetchData();
      }, []);


    function processOccupational(gain, item, message){
        setOccCount(occCount -1);
        if (occCount == 1) {
            setoccRolled(true);
        }
        props.update(gain, item, message);
  }

      function handleChange(event) {
          setDisplay(event.target.checked);
      }

      return (
          <div>
            {
                
                occupationalSkills && occupationalSkills.length > 0 ?
                    <div id="occupationalSkills">
                        <div style={{backgroundColor: "#656565", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "44px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>
                            <div style={{width: "200px"}}>Occupational Skills</div>
                            <div style={{display:"flex", alignItems: "center"}}>
                                {/* <div style={{fontSize: "14px"}}>Show occupational skills</div> */}
                                <FormControlLabel style={{marginRight: "0px", marginLeft: "5px", fontSize:"14px"}} label="Display"
                                    control={<Switch checked={display} color="default" onChange={(event) => handleChange(event)} />}
                                />
                            </div>
                            {display ? 
                                <div style={{marginLeft: "20px", display:"flex"}}>
                                    <div>Remaining: </div>
                                    <div style={{marginLeft: "10px"}}>{occCount}</div>
                                </div>
                            : 
                            null
                            }
                        </div>
                        {
                            display ? 
                                <>
                                    <div style={{display:"flex", fontSize: "14px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                                        <div style={{width:"120px", marginLeft:"5px", textAlign:"left"}}>{props.title}</div>
                                        <div style={{width:"66px", textAlign:"center"}}>Current %</div>
                                        <div style={{width:"86px"}}>Roll 1D6</div>
                                        <div style={{width:"80px"}}></div>
                                    </div>
                                    {
                                        occupationalSkills.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <ExperienceRow diceRoller={props.diceRoller} occupational rolled={occRolled}  processExperience={processOccupational} key={item.name} item = {item}></ExperienceRow>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            : 
                            null
                        }
                    </div>
                : 
                null
            }

          </div>

      )
      
}

export default OccupationalSkills