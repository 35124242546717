import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import Button from '@mui/material/Button';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div>
                {this.state.isSent ? (
                  <div style={{marginTop: "100px", marginBottom: "20px", padding: "20px", border: "2px solid #2f588A", borderRadius: "5px"}}>
                    <div> E-mail confirmation sent: please check your E-Mails, and refresh this page once you have confirmed your E-Mail. </div>
                    <div style={{color: "#851113", marginTop: "10px", fontWeight: "bold"}}>If you don't see the confirmation e-mail, please check your spam folder.</div>
                  </div>
                ) : (
                  <div style={{marginTop: "100px", marginBottom: "20px", padding: "20px", border: "2px solid #2f588A", borderRadius: "5px"}}>
                    In order to complete sign-up you must first verify your E-Mail: Click the button below to request a verification E-Mail .
                  </div>
                )}

                <Button
                  color="primary" variant="contained"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Send confirmation E-Mail
                </Button>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
