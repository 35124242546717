import React, {useState, useEffect} from 'react'
import GiftList from '../../Lists/giftList'

const Gifts = (props) => {

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (props.cult.selectedGifts) {
            setSelected([...props.cult.selectedGifts]);
            props.isComplete(props.cult.selectedGifts.length >= 1)
        }
    },[props.cult.selectedGifts])

    function selectGift(gift, checked) {
        var updatedSelected = [...selected]
        if (checked) {
            var index = updatedSelected.findIndex(item => item.id === gift.id);
            if (index < 0 ) {
                updatedSelected.push({...gift});
            }
            else {
                updatedSelected[index] = {...gift};
            }
        } 
        else {
            var index = updatedSelected.findIndex(item => item.id === gift.id);
            updatedSelected.splice(index, 1);
        }
        setSelected(updatedSelected);
        props.update(updatedSelected);
        props.isComplete(updatedSelected.length >= 1)

    }

    return (
        <div>
            <GiftList cult={props.cult} max={props.cult.maxGifts || 1} select={selectGift} selectedGifts={selected} />
        </div>
    )
}

export default Gifts