export const dice_initialize = (container, setResults) => {

    try {

        const canvas = $t.id('canvas');
        canvas.style.width = window.innerWidth - 1 + 'px';
        canvas.style.height = window.innerHeight + 'px';
        const label = $t.id('label');
        const set = $t.id('set');
        const selector_div = $t.id('selector_div');
        const info_div = $t.id('info_div');
        on_set_change();

        $t.dice.use_true_random = false;

        function on_set_change(ev) {
            // set.style.width = set.value.length + 3 + 'ex';
        }
        $t.bind(set, 'keyup', on_set_change);
        $t.bind(set, 'mousedown', (ev) => ev.stopPropagation());
        $t.bind(set, 'mouseup', (ev) => ev.stopPropagation());
        $t.bind(set, 'focus', (ev) => $t.set(container, { class: '' }));
        $t.bind(set, 'blur', (ev) => $t.set(container, { class: 'noselect' }));

        const params = $t.get_url_params();

        if (params.chromakey) {
            $t.dice.desk_color = 0x00ff00;
            info_div.style.display = 'none';
            $t.id('control_panel').style.display = 'none';
        }
        if (params.shadows === 0) {
            $t.dice.use_shadows = false;
        }
            $t.dice.dice_color = '#1e3475';
            $t.dice.label_color = '#b3722a';
        if (params.color === 'white') {
        }

        var box = new $t.dice.dice_box(canvas, { w: 500, h: 300 });
        box.animate_selector = false;

        $t.bind(window, 'resize', () => {
            canvas.style.width = window.innerWidth - 1 + 'px';
            canvas.style.height = window.innerHeight + 'px';
            box.reinit(canvas, { w: 500, h: 300 });
        });

        const show_selector = () => {
            info_div.style.display = 'none';
            selector_div.style.display = 'inline-block';
        };

        const before_roll = (vectors, notation, callback) => {
            info_div.style.display = 'none';
            selector_div.style.display = 'none';
            callback();
        };

        const notation_getter = () => $t.dice.parse_notation(set.value);

        const after_roll = (notation, result) => {
            let diceResultsArr = {};
            let prevItem = null;
            let counterkey = 0;
            notation.set.map((item, index) => {
                if (item === prevItem) {
                    counterkey++;
                    let itemWithKey = `${item}_${counterkey}`;
                    diceResultsArr[itemWithKey] = result[index];
                }
                else {
                    counterkey = 0;
                    prevItem = item;
                    diceResultsArr[item] = result[index];
                }
            })

            setResults(diceResultsArr);

            if (params.chromakey || params.noresult) return;
            let res = result.join(' ');
            if (notation.constant) {
                if (notation.constant > 0) res += ' +' + notation.constant;
                else res += ' -' + Math.abs(notation.constant);
            }
            if (result.length > 1) res += ' = ' + (result.reduce((s, a) => s + a) + notation.constant);
            label.innerHTML = res;
            info_div.style.display = 'inline-block';
        };

        // box.bind_mouse(container, notation_getter, before_roll, after_roll);
        box.bind_throw($t.id('throw'), notation_getter, before_roll, after_roll);

        // $t.bind(container, ['mouseup', 'touchend'], (ev) => {
        //     ev.stopPropagation();
        //     if (selector_div.style.display === 'none') {
        //         if (!box.rolling) show_selector();
        //         box.rolling = false;
        //         return;
        //     }
        //     const name = box.search_dice_by_mouse(ev);
        //     if (name !== undefined) {

        //         setDiceData((prevState) => ({
        //             ...prevState,
        //             [name]: prevState[name] + 1,
        //         }));

        //         const notation = $t.dice.parse_notation(set.value);
        //         notation.set.push(name);
        //         // setData(name)
        //         set.value = $t.dice.stringify_notation(notation);
        //         on_set_change();
        //     }
        // });

        if (params.notation) {
            set.value = params.notation;
        }
        if (params.roll) {
            $t.raise_event($t.id('throw'), 'mouseup');
        } else {
            show_selector();
        }

    }
    catch (error) {
        
    }
};
