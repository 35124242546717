import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';
import BloodDrop from "../../icons/blood-drop.svg";
import DialogButtons from '../Dialogs/dialogButtons';

export default function SpiritCombatDamageDialog(props) {

	const [damage, setDamage] = React.useState('');
	const [ignoreArmour, setIgnoreArmour] = useState(false);

	function handleDamageChange(event) {
		if (event.target.value) {
		setDamage(parseInt(event.target.value));
		}
		else {
		setDamage('')
		}
	}

	function handleOkClick(){
		var change = (damage || 0);
		props.close();
		props.update(change, ignoreArmour);
	}

	function handleCancelClick(){
		props.close();
	}

	function changeArmour() {
		setIgnoreArmour(!ignoreArmour);
	}

	const style = {
		borderRadius: 5,
		boxSizing:"border-box",
		border: "0px",
		color: "#121312",
		backgroundColor:"#fff",
		width:"80px"
	};

		return (
			<div >
				<div style={{padding: "20px"}}>
					<div>Armor: </div>
					<div style={{display:"flex", padding: "10px 0px"}}>
						<div>Enchantments:&nbsp;</div>
						{props.enchantments && props.enchantments.map((enchantment, index) => {
							return (
								<div key={index} style={{display:"flex"}}>
									{enchantment.name}{index < props.enchantments.length -1 ? <div>,&nbsp;</div> : null}
								</div>
							)
						})}
					</div>
				</div>
				<form>
					<hr style={{width: "90%"}} />
					<div style={{display:"flex", alignItems:"center", justifyContent:"space-between", padding:"20px"}}>
						<div style={{color:"#851113", fontSize: "20px", fontWeight:"bold", display:"flex", width:"140px", alignItems:"center", justifyContent:"space-between"}}>
							<img src={BloodDrop} style={{height: "36px"}} />
							<div>Damage</div>

						</div>
						<FormGroup>
							<FormControlLabel control={<Switch color="primary" checked={ignoreArmour} onChange={changeArmour}></Switch>} label="Ignore armour"></FormControlLabel>
						</FormGroup>
						
						<TextField style={{...style, border: "2px solid #851113"}} type="number" autoFocus variant="outlined" size='small' value={damage} onChange={handleDamageChange} 
							color="primary">{damage}
						</TextField>
					</div>
					<DialogButtons style={{borderTop: "2px solid #2f588a"}} handleOkClick={handleOkClick} handleCancelClick={handleCancelClick} title="Ok" />
				</form>
			</div>
		);
}