import React, {useState, useEffect} from "react";
import EditBox from "../../Controls/editBoxSingle";
import globalStyles from '../../Styles/styles.module.css'
import styles from '../encounters.module.css'
import * as SAVE from '../../../constants/save';
import DiceButton from '../../Buttons/diceButton';
import { withPageState } from "../../Context/PageState";

const StatTile = (props) => {

    const [value, setValue] = useState(0)
    const [hover, setHover] = useState(false);

    useEffect(() => {
        setValue(props.value);
    },[props.item]);
    
    function handleValueChange(value) {
        var updatedItem = {...props.item};
        updatedItem.baseValue = value;
        props.update(updatedItem, SAVE.NO);
    }

    const enter = () => {
        var updatedValue = (props.value) * 5;
        setHover(true)
        setValue(updatedValue)
    }

    const leave = () => {
        setHover(false);
        setValue(props.item.baseValue + (props.item.statBonus || 0));
    }

    function displayItem() {
        props.displayItem({type: "Stat", item: props.item})
    }

    if (props.pageState && props.pageState.editMode) {
        return (
            <div style={{display:"flex", alignItems:"center", justifyContent: "flex-end", padding: "1px", width: "80px", marginLeft: "5px"}}>
                <EditBox size="sm" label={props.item.name +":"} labelMargin="4px" onChange={(value) => handleValueChange(value)} value={props.item.baseValue} width="35px" number>{props.item.baseValue}</EditBox>
            </div>
        )
    }
    else {
        return (
            <div style={{display:"flex", width: "55px"}} onMouseEnter={enter} onMouseLeave={leave}>
                <div className={globalStyles.expandableTile} style={{display:"flex", fontSize: "13px", alignItems: "center", cursor: "zoom-in"}} onClick={displayItem} >{props.item.name}:</div>
                    <div className={styles.diceButton} style={{display:"flex", width: "55px"}} onMouseEnter={enter} onMouseLeave={leave}>
                        <DiceButton type="Stat" diceRoller={props.diceRoller} item={props.item} processResult={props.processResult} target={props.value * 5} entity={props.entity} >
                            <ButtonValue />
                        </DiceButton>
                    </div>
                </div>
            )
    }

    function ButtonValue() {
        return (
            <div style={{display:"flex"}}>
                <div>{hover ? props.value * 5 +"%" : props.value},&nbsp;</div>
            </div>
        )
    }
}

export default withPageState(StatTile);