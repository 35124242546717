import React, { useState, useEffect } from 'react';
import { CssBaseline, Typography, Container, Paper, Button } from '@mui/material';
import globalStyles from '../Styles/styles.module.css'
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import CharacterCard from '../Characters/characterCard';
import { useNavigate } from "react-router-dom";
import DialogBox from '../Dialogs/dialogBox';
import { useParams } from 'react-router-dom';

const InvitePage = (props) => {

	const navigate = useNavigate();
    const params = useParams()

    const[dialog, setDialog] = useState(false);
    const[success, setSuccess] = useState(false);
    const[campaign, setCampaign] = useState();
    const[characters, setCharacters] = useState();
    const[selectedCharacter, setSelectedCharacter] = useState();
    const [uid, setUid] = useState("");

        
    useEffect(() => {
        document.title = "RuneQuesting: Invite"
    },[]);

    useEffect(() => {
      var uid = params.uid;
      setUid(uid);
  }, [params]);

    useEffect(() => {
		if (uid) {
			props.firebase
			.campaign(uid)
			.get()
			.then((doc) => {
				if (doc.exists) {
				var selectedCampaign = doc.data();
				selectedCampaign.id = doc.id;
				setCampaign(selectedCampaign);
				} 
			});
		}
      }, [uid]);

	  useEffect(() => {
		if (campaign) {
			getCharacters()
		}
	  },[campaign])
        
      function getCharacters(){

        var characters = [];
        var currentRequests = [];

        props.firebase
        .requests()
        .where("campaignId", "==", campaign.id)
        .get()
        .then((requestCollection) => {
            requestCollection.forEach((requestItem) => {
                var request = requestItem.data();
                request.id = requestItem.id;
                currentRequests.push(request);
            });
            props.firebase
            .characters()
            .where("uid", "==", props.authUser.uid)
            .get()
            .then(querySnapshot => 
            {
            querySnapshot.forEach(function(doc) {
                const id = doc.id;
                const data = doc.data();
                var character = {id, ...data}
                var existingRequests = currentRequests.filter(function(e) {
                    return (e.pcid === character.id)
                });
                if (! existingRequests || existingRequests.length == 0){
                    if (! character.campaignId || character.campaignId === character.id) {
                        characters.push(character);
                    }
                }
            });
            setCharacters(characters);
            });
        });       
      }

      function handleDialogClose() {
        setDialog(false);
    }

    function handleSuccessClose() {
        navigate("/characters/");
    }

      function openDialog(event, character) {
          setSelectedCharacter(character);
          setDialog(true);
      }

      function confirmRequest(){
          var ts = new Date(Date.now());
        props.firebase
        .requests()
        .add({
            playerId: selectedCharacter.uid,
            pcid: selectedCharacter.id,
            campaignId: campaign.id,
            characteristics: selectedCharacter.characteristics,
            requestDateTime: ts,
            name: selectedCharacter.name,
            occupation: selectedCharacter.occupation,
            profileImage: selectedCharacter.profileImage,            
        });
        setDialog(false);
        setSuccess(true);
      }

      if (campaign) {

	return (
		<div id="campaignPage" style={{width: "100%", height: "100%"}}>
					{characters ? 
					<>
						<div style={{display: "flex", backgroundColor: "#2f588a", justifyContent: "center", alignItems: "center", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", position: "relative"}}>
							<div style={{width: "1200px", position: "relative", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", flexDirection: "column", marginTop: "50px", minHeight: "calc(100vh - 50px", height: "100%", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
								<div style={{padding: "10px"}}>
									<h1>You have been invited to join the following campaign</h1>
									<div>
										<h2>{campaign.name}</h2>
										<h3>{campaign.description}</h3>
									</div>
									<div>
										Select the character you wish to add to this campaign
									</div>
									<div className={globalStyles.horizontalLine}></div>
									<div >
										<div  style={{display:"flex", flexWrap:"wrap", overflow:"hidden", }}>
										{characters.map((character) => {
											return (
											<div style={{padding: "10px"}} key={character.id}>
												<a href='#' onClick={(event) => openDialog(event, character)}>
													<CharacterCard character={character}></CharacterCard>
													</a>
											</div>
											)
										})}
										</div>
									</div>                
								</div>
							</div>                
						</div>                
					</>
				:
					null
									}
					
			{
				selectedCharacter ? 
				<div>
				<DialogBox width="500px" isOpen={dialog} handleClose={handleDialogClose} title='Confirm character'>
					<div style={{padding: "10px"}}>
						<h3>You are requesting that {selectedCharacter.name.first + (selectedCharacter.name.last ? " " + selectedCharacter.name.last : "")} join the following campaign</h3>
						<h3>{campaign.name}</h3>
						<div style={{display:"flex", flexDirection:"row-reverse"}}>
							<Button style={{marginLeft:"30px",marginBottom:"10px", width: "160px"}} onClick={confirmRequest} color="primary" variant="contained">Confirm</Button>
							<Button style={{marginBottom:"10px", width: "160px"}} onClick={handleDialogClose} color="primary" variant="outlined">Cancel</Button>
						</div>
					</div>
				</DialogBox>
					<DialogBox width="400px" isOpen={success} style={{transition: "3s ease-out"}} subtitle='' title=''>
					<div style={{padding: "10px"}}>
						<h3>Request sent</h3>
						<div style={{display: "flex", flexDirection: "row-reverse"}}>
							<Button style={{marginBottom:"10px", width: "100px"}} onClick={handleSuccessClose} color="primary" variant="contained">Ok</Button>
						</div>
					</div>
				</DialogBox>
				</div>
	: 
				null
			}
		</div>
	);
                        }
                        else {
                            return null;
                        }
        }

export default withFirebase(withEmailVerification(withAuthorization(InvitePage)));
