import React, { Component } from 'react';
import styles from './messages.module.css';

class MessageItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message, isGM } = this.props;

    var nameColour = "#fff"
    var messageColour = "#fff";
    var background="#232323";
    // var messageText = message.hidden ? message.hiddenText : message.text;

    function colorMessage(result) {
      switch(result){
        case "Critical":
          messageColour = "#2F588A";
          nameColour = "#2F588A";
          background = "#fff"
          break;
        case "Special":
          nameColour = "#fff";
          messageColour = "#fff";
          background = "#2F588A"
          break;
        case "Success":
          nameColour = "#2F7589";
          break;
        case "Failure":
          nameColour = "#a71d21";
          break;
        case "Fumble":
          messageColour = "#fff";
          nameColour = "#fff";
          background = "#851113"
          break;
        case "Damage":
          nameColour="#b3722a";
          break;
        default:
          messageColour = "#a71d21";
          nameColour = "#a71d21";
          background = "#fff"
      }
    }

    if (message.hidden) {
      if ( this.props.isGM) {
        colorMessage(message.result);
      }
      else {
        messageColour = "#b3722a";
        nameColour = "#b3722a";
      }
    }
    else {
      colorMessage(message.result);
    }

    return (
      <div style={{borderBottom: "1px solid #c4c4c4", padding:"5px", backgroundColor:background}} className={this.props.isLatest ? styles.currentEvent : null}>
        <span style={{color: nameColour}}><strong>{message.characterName} ({message.firstName}) </strong></span>
        {message.hidden && (!isGM) ? 
            <span style={{color:messageColour}}>{message.hiddenText}
            </span>
          : 
            <span style={{color:messageColour}}>{message.text}
            </span>
          }
      </div>
    )
    /* 
          <li>
              <span style={{color:nameColour, backgroundColor:background}}>
                <strong>
                  {message.characterName} ({message.firstName})
                </strong>{' '}</span>
                <span style={{color:messageColour, backgroundColor:background}}>{message.text} {message.editedAt && <span>(Edited)</span>}
              </span>
          </li> */
        // );
      // }
    }
  }

export default MessageItem;
