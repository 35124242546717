import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import { withContext } from "../../Context/context";
import EditBox from "../../Controls/editBoxSingle";
import AddItemButton from "../buttons/addItemButton";
import ManageRuneSpellDialog from "../../Magic/manageRuneSpellDialog";

const CultHeaderBar = (props) => {

    const [points , setPoints] = useState(0);

    useEffect(()=> {
        if (props.cult) {
            var cultIndex = props.providerData.character.cults.findIndex(obj => obj.parentId === props.cult.parentId);
            props.providerData.character.cults[cultIndex].runePoints.forEach (runePoints => {
                if (runePoints.entityId === props.entity.id) {
                    setPoints(runePoints.total);
                }
            })
        }
    },[props.cult])

    return (
        <div style={{backgroundColor: "#2F7589", padding: "0px 5px", color: "#fff", height: "24px", fontSize: "14px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between"}}>
            <div style={{fontWeight:"bold"}}>{props.providerData.character.cults ? props.cult.name : "Cult not yet selected"}</div>
            <div style={{display: "flex", alignItems: "center"}}>
                <div>Rune pts:&nbsp;</div>
                {props.pageState.editMode && props.entity.id === props.providerData.character.id ?
                    <div style={{width: "50px"}}><EditBox number style={props.buttonStyle} value={props.cult.runePoints[0].total} onChange={(value) => props.handleValueChange(value, "runePoints")}  /> </div>
                : 
                    <div>{points}</div>
                }
            </div>
                {props.pageState.editMode && props.entity.id === props.providerData.character.id ?
                    <div style={{width: "30px"}}>
                            <AddItemButton edit addTitle="Edit rune spells" title="Manage rune spells" width="800px">
                                <ManageRuneSpellDialog character={props.providerData.character} cult={props.cult} storage={props.storage} setSpells={props.setSpells} />
                            </AddItemButton>
                    </div>
                : 
                null}
        </div>
    )
}

export default withPageState(withContext(CultHeaderBar))