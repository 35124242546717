import React, {useState, useEffect, useRef} from 'react';
import {withContext} from '../Context/context';
import { withPageState } from '../Context/PageState';
import CloseIcon from '../../icons/close-icon.svg';
import { Button, Checkbox } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import AddEnchantmentDialog from './addEnchantmentDialog';
import * as SAVE from '../../constants/save';
import ApplySpiritSpellDialog from './applySpiritSpellDialog';
import { calculateDamageBonus, calculateMaxEncumbrance, calculateSpiritCombatDamage, calculateStrikeRanks } from '../../services/attributeService';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateHitPoints } from '../../services/hitPointsService';
import { isEqual } from 'lodash';
import ApplyRuneSpellDialog from './applyRuneSpellDialog';

const EnchantmentsContextPanel = (props) => {

    const [enchantments, setEnchantments] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [open, setOpen] = useState("");

    useEffect(() => {
        var currentEnchantments = [...props.character.enchantments];
        if (enchantments.length === 0) {
            for(const element of currentEnchantments) {
                element.valid = true;
            } 
        }
        setEnchantments(currentEnchantments)
    }, [props.character.enchantments]);

    const update = (updatedEnchantments) => {
        var updates = [];
        var updatedCharacteristics = {...props.providerData.character.characteristics};
        var statsUpdated = false;

        updatedEnchantments.forEach(selection => {
            if (!selection.valid ) {
                if (selection.name === "Despondency") {
                    updates.push({fieldName: "despondent", value: false});
                }
                selection.effects.forEach(effect => {
                    var statName = effect.item && effect.item.id
                    if (statName && effect.name === "statModifier") {
                        statsUpdated = true;
                        var index = updatedCharacteristics[statName].enchantments.findIndex(item => item.name === selection.name);
                        if (index > -1) {
                            updatedCharacteristics[statName].enchantmentBonus -= effect.value;
                            updatedCharacteristics[statName].enchantments.splice(index, 1);
                        }
                    }
                    if (statName && effect.name === "statMultiplier") {
                        statsUpdated = true;
                        var index = updatedCharacteristics[statName].enchantments.findIndex(item => item.name === selection.name);
                        if (index > -1) {
                            updatedCharacteristics[statName].enchantmentBonus -= (updatedCharacteristics[statName].baseValue * (effect.value - 1));
                            updatedCharacteristics[statName].enchantments.splice(index, 1);
                        }
                    }
                    if (effect.name === "addWeapon") {
                        var updatedWeapons = [...props.providerData.character.weapons];
                        var index = updatedWeapons.findIndex(obj => obj.id === effect.item.id);
                        updatedWeapons[index].hidden = true;
                        updates.push({fieldName: "weapons", value: updatedWeapons})
                    }
                })
            }
        });
        var finalEnchantments = [];
        updatedEnchantments.forEach(item => {
            if (item.valid) {
                finalEnchantments.push(item)
            }
        })
        updates.push({fieldName: "enchantments", value: finalEnchantments})

        if (statsUpdated) {
            updates.push({fieldName: "characteristics", value: updatedCharacteristics});

            var newDamageBonus = calculateDamageBonus(updatedCharacteristics)
            if (! isEqual(props.providerData.character.damageBonus, newDamageBonus)) {
                updates.push({fieldName: "damageBonus", value: newDamageBonus})
            }

            var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
            if (! isEqual(props.providerData.character.categoryModifiers, updatedModifiers)) {
                updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
            }

            var updatedHitPoints = calculateHitPoints(updatedCharacteristics, props.providerData.character.hitPoints);
            if (! isEqual(props.providerData.character.hitPoints, updatedHitPoints)) {
                updates.push({fieldName: "hitPoints", value: updatedHitPoints})
            }

            var updatedStrikeRanks = calculateStrikeRanks(updatedCharacteristics);
            if (! isEqual(props.providerData.character.strikeRanks, updatedStrikeRanks)) {
                updates.push({fieldName: "strikeRanks", value: updatedStrikeRanks})
            }

            var updatedSpiritCombat = {...props.providerData.character.spiritCombat};
            updatedSpiritCombat.damage = calculateSpiritCombatDamage(updatedCharacteristics);
            if (! isEqual(props.providerData.character.spiritCombat, updatedSpiritCombat)) {
                updates.push({fieldName: "spiritCombat", value: updatedSpiritCombat})
            }
            
            var updatedEncumbrance = calculateMaxEncumbrance(updatedCharacteristics);
            if (! isEqual(props.providerData.character.maxEnc, updatedEncumbrance)) {
                updates.push({fieldName: "maxEnc", value: updatedEncumbrance});
            }
        }
        props.update(updates, SAVE.YES);

    };

    function handleCheck(event, index) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments[index].valid = event.target.checked;
        setEnchantments(updatedEnchantments);
        update(updatedEnchantments)
    }

    function displayEnchantment(item, index) {
        var target = item.target;
        if (["skill", "passion", "rune", "weapon"].indexOf(item.class) > -1 ) {
            var key = props.providerData.character[item.class + "s"].findIndex(obj => obj.id === item.target);
            if (key > -1) {
                var details = props.providerData.character[item.class + "s"][key];
                target = details.name + (details.variant ? " " + details.variant : "");
            }
        }
        return (
            <div key={index} style={{display:"flex", alignItems: "center"}}>
                <div style={{width:"20%"}}>
                    <Checkbox
                        checked={item.valid}
                        color="default"
                        onClick={(event) => handleCheck(event, index)}
                    />
                </div>
                <div style={{width:"40%", marginRight: "20px"}}>
                    {item.name}
                </div>
                <div style={{width:"40%"}}>
                    {item.target}
                    {/* {(item.itemName || "Character") + (item.target === "Skill" ? " skill" : item.target === "Passion" ? " passion" : item.target === "Rune" ? " rune" : item.target === "Characteristic" ? " characteristic" : "")} */}
                </div>
            </div>
        )
    }

    // function selectAllEnchantments(event) {
    //     setEnchantments([]);
    //     var updates = [{fieldName: "enchantments", value: []}]
    //     props.update(updates, SAVE.YES)
    // }

    function addEnchantment(newEnchantment, updatedCharacteristics, updatedModifiers) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments.push(newEnchantment);
        var updates = [{fieldName: "enchantments", value: updatedEnchantments}];
        if (updatedCharacteristics) {
            updates.push({fieldName: "characteristics", value: updatedCharacteristics});
        }
        if (updatedModifiers) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers})
        }
        props.update(updates, SAVE.YES)
    }

    return (
        <>
            <div style={{position:"relative", height: "100%", display: "flex", flexDirection:"column", justifyContent: "space-between", padding: "10px"}}>

                <div>
                    <div>All magical effects in RuneQuesting, whether positive or negative, are referred to as "Enchantments". Whenever a spell is cast on a character that has a mechanical effect on that character, it will appear here.</div>
                    <div style={{marginTop: "15px"}}>To manually add an enchantment to your character, click one of the following buttons. To remove an enchantment from your character, simply deselect it from the list below.</div>
                    <hr style={{marginTop: "20px"}} />
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}>
                        <Button style={{margin: "5px", marginLeft: "0px", minWidth: "104px"}} color="primary" variant='contained' onClick={() => setOpen("spirit")}>Apply Spirit Spell</Button>
                        <Button style={{margin: "5px", minWidth: "104px"}} color="primary" variant='contained' onClick={() => setOpen("rune")}>Apply Rune Spell</Button>
                        <Button style={{margin: "5px", minWidth: "104px"}} color="primary" variant='contained' onClick={() => setOpen("custom")}>Add Custom Effect</Button>
                        {/* <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                            <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                            <Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"7px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
                        </div> */}
                    </div>
                    <hr style={{marginTop: "20px"}} />
                    <div style={{fontWeight: "bold", marginBottom: "10px"}}>Current Enchantments</div>
                {enchantments && enchantments.length > 0 ? 
                    <>
                        {/* <div style={{display:"flex", alignItems:"center"}}>
                            <Checkbox checked={selectAll} onClick={selectAllEnchantments}
                            />
                            <div>Select/Deselect all</div>
                        </div> */}
                        <div style={{fontWeight: "bold", display: "flex", alignItems: "center"}}>
                            <div style={{width: "20%"}}></div>
                            <div style={{width: "40%", marginRight: "20px"}}>Name</div>
                            <div style={{width: "40%"}}>Target</div>
                        </div>
                        <div style={{marginBottom: "50px"}}>
                            {enchantments.map((item, index) => {
                                return (displayEnchantment(item, index))
                            })}
                        </div>
                    </>
                    : 
                        <div>{props.providerData.character.name.first} is not currently affected by any enchantments.</div>
                    }
                </div>
            </div>
            <DialogBox isOpen={open === "custom"} handleClose={() => setOpen("")} width="600px" title="Add Enchantment">
                <AddEnchantmentDialog character={props.providerData.character} update={addEnchantment} close={() => setOpen("")} />
            </DialogBox>
            <DialogBox isOpen={open === "spirit"} handleClose={() => setOpen("")} width="600px" title="Apply Spirit Spell">
                <ApplySpiritSpellDialog character={props.providerData.character} update={props.update} close={() => setOpen("")} />
            </DialogBox>
            <DialogBox isOpen={open === "rune"} handleClose={() => setOpen("")} width="600px" title="Apply Rune Spell">
                <ApplyRuneSpellDialog character={props.providerData.character} update={props.update} close={() => setOpen("")} />
            </DialogBox>
        </>

    )
}
export default withContext(withPageState(EnchantmentsContextPanel))