import React from 'react';
import {withContext} from '../Context/context';
import { calculateDamageBonus } from '../../services/attributeService';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateMaxEncumbrance } from '../../services/attributeService';
import { isEqual } from 'lodash';

const StrengthTile = (props) => {

    function update(characteristic, save) {

        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.STR = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var newDamageBonus = calculateDamageBonus(updatedCharacteristics)
        if (! isEqual(props.entity.damageBonus, newDamageBonus)) {
            updates.push({fieldName: "damageBonus", value: newDamageBonus})
        }

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedEncumbrance = calculateMaxEncumbrance(updatedCharacteristics);
        if (! isEqual(props.entity.maxEnc, updatedEncumbrance)) {
            updates.push({fieldName: "maxEnc", value: updatedEncumbrance});
        }
        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(StrengthTile)