import React, { useState, useEffect, useRef } from 'react';
import { getStatValue } from '../../services/attributeService';
import { withPageState } from '../Context/PageState';

const CharacteristicTile = (props) => {
    const [item, setItem] = useState();
    const [value, setValue] = useState(0);
    const ref = useRef();

    useEffect(() => {
        setItem({...props.stat, id: props.stat.name});
    },[props.stat])

    useEffect(() => {
        if (item) {
            var value = getStatValue(item);
            setValue(value);
        }

    },[item])

    if (item) {
        if (props.pageState && props.pageState.editMode) {
            return (
                <>
                    {React.cloneElement(props.tile, {...props, item: item, value: value})}
                </>  
            )
        }
        else {
            return (
                <>
                    {React.cloneElement(props.tile, {...props, item: item, value: value})}
                </>  
            )            
        }
    }
    else {
        return null;
    }      
}

export default withPageState(CharacteristicTile);