import React, { useState, useEffect } from 'react';
import styles from '../character.module.css';
import { withPageState } from '../../Context/PageState';
import { withContext } from '../../Context/context';
import * as SAVE from '../../../constants/save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getAvailableMagicPoints} from '../../../services/magicPointsService';
import {incrementAvailableMagicPoints} from '../../../services/magicPointsService';
import {decrementAvailableMagicPoints} from '../../../services/magicPointsService';

const MagicPoints = (props) => {

    const [magicPoints, setMagicPoints] = useState({current:0, total:0});

    useEffect(() => {
        var points = getAvailableMagicPoints(props.providerData.character);
        if (points.current !== magicPoints.current || points.total !== magicPoints.total) {
            setMagicPoints(points);
        }
    },[props.providerData.character.magicPoints])

    function modifyMagicPoints(increment) {
        var updatedCharacter = {...props.providerData.character};
        var updates = [];
        if (increment && magicPoints.current < magicPoints.total) {
            updates = incrementAvailableMagicPoints(updatedCharacter, 1)
            var updatedMagicPoints = {...magicPoints};
            updatedMagicPoints.current ++; 
            setMagicPoints(updatedMagicPoints);
        }
        else if (!increment && magicPoints.current > 0) {
            var updatedMagicPoints = {...magicPoints};
            updatedMagicPoints.current --; 
            setMagicPoints(updatedMagicPoints);
            updates = decrementAvailableMagicPoints(updatedCharacter, 1)
        }
        props.update(updates, SAVE.DELAYED)
    }

    if (magicPoints) {
        return (
            <div style={{justifyContent: "center", display:"flex", alignItems: "center"}}>
            <div>Magic Pts:&nbsp;</div>
            <div style={{display: "flex", alignItems:"center"}}>
                    <div>{magicPoints.current}</div>
                    <div>/{magicPoints.total}</div>
                    <div style={{display:"flex", marginTop: "5px"}}>
                        <div style={{margin: "0px 5px"}} >
                            <RemoveCircleOutlineIcon style={{height: "18px", width:"18px", cursor: "pointer"}} onClick={() => modifyMagicPoints(false)}/>
                        </div>
                        <div>
                            <AddCircleOutlineIcon style={{height: "18px", width:"18px", cursor: "pointer"}} onClick={() => modifyMagicPoints(true)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null;
    }
}

export default withPageState(withContext(MagicPoints));