import React, {useEffect,  useState } from 'react';
import HomelandCards from './homelandCards';
import DialogBox from '../../Dialogs/dialogBox';
import SelectTribeDialog from '../dialogs/selectTribeDialog';

const Homeland = (props) => {
    const [size, setSize] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.builder && props.builder.homeland) {
            props.disableNext(false);
        }
        if (props.size){
            setSize(props.size)
        }
        else {
            setSize({
                width: "360px",
            })
        }
    }, [props.builder.homeland]);

    function handleSelect(homeland) {
        var updatedBuilder = {...props.builder};
        updatedBuilder.homeland = homeland;
        updatedBuilder.passions = [...homeland.passions];
        if (homeland.name === "Prax") {
            setOpen(true);
        }
        else {
            updatedBuilder.tribe = {};
        }
        props.disableNext(false);
        props.update(updatedBuilder, false);
    }

    function selectTribe(tribe) {
        var updatedHomeland = {...props.builder.homeland};
        updatedHomeland.skills = tribe.skills;
        updatedHomeland.weapons = tribe.weapons;
        updatedHomeland.occupations = [...props.builder.homeland.occupations, ...tribe.occupations];
        updatedHomeland.characteristics = tribe.characteristics ? tribe.characteristics : [];
        updatedHomeland.rune = tribe.rune;
        updatedHomeland.title += " " + tribe.name
        var updatedBuilder = {...props.builder};
        updatedBuilder.homeland = updatedHomeland;
        updatedBuilder.tribe = tribe;
        props.disableNext(false);
        setOpen(false);
        props.update(updatedBuilder, false);
    }

    return (
        <div>
            <HomelandCards homelands={props.homelands} builder={props.builder} size={size} handleSelect={handleSelect} selected={props.builder.homeland} tribe={props.builder.tribe} />
            {props.builder.homeland ?
                <div style={{padding: "20px"}}>
                    <p>{props.builder.homeland.description}</p>
                    <p>{props.builder.homeland.stereotype}</p>
                    <p>{props.builder.homeland.attitude}</p>
                </div>
            :
            null}
            {open ? 
                <DialogBox isOpen={open} title="Select Tribe" width="600px">
                    <SelectTribeDialog homeland={props.builder.homeland} tribe={props.builder.tribe} close={()=> setOpen(false)} select={selectTribe}></SelectTribeDialog>
                </DialogBox>
            : 
            null
            }
        </div>
    );
}

export default Homeland;