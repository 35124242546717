import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { withFirebase } from '../Firebase';
import DeleteIcon from '../../icons/delete.svg';
import ResultsDialog from '../Dialogs/dialogBox';
import ConfirmDeleteDialog from '../Dialogs/confirmDeleteDialog';

 const EncounterCard = (props) => {
  const[encounter, setencounter] = React.useState(props.encounter);
  const [dialog, setDialog] = useState(false);

  if (encounter) {
  var link = "/encounter/" + encounter.id;

  var imageLink = '/images/profiles/' + encounter.profileImage
  var cultName = "";
  if (encounter.cults && encounter.cults.length > 0) {
      encounter.cults.forEach((cult) => {
          if (cult.name !== "Common Rune Spells") {
              if (cultName) {
                  cultName += " & " + cult.name;
              }
              else {
                  cultName = cult.name;
              }
          }
      })
  }


  function openDialog(event) {
    event.preventDefault();
    event.stopPropagation();
    setDialog(true);
  }

  function handleDialogClose(result) {
    setDialog(false);
  }

  function deleteEncounter(event) {
    event.preventDefault();
    event.stopPropagation();
    props.deleteEncounter(encounter.id);
    setDialog(false);
  }

  return (
    <Card style={{marginTop:"30px", display: "flex"}}>
      <div style={{width: "300px", display: "flex", flexDirection: "column"}} >
        <CardContent style={{flex: '1 0 auto'}}>
          <Typography component="h5" variant="h5">
            {encounter.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          {encounter.count} {encounter.experience} {encounter.template}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
          {encounter.description}
          </Typography>
        </CardContent>
        {
          props.showDelete ? 
          <div>
          <div style={{display:"flex", flexDirection: "column-reverse", alignItems:"end", padding:"10px"}}><img onClick={openDialog} style={{width:"20px"}} src={DeleteIcon}></img></div>
          <ResultsDialog isOpen={dialog} handleClose={handleDialogClose} subtitle='' title=''>
            <ConfirmDeleteDialog itemName={encounter.name} innerHTML={props.innerHTML} type="Encounter" delete={deleteEncounter} closeDialog={handleDialogClose}></ConfirmDeleteDialog>
          </ResultsDialog>
          </div>
          :null
        }
      </div>
      <CardMedia
      style={{height:"240px", width:"240px"}}
        image={imageLink}
        title={encounter.name}
      />
    </Card>
  );

  }
  else {return null;}
}

export default withFirebase(EncounterCard);
