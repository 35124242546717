import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';
import PoweredCrystal from './poweredCrystal';

const CultSpiritPanel = (props) => {

    const [crystals, setCrystals] = useState([]);

    useEffect(() => {
        if (props.providerData.character) {
            setCrystals([]);
        }
    },[props.providerData.character])

    function deleteCrystal(item) {
        var updatedCrystals = [...crystals];
        var index = updatedCrystals.findIndex(crystal => crystal.id === item.id);
        updatedCrystals.splice(index, 1)
        setCrystals(updatedCrystals);
        props.update([{fieldName: "crystals", value: updatedCrystals}], SAVE.NO)
    }

    return (
        <div style={{border: "2px solid #2F588A", borderRadius: "5px", height: "150px", marginLeft: "5px", marginTop: "10px", overflow: "hidden"}}>
            <div id="header" style={{background: "rgb(47,88,138)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "10px", textAlign: "center", color: "#fff"}}>Cult Spirits & Ancestors</div>
            {crystals && crystals.length > 0 ? 
                crystals.map((item, index) => {
                    return (
                        
                        <PoweredCrystal character={props.providerData.character} delete={deleteCrystal} key={index} crystal={item} availableMagicPoints={props.availableMagicPoints} storage={props.storage} spells={[item.spell]} processResult={props.processSpell} updateSpell={props.update} update={props.update} />
                    )
                })
            : 
                <div style={{marginTop: "10px", padding: "5px"}}>
                    You do not currently know any cult spirits or ancestors
                </div>  
            }
        </div>
    );
}
export default withContext(withPageState(CultSpiritPanel));