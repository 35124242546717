import React from 'react';
import ActiveEncounters from './activeEncounters';

const EncounterContext = React.createContext(new ActiveEncounters());

export const withActiveEncounters = Component => props => (
  <EncounterContext.Consumer>
    {activeEncounters => <Component {...props} activeEncounters={activeEncounters} />}
  </EncounterContext.Consumer>
);

export default EncounterContext;
