import { v4 as uuidv4 } from 'uuid';

const generateRunes = (runesList, experience) => {
    var generatedRunes = [];

    if (runesList && runesList.length > 0) {
        runesList.forEach((rune) => {
            var newRune; 
            if (rune.alternates) {
                newRune = generateAlternateRune(rune, experience);
            }
            else {
                newRune = generateNewRune(rune, experience)
            }
            if (newRune) {
                var found = false;
                generatedRunes.forEach(rune => {
                    if (rune.parentId === newRune.parentId) {
                        rune.value += 5;
                        found = true;
                    }
                })
                if (!found) {
                    generatedRunes.push(newRune);
                }
            }
        });
    }
    return generatedRunes;
  }

  const generateNewRune = (rune, experience) => {
    var generatedNumber = Math.ceil(Math.random()*(100));
    var bonus = experience.bonus ? experience.bonus / 2 : 0;
    if (generatedNumber <= rune.possession) {
        var newRune = {
            id: uuidv4(),
            parentId: rune.parentId, 
            value:50 + bonus,
            name: rune.name, 
            variant: rune.variant || ""
        };
        newRune.value += Math.ceil(Math.random()*(3)) * 5;
        return newRune;
    }
  }

const generateAlternateRune = (alternates, experience) => {
    var newRune; 

    alternates.runes.forEach(rune => {
        if (!newRune) {
            newRune = generateNewRune(rune, experience)
        }
    })

    return newRune;
}

export default generateRunes;