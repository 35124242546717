import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import RunePointIncrementor from '../../Controls/runePointIncrementor';
import EditIcon from '../../../icons/edit-icon-white.svg';
import * as SAVE from '../../../constants/save';
import DialogBox from '../../Dialogs/dialogBox';
import CultDialog from '../../Magic/cultDialog';
import { withContext } from "../../Context/context";

const CultHeaderBar = (props) => {
    
    const [cult, setCult] = useState(props.cult);
    const [runePoints, setRunePoints] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCult({...props.cult});
        if (props.cult) {
            props.cult.runePoints.forEach(points => {
                if (points.entityId === props.entity.id) {
                    setRunePoints(points.total);
                }
            });
        }
    },[props.cult]);

    function updateCult(updatedCult) {
        setCult(updatedCult);
        props.updateCult(updatedCult, SAVE.DELAYED);
    }

    if (cult && cult.runePoints) {
        return (
            <div style={{height: "30px", backgroundColor: "#232523", color: "#fff", fontSize: "15px", display:"flex", alignItems:"center"}}>
                <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                    <div style={{display:"flex", alignItems:"center", margin: "0px 5px", width: "56%"}}>
                        {cult.runes && cult.runes.map((rune, index) => {
                            var imgSrc = "https://storage.googleapis.com/runequest/runes/" + rune.toLowerCase().replace("/", "") + "-rune-white-40x40.png"
                            return (
                                <img key={index} src= {imgSrc} style={{width:"20px"}} />
                            )
                        })}
                        <div style={{marginLeft: "5px"}}>{cult.name}</div>
                    </div>
                    {cult ? 
                    <div style={{width: "44%"}}>
                        <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", alignItems: "center", marginRight: "5px", position: "relative", width: "100%"}}>
                            {props.pageState.editMode ? 
                                <>
                                    <div style={{marginLeft: "25px"}}>{"Rune points: " + runePoints}</div>
                                    {props.entity.id === props.providerData.character.id ? 
                                        <div style={{cursor: "pointer", marginRight: "5px", display:"flex", alignItems: "center"}} onClick={() => setOpen(true)}>
                                            <img src={EditIcon} style={{width: "22px"}} alt="Edit matrix" title={"Edit " + props.cult.name} />
                                        </div>
                                    : 
                                    null
                                    }
                                </>
                            : 
                                <>
                                    <RunePointIncrementor cult={{...cult}} update={props.updateCult} entityId={props.entity.id} />
                                </>
                            }
                        </div>
                    </div>
                    :
                    null
                    }

                </div>
            <DialogBox title="Edit cult" isOpen={open} width="900px" >
                <CultDialog addCult={updateCult} entity={props.entity} close={() => setOpen(false)} cult={props.cult} update={props.update} />
            </DialogBox>
        </div>
        )
    }
    else return null;
}

export default withContext(withPageState(CultHeaderBar));