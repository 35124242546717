import React from 'react'
import globalStyles from '../Styles/styles.module.css';

const DodgeResultsTable = (props) => {
    return (
        <div style={{backgroundColor: "f0f0f0", width: "1060px", height: "800px"}}>
            <div style={{backgroundColor: "#676767", color: "#fff", display:"flex", textAlign:"center", fontSize: "13px", padding: "10px"}}>
                <div style={{width: "120px"}}></div>
                <div style={{width: "180px"}}>Critical Success</div>
                <div style={{width: "180px"}}>Special Success</div>
                <div style={{width: "180px"}}>Normal Success</div>
                <div style={{width: "180px"}}>Failed Success</div>
                <div style={{width: "180px"}}>Fumbled Success</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Critical Dodge vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Special Dodge vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does special success damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Normal Dodge vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does special success damage, ignores armor.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does special success damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> is dodged successfully.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Failed Dodge vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does maximum special success damage, ignores armor.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does special success damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does normal damage.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> misses.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attacker</b> rolls on Fumbles table.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", textAlign:"center", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", backgroundColor: "#676767", color: "#fff", fontSize: "13px", padding: "10px", display: "flex", alignItems: "center"}}>Fumbled Dodge vs</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does maximum special success damage, ignores armor.<br /><br /><b>Defender</b> rolls on the Fumbles table.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does special success damage.<br /><br /><b>Defender</b> rolls on the Fumbles table.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does normal damage.<br /><br /><b>Defender</b> rolls on the Fumbles table.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> does normal damage.<br /><br /><b>Defender</b> rolls on the Fumbles table.</div>
                <div style={{width: "180px", padding: "10px"}}><b>Attack</b> fails and <b>Attacker</b> rolls on Fumbles table.<br /><br /><b>Defender</b> rolls on the Fumbles table.</div>
            </div>
       </div>
    )
}

export default DodgeResultsTable;