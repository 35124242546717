import React, {useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css'
import styles from './home.module.css';
import { withAuthorization, withEmailVerification, AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material'
import * as ROLES from '../../constants/roles';

const Home = () => {
        
    useEffect(() => {
        document.title = "RuneQuesting: Home"
    },[]);

	var items = [
        {
			styles: {color: "#fff", padding: "20px"},
            name: "Now in beta!",
            description: "A fully functioning digital character sheet - play RuneQuest both online and at the table like never before.",
			imageUrl: "https://storage.googleapis.com/runequest/images/sample-character.png",
			backgroundImageUrl: "https://storage.googleapis.com/runequest/images/runequest-home-2.jpg", 
        },
        {
			styles: {color: "#121312", padding: "20px"},
            name: "Now in beta!",
            description: "A complete NPC & encounter builder for your GM to create friends and foes alike.",
			imageUrl: "https://storage.googleapis.com/runequest/images/sample-encounter.png",
			backgroundImageUrl: "https://storage.googleapis.com/runequest/images/runequest-home-1.jpg",
        },
        {
			styles: {color: "#fff", padding: "20px"},
            name: "Now in beta!",
            description: "A suite of tools designed to make your gameplay smooth and easy, leaving you with more time to explore and discover the amazing world of Glorantha.",
			imageUrl: "https://storage.googleapis.com/runequest/images/sample-tools2.png",
			backgroundImageUrl: "https://storage.googleapis.com/runequest/images/runequest-home-3.jpg",
        }
    ]

	function Item(props)
	{
		return (
			<Paper style={{backgroundColor: "#2f588a"}}>
				<div className={styles.background} style={{display: "flex", color: "#fff", backgroundColor: "#2f588a", padding: "20px 80px", height: "500px", backgroundImage: "url('" + props.item.backgroundImageUrl + "')"}}>
					<div style={props.item.styles}>
						<h1>{props.item.name}</h1>
						<h2>{props.item.description}</h2>
					</div>
					<img src={props.item.imageUrl} style={{height: "460px", borderRadius: "20px"}} />
				</div>
			</Paper>
		)
	}

	return (
		<AuthUserContext.Consumer>
			{authUser => (
				<div style={{width: "100%", backgroundColor: "#2f588a", display:"flex", flexDirection: "column", alignItems: "center", minHeight: "100vh"}}>
					<div style={{height: "40px", width: "100%", backgroundColor: "#2F7589", fontSize: "18px", color: "#fff", display:"flex", alignItems: "center", justifyContent: "center", marginTop: "50px"}}>The Online Toolset for RuneQuest: Roleplaying in Glorantha</div>
					<div style={{position: "relative", width: "1200px", display: "flex", flexDirection: "column", alignItems: "center"}}>
						<div style={{width: "100%", backgroundColor: "#2f588a"}}>
							<Carousel height={500} interval={6000} >
							{
								items.map( (item, i) => <Item key={i} item={item} /> )
							}
							</Carousel>
						</div>
					</div>
					{/* <h1 style={{color: "#fff", marginTop: "20px"}}>The site is currently undergoing maintenance, but will be back soon</h1> */}
					<div style={{height: "3px", width: "100%", backgroundColor: "#2F7589", fontSize: "18px", color: "#fff", display:"flex", alignItems: "center", justifyContent: "center"}} />
					<div style={{position: "relative", width: "1200px", display: "flex", flexDirection: "column", alignItems: "center"}}>
						<div style={{display: "flex", justifyContent: "space-evenly", width: "100%", alignItems: "center", margin: "60px 0px"}}>
							<div>
								<a href="/characters">
									<div style={{backgroundImage: "url(https://storage.googleapis.com/runequest/images/characters.png)", color: "#121312",backgroundRepeat: "no-repeat", backgroundSize: "cover", border: "5px solid #121312", borderRadius: "20px", textAlign: "center", width: "250px", height: "250px", fontSize: "24px", padding: "20px"}}>
									<div>Characters</div>
									</div>
								</a>
							</div>
							<div style={{backgroundColor: "#fff", border: "5px solid #121312", borderRadius: "20px", width: "290px", height: "290px"}}>
								<a href={authUser.roles[ROLES.MEMBER] ? "/campaigns": null} style={authUser.roles[ROLES.MEMBER] ? {cursor: "pointer"}: {cursor: "default"}}>

								<div style={{backgroundImage: "url(https://storage.googleapis.com/runequest/images/campaigns.png)", backgroundRepeat: "no-repeat", backgroundSize: "cover", color: "#121312", borderRadius: "15px", width: "250px", height: "250px", textAlign: "center", marginRight: "100px", fontSize: "24px", padding: "20px"}}>
									<div>Campaigns</div>
								</div>
								</a>
							</div>
						</div>
					</div>
					<div style={{color: "#fff"}}>
						<div style={{marginTop: "10px", fontWeight: "bold"}}>7th October 2024: Release 1.3.1 updates now live:</div>
						<div style={{marginTop: "10px", fontWeight: "bold"}}>Bug Fixes:</div>
						<ul>
							<li>Fixed height issues on custom panels within custom content to allow for scrolling</li>
							<li>Fixed issue where Apply Spirit Spell and Apply Rune Spell not behaving correctly when added from Enchantment panel</li>
							<li>Fixed error with Axe Trance targeting weapons other than axes</li>
							<li>Corrected stereotype for the Grazelanders homeland in character builder</li>
							<li>Removed unnecessary parentheses from Praxian homeland in character builder when no tribe selected</li>
						</ul>
						<div style={{marginTop: "10px", fontWeight: "bold"}}>30th September 2024: Release 1.3 updates now live:</div>
						<div style={{marginTop: "10px", fontWeight: "bold"}}>Additions:</div>
						<ul>
							<li>Added custom content to the application. Create your own cults, spirit spells, rune spells and skills, and use them when building or editing characters.</li>
							<li>Introduced subcults to the application so that all subcults share the same rune pool.</li>
							<li>Updated Summon & Dismiss Elemental rune spells to use cult elemental rune as primary casting rune, or cult runes if no elemental rune exists.</li>
							<li>Removed Sleep from associated cult spirit spell lists due to its restriction by Chalana Arroy.</li>
							<li>When selecting rune spells, added highlight to indicate if the spell is provided by an associated cult.</li>
						</ul>
						<div style={{marginTop: "10px", fontWeight: "bold"}}>Bug Fixes:</div>
						<ul>
							<li>Corrected Rhomphaia ENC to 2</li>
						</ul>
					</div>
					<div style={{height: "3px", width: "100%", backgroundColor: "#2F7589", fontSize: "18px", color: "#fff", display:"flex", alignItems: "center", justifyContent: "center"}} />
						<div style={{position: "relative", width: "1200px", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "50px"}}>
							<div style={{bottom: "5px", margin: "0 auto", color: "#fff", textAlign: "center"}}>
								<div style={{margin: "0 auto", display: "flex", flexWrap: "wrap", alignItems: "center"}}>
									<a href="https://chaosium.com" target="_blank">
										<img src="https://storage.googleapis.com/runequest/images/chaosium-logo-white.png" style={{height: "160px", marginRight: "100px"}} />
									</a>
									<div>
										<div>Produced under license from Moon Design Publications LLC and Chaosium Inc. (chaosium.com)</div>
										<div>RuneQuest and Glorantha are registered trademarks of Moon Design Publications LLC.</div>
										<div>Chaosium Inc. and the Chaosium logo are registered trademarks of Chaosium Inc.</div>
										<div><i>RuneQuest</i> © 1978–2024 Moon Design Publications LLC. All rights reserved.</div>
										<div>The names, descriptions, and depictions in this product are derived from works copyrighted by and include trademarks<br/> owned by Moon Design Publications LLC and Chaosium Inc., and may not be used or reused without their permission.</div>
									</div>

								</div>
							</div>
					</div>

				</div>
			)}
		</AuthUserContext.Consumer>
	)
}
 
export default withFirebase(withEmailVerification(withAuthorization(Home)));