import React, {useState, useEffect } from 'react';

const HomelandCards = (props) => {

    const [selected, setSelected] = useState();

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
        }
    },[props.selected])

    function handleSelect(homeland) {
        setSelected(homeland);
        props.handleSelect(homeland);
    }

    if (props.homelands && props.size) {
        return (
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {props.homelands.map((homeland, index) => {
                    var opacity = 1;
                    var borderColor="#2F588A";
                    if (selected && selected.name != homeland.name) {
                        opacity = 0.5;
                        borderColor = "#676767";
                    }
                    if (homeland.imageLink) {
                        return(
                            <div key={index} style={{opacity: opacity}} onClick={() => handleSelect(homeland)}>
                                <img style={{width: props.size.width, objectFit: "cover", margin: "6px", borderRadius: "5px", border: "5px solid " + borderColor}} src={homeland.imageLink} />
                                {homeland.name == "Prax" && props.tribe && props.tribe.name ? 
                                    <div  style={{display:"flex", justifyContent: "center", alignItems:"center", margin: "5px", fontSize: props.size.fontSize, height: "30px"}}>{homeland.name} ({props.tribe.name})</div>
                                :
                                    <div  style={{display:"flex", justifyContent: "center", alignItems:"center", margin: "5px", fontSize: props.size.fontSize, height: "30px"}}>{homeland.name}</div>
                                }
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
    else {
        return null;
    }
};

export default HomelandCards;