import React, {useState, useEffect} from 'react';
import DiceButton from './diceButton';
import RuneDiceButton from './runeDiceButton';
import { withPageState } from '../Context/PageState';
import SmallDiceButton from './smallDiceButton';

const RuneMatrixButton = (props) => {

    if (props.pageState.editMode) {
        return (
            <div></div>
            )
    }
    else {

        return (
            <DiceButton diceRoller={props.diceRoller} style={{marginBottom: "10px", width: "140px"}} type="Rune Spell Matrix" disabled={props.disabled} entity={props.spellcaster} processResult={props.processResult} item={props.item} modifier={props.modifier} target={props.item.target}>
                <SmallDiceButton target={props.target + props.modifier} />
            </DiceButton>
)
    }
}

export default withPageState(RuneMatrixButton)