import * as Template from '../data/characterTemplate.json';
import { v4 as uuidv4 } from 'uuid';
import { getAllSkills } from './dataService';

function saveCharacter(firebase, uid, updates, type) {
    if (updates && updates.length > 0) {
        var saveObjects = {}
        updates.forEach(update => {
            saveObjects[update.fieldName] = update.value;
        })
    
        firebase[type](uid)
        .update(saveObjects)
        .then( function() {
        });
    }
}

function calculateMove(character, currentEncumbrance) {
    var move = character.move;
    var encPenalty = character.maxEnc - currentEncumbrance;
    if (encPenalty < 0) {
        move += encPenalty;
    }
    if (character.enchantments) {
        character.enchantments.forEach(enchantment => {
            enchantment.effects && enchantment.effects.forEach(effect => {
                if (effect.name === "moveMultiplier") {
                    var multiplier = effect.value
                    move *= multiplier;
                }
            })
        });
        character.enchantments.forEach(enchantment => {
            enchantment.effects.forEach(effect => {
                if (effect.name === "moveModifier") {
                    move += effect.value || 0
                }
            })
        });
    }
    if (move < 0) {move = 0;}
    return move;
}

export function createBlankCharacter(campaign) {
    var character = {...Template};
    var skills = [];
    var allSkills = getAllSkills(campaign)
    character.skills.forEach(skill => {
        var index = allSkills.findIndex(item => item.id === skill.parentId);
        var skillDetails = {...allSkills[index]}
        var newSkill = {
            id: uuidv4(), 
            baseValue: skillDetails.baseValue,
            canLearn: skillDetails.canLearn || false,
            skillCategory: skillDetails.skillCategory || "",
            hasSucceeded: false,
            name: skillDetails.name, 
            parentId: skill.parentId, 
            value: skillDetails.baseValue
        };
        if (skillDetails.variable) {
            newSkill.variable = true;
            newSkill.variant = "";
        }
        skills.push(newSkill);
    });
    character.skills = skills;

    character.runes.forEach(rune => {
        rune.id = uuidv4();
    });

    return character;
}

export {saveCharacter, calculateMove}