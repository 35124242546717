import React from 'react';

const HomelandDetails = (props) => {

    return (
        <div style={{padding: "10px", backgroundColor: "#676767", color: "#fff", border:"5px solid #454645", borderRadius: "15px"}}>
            <div id="props.item-rune" style={{fontSize:"24px", textAlign:"center"}}>
                {props.builder.homeland.name} {props.builder.tribe && props.builder.tribe.name ? " (" + props.builder.tribe.name + ")" : ""}
            </div>
            <div id="props.item-rune" style={{fontSize:"24px", padding: "20px"}}>
                <img style={{width: props.width ? props.width: "370px"}} src={props.builder.homeland.imageLink} alt={props.builder.homeland.name + " image"} />
            </div>
        </div>
    );
}

export default HomelandDetails;
