import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

const OptionsVariant = (props) => {

    const [variant, setVariant] = useState("");

    useEffect(() => {
        if (props.item.options[0].variant) {
            setVariant(props.item.options[0].variant);
        }
    }, [props.item.options[0].variant]);
    
    function modifyVariant(event) {
        setVariant(event.target.value);
        props.update(event.target.value, props.index);
    }

    var border = props.item.options[0].variant ? "3px solid transparent" : "3px solid #851113";

    return (
        <div key={props.index} style={{display:"flex", maxHeight: "30px", overflow: "hidden", borderRadius: "5px", border: border, margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
            <div style={{display: "flex", paddingLeft: "5px", width: "100%", margin: "2px"}}>
                <div style={{alignItems: "center", display: "flex", width: "320px"}}>
                    <div style={{display:"flex"}}>
                        <div>{props.item.options[0].name}</div>
                    </div>
                    <div style={{width:"220px", display:"flex", justifyContent:"flex-start", alignItems: "center", marginLeft: "20px", borderRadius:"5px"}}>
                        <TextField inputProps={{style: {fontSize: "15px", width:"220px", height: "20px", backgroundColor: "#fff", color: "#121312", border: "1px solid #2F588A", borderRadius: "5px", padding: "3px"}}} placeholder={props.item.title} id={props.item.options[0].name} color="primary" value={variant} onChange={modifyVariant} variant="outlined" size="small">{variant}</TextField>
                    </div>
                </div>
                {props.item.value ? 
                    <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>({props.item.value})</div>
                : 
                    <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{props.item.bonus}</div>
                }
            </div>
        </div>
    );
}

export default OptionsVariant;