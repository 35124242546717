import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import RuneSpellButtonTile from './runeSpellButtonTile';

const RuneSpellTile = (props) => {

    const [item, setItem] = useState();
    const [disabled, setDisabled] = useState(false);
    const [castingRune, setCastingRune] = useState(null);

    useEffect(() => {
        setDisabled(props.totalPoints < props.item.points);
    },[props.totalPoints])

    useEffect(() => {
        var spell = {...props.item};
        var target = 0;
        var favouredRune = "";
        var runeId = "";
        var spellRunes = [...props.item.runes];
        if (props.cult && spellRunes.indexOf("Magic") > -1) {
            spellRunes = [...props.cult.runes];
        }
        spellRunes.forEach(rune => {
            var index = props.spellcaster.runes.findIndex(item => item.name == rune);
            if (index > -1 && props.spellcaster.runes[index].value > target) {
                target = props.spellcaster.runes[index].value;
                favouredRune = props.spellcaster.runes[index].name.replace("/", "");
                runeId = props.spellcaster.runes[index].id
                setCastingRune({...props.spellcaster.runes[index]})
            }
        })  
        spell.value = target;
        spell.favouredRune = favouredRune;
        spell.runeId = runeId;
        setItem({...spell, runes: spellRunes});
    },[props.spellcaster.characteristics, props.item, props.cult]);

    function displayItem(details) {
        props.displayItem({...details, cult: props.cult})
    }
    
    function processSpell(roll) {
        var name = roll.item.name + (roll.item.stackable ? " " + roll.item.points : "") + (roll.item.variant ? " " + roll.item.variant : "");
        var messageText={critical: " achieved a critical success when casting " + name, special: " achieved a special success when casting " + name, success: " successfully cast " + name, failure: " failed to cast " + name, fumble: " fumbled when casting " + name}
        var dieRoll = {target: roll.target, result: roll.result, augmentRoll: false, messageText: messageText}
        var action = {type: "runes", spell: {...roll.item}, spellcaster: props.spellcaster, points: roll.item.points, boost: 0, deductMP: false, name: roll.item.favouredRune, id: roll.item.runeId, cult: props.cult}
        if (castingRune) {action.castingRune = castingRune};
        props.processResult(dieRoll, action, roll.title);
    }

    if (item) {
        return (
            <RuneSpellButtonTile diceRoller={props.diceRoller} element={props.element} disabled={disabled} item={item} update={props.update} spellcaster={props.spellcaster} totalPoints={props.totalPoints} displayItem={displayItem} processResult={processSpell} entity={props.entity} />
        )
    }
    else {
        return null;
    }
}
export default withPageState(RuneSpellTile);