import React from 'react';
import {withContext} from '../Context/context';
import { getCategoryModifiers } from '../../services/skillsService';
import { isEqual } from 'lodash';

const IntelligenceTile = (props) => {

    function update(characteristic, save) {
        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.INT = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }
        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(IntelligenceTile)