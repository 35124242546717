import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ProfileImagesPanel from "../dialogs/profileImagesPanel";
import { Button } from "@mui/material";

const ProfileImage = (props) => {

    const [displayImages, setDisplayImages] = useState(false);

    useEffect(() => {
        if (props.pageState && props.pageState.editMode === false) {
            setDisplayImages(false);
        } 
    },[props.pageState])

    function selectImage(image) {
        setDisplayImages(false);
        props.updateImage(image);
    }

    var imagesStyle = {position: "absolute", width: "775px"};
    if (props.builder) {imagesStyle.right = 0} else {imagesStyle.left = 0;}
    
    return (
        <div style={{position: "relative"}}>
            <img src={props.profileImage} style={{height: "240px"}} />
            {props.pageState.editMode ? 
            <div onClick={() => setDisplayImages(true)} style={{position: "absolute", cursor: "pointer", bottom: 5, right: 5, backgroundColor: "#2f588a", color: "#fff", width: "50px", height: "50px", borderRadius: "25px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <ModeEditIcon />
            </div>
            : 
            null}
            <div>
                {displayImages ? 
                    <div style={imagesStyle}>
                        <ProfileImagesPanel gender={props.gender} modifyImage={selectImage} />
                    </div>
                : null}
                {props.builder ? 
                    <Button color="primary" variant="contained" onClick={() => {setDisplayImages(true)}}>Select image</Button>
                : 
                null }
            </div>
        </div>
    )
}

export default withPageState(ProfileImage);