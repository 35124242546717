import React, {useState, useEffect } from 'react';

const OccupationCards = (props) => {

    const [selected, setSelected] = useState();

    useEffect(() => {
        setSelected(props.occupation);
    },[props.occupation])

    function handleSelect(occupation) {
        setSelected(occupation);
        props.handleSelect(occupation);
    }

    return (
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            {props.occupations.map((occupation, index) => {
                var opacity = 1;
                var border = "3px solid #676767";
                var backgroundColor="#999999";
                var margin = "5px";
                if (selected) {
                    if (selected.name != occupation.name) {
                    opacity = 0.5;
                    }
                    else {
                        border = "3px solid #2F588A";
                        margin = "5px";
                        backgroundColor="#fff";
                    }
                }
                if (occupation.imageLink) {
                    return(
                        <div key={index} style={{opacity: opacity, textAlign:"center", margin: "10px", width: "108px", cursor: "pointer"}} onClick={() => handleSelect(occupation)}>
                            <img style={{width: props.size.width, height: props.size.height, objectFit: "cover", borderRadius: "5px", border: border, backgroundColor: backgroundColor}} src={occupation.imageLink} />
                            <div  style={{display:"flex", justifyContent: "center", alignItems:"center", margin: "5px", flexWrap:"wrap", fontSize: props.size.fontSize, height: "30px"}}>{occupation.name}</div>
                        </div>
                    )
                }
            })}
        </div>
    )
};

export default OccupationCards;