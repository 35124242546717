import React, { useState, useEffect } from 'react';
import AddWeaponDialog from '../dialogs/addWeaponDialog';
import DialogBox from '../../Dialogs/dialogBox';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const AddWeaponButton = (props) => {
    const [open, setOpen] = useState(false);

    const style = {
        borderRadius: 3,
        boxSizing:"border-box",
        border: 0,
        color: 'white',
        height: "80px",
    };

    return (
        <>
            <div onClick={() => setOpen(true)} style={{cursor: "pointer", display: "flex", alignItems: "center"}} title={props.title}>
                <ModeEditIcon style={{height:"24px", width:"24px", color: "#fff"}}/>
            </div>
            <DialogBox isOpen={open} width="900px" title="Add Weapons">
                <AddWeaponDialog close={() => {setOpen(false)}} currentWeapons={props.currentWeapons} addItem={props.addItem} />
            </DialogBox>
        </>
    )
}

export default AddWeaponButton