import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const SpellAdjuster = (props) => {

    return (
        <>
            <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", fontSize: "14px", marginTop: "10px"}}>
                <div style={{display:"flex", justifyContent:"center"}}>{props.title}</div>
                <div style={{display:"flex", marginRight:"20px", alignItems:"center"}}>
                        <RemoveCircleOutlineIcon style={{height:"20px", width:"20px"}} onClick={props.adjustDown}/>
                        <AddCircleOutlineIcon style={{height:"20px", width:"20px", marginLeft:"4px"}} onClick={props.adjustUp}/>
                </div>
            </div>
            <hr style={{width: "100% "}} />
        </>
    )
}

export default SpellAdjuster;    