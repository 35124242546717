import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { FormControl, Checkbox } from '@mui/material';
import { displayDiceRolled } from '../../services/dieRollService';
import Selector from '../Controls/selector';

export default function GiftListItem(props) {

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [damage, setDamage] = useState("");
    const[cultWeapons, setCultWeapons] = useState([]);
    const[options, setOptions] = useState([]);

    useEffect(() => {
        setChecked(props.checked);
    },[props.checked, props.gift])

    useEffect(() => {
        setCultWeapons(props.gift.type === "cultWeaponSkill" ? [...props.gift.options] : []);
        setOptions(props.gift.options ? [...props.gift.options] : [])
    },[props.gift])

    const handleChange = (event) => {
        setChecked(event.target.checked);
        props.select(props.gift, event.target.checked)
    };

    const setSelected = (value) => {
        var index = props.gift.options.findIndex(item => item.name + (item.variant ? " " + item.variant : "") === value);
        var updatedGift = {...props.gift};
        updatedGift.selected = props.gift.options[index];
        props.select(updatedGift, checked);
    }

    function displaySelector(values) {
        var name = "";
        if (props.gift.selected) {
            name = props.gift.selected.name + (props.gift.selected.variant ? " " + props.gift.selected.variant : "");
        }
        var currentValue = name;
        return (
            <Selector value={currentValue} updateSelected={value => setSelected(value)} values={values} label="Select option" />
        )
    }

    var disabled = checked ? false : props.disabled;

    return (
        <>
            <div 
            style={{display:"flex", height: "30px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px"}}>
                    {props.noSelect ? 
                        null
                    : 
                        <div style={{width:"80px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                            <FormControl variant="outlined" 	>
                                <Checkbox disabled={disabled} checked={checked} color='primary' onChange={handleChange} />
                            </FormControl>
                        </div>
                    }
                    <div style={{display:"flex"}} onClick={() => setOpen(!open)}>
                    <div style={{minWidth:"600px", alignItems: "center", display: "flex"}}>
                            <div>{props.gift.description}</div>
                        </div>
                        <div style={{minWidth:"80px", alignItems: "center", display: "flex", justifyContent: "center"}}>
                            <div>{props.gift.geases}</div>
                        </div>
                    </div>
                </div>
            </div>
            {checked && options.length > 0 ?
                <>
                    {displaySelector(options)}
                </>
                : 
                null
            }
        </>
    )
}