import React, {useState, useEffect} from 'react';
import DiceButton from '../Buttons/diceButton';
import SmallDiceButton from '../Buttons/smallDiceButton';
import { Box, Slider } from '@mui/material';
import ModifierBox from '../Controls/modifierBox';
import * as SAVE from '../../constants/save';
import Incrementor from '../Controls/incrementor';
import { withContext } from '../Context/context';
import { getStatValue } from '../../services/attributeService';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import { getEnchantmentsModifier } from '../../services/enchantmentsService';

const CharacteristicTileContextMenuPanel = (props) => {

    const [opposed, setOpposed] = useState(11);
    const [opposedTarget, setOpposedTarget] = useState({value: 50});
    const [modifier, setModifier] = useState("");
    const [stat, setStat] = useState(props.panelItem.item);
    const [multiplier, setMultiplier] = useState(5);
    const [value, setValue] = useState(getStatValue(props.panelItem.item));
    const [target, setTarget] = useState(0);
    const [enchantmentsModifier, setEnchantmentsModifier] = useState(0);

    useEffect(() => {
        var item = {...props.providerData.character.characteristics[props.panelItem.item.name]};
        setStat(item);
        var enchantmentsMod = getEnchantmentsModifier("stat", props.providerData.character, item, (item.baseValue + (item.statBonus || 0)));
        setEnchantmentsModifier(enchantmentsMod);

    }, [props.panelItem, props.providerData.character]);

    useEffect(() => {
        setValue(getStatValue(stat));
    },[stat]);

    useEffect(() => {
        var target = value * multiplier;
        target = target + (modifier || 0);
        setTarget(target);
    }, [value, multiplier, modifier]);

    useEffect(() => {
        var target = (((value - opposed) * 5) +50);
        target = target + (modifier || 0);
        if (props.providerData.augmenting) {
            target += (props.providerData.augmenting.modifier || 0)
        }
        setOpposedTarget(target);
    }, [value, opposed, modifier]);

    function processResult(roll) {
        var messageText={critical: " achieved a critical success against {pronoun} "  + roll.item.name, special: " achieved a special success against {pronoun} " + roll.item.name, success: " succeeded against {pronoun} " + roll.item.name, failure: " failed against {pronoun} " + roll.item.name, fumble: " fumbled against {pronoun} " + roll.item.name};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText}
        props.processResult(dieRoll, roll.item, roll.title)
    }

    function modifyStat(value) {
        var updatedItem = {...stat};
        var bonus = updatedItem.statBonus || 0;
        bonus += value;
        if (updatedItem.baseValue + bonus < 0) {
            bonus = updatedItem.baseValue * -1;
        }
        updatedItem.statBonus = bonus;
        setValue(getStatValue(updatedItem));
        props.update(updatedItem, SAVE.DELAYED);
        setStat(updatedItem)
    }

    function multiplierValue(value) {
        if (multiplier != value) {
            setMultiplier(value)
        }
      }

      function opposingValue(value) {
        if (opposed != value) {
            setOpposed(value)
        }
      }

    const multipliers = [1,2,3,4,5,6,7,8,9];
    const opposingStats = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25];

    return (
            <div style={{padding: "10px", fontSize: "16px", height: "450px"}}>
                <div style={{width:"100%", display:"flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0px"}}>
                    <Incrementor style={{marginRight: "20px"}} label="Current value:" increment={modifyStat} currentValue={value} />
                    <div style={{width:"30%", display:"flex", justifyContent: "flex-start", alignItems: "center", color: stat.statBonus < 0 ? "#851113" : "#121312"}}>
                        <div>Base value: </div>
                        <div>{stat.baseValue}</div>
                    </div>
                </div>
                {props.enchantments && props.enchantments.length > 0 ? 
                    <div style={{display:"flex"}}>
                        <div>(Includes&nbsp;</div>
                        {props.enchantments.map((enchantment, index) => {
                            return (
                                <div key={index}>{index > 0 ? ", ": ""} {enchantment.statModifier} points from {enchantment.name}</div>
                            )
                        })}
                        <div>)</div>
                    </div>
                : 
                null
                }
                <hr style={{width:"360px"}} />
                <ModifierBox handleChange={(value) => setModifier(value)} />
                <hr style={{width:"360px"}} />
                <div style={{width: "100%", display: "flex", justifyContent: "space-between", padding: "0px", alignItems: "center"}}>
                    <div>
                        <div>{"Characteristic x " + multiplier + "%"} </div>
                        <Box sx={{ width: 200 }}>
                            <Slider key={100} aria-label="Custom marks" defaultValue={5} getAriaValueText={multiplierValue} step={1} valueLabelDisplay="auto" min={1} max={10} />
                        </Box>
                    </div>
                    <div style={{width: "120px"}}>
                        <DiceButton type="Multiplier" diceRoller={props.diceRoller} style={{height: "32px"}} character={props.providerData.character} processResult={processResult} item={stat} modifier={modifier} target={target} entity={props.entity}>
                            <SmallDiceButton />
                        </DiceButton>
                    </div>
                </div>
                <hr style={{width:"360px"}} />
                <div style={{width: "100%", display: "flex", justifyContent: "space-between", padding: "0px", alignItems: "center"}}>
                    <div>
                        <div>{"Opposing value: " + opposed
                        } </div>
                        <Box sx={{ width: 200 }}>
                            <Slider aria-label="Custom marks" defaultValue={11} getAriaValueText={opposingValue} step={1} valueLabelDisplay="auto" min={1} max={25} />
                        </Box>
                    </div>
                    <div style={{width: "120px"}}>
                        <DiceButton type="Opposed" diceRoller={props.diceRoller} character={props.providerData.character} processResult={processResult} item={stat} modifier={modifier} target={opposedTarget} entity={props.entity}>
                            <SmallDiceButton />
                        </DiceButton>
                    </div>
                </div>
                <hr style={{width:"360px"}} />
                <div style={{fontSize:"14px"}}>
                    <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{stat.fullName + " (" + stat.name + ")"}</div>
                    {enchantmentsModifier ? 
                        <div>Enchantments: {enchantmentsModifier < 0 ? "" : "+"}{enchantmentsModifier}</div>
                    : null}
                    {props.providerData.character.despondent ? 
                        <div style={{fontWeight: "bold"}}>Despondent: -10%</div>
                    : null}
                    <div style={{display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between"}}>
                        <div dangerouslySetInnerHTML={{__html: stat.description}}></div>
                    </div>
                </div>
            </div>
    )
}
export default withContext(CharacteristicTileContextMenuPanel);