function formatBenefits(item, weapons) {
    if (item.value) {
        var total = 0;
        for (var i = 0; i < item.value.quantity; i++)  {
            total += Math.ceil(Math.random() * item.value.dieType);
        }
        total += item.value.modifier;
        total *= 100;
        item.description = item.description.replace ("<value>", total);
    }
    item.benefits.forEach(benefit => {
        if (benefit.roll) {
            var total = 0;
            for (var i = 0; i < benefit.roll.quantity; i++)  {
                total += Math.ceil(Math.random() * benefit.roll.dieType);
            }
            total += benefit.roll.modifier;
            benefit.POW = total;
            item.description = item.description.replace ("<roll>", total);
        }
        else if (benefit.characteristics) {
            var total = 0;
            var description = item.description;
            benefit.characteristics.forEach(stat => {
                var total = 0;
                for (var i = 0; i < stat.quantity; i++)  {
                    total += Math.ceil(Math.random() * stat.dieType);
                }
                total += stat.modifier;
                description = description.replace("<" + stat.name + ">", total)
            })
            
            item.description = description;
        }
        else if (benefit.type === "hitPoints" && weapons){
            var options = [];
            weapons.forEach(weapon => {
                options.push({name: weapon.name, parentId: weapon.parentId});
            });
            benefit.options = options;
        }
        if (benefit.options) {
            benefit.selection = -1;
        }
    });
    return item;
}

export {formatBenefits}