import { getCult, getRuneSpell } from "./dataService";

export function getSpells(cult, subcults, campaign) {
    var spells = getSpellDetails(cult, campaign);
    if (subcults && subcults.length > 0) {
        subcults.forEach(subcult => {
            spells = spells.concat(getSpellDetails(subcult, campaign));
        })
    }
    return spells;
}

function getSpellDetails(cult, campaign) {
    let spells = [];
    if (! cult.runeSpells) {
        cult = getCult(cult.parentId, campaign)
    }
    cult.runeSpells.forEach(runeSpell => {
        var newSpell = {name: runeSpell.name, parentId: runeSpell.parentId};
        if (!newSpell.id) {
            newSpell.id = newSpell.parentId;
        }
        if (runeSpell.variant) {
            newSpell.variant = runeSpell.variant;
        }
        if (runeSpell.parentCult) {
            newSpell.parentCult = runeSpell.parentCult;
            newSpell.parentCultId = runeSpell.parentCultId;
        }
        const spellDetails = getRuneSpell(runeSpell.parentId, campaign)
        newSpell.points = spellDetails.points;
        newSpell.stackable = spellDetails.stackable;
        newSpell.runes = spellDetails.runes;
        newSpell.description = spellDetails.description;
        newSpell.range = spellDetails.range;
        newSpell.duration = spellDetails.duration;
        spells.push(newSpell);
    })
    return spells;
}
