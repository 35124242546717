import React, {useState, useEffect} from 'react';
import { Button, FormControlLabel, Switch } from '@mui/material';
import EditBoxMulti from '../Controls/editBoxMulti';

const AddNoteDialog = (props) => {

    const [note, setNote] = useState("")
    const [privateNote, setPrivateNote] = useState(false);

    useEffect(() => {
        if (props.index > -1) {
            setNote(props.currentNote.content);
            setPrivateNote(props.currentNote.private)
        }
    },[props.currentNote])

    function addNote() {
        props.update(note, privateNote, props.index);
        props.close();
    }

    return (
        <div style={{overflowX: "hidden", height: "480px"}}>
            <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between", padding: "10px"}}>
                <EditBoxMulti rows={15} multiline label="New note" value={note} onChange={(value) => setNote(value)} />
            </div>
            <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", marginTop:"30px", padding: "20px", justifyContent:"space-between"}}>
                <div style={{display:"flex", flexDirection:"row-reverse"}}>
                    <Button onClick={addNote} style={{width: "100px"}} color="primary" variant="contained">{props.index > -1 ? "Edit": "Add"}</Button>
                    <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>
                    <div style={{marginRight: "10px"}}>Private note</div>
                    <FormControlLabel 
                        control={<Switch checked={privateNote} color='primary' onChange={() => {setPrivateNote(!privateNote)} } />}
                    />
                </div>
            </div>
        </div>
    );
}
export default AddNoteDialog;