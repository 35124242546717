import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import EncountersDialog from './createEncounterDialog';
import EditBox from '../Controls/editBoxSingle';
import DialogButtons from '../Dialogs/dialogButtons';
import DeleteButton from '../Controls/deleteButton';

const EncountersContextPanel = (props) => {

	const [encounters, setEncounters] = useState([]);
	const [encounter, setEncounter] = useState({startDate: {}});
	const [currentAdventure, setCurrentAdventure] = useState("");
	const [openNew, setOpenNew] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
		var items = [];
        props.campaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
						setCurrentAdventure(adventure.name)
                        setEncounters([...adventure.encounters])
                    }
                })
            }
        })
    },[props.campaign])

	function updateEncounters(updatedEncounters, encounterId) {
		var updatedCampaign = {...props.campaign}
		if (encounterId) {
			updatedCampaign.currentEncounterId = encounterId;
		}
		updatedCampaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
                        adventure.encounters = [...updatedEncounters];
						props.update(updatedCampaign)
                    }
                })
            }
		});
	}

	function editEncounter(encounter) {
		setEncounter(encounter);
		setOpenEdit(true);
	}

	function addEncounter() {
		setOpenNew(true);
	}

	function updatedEncounterName(newName) {
		var updatedEncounter = {...encounter};
		updatedEncounter.name = newName;
		setEncounter(updatedEncounter)
	}

	function updateEncounter() {
		var updatedEncounter = {...encounter};
		props.campaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
                        var updatedEncounters = [...adventure.encounters];
						updatedEncounters.forEach(item => {
							if (item.id === encounter.id) {
								item.name = encounter.name;
							}
						});
						updateEncounters(updatedEncounters, encounter.id)
					}
                })
            }
		});
		setOpenEdit(false);
	}

	function deleteEncounter() {
		props.campaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
                        var updatedEncounters = [...adventure.encounters];
						var index = updatedEncounters.findIndex(obj => obj.id === encounter.id)
						if (index > -1) {
							updatedEncounters.splice(index, 1);
							props.firebase
							.npcs()
							.where("encounterId", "==", encounter.id)
							.get()
							.then((collection) => {
								collection.forEach(doc => {
									props.firebase
									.npc(doc.id)
									.delete()

								})
							})
						}
						updateEncounters(updatedEncounters)
					}
                })
            }
		});
		setOpenEdit(false);
	}

    return (
		<>            
			<div style={{fontSize: "14px", lineHeight: "1"}}>
                <div style={{height: "800px", overflow: "auto", padding: "5px"}}>
					<h3>Encounters</h3>
					<div>Each potential conflict within RuneQuesting is represented by an encounter. The encounter consists of one or more groups of enemies, each of which can be a different cult, race and ability level. By creating an encounter, you as the GM can interact directly with your players within the RuneQuesting application, targeting their characters with spells and being targeted in return.</div>
					<div style={{marginTop: "10px"}}>N.B. You must have selected a current adventure within the Storytelling section before you can create an encounter.</div>
					<div style={{margin: "20px 0px"}}>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{currentAdventure ? 
								<div style={{display: "flex", alignItems: "center"}}><div style={{fontWeight: "bold", marginRight: "10px"}}>Current Adventure: </div><div>{currentAdventure}</div></div>
							: 
							<div></div>}
						</div>
						<hr />
						<div style={{margin: "10px 0px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
							<div>Add new encounter:</div>
							<Button disabled={!props.campaign.currentAdventureId} onClick={addEncounter} style={{width: "100px"}} color="primary" variant="contained">Add</Button>
						</div>
						<hr />
						<div id="header" style={{display: "flex", alignItems: "center", fontWeight: "bold", marginTop: "10px"}}>
							<div style={{width: "400px"}}>Name</div>
						</div>
						<div className={globalStyles.panel}  style={{height: "500px", overflow: "auto"}}>
							{encounters.map((encounter, index) => {
								return(
									<div key={index} id="row" style={{display: "flex", alignItems: "center", padding: "10px 0px"}}>
										<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
											<div style={{width: "400px"}}>{encounter.name}</div>
											<div title="Edit encounter">
												<ModeEditIcon style={{color: "#2f588a", height: "24px", width: "24px", cursor: "pointer"}} onClick={() => editEncounter(encounter)} />
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
                </div>
            </div>
            <DialogBox isOpen={openNew} width="800px" title="Add Encounter">
                <EncountersDialog campaignId={props.campaign} authUserId={props.authUser.uid} encounters={encounters} close={() => setOpenNew(false)} closePanel={props.close} update={updateEncounters} />
            </DialogBox>
            <DialogBox isOpen={openEdit} width="800px" title="Edit Encounter">
				<div style={{padding: "10px"}}>
					<div style={{margin: "10px 0px"}}>
						<EditBox style={{backgroundColor: "#fff"}} width="750px" value={encounter.name} onChange={updatedEncounterName} placeholder="Encounter name" autoFocus />
					</div>
					<div style={{display:"flex", marginTop:"30px", padding: "20px", alignItems:"flex-end", flexDirection: "row-reverse", justifyContent: "space-between"}}>
						<div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
							<Button disabled={!encounter.name} type="submit" onClick={updateEncounter} style={{width: "100px"}} color="primary" variant="contained">Ok</Button>
							<Button onClick={() => setOpenEdit(false)} color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
						</div>
						<div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
							<DeleteButton type="encounter" delete={deleteEncounter} item={encounter} title="Delete encounter" />
						</div>
					</div>
				</div>
            </DialogBox>
        </>
    )
}
export default withFirebase(EncountersContextPanel);