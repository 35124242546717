import React from 'react'

const ElementalRuneCard = (props) => {
    return (
        <div style={{border: "5px solid #2F588A", borderRadius: "10px", backgroundColor: "#cdcdcd", padding: "10px", maxWidth: "900px", minHeight: "200px"}}>
            <div id="props.item-rune" style={{fontSize:"20px", marginTop: "-5px", fontWeight: "bold"}}>
                {props.item.name}
            </div>
            <div style={{margin: "5px 0px", marginBottom: "10px"}}>{props.item.background}</div>
            <div style={{margin: "5px 0px", marginBottom: "10px"}}>{props.item.description}</div>
            <div style={{display:"flex"}}>
                <div style={{width: "33%", marginRight: "20px"}}>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Characteristic:&nbsp;</div>
                        <div>{props.item.characteristic}</div>
                    </div>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Sense:&nbsp;</div>
                        <div>{props.item.sense}</div>
                    </div>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Skill Category:&nbsp;</div>
                        <div>{props.item.skillCategory}</div>
                    </div>
                </div>
                <div style={{width: "33%"}}>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Weapons:&nbsp;</div>
                        <div>{props.item.weapons}</div>
                    </div>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Organs:&nbsp;</div>
                        <div>{props.item.organs}</div>
                    </div>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Color:&nbsp;</div>
                        <div>{props.item.color}</div>
                    </div>
                </div>
                <div style={{width: "33%"}}>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Metal:&nbsp;</div>
                        <div>{props.item.metal}</div>
                    </div>
                    <div style={{display:"flex", margin: "5px 0px"}}>
                        <div style={{fontWeight: "bold"}}>Phylum:&nbsp;</div>
                        <div>{props.item.phylum}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ElementalRuneCard;