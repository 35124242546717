import React from 'react';
import {useDraggable} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';

export function Draggable(props) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: props.id,
    });
    const style = {
        // Outputs `translate3d(x, y, 0)`
        transform: CSS.Translate.toString(transform),
        // backgroundColor: "#121312", 
        // border: "none", 
        // padding: "0px",
        // borderRadius: "15px",
        // marginRight: "16px"
    };

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        {props.children}
        </div>
    );
}