import React, { useState, useEffect } from 'react';
import { withContext} from '../Context/context';
import { Button } from '@mui/material';
import * as SAVE from '../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import DeleteButton from '../Controls/deleteButton';
import EditBoxMulti from '../Controls/editBoxMulti';
import EditBoxSingle from '../Controls/editBoxSingle';

const CrystalDialog = (props) => {

    const [crystal, setCrystal] = useState({
        type: "",
        location: ""
    });
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (props.crystal) {
            setCrystal({...props.crystal});
            updateDisabled(props.crystal)
        }
    },[props.crystal])

    function handleOkClick() {
        if (props.crystal) {
            editCrystal(crystal);
        }
        else {
            var crystalId = crystal && crystal.id ? crystal.id : uuidv4(); 
            var newCrystal = {...crystal, id: crystalId, type: "POW storage crystal"}
            props.addItem(newCrystal);
            props.close();
        }
    }

    function updateDisabled(updatedCrystal) {
        var disabled = false; 
        if (! updatedCrystal.location || ! updatedCrystal.POW) {
            disabled = true;
        }
        setDisabled(disabled);
    }

    function handleCancelClick(){
        props.close();
    }
      
    function update(field, value) {
        var updatedCrystal = {...crystal};
        updatedCrystal[field] = value;
        setCrystal(updatedCrystal);
        updateDisabled(updatedCrystal)
    }
  
	function editCrystal(updatedCrystal, deleteFlag){
		var updatedCrystals = [...props.providerData.character.crystals]
		var index = updatedCrystals.findIndex(obj => obj.id === updatedCrystal.id);
        if (deleteFlag) {
            updatedCrystals.splice(index, 1);
        }
        else {
            updatedCrystals.splice(index, 1, crystal);
        }
        var updates = [{fieldName: "crystals", value: updatedCrystals}];
		var updatedMagicPoints = [...props.providerData.character.magicPoints]
		index = updatedMagicPoints.findIndex(obj => obj.entityId === updatedCrystal.id);
        if (index > -1) {
            updatedMagicPoints[index].total = updatedCrystal.POW;
        }
        updates.push({fieldName: "magicPoints", value: updatedMagicPoints});

        props.update(updates, SAVE.NO)
		props.close();
	}

      return (
            <div style={{overflow: "none"}}>
                <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                    <div style={{marginTop: "10px"}}>
                        <div style={{marginLeft:"10px", display:"flex", alignItems:"center", width: "400px"}}>
                            <EditBoxSingle label="Location:" autoFocus value={crystal.location} onChange={(value) => {update("location", value)}} width="300px" />
                        </div>
                        <div style={{marginLeft:"10px", display:"flex", alignItems: "center", marginTop: "10px", width: "136px"}}>
                            <EditBoxSingle label="POW:" labelMargin="36px" number value={crystal.POW} onChange={(value) => {update("POW", value)}} />
                        </div>
                        <div style={{marginLeft: "10px", width: "410px"}}>
                            <div style={{margin: "10px 0px"}}>Notes:&nbsp;</div>
                            <EditBoxMulti multiline rows={4} value={crystal.notes} onChange={(value) => {update("notes", value)}} width="390px" />
                        </div>

                        <div style={{display:"flex", marginTop:"30px", padding: "20px", alignItems:"flex-end", flexDirection: "row-reverse", justifyContent: "space-between"}}>
                            <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                                <Button type="submit" disabled={disabled} onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                                <Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
                            </div>
                            <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                                {props.crystal ? 
                                    <DeleteButton delete={(item) => editCrystal(item, true)} type="crystal" item={props.crystal} />
                                : 
                                null
                                }
                            </div>
                        </div>
                        {/* <div style={{height: "80px", display:"flex", flexDirection: "row-reverse", marginRight: "20px", alignItems: "center"}}>
                        </div> */}
                    </div>
                </div>
            </div>
    );
    
}
export default withContext(CrystalDialog);