import React from 'react';
import globalStyles from '../Styles/styles.module.css'

import MessageItem from './MessageItem';

const MessageList = ({
  isGM,
  messages,
}) => (
  <div className={globalStyles.panel} style={{height: "250px", width: "320px", borderRadius: "5px", border: "2px solid #2F588A",position: "relative", fontSize: "15px", padding: "0px", overflow: "auto", display: "flex", flexDirection: "column-reverse"}}>
  {messages && messages.map((message, index) => (
      <MessageItem
        key={message.uid}
        message={message}
        isGM={isGM}
        isLatest={index === 0}
      />
    ))}
  </div>
);

export default MessageList;
