import React, {useState, useEffect, useRef} from 'react';
import {withContext} from '../Context/context';
import { v4 as uuidv4 } from 'uuid';
import * as SAVE from '../../constants/save';
import { withPageState } from '../Context/PageState';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import InventoryArmourList from './inventoryArmourList';
import InventoryWeaponList from './inventoryWeaponList';

const InventoryContextPanel = (props) => {

    const [armour, setArmour] = useState([]);
    const [weapons, setWeapons] = useState([]);
    const [selectedWeapons, setSelectedWeapons] = useState([]);

    useEffect(() => {
        setWeapons([...props.entity.weapons]);
    },[props.entity.weapons])

    useEffect(() => {
        setArmour([...props.entity.armour]);
    },[props.entity.armour])

    function updateArmour(armour) {
        var updates = [];
        setArmour(armour);
        updates.push({fieldName: "armour", value: armour});
        var updatedHitPoints = {...props.entity.hitPoints}
        updatedHitPoints.locations.forEach(location => {
            var index = armour.findIndex(obj => (obj.covers.find(item => item === location.name) && obj.equipped));
            if (index < 0) {
                location.armour = {}
            }
            else {
                location.armour = {...armour[index]};
            }
        })
        updates.push({fieldName: "hitPoints", value: updatedHitPoints})
        props.update(updates, props.pageState.editMode ? SAVE.NO : SAVE.YES);
    }

    function addArmour(armourPiece) {
        var updates = [];
        var updatedArmour = [...armour]
        updatedArmour.push(
            {
                id: uuidv4(), 
                parentId: armourPiece.id,
                ENC: armourPiece.ENC,
                covers: [...armourPiece.covers],
                equipped: true,
                material: armourPiece.material,
                stealth: armourPiece.stealth,
                points: armourPiece.points,
                type: armourPiece.type,
            });
        updates.push({fieldName: "armour", value: updatedArmour});
        var updatedHitPoints = {...props.entity.hitPoints}
        updatedHitPoints.locations.forEach(location => {
            var index = updatedArmour.findIndex(obj => (obj.covers.indexOf(location.name) > -1) && obj.equipped);
            if (index < 0) {
                location.armour = {}
            }
            else {
                location.armour = {
                    id: updatedArmour[index].id,
                    material: updatedArmour[index].material,
                    points: updatedArmour[index].points,
                    type: updatedArmour[index].type,
                };
            }
        })
        updates.push({fieldName: "hitPoints", value: updatedHitPoints})
        props.update(updates, SAVE.NO);
    }

    function updateWeapons(weapons) {
        setSelectedWeapons(weapons);
        props.update([{fieldName: "weapons", value: weapons}], props.pageState.editMode ? SAVE.NO : SAVE.YES)
    }

    function addWeapon(newWeapon) {
        var updates = [];
        var updatedWeapons = [...weapons];
        updatedWeapons.push(newWeapon);
        updates.push({fieldName: "weapons", value: updatedWeapons});
        var index = props.entity.weaponSkills.findIndex(obj => obj.name === newWeapon.name);
        if (index < 0) {
            var newSkill = {
                id: uuidv4(),
                category: newWeapon.category,
                hasSucceeded: false,
                name: newWeapon.name,
                parentId: newWeapon.parentId,
                skillCategory: "Manipulation",
                value: newWeapon.baseValue
            }
            var updatedSkills = [...props.entity.weaponSkills]
            updatedSkills.push(newSkill);
            updates.push({fieldName: "weaponSkills", value: updatedSkills})
        }
        props.update(updates, SAVE.NO)
    }

    return (
            <div style={{fontSize: "14px", lineHeight: "1"}}>
                <div style={{height: "800px", overflow: "auto"}}>
                    <InventoryArmourList armour={props.entity.armour} setArmour={updateArmour} addArmour={addArmour} />
                    <InventoryWeaponList weapons={weapons} categoryModifier={props.entity.categoryModifiers.Manipulation} weaponSkills={props.entity.weaponSkills} selected={selectedWeapons} updateWeapons={updateWeapons} addWeapon={addWeapon} selectionTitle="Equipped" />
                </div>
            </div>
    )
}
export default withContext(withPageState(InventoryContextPanel))