import React, {useState, useEffect, useRef} from 'react';
import CloseIcon from '../../icons/close-icon.svg';
import { withContext } from '../Context/context';
import { Button } from '@mui/material';
import { withPageState } from '../Context/PageState';

const DetailsPanel = (props) => {

    const [title, setTitle] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("")
    const [buttonClick, setButtonClick] = useState("");

    useEffect(() => {
        if (props.red) {
            setBackgroundColor("linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)")
        }
        else {
            setBackgroundColor("linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)")
        }
    },[props.red])

    useEffect(() => {
        if (buttonClick !== "") {
            props.close();
        }
    },[buttonClick])

    useEffect(() => {
        let itemTitle = "";
        let item;
        var type = props.panelItem.type.toLowerCase() + "s";
        if (type === "weapons") {
            item = {...props.panelItem.item.weapon};
        }
        else {
            item = {...props.panelItem.item};
        }
        if (item) {
            itemTitle = props.npcName ? props.npcName + " ": "";
            itemTitle += item.title ? item.title : item.name + (item.variant ? " (" + item.variant + ")" : "")
            if (item.title && item.title !== item.name) {
                itemTitle += " (" + item.name + ")";
            }
            else if (item.name.first) {
                itemTitle += item.name.first;
            }
            setTitle(itemTitle);
        }
    },[props.panelItem]);

    function clickButton(command) {
        setButtonClick(command);
    }

    return (
        <div style={{width:"100%", overflow: "auto", display:"flex", flexDirection:"column"}}>
            <div id="header" style={{background: "rgb(47,88,138)", background: backgroundColor, height: "24px", width: "100%", fontSize: "18px", padding: "10px 0px", textAlign: "center", color: "#fff"}}>
                <div>{title}</div>
                <div style={{position: "absolute", right: "10px", top: "10px", cursor: "pointer"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <img style={{width: "25px", marginLeft: "10px"}} src={CloseIcon} onClick={props.close} />
                    </div>
                </div>
            </div>
            <div style={{padding: "10px", fontSize: "14px", lineHeight: "1", height: (props.panelHeight ? props.panelHeight - 64 : 736) + "px", overflow: "auto"}}>
                {React.cloneElement(props.children, {...props, buttonClick:buttonClick })}
            </div>
            {/* {props.pageState.editMode ? 
                <div style={{padding: "0px 10px"}}>
                    <hr />
                    <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end", margin: "20px 0px"}}>
                        <Button onClick={() => clickButton("ok")} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                        <Button onClick={() => clickButton("cancel")} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
                    </div>
                </div>
                : 
                null 
            } */}
        </div>
    )
}

export default withContext(withPageState(DetailsPanel));