import React, { useState, useEffect } from 'react';
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import globalStyles from '../../Styles/styles.module.css';
import { Checkbox } from "@mui/material";
import HideButton from "../controls/hideButton";
import EditBox from "../../Controls/editBoxSingle";
import AddWeaponButton from '../buttons/addWeaponButton';
import AddWeaponDialog from "../dialogs/addWeaponDialog";
import compareValues from '../../../utils/compareValues';
import * as SAVE from '../../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import calculateSR from '../../../services/strikeRankService';

const WeaponsPanel = (props) => {

    const [weapons, setWeapons] = useState([]);
    const [catMod, setCatMod] = useState(0);
    const [stats, setStats] = useState({STR: 20, DEX: 20})

    useEffect(() => {
        setCatMod(props.providerData.character.categoryModifiers.Manipulation || 0);
    },[props.providerData.character.categoryModifiers]);

    useEffect(() => {
        var updatedStats = {...stats};
        var characteristics = {...props.providerData.character.characteristics}
        updatedStats.STR = characteristics.STR.baseValue + (characteristics.STR.statBonus || 0);
        updatedStats.DEX = characteristics.DEX.baseValue + (characteristics.DEX.statBonus || 0);
        setStats(updatedStats);
    },[props.providerData.character.characteristics])

	useEffect(() => {
        if (props.providerData.character) {
            var characterWeapons = [];
            props.providerData.character.weapons.forEach(weapon => {
                var index = props.providerData.character.weaponSkills.findIndex(item => item.parentId === weapon.parentId);
                if (index > -1) {
                    characterWeapons.push({...weapon, value: props.providerData.character.weaponSkills[index].value})
                }
            })
            setWeapons(characterWeapons.sort(compareValues('value', 'desc')));
        }
	},[props.providerData.character.weaponSkills]);

    const getValue = (item) => {
        if ((item.value + catMod) < 0) {
            return 0;
        }
        else {
            return item.value + catMod;
        }
    }

    const updateSkill = (value, item) => {
        var updatedWeapons = [...weapons]
        var index = updatedWeapons.findIndex(obj => obj.id === item.id)
        updatedWeapons[index].value = value - catMod;
        setWeapons(updatedWeapons);
        var updatedValue = value - catMod;
        props.handleValueChange(updatedValue, item);
    }

	function displayWeapon(item, index) {
        if (item.hidden && ! props.pageState.editMode) return null;
        var damage = item.damage.modifier; 
        if (item.damage.quantity > 0) {
             damage = item.damage.quantity + "D" + item.damage.dieType + (item.damage.modifier ? "+" + item.damage.modifier : "");
        }
		if ((item.damage.bonus > 0) && props.providerData.character.damageBonus && props.providerData.character.damageBonus.quantity > 0) {
			damage = damage + ("+" + props.providerData.character.damageBonus.quantity + "D" + (props.providerData.character.damageBonus.dieType * item.damage.bonus));
		}
		var sr = calculateSR("DEX", props.providerData.character)
		if (item.class === "Melee" || item.class === "Shield") {
			sr = sr + item.sr + calculateSR("SIZ", props.providerData.character)
		}
		else {
			sr = item.rate
		}
        var lowStats = false;
        var minSTR = item.minSTR || 0;
        var minDEX = item.minDEX || 0;
        var lowStats = minSTR > stats.STR;
        if (!lowStats) {
            if (minDEX > stats.DEX) {
                if ((stats.STR - minSTR) < ((minDEX - stats.DEX) *2)) {
                    lowStats = true;
                }
            }
        }
        return(
			<div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", color: lowStats ? "#851113" : "#121312", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                {props.pageState.editMode ? 
                <HideButton item={item} type="weapons" name={item.title} update={(hidden) => props.hideItem("weapons", item, hidden)} />
                : 
				<Checkbox style={{cursor: "default", width: "18px", padding: "1px", marginRight: "5px"}} checked={false} />
                }
                <div style={{width:"150px", textAlign: "left", display: "flex"}}>
                    <div>{item.title + (item.hidden ? " (hidden)" : "")}</div>
                    <div style={{cursor: "default"}} title="Insufficient STR and/or DEX">{lowStats ? "**" : ""}</div>
                </div>
                {props.pageState.editMode ? 
                    <div style={{width: "50px"}}><EditBox number style={props.buttonStyle} value={getValue(item)} onChange={(value) => updateSkill(value, item)}  /> </div>
                        : 
                    <div style={{width:"50px", textAlign: "center"}}>{Math.floor(((getValue(item)) * (lowStats ? 0.5 : 1)))}%</div>
                }
				<div style={{width:"100px", textAlign: "center"}}>{damage}</div>
				<div style={{width:"30px", textAlign: "center"}}>{sr}</div>
				<div style={{width:"30px", textAlign: "center"}}>{item.range || "-"}</div>
				<div style={{width:"30px", textAlign: "center"}}>{item.hitPoints.current}</div>
			</div>
		);
	}

    function addWeapon(weapon) {
        weapon.id = uuidv4();
        var updates = [];
        var updatedWeapons = [...weapons];
        var value = weapon.baseValue;
        var index = props.providerData.character.weaponSkills.findIndex(obj => obj.name === weapon.name);
        if (index > -1) {
            value = props.providerData.character.weaponSkills[index].value;
        }
        updatedWeapons.push({...weapon, value: value});
        setWeapons(updatedWeapons);

        updates.push({fieldName: "weapons", value: updatedWeapons});

        if (index < 0) {
            var updatedSkills = [...props.providerData.character.weaponSkills];
            updatedSkills.push({
                id: uuidv4(), 
                name: weapon.name,
                parentId: weapon.parentId, 
                category: weapon.category, 
                hasSucceeded: false,
                value: weapon.baseValue, 
                skillCategory: "Manipulation"
            });
            updates.push({fieldName: "weaponSkills", value: updatedSkills});
        }
        props.update(updates, SAVE.NO)
    }

    return (
        <div id="weapons">
        <div style={{background: "linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", justifyContent: "space-between", alignItems:"center"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Weapons</div>
                <div style={{marginLeft: "20px"}}>{props.providerData.character.categoryModifiers.Manipulation < 0 ? "" : "+"}{props.providerData.character.categoryModifiers.Manipulation || 0}%</div>
            </div>
            <div style={{width: "30px"}}>
            {props.pageState.editMode ?
                        <AddWeaponButton title="Add weapon" addItem={addWeapon}>
                            <AddWeaponDialog />
                        </AddWeaponButton>
                    : 
                    null}
            </div>
        </div>
        { 
        weapons ? 
            <>
                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", fontWeight:"bold", overflow: "hidden", padding: "5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                        <div style={{width:"20px"}}></div>
                        <div style={{width:"150px", textAlign: "left"}}>Weapon</div>
                        <div style={{width:"50px", textAlign: "center"}}>%</div>
                        <div style={{width:"100px", textAlign: "center"}}>Damage</div>
                        <div style={{width:"30px", textAlign: "center"}}>SR</div>
                        <div style={{width:"30px", textAlign: "center"}}>Range</div>
                        <div style={{width:"30px", textAlign: "center"}}>HPs</div>
                    </div>
                <div style={{borderBottom: "2px solid #121312"}}>
                    {weapons.filter((item) => {return item.class === "Melee"}).map((item, index) => {
                        {return displayWeapon(item, index)}
                    })}
                </div>
                <div style={{borderBottom: "2px solid #121312"}}>
                {weapons.filter((item) => {return item.class === "Missile"}).map((item, index) => {
                    {return displayWeapon(item, index)}
                })}
            </div>
                <div>
                {weapons.filter((item) => {return item.class === "Shield"}).map((item, index) => {
                    {return displayWeapon(item, index)}
                })}
            </div>
            </>
        :
        <div style={{backgroundColor: "#BFD0CD", color: "#851113", fontSize: "20px", display: "flex", alignItems: "center", justifyContent:"center", marginTop: "2px", height: "83px"}}>None</div>
        }
    </div>

    )

}

export default withContext(withPageState(WeaponsPanel));