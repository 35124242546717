import React, { useState, useEffect} from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { withFirebase } from '../Firebase';
import * as NewCharacter from '../../data/newCharacter.json';
import * as ExistingCharacter from '../../data/existingCharacter.json';
import { createBlankCharacter } from '../../services/characterService';
import EditBox from '../Controls/editBoxSingle';

const CreateCharacterDialog = (props) => {

    const [type, setType] = useState("");
    const [step, setStep] = useState("select");
    const [link, setLink] = useState("");
    const [campaignId, setCampaignId] = useState("")

	const navigate = useNavigate();

	function createCharacter(authUser, useBuilder) {
		var char = useBuilder ? {...NewCharacter} : {...ExistingCharacter};
		var newcharacter = char.default;
		newcharacter.uid = authUser.uid;
		var now = new Date();
		newcharacter.name = {first: authUser.firstName + " " + now.getHours() + now.getMinutes() + now.getSeconds()} ;
		props.firebase
		.characters()
		.add(
			newcharacter
		)
		.then(function(docRef) {
            newcharacter.id = docRef.id
            if (campaignId) {
                newcharacter.campaignId = campaignId
            }
            else {
                newcharacter.campaignId = docRef.id
            }
            newcharacter.created = new Date();
            props.firebase
            .character(docRef.id)
            .set(newcharacter)
            .then( function() {
                navigate("/builder/" + docRef.id);
            });
		})
	}

    function okClick() {
        if (step === "select") {
            setStep("campaign")
        }
        else {
            if (type === "create") {
                createCharacter(props.authUser, true)
            }
            else {
                props.importCharacter()
            }
            props.close();
        }
    }

    function parseCampaignId(value) {
        if (value) {
            let index = value.indexOf("/invite/") + 8;
            setCampaignId(value.substr(index));
            setLink(value);
        }
    }

    return (
        <div style={{padding: "20px"}}>
            {step === "select" ? 
                <div  style={{display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:"space-between", padding:"0px 20px", overflow:"hidden", }}>
                    <h3 style={{marginLeft: "20px"}}>What would you like to do today?</h3>
                    <div style={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                        <div>
                            <Button onClick={() => setType("create")} style={{width:"480px", height:"200px", backgroundColor: type === "create" ? "#2f588a" : "#fff", color: type === "create" ? "#fff" : "#121312"}} size="large" variant="contained" color="primary">
                                <div>
                                    <h3>Create a new adventurer</h3>
                                    <p>Create a new adventurer using the RuneQuest character generation rules. This will take you through each step listed in the RuneQuest core rulebook.</p>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <h3 style={{marginLeft: "20px"}}>Or...</h3>
                    <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", marginTop:"10px"}}>
                        <div>
                            <Button disabled={false} onClick={() => setType("import")} style={{width:"480px", height:"200px", backgroundColor: type === "import" ? "#2f588a" : "#fff", color: type === "import" ? "#fff" : "#121312"}} size="large" variant="contained" color="primary">
                                <div>
                                    <h3>Import an existing character</h3>
                                    <p>Import an existing adventurer previously created using the RuneQuest character generation system.</p>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            :
                <div>
                    <div>If your GM has given you a link to join a campaign, enter it here now.</div>
                    {type === "create" ? 
                        <div style={{marginTop: "10px"}}>If you do not yet have a link to a campaign, no problem, you can create your character now and then add them to a campaign at a later date. However, you will not have access to any custom content your GM has created for their campaign during the character building process.</div>
                        : 
                        <div style={{marginTop: "10px"}}>If you do not yet have a link to a campaign, no problem, you can import your character now and then add them to a campaign at a later date.</div>
                    }
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "20px"}}>
                        <div>Campaign link: </div>
                        <EditBox size="sm" width="420px" height="24px" value={link} onChange={parseCampaignId} />
                    </div>
                </div>
            }

            <div style={{display:"flex", justifyContent:"space-around", alignItems:"center", marginTop:"30px"}}>
                <div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end", borderTop: "2px solid #2f588a", width: "100%"}}>
                    <Button onClick={okClick} style={{minWidth: "100px"}} color="primary" variant="contained">{step==="select" ? "Next" : "OK"}</Button>
                    <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "40px"}} >Cancel</Button>
                </div>
            </div>
        </div>                

    );
}

export default withFirebase(CreateCharacterDialog);