import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';

const SpiritSpellTile = (props) => {

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(props.availableMagicPoints < props.item.points);
    },[props.availableMagicPoints, props.item])
        
    function processResult(roll) {
        var spellName = roll.item.variable ? roll.item.name + " " + roll.item.points : roll.item.name + (roll.item.variant ? " " + roll.item.variant : ""); 
        var messageText={critical: " achieved a critical success when casting " + spellName, special: " achieved a special success when casting " + spellName, success: " successfully cast " + spellName, failure: " failed to cast " + spellName, fumble: " fumbled when casting " + spellName}
        var dieRoll = {target: roll.target, result: roll.result, augmentRoll: false, messageText: messageText}
        var action = {type: "spirit", spell: roll.item, spellcaster: props.spellcaster, points: roll.item.points, boost: 0}
        props.processResult(dieRoll, action, roll.title);
    }

    return(
        React.cloneElement(props.element, {...props, disabled: disabled, processResult: processResult })
    )
}

export default withPageState(SpiritSpellTile);