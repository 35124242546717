import React, { useRef, useState } from "react";

const InfoButton = (props) => {

    const [show, setShow] = useState(false);

    const infoBox = useRef(null)

    return (
        <>
            <div onClick={() => setShow(!show)} style={{backgroundColor: "#fff", borderRadius: "30px", marginRight: "10px", border: "1px solid #121312", marginLeft: "4px", fontSize: "12px", color: "#121312", minWidth: "14px", height: "14px", marginTop: "-8px", display:"flex", cursor: "pointer", alignItems: "center", justifyContent: "center", fontWeight: "bold"}}>?</div>
            <div style={{position: "absolute", top: 25, left: 0, zIndex: 399, width: "300px"}} ref={infoBox} onMouseOver={() => infoBox.current.focus()} onBlur={() => {setShow(false)}} tabIndex={0}>
                {show ? 
                    <div onClick={() => setShow(false)} style={{padding: "5px", fontSize: "14px", border: "1px solid #2f588a", borderRadius: "5px", backgroundColor: "#fff", color: "#2f588a"}}>
                        <p>{props.title}</p>
                        <p style={{marginTop: "10px"}}>{props.info}</p>
                    </div>
                : null}
            </div>
        </>
    )
}

export default InfoButton