import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import EditIcon from '../../icons/edit-icon-white.svg'
import DialogBox from '../Dialogs/dialogBox';
import CrystalDialog from './crystalDialog';
import MagicPointIncrementor from '../Controls/magicPointIncrementor';

const StorageCrystal = (props) => {

    const [open, setOpen] = useState(false);
    const [crystal, setCrystal] = useState(props.crystal);

    useEffect(() => {
        if (props.crystal) {
            setCrystal({...props.crystal});
        }
    },[props.crystal])

    return (
        <>
        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#2F7589", color: "#fff", padding: "0px 5px", height: "30px", position: "relative"}}>
            <div>{crystal.location} </div>
            {props.pageState.editMode ? 
                <div style={{position: "absolute", right: "10px", top: "4px", cursor: "pointer"}} onClick={() => setOpen(true)}>
                    <img src={EditIcon} style={{width: "22px"}} alt="Edit matrix" title="Edit matrix" />
                </div>
            : 
                null
            }
        </div>
            <div style={{padding: "7px"}}>
                <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div style={{display:"flex"}}>
                        <div>POW:&nbsp;</div>
                        <div> {crystal.POW}</div>
                    </div>
                    {props.pageState.editMode ?
                    null
                    : 
                    <MagicPointIncrementor update={props.update} entityId={crystal.id} magicPoints={props.character.magicPoints} />
                    }
                </div>
            </div>
            <DialogBox isOpen={open} width="450px" title={"Edit crystal"}>
                <CrystalDialog close={() => {setOpen(false)}} update={props.update} crystal={crystal} />
            </DialogBox>
        </>
    )
}

export default withPageState(StorageCrystal);    