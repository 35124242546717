import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { CssBaseline, Typography, Container, Paper, Button, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import globalStyles from '../Styles/styles.module.css'
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import EncounterCard from './encounterCard';
import { AuthUserContext } from '../Session';

const EncounterList = (props) => {
  const [encounters, setEncounters] = useState();
  const [campaigns, setCampaigns] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState("")
	const navigate = useNavigate();

  useEffect(() => {
    var allCampaigns = [];
    props.firebase
    .campaigns()
    .get()
    .then((results) => {
        results.forEach((campaign) => {
          allCampaigns.push({
            parentId: campaign.id,
            name: campaign.data().name,
          });
        });
        setCampaigns(allCampaigns);
    })
    
  }, []);

  if (!campaigns) return null;
  return (
    <div>
      <div className={globalStyles.encounterBanner}></div>
      <AuthUserContext.Consumer>
        {authUser => (
            <React.Fragment>
              <CssBaseline />
              <Container maxWidth="lg">
                <Paper elevation={3}>
                  <Typography component="div" style={{backgroundColor:"#eceff1", height: '100vh', padding:"20px" }}>
                    <Button onClick={() => createEncounter(authUser)} style={{minWidth:"10em"}} size="large" variant="contained" color="primary">Create a encounter</Button>
                      <div className={globalStyles.horizontalLine}></div>
                      <div >
                      <FormControl variant="outlined" >
                        <InputLabel id="occupation-select-label">Campaign</InputLabel>
                        <Select value={displayCampaign()} style={{backgroundColor:"#fff", height: "32px", minWidth:"10em", fontSize:"13px"}} autoWidth={true} labelId="occupation-select-label" onChange={handleSelect} label="Select your occupation" color="primary">
                          <MenuItem key={0} value="Select">
                              <em>Select</em>
                          </MenuItem>
                          {
                              campaigns.map(item => (
                                  getItem(item)

                              ))
                          }
                        </Select>
                      </FormControl>
                      {encounters ? 

                        <div  style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", padding:"0px 20px", overflow:"hidden", }}>
                          {encounters.map((item) => {
                            return (
                              <div key={item.id}>
                                <div style={{width:"500", height:"450"}}>
                                  <div style={{ height: 'auto', width:'auto'}}>
                                  <a href={'/encounter/' + item.id}>
                                  <EncounterCard encounter={item} deleteEncounter={deleteEncounter} showDelete={showDelete(item, authUser)}></EncounterCard>
                                  </a>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      :
                        getEncounters(authUser)
                      }
                      </div>                
                    </Typography>
                  </Paper>
              </Container>
            </React.Fragment>
        )}
      </AuthUserContext.Consumer>
    </div>
  );
   
  function deleteEncounter(encounterId){

    props.firebase
    .npcs()
    .where('encounterId', '==', encounterId)
    .get()
    .then((results) => {
      results.forEach((npc) => {
        props.firebase
        .npc(npc.id)
        .delete()
      })
    });

    props.firebase
      .encounters()
      .doc(encounterId)
      .delete()
      .then(() => {
        var updatedEncounters = [];
        encounters.forEach((encounter) => {
          if (encounter.id !== encounterId) {
            updatedEncounters.push(encounter);
          }
        });
        setEncounters(updatedEncounters);
      });
  }
 
  function showDelete(item, authUser){
    if (item.uid === authUser.uid) return true
    else return false;
  }
 
  function displayCampaign() {
    return selectedCampaign ? selectedCampaign.name : "Select";
  }

  function getItem(item) {
    return (
        <MenuItem style={{fontSize:"13px"}} key={item.parentId} value={item.name}>{item.name}</MenuItem>
    )
  }
    
  function handleSelect(event) {
    var allCampaigns = [...campaigns];
    var campaign = allCampaigns.filter(function(e) {
        return e.name === event.target.value;

    })
  
    if (campaign && campaign.length > 0) {
        setSelectedCampaign (campaign[0]);
        setEncounters(null);
    }
  }

  function createEncounter(authUser) {
    navigate("/newEncounter/" + authUser.id);

    }
        
  function getEncounters(authUser){
    if (!selectedCampaign) return [];
    var encounters = [];
    props.firebase
    .encounters()
    .where("uid", "==", authUser.uid)
    .where("campaignId", "==", selectedCampaign.parentId )
    .get()
    .then(querySnapshot => 
    {
      querySnapshot.forEach(function(doc) {
        const id = doc.id;
        const data = doc.data();
        var encounter = {id, ...data}
          encounters.push(encounter);
      });
      setEncounters(encounters);
    });
  }
}

export default withFirebase(withEmailVerification(withAuthorization(EncounterList)));
