import { update } from 'lodash';
import * as SAVE from '../constants/save';
import { getStatValue } from './attributeService';

export function deductMagicPoints(char, spellcaster, points) {
    var pointsRemaining = points;
    var character = {...char}
    var updatedMagicPoints = [...character.magicPoints];

    if (spellcaster.type === "Bound spirit") {
        reduceSpecificEntity(updatedMagicPoints, spellcaster.id, pointsRemaining);
    }
    else if (char.id !== spellcaster.id) {
        reduceSpecificEntity(updatedMagicPoints, spellcaster.id, pointsRemaining);
    }
    else {
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "POW storage crystal", pointsRemaining, 0);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Bound spirit", pointsRemaining, 1);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Allied spirit", pointsRemaining, 1);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Fetch", pointsRemaining, 1);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Character", pointsRemaining, 1);

        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Bound spirit", pointsRemaining, 0);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Allied spirit", pointsRemaining, 0);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Fetch", pointsRemaining, 0);
        if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Character", pointsRemaining, 0);
    }

    character.magicPoints = updatedMagicPoints;
    return character; 
}

function increaseEntityPoints(updatedMagicPoints, type, points) {
    var pointsRemaining = points;
    updatedMagicPoints.forEach(magicPoints => {
        if (magicPoints.type === type) {
            pointsRemaining = increasePoints(magicPoints, pointsRemaining)        
        }
    })
    return pointsRemaining;
}

const increasePoints = (magicPoints, pointsRemaining) => {
    if (magicPoints.current + pointsRemaining < magicPoints.total) {
        magicPoints.current += pointsRemaining;
        pointsRemaining = 0;
    }
    else {
        pointsRemaining -= (magicPoints.total - magicPoints.current);
        magicPoints.current = magicPoints.total;
    }
    return pointsRemaining;
}

function reduceSpecificEntity(updatedMagicPoints, entityId, points) {
    var index = updatedMagicPoints.findIndex(item => item.entityId === entityId);
    if (index > -1) {
        updatedMagicPoints[index].current -= points;
    }
    return 0;
}

function reduceEntityPoints(updatedMagicPoints, type, points, minimum) {
    var pointsRemaining = points;
    updatedMagicPoints.forEach(magicPoints => {
        if (magicPoints.type === type) {
            pointsRemaining = reducePoints(magicPoints, pointsRemaining, minimum)        
        }
    })
    return pointsRemaining;
}

const reducePoints = (magicPoints, pointsRemaining, limit) => {
    if (magicPoints.current > pointsRemaining) {
        magicPoints.current -= pointsRemaining;
        pointsRemaining = 0;
    }
    else {
        pointsRemaining -= (magicPoints.current - limit);
        magicPoints.current = limit;
    }
    return pointsRemaining;
}

export function getAvailableMagicPoints(character, caster) {
    var spellcaster = caster || character;
    var points = {
        current: 0,
        total: 0
    }
    
    character.magicPoints.forEach(entity => {
        if ((entity.entityId === spellcaster.id) || (character.id === spellcaster.id)) {
            points.current += entity.current;
            points.total += entity.total;
        }
    })

    return points; 
}

export function incrementAvailableMagicPoints(char, points) {
    var character = {...char}

    var updatedMagicPoints = [...character.magicPoints];
    var pointsRemaining = points;

    if (pointsRemaining > 0) pointsRemaining = increaseEntityPoints(updatedMagicPoints, "Character", pointsRemaining);
    if (pointsRemaining > 0) pointsRemaining = increaseEntityPoints(updatedMagicPoints, "Fetch", pointsRemaining);
    if (pointsRemaining > 0) pointsRemaining = increaseEntityPoints(updatedMagicPoints, "Allied spirit", pointsRemaining);
    if (pointsRemaining > 0) pointsRemaining = increaseEntityPoints(updatedMagicPoints, "Bound spirit", pointsRemaining);
    if (pointsRemaining > 0) pointsRemaining = increaseEntityPoints(updatedMagicPoints, "POW storage crystal", pointsRemaining);
    return [{fieldName: "magicPoints", value: updatedMagicPoints}];
}

export function decrementAvailableMagicPoints(char, points) {
    var character = {...char}

    var updatedMagicPoints = [...character.magicPoints];
    var pointsRemaining = points;

    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "POW storage crystal", pointsRemaining, 0);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Bound spirit", pointsRemaining, 1);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Allied spirit", pointsRemaining, 1);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Fetch", pointsRemaining, 1);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Character", pointsRemaining, 1);

    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Bound spirit", pointsRemaining, 0);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Allied spirit", pointsRemaining, 0);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Fetch", pointsRemaining, 0);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedMagicPoints, "Character", pointsRemaining, 0);
    return [{fieldName: "magicPoints", value: updatedMagicPoints}];
}

export function calculateSpellStorage(entity) {
    var points = getStatValue(entity.characteristics.CHA);

    if (entity.boundSpirits) {
        entity.boundSpirits.forEach(spirit => {
            if (spirit.type === "Fetch") {
                points += getStatValue(spirit.characteristics.CHA)
            }
        })
    }
    return points;
}
