import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import OccupationCards from '../step7Occupation/occupationCards';

const SelectOccupationDialog = (props) => {

    const [occupations, setOccupations] = useState([]);
    const [selected, setSelected] = useState();

    useEffect(() => {
        setOccupations(props.occupations);
    },[props.occupations]);
    
    useEffect(() => {
        setSelected(props.occupation)
    },[props.occupation]);

    function handleSelect(occupation) {
        setSelected(occupation);
    }
	  
	function handleOkClick(){
        props.close();
        props.handleSelect(selected);
	}

    return (
        <div>
            <div style={{fontSize: "18px", margin: "14px"}}>Select the occupation of your {props.relative}</div>	
                <OccupationCards occupations={occupations} size={{width: "120px", height: "120px", fontSize: "14px"}} handleSelect={handleSelect} occupation={selected} />
                <div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
                    <Button onClick={handleOkClick} disabled={!selected} id="diceButton" color="primary" variant="contained" style={{width: "100px"}}>Ok</Button>
                    <Button onClick={() => {props.close()}} id="diceButton" color="primary" variant="outlined" style={{marginRight:"30px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
				</div>
        </div>
    );
}

export default SelectOccupationDialog;