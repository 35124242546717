import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { withPageState } from '../../Context/PageState';
import globalStyles from '../../Styles/styles.module.css';
import { Checkbox } from '@mui/material';
import { getCategoryModifiers } from '../../../services/skillsService';
import {calculateDamageBonus, calculateMagicPoints, calculateMaxEncumbrance, calculateSpiritCombatDamage, calculateStrikeRanks} from '../../../services/attributeService';
import * as SAVE from '../../../constants/save';
import HitLocationPanel from '../hitLocationPanel';
import CharacterContext from '../../Context/context';
import { useReactToPrint } from 'react-to-print';
import EventHistory from '../../Builder/step3History/eventHistory';
import SaveIcon from '../../../icons/white-save-icon.svg';
import CancelIcon from '../../../icons/cancel-icon.svg';
import EditButton from '../../Buttons/editButton';
import EditBox from '../../Controls/editBoxSingle';
import { calculateHitPoints } from '../../../services/hitPointsService';
import PrintIcon from '@mui/icons-material/Print';
import DiceIcon from '../../../icons/dice-icon.svg';
import PassionsPanel from './passionsPanel';
import SkillsPanel from './skillsPanel';
import SpiritMagicPanel from './spiritMagicPanel';
import WeaponsPanel from './weaponsPanel';
import RuneMagicPanel from './runeMagicPanel';
import RunesPanel from './runesPanel';
import ProfileImage from '../controls/profileImage';
import BoundSpiritPanel from './boundSpiritPanel';
import RuneSpellMatrixPanel from './runeSpellMatrixPanel';
import SpiritSpellMatrixPanel from './spiritSpellMatrixPanel';
import CrystalsPanel from './crystalsPanel';
import { getAvailableMagicPoints } from '../../../services/magicPointsService';
import EditBoxMulti from '../../Controls/editBoxMulti';
import CampaignButton from '../buttons/campaignButton';

const CharacterSheet = React.forwardRef((props, ref) => {

    const params = useParams();
    const navigate = useNavigate();
    const buttonStyle = {height: "20px", backgroundColor: "#fff", color: "#121312", flexDirection: "row", borderRadius: "2px", border: "1px solid #2f588a"};

    const [dialogs, setDialogs] = useState({
        weapons: false,
        spiritSpells: false,
        runeSpells: false,
        passions: false,
        skills: false
    })
	const [authUser, setAuthUser] = useState(null);
    const [character, setCharacter] = useState(null);
	const [spiritCombat, setSpiritCombat] = useState({
		value: 0,
		damage: {
			quantity: 0,
			dieType: 0,
			modifier: 0
		}
	})
	const [skills, setSkills] = useState({
		agility: [],
		communication: [],
		knowledge: [],
		magic: [], 
		manipulation: [],
		perception: [],
		stealth: []
	});
	const [dmgBonus, setDmgBonus] = useState({quantity: 0, dieType: 0});
    const [imported, setImported] = useState(false);

    useEffect(() => {
        props.firebase
        .character(params.uid)
        .get()
        .then((doc) => {
            if (doc.exists) {
                var character = {...doc.data()};
                character.id = doc.id;
                setCharacter(character);
                setImported(character.import);
            }
        });
    }, [params]);

    useEffect(() => {
        setAuthUser({...props.authUser});
    }, [props.authUser]);

	useEffect(() => {
        if (character) {
            var sc = {};
            var index = character.skills.findIndex(item => item.name === "Spirit Combat");
            sc.value = character.skills[index].value;
            sc.damage = {...character.spiritCombat.damage};
            setSpiritCombat(sc);
        }
	},[character])

	useEffect(() => {
        if (character) {
            var updatedSkills = {...skills};
            if (character.skills && character.skills.length > 0) {
                character.skills.forEach(skill => {
                switch(skill.skillCategory) {
                    case "Agility": 
                        updatedSkills.agility.push({...skill});
                        return;
                    case "Communication": 
                        updatedSkills.communication.push({...skill});
                        return;
                    case "Knowledge": 
                        updatedSkills.knowledge.push({...skill});
                        return;
                    case "Magic": 
                        updatedSkills.magic.push({...skill});
                        return;
                    case "Manipulation": 
                        updatedSkills.manipulation.push({...skill});
                        return;
                        case "Perception": 
                        updatedSkills.perception.push({...skill});
                        return;
                    case "Stealth": 
                        updatedSkills.stealth.push({...skill});
                        return;
                    }
                });
                setSkills(updatedSkills);
                setDmgBonus(calculateDamageBonus(character.characteristics));
            }
        }
	},[]);

	function handleCancelClick(){
        navigate("/characters");
	}
	  
    function getCults() {
        if (props.pageState.editMode) {
            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <EditBox width="100px" size="sm" value={character.cults[0].rank} onChange={updateRank} />
                    <div style={{marginLeft: "10px"}}>{" of " + character.cults[0].name}</div>
                </div>
                
            )
        }
        else {
            var cults = "";
            character.cults.forEach((cult, index) => {
                cults += cult.rank + " of " + cult.name;
                cults += index < character.cults.length -1 ? ", " : ""; 
            });
            return <div>{cults}</div>;
        }
    }

    function updateRank(newRank) {
        var updatedCults = [...character.cults];
        updatedCults[0].rank = newRank;
        updateFields([{fieldName: "cults", value: updatedCults}]);
    }

    function handleAbilityChange(value, item, type) {
        var updatedCharacter = {...character};
        var index = updatedCharacter[type].findIndex(obj => obj.id === item.id);
        updatedCharacter[type][index].value = value;
        setCharacter(updatedCharacter);
    }

    function handleSpellChange(value, item) {
        var updatedCharacter = {...character};
        var index = updatedCharacter.spiritSpells.findIndex(obj => obj.id === item.id);
        updatedCharacter.spiritSpells[index].points = value;
        setCharacter(updatedCharacter);
    }

    function handleSpiritChange(value, item) {

    }

    function removeImport() {
        setImported(false);
        var updatedCharacter = {...character}
        updatedCharacter.import = false;
        saveCharacter(updatedCharacter);
    }

    function handleStatChange(value, statName) {
        var updatedCharacteristics = {...character.characteristics}
        updatedCharacteristics[statName].baseValue = value;
        var updatedCharacter = {...character};
        updatedCharacter.categoryModifiers = getCategoryModifiers(updatedCharacteristics);
        updatedCharacter.strikeRanks = calculateStrikeRanks(updatedCharacteristics);
        updatedCharacter.damageBonus = calculateDamageBonus(updatedCharacteristics);
        updatedCharacter.spiritCombat.damage = calculateSpiritCombatDamage(updatedCharacteristics);
        updatedCharacter.magicPoints[0].total = updatedCharacter.magicPoints[0].current = calculateMagicPoints(updatedCharacteristics);
        updatedCharacter.hitPoints = calculateHitPoints(updatedCharacteristics, {...character.hitPoints});
        updatedCharacter.maxEnc = calculateMaxEncumbrance(updatedCharacteristics);
        updatedCharacter.characteristics = updatedCharacteristics;
        setCharacter(updatedCharacter);
    }

    function handleValueChange(value, type) {
        var updatedCharacter = {...character};
        switch (type) {
            case "wealth": 
                updatedCharacter.wealth.silver = value;
                break;
            case "spiritCombat": 
                let index = updatedCharacter.skills.findIndex(item => item.name === "Spirit Combat");
                let newValue = value - (character.categoryModifiers.Magic || 0);
                updatedCharacter.skills[index].value = newValue;
                var updatedSpiritCombat = {...spiritCombat};
                updatedSpiritCombat.value = newValue;
                setSpiritCombat(updatedSpiritCombat)
                break;
            case "runePoints":
                updatedCharacter.cults[0].runePoints[0].current = value;
                updatedCharacter.cults[0].runePoints[0].total = value;
                break;
            default:
                updatedCharacter[type] = value;
                break;
        }
        setCharacter(updatedCharacter);
    }

    function handleWeaponChange(value, item) {
        var updatedCharacter = {...character}
        var index = updatedCharacter.weaponSkills.findIndex(obj => obj.name === item.name);
        updatedCharacter.weaponSkills[index].value = value;
        setCharacter(updatedCharacter);
    }

    function updateArmour(updates) {
        var updatedCharacter = {...character};
        updatedCharacter.hitPoints = updates[0].value;
        setCharacter(updatedCharacter);
    }

	function displayStat(statName) {
		return(
			<div style={{border: "2px solid #2F588A", margin: "10px 0px", borderRadius: "5px", color: "#121312", display:"flex", justifyContent:"center", alignItems: "center", textAlign:"center", width: "95px", height: "40px"}}>
				<div>{statName}: </div>
                {props.pageState.editMode ? 
                    <div style={{width: "55px"}}><EditBox number value={character.characteristics[statName].baseValue} style={buttonStyle} onChange={(value) => handleStatChange(value, statName)}  /> </div>
                : 
                    <div>{character.characteristics[statName].baseValue + (character.characteristics[statName].statBonus || 0)}</div>
                }
			</div>
		);

	}

    function displayDamageBonus() {
        return character.damageBonus.quantity === 0 ? "" : character.damageBonus.quantity < 0 ? "-" : "+" + character.damageBonus.quantity + "D" + character.damageBonus.dieType;
    }

    function hideItem(type, item, hidden) {
        var updatedCharacter = {...character};
        var index = updatedCharacter[type].findIndex(obj => obj.id === item.id);
        updatedCharacter[type][index].hidden = hidden;
        setCharacter(updatedCharacter);
    }

    function openImages() {

    }

    const saveChanges = () => { 
        if (character.name.first === "") {
            alert("First name required")
        }
        else {
            var updatedCharacter = {...character};
            saveCharacter(updatedCharacter);
            changeMode();
        }
    }
    
    const cancelChanges = () => { 
        changeMode();
        props.firebase
        .character(character.id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                var character = doc.data();
                setCharacter(character);    
            }
        });
    }

    function changeMode() {
        var mode = props.pageState.editMode;
        props.pageState.setEditMode(!mode);
    }

    function updateImage(image) {
        var updatedCharacter = {...character}
        updatedCharacter.profileImage = image;
        setCharacter(updatedCharacter);
    }

    function updateName(name, value) {
        var updatedCharacter = {...character}
        updatedCharacter.name[name] = value;
        setCharacter(updatedCharacter);
    }

    function updateAge(value) {
        var updatedCharacter = {...character}
        updatedCharacter.personalInfo.age = value;
        setCharacter(updatedCharacter);
    }

    function updateFields(updates, persist) {
        var updatedCharacter = {...character};
        updates.forEach(update => {
            updatedCharacter[update.fieldName] = update.value;
        }) 
        updateCharacter(updatedCharacter, persist);
    }
    
    function updateCharacter(updatedCharacter, persist) {
        setCharacter({...updatedCharacter});
        if (persist === SAVE.YES) {
            saveCharacter(updatedCharacter);
        }
    }

    function saveCharacter(updatedCharacter) {
        updatedCharacter.modified = new Date();
        props.firebase
        .character(updatedCharacter.id)
        .set(updatedCharacter)
        .then( function() {
        });
    }

    function updateEquipment(newValue) {
        var updatedCharacter = {...character};
        updatedCharacter.equipmentList = newValue;
        updateCharacter(updatedCharacter);
    }

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
	  content: () => componentRef.current,
	});

    if (character) {
        var citizen = ["Esrolia", "Lunar Tarsh"].indexOf(character.background.homeland) > -1 ? true : false
        return (
            <CharacterContext.Provider value={{authUser, character: character, type: "character"}}>
                {props.pageState.editMode ? 
                    <div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"200px", left:"0px", zIndex: "999"}}>
                    <div className={globalStyles.floatingButton} onClick={saveChanges} style={{width:"50px", cursor:"pointer", height:"50px", borderRadius:"25px", backgroundColor:"#2F588A", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                            <img style={{width:"30px"}} src={SaveIcon} title="Save changes" />
                        </div>
                        <div className={globalStyles.floatingButton} onClick={cancelChanges} style={{width:"50px", cursor: "pointer", height:"50px", borderRadius:"25px", backgroundColor: "#851113", display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                            <img style={{width:"40px"}} src={CancelIcon} title="Cancel changes" />
                        </div>
                    </div>
                :                    
                <>
                    <div style={{color:"#001e23", height: "200px", width: "50px", borderRadius:"10px", position:"fixed", top:"200px", left:"0px", zIndex: "999"}}>
                        <div title="Print character sheet" onClick={handlePrint} style={{width:"50px", height:"50px", borderRadius:"30px", border: "2px solid #2f588a", cursor: "pointer", color:"#fff", display:"flex", justifyContent:"center", alignItems:"center", marginTop: "10px"}} >
                            <PrintIcon style={{height:"30px", width:"30px", color: "#2f588a"}}/>
                        </div>
                        {character.campaignId === character.id ? 
                        null
                        : 
                        <CampaignButton campaignId={character.campaignId} />
                        }
                        {character.uid === authUser.uid ? 
                            <>
                                <a href={"/character/" + params.uid}>
                                    <div title="Interactive character sheet" style={{width:"50px", height:"50px", borderRadius:"30px", border: "2px solid #2f588a", cursor: "pointer", backgroundColor:"#fff", display:"flex", justifyContent:"center", alignItems:"center", marginTop: "10px"}} >
                                        <img src={DiceIcon} style={{height:"45px", width:"45px", color: "#2f588a"}}/>
                                    </div>
                                </a>
                                <EditButton character={character} user={authUser} update={updateCharacter} imported={imported} onClick={() => props.pageState.setEditMode(true)} close={removeImport} />
                            </>
                        : 
                        null}
                    </div>
                </>
                }
                <div className={globalStyles.panel} ref={componentRef} style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"13px", maxWidth: "820px", minHeight: "520px", padding: "20px", overflow: "auto", marginTop: "50px", paddingBottom: "150px"}}>
                    <div id="content" style={{}}>
                        <div id="topBar" style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{display:"flex", alignItems: "center"}}>
                                <ProfileImage profileImage={character.profileImage} gender={character.personalInfo.gender} updateImage={updateImage} />
                                <div style={{margin: "10px 5px", display: "flex", alignItems: "flex-start", flexDirection: "column", height: "240px", maxWidth: "350px", padding: "2px"}}>
                                    {props.pageState.editMode ? 
                                        <div style={{marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                            <EditBox placeholder="First name (required)" size="sm" value={character.name.first} onChange={(value) => updateName("first", value)} />
                                            <EditBox placeholder="Last name (optional)" size="sm" value={character.name.last} onChange={(value) => updateName("last", value)} />
                                        </div>
                                        : 
                                        <div style={{marginBottom: "10px", fontSize: "24px"}}>{character.name.first}&nbsp;{character.name.last}</div>
                                    }
                                    { character.personalInfo.gender === "Male" || character.personalInfo.gender === "Female" ?  
                                        <div style={{marginBottom: "10px", display: "flex", alignItems: "center"}}>
                                            <div>{character.personalInfo.gender}&nbsp;{character.occupation.name.toLowerCase()}</div>
                                            {props.pageState.editMode ? 
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <div>, age </div>
                                                    <div style={{width: "50px", paddingLeft: "5px"}}>
                                                        <EditBox number size="sm" value={character.personalInfo.age} onChange={(value) => updateAge(value)} />
                                                    </div>
                                                </div>
                                            : 
                                                <div>, age {character.personalInfo.age}</div>
                                            }
                                        </div>
                                    : 
                                        <div style={{marginBottom: "10px"}}>{character.occupation.name}, age {character.personalInfo.age}</div>
                                    }
                                    { character.background.clan ? 
                                        citizen ? 
                                        <div style={{marginBottom: "10px"}}>Member of the {character.background.clan} clan from the city of {character.background.tribe} in {character.background.homeland}</div>
                                        : 
                                        <div style={{marginBottom: "10px"}}>Member of the {character.background.clan} clan of the {character.background.tribe} tribe in {character.background.homeland}</div>
                                    : 
                                    character.background.tribe ? 
                                        citizen ? 
                                        <div style={{marginBottom: "10px"}}>Citizen of the city of {character.background.tribe} in {character.background.homeland}</div>
                                        : 
                                        <div style={{marginBottom: "10px"}}>Member of the {character.background.tribe} tribe in {character.background.homeland}</div>
                                    :
                                        <div style={{marginBottom: "10px"}}>Born in {character.background.homeland}</div>
                                    }

                                    <div style={{marginBottom: "10px"}}>{getCults()}</div>
                                    <div style={{display: "flex", marginBottom: "10px", width: "100%", alignItems: "center"}}>
                                        {character.wealth ? 
                                            props.pageState.editMode ? 
                                            <div style={{width: "50%", display: "flex", alignItems: "center"}}>
                                                <div>{"Wealth: "}</div><div style={{width: "80px", display: "flex", alignItems: "center"}}><EditBox number style={buttonStyle} value={character.wealth.silver} onChange={(value) => handleValueChange(value, "wealth")}  />&nbsp;L </div>
                                            </div>
                                            :
                                            <div style={{display:"flex", width: "50%"}}>{"Wealth: " + character.wealth.silver + "L"}</div>
                                        : 
                                        null
                                        }
                                        {character.reputation ? 
                                            <div style={{display:"flex", width: "50%", alignItems: "center"}}>
                                                <div>Reputation:&nbsp;</div>
                                                {props.pageState.editMode ? 
                                                    <div style={{width: "50px"}}><EditBox number style={buttonStyle} value={character.reputation} onChange={(value) => handleValueChange(value, "reputation")}  /> </div>
                                                : 
                                                    <div>{character.reputation}</div>
                                                }
                                            </div>
                                        : 
                                        null
                                        }

                                    </div>
                                    <div style={{display: "flex", width: "100%"}}>
                                        {character.scars ? 
                                            <div style={{marginBottom: "10px", display:"flex"}}>{character.scars + " distinctive scar" + (character.scars > 1 ? "s": "")}</div>
                                        : 
                                        null
                                        }

                                    </div>
                                    <div style={{maxHeight:"184px", overflow: "hidden"}}>
                                        <i>
                                            {character.background.reputation && character.background.reputation.slice(-4).map((characterEvent, index) => {
                                                return (
                                                    <div key={index} style={{display:"flex",marginBottom: "10px"}}>
                                                        {/* <div key={index} style={{width: "100px"}}>+{props.characterEvent.value}%</div> */}
                                                        <div>{characterEvent.event}</div>
                                                    </div>
                                                )
                                            })}
                                        </i>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <HitLocationPanel character={character} buttonStyle={buttonStyle} readOnly update={updateFields} />
                            </div>
                        </div>
                        <div id="stats" style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between", fontSize: "20px", marginLeft: "-10px"}}>
                            {displayStat("STR")}
                            {displayStat("CON")}
                            {displayStat("SIZ")}
                            {displayStat("DEX")}
                            {displayStat("INT")}
                            {displayStat("POW")}
                            {displayStat("CHA")}
                        </div>
                        <div id="atributes" style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between", fontSize: "17px"}}>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>Movement:&nbsp;</div>
                                {props.pageState.editMode ? 
                                    <div style={{width: "50px"}}><EditBox number style={buttonStyle} value={character.move} onChange={(value) => handleValueChange(value, "move")}  /> </div>
                                : 
                                    <div>{character.move}</div>
                                }
                            </div>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>HPs:&nbsp;</div>
                                <div>{character.hitPoints.total}</div>
                            </div>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>MPs:&nbsp;</div>
                                <div>{getAvailableMagicPoints(character).total}</div>
                            </div>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>SIZ SR:&nbsp;</div>
                                <div>{character.strikeRanks.SIZ}</div>
                            </div>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>DEX SR:&nbsp;</div>
                                <div>{character.strikeRanks.DEX}</div>
                            </div>
                            <div style={{display:"flex", marginTop: "5px", marginRight: "15px"}}>
                                <div style={{fontWeight: "bold"}}>Damage Bonus:&nbsp;</div>
                                {displayDamageBonus()}
                            </div>
                            <div style={{display:"flex", marginTop: "5px"}}>
                                <div style={{fontWeight: "bold"}}>Max ENC:&nbsp;</div>
                                <div>{character.maxEnc}</div>
                            </div>
                        </div>
                    <div style={{display: "flex", alignItems: "flex-start"}}>
                        <div style={{margin: "10px 0px", width:"65%"}}>
                            <WeaponsPanel hideItem={hideItem} buttonStyle={buttonStyle} handleValueChange={handleWeaponChange} update={updateFields} />
                            <div id="spiritCombat" style={{}}>
                                <div style={{background: "linear-gradient(90deg, rgba(133,17,19,1) 35%, rgba(167,29,33,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Spirit Combat</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Magic < 0 ? "" : "+"}{character.categoryModifiers.Magic || 0}%</div>
                                </div>
                                { 
                                spiritCombat ? 
                                    <div className={globalStyles.spellRow} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                                        <Checkbox style={{cursor: "default", width: "18px", padding: "1px", marginRight: "5px"}} checked={false} />
                                        <div style={{width:"150px", textAlign: "left"}}>Spirit Combat</div>
                                        {props.pageState.editMode ? 
                                            <div style={{width: "50px"}}><EditBox number style={buttonStyle} value={spiritCombat.value + (character.categoryModifiers.Magic || 0)} onChange={(value) => handleValueChange(value, "spiritCombat")}  /> </div>
                                        : 
                                            <div style={{width:"50px", textAlign: "center"}}>{spiritCombat.value + (character.categoryModifiers.Magic || 0)}%</div>
                                        }
                                        <div style={{width:"100px", textAlign: "center"}}>{spiritCombat.damage.quantity + "D" + spiritCombat.damage.dieType + (spiritCombat.damage.modifier ? "+" + spiritCombat.damage.modifier : "")}</div>
                                        <div style={{width:"30px", textAlign: "center"}}>12</div>
                                        <div style={{width:"30px", textAlign: "center"}}></div>
                                        <div style={{width:"30px", textAlign: "center"}}></div>
                                    </div>
                                :
                                <div style={{backgroundColor: "#BFD0CD", color: "#851113", fontSize: "20px", display: "flex", alignItems: "center", justifyContent:"center", marginTop: "2px", height: "83px"}}>None</div>
                                }
                            </div>
                            <PassionsPanel hideItem={hideItem} buttonStyle={buttonStyle} handleAbilityChange={handleAbilityChange} update={updateFields} />
                        </div>
                        <div id="magic" style={{border: "2px solid #121312", backgroundColor: "#fff", padding: "10px", margin: "10px", width:"33%"}}>
                            <RunesPanel hideItem={hideItem} buttonStyle={buttonStyle} handleAbilityChange={handleAbilityChange} update={updateFields} />
                        </div>
                    </div>
                <div className={globalStyles.pagebreak} />
                <div style={{marginTop: "20px", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", justifyContent: "space-between", display:"flex", alignItems:"center"}}>
                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Magic</div>
                </div>
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <RuneMagicPanel handleValueChange={handleValueChange} buttonStyle={buttonStyle} update={updateFields} />
                    <div>
                        <SpiritMagicPanel onChange={handleSpellChange} buttonStyle={buttonStyle} update={updateFields}/>
                        <SpiritSpellMatrixPanel update={updateFields} />
                    </div>
                    <div>
                        <RuneSpellMatrixPanel update={updateFields} />
                        <CrystalsPanel update={updateFields} />
                        <BoundSpiritPanel onChange={handleSpiritChange} handleValueChange={handleValueChange} buttonStyle={buttonStyle} update={updateFields} />
                    </div>
                </div>

                <div className={globalStyles.pagebreak} />
                <SkillsPanel hideItem={hideItem} buttonStyle={buttonStyle} handleAbilityChange={handleAbilityChange} update={updateFields} />
                <div className={globalStyles.pagebreak} />

                <>
                    <EventHistory history={character.history} update={updateFields} style={{marginTop: "10px"}} title="character" startYear={1622} endYear = {1700} />
                    <EventHistory history={character.history} update={updateFields} style={{marginTop: "10px"}} title="parent" startYear={1608} endYear = {1622} />
                    <EventHistory history={character.history} update={updateFields} style={{marginTop: "10px"}} title="grandparent" startYear={0} endYear = {1608} />
                </>

                <aside style={{margin:"5px", border: "2px solid #2F588A", backgroundColor: "#fff", borderRadius: "10px", padding: "10px"}}>
                    <div style={{margin: "10px 0px", fontSize: "20px"}}>{"Items & Equipment"}</div>
                    {props.pageState.editMode ? 
                        <EditBoxMulti rows={4} value={character.equipmentList} onChange={updateEquipment} />
                    : 
                        <div dangerouslySetInnerHTML={{__html: character.equipmentList}} />
                    }
                </aside>
                {character.cults[0].gifts && character.cults[0].gifts.length > 0 ? 
                <div style={{margin:"5px", border: "2px solid #2F588A", backgroundColor: "#fff", borderRadius: "10px", padding: "10px"}}>
                    <div style={{margin: "10px 0px", fontSize: "20px"}}>{"Gifts"}</div>
                    {character.cults[0].gifts.map((gift, index) => {
                        return (
                            <div key={index}>{gift}</div>
                        )
                    })}
                    <div style={{margin: "10px 0px", fontSize: "20px"}}>{"Geases"}</div>
                    {character.cults[0].geases.map((geas, index) => {
                        return (
                            <div key={index}>{geas}</div>
                        )
                    })}
                </div>
            : 
                null}
            </div>
        </div>
        </CharacterContext.Provider>
        );
    }
    else return null;
});

export default withFirebase(withPageState(CharacterSheet));