import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {updateEmail} from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();

    this.db = app.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider(); //fbAuthProvider
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => 
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "https://runequesting.com",
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doEmailUpdate = (oldEmail, newEmail, password) => {
    this.auth.signInWithEmailAndPassword(oldEmail, password)
    .then(function(userCredential) {
        updateEmail(userCredential.user, newEmail)
    })
      .then(() => {
    }).catch((error) => {
      console.log('error: ', error)
    })
  }


  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.multiFactor.user.uid)
        .get()
        .then(snapshot => {
          const dbUser = snapshot.data();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

    currentUser = () => this.auth;
  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');
 
  // *** Message API ***
  message = uid => this.db.doc(`messages/${uid}`);
  messages = () => this.db.collection('messages');
  
  // *** Character API ***
  character = uid => this.db.doc(`characters/${uid}`);
  characters = () => this.db.collection('characters');
  
  // *** Character API ***
  campaign = uid => this.db.doc(`campaigns/${uid}`);
  campaigns = () => this.db.collection('campaigns');
  
  // *** Homeland API ***
  history = () => this.db.collection('history');
  
  // *** Homeland API ***
  homelands = () => this.db.collection('homelands');
  
  // *** Homeland API ***
  tribes = () => this.db.collection('tribes');
  
  // *** Homeland API ***
  encounter = uid => this.db.doc(`encounters/${uid}`);
  encounters = () => this.db.collection('encounters');
  
  // *** Homeland API ***
  npc = uid => this.db.doc(`npcs/${uid}`);
  npcs = () => this.db.collection('npcs');

  // *** Skills API ***
  skill = uid => this.db.doc(`skills/${uid}`);
  skills = () => this.db.collection('skills');

  // *** Skills API ***
  rune = uid => this.db.doc(`runes/${uid}`);
  runes = () => this.db.collection('runes');

  // *** Skills API ***
  weapon = uid => this.db.doc(`weapons/${uid}`);
  weapons = () => this.db.collection('weapons');

  // *** Skills API ***
  cult = uid => this.db.doc(`cults/${uid}`);
  cults = () => this.db.collection('cults');

  // *** Skills API ***
  experience = () => this.db.collection('experience');

  // *** Skills API ***
  request = uid => this.db.doc(`requests/${uid}`);
  requests = () => this.db.collection('requests');

  // *** Skills API ***
  template = uid => this.db.doc(`templates/${uid}`);
  templates = () => this.db.collection('templates');

  // *** Chapters API ***
  chapter = uid => this.db.doc(`chapters/${uid}`);
  chapters = () => this.db.collection('chapters');

  // *** Episodes API ***
  adventure = uid => this.db.doc(`adventures/${uid}`);
  adventures = () => this.db.collection('adventures');

  // *** Diagnostics API ***
  feedback = () => this.db.collection('feedback');

  // *** Diagnostics API ***
  diagnostics = () => this.db.collection('diagnostics');

  // *** Skills API ***
  note = uid => this.db.doc(`notes/${uid}`);
  notes = () => this.db.collection('notes');

  // *** Skills API ***
  spiritSpells = () => this.db.collection('spiritSpells');
  runeSpells = () => this.db.collection('runeSpells');

  // *** Occupations API ***
  occupation = uid => this.db.doc(`occupations/${uid}`);
  occupations = () => this.db.collection('occupations');

  // *** Passions API ***
  passion = uid => this.db.doc(`passionTypes/${uid}`);
  passions = uid => this.db.doc(`characters/${uid}`).collection('passions');
  passionTypes = () => this.db.collection('passionTypes');

  // *** Character Skills API ***
  characterSkills = uid => this.db.doc(`characters/${uid}`).collection('skills');
  customContent = uid => this.db.doc(`customContent/${uid}`);
  customContents = uid => this.db.collection('customContent');
  
}

export default Firebase;
