import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css'
import { withPageState } from '../Context/PageState';
import { withContext } from '../Context/context';
import compareValues from '../../utils/compareValues';

const Abilities = (props) => {
    const [type, setType] = useState("");
    const [items, setItems] = useState([]);
    const [columnOne, setColumnOne] = useState([])
    const [columnTwo, setColumnTwo] = useState([])
    const [columnThree, setColumnThree] = useState([])

    useEffect(() => {
        var type = props.type.toLowerCase() + "s";
        setType(type);
    },[props.providerData.character.configuration]);

    useEffect(() => {
        setItems(props.displayItems.sort(compareValues('name', 'asc')))
    },[props.displayItems])

    useEffect(() => {
        var columnLength = Math.floor(items.length / props.columnCount);
        var rem = items.length % props.columnCount;
        var columnOneLength = rem >= 1 ? columnLength + 1 : columnLength;
        var columnTwoLength = rem === 2 ? columnLength + 1 : columnLength;
        
        setColumnOne(items.slice(0, columnOneLength));
        if (props.columnCount === 2) {
            setColumnTwo(items.slice(columnOneLength, items.length ));
        }
        else {
            setColumnTwo(items.slice(columnOneLength, columnOneLength + columnTwoLength ));
            setColumnThree(items.slice(columnOneLength + columnTwoLength, items.length ));
        }
    },[items])

    function update(item, persist) {
        var updatedAbilities = [...props.providerData.character[type]];
        const index = updatedAbilities.findIndex(ability => (ability.id === item.id));
        updatedAbilities[index] = item;
        props.update([{fieldName: type, value: updatedAbilities}], persist);
    }

    function processResult(roll) {
        var messageText={critical: " achieved a critical success with {pronoun} " + " " + roll.item.name +" " + props.type.toLowerCase(), special: " achieved a special success with {pronoun} " + " " + roll.item.name +" " + props.type.toLowerCase(), success: " succeeded with {pronoun} " + " " + roll.item.name +" " + props.type.toLowerCase(), failure: " failed with {pronoun} " + " " + roll.item.name +" " + props.type.toLowerCase(), fumble: " fumbled {pronoun} "  + " " + roll.item.name +" " + props.type.toLowerCase()};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment}
        props.processResult(dieRoll, {...roll.item, type: type}, roll.title)
    }

    if (columnTwo && columnTwo.length > 0) {
        return(
            <div className={globalStyles.panel} style={{...props.style, zIndex: 20}}>
                <div style={{marginRight: "20px"}}>
                    {columnOne.map((item, index) => {
                        return(
                            <div key={index}>
                                {React.cloneElement(props.tile, {...props, item: item, processResult: processResult, update: update, displayItem: props.displayItem})}
                            </div>
                        )
                    })}
                </div>
                <div style={props.columnCount === 2 ? {marginLeft: "20px"} : {margin: "0px 20px"}}>
                    {columnTwo.map((item, index) => {
                        return(
                            <div key={index}>
                                {React.cloneElement(props.tile, {...props, item: item, processResult: processResult, update: update, displayItem: props.displayItem})}
                            </div>
                        )
                    })}
                </div>
                {props.columnCount > 2 ? 
                    <div style={{marginLeft: "20px"}}>
                        {columnThree.map((item, index) => {
                            return(
                                <div key={index}>
                                    {React.cloneElement(props.tile, {...props, item: item, processResult: processResult, update: update, displayItem: props.displayItem})}
                                </div>
                            )
                        })}
                    </div>
                : 
                null
                }
            </div>
        );
    }
    else {
        return (
            <div style={{display:"flex", flexWrap: "wrap", alignItems: "center", zIndex: 20}}>
                <div><b>{props.title}</b></div>
                {items.map((item, index) => {
                    return(
                        <div key={index}>
                            {React.cloneElement(props.tile, {...props, item: item, processResult: processResult, update: update, displayItem: props.displayItem})}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default withContext(withPageState(Abilities));