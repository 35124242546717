import React, { useState, useEffect } from 'react';
import { CssBaseline, Typography, Container, Paper, Button, Zoom } from '@mui/material';
import globalStyles from '../Styles/styles.module.css'
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import NPCCard from './npcCard';
import { AuthUserContext } from '../Session';
import styles from './encounters.module.css'
import Messages from '../Messages/Messages';
import DicePoolButton from '../DicePool/dicePoolButton';
import EditIcon from '../../icons/edit-icon-white.svg';
import { TextField, Tooltip } from '@mui/material';
import ResultsDialog from '../Dialogs/dialogBox';

const EncounterPage = (props) => {
  var uid = props.match.params.uid;
  const [NPCs, setNPCs] = useState();
  const [pageState, setPageState] = useState({
    editMode: false,
    saveData: false,
  });
  const [dicePoolResult, setDicePoolResult] = useState({
    result: 0,
    diceRolled: "",
    show: false,
});
  const [modifier, setModifier] = useState({open: false});
  const [encounter, setEncounter] = useState();
  const [fixedRoll, setFixedRoll] = useState();
  const [popper, setPopper] = useState( {
    open: false,
    markup: "",
    anchor: ""
},
)

  useEffect(() => {
    props.firebase
    .encounter(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
       var enc = doc.data();
       enc.id = doc.id;
       setEncounter(enc);
      }
    });
  },[uid]);

  const getBannerStyle = () => {
    if (pageState.editMode) {
        return styles.editingBanner;
    }
    else {
        return styles.characterBanner;
    }
}

const modeChanged = () => { 
  var mode = !pageState.editMode;
  var saveData = !mode;
  var newPageState = {editMode: mode, saveData: saveData};
  setPageState(newPageState);
}

function handleClickAway(){
  setPopper({open: false})
}

const displayItemDetails = (markup, name) => {
  var toggle = ! popper.open;
  setPopper( {
      open: toggle,
      markup: markup,
      name: name,
      anchor: window.document.getElementById(name)
  });  
}

function displayDicePoolResult(diceRolled, result){
  setDicePoolResult({result: result, diceRolled: diceRolled, show: true});
}

const handleDamageClose = () => {
  setDicePoolResult({show: false});
}

function handleFix(event) {
  setFixedRoll(event.target.value);
}

function clearFix(event) {
  setFixedRoll(0);
}

function addModifier() {
  setModifier({open:true})
}

function processModifier(value) {
  setModifier({open:false, value: value})
}

const displayDiceRoll = (authUser) => {

  if (encounter.campaignId) {
      if (dicePoolResult.result && dicePoolResult.show) {
          props.firebase.messages().add({
            text: "rolled " + dicePoolResult.diceRolled + " for a total of " + dicePoolResult.result,
            result:"Damage",
              userId: authUser.uid,
              firstName: "GM",
              characterName: "",
              campaignId: encounter.campaignId,
              createdAt: props.firebase.fieldValue.serverTimestamp(),
          });
      }
  }
  return (
    <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
        <div><h2>You rolled {dicePoolResult.diceRolled} for a total of </h2></div>
        <div style={{color: "#851113", fontSize: "20px", marginLeft: "10px", fontWeight: "700"}}><h1>{dicePoolResult.result}</h1></div>
    </div>
    );

}

if (!encounter) return null;
var currentTemplate;
  return (
    <div id="encountersPage" className={styles.bg}>
    {/* <div id="encountersPage"> */}

      <div className={globalStyles.characterBanner}></div>
      <AuthUserContext.Consumer>
        {authUser => (
          NPCs ? 
            <React.Fragment>
                  <Messages campaignId={encounter.campaignId}></Messages>
                    <div style={{padding:"0px 30px", display:"flex"}} className={[globalStyles.mainBanner, getBannerStyle()].join(' ')}>
                        <CssBaseline />
                            <div>
                                <div style={{display:"flex", alignItems:"center"}}>
                            <div style={{width: "500px", color:"white"}}>
                            <div style={{fontSize:"2em"}}>{encounter.name}</div>
                            </div>
                            <DicePoolButton displayResult={displayDicePoolResult}></DicePoolButton>
                                {/* <Tooltip TransitionComponent={Zoom} title="Click to modify your next dice roll">
                                    <Button onClick={addModifier} id="modifyButton" color="primary" variant="contained" style={{marginRight:"30px"}}><img style={{width:"20px", marginRight:"5px"}} src={ModifyIcon} />Modify</Button>
                                </Tooltip>
                                <ResultsDialog isOpen={modifier.open} size='sm' subtitle='This modifier will be applied to your next die roll' title='Enter modifier'>
                                <ModifyDialog processModifier={processModifier}></ModifyDialog>
                                </ResultsDialog> */}
                                {
                                pageState.editMode ?
                                <Tooltip TransitionComponent={Zoom} title="Edit the encounter">
                                    <Button id="editButton" color="primary" variant="contained" onClick={modeChanged}>Save</Button>
                                </Tooltip>
                                :
                                <Tooltip TransitionComponent={Zoom} title="Edit ther encounter">
                                    <Button style={{backgroundColor:"#851113", color:"white", marginRight:"20px", fontSize:"13px"}} id="editButton" variant="contained" onClick={modeChanged}><img style={{width:"20px", marginRight:"3px"}} src={EditIcon}/>Edit</Button>
                                </Tooltip>
                            }
                            </div>
                            </div>
                    </div>

              <CssBaseline />
              <Container maxWidth="lg">
                <Paper elevation={3}>
                  <Typography component="div" style={{backgroundColor:"#dcdfe1", overflowY:"scroll", height: '100vh', padding:"20px" }}>
                    <h3 style={{color:"#851113", margin:"5px"}}>{encounter.description}</h3>
                      <div className={globalStyles.horizontalLine}></div>
                      <div style={{display:"flex"}}>
                      <div style={{width:"15%"}}>
                    <TextField
                      variant="outlined" 
                      size="small"
                      name="fixedRoll"
                      value={fixedRoll} 
                      color="primary" 
                      onChange={handleFix} 
                      inputProps={{
                          style: {fontSize: "13px", height: "13px", padding: "3px", backgroundColor:"wheat", textAlign:"center"} 
                        }}                                   
                        >
                          {fixedRoll}
                      </TextField>
                  </div>
                  <div style={{width:"15%"}}>
                    <Button onClick={clearFix} id="modifyButton" color="primary" variant="contained" style={{marginRight:"20px", fontSize:"13px", height: "30px"}}>Clear</Button>
                    </div>
                    </div>
                        <div  style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", padding:"0px 20px" }}>
                          {NPCs.map((item) => {
                            return (
                              <div key={item.id}>
                                <div style={{width:"500"}}>
                                  <div style={{ height: 'auto', width:'auto'}}>
                                    {
                                      <NPCCard modifier={modifier.value} fixedRoll={fixedRoll} encounter={encounter} pageState={pageState} encounterId={uid} authUser={authUser} npc={item} displayItemDetails={displayItemDetails}></NPCCard>
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                    </Typography>
                  </Paper>
              </Container>
              <ResultsDialog isOpen={dicePoolResult.show} successLevel="Damage" size='sm' handleClose={handleDamageClose} subtitle='' title=''>{displayDiceRoll(authUser)}</ResultsDialog>
            </React.Fragment>
          :
          getNPCs(authUser)
        )}
      </AuthUserContext.Consumer>
    </div>
  );
       
  function getTemplate(id) {
    var template;
    props.firebase
    .templates()
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        template = doc.data();
        template.id = doc.id;
        return template;
      }
    });
  }

  function getNPCs(){
    var npcs = [];
    props.firebase
    .npcs()
    .where("encounterId", "==", uid)
    .orderBy("templateId")
    .orderBy("rank", "desc")
    .get()
    .then(querySnapshot => 
    {
      querySnapshot.forEach(function(doc) {
        const id = doc.id;
        const data = doc.data();
        var npc = {id, ...data}
        npcs.push(npc);
      });
      setNPCs(npcs);
    });
  }
}

export default withFirebase(withEmailVerification(withAuthorization(EncounterPage)));
