import React, { useState, useEffect } from 'react';
import globalStyles from '../Styles/styles.module.css'
import PolyhedronIcon from '../../icons/tables-icon.svg';
import DialogBox from '../Dialogs/dialogBox';
import TablesDialog from '../Dialogs/tablesDialog';

export default function TablesButton(props) {
    const [open, setOpen] = useState(false);
    
    function processResult(dice, result, diceRolled) {
        setOpen(false);
        props.processResult(dice, result, diceRolled);
    }

    return (
            <>
                <div onClick={() => setOpen(true)} className={globalStyles.floatingButton}>
                        <img style={{width:"56px"}} src={PolyhedronIcon} title="View tables"/>
                </div>
                <DialogBox isOpen={open} handleClose={() => setOpen(false)} width="1090px" size="lg" title="RuneQuest Tables">
                    <TablesDialog campaignId={props.campaignId} displayResult={processResult} close={() => setOpen(false)} authUser={props.authUser} name={props.name}/>
                </DialogBox>
            </>
    );
}