import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import { withContext } from "../../Context/context";
import globalStyles from '../../Styles/styles.module.css';
import AddItemButton from "../buttons/addItemButton";
import * as SAVE from '../../../constants/save';
import DialogBox from "../../Dialogs/dialogBox";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SpiritDialog from "../../MagicItems/spiritDialog";
import compareValues from "../../../utils/compareValues";
import CultHeaderBar from "./cultHeaderBar";

const BoundSpiritPanel = (props) => {

    const [boundSpirits, setBoundSpirits] = useState([])
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(-1);

    useEffect(() => {
        setBoundSpirits([...props.providerData.character.boundSpirits]);
    },[props.providerData.character.boundSpirits])

    function addSpirit(newSpirit) {
        var updatedSpirits = [...props.providerData.character.boundSpirits];        
        var index = updatedSpirits.findIndex(item => item.id === newSpirit.id);
        if (index < 0) {
            updatedSpirits.push(newSpirit);
        }
        else {
            updatedSpirits[index] = newSpirit;
        }      
        var updates = [{fieldName: "boundSpirits", value: updatedSpirits}];
        props.update(updates, SAVE.NO);
    }

    function openDialog(index) {
        setKey(index);
        setOpen(true);
    }

        return (
            <div style={{border: "2px solid #676767", margin: "5px", width: "260px"}}>
                <div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between "}}>
                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Bound & Allied Spirits</div>
                    <div style={{width: "30px"}}>
                        {props.pageState.editMode ?
                            <AddItemButton title="Add POW Storage Crystal" width="860px" addItem={addSpirit}>
                                <SpiritDialog update={props.update} entity={props.providerData.character} close={() => setOpen(false)} />
                            </AddItemButton>
                        : 
                        null}
                    </div>
                </div>
                {boundSpirits.length > 0 ? 
                boundSpirits.map((spirit, index) => {
                    return (
                        <div key={index}>
                            <div style={{backgroundColor: "#2F588A", padding: "0px 5px", color: "#fff", height: "24px", position: "relative"}}>
                                <div style={{display: "flex", alignItems: "center", height: "24px"}}>
                                    {props.pageState.editMode ? 
                                    <div style={{position: "absolute", right: "6px", top: "0px", cursor: "pointer"}} title="Edit crystal" onClick={() => openDialog(index)}>
                                        <ModeEditIcon style={{height:"22px", width:"22px", color: "#fff"}} />
                                    </div>
                                    : 
                                    null}
                                    <div>{spirit.name.first} bound into {spirit.location}, POW: {spirit.characteristics.POW.baseValue + (spirit.characteristics.POW.statBonus || 0)}</div>
                                </div>
                                <DialogBox title="Edit Spirit" isOpen={open} width="860px" >
                                    <SpiritDialog update={props.update} entity={props.providerData.character} close={() => setOpen(false)} spirit={boundSpirits[key]} />
                                </DialogBox>
                            </div>
                            { spirit.spiritSpells.sort(compareValues('name')).map((item, index) => {
                                    return(
                                        <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                                                <div>{item.name}{item.variant ? " " + item.variant : ""}</div>
                                            {item.variable ? 
                                                <div style={{marginRight: "12px"}}>{item.points}</div>
                                                : 
                                                <div style={{marginRight: "12px"}}>{item.points}{item.points == 1 ? "pt" : "pts"}</div>
                                            }
                                        </div>
                                    );
                                })
                            }
                            {spirit.runeSpells ? 
                                <>
                                    <CultHeaderBar handleValueChange={props.handleValueChange} buttonStyle={props.buttonStyle} storage={999} setSpells={props.setSpells} entity={spirit} cult={spirit.cult} />
                                    { spirit.runeSpells.sort(compareValues('name')).map((item, index) => {
                                            return(
                                                <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                                                        <div>{item.name}{item.variant ? " " + item.variant : ""}</div>
                                                    {item.variable ? 
                                                        <div style={{marginRight: "12px"}}>{item.points}</div>
                                                        : 
                                                        <div style={{marginRight: "12px"}}>{item.points}{item.points == 1 ? "pt" : "pts"}</div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </>
                            : 
                            null}
                        </div>
                    )
                })
                : 
                <div style={{padding: "10px"}}>None</div>}
            </div>
        )
}

export default withPageState(withContext(BoundSpiritPanel));