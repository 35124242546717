import React from 'react'
import globalStyles from '../Styles/styles.module.css';

const CombatFumblesTable = (props) => {
    return (
        <div style={{backgroundColor: "f0f0f0", width: "1060px", height: "800px"}}>
            <div style={{backgroundColor: "#676767", color: "#fff", display:"flex", fontSize: "13px", padding: "10px"}}>
                <div style={{width: "120px"}}>D100</div>
                <div style={{width: "900px"}}>Nature and Effect on Fumbler</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>1-5</div>
                <div style={{width: "900px"}}>Lose next parry.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>6-10</div>
                <div style={{width: "900px"}}>Lost next attack.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>11-15</div>
                <div style={{width: "900px"}}>Lost next attack and parry.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>21-25</div>
                <div style={{width: "900px"}}>Lose next 1D3 attacks.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>26-30</div>
                <div style={{width: "900px"}}>Lose next 1D3 attacks and parries.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>31-35</div>
                <div style={{width: "900px"}}>Shield strap breaks, lose shield immediately.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>36-40</div>
                <div style={{width: "900px"}}>Shield strap breaks as above, and lose next attack.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>41-45</div>
                <div style={{width: "900px"}}>Armor strap breaks and armor comes loose (roll for hit location to determine which piece of armor is lost).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>46-50</div>
                <div style={{width: "900px"}}>Armor strap breaks as above, and lose next attack and parry.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>51-55</div>
                <div style={{width: "900px"}}>Fall and lose parry this round (take 1D3 rounds to get up).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>56-60</div>
                <div style={{width: "900px"}}>Twist ankle, lose half movement rate for 5D10 rounds.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>61-63</div>
                <div style={{width: "900px"}}>Twist ankle and fall, apply the effects of 51-55 and 56-60.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>64-67</div>
                <div style={{width: "900px"}}>Vision impaired, lose 25% effectiveness on attacks and parries (takes 1D3 rounds unengaged to clear vision).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>68-70</div>
                <div style={{width: "900px"}}>Vision impaired, lose 50% effectiveness on attacks and parries (takes 1D6 rounds unengaged to clear vision).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>71-72</div>
                <div style={{width: "900px"}}>Vision blocked, lose all attacks and parries (takes 1D6 rounds unengaged to clear vision).</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>73-74</div>
                <div style={{width: "900px"}}>Distracted, foes attack at +25% effectiveness for next round.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>75-78</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Weapon used in attack dropped (takes 1D3 rounds to recover).</div>
                    <div><b>Parry: </b>Parrying weapon or shield dropped (takes 1D3 rounds to recover).</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>79-82</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Weapon knocked away (roll 1D6 for number of meters it travels, and roll 1D8 for compass direction it goes; with 1=north, 2=northeast, 3=east, 4=southeast, 5=south, 6=southwest, 7=west, 8=northwest).</div>
                    <div><b>Parry: </b>Parrying weapon or shield knocked away (roll 1D6 for number of meters it travels and 1D8 for compass direction it goes, as above).</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>83-86</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Weapon shattered (100% chance if unenchanted; 10% less for each point of battle magic spell on weapon, and 20% less for each point of Rune magic spell on weapon).</div>
                    <div><b>Parry: </b>Parrying weapon or shield shattered (100% if unenchanted; 10% less for each point of battle magic on object, and 20% less for each point of Rune magic.)</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>87-89</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit nearest friend (hit self if no friend near); do rolled damage.</div>
                    <div><b>Parry: </b>Wide open; foe automatically hits with normal damage.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>90-91</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit nearest friend (hit self if no friend near); do full possible damage.</div>
                    <div><b>Parry: </b>Wide open; foe automatically hits with normal damage.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>92</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit nearest friend (hit self if no friend near); do critical hit.</div>
                    <div><b>Parry: </b>Wide open; foe automatically hits with normal damage.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>93-95</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit self; do rolled damage.</div>
                    <div><b>Parry: </b>Wide open; foe automatically hits with full possible damage.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>96-97</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit self; do full possible damage.</div>
                    <div><b>Parry: </b>Wide open; foe automatically critical hits.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>98</div>
                <div style={{width: "900px"}}>
                    <div><b>Attack: </b>Hit self; do critical hit.</div>
                    <div><b>Parry: </b>Wide open; foe automatically critical hits.</div>
                </div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>99</div>
                <div style={{width: "900px"}}>Blow it; roll twice on this table, and apply both results. If this result is rolled again, continue rolling until two other results are achieved.</div>
            </div>
            <div className={globalStyles.row} style={{display:"flex", padding: "4px", fontSize: "12px"}}>
                <div style={{width: "120px", textAlign: "center"}}>100</div>
                <div style={{width: "900px"}}>Blow it badly; roll three times on this table, and apply all three results. If this result is rolled again, continue rolling until three other results are achieved.</div>
            </div>
       </div>
    )
}

export default CombatFumblesTable;
