import React, {useState, useEffect} from 'react'
import Incrementor from './incrementor'
import * as SAVE from '../../constants/save';

const RunePointIncrementor = (props) => {

    const [runePoints, setRunePoints] = useState(null);
    const [entityIndex, setEntityIndex] = useState(-1);

    useEffect(() => {
        if (props.cult) {
            var index = props.cult.runePoints.findIndex(item => item.entityId === props.entityId);
            setEntityIndex(index);
            setRunePoints([...props.cult.runePoints]);
        }
    },[props.cult, props.entityId])
  
    function modifyRunePoints(value) {
        var updatedRunePoints = [...runePoints];
        updatedRunePoints[entityIndex].current += value;
        if (updatedRunePoints[entityIndex].current > updatedRunePoints[entityIndex].total)  {
            updatedRunePoints[entityIndex].current = updatedRunePoints[entityIndex].total;
        }
        else if (updatedRunePoints[entityIndex].current < 0) {
            updatedRunePoints[entityIndex].current = 0;
        }
        else {
            setRunePoints(updatedRunePoints);
            var updatedCult = {...props.cult};
            updatedCult.runePoints = updatedRunePoints;
            props.update(updatedCult, SAVE.DELAYED);
        }
    }

    if (runePoints && (entityIndex > -1)) {
        return (
            <Incrementor style={props.style} label="Rune points:" increment={modifyRunePoints} currentValue={runePoints[entityIndex].current} totalValue={runePoints[entityIndex].total} />
        )
    }
    else return null;
}

export default RunePointIncrementor