import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { Button, FormControlLabel, Switch } from '@mui/material';
import EditBoxMulti from '../Controls/editBoxMulti';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import EditBox from '../Controls/editBoxSingle';
import htmlToDraft from 'html-to-draftjs';

const DescriptionDialog = (props) => {

    const [editorContent, setEditorContent] = useState()
    const [description, setDescription] = useState()

    useEffect(() => {
        if (props.description) {
            const contentBlock = htmlToDraft(props.description);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const state = EditorState.createWithContent(contentState);
            setEditorContent(state);
            setDescription(props.description);
        }
    },[props.description])

    function update() {
        props.update(description);
        props.close();
    }

    function onChange(value) {
        setEditorContent(value);
        var foo = draftToHtml(convertToRaw(value.getCurrentContent()))
        setDescription(foo);
    }

    return (
        <div style={{overflowX: "hidden", height: "540px"}}>
            <div style={{padding: "10px", backgroundColor: "#fff"}}>
                <div className={globalStyles.panel} style={{display:"flex", flexDirection: "column", justifyContent: "space-between", height: "434px", overflow: "auto", backgroundColor: "#fff"}}>
                    <Editor
                        editorState={editorContent}
                        placeholder='Enter description details'
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onChange}
                    />          
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", padding: "20px", height: "40px"}}>
                <Button onClick={update} style={{width: "100px"}} color="primary" variant="contained">Ok</Button>
                <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    );
}
export default DescriptionDialog;