import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { withContext } from '../../Context/context';
import compareValues from '../../../utils/compareValues';
import { getAllSkills } from '../../../services/dataService';

const AddSkillDialog = (props) => {

	const [items, setItems] = useState([]);
	const [selection, setSelection] = useState("Select");
	const [value, setValue] = useState(0);
	const [variant, setVariant] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [variable, setVariable] = useState(true);

	const allSkills = getAllSkills(props.providerData.campaign);

	useEffect(() => {
		var skills = [];
		allSkills.forEach(skill => {
			if (skill.variable) {
				skills.push(skill);
			}
			else if (props.providerData.character.skills.findIndex(obj => obj.parentId === skill.id) < 0) {
				if (skill.restricted) {
					if (props.providerData.character.cults.findIndex(obj => obj.parentId === skill.cult.parentId) > -1) {
						skills.push(skill);
					}
				}
				else {
					skills.push(skill);
				}
			}
		})
		setItems(skills.sort(compareValues("name")));
	},[]);

	useEffect(() => {
		var disabled = true;
		if (selection !== "Select") {
			if (value > 0) {
				var index = allSkills.findIndex(obj => obj.name === selection);
				if (index > -1) {
					if (allSkills[index].variable) {
						if (variant) {
							disabled = false;
						}
					}
					else {
						disabled = false;
					}
				}
			}
		}
		setDisabled(disabled);
	},[selection, variant, value])

	function handleChange(event) {
		setSelection(event.target.value);
		var index = items.findIndex(obj => obj.name === event.target.value);
		setValue(items[index].baseValue);
		setVariant("");
		setVariable(items[index].variable || false);
	}
  
    function modifyValue(event, index) {
        var previous = value || 0;
        var newValue = parseInt(event.target.value)
        if (! newValue) newValue = previous;
        setValue(newValue);
    }
  
    function modifyVariant(event){
        setVariant(event.target.value);
    }

	function handleCancelClick(){
		props.close();
	}
	  
	function handleOkClick(){
		if (selection === "Select") return;
		var index = allSkills.findIndex(obj => obj.name === selection);
		if (index > -1) {
			if (allSkills[index].variable && !variant) {return}
		}
		var categoryModifiers = props.providerData.character.categoryModifiers
		var selectedItem;
		items.forEach(item => {
			if (item.name === selection) {
				selectedItem = {...item};
				selectedItem.id = uuidv4();
				selectedItem.value = value;
				selectedItem.parentId = item.id;
				selectedItem.hasSucceeded = false;
				selectedItem.canLearn = true;
				selectedItem.variable = item.variable;
				selectedItem.variant = variant;
				return;
			}
		});
        props.addItem(selectedItem);
		props.close();
	}

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", padding: "20px"}}>
			{items && items.length > 0 ? 
				<div>
					<div style={{display:"flex"}}>
						<FormControl variant="outlined" >
							<Select value={selection} style={{backgroundColor:"#fff", minWidth:"10em"}} autoWidth={true} onChange={handleChange} color="primary">
								<MenuItem key={0} value="Select">
									<em>Select</em>
								</MenuItem>
								{ 
									items.map((item, index) => {
										return( <MenuItem key={index} value={item.name}>{item.name}</MenuItem> );
									})
								}
							</Select>
						</FormControl>
						
						<div style={{width:"275px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
								<TextField disabled={!variable} size="small" inputProps={{style: {fontSize: "16px", width:"275px", height: "48px", padding: "3px 10px", backgroundColor: "#fff", color: "#121312", borderRadius: "5px", }}} placeholder="Expertise" color="primary" value={variant} onChange={modifyVariant} variant="outlined" ></TextField>
							</div>
						<div style={{width:"100px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
							<TextField inputProps={{style: {fontSize: "16px", width:"140px", height: "48px", padding: "3px", textAlign:"center", backgroundColor: "#fff", color: "#121312", borderRadius: "5px", }}} placeholder="Modifier" color="primary" value={value} onChange={modifyValue} variant="outlined" size="small"></TextField>
						</div>
					</div>
					<hr style={{marginTop: "30px"}} />
					<div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end"}}>
						<Button disabled={disabled} type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
						<Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
					</div>
				</div>
			:
				null
			}
			
		</div>
	);
};

export default withContext(AddSkillDialog);