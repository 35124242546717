import React, { useEffect, useState } from "react";
import { ListItemText, MenuItem, FormControl, Select, Button, Checkbox, FormGroup, FormControlLabel, Switch, RadioGroup, Radio } from '@mui/material';

const MultiSelect = (props) => {

    const [values, setValues] = useState(props.values);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        setValues([...props.values])
    },[props.values]);

    useEffect(() => {
        setSelectedValues([...props.selectedValues])
    },[props.selectedValues]);

    const handleSelect = (event) => {
        const {target: { value } } = event;
        setSelectedValues(
          typeof value === 'string' ? value.split(',') : value,
        );
        props.onChange(value)
    };

    if (props.values) {
        return (
            <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                style={{...props.style, backgroundColor: "#fff", border: "2px solid #2F588A", borderRadius: "5px", height: "28px"}}
                multiple
                placeholder={props.placeholder}
                value={selectedValues}
                onChange={handleSelect}
                renderValue={(selected) => selected.join(', ')}
                >
                {props.values.map((name) => {
                    return (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedValues.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    )
                })}
                </Select>
            </FormControl>
        )
    }
    else {
        return null;
    }
}

export default MultiSelect;