import React from 'react';
import globalStyles from '../Styles/styles.module.css';

const SmallDiceButton = (props) => {

    if (props.disabled) {
        return (
            <div style={{height: "32px", display:"flex", alignItems:"center", justifyContent: "center", borderRadius: "5px", ...props.style}} className={globalStyles.disabledButton}>
                {props.title || "Target: " + props.target + "%"}
            </div>
        )
    }
    else if (props.augment) {
        return (
            <div style={{height: "32px", display:"flex", alignItems:"center", justifyContent: "center", borderRadius: "5px", ...props.style}} onClick={props.rollDice} className={globalStyles.augmentButton}>
                {props.title || "Augment: " + props.target + "%"}
            </div>
        )
    }
    else {
        return (
            <div style={{height: "32px", display:"flex", alignItems:"center", justifyContent: "center", borderRadius: "5px", ...props.style}} onClick={props.rollDice} className={globalStyles.blueDiceButton}>
                {props.title || "Target: " + props.target + "%"}
            </div>
        )

    }
}

export default SmallDiceButton;