import React, { useState } from 'react';
import { TextField } from '@mui/material';

const ModifierBox = (props) => {

    const [value, setValue] = useState("");

    function handleChange(event) {
        var previous = value || "";
        var newValue = "";
        if (event.target.value == "" || event.target.value == 0) {
            setValue(event.target.value);
            props.handleChange(newValue);
        }
        else if (event.target.value) {
            var newValue = parseInt(event.target.value)
            if (event.target.value === '-') {
                newValue = '-';
            }
            else if (! newValue) {
                newValue = previous;
            }
            setValue(newValue);
            props.handleChange(newValue, event);
        }
    }

    var editStyle = {fontSize: "16px", height: "20px", width:"60px", padding: "3px", backgroundColor:"#47909D", color: "#fff", borderRadius:"5px"};
    editStyle.textAlign = props.number ? "right" : "left";

    return (
        <div style={{width:"100%", display:"flex", alignItems: "center", padding: "0px", justifyContent: "space-between"}}>
            <div style={{marginRight: "10px"}}>Modify chance of success:</div>
            <TextField type="number" inputProps={{style: {fontSize: "16px", backgroundColor: "#fff", borderRadius: "5px ", width:"80px", height: "30px", padding: "3px", textAlign:"center"}}} placeholder="Modifier" color="primary" value={value} onChange={handleChange} variant="outlined" size="small"></TextField>
        </div>
)

};

export default ModifierBox;


