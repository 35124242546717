import React, {useState, useEffect} from "react";
import globalStyles from '../../Styles/styles.module.css';
import DamageButton from '../../Buttons/damageButton';
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import Incrementor from '../../Controls/incrementor';
import EditBox from "../../Controls/editBoxSingle";
import WeaponButton from "../../Combat/weaponButton";
import { strikeRankEnchantments } from "../../../services/enchantmentsService";
import calculateSR from "../../../services/strikeRankService";

const WeaponButtonTile = (props) => {

    const [strikeRank, setStrikeRank] = useState(0);

    useEffect(() => {
        var sr = calculateSR("DEX", props.entity) + props.item.weapon.sr;
        if (props.item.weapon.class !== "Missile") {
            sr += calculateSR("SIZ", props.entity)
        }
        setStrikeRank(sr)  
    },[props.entity, props.item]);

    function displayItem() {
        props.displayItem({type: "Weapon", item: props.item})
    }

    function modifyHitPoints(value, event) {
        event.stopPropagation();
        props.modifyHitPoints(value)
    }

    if (props.pageState.editMode) {
        return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <div id={props.item.weapon.name} style={{width:"145px"}}>{props.item.weapon.name}</div>
                    <div style={{display:"flex", alignItems: "center", width: "50px"}}>
                        <EditBox size="sm" onChange={(value) => props.handleValueChange(value)} value={props.item.skill.value} number>{props.item.skill.value}</EditBox>
                    </div>
                </div>
        )
    }
    else {
        return(
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems:"center", minWidth: "400px"}} onClick={displayItem} >
                <div id={props.item.weapon.name} style={{width:"95px"}}>{props.item.weapon.name}</div>
                <div style={{width:"30px", textAlign:"center"}}>{strikeRank + strikeRankEnchantments(props.entity)}</div>
                <div style={{display:"flex",justifyContent:"center", alignItems:"center", width: "90px"}}>
                    <div className={globalStyles.diceButton} style={{cursor: "pointer"}}>
                        <WeaponButton type="Attack" style={{width: "36px", borderRadius: "3px", border: "1px solid #2f588a", display: "flex", alignItems: "center", justifyContent: "center", height: "18px"}} diceRoller={props.diceRoller} entity={props.entity} augmenting={props.augmenting} weapon title={"" } disabled={props.item.skill.value === 0} processResult={props.processResult} item={props.item}>
                            <ButtonValue />
                        </WeaponButton>
                    </div>
                    <div style={{margin: "0px 3px"}}>/</div>
                    <div className={globalStyles.diceButton} style={{cursor: "pointer"}}>
                        <WeaponButton type="Parry" style={{width: "36px", borderRadius: "3px", border: "1px solid #2f588a", display: "flex", alignItems: "center", justifyContent: "center", maxHeight: "18px"}} diceRoller={props.diceRoller} entity={props.entity} augmenting={props.augmenting} weapon title={"" } disabled={props.item.skill.value === 0} processResult={props.processResult} item={props.item}>
                            <ButtonValue />
                        </WeaponButton>
                    </div>
                </div>
    
                <div style={{width:"80px", display:"flex", justifyContent:"center"}}>
                    <div className={globalStyles.damageButton} style={{cursor: "pointer", marginLeft: "5px"}}>
                        <DamageButton style={{width: "75px", border: "1px solid #851113", borderRadius: "3px", textAlign: "center", height: "18px"}} diceRoller={props.diceRoller} noButton entity={props.entity} size="medium" processDamage={props.processDamage} weapon={props.item.weapon} />
                    </div>
                </div>
                <div style={{width: "95px"}}>
                    <Incrementor style={{width: "80px"}} currentValue={props.item.weapon.hitPoints?.current} iconSize="16px" totalValue={props.item.weapon.hitPoints?.total} increment={modifyHitPoints} />
                </div>
            </div>
        );
    }

    function ButtonValue(props) {
        return (
            <div style={{height: "18px"}}>{(props.target || 0) + "%"}</div>
        )
    }
}

export default withPageState(withContext(WeaponButtonTile));