import React from 'react';
import PageState from './pageState';

const PageStateContext = React.createContext(new PageState());

export const withPageState = Component => props => (
  <PageStateContext.Consumer>
    {pageState => <Component {...props} pageState={pageState} />}
  </PageStateContext.Consumer>
);

export default PageStateContext;
