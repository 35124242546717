import React, {useState, useEffect} from "react";
import SpiritSpell from "../Magic/spiritSpell";
import EditIcon from '../../icons/edit-icon-white.svg'
import { withPageState } from "../Context/PageState";
import { withContext } from "../Context/context";
import DialogBox from "../Dialogs/dialogBox";
import MatrixDialog from "./matrixDialog";
import * as SAVE from '../../constants/save';

const SpiritMagicMatrix = (props) => {
    const [matrix, setMatrix] = useState(props.matrix);
    const [open, setOpen] = useState(false);

    useEffect(()=> {
        setMatrix({...props.matrix});
    },[props.matrix])

    function updateSpell(updatedSpell){
        var updatedMatrix = {...matrix}
        updatedMatrix.spell = updatedSpell;
        props.update(updatedMatrix, SAVE.YES)
    }

    function displayItem(displayDetails) {
        props.displayItem({...displayDetails, parent: {type: "matrix", id: matrix.id}});
    }

    return (
        <div>
            <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#2F7589", color: "#fff", padding: "0px 5px", height: "30px", position: "relative"}}>
                <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", maxWidth: props.pageState.editMode ? "320px" : "360px"}}>{"Matrix enchanted into " + matrix.location.toLowerCase()} </div>
                {props.pageState.editMode ? 
                    <div style={{position: "absolute", right: "10px", top: "4px", cursor: "pointer"}} onClick={() => setOpen(true)}>
                        <img src={EditIcon} style={{width: "22px"}} alt="Edit matrix" title="Edit matrix" />
                    </div>
                : 
                    null
                }
            </div>
            {props.pageState.editMode ? 
                <div style={{display:"flex", fontWeight: "bold", padding: "5px 5px 0px"}}>
                    <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                    <div style={{width: "80px", textAlign: "center"}}>Variable</div>
                    <div style={{width: "70px", textAlign: "center"}}>Pts</div>
                </div>
            : 
                <div style={{display:"flex", fontWeight: "bold", padding: "5px 5px 0px"}}>
                    <div style={{width: "190px", paddingLeft: "3px"}}>Spell</div>
                    <div style={{width: "30px", textAlign: "center"}}>Pts</div>
                    <div style={{width: "70px", textAlign: "center"}}>Variable</div>
                    <div style={{width: "30px", textAlign: "center"}}>SR</div>
                    <div style={{width: "40px", textAlign: "right", marginLeft: "8px"}}>Cast</div>
                </div>
            }
            <div className={props.stylesheet.spellList}>
                <SpiritSpell diceRoller={props.diceRoller} matrix={matrix} element={props.element} availableMagicPoints={props.availableMagicPoints} displayItem={displayItem} character={props.providerData.character} spellcaster={props.providerData.character} item={matrix.spell} processResult={props.processResult} update={updateSpell} powGain={props.powGain} />
            </div>
            <DialogBox title="Edit Spirit Spell Matrix" isOpen={open} width="700px" >
                <MatrixDialog type="Spirit spell matrix" close={() => setOpen(false)} update={props.update} matrix={matrix} delete={props.delete} />
            </DialogBox>
        </div>
    )
}

export default withPageState(withContext(SpiritMagicMatrix));