import React, {useState, useEffect} from 'react';
import { withContext } from '../Context/context';
import globalStyles from '../Styles/styles.module.css';

const ArmourListItem = (props) => {

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [buttonTitle, setButtonTitle] = useState("Add");

    const handleClick = () => {
        setDisabled(true)
        setButtonTitle("Added");
        props.add(props.item);
    }

    return (
        <>
            <div className={globalStyles.row} style={{display:"flex", height: "24px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312", fontSize: "13px"}}>
                <div style={{display: "flex", width:"100%", paddingLeft: "5px", justifyContent: "space-between"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        <div style={{cursor: "pointer", display:"flex", }} onClick={() => setOpen(!open)}>
                            <div style={{width:"220px", alignItems: "center", display: "flex"}}>
                                <div>{props.item.material}&nbsp;{props.item.type}</div>
                            </div>
                            <div style={{width:"90px", justifyContent:"center", display:"flex", alignItems: "center"}}>{props.item.points}</div>
                            <div style={{width:"90px", justifyContent:"center", display:"flex", alignItems: "center", justifyContent:"center"}}>{props.item.ENC || props.item.enc}</div>
                            <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap", width: "400px"}}>{props.item.description}</div>
                        </div>
                    </div>
                    <button disabled={disabled} className={disabled ? globalStyles.disabledButton : globalStyles.activeButton} style={{borderRadius: "3px", padding: "2px 5px", width: "60px"}} onClick={handleClick}>{buttonTitle}</button>
                </div>
            </div>
            {open ?
                <div style={{minHeight:"80px", backgroundColor: "#fff", margin: "2px", padding: "10px"}}>
                    <div style={{display:"flex", color:"#2F588A"}}>
                        <div style={{display:"flex", margin: "0px 10px"}}>
                            <div><b>Category:</b></div>
                            <div style={{marginLeft: "5px"}}>{props.item.area}</div>
                        </div>
                        <div style={{display:"flex", margin: "0px 10px"}}>
                            <div><b>Type:</b></div>
                            <div style={{marginLeft: "5px"}}><b>{props.item.type}</b></div>
                        </div>
                        <div style={{display:"flex", margin: "0px 10px"}}>
                            <div><b>Material:</b></div>
                            <div style={{marginLeft: "5px"}}><b>{props.item.material}</b></div>
                        </div>
                    </div>
                    <div style={{display:"flex", color:"#2F588A", marginTop: "10px"}}>
                        <div style={{display:"flex"}}>
                            <div><b>Absorbs:</b></div>
                            <div style={{marginLeft: "5px"}}>{props.item.points}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>ENC:</b></div>
                            <div style={{marginLeft: "5px"}}>{props.item.ENC}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Cost:</b></div>
                            <div style={{marginLeft: "5px"}}>{props.item.cost}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Move Quietly:</b></div>
                            <div style={{marginLeft: "5px"}}>{props.item.stealth}</div>
                        </div>
                    </div>
                    <div style={{marginTop: "10px"}} dangerouslySetInnerHTML={{ __html: props.item.description}} />
                </div>
                : 
                null
                }
        </>
    )
}

export default withContext(ArmourListItem);