import React, { useState, useEffect } from 'react';
import compareValues from '../../../utils/compareValues';
import SpiritSpellPanel from '../../Magic/spiritSpellPanel';
import { getSpiritSpell } from '../../../services/dataService';

const CultSpiritSpells = (props) => {

    const [spiritSpells, setSpiritSpells] = useState([]);
    const [availablePoints, setAvailablePoints] = useState(999);

	useEffect(() => {
		if (props.maxPoints){ 
			var usage = 0;
			if (props.cult.selectedSpiritSpells && props.cult.selectedSpiritSpells.length > 0) {
				props.cult.selectedSpiritSpells.forEach(spell => {
					usage += spell.points;
				});
			}
			setAvailablePoints(props.maxPoints - usage);
		}
	},[props.cult.selectedSpiritSpells, props.maxPoints]);

    useEffect(() => {
        if (props.cult) {
            var spells = [...props.cult.spiritSpells];
            props.cult.associated.forEach(associatedCult => {
                var index = props.allCults.findIndex(cult => cult.name == associatedCult.name);
                if (index > -1) {
                    spells = [...spells, ...props.allCults[index].spiritSpells];
                }
            });
            var uniqueSpells = [...new Map(spells.map((m) => [m.name, m])).values()];
            if (props.cult.prohibited) {
                props.cult.prohibited.forEach(prohibitedSpell => {
                    var index = uniqueSpells.findIndex(spell => spell.name === prohibitedSpell.name);
                    if (index > -1) {
                        uniqueSpells.splice(index, 1);
                    }
                });
            }
            if (props.builder.occupation.freeSpiritSpells) {
                props.builder.occupation.freeSpiritSpells.forEach(freeSpell => {
                    var index = uniqueSpells.findIndex(spell => spell.parentId === freeSpell.parentId);
                    if (index > -1) {
                        uniqueSpells.splice(index, 1);
                    }
                })
            }
            props.cult.selectedSpiritSpells.forEach(selectedSpell => {
                var index = uniqueSpells.findIndex(obj => obj.parentId === selectedSpell.parentId);
                uniqueSpells[index].points = selectedSpell.points;
            })
            uniqueSpells.forEach(spell => {
                if (!spell.id) {
                    spell.id = spell.parentId;
                }
                let spellDetails = getSpiritSpell(spell.parentId, props.builder.campaign)
                if (spellDetails) {
					spell.description = spellDetails.description;
					spell.range = spellDetails.range;
					if (spellDetails.variant) {
						spell.variant = spellDetails.variant;
					}
					spell.duration = spellDetails.duration;
					spell.variable = spellDetails.variable;
                    spell.points = spellDetails.points;               
                }
            })
            setSpiritSpells(uniqueSpells.sort(compareValues('name')));
            var points = 0;
            if (props.cult.selectedSpiritSpells && props.cult.selectedSpiritSpells.length > 0) {
                props.cult.selectedSpiritSpells.forEach(spell => {
                    points += spell.points;
                });
            }
            props.isComplete(points == props.maxPoints);
        }
    },[props.cult]);

    function updateSpiritSpells(selectedSpells) {
        props.update(selectedSpells, false);
    }

    if (props.cult) {
        return (
            <div style={{backgroundColor: "#cdcdcd", border: "2px solid #2F588A", borderRadius: "10px", padding: "20px 0px"}}>
                <div style={{padding:"0px 10px 20px", fontSize: "20px", display: "flex"}}>
                    <div >{"Select " + props.maxPoints + " points of Spirit Magic"}</div>
                    <div style={{marginLeft: "10px"}}>{"(points remaining: " + availablePoints + ")"}</div>
                </div>
                <SpiritSpellPanel campaign={props.builder.campaign} currentSpells={props.cult.selectedSpiritSpells} availableSpells = {spiritSpells} setSpells={()=> {}} availablePoints={availablePoints} select={updateSpiritSpells} />
            </div>
        );
    }
    else {
        return null;
    }
}

export default CultSpiritSpells;