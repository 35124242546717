import React from 'react';
import { Button, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';

export default function BenefitsDialog(props) {

	const [benefit, setBenefit] = React.useState("");

	function handleChange(event) {
		setBenefit(event.target.value)
	}

	function handleOkClick(){
		props.addBenefit(props.options[benefit]);
	}

	return (
		<div style={{padding: "20px"}}>
			<div>
				<div style={{fontSize:"16px"}}>
					<div>{props.outcome.display}</div>
					<div style={{marginTop: "40px"}}>Select from: </div>
				</div>
				<div style={{margin: "10px"}}>
					<FormControl component="fieldset">
						<RadioGroup aria-label={"Year " + props.year + " events" } name="events" value={parseInt(benefit)} onChange={handleChange}>
							{
								props.options.map((option, index) => {
									var label = "";
									if (option.type === "passion") {
										label = option.variant ?  option.name + " (" + option.variant + ")" : option.name;
									}
									else if (option.type === "skill") {
										var label = option.name;
									}
									return (
										<FormControlLabel key={index} value={index} control={<Radio />} label={label} />
									)
								})
							}
						</RadioGroup>
					</FormControl>
				</div>
			</div>
			<div style={{display:"flex", flexDirection: "row-reverse", marginBottom: "10px"}}>
				<Button onClick={handleOkClick} disabled={benefit == ""} color="primary" variant="contained" style={{width: "100px"}}>Ok</Button>
			</div>
		</div>
	);
}