import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import Abilities from '../Abilities/abilities';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';

const Runes = (props) => {
    const [open, setOpen] = useState(false);
    const [allRunes, setAllRunes] = useState([]);
    const [displayRunes, setDisplayRunes] = useState([]);
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.runes.favourites)
        }
        var runes = [];
        props.providerData.character.runes.forEach(rune => {
            if ((!props.pageState.editMode) && rune.value === 0) return;
            if (props.providerData.character.configuration && props.providerData.character.configuration.runes.favourites && (!rune.favourite)) {
                return;
            }
            runes.push({...rune});
        })
        setDisplayRunes(runes);
    },[props.providerData.character.configuration]);

    useEffect(() => {
        setAllRunes(props.providerData.character.runes);
    },[props.providerData.character]);

    function handleValueChange(value, item) {
        var updatedCharacter = {...props.providerData.character};
        updatedCharacter.runes.forEach(rune => {
            if (rune.name === item.name) {
                rune.value = value;
                return;
            }
        });
        props.update(updatedCharacter);
    }

    function newRune(selectedRune) {
        var updatedRunes = [...props.providerData.character.runes];
        updatedRunes.push(selectedRune);
        props.update([{fieldName: "runes", value: updatedRunes}], SAVE.NO);
    }

    const updateFavourites = (updates, newValue) => {
        showFavouritesOnly(newValue);
        props.update(updates, SAVE.YES)
    }

    function displayAbilities() {
        var categories = ["All"]

        return (
            <>
                {/* {props.providerData.type === "character" ? 
                <div style={{backgroundColor: "#232523", marginBottom: "5px", color: "#ffffff", fontSize: "16px", border: "2px solid #090909", height: "36px", borderTopLeftRadius: "3px", borderTopRightRadius: "3px", fontSize: "18px", display:"flex", alignItems: "center", padding: "0px 15px"}}>
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width: "100%"}}>
                        <div>Runes</div>
                            {props.pageState.editMode ? 
                            <div style={{marginRight:"10px", display:"flex", alignItems: "center"}}>
                                <AddAbilityButton addAbility={() => setOpen(true)} title={"Add rune"}></AddAbilityButton>
                            </div>
                            : 
                            <div style={{marginRight:"10px", display:"flex", height: "24px", alignItems: "center"}}>
                                <DisplayFavouritesButton toggled={favouritesOnly} character={props.providerData.character} type="runes" update={updateFavourites} />
                            </div>
                            }
                    </div>
                </div>
                : 
                null
                } */}
            </>
        )
    }

    if (!allRunes) return null;
        return(
            <Abilities diceRoller={props.diceRoller} style={{...props.style}} title={props.title} columnCount={props.columnCount} entity={props.entity} displayItem={props.displayItem} display={props.display} tile={props.tile} favouritesOnly={favouritesOnly} displayItems={displayRunes} type="Rune" items={allRunes} processResult={props.processResult} update={props.update} handleValueChange={handleValueChange} ></Abilities>
        );
    }

    export default withContext(withPageState(Runes));