import React from "react";
import { Button } from "@mui/material";

const DialogButtonSingle = (props) => {

    const closeClick = () => {
        if (props.closeClick) {
            props.closeClick();
        }
    }

    return (
        <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", padding: "20px", justifyContent:"space-between"}}>
            <div style={{display:"flex", flexDirection:"row-reverse"}}>
                <Button onClick={closeClick} color="primary" variant="contained" style={{width: "100px"}} >{props.title || "Close"}</Button>
            </div>
        </div>
    )
}

export default DialogButtonSingle;