import { getStatValue } from "./attributeService";

export function deductRunePoints(spellDetails) {
    var pointsRemaining = spellDetails.points;
    var updatedRunePoints = [...spellDetails.cult.runePoints];

    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedRunePoints, "Allied spirit", pointsRemaining);
    if (pointsRemaining > 0) pointsRemaining = reduceEntityPoints(updatedRunePoints, "Character", pointsRemaining);
    var updatedCult = {...spellDetails.cult}
    updatedCult.runePoints = updatedRunePoints;
    return updatedCult;
}

function reduceEntityPoints(updatedRunePoints, type, points) {
    var pointsRemaining = points;
    updatedRunePoints.forEach(runePoints => {
        if (runePoints.type.toLowerCase() === type.toLowerCase()) {
            pointsRemaining = reducePoints(runePoints, pointsRemaining)        
        }
    })
    return pointsRemaining;
}

const reducePoints = (runePoints, pointsRemaining) => {
    if (runePoints.current >= pointsRemaining) {
        var current = runePoints.current -= pointsRemaining;
        runePoints.current = current;
        pointsRemaining = 0;
    }
    else {
        pointsRemaining -= (runePoints.current);
        runePoints.current = 0;
    }
    return pointsRemaining;
}

export function getAvailableRunePoints(character, selectedCult) {
    var points = 0;
    if (character.cults && character.cults.length > 0) {
        var index = character.cults.findIndex(cult => cult.name === selectedCult.name);
        if (index > -1) {
            character.cults[index].runePoints.forEach(entity => {
                points += entity.current;
            })
        }
    }
    return points; 
}

export function incrementAvailableRunePoints(character) {
    if (character.runePoints.current < character.runePoints.total) {
        character.runePoints.current ++;
        return;
    }
    var incomplete = true;

    if (character.boundSpirits) {
        character.boundSpirits.forEach(spirit => {
            if (spirit.type === "allied" && spirit.runePoints.current < spirit.runePoints.total) {
                spirit.runePoints.current ++;
                incomplete = false;
                return;
            }
        });
    }
    if (incomplete && character.boundSpirits) {
        character.boundSpirits.forEach(spirit => {
            if (spirit.type === "bound" && spirit.runePoints.current < spirit.runePoints.total) {
                spirit.runePoints.current ++;
                incomplete = false;
                return;
            }
        });
    }
    if (incomplete && character.crystals) {
        character.crystals.forEach(item => {
            if (item.runePoints.current < item.runePoints.total) {
                item.runePoints.current ++;
                return;
            }
        })
    }
}

export function decrementAvailableRunePoints(character) {
    var incomplete = true;
    if (character.crystals) {
        character.crystals.forEach(item => {
            if (item.runePoints.current > 0) {
                item.runePoints.current --;
                incomplete = false;
                return;
            }
        })
    }
    if (incomplete && character.boundSpirits) {
        character.boundSpirits.forEach(spirit => {
            if (spirit.type === "bound" && spirit.runePoints.current > 1) {
                spirit.runePoints.current --;
                incomplete = false;
                return;
            }
        });
    }
    if (incomplete && character.boundSpirits) {
        character.boundSpirits.forEach(spirit => {
            if (spirit.type === "allied" && spirit.runePoints.current > 1) {
                spirit.runePoints.current --;
                incomplete = false;
                return;
            }
        });
    }
    if (incomplete && character.runePoints.current > 1) {
        character.runePoints.current --;
        return;
    }
}

export function calculateSpellStorage(character) {
    if (character.characteristics.CHA) {
        var totalPoints = 0; 
        character.spiritSpells.forEach(spell => {
            totalPoints += spell.points;
        });
        return getStatValue(character.characteristics.CHA) - totalPoints;
    }
    else {
        return 0;
    }
}