import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import DamageButton from '../Buttons/damageButton';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Typography } from '@mui/material';
import CrushingDamageButton from './crushingDamageButton';
import SlashingDamageButton from './slashingDamageButton';
import WeaponButton from './weaponButton';
import ModifierBox from '../Controls/modifierBox';
import EditBox from '../Controls/editBoxSingle';
import { withContext } from '../Context/context';
import { withPageState } from '../Context/PageState';
import * as SAVE from '../../constants/save';
import Incrementor from '../Controls/incrementor';
import { getEnchantmentsModifier } from '../../services/enchantmentsService';
import { lowWeaponStats } from '../../services/attributeService';
import { getAllWeapons } from '../../services/dataService';

const WeaponTileContextMenu = (props) => {

    const [modifier, setModifier] = useState("");
    const [selectedType, setSelectedType] = useState("Normal");
    const [item, setItem] = useState({...props.panelItem.item});
    const [categoryModifier, setCategoryModifier] = useState(0);
    const [encPenalty, setEncPenalty] = useState(0);
    const [attackModifier, setAttackModifier] = useState(0);
    const [parryModifier, setParryModifier] = useState(0);
    const [weapon, setWeapon] = useState({})
    const [statModifier, setStatModifier] = useState(0);

    const damageTypes=["Normal", "Special", "Critical", "Magical only"]

    useEffect(() => {
        setItem({...props.panelItem.item});
        var allWeapons = getAllWeapons(props.providerData.campaign)
        var index = allWeapons.findIndex(obj => obj.id === props.panelItem.item.weapon.parentId);
        setWeapon(allWeapons[index]);
        setCategoryModifier(props.providerData.character.categoryModifiers.Manipulation || 0);
        var excessEnc = props.providerData.character.maxEnc - props.providerData.currentEncumbrance;
        if (excessEnc < 0) {
            setEncPenalty(excessEnc * 5);
        }
        var value = props.panelItem.item.skill.value + props.providerData.character.categoryModifiers.Manipulation;
        var statMultiplier = lowWeaponStats(props.providerData.character, props.panelItem.item.weapon);
        if (statMultiplier < 1) {
            var modifier = Math.ceil((value * statMultiplier) * -1);
            setStatModifier(modifier);
            value += modifier;
        }
    
        var item = {...props.panelItem.item.weapon, value: value}
        var attackMods = getEnchantmentsModifier("attack", props.providerData.character, item, value);
        setAttackModifier(attackMods);
        var parryMods = getEnchantmentsModifier("parry", props.providerData.character, item, value);
        setParryModifier(parryMods);

    },[props.panelItem.item, props.providerData.character.characteristics, props.providerData.character.enchantments])

    useEffect(() => {
        if (props.buttonClick === "ok") {
            update(item, SAVE.NO);
        }
    },[props.buttonClick])

    function handleChange(event, ) {
        var type = event.target.value;
        setSelectedType(type)
    }

    function handleWeaponChange(field, value, ) {
        var updatedItem = {...item};
        updatedItem.weapon[field] = value;
        setItem(updatedItem);
    }

    function handleSkillChange(field, value, ) {
        var updatedItem = {...item};
        updatedItem.skill[field] = value;
        setItem(updatedItem);
    }

    const incrementHitPoints = (value) => {
        var updatedItem = {...item};
        if ((updatedItem.weapon.hitPoints.current + value) > updatedItem.weapon.hitPoints.total) {return}
        updatedItem.weapon.hitPoints.current += value;
        setItem(updatedItem);
        var updatedWeapons = [...props.entity.weapons]
        var index = updatedWeapons.findIndex(obj => obj.id === item.weapon.id)
        updatedWeapons[index].hitPoints = {...updatedItem.weapon.hitPoints};
        props.update([{fieldName: "weapons", value: updatedWeapons}], SAVE.DELAYED);
    }

    function update(updatedItem, persist) {
        var updatedWeaponSkills = [...props.entity.weaponSkills]
        var index = updatedWeaponSkills.findIndex(obj => obj.id === item.skill.id)
        updatedWeaponSkills[index].value = updatedItem.skill.value;
        props.update([{fieldName: "weaponSkills", value: updatedWeaponSkills}], persist);
    }

    const processResult = (roll) => {
        var verb = roll.type == "parry" ? "parried" : roll.type;
        var messageText={critical: " made a critical " + verb + " with {pronoun} " + item.weapon.name, special: " achieved a special " + verb + " with {pronoun} " + item.weapon.name, success: " successfully " + verb + " with {pronoun} " + item.weapon.name, failure: " failed to " + verb + " with {pronoun} " + item.weapon.name, fumble: " attempted to " + verb + " with {pronoun} " + item.weapon.name + " but fumbled"};
        var dieRoll = {target: roll.target, result: roll.result, messageText: messageText, augmentRoll: roll.augment}
        props.processResult(dieRoll, {...roll.item, type:"weaponSkills"}, roll.title)
    }

    const displayWeaponDetails = () => {
        return (
            <div style={{fontSize: "14px"}}>
                <hr style={{width:"360px"}} />
                <div>
                    <div style={{fontSize: "16px", fontWeight: "bold", margin: "10px 0px"}}>{weapon && weapon.name}</div>
                    <div style={{fontWeight: "bold", lineHeight: "20px"}}>
                        <div>Base Value: {item.skill.value}%{(categoryModifier ? " + Category Modifier (" + categoryModifier + "%) = " + (item.skill.value + categoryModifier) + "%": "")}</div>
                        {statModifier < 0 ? 
                            <div>Insufficent STR/DEX: {statModifier}%</div>
                        : null}
                        {attackModifier ? 
                            <div>Attack Enchantments: {attackModifier}%</div>
                        : null}
                        {parryModifier ? 
                            <div>Parry Enchantments: {parryModifier}%</div>
                        : null}
                        {encPenalty ? 
                            <div>Enc Penalty: {encPenalty}%</div>
                        : null}
                    </div>
                    <hr style={{width:"360px"}} />
                    <div style={{display: "flex", margin: "5px 0px", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{display: "flex", width: "90px"}}>
                            <div style={{marginRight: "10px"}}>Min STR: </div>
                            <div>{weapon.minSTR}</div>
                        </div>
                        <div style={{display: "flex", width: "90px"}}>
                            <div style={{marginRight: "10px"}}>Min DEX: </div>
                            <div>{weapon.minDEX}</div>
                        </div>
                        <div style={{display: "flex", width: "90px"}}>
                            <div style={{marginRight: "10px"}}>Enc: </div>
                            <div>{weapon.ENC}</div>
                        </div>
                    </div>
                    {weapon.class === "Melee" ? 
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>Length: </div>
                                <div>{weapon.length}</div>
                            </div>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>SR: </div>
                                <div>{weapon.sr}</div>
                            </div>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>Type: </div>
                                <div>{weapon.damage && weapon.damage.type}</div>
                            </div>
                        </div>
                    :
                    weapon.class === "Missile" ? 
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>Range: </div>
                                <div>{weapon.range}</div>
                            </div>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>Rate: </div>
                                <div>{weapon.rate}</div>
                            </div>
                            <div style={{display: "flex", width: "90px"}}>
                                <div style={{marginRight: "10px"}}>Type: </div>
                                <div>{weapon.damage && weapon.damage.type}</div>
                            </div>
                        </div>
                    : 
                    null
                    }
                    <div style={{width:"100%", display:"flex", alignItems: "center", padding: "10px 0px", justifyContent:"space-between", fontSize:"14px"}}>
                        <div dangerouslySetInnerHTML={{__html: weapon && weapon.description}}></div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.pageState.editMode){
        return(
                <div style={{padding: "10px"}}>
                    <div style={{display:"flex", margin: "10px", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{marginRight: "10px"}}>Weapon title: </div>
                        <EditBox onChange={(value) => handleWeaponChange("title", value)} value={item.weapon.title} width="200px" >{item.weapon.title}</EditBox>
                    </div>
                    <div style={{display:"flex", margin: "10px", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{marginRight: "10px"}}>Value*: </div>
                        <EditBox onChange={(value) => handleSkillChange("value", value)} value={item.skill.value} width="100px" number>{item.skill.value}</EditBox>
                    </div>
                    <div>* Does not include the Manipulation skill category modifier</div>
                    {displayWeaponDetails()}
                </div>
        )
    }
    else {
        if (! item.weapon.damage) return;
        return (
                <div style={{padding: "10px", fontSize: "14px", lineHeight: "1"}}>
                    <ModifierBox handleChange={(value) => setModifier(value)} />
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px", marginBottom: "10px"}}>
                        <div>Attack/Parry</div>
                        <div style={{display: "flex", }}>
                                <WeaponButton className={globalStyles.activeButton} style={{width: "100px", borderRadius: "5px"}} diceRoller={props.diceRoller} type="Attack" modifier={modifier} weapon title={"Attack: " } processResult={processResult} item={item} entity={props.entity}>
                                    <DisplayButton />
                                </WeaponButton>
                                <WeaponButton className={globalStyles.activeButton} style={{width: "100px", borderRadius: "5px", marginLeft: "10px"}} diceRoller={props.diceRoller} type="Parry" modifier={modifier} weapon title={"Parry: " } processResult={processResult} item={item} entity={props.entity}>
                                    <DisplayButton />
                                </WeaponButton>
                        </div>
                    </div>
                    <hr style={{width:"360px"}} />
                    <div style={{width: "100%", display: "flex", padding: "10px 0px", alignItems: "center"}}>
                        <div style={{fontSize: "14px"}}>
                            <FormControl variant="outlined">
                                <FormLabel id="damage-type-label">Damage type</FormLabel>
                                <RadioGroup aria-label="damage-type-label" sx={{fontSize: "14px"}} row color="primary" value={selectedType} onChange={handleChange}>
                                    {damageTypes.map(type => {
                                        return (
                                            <FormControlLabel key={type} style={{fontSize: "14px"}} checked={selectedType == type} value={type} control={<Radio color='primary' />} label={<span style={{fontSize: "14px"}}>{type}</span>} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div style={{width:"160px", display:"flex", justifyContent:"center", alignItems: "center"}}>
                            {item.weapon.damage.type === "C" ? 
                                <div className={globalStyles.largeDamageButton} style={{width: "110px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <CrushingDamageButton processDamage={props.processDamage} weapon={item.weapon} entity={props.entity} >
                                        <DamageButton diceRoller={props.diceRoller} type={selectedType} />
                                    </CrushingDamageButton>
                                </div>
                            :
                                <div className={globalStyles.largeDamageButton} style={{width: "110px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <SlashingDamageButton processDamage={props.processDamage} weapon={item.weapon} entity={props.entity}>
                                            <DamageButton diceRoller={props.diceRoller} type={selectedType} />
                                    </SlashingDamageButton>
                                </div>
                            }
                        </div>
                    </div>
                    <hr style={{width:"360px"}} />
                    <div style={{fontSize: "16px", display: "flex", alignItems: "center"}}>
                        <div style={{fontWeight: "bold", margin: "10px 10px 10px 0px"}}>Hit Points: </div>
                        <Incrementor currentValue={item.weapon.hitPoints.current} totalValue={item.weapon.hitPoints.total} increment={incrementHitPoints} />
                    </div>
                    {displayWeaponDetails()}
                </div>
        )
    }

    function DisplayButton(props) {
        return (
            <div style={{cursor: props.disabled ? "default" : "pointer", height: "32px", display: "flex", alignItems: "center", justifyContent: "center"}}>{props.target + "%"}</div>

        )
    }

    function DamageButtonValue(props) {
        return (
            <div >{props.buttonTitle}</div>
        )
    }
}

export default withPageState(withContext(WeaponTileContextMenu));