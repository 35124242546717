import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';

export default function AddCampaignDialog(props) {

	const [state, setState] = useState({
		name: "",
		description: "",
		imageUrl: ""
	});
	const [disabled, setDisabled] = useState(true);

	function update(field, value) {
		var updatedState = {...state};
		updatedState[field] = value;
		setState(updatedState);
	}

	useEffect(() => {
		var disabled = true;
		if (state.name && state.description && state.imageUrl) {
			disabled = false;
		}
		setDisabled(disabled);
	},[state])

	function handleOkClick(){
		var newCampaign = {
			name: state.name,
			description: state.description,
			imageUrl: state.imageUrl,
			chapters: [],
			notes: [],
			modified: new Date(),
			spiritSpells: [],
			runeSpells: [],
			cults: [],
			passions: [],
			skills: [],
			runes: [],
			weapons: [],
		}
		props.addCampaign(newCampaign);
	}

	function handleDialogClose(){
		props.handleDialogClose();
	}

	const style = {
		width: "100%"
	};

	function getImages() {
		var images = [];
		for (var i = 1; i < 10; i++) {
			images.push("https://storage.googleapis.com/runequest/images/campaign-image-" + i + ".png")
		}
		return images;
	}

	return (
		<div style={{padding: "10px"}}>
			<div style={{marginBottom: "10px"}}>
				<EditBox width="710px" label="Campaign name:" autoFocus onChange={(value) => {update("name", value)}} />
			</div>
			<div style={{marginBottom: "10px"}}>
				<EditBox width="710px" label="Description:" multiline rows={2} onChange={(value) => {update("description", value)}} />
			</div>
			<div style={{display:"flex", flexWrap: "wrap", marginLeft: "30px"}}>
			{
				getImages()
				.map((image, index) => {
					return (
						<img style={{width: "180px", opacity: state.imageUrl && image !== state.imageUrl ? 0.6 : 1, height: "180px", margin: "10px", border: image === state.imageUrl ? "2px solid #2f588a" : "2px solid transparent"}} src={image} key={index} onClick={() => update("imageUrl", image)} />
					)
				})
			}
			</div>
			<div style={{display:"flex", flexDirection:"row-reverse", marginTop: "30px"}}>
				<Button disabled={disabled} style={{marginLeft:"30px",marginBottom:"10px", width: "160px"}} onClick={handleOkClick} color="primary" variant="contained">Confirm</Button>
				<Button style={{marginBottom:"10px", width: "160px"}} onClick={handleDialogClose} color="primary" variant="outlined">Cancel</Button>
			</div>
		</div>
	);
}