import React, { useState} from 'react';
import globalStyles from '../../Styles/styles.module.css'
import BackgroundIcon from '../../../icons/background-icon.svg';
import ReputationDialog from '../dialogs/backgroundDialog'
import DialogBox from '../../Dialogs/dialogBox';

export default function BackgroundButton(props) {
    const [open, setOpen] = useState(false);
   
    return (
        <>
            <div onClick={() => setOpen(true)} className={globalStyles.floatingButton}>
                <img style={{width:"40px"}} src={BackgroundIcon} title="Background & reputation" />
            </div>
            <DialogBox isOpen={open} size="md" title="Background & Reputation">
                <ReputationDialog update={props.update} displayMessage={props.displayMessage} character={props.character} user={props.user} close={() => setOpen(false)} />
            </DialogBox>
        </>
    );
}