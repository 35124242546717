import React, { useState, useEffect } from 'react';
import {withContext} from '../Context/context';
import { FormControl, Checkbox } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditBox from '../Controls/editBoxSingle';
import { getRuneSpell } from '../../services/dataService';
import getElementalRune from '../../services/runesService';

const RuneSpellListItem = (props) => {

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [cast, setCast] = useState(0);
    const [spell, setSpell] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        var selected = false;
        props.selected.forEach(item => {
            if (item.id === props.spell.id) {
                setChecked(true);
                selected = true;
            }
        })
        var spellDisabled = false;
        if (!selected) {
            setChecked(false);
            if (props.availableSpellCount === 0) {
                spellDisabled = true;
            }
        }
        setDisabled(spellDisabled)
    },[props.spell, props.availableSpellCount, props.selected]);

    useEffect(() => {
        var value = 0;
        var runes = [...props.spell.runes];
        if ((runes[0] === "Magic") && props.cult) {
            runes = [...props.cult.runes]
        }
        else if ((runes[0] === "Elemental") && props.cult) {
            runes = getElementalRune(props.cult.runes);
        }
        runes.forEach(spellRune => {
            var characterRunes = [...props.characterRunes];
            characterRunes.forEach(charRune => {
                if (spellRune.replace("/", "") === charRune.name && charRune.value > value) {
                    value = charRune.value;
                }
            })
        })
        setCast(value);
        var spell = {...props.spell, runes: runes}
        const spellDetails = getRuneSpell(spell.parentId, props.campaign)
        spell.description = spellDetails.description
        spell.duration = spellDetails.duration;
        spell.range = spellDetails.range;
        spell.stackable = spellDetails.stackable;
        setSpell(spell);
    },[props.spell, props.cult, props.characterRunes])

    const selectSpell = (event) => {
        setChecked(event.target.checked);
        props.select(spell, event.target.checked);
    }
  
    function setVariant(value) {
        var updatedSpell = {...spell}
        updatedSpell.variant = value;
        setSpell(updatedSpell);
        props.update(updatedSpell);  
    }
  
    function amendVariablePoints(value){
        var updatedSpell = {...spell};
        if ((updatedSpell.points + value) > 0) {
            updatedSpell.points += value;
            setSpell(updatedSpell);
            props.update(updatedSpell);           
        }
        if (!checked) {
            setChecked(true);
        }
        props.select(updatedSpell, checked);
    }

    if (!spell) return null;

    return(
        <>
            <div onClick={() => setOpen(!open)} style={{display:"flex", alignItems: "center", height: "28px", overflow: "hidden", padding: "0px 5px", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312"}}>
                <div onClick={(event) => {event.stopPropagation()}} style={{flex: "0 0 50px"}}>
                    <FormControl variant="outlined">
                        <Checkbox disabled={disabled} checked={checked} color='primary' onChange={selectSpell} />
                    </FormControl>
                </div>
                <div style={{fontWeight:700, display: "flex", alignItems: "center", flex: "0 0 225px", cursor:"pointer"}}>
                        <div>{spell.name}</div>
                        {spell.variant ? 
                        <div onClick={(event) => {event.stopPropagation()}} style={{width: "100px", marginLeft: "10px"}}>
                            <EditBox size="sm" value={spell.variant } onChange={setVariant} />
                        </div>
                        : 
                        null }
                    </div>
                <div style={{flex: "0 0 80px", textAlign:"center", cursor:"pointer", display:"flex", justifyContent:"center"}}>
                    {spell.points || "-"}
                    {props.matrix ? 
                        spell.stackable ?
                            <div onClick={(event) => {event.stopPropagation()}} style={{display:"flex", marginLeft:"5px", alignItems:"center"}}>
                                    <RemoveCircleOutlineIcon style={{height:"16px", width:"16px"}} onClick={() => amendVariablePoints(-1)}/>
                                    <AddCircleOutlineIcon style={{height:"16px", width:"16px", marginLeft:"4px"}} onClick={() => amendVariablePoints(1)}/>
                            </div>
                        :
                            <div style={{width: "41px"}} />
                    : 
                    null
                    }
                </div>
                <div style={{flex: "0 0 80px", textAlign:"center", cursor:"pointer"}} onClick={() => setOpen(!open)}>{cast || "-"}</div>
                <div style={{flex: "0 0 100px", textAlign:"center", cursor:"pointer"}} onClick={() => setOpen(!open)}>{spell.stackable ? "Yes" : "No"}</div>
                <div style={{flex: "0 0 100px", textAlign:"center", cursor:"pointer"}} onClick={() => setOpen(!open)}>{spell.range}</div>
                <div style={{flex: "0 0 100px", textAlign:"center", cursor:"pointer"}} onClick={() => setOpen(!open)}>{spell.duration}</div>
            </div>
            {open ?
                <div style={{minHeight:"80px", backgroundColor: "#fff", margin: "2px", padding: "10px", cursor: "pointer"}} onClick={() => setOpen(false)}>
                    <div style={{display:"flex"}}>
                        <div style={{fontSize: "18px", color: "#2f588a"}}><b>{spell.name}</b></div>
                    </div>
                    <div style={{display:"flex", color:"#2F588A"}}>
                        <div style={{display:"flex", width: "150px"}}>
                            <div><b>Points:</b></div>
                            <div style={{marginLeft: "5px"}}>{spell.points}</div>
                        </div>
                        <div style={{display:"flex", width: "150px"}}>
                            <div><b>Stackable:</b></div>
                            <div style={{marginLeft: "10px"}}>{spell.stackable ? "Yes": "No"}</div>
                        </div>
                        <div style={{display:"flex", width: "150px"}}>
                            <div><b>Range:</b></div>
                            <div style={{marginLeft: "5px"}}>{spell.range}</div>
                        </div>
                        <div style={{display:"flex", width: "150px"}}>
                            <div><b>Duration:</b></div> 
                            <div style={{marginLeft: "5px"}}>{spell.duration}</div>
                        </div>
                        <div style={{display:"flex", marginLeft: "20px"}}>
                            <div><b>Cast %:</b></div>
                            <div style={{marginLeft: "10px"}}>{cast}%</div>
                        </div>
                    </div>
                    <div style={{marginTop: "10px"}} dangerouslySetInnerHTML={{ __html: spell.description}} />
                </div>
            : 
            null
            }
        </>
    )
};

export default withContext(RuneSpellListItem);
