import React, { useState, useEffect } from 'react';
import styles from '../builder.module.css'
import globalStyles from '../../Styles/styles.module.css'
import { Radio, RadioGroup, FormControl, FormControlLabel, Button } from '@mui/material';
import DialogBox from '../../Dialogs/dialogBox';
import BenefitsDialog from './benefitsDialog';
import EventHistory from './eventHistory';
import * as HistoryData from '../../../data/history.json';

const History = (props) => {
    const [currentAncestor, setCurrentAncestor] = useState();
    const [currentEventId, setCurrentEventId] = useState(0);
    const [currentYear, setCurrentYear] = useState(1561)
    const [outcomeId, setOutcomeId] = useState([0]);
    const [resultId, setResultId] = useState(0);
    const [events, setEvents] = useState();
    const [open, setOpen] = useState(false);
    const [benefitOptions, setBenefitOptions] = useState();
    const [benefitOutcome, setBenefitOutcome] = useState();
    const [index, setIndex] = useState(0);
    const [history, setHistory] = useState([]);
    const [showButtons, setShowButtons] = useState(true);
    const [futureEvent, setFutureEvent] = useState();
    const [showResult, setShowResult] = useState();
    const [result, setResult] = useState();
    const [eventClass, setEventClass] = useState(styles.currentEvent)

    useEffect(() => {
        var allHistory = {...HistoryData}
        setHistory(allHistory.data);
    },[]);

    useEffect(() => {
        if (props.builder.history && props.builder.history.index) {
            setIndex(props.builder.history.index);
            if (props.builder.history.index > 18 ) {
                setShowButtons(false);
                props.disableNext(false);
            }
        }
        else {
            setIndex(0);
        }
    },[props.builder.history.index])

    useEffect(() => {
        if (index >= 19) return;
        if (history && history.length > 0) {
            setCurrentYear(parseInt(history[index].year));
            if (history[index].ancestor === "none"){
                setCurrentAncestor(props.builder);
            }
            else if (history[index].ancestor === "parent"){
                setCurrentAncestor(props.builder.parent);
            }
            else {
                setCurrentAncestor(props.builder.grandparent);
            }
            const fetchData = async () => {

                if (futureEvent && futureEvent.index == index) {
                    setOutcomeId(-1);
                    setCurrentEventId(futureEvent.id);
                    var updatedBuilder = {...props.builder}
                    updatedBuilder.history.events.push({year: history[index].year, outcomes: [futureEvent.description]});
                    setFutureEvent(null);
                    props.update(updatedBuilder)
                }
                else {
                    setCurrentEventId(0);
                }
                setEvents(history[index].events);
                if (history[index].outcome) {
                    addCoreOutcome();
                }
            };
            fetchData();            
        }
      }, [index, history]);

    useEffect(() => {
        setOutcomeId(-1);
    }, [index, currentEventId])

    function addCoreOutcome() {
        var updatedBuilder = {...props.builder};
        var characterHistory = updatedBuilder.history;
        if (! characterHistory) {
            characterHistory = {index: 0, events: []};
        }
        var outcomes = [history[index].outcome]
        var i = characterHistory.events.findIndex(event => event.year === history[index].year);
        if (i < 0) {
            characterHistory.events.push({year: history[index].year, outcomes: outcomes});
            updatedBuilder.history = characterHistory;
            props.update(updatedBuilder);
        }

    }

    const handleChange = (event) => {
        setOutcomeId(parseInt(event.target.value));
        
    };

    const handleSelected = () => {
        var resultId = getResultId(outcomeId);
        getBenefits(outcomeId, resultId);
    }

    const handleRandom = () => {
        var generatedOutcomeId = -1;
        var randomNumber = Math.ceil(Math.random()*(20));

        events[currentEventId].modifiers && events[currentEventId].modifiers.forEach((modifier) => {
            if (modifier.homeland == currentAncestor.homeland.name){
                randomNumber += modifier.value;
            }
            if (currentAncestor.occupation && modifier.occupation == currentAncestor.occupation.name){
                randomNumber += modifier.value;
            }
        })

        events[currentEventId].outcomes.forEach((possibleOutcome) => {
            if (possibleOutcome.from <= randomNumber && possibleOutcome.to >= randomNumber) {
                generatedOutcomeId = possibleOutcome.id
            }
        })
        setOutcomeId(generatedOutcomeId);
        var resultId = getResultId(generatedOutcomeId);
        getBenefits(generatedOutcomeId, resultId);
    }

    const getBenefits = (confirmedOutcomeId, confirmedResultId) => {
        setResultId(confirmedResultId);
        var benefits = events[currentEventId].outcomes[confirmedOutcomeId].results[confirmedResultId].benefits;
        var noOptions = true;
        if (benefits){
            benefits.forEach((benefit) => {
                if (benefit.options && benefit.options.length > 1){
                    noOptions = false;
                    setOpen(true);
                    setBenefitOptions(benefit.options);
                    setBenefitOutcome(events[currentEventId].outcomes[confirmedOutcomeId].results[confirmedResultId])
                }
            });
        }
        if (noOptions) {
            processResult(confirmedOutcomeId, confirmedResultId);
        }
    }

    function getResultId(outcomeId) {
        var results = events[currentEventId].outcomes[outcomeId].results;
        var resultId = 0;
        var found = false;
        results.length > 0 && results.forEach((result, index) => {
            if (!found) {
                if (!result.homelands) {
                    resultId = index;
                }
                else if (result.homelands.indexOf(currentAncestor.homeland.name) > -1) {
                    if (result.tribes && currentAncestor.tribe && result.tribes.indexOf(currentAncestor.tribe) < 0) { 
                        // wrong tribe
                    }
                    else {
                        resultId = index;
                        found = true; // match found so don't default to no Homelands
                    }
                }
            }
        });
        return resultId;
    }

    const addBenefit = (benefit) => {
        setOpen(false);
        processResult(outcomeId, resultId, benefit);
    }

    const processResult = (selectedOutcomeId, resultId, selectedOption) => {
        setShowResult(showResult===globalStyles.fastFade ? globalStyles.fastFade2 : globalStyles.fastFade);

        var updatedBuilder = {...props.builder};
        var characterHistory = updatedBuilder.history;

        var benefits;
        if (events && events.length > 0) {
            benefits = events[currentEventId].outcomes[selectedOutcomeId].results[resultId].benefits;
            if (events[currentEventId].outcomes[selectedOutcomeId].results[resultId].futureEvent) {
                setFutureEvent(events[currentEventId].outcomes[selectedOutcomeId].results[resultId].futureEvent)
            }
        }
        var reputation, wealth, option;

        if (benefits){
            if (selectedOption) {
                benefits.push(selectedOption);
            }
            benefits.forEach((benefit) => {
                if (benefit.options) {}
                else if (benefit.type === "passion"){
                    if (updatedBuilder.history.passions) {
                        var index = -1; 
                        if (benefit.variant) {
                            index = updatedBuilder.history.passions.findIndex(passion => (passion.name === benefit.name) && (passion.variant === benefit.variant));
                        }
                        else {
                            index = updatedBuilder.history.passions.findIndex(passion => passion.name === benefit.name);
                        }
                        if (index > -1) {
                            var gain = 10;
                            if (benefit.gain < 0) { gain = benefit.gain}
                            if (benefit.gain > 0) { gain = benefit.gain}
                            updatedBuilder.history.passions[index].value += gain
                        }
                        else {
                            updatedBuilder.history.passions.push({
                                name: benefit.name,
                                variant: benefit.variant || "",
                                value: benefit.gain ? benefit.gain + 50 : 60,
                                parentId: benefit.parentId
                            });
                        }
                    }
                    else {
                        updatedBuilder.history.passions = [{
                            name: benefit.name,
                            variant: benefit.variant || "",
                            value: benefit.gain ? benefit.gain + 50 : 60,
                            parentId: benefit.parentId
                        }]
                    }
                }
                else if (benefit.type === "skill"){


                    if (updatedBuilder.history.skills) {
                        var index = updatedBuilder.history.skills.findIndex(skill => skill.options[0].name === benefit.name);
                        if (index > -1) {
                            updatedBuilder.history.skills[index].bonus += benefit.gain;
                        }
                        else {
                            updatedBuilder.history.skills.push({
                                options: [
                                    {
                                        name: benefit.name,
                                        parentId: benefit.parentId
                                    }
                                ],
                                bonus: benefit.gain
                            })
                        }
                    }
                    else {
                        updatedBuilder.history.skills = [{
                            options: [
                                {
                                    name: benefit.name,
                                    parentId: benefit.parentId
                                }
                            ],
                            bonus: benefit.gain
                    }]
                    }
                }
                else if (benefit.type === "reputation"){
                    if (benefit.gain === "1d3") {
                        reputation = Math.ceil(Math.random()*(3));
                        updatedBuilder.reputation += reputation;
                        var reputationEvent = events[currentEventId].outcomes[selectedOutcomeId].results[resultId].reputation.replace("<ancestor>", currentAncestor.title);
                        if (characterHistory.reputation) {
                            characterHistory.reputation.push({event: reputationEvent, value: reputation})
                        }
                        else {
                            characterHistory.reputation = [{event: reputationEvent, value: reputation}]
                        }        
                    }
                    if (benefit.gain === "1d6") {
                        reputation = Math.ceil(Math.random()*(6));
                        updatedBuilder.reputation += reputation;
                        if (characterHistory.reputation) {
                            characterHistory.reputation.push({event: events[currentEventId].outcomes[selectedOutcomeId].results[resultId].reputation, value: reputation})
                        }
                        else {
                            characterHistory.reputation = [{event: events[currentEventId].outcomes[selectedOutcomeId].results[resultId].reputation, value: reputation}]
                        }        
                    }                
                }
                else if (benefit.type === "wealth"){
                    wealth = 0;
                    for (var i =0; i < benefit.gain; i++) {
                        wealth += Math.ceil(Math.random()*6);
                    };
                    wealth *= 100;
                    updatedBuilder.wealth += wealth;
                }
                else if (benefit.type === "outlaw"){
                    var years = Math.ceil(Math.random()*(6));
                    updatedBuilder.outlaw = years;
                }
                else if (benefit.type === "runePoints"){
                    updatedBuilder.runePoints = updatedBuilder.runePoints ? updatedBuilder.runePoints + 1 : 1;
                }
                else if (benefit.type === "scar") {
                    if (! updatedBuilder.scars) {
                        updatedBuilder.scars = 1;
                    }
                    else {
                        updatedBuilder.scars ++;
                    }
                }
            });

        }

        if (events[currentEventId].outcomes[selectedOutcomeId].results[resultId].died){
            if (history[index].ancestor === "grandparent" ) {
                updatedBuilder.grandparent.dead = true;
                updatedBuilder.grandparent.yearDied = history[index].year;
            }
            else {
                updatedBuilder.parent.dead = true;
                updatedBuilder.grandparent.yearDied = history[index].year;
            }
        }

        var arrayIndex = -1;
        if (characterHistory.events && characterHistory.events.length > 0) {
            characterHistory.events.forEach((entry, i) => {
                if (entry.year == history[index].year){
                    arrayIndex = i;
                }
            });
        }
        else {
            characterHistory.events = [];
        }
        var description = events[currentEventId].outcomes[selectedOutcomeId].results[resultId].description;
        if (selectedOption) {
            if (selectedOption.type === "passion") {
                var option = selectedOption.name;
                option += selectedOption.variant ? " (" + selectedOption.variant + ")" : "";
                description = description.replace("<option>", option);
                description = description.replace("<optionFocus>", selectedOption.variant);
            }
            if (selectedOption.type === "skill") {
                var option = "+" + selectedOption.gain + " " + selectedOption.name;
                option += selectedOption.variant ? " (" + selectedOption.variant + ")" : "";
                description = description.replace("<option>", option);
                description = description.replace("<optionFocus>", selectedOption.variant);
            }
        }
        var gender = ["pm", "mm", "mr"].indexOf(currentAncestor.relative) > -1 ? "her" : "his";
        description = description.replace("<gender>", gender);
        description = description.replace("<reputation>", reputation);
        description = description.replace("<outlaw>", updatedBuilder.outlaw);
        description = description.replace("<wealth>", wealth);
        description = description.replace("<ancestor>", currentAncestor.title);
        setResult(description);
        if (arrayIndex > -1) {
            characterHistory.events[arrayIndex].outcomes.push(description);
        }
        else {
            var outcomes = [description]
            characterHistory.events.push({year: history[index].year, outcomes: outcomes});
            characterHistory.index = index;
        }

        var nextEvent = -1;
        if (events && events.length > 0) {
            nextEvent = events[currentEventId].outcomes[selectedOutcomeId].results[resultId].subsequentEventId;
        }
        if (nextEvent > -1) {
            setOutcomeId(-1);
            setCurrentEventId(nextEvent);
        }
        else {
            var newIndex = index;
            if (history[index].ancestor === "grandparent" && updatedBuilder.grandparent.dead) {
                newIndex = 6;
            }
            else if (history[index].ancestor === "parent" && updatedBuilder.parent.dead) {
                newIndex = 15;
            }
            else {
                if (newIndex <= 18) {
                    newIndex += 1;
                }
                else {
                    props.disableNext(false);
                    setShowButtons(false);
                }
            }
            characterHistory.index = newIndex;
            setIndex(newIndex);
            props.update(updatedBuilder, false);
        }
        if (eventClass === styles.currentEvent) {
            setEventClass(styles.currentEvent2)
        }
        else {
            setEventClass(styles.currentEvent)
        }
    }

    function advance() {
        setIndex(index +1);
    }

    const displayOutcomes = () => {
        return (
            <div key="eventChoices">
                <FormControl component="fieldset">
                    <RadioGroup aria-label={"Year " + history[index].year + " events" } color="primary" name="events" value={outcomeId} onChange={(event) => handleChange(event)}>
                        {
                            events[currentEventId].outcomes.map((outcome) => {
                                return (
                                    displayOutcome(outcome)
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </div>
        )
    }

    const displayOutcome = (outcome) => {
        var result;
        var found = false;
        outcome.results.forEach(item => {
            if (found) {return;}
            if (! item.homelands) {
                result = item;
            }
            else if (item.homelands.indexOf(currentAncestor.homeland.name) > -1) {
                result = item;
                found = true;
            }
        })
        return (
            <FormControlLabel key={outcome.id} value={outcome.id} from={10} control={<Radio style={{padding: "5px"}} color='primary' />} label={result.display} />
        )
    }

    if (history[index] && history[index].year ) {
        return (
            <div style={{width: "100%"}}>
            <div style={{display:"flex", justifyContent:"center"}}>
                <div style={{width: "100%", minHeight: "600px", backgroundColor: "#454645", color: "#fff", border: "5px solid #cdcdcd", borderRadius: "5px", position:"relative"}} >
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#851113", color: "white", height: "50px", fontSize: "24px"}}>
                            <div> YEAR {history[index].year}</div>
                    </div>
                    <div style={{display:"flex"}}>
                        <div style={{backgroundColor: "#454645", width: "40%", border: "3px solid #676767", borderRadius:"5px", minHeight: "505px", padding: "20px", fontSize:"15px"}}>
                            <div dangerouslySetInnerHTML={{__html: history[index].description}}></div>
                        </div>
                        <div style={{width: "58%", backgroundColor:"#cdcdcd", color: "#454645"}}>
                            <div style={{padding:"20px", height: "90%"}}>
                                {index > 0 ?
                                    events && events.length > 0 ? 
                                        <div style={{height: "100%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                            {/* <div className={`${globalStyles.show} ${showResult}`} style={{position:"absolute", height:"80%", width:"724px", color: "#fff", backgroundColor: "#232423", display:"flex", alignItems:"center", justifyContent:"center", fontSize: "24px", zIndex:999, border:"#2px solid #454645", borderRadius:"10px", padding: "20px"}}> */}
                                            <div className={`${globalStyles.show}`} style={{position:"absolute", height:"80%", width:"724px", color: "#fff", backgroundColor: "#232423", display:"flex", alignItems:"center", justifyContent:"center", fontSize: "24px", zIndex:999, border:"#2px solid #454645", borderRadius:"10px", padding: "20px"}}>
                                                {result}
                                            </div>
                                            <div>
                                                <div style={{margin: "20px 0px", fontSize: "20px"}}>{events[currentEventId].name}</div>
                                                {events.map((ev) => {
                                                    if (currentEventId == ev.id) {
                                                        return displayOutcomes(ev.id);
                                                    }
                                                })}
                                            </div>
                                            {showButtons ? 
                                                <div style={{marginTop:"20px", display:"flex", flexDirection:"row-reverse"}}>
                                                    {events[currentEventId].noRandom ? 
                                                        null
                                                    :
                                                        <Button onClick={handleRandom} className={globalStyles.next} style={{minWidth:"10em"}} variant="contained" color="primary">Choose random</Button>
                                                    }
                                                    <Button onClick={handleSelected} disabled={outcomeId < 0} className={globalStyles.next} style={{sminWidth:"10em", margin:"0px 20px"}} variant="contained" color="primary">Choose selected</Button>
                                                    {history[index].homelands.indexOf(currentAncestor.homeland.name) < 0 ? 
                                                        <Button onClick={advance} className={globalStyles.next} style={{minWidth:"10em"}} variant="contained" color="primary">Skip year</Button>
                                                    : 
                                                        null
                                                    }
                                                </div>
                                            : 
                                                null
                                            }
                                        </div>
                                    : 
                                        null
                                :
                                <div style={{height: "100%", display:"flex", flexDirection:"column", justifyContent:"flex-end"}}>
                                                <div style={{marginTop:"20px", display:"flex", flexDirection:"row-reverse"}}>
                                <Button onClick={advance} className={globalStyles.next} style={{marginLeft:"20px", minWidth:"10em"}} variant="contained" color="primary">Continue</Button>
                                </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DialogBox isOpen={open} width="500px" title="Select Option">
                    <BenefitsDialog outcome={benefitOutcome} options={benefitOptions} year={history[index].year} addBenefit={addBenefit}></BenefitsDialog>
                </DialogBox>
            </div>
            <div className={eventClass} style={{margin:"5px", border: "2px solid #2F588A", backgroundColor: "#cdcdcd", borderRadius: "10px", padding: "10px"}}>
                <div>{"Latest event: " + (result || "")}</div>
            </div>
            <div>
                {currentYear >= 1622 ? 
                <EventHistory history={props.builder.history} title="character" startYear={1622} endYear = {1700} />
                : null}
                {currentYear >= 1608 ? 
                <EventHistory history={props.builder.history} title="parent" startYear={1608} endYear = {1622} />
                : null}
                <EventHistory history={props.builder.history} title="grandparent" startYear={0} endYear = {1608} />
            </div>
        </div>
        );
    }
    else if (props.builder.history.events) {
        return (
            <div style={{width: "100%"}}>
                <EventHistory history={props.builder.history} title="character" startYear={1622} endYear = {1700} />
                <EventHistory history={props.builder.history} title="parent" startYear={1608} endYear = {1622} />
                <EventHistory history={props.builder.history} title="grandparent" startYear={0} endYear = {1608} />
            </div>
        );
    }
    else {
        return null;
    }
}

export default History;