import React, { useState, useEffect } from 'react';
import {withContext} from '../../Context/context';
import DialogBox from '../../Dialogs/dialogBox';
import CharacterPickerDialog from '../../Dialogs/characterPickerDialog';

const CharacterSpell = (props) => {

    const [open, setOpen] = useState(false);
    const [targetCharacter, setTargetCharacter] = useState();
    const [remember, setRemember] = useState(true);
	const [magicPoints, setMagicPoints] = useState(0);
    const [diceRoller, setDiceRoller] = useState({
        diceResults: {},
        setDice: setDice
    });

    useEffect(() => {
        if (props.diceRoller && Object.keys(props.diceRoller.diceResults).length > 0) {
            setDiceRoller(props.diceRoller);
        }
        else {setDiceRoller({
            diceResults: {},
            setDice: setDice
        });}
    },[props.diceRoller.diceResults])

    function setDice() {
        setOpen(true);
    }

    function processResult(dieRoll, spellDetails, title) {
        var updatedDieRoll = {...dieRoll};
        if (targetCharacter) {
            if (targetCharacter.id !== props.providerData.character.id) {
                updatedDieRoll.messageText.target = targetCharacter.name.first;
            }
            else {
                var gender = targetCharacter.personalInfo.gender === "Male" ? "him" : targetCharacter.personalInfo.gender === "Female" ? "her" : "them";
                gender += "self";
                updatedDieRoll.messageText.target = gender;
            }
        }
        var updatedSpellDetails = {...spellDetails};
        if (remember && targetCharacter) {
            updatedSpellDetails.remember = remember;
            updatedSpellDetails.targetCharacter = targetCharacter;
        }
        if (magicPoints) {
            updatedSpellDetails.runeSpellMagicPoints = magicPoints
        }
        setDiceRoller({
            diceResults: {},
            setDice: setDice
        })
        props.processResult(updatedDieRoll, updatedSpellDetails, title)
    }

    function processSpell(remember, character, magicPoints) {
        setOpen(false);
        setTargetCharacter(character);
        setRemember(remember);
        setMagicPoints(magicPoints);
        props.diceRoller.setDice({d100: 1, d10: 1})
    }

    return (
        <div>
            {React.cloneElement(props.children, {...props, diceRoller: diceRoller, processResult: processResult})}
            <DialogBox isOpen={open} width="600px" title="Select character">
                <CharacterPickerDialog castingDetails={props.castingDetails} character={props.providerData.character} spell={props.item} processSpell={processSpell} close={() => setOpen(false)} />
            </DialogBox>
        </div>
    )
}

export default withContext(CharacterSpell)