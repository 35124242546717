import React, {useEffect, useState } from 'react';

const CultCards = (props) => {

    const [selected, setSelected] = useState();
    const [hover, setHover] = useState();

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
        }
    }, [props.selected])

    function handleSelect(cult) {
        setSelected(cult);
        props.handleSelect(cult);
    }

    return (
        <div style={{display: "flex", flexWrap: "wrap", marginLeft: "-5px", cursor: "pointer"}}>
            {props.cults.map((cult, index) => {
                var opacity = 1;
                var border = "3px solid #2F588A";
                var margin = "5px";
                var color = "#121312"
                var backgroundColor = "#f0f0f0";
                if (hover && hover.name === cult.name) {
                    backgroundColor = "#cdcdcd";
                }
                if (selected) {
                    if (selected.name != cult.name) {
                    opacity = 1;
                    }
                    else {
                        border = "5px solid #2F588A";
                        margin = "3px";
                        backgroundColor = "#2F588A";
                        color = "#fff";
                    }
                }
                return(
                    <div key={index} style={{opacity: opacity, textAlign:"center", margin: "6px", width: "128px", border: "2px solid #2F588A", borderRadius: "5px", padding: "20px 0px", color: color, backgroundColor: backgroundColor}}  onClick={() => handleSelect(cult)}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                        {cult.runes.map((rune, runeIndex) => {
                            rune = rune.toLowerCase();
                            rune = rune == "fire/sky" ? "fire" : rune
                            return (
                                <div key={runeIndex}>
                                    <img style={{width:"40px"}} src={"https://storage.googleapis.com/runequest/runes/" + rune + "-rune-40x40.png"}  />
                                </div>
                            )
                        })}
                        </div>
                        <div  style={{display:"flex", justifyContent: "center", alignItems:"center", margin: "5px", flexWrap:"wrap", fontSize: props.size.fontSize, height: "30px"}}>{cult.name}</div>
                    </div>
                )
        })}
        </div>
    )
};

export default CultCards;