import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context';
import { TextField, MenuItem, FormControl, Select, Button, Checkbox } from '@mui/material';
import { calculateDamageBonus } from '../../services/attributeService';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateStrikeRanks } from '../../services/attributeService';
import { calculateHitPoints } from '../../services/hitPointsService';
import { calculateMaxEncumbrance } from '../../services/attributeService';
import { calculateSpiritCombatDamage } from '../../services/attributeService';
import { isEqual } from 'lodash';
import * as SAVE from '../../constants/save';
import DialogButtons from '../Dialogs/dialogButtons';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/selector';

const options = [{name: "armourModifier", display: "Armor modifier", prefix: "", suffix: "pts"}, {name: "attackModifier", display: "Attack modifier", prefix: "", suffix: "%", type: "Weapon"}, {name: "attackMultiplier", display: "Attack multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "statModifier", display: "Characteristic modifier", prefix: "+", suffix: "", type: "Characteristic"}, {name: "damageModifier", display: "Damage modifier", prefix: "+", suffix: "", prefix: "", suffix: "", type: "Weapon"}, {name: "damageMultiplier", display: "Damage multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "defenseModifier", display: "Defense modifier", prefix: "", suffix: "%"}, {name: "moveModifier", display: "Move modifier", prefix: "", suffix: ""}, {name: "moveMultiplier", display: "Move multiplier", prefix: "x", suffix: ""}, {name: "parryModifier", display: "Parry modifier", prefix: "", suffix: "%", type: "Weapon"}, {name: "parryMultiplier", display: "Parry multiplier", prefix: "x", suffix: "", type: "Weapon"}, {name: "passionModifier", display: "Passion modifier", prefix: "", suffix: "%", type: "Passion"}, {name: "passionMultiplier", display: "Passion multiplier", prefix: "x", suffix: "", type: "Passion"}, {name: "runeModifier", display: "Rune modifier", prefix: "", suffix: "%", type: "Rune"}, {name: "runeMultiplier", display: "Rune multiplier", prefix: "x", suffix: "", type: "Rune"}, {name: "skillModifier", display: "Skill modifier", prefix: "", suffix: "%", type: "Skill"}, {name: "skillMultiplier", display: "Skill multiplier", prefix: "x", suffix: "", type: "Skill"}, {name: "spiritArmourModifier", display: "Spirit combat armor modifier", prefix: "", suffix: "pts"}, {name: "strikeRankModifier", display:"Strike rank modifier", prefix: "", suffix: ""}]
const characteristics = [{id: "STR", name: "STR"}, {id: "CON", name: "CON"}, {id: "SIZ", name: "SIZ"}, {id: "DEX", name: "DEX"}, {id: "INT", name: "INT"}, {id: "POW", name: "POW"}, {id: "CHA", name: "CHA"}]
const spiritSpells = [{id:"58a93d90-d032-11ea-95c7-dd3621a1bc0c", name: "Vigor"}, {id: "796c7c70-d052-11ea-a3e0-8702f9fd9b66", name: "Spirit Screen"}, {id: "acb387d0-d01c-11ea-a5a6-031bfb87f1db", name: "Bladesharp"}, {id: "ddd125a0-da7d-11ea-b960-4bca669b4931", name: "Ironhand"}, {id: "ad20efa0-d01c-11ea-a5a6-031bfb87f1db", name: "Shimmer"}, {id: "ae4bb400-d01c-11ea-a5a6-031bfb87f1db", name: "Demoralize"}, {id: "ae800a70-d01c-11ea-a5a6-031bfb87f1db", name: "Speedart"},{id: "af1c9890-d01c-11ea-a5a6-031bfb87f1db", name: "Protection"},{id: "dc8062b0-da7d-11ea-b960-4bca669b4931", name: "Bludgeon"},{id: "dca15830-da7d-11ea-b960-4bca669b4931", name: "Coordination"},{id:"ix73hu9FxWN2wxcyIAfZ", name: "Glamour"},{id: "dd5e8db0-da7d-11ea-b960-4bca669b4931", name: "Dullblade"},{id: "dd6eba50-da7d-11ea-b960-4bca669b4931", name: "Fanaticism"},{id: "dd94dff0-da7d-11ea-b960-4bca669b4931", name: "Firearrow"},{id: "dda6e150-da7d-11ea-b960-4bca669b4931", name: "Fireblade"},{id: "de092590-da7d-11ea-b960-4bca669b4931", name: "Mobility"},{id: "de2698a0-da7d-11ea-b960-4bca669b4931", name: "Parry"},{id: "de58f340-da7d-11ea-b960-4bca669b4931", name: "Slow"},{id: "de8a6380-da7d-11ea-b960-4bca669b4931", name: "Strength"}]
const targets = ["Character", "Characteristic", "Passion", "Rune", "Skill", "Weapon"]

const AddEnchantmentDialog = (props) => {
    const [target, setTarget] = useState("Select");
    const [weapon, setWeapon] = useState("Select weapon");
    const [ability, setAbility] = useState("Select");
    const [selected, setSelected] = useState({name: "Select", display: "Select"});
	const [value, setValue] = useState(0);
    const [effects, setEffects] = useState([]);
    const [enchantment, setEnchantment] = useState({name: ""});
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [strength, setStrength] = useState(0)


    useEffect(() => {
        switch (target) {
            case "Weapon": 
                setItems([...props.character.weapons]);
                break;
            case "Skill": 
                setItems([...props.character.skills]);
                break;
            case "Passion": 
                setItems([...props.character.passions]);
                break;
            case "Rune": 
                setItems([...props.character.runes]);
                break;
            case "Characteristic": 
                setItems([...characteristics]);
                break;
            case "default": 
                break;
        }
    }, [target]);

    function handleOkClick() {
        var updatedCharacteristics = null;
        var updatedModifiers = null;
        var newEnchantment = {...enchantment};
        newEnchantment.valid = true;
        if (selectedItem.name) {
            newEnchantment.target = selectedItem.name;
        }
        else {
            newEnchantment.target = target;
        }
        var newEffects = [];
        effects.forEach(effect => {
            var newEffect
            if (effect.name === "damageModifier") {
                newEffect = {name: "damageModifier", value: {dieType: 0, modifier: effect.value, quantity: 0}}
            }
            else if (effect.name === "damageMultiplier") {
                newEffect = {name: "damageModifier", value: {dieType: selectedItem.damage.dieType, modifier: selectedItem.damage.modifier * (effect.value - 1), quantity: selectedItem.damage.quantity * (effect.value - 1)}}
            }
            else {
                newEffect = {name: effect.name, value: effect.value}
            }
            if (effect.name === "statModifier") {
                updatedCharacteristics = {...props.character.characteristics};
                updatedCharacteristics[selectedItem.name].enchantmentBonus += effect.value;
                updatedCharacteristics[selectedItem.name].enchantments.push({name: newEnchantment.name, value: effect.value})
                updatedModifiers = getCategoryModifiers(updatedCharacteristics);
            }
            if (target !== "Character") {
                newEffect.item = {id: selectedItem.id};
            }
            newEffects.push(newEffect);
        });
        newEnchantment.effects = newEffects;
        props.update(newEnchantment, updatedCharacteristics, updatedModifiers)
        props.close();
    }

    function handleCancelClick() {
        props.close();
    }

    function handleCheck(event, index) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments[index].valid = event.target.checked;
        setEnchantments(updatedEnchantments)
        
    }

    const handleAddClick = () => {
        var updatedEffects = [...effects]
        updatedEffects.push({
            prefix: selected.prefix,
            name: selected.name,
            display: selected.display,
            value: value,
            suffix: selected.suffix
        });
        setEffects(updatedEffects)
        setSelected({name: "Select", display: "Select"})
        setValue(0);
    }

	function handleTypeChange(event) {
        var index = options.findIndex(obj => obj.display === event.target.value);
        setSelected({...options[index]});
	}

	function handleTargetChange(event) {
        setTarget(event.target.value)
        setSelectedItem({})
	}

	function handleItemChange(event, index) {
        if (target === "Characteristic"){
            var index = items.findIndex(obj => obj.name === event.target.value);
        }
        else {
            var index = items.findIndex(obj => obj.id === event.target.value);
        }
        setSelectedItem({...items[index]});
	}

	function handleValueChange(field, value) {
        var updatedEnchantment = {...enchantment}
        updatedEnchantment[field] = value;
        setEnchantment(updatedEnchantment);
	}

    function displayEffects() {
        return (
            <div style={{marginTop: "20px"}}>
                {effects && effects.map((effect, index) => {
                return (
                    <div key={index} style={{display:"flex", alignItems: "center", margin: "5px"}}>
                        <div style={{marginRight: "20px", width: "200px"}}>
                            {effect.display}
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>{effect.prefix}</div>
                            <div style={{margin: "0px 5px"}}>
                                {effect.value}
                            </div>
                            <div>{effect.suffix}</div>
                        </div>
                    </div>
                )
            })}            
            </div>
        )
    }

    return (
        <div style={{padding: "10px"}}>
            <div style={{display: "flex", fontSize: "16px", alignItems: "center", marginTop: "10px"}}>
                <div>Enchantment Name: </div>
                <div style={{width: "400px", marginLeft: "10px"}}>
                    <EditBox width="380px" onChange={(value) => handleValueChange("name", value)} value={enchantment.name} />
                </div>
            </div>
            <div style={{display: "flex", fontSize: "16px", alignItems: "center", marginTop: "10px"}}>
                <div style={{display:"flex", marginRight: "20px", alignItems: "center"}}>
                    <div style={{marginRight: "18px"}}>Enchantment type: </div>
                    <FormControl variant="outlined" >
                        <Select value={target} style={{backgroundColor:"#fff", minWidth:"10em"}} autoWidth={true} onChange={handleTargetChange} color="primary">
                            <MenuItem key={0} value="Select">
                                <em>Select target</em>
                            </MenuItem>
                            { 
                                targets.map((target, index) => {
                                    return( <MenuItem key={index} value={target}>{target}</MenuItem> );
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                {target !==  "Character" ? 
                <div style={{display:"flex", alignItems: "center"}}>
                    <div style={{marginRight: "20px"}}>{target}: </div>
                    <FormControl variant="outlined" >
                        <Select value={selectedItem.title || (selectedItem.variant ? selectedItem.name + " " + selectedItem.variant : selectedItem.name)} style={{backgroundColor:"#fff", minWidth:"10em"}} autoWidth={true} onChange={(event, index) => handleItemChange(event, index)} color="primary">
                            <MenuItem key={0} value="Select">
                                <em>{"Select " + target.toLowerCase()} </em>
                            </MenuItem>
                            { 
                                items.map((item, index) => {
                                    return( <MenuItem key={index} value={item.id}>{item.title || (item.variant ? item.name + " " + item.variant : item.name)}</MenuItem> );
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            : null}
            </div>
            <hr style={{marginTop: "30px"}} />

            {displayEffects()}

            <div style={{display:"flex", marginTop: "10px", alignItems: "center"}}>
                <FormControl variant="outlined" >
                    <Select value={selected.display} style={{backgroundColor:"#fff", width: "250px"}} autoWidth={true} onChange={handleTypeChange} color="primary">
                        <MenuItem key={0} value="Select">
                            <em>Select effect</em>
                        </MenuItem>
                        { 
                            options.filter(function (item) {return ((target === "Character" && ["Damage multiplier", "Damage modifier"].indexOf(item.display) < 0 ) || item.type === target)}).map((option, index) => {
                                return( <MenuItem key={index} value={option.display}>{option.display}</MenuItem> );
                            })
                        }
                    </Select>
                </FormControl>
                <div style={{display:"flex", alignItems: "center", margin: "0px 10px", width: "160px"}}>
                    <div style={{marginLeft: "45px"}}>{selected.prefix}</div>
                    <div style={{marginLeft: "5px", width: "80px"}}>
                        <EditBox number onChange={setValue} value={value} />
                    </div>
                    <div style={{marginLeft: "5px"}}>{selected.suffix}</div>
                </div>
                <Button style={{borderRadius: "3px", width: "120px", height: "40px", marginLeft: "20px"}} color="primary" variant="contained" onClick={handleAddClick}>Add effect</Button>
            </div>
            <hr style={{marginTop: "30px"}} />
            <div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end"}}>
                <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                <Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
            </div>
        </div>
    )
}

export default withContext(AddEnchantmentDialog)