import React from 'react';
import styles from './panels.module.css';

const AugmentationPanel = (props) => {

    var image = props.augmentation.modifier > 0 ? "https://storage.googleapis.com/runequest/images/green-runic-circle.png" : "https://storage.googleapis.com/runequest/images/red-runic-circle.png";
    var augmentation = props.augmentation.modifier > 0 ? "+" + props.augmentation.modifier : props.augmentation.modifier;
    augmentation += "%";
    var label = props.augmentation.modifier > 0 ? "bonus" : "penalty";
    var color = props.augmentation.modifier > 0 ? "#2F588A" : "#851113";

    return (
        <div style={{height: "254px", position: "relative", fontSize: "20px"}}>
            <img className={styles.runicCircle} style={{width:"240px"}} src={image} alt="Augmentation notifier" />
            <div style={{position:"absolute", left: 62, top: 69, width: 120, textAlign:"center", color:color}}>
                <div style={{display:"flex", justifyContent:"center", fontSize: "16px"}}>{props.augmentation.label}</div>
            </div>
            
        </div>
    )
}

export default AugmentationPanel