import { v4 as uuidv4 } from 'uuid';
import { getSpiritSpell } from '../../services/dataService';

function generateSpiritSpells(cult, experience, maxPoints, campaign) {
    var pointsRemaining = experience.spiritSpellBonus;
    pointsRemaining += Math.ceil(Math.random() * 3);
    if (pointsRemaining > maxPoints) {
        pointsRemaining = maxPoints;
    }

    var generatedSpells = [];
    var weaponBoostSpellId = ""
    var shieldBoostSpellId = ""
    var offensiveSpellId = ""
    
    if (cult.weaponBoostSpells){
        if (Math.ceil(Math.random()*(100)) <= cult.weaponBoostSpells.possession) {
            var rnd = Math.ceil(Math.random()*(100));
            cult.weaponBoostSpells.spiritSpells.forEach(spell => {
                if (spell.possession >= rnd) {
                    rnd = 999;
                    var weaponBoost = generateNewSpell(spell, experience, campaign)                    
                    if (weaponBoost) {
                        generatedSpells.push(weaponBoost);
                        weaponBoostSpellId = weaponBoost.parentId;
                        pointsRemaining -= weaponBoost.points;
                    }
                }
            })
        }
    }
    
    if (cult.shieldBoostSpells){
        if (Math.ceil(Math.random()*(100)) <= cult.shieldBoostSpells.possession) {
            var rnd = Math.ceil(Math.random()*(100));
            cult.shieldBoostSpells.spiritSpells.forEach(spell => {
                if (spell.possession >= rnd) {
                    rnd = 999;
                    var shieldBoost = generateNewSpell(spell, experience, campaign)                    
                    if (shieldBoost) {
                        generatedSpells.push(shieldBoost);
                        shieldBoostSpellId = shieldBoost.parentId;
                        pointsRemaining -= shieldBoost.points;
                    }
                }
            })
        }
    }
    
    if (cult.offensiveSpells){
        var offensiveSpell = getOffensiveSpell(cult, experience);
        if (offensiveSpell) {
            generatedSpells.push(offensiveSpell);
            offensiveSpellId = offensiveSpell.parentId;
            pointsRemaining -= offensiveSpell.points;
        }
    }

    var safety = 0;
    while (pointsRemaining > 0) {
        safety++;
        if (safety > 99) {
            break;
        }
        var rnd = Math.ceil(Math.random()*(100));
        cult.spiritSpells.forEach(spell => {
            if (spell.possession >= rnd) {
                rnd = 999;
                if (spell.weaponBoost) {
                    var result = incrementVariable(generatedSpells, weaponBoostSpellId, experience.maxVariablePoints);
                    if (result.success) {
                        generatedSpells = result.generatedSpells
                        pointsRemaining --;
                    }
                }
                else if (spell.shieldBoost) {
                    var result = incrementVariable(generatedSpells, shieldBoostSpellId, experience.maxVariablePoints);
                    if (result.success) {
                        generatedSpells = result.generatedSpells
                        pointsRemaining --;
                    }
                }
                else if (spell.offensiveSpell && ! offensiveSpellId) {
                    var offensiveSpell = getOffensiveSpell(cult, experience);
                    if (offensiveSpell && offensiveSpell.points <= pointsRemaining) {
                        generatedSpells.push(offensiveSpell);
                        offensiveSpellId = offensiveSpell.parentId;
                        pointsRemaining -= offensiveSpell.points;
                    }
                }
                else {
                    var index = generatedSpells.findIndex(obj => obj.parentId === spell.parentId)
                    if (index > -1) {
                        var existingSpell = generatedSpells[index];
                        if (existingSpell.variable) {
                            var result = incrementVariable(generatedSpells, spell.parentId, experience.maxVariablePoints);
                            if (result.success) {
                                generatedSpells = result.generatedSpells
                                pointsRemaining --;
                            }
                        }
                    }
                    else {
                        var newSpell = generateNewSpell(spell, experience, campaign)                    
                        if (newSpell && newSpell.points <= pointsRemaining) {
                            generatedSpells.push(newSpell);
                            pointsRemaining -= newSpell.points;
                        }
                    }
                }
            }
        })
    }
    // if (cult.spiritSpells && cult.spiritSpells.length > 0) {
    //     console.log('spells', cult.spiritSpells)
    //     var remainingPoints = cult.spiritMagicBase + experience.spiritSpellBonus;
    //     remainingPoints += Math.ceil(Math.random() * 4);
    //     console.log('remaining points', remainingPoints);
    //     if (remainingPoints > maxPoints) remainingPoints = maxPoints;
    //     while (remainingPoints > 0) {
    //         var generatedNumber = Math.ceil(Math.random()*(100));
    //         console.log('generated', generatedNumber)
    //         cult.spiritSpells.forEach((spell) => {
    //             console.log('spell', spell.name)
    //             if (generatedNumber <= spell.possession) {
    //                 var spellExists = false;
    //                 generatedSpells.forEach((existingSpell) => {
    //                     if (existingSpell.parentId === spell.parentId)
    //                     {

    //                         spellExists = true;
    //                         if (spell.variable) {
    //                             existingSpell.points ++;
    //                             remainingPoints --;
    //                             generatedNumber = 999;
    //                             console.log('remaining now: ', remainingPoints)
    //                         }
    //                     }
    //                 });
    //                 if(! spellExists) {
    //                     var newSpell = generateNewSpell(spell, experience)
    //                     if (newSpell) {
    //                         remainingPoints -= newSpell.points;
    //                         generatedSpells.push(newSpell);
    //                         generatedNumber = 999;
    //                     }
    //                 }
    //             }
    //         });

    //     }
    // }
    return generatedSpells;
}

const getOffensiveSpell = (cult, experience, campaign) => {
    var offensiveSpell;
    var rnd = Math.ceil(Math.random()*(100));
    cult.offensiveSpells.spiritSpells.forEach(spell => {
        if (spell.possession >= rnd) {
            rnd = 999;
            offensiveSpell = generateNewSpell(spell, experience, campaign)                    
        }
    })
    return offensiveSpell;
}

const incrementVariable = (generatedSpells, parentId, max) => {
    var success = false;
    generatedSpells.forEach(spell => {
        if (spell.parentId === parentId && spell.points < max) {
            spell.points++;
            success = true;
        }
    });
    return {generatedSpells: generatedSpells, success: success};
} 

const generateNewSpell = (spell, experience, campaign) => {
    var newSpell;
    let spellDetails = getSpiritSpell(spell.parentId, campaign);
    if (spellDetails) {
        var points = spellDetails.variable ? experience.startingVariablePoints : spellDetails.points;
        newSpell = {
            id: uuidv4(),
            parentId: spell.parentId, 
            points: points,
            name: spell.name,
            variable: spellDetails.variable,
            target: {...spellDetails.target}
        };
    }
    return newSpell;
}


export default generateSpiritSpells;