import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';

const ExperienceDiceButton = (props) => {

    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (clicked || props.diceRoller.processingOpposed) {
            setClicked(false);
            var result = -1;
            if (props.diceRoller.diceResults.d100 === 0 && props.diceRoller.diceResults.d10 === 10) {
                result = 100;
            }
            else if (props.diceRoller.diceResults.d10 === 10) {
                result = props.diceRoller.diceResults.d100;
            } 
            else {
                result = props.diceRoller.diceResults.d100 + props.diceRoller.diceResults.d10
            } 
            props.processResult(result, props.item, props.diceRoller.diceResults.d6 || 3);
        }
    },[props.diceRoller.diceResults])

      function rollDice(event){
        // var result = Math.ceil(Math.random()*(100));
        // console.log('result is ', result)
        // props.processResult(result, props.item);
        event.stopPropagation();
        if (props.diceRoller) {
            setClicked(true);
            var dice = {d100: 1, d10: 1}
            if (props.useD6) {
                dice.d6 = 1;
            }
            props.diceRoller.setDice(dice)
        }
    }

    const classNameGenerator = (...classes)=>{
        return classes.join(" ")
    }
        return (
            <div onClick={props.disabled ? () => {} : rollDice} style={{height: "24px", display: "flex", alignItems: "center", justifyContent: "center", cursor: props.disabled? "default": "pointer", color: props.disabled ? "#454645": "#fff", backgroundColor: props.disabled ? "#cdcdcd": "#2f588a"}} className={classNameGenerator(globalStyles.diceButton, globalStyles.buttonTile)}>{props.title || "Roll"}</div>
        )
}

export default ExperienceDiceButton