import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button } from '@mui/material';

const OpposedPOWDialog = (props) => {

	const [target, setTarget] = useState("");
	const searchInput = useRef(null);

	useEffect(() => {
		if (props.targetEnemy) {
			setTarget(props.targetEnemy.characteristics.POW.baseValue + (props.targetEnemy.characteristics.POW.statBonus || 0));
		}
	},[props.targetEnemy])

    function modifyValue(event){
        var previous = target || "";
        var newValue = parseInt(event.target.value)
        if (! newValue) newValue = previous;
        setTarget(newValue);
    }

	function handleOkClick(){
		props.processPOWRoll(target);
		props.close();
	}

	return (
			<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px"}}>
				<div style={{display:"flex", flexDirection:"row-reverse", fontSize: "40px", color: "#121312"}}>
					{/* <div style={{padding: "5px", display:"flex", alignItems:"center", justifyContent:"center", borderRadius: "5px", cursor:"pointer", width: "48px", border: "1px solid #757575"}}>X</div> */}
					<div style={{fontSize: "18px", padding: "10px"}}>You have successfully cast {props.spell.name} but must still overcome the targets POW</div>
				</div>
				{props.targetEnemy ? 
				null
				: 
					<div style={{display:"flex", alignItems: "center", padding: "10px"}}>
						<div style={{marginRight: "20px"}}>Enter the targets POW here and click OK</div>
						<TextField autoFocus ref={searchInput} inputProps={{style: {fontSize: "16px", width:"60px", height: "30px", padding: "3px", paddingRight:"10px", borderRadius: "5px",  textAlign:"right", backgroundColor:"#fff"}}} placeholder="POW" color="primary" value={target} onChange={modifyValue} variant="outlined" size="small"></TextField>
					</div>
				}
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px"}}>
					<Button type="submit" onClick={handleOkClick} disabled={!target}  style={{marginTop:"30px", width: "100px", backgroundColor:"#2F588A", color:"#fff"}} variant="contained">Roll</Button>
				</div>
		</div>
	);
}

export default OpposedPOWDialog;