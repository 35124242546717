import React, { useState } from 'react';
import globalStyles from '../Styles/styles.module.css'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import ProfileButton from '../Profile';
import { withFirebase } from '../Firebase';
import DialogBox from '../Dialogs/dialogBox';
import SendFeedbackDialog from '../Dialogs/sendFeedbackDialog';
import { LineWeight } from '@mui/icons-material';

const Navigation = (props) => {
  	const [admin, setAdmin] = useState(false);
	const [open, setOpen] = useState(false);

	function displaySubMenu() {
    setAdmin(true);
	}
	function hideSubMenu() {
    setAdmin(false);
	}

	window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
      hideSubMenu();
    }
	}

	const NavigationAuth = ({ authUser }) => (
    <div className={globalStyles.navBanner} style={{position: "fixed", top: 0, display:"flex", justifyContent: "space-between", alignItems: "center"}}>
		<ul className={globalStyles.menu} style={{width: "800px"}}>
			<li>
				<Link to={ROUTES.HOME}>Home</Link>
			</li>
			<li>
				<Link to={ROUTES.CHARACTERS}>Characters</Link>
			</li>
			<li>
				<Link to={ROUTES.CAMPAIGNS}>Campaigns</Link>
			</li>
			{/* <li>
				<Link to={ROUTES.CUSTOM}>Custom Content</Link>
			</li> */}
			<li onClick={() => setOpen(true)} style={{cursor: "pointer"}}>
				Feedback
			</li>
			{/* <li>
			<Link to={ROUTES.CAMPAIGNS}>Campaigns</Link>
			</li> */}
			{authUser.roles[ROLES.ADMIN] && (
				<li className="dropbtn" name="admin" style={{color:"#fff", marginRight:"30px", cursor:"pointer"}} onClick={displaySubMenu}>Admin
					{admin ? 
						<div style={{backgroundColor:"#232323", color:"#fff", padding:"10px", width:"100px", display:"flex", flexDirection:"column", position:"relative", zIndex:100}}>
							<Link to={ROUTES.ADMIN}>Admin</Link>
							<Link to={ROUTES.TEMPLATES}>Templates</Link>
							<Link to={ROUTES.CULTS}>Cults</Link>
							<Link to={ROUTES.RUNEMAGIC}>Rune magic</Link>
							<Link to={ROUTES.ENCOUNTERS}>Encounters</Link>
						</div>

					: null
					}
				</li>
			)}
		</ul>
		<div style={{display:"flex", alignItems: "center"}}>
			<img src="https://storage.googleapis.com/runequest/runes/truth-rune-white-40x40.png" />
			<div style={{fontSize: "32px", margin: "0px 20px", color: "#fff"}}>RuneQuesting</div>
			<img src="https://storage.googleapis.com/runequest/runes/illusion-rune-white-40x40.png" />
		</div>
		<ul className={globalStyles.menu}>
			<li>
			<ProfileButton authUser={authUser} />
			</li>
		</ul>
		<DialogBox isOpen={open} style={{height: "540px", lineHeight: "21px"}} width="700px" title={"Provide Feedback"} >
			<SendFeedbackDialog authUser={props.authUser} close={() => setOpen(false)} />
		</DialogBox>
    </div>
	);

	return (
		props.authUser ? (
			<NavigationAuth authUser={props.authUser}>
			</NavigationAuth>
		) : (
			<>
			<div />
			{/* <div style={{padding: "20px 10px", borderTop: "1px solid #fff", width: "100%", cursor: "pointer"}} onClick={props.firebase.doSignOut}>Sign Out</div> */}
			</>
		)
	)
};
 
export default withFirebase(Navigation);