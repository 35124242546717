import React from 'react';
import {withContext} from '../Context/context';
import { calculateMaxEncumbrance } from '../../services/attributeService';
import { calculateHitPoints } from '../../services/hitPointsService';
import { isEqual } from 'lodash';

const ConstitutionTile = (props) => {

    function update(characteristic, save) {

        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.CON = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var updatedHitPoints = calculateHitPoints(updatedCharacteristics, props.entity.hitPoints);
        updates.push({fieldName: "hitPoints", value: updatedHitPoints})

        var updatedEncumbrance = calculateMaxEncumbrance(updatedCharacteristics);
        if (! isEqual(props.entity.maxEnc, updatedEncumbrance)) {
            updates.push({fieldName: "maxEnc", value: updatedEncumbrance});
        }
        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(ConstitutionTile)