import React, { useState, useEffect } from 'react';
import { Button, Slider } from '@mui/material';
import { styled } from "@mui/system";
import PowerRuneCard from './powerRuneCard';

  let beforeChange = null;

const RuneBonuses = (props) => {
    const [elementalRunes, setElementalRunes] = useState([]);
    const [powerRunes, setPowerRunes] = useState([]);
    const [hover, setHover] = useState();
    const [remaining, setRemaining] = useState();
    const [elementalPoints, setElementalPoints] = useState(0);
    const [powerPoints, setPowerPoints] = useState(0);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        var elementalPoints = 0;
        props.builder.runes.elementalRunes.forEach(rune => {
            elementalPoints += (rune.value - rune.startingValue);
        })
        setElementalPoints(elementalPoints)
        setElementalRunes([...props.builder.runes.elementalRunes]);

        var powerPoints = 0;
        var updatedPowerRunes = ([...props.builder.runes.powerRunes]);
        updatedPowerRunes.forEach(rune => {
            if (rune.first.value > rune.first.startingValue) {
                powerPoints += (rune.first.value - rune.first.startingValue);
            }
            if (rune.second.value > rune.second.startingValue) {
                powerPoints += (rune.second.value - rune.second.startingValue);
            }
        })
        setPowerPoints(powerPoints)
        setPowerRunes(updatedPowerRunes);

        var remaining = 50 - (elementalPoints + powerPoints);
        setRemaining(remaining);

    }, [props.powerRunes])

    function valuetext(value) {
        return `${value}%`;
    }

    function handleElementalChange(newValue, index){
        var updatedRune = {...elementalRunes[index]};
        if (newValue < updatedRune.startingValue) {
            newValue = updatedRune.startingValue;
        }

        if (!beforeChange) {
            beforeChange = updatedRune.value;
        }

        var totalSpent = 0;
        elementalRunes.forEach((rune, runeIndex) => {
            if (runeIndex === index){
                totalSpent += (newValue - rune.startingValue);
            }
            else {
                totalSpent += (rune.value - rune.startingValue);
            }
        });
        var pointsLeft = 50 - (powerPoints + totalSpent);
        if (pointsLeft < 0) {
            newValue += pointsLeft;
            totalSpent += pointsLeft;
            pointsLeft = 0;
        }

        setRemaining(pointsLeft);
        updatedRune.value = newValue;
        var updatedRunes = [...elementalRunes];
        updatedRunes[index] = updatedRune;
        setElementalRunes(updatedRunes);
        setElementalPoints(totalSpent);
    }

    function handlePowerChange(newValue, index){

        var updatedValues = [];
        powerRunes.forEach(rune => {
            updatedValues.push({...rune});
        });

        if (!beforeChange) {
            beforeChange = updatedValues[index].value;
        }

        if (newValue < 0 || newValue > 100) {
            return;
        }
        var totalSpent = 0;
        updatedValues.forEach((runePair, runeIndex) => {
            if (runeIndex === index){}
            else if (runePair.first.value >  runePair.first.startingValue) {
                totalSpent += (runePair.first.value - runePair.first.startingValue);
            }
            else if (runePair.second.value >  runePair.second.startingValue) {
                totalSpent += (runePair.second.value - runePair.second.startingValue);
            }
        });
        var pointsLeft = 50 - (elementalPoints + (totalSpent));
        if ((newValue - updatedValues[index].second.startingValue) > pointsLeft) {
            newValue = updatedValues[index].second.startingValue + pointsLeft;
            pointsLeft = 0;
        }
        else if ((updatedValues[index].second.startingValue - newValue) > pointsLeft) {
            newValue = updatedValues[index].second.startingValue - pointsLeft;
            pointsLeft = 0;
        }
        else if (newValue > updatedValues[index].second.startingValue) {
            pointsLeft -= (newValue - updatedValues[index].second.startingValue);
        }
        else if (newValue < updatedValues[index].second.startingValue) {
            pointsLeft -= (updatedValues[index].second.startingValue - newValue);
        }

        updatedValues[index].second.value = newValue;
        var diff = updatedValues[index].second.startingValue - newValue;
        if (diff < 0) {diff *= -1;}
        totalSpent += diff;


        updatedValues[index].first.value = (100 - newValue);
        setRemaining(pointsLeft);
        setPowerRunes(updatedValues);
        setPowerPoints(totalSpent);
    }

    function changeCommitted() {
        beforeChange = null;
    }
    
    function advance() {
        setDisabled(true);
        var updatedBuilder = {...props.builder};
        updatedBuilder.runes.elementalRunes = [...elementalRunes];
        updatedBuilder.runes.powerRunes = [...powerRunes];
        updatedBuilder.runes.pointsRemaining = remaining;
        props.update(updatedBuilder);
        props.advance();
    }

    return (
        <>
        <div style={{display: "flex", width: "100%"}}>

            </div>
            <div style={{display:"flex", width: "100%"}}>
            <div style={{width: "35%", marginTop: "10px", marginRight: "5px"}}>
                <div id="elementalRunes" style={{border: "2px solid #2F588A", padding: "10px", borderRadius: "10px"}}>
                        {elementalRunes.map((rune, index) => {
                            return (
                                <div key={index} style={{display: "flex"}}>
                                    <div onMouseEnter={() => setHover(rune)} onMouseLeave={() => {setHover(null)}} style={{border: "2px solid #cdcdcd", backgroundColor: "#fff", borderRadius:"15px", marginRight: "15px", margin: "10px", height: "70px", width: "70px", minWidth: "70px"}}>
                                        <img style={{width:"100%"}} src={"https://storage.googleapis.com/runequest/runes/" + rune.image}  />
                                    </div>
                                    <div style={{width: "100%"}}>
                                        <div style={{display:"flex"}}>
                                            <div style={{fontSize: "18px", margin: "0px 20px 10px 20px"}}>{rune.name}</div>
                                            <div style={{fontSize: "18px"}}>{elementalRunes[index].value}%</div>
                                        </div>
                                        <PrettoSlider
                                            min={0}
                                            max={100}
                                            value={elementalRunes[index].value}
                                            onChange={(event, value) => handleElementalChange(value, index)}
                                            onChangeCommitted={changeCommitted}
                                            defaultValue={rune.startingValue}
                                            getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider-always"
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{marginTop: "10px", display:"flex", justifyContent:"flex-end", width: "65%"}}>
                    <div id="powerRunes" style={{border: "2px solid #2F588A", borderRadius: "10px", width: "100%"}}>
                        {powerRunes.map((rune, index) => {
                            return (
                                <div key={index} style={{display: "flex", marginTop: index === 4 ? "60px" : "0px"}}>
                                    <div style={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems:"center", width:"100px", marginTop: "20px", width: "16%"}}>
                                        <div style={{fontSize: "18px"}}>{rune.first.name}</div>
                                        <div style={{fontSize: "18px"}}>{rune.first.value}%</div>
                                    </div>
                                    <div onMouseEnter={() => setHover(rune.first)} onMouseLeave={() => {setHover(null)}} style={{border: "2px solid #cdcdcd", width: "14%", maxWidth: "70px", maxHeight: "70px", backgroundColor: "#fff", borderRadius:"15px", margin: "10px", opacity:(rune.first.value / 100) + 0.15}}>
                                        <img style={{width:"100%"}} src={"https://storage.googleapis.com/runequest/runes/" + rune.first.image}  />
                                    </div>
                                    <div style={{width: "40%", display:"flex", alignItems:"center"}}>
                                        <PrettoSlider
                                            min={0}
                                            max={100}
                                            track={false}
                                            value={rune.second.value}
                                            onChange={(event, value) => handlePowerChange(value, index)}
                                            onChangeCommitted={changeCommitted}
                                            getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider-always"
                                        />
                                    </div>
                                    <div onMouseEnter={() => setHover(rune.second)} onMouseLeave={() => {setHover(null)}} style={{border: "2px solid #cdcdcd", width: "14%", maxWidth: "70px", maxHeight: "70px", backgroundColor: "#fff", borderRadius:"15px", margin: "10px", opacity:(rune.second.value / 100) + 0.15}}>
                                        <img style={{width:"100%"}} src={"https://storage.googleapis.com/runequest/runes/" + rune.second.image}  />
                                    </div>
                                    <div style={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems:"center", marginTop: "20px", width: "16%"}}>
                                        <div style={{fontSize: "18px"}}>{rune.second.name}</div>
                                        <div style={{fontSize: "18px"}}>{rune.second.value}%</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {/* {hover ? 
                <div style={{position: "fixed", top: "60px", left: "40%"}}>
                <PowerRuneCard item={hover} ></PowerRuneCard>
                </div>
            : 
                null
            } */}
                <div style={{display: "flex", flexDirection: "row-reverse", marginTop: "10px"}}>
                    {remaining > 0 ? 
                    <div style={{backgroundColor: "#fff", fontSize: "15px", color: "#851113", display:"flex", flexDirection:"column", justifyContent: "center", marginLeft: "10px", width: "180px", height: "60px", alignItems: "center", textAlign: "center", border: "5px solid #2F588A", borderRadius: "10px", fontWeight:"bold"}}>
                        <div style={{margin: "0px 20px"}}>POINTS REMAINING {remaining}</div>
                        
                    </div>
                    : 
                        <div>
                            <Button onClick={advance} size="large" variant="contained" style={{marginLeft:"10px", fontSize: "15px", width: "180px", height: "60px", borderRadius: "10px"}} disabled={disabled} color="primary">Confirm Rune Selection</Button>
                        </div>
                    }
                </div>
        </>
    );
}

const PrettoSlider = styled(Slider,{
    name: "PrettoSlider",
    slot: "Wrapper"
    })({
      root: {
      color: '#2f588a',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  });

export default RuneBonuses;