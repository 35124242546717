import React, { useState, useEffect } from 'react';
import globalStyles from '../../Styles/styles.module.css'
import { withPageState } from '../../Context/PageState';
import compareValues from '../../../utils/compareValues';
import WeaponTile from '../../Combat/weaponTile';
import { withContext } from '../../Context/context';
import AddWeaponDialog from "../dialogs/addWeaponDialog";
import * as SAVE from '../../../constants/save';
import { v4 as uuidv4 } from 'uuid';
import PanelHeader from '../../Controls/panelHeader';

const Weapons = (props) => {
    const [displayWeapons, setDisplayWeapons] = useState([]);

    useEffect(() => {
        defaultSort();
    },[props.entity.weapons, props.entity.configuration.weapons.favourites]);

    function update(item, persist) {
        var updatedWeapons = [...props.entity.weapons];
        var index = updatedWeapons.findIndex(weapon => weapon.id === item.id);
        if (index > -1) {
            updatedWeapons[index] = item;
            props.update([{fieldName: "weapons", value: updatedWeapons}], persist);
        }
    }

    function updateSkill(skill, persist) {
        var updatedSkills = [...props.entity.weaponSkills];
        var index = updatedSkills.findIndex(item => item.id === skill.id);
        if (index > -1) {
            updatedSkills[index] = skill;
            props.update([{fieldName: "weaponSkills", value: updatedSkills}], persist);
        }
    }

    function defaultSort() {
        var updatedWeapons = [];
        props.entity.weapons.forEach(weapon => {
            var updatedWeapon = {...weapon};
            var index = props.entity.weaponSkills.findIndex(skill => (weapon.weaponSkillId && weapon.weaponSkillId === skill.parentId) || skill.parentId === weapon.parentId);
            updatedWeapon.value = props.entity.weaponSkills[index].value + (props.entity.categoryModifiers.Manipulation || 0);
            updatedWeapon.sortKey = updatedWeapon.class + (999-updatedWeapon.value);
            updatedWeapons.push(updatedWeapon);
        });
        if (props.pageState.editMode) {
            setDisplayWeapons(updatedWeapons);
        }
        else {
            var sorted = updatedWeapons.sort(compareValues('sortKey', "asc"));
            setDisplayWeapons(sorted);
        }
    }

    function addWeapon(weapon) {
        weapon.id = uuidv4();
        var updates = [];
        var updatedWeapons = [...displayWeapons];
        var value = weapon.baseValue;
        var index = props.providerData.character.weaponSkills.findIndex(obj => obj.name === weapon.name);
        if (index > -1) {
            value = props.providerData.character.weaponSkills[index].value;
        }
        updatedWeapons.push({...weapon, value: value});
        setDisplayWeapons(updatedWeapons);

        updates.push({fieldName: "weapons", value: updatedWeapons});

        if (index < 0) {
            var updatedSkills = [...props.providerData.character.weaponSkills];
            updatedSkills.push({
                id: uuidv4(), 
                name: weapon.name,
                parentId: weapon.parentId, 
                category: weapon.category, 
                hasSucceeded: false,
                value: weapon.baseValue, 
                skillCategory: "Manipulation"
            });
            updates.push({fieldName: "weaponSkills", value: updatedSkills});
        }
        props.update(updates, SAVE.NO)
    }

    return(
        <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
            <PanelHeader title="Weapons" addTitle="Add weapon" addItem={addWeapon} startColour="rgba(133,17,19,1)" endColour="rgba(167,29,33,1)" width="900px" >
                <AddWeaponDialog />
            </PanelHeader>

        <div style={{}}>
            {props.type === "familiar" ? 
                <>
                    <div style={{display:"flex", fontWeight: "bold", padding: "5px"}}>
                        <div style={{width: "120px"}}>Weapon</div>
                        <div style={{width: "30px", textAlign: "center"}}>SR</div>
                        <div style={{width: "80px", textAlign: "center"}}>Attack</div>
                        <div style={{width: "80px", textAlign: "center"}}>Damage</div>
                    </div>
                </>
            : 
            props.providerData.type === "character" ? 
                props.pageState.editMode ? 
                    <>
                        <div style={{display:"flex", fontWeight: "bold", padding: "5px"}}>
                            <div style={{width: "160px"}}>Weapon</div>
                            <div style={{width: "60px", textAlign: "center"}}></div>
                            <div style={{width: "60px", textAlign: "center"}}>Skill</div>
                            <div style={{width: "60px", textAlign: "center", marginLeft: "10px"}}></div>
                            <div style={{width: "90px", textAlign: "center"}}>Equipped</div>
                        </div>
                    </>
                : 
                    <>
                        <div style={{display:"flex", fontWeight: "bold", padding: "5px"}}>
                            <div style={{width: "160px"}}>Weapon</div>
                            <div style={{width: "60px", textAlign: "center"}}>SR</div>
                            <div style={{width: "60px", textAlign: "center"}}>Attack</div>
                            <div style={{width: "60px", textAlign: "center", marginLeft: "10px"}}>Parry</div>
                            <div style={{width: "90px", textAlign: "center"}}>Damage</div>
                        </div>
                    </>
            : 
                <>
                    <div style={{display:"flex", fontWeight: "bold", padding: "0px 5px"}}>
                        <div style={{width: "80px"}}>Weapon</div>
                        <div style={{width: "30px", textAlign: "center"}}>SR</div>
                        <div style={{width: "90px", textAlign: "center"}}>Attack / Parry</div>
                        <div style={{width: "80px", textAlign: "center"}}>Damage</div>
                        <div style={{width: "80px", textAlign: "center"}}>HP</div>
                    </div>
                </>
            }   
            {props.pageState.editMode ? 
                <div style={props.style}>
                    {displayWeapons && displayWeapons.filter(function(obj) {return (!obj.hidden)}).map((item, index) => {
                        return (
                            <WeaponTile key={index} item={item} update={update} updateSkill={updateSkill} processResult={props.processResult} processDamage={props.processDamage} entity={props.entity}>
                                {React.cloneElement(props.tile, {...props, displayItem: props.displayItem, item: item, update: update, updateSkill:updateSkill, entity:props.entity })}
                            </WeaponTile>
                        )
                    })}
                </div>
            : 
                <div className={globalStyles.panel} style={{...props.style}}>
                    {displayWeapons && displayWeapons.filter(function(obj) {return (!obj.hidden)}).map((item, index) => {
                        if (item.equipped) {
                            return (
                                <WeaponTile key={index} item={item} update={update} updateSkill={updateSkill} processResult={props.processResult} processDamage={props.processDamage} entity={props.entity} displayItem={props.displayItem} >
                                    {React.cloneElement(props.tile, {...props, displayItem: props.displayItem, item: item, processResult: props.processResult, update: update, updateSkill:updateSkill, processDamage:props.processDamage, entity:props.entity })}
                                </WeaponTile>
                            )
                        }
                    })}
                </div>
            }
        </div>
        </div>
    );
}

export default withPageState(withContext(Weapons));