import React, { useState, useEffect} from 'react';
import { withFirebase } from '../Firebase';
import * as ExistingCharacter from '../../data/existingCharacter.json';
import { createBlankCharacter } from '../../services/characterService';
import DialogButtons from '../Dialogs/dialogButtons';
import Selector from '../Controls/selector';
import * as OccupationsData from '../../data/occupations.json';
import * as HomelandsData from '../../data/homelands.json';
import EditBox from '../Controls/editBoxSingle';
import { v4 as uuidv4 } from 'uuid';
import { withPageState } from '../Context/PageState';
import { getAllCults, getRuneSpell } from '../../services/dataService';
import MultiSelect from '../Controls/multiSelect';
import MultiSelectItems from '../Controls/multiSelectItems';

const pantheons=[{name: "Orlanthi"}, {name: "Praxian"}, {name: "Lunar"}, {name: "Yelm"}, {name: "Water"}, {name: "Troll"}, {name: "Elf"}, ]
const ranks = [{id: "Lay Member", name: "Lay Member"}, {id: "Initiate", name: "Initiate"}, {id: "God Talker", name: "God Talker"}, {id: "Rune Lord", name: "Rune Lord"}, {id: "Rune Priest", name: "Rune Priest"}, {id: "Shaman", name: "Shaman"}];
const occupations = [{id: "Assistant Shaman", name: "Assistant Shaman"}, {id: "Bandit", name: "Bandit"}, {id: "Chariot Driver", name: "Chariot Driver"}, {id: "Crafter", name: "Crafter"}, {id: "Entertainer", name: "Entertainer"}, {id: "Farmer", name: "Farmer"}, {id: "Fisher", name: "Fisher"}, {id: "Healer", name: "Healer"}, {id: "Herder", name: "Herder"}, {id: "Hunter", name: "Hunter"}, {id: "Merchant", name: "Merchant"}, {id: "Noble", name: "Noble"}, {id: "Philosopher", name: "Philosopher"}, {id: "Priest", name: "Priest"}, {id: "Scribe", name: "Scribe"}, {id: "Thief", name: "Thief"}, {id: "Warrior (Heavy Cavalry)", name: "Warrior (Heavy Cavalry)"}, {id: "Warrior (Heavy Infantry)", name: "Warrior (Heavy Infantry)"}, {id: "Warrior (Light Cavalry)", name: "Warrior (Light Cavalry)"}, {id: "Warrior (Light Infantry)", name: "Warrior (Light Infantry)"}];
const genders = [{id: "Male", name: "Male"}, {id: "Female", name: "Female"}, {id: "Neither", name: "Neither"}, {id: "Both", name: "Both"}]
const homelands = [{id: "Sartar", name: "Sartar"}, {id: "Esrolia", name: "Esrolia"}, {id: "Grazelands", name: "Grazelands"}, {id: "Prax", name: "Prax"}, {id: "Male", name: "Lunar Tarsh"}, {id: "Old Tarsh", name: "Old Tarsh"}]

const ImportCharacterDialog = (props) => {

	const [items, setItems] = useState([]);
    const [availableCults, setAvailableCults] = useState([]);
	const [selected, setSelected] = useState("Select");
	const [selectedCult, setSelectedCult] = useState();
    const [selectedSubcults, setSelectedSubcults] = useState([]);
	const [pantheon, setPantheon] = useState("");
	const [occupation, setOccupation] = useState("");
	const [selectedOccupation, setSelectedOccupation] = useState(null);
	const [rank, setRank] = useState("");
	const [runePoints, setRunePoints] = useState(3);
    const [gender, setGender] = useState("");
    const [age, setAge] = useState(21);
    const [homeland, setHomeland] = useState("");
    const [selectedHomeland, setSelectedHomeland] = useState(null);
    const [tribe, setTribe] = useState("");
    const [clan, setClan] = useState("");
    const [disabled, setDisabled] = useState(true)

	useEffect(() => {
		if (!props.cult) {
			var cults = [];
			var allCults = getAllCults(props.campaign).filter(function(cult) {return !cult.restricted && !cult.parentCultId});
			allCults.forEach(cult => {
				cults.push(cult);
			});
			setItems(cults);
		}
	},[]);

    useEffect(() => {
        if (gender && homeland && occupation && pantheon && selectedCult && rank) {
            setDisabled(false) 
        }
        else {
            setDisabled(true);
        }
    },[gender,homeland, occupation, pantheon, selectedCult, rank])

	useEffect(() => {
		if (props.cult) {
			setPantheon(props.cult.pantheon)
			var cults=[props.cult];
			setAvailableCults(cults)
			setSelectedCult(props.cult);
			setRank(props.cult.rank);
			setRunePoints(props.cult.runePoints[0].total);
		}
	},[props.cult])

	useEffect(() => {
		var cults = [];
		items.forEach(item => {
			if (item.pantheons.indexOf(pantheon) > -1) {
				cults.push(item);
			}
		  });

		  setAvailableCults(cults);
		},[pantheon])

	function selectCult(value) {
		setSelected(value);
        var allCults = getAllCults(props.campaign);
		const index = allCults.findIndex(obj => obj.name === value);
        var cultDetails = allCults[index];
        var runeSpells = [];
        cultDetails.commonRuneSpells.forEach(spell => {
            const spellDetails = getRuneSpell(spell.parentId, props.campaign)
            var updatedSpell = {...spell, points: spellDetails.points, runes: [...cultDetails.runes], target: spellDetails.target || "", stackable: spellDetails.stackable, common: true}
            runeSpells.push(updatedSpell);
        })
        var cult = {
            id: uuidv4(),
            gifts: [],
            geases: [],
            name: cultDetails.name,
            parentId: cultDetails.id,
            rank: rank,
            runePoints: [
                {
                    type: "Character",
                    current: runePoints,
                    total: runePoints,
                    entityId: ""
                }
            ],
            runeSpells: [...runeSpells],
            runes: [...cultDetails.runes]
        }
        if (cultDetails.subcults) {
            cult.subcults = [...cultDetails.subcults.cults]
        }
		setSelectedCult(cult);
	}

    function selectOccupation(value) {
        setOccupation(value);
        var allOccupations = {...OccupationsData};
		const index = allOccupations.data.findIndex(obj => obj.name === value);
        var details = allOccupations.data[index];
        var skills = []
        allOccupations.data[index].skills.forEach(skill => {
            skill.options.forEach(option => {
                skills.push({
                    id: uuidv4(),
                    name: option.name,
                    parentId: option.parentId
                })
            })
        })
        var weaponSkills = []
        if (allOccupations.data[index].weapons && allOccupations.data[index].weapons.length > 0) {
            allOccupations.data[index].weapons.forEach(weapon => {
                weapon.options.forEach(option => {
                    weaponSkills.push({
                        id: uuidv4(),
                        name: option.name,
                        parentId: option.parentId
                    })
                })
            })
        }
		var occ = {
            name: details.name,
            reputation: details.reputation,
            skills: skills,
            weaponSkills: weaponSkills
        }
        setSelectedOccupation(occ);
    }

    function selectHomeland(value) {
        setHomeland(value);
        var allHomelands = {...HomelandsData};
        var index = allHomelands.data.findIndex(obj => obj.name === value);
        var selected = {
            name: value,
            title: allHomelands.data[index].title
        }
        setSelectedHomeland(selected);
    }

	function selectRank(value) {
		setRank(value);
	}

	function createCharacter() {
		var char = {...ExistingCharacter};
		var newcharacter = char.default;
		newcharacter.uid = props.authUser.uid;
		var now = new Date();
		newcharacter.name = {first: props.authUser.firstName + " " + now.getHours() + now.getMinutes() + now.getSeconds(), last: ""} ;
        newcharacter.occupation = {...selectedOccupation};
        newcharacter.personalInfo = {gender: gender, age: age, family: {siblings: []} }
        newcharacter.background = {homeland: selectedHomeland.name, culture: selectedHomeland.title, tribe: tribe, clan: clan, reputation: []}
		props.firebase
		.characters()
		.add(
			newcharacter
		)
		.then(function(docRef) {
            var char = createBlankCharacter();
            char.id = docRef.id;
            char.campaignId = docRef.id;
            char.uid = newcharacter.uid
            char.name = {...newcharacter.name}
            char.personalInfo = newcharacter.personalInfo;
            char.background = newcharacter.background;
            char.occupation = newcharacter.occupation;
            char.magicPoints[0].entityId = docRef.id;
            char.import = true;
            char.cults = [{...selectedCult}];
            char.cults[0].runePoints[0].entityId = docRef.id;
            char.cults[0].runePoints[0].total = runePoints;
            char.cults[0].runePoints[0].current = runePoints;
            char.cults[0].pantheon = pantheon;
            char.cults[0].rank = rank;
            char.created = new Date();
            char.complete = true;
            props.firebase
            .character(docRef.id)
            .set(char)
            .then( function() {
                props.pageState.setEditMode(true)
                // navigate("/charactersheet/" + docRef.id);
                window.location.href="/character/" + docRef.id
            });
        })
	}

    function updateRunePoints(value) {
        if (value > 0) {
            setRunePoints(value);
        }
    }

    return (
        <div style={{padding: "20px"}}>
            <div style={{fontSize: "18px", margin: "10px 0px"}}>In order to import your character into RuneQuesting, enter the following details: </div>
            <div style={{display:"flex", margin: "10px 0px", alignItems: "center", marginLeft: "30px"}}>
                <div style={{display:"flex", alignItems: "center"}}>
                    <Selector updateSelected={setGender} values={genders} label="Gender:" style={{width: "160px"}} value={gender} />
                </div>
                <div style={{marginLeft: "73px", width: "100px"}}>
                    <EditBox number placeholder="Age" label="Age" value={age} onChange={setAge} />
                </div>
            </div>
            <div style={{display:"flex", margin: "10px 0px", alignItems: "center", marginLeft: "13px"}}>
                <div >
                    <Selector updateSelected={(value) => selectHomeland(value)} values={homelands} label="Homeland:" style={{width: "160px"}} value={homeland} />
                </div>
                <div style={{marginLeft: "20px"}}>
                    <Selector updateSelected={selectOccupation} values={occupations} label="Occupation:" style={{width: "200px"}} value={occupation} />
                </div>
            </div>
            <div style={{display:"flex", margin: "10px 0px", alignItems: "center", marginLeft: "50px"}}>
                <div style={{width: "200px"}}>
                    <EditBox placeholder="Tribe" label="Tribe" value={tribe} onChange={setTribe} />
                </div>
                <div style={{marginLeft: "70px", width: "200px"}}>
                    <EditBox placeholder="Clan" label="Clan" value={clan} onChange={setClan} />
                </div>
            </div>
            <div style={{display:"flex", margin: "10px 0px", alignItems: "center", marginLeft: "18px"}}>
                <div >
                    {props.cult ? 
                        <div style={{fontWeight: "bold"}}>{"Pantheon: " + props.cult.pantheon}</div>
                    : 
                        <Selector updateSelected={(value) => setPantheon(value)} values={pantheons} label="Pantheon:" style={{width: "160px"}} value={pantheon} />
                    }
                </div>
                {pantheon ? 
                    props.cult ? 
                        <div style={{marginLeft: "20px", fontWeight: "bold"}}>{"Cult: " + props.cult.name}</div>
                    : 
                    <div style={{marginLeft: "66px"}}>
                        <Selector updateSelected={selectCult} values={availableCults} label="Cult:" style={{width: "200px"}} value={selected} />
                    </div>
                : 
                null
                }
            </div>
            {selectedCult && selectedCult.subcults ? 
                <div style={{marginLeft: "66px"}}>
                    <MultiSelectItems values={selectedCult.subcults.cults} onChange={setSelectedSubcults} selectedValues={selectedSubcults} />
                </div>
            : 
                null
            }
            <div style={{display:"flex", margin: "10px 0px", alignItems: "center"}}>
                {selectedCult ? 
                    <div style={{marginLeft: "46px", display:"flex", alignItems: "center"}}>
                        <Selector updateSelected={selectRank} values={ranks} label="Rank:" style={{width: "160px"}} value={rank} />
                        <div style={{marginLeft: "20px"}}>
                            <EditBox  label="Rune Points: " onChange={(value) => updateRunePoints(value)} value={runePoints} number width="40px">{runePoints}</EditBox>
                        </div>
                    </div>
                : 
                    null
                }
            </div>
            <DialogButtons style={{borderTop: "2px solid #2f588a"}} disabled={disabled} handleOkClick={createCharacter} handleCancelClick={props.close} title="Ok" />
        </div>                

    );
}

export default withFirebase(withPageState(ImportCharacterDialog));