import React, {useState, useEffect, useRef} from 'react';
import {withContext} from '../Context/context';
import rollSound from '../../sounds/rollD100.mp3';
import {postDicePoolMessage} from '../../services/dieRollService';
import { withPageState } from '../Context/PageState';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import d4Icon from '../../icons/d4_icon.svg';
import d6Icon from '../../icons/d6_icon.svg';
import d8Icon from '../../icons/d8_icon.svg';
import d10Icon from '../../icons/d10_icon.svg';
import d20Icon from '../../icons/d20_icon.svg';
import EditBox from '../Controls/editBoxSingle';
import { Button } from '@mui/material';

const DicePoolContextPanel = (props) => {

    const [dicePool, setDicePool] = useState({});
    const [modifier, setModifier] = useState(0);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        setDicePool({d3: 0, d4: 0, d6: 0, d8: 0, d10: 0, d20: 0, d100: 0});
    },[]);
    
    useEffect(() => {
        if (clicked) {
            setClicked(false);
            var result = 0;
            if (dicePool.d100 > 0) {
                if (props.diceRoller.diceResults.d100 === 0 && props.diceRoller.diceResults.d10 === 10) {
                    result = 100;
                }
                else if (props.diceRoller.diceResults.d10 === 10) {
                    result = props.diceRoller.diceResults.d100;
                } 
                else {
                    result = props.diceRoller.diceResults.d100 + props.diceRoller.diceResults.d10
                } 
            }
            else if (dicePool.d20 > 0) {
                result = props.diceRoller.diceResults.d20
            }
            else {
                Object.keys(props.diceRoller.diceResults).forEach(key => {
                    result += props.diceRoller.diceResults[key];
                })
            }
            if (modifier) {
                result += modifier;
            }
            postDicePoolMessage({result: result, dicePool: dicePool}, props.authData, props.firebase, {campaignId: props.campaignId, name: {first: props.providerData ? props.providerData.character.name.first : "GM"}}, modifier)
            props.displayResult(result, props.diceRoller.diceResults, modifier)
            setDicePool({});
            setModifier(0)
        }
    },[props.diceRoller.diceResults])

    var d4Pool = [];
    for (var a = 0; a < dicePool.d4; a++) {
        d4Pool.push(<img onClick={() => addDieToPool("d4", -1)} key={"d4" + a} style={{width: "55px", height: "55px"}} src={d4Icon}></img>)
    }
    var d6Pool = [];
    for (var a = 0; a < dicePool.d6; a++) {
        d6Pool.push(<img onClick={() => addDieToPool("d6", -1)} key={"d6" + a} style={{width: "55px", height: "55px"}} src={d6Icon}></img>)
    }
    var d8Pool = [];
    for (var a = 0; a < dicePool.d8; a++) {
        d8Pool.push(<img onClick={() => addDieToPool("d8", -1)} key={"d8" + a} style={{width: "55px", height: "55px"}} src={d8Icon}></img>)
    }
    if (dicePool.d100 > 0) {}
    else {
        var d10Pool = [];
        for (var a = 0; a < dicePool.d10; a++) {
            d10Pool.push(<img onClick={() => addDieToPool("d10", -1)} key={"d10" + a} style={{width: "55px", height: "55px"}} src={d10Icon}></img>)
        }
    }

    function rollSingleDie(dieType){
        let beat = new Audio(rollSound);
        beat.play();
        event.stopPropagation();
        if (props.diceRoller) {
            var dice = {};
            dieType.forEach(die => {
                dice[die] = 1
            })
            setDicePool(dice);
            props.diceRoller.setDice(dice)
            setClicked(true);
        }
    }

    function rollDice(){
        let beat = new Audio(rollSound);
        beat.play();
        event.stopPropagation();
        if (props.diceRoller) {
            props.diceRoller.setDice(dicePool)
            setClicked(true);
        }
    }

    function addDieToPool(dieType, value){
        var newDicePool = {...dicePool};
        if (newDicePool.d100) {newDicePool.d100 = 0;}
        if (newDicePool.d20) {newDicePool.d20 = 0;}
        var totalDice = (newDicePool.d3 || 0) + (newDicePool.d4 || 0) + (newDicePool.d6 || 0) + (newDicePool.d8 || 0) + (newDicePool.d10 || 0) 
        if (totalDice >= 30) return;
        if (newDicePool[dieType]) {
            newDicePool[dieType] += value;
        }
        else {
            newDicePool[dieType] = 1;
        }
        setDicePool(newDicePool);
    }

    function clearDice() {
        setDicePool({d3: 0, d4: 0, d6: 0, d8: 0, d10: 0, d20: 0, d100: 0});
        setModifier(0);
    }

    return (
            <div style={{fontSize: "14px", lineHeight: "1"}}>
                <div style={{height: "800px", overflow: "auto", padding: "10px"}}>
                    <div style={{padding: "10px 0px"}}>Click on a die icon to add it to the pool, and click on a die in the pool to remove it. Clicking on the D20 or D100 will roll a single copy of that die only</div>
                    <hr style={{width:"340px", margin: "10px: 0px"}} />
                    <div style={{display:"flex", alignItems: "center", marginTop: "10px"}}>
                        <img onClick={() => rollSingleDie(["d20"])}  style={{width: "55px", height: "55px"}} src={d20Icon}></img>
                        <fieldset onClick={() => rollSingleDie(["d100", "d10"])}  style={{display:"flex", alignItems:"center", border: "1px solid #8a612f", borderRadius: "5px"}}>
                            <legend>D100</legend>
                            <img style={{width:"55px", height:"55px"}} src={d10Icon}></img>
                            <img style={{width:"50px", height:"50px"}} src={d10Icon}></img>
                        </fieldset>
                    </div>
                    <hr style={{width:"340px", margin: "10px: 0px"}} />
                    <div style={{display:"flex", alignItems: "center"}}>
                        <img onClick={() => addDieToPool("d4", 1)} style={{width: "55px", height: "55px"}} src={d4Icon}></img>
                        <img onClick={() => addDieToPool("d6", 1)}  style={{width: "55px", height: "55px"}} src={d6Icon}></img>
                        <img onClick={() => addDieToPool("d8", 1)}  style={{width: "55px", height: "55px"}} src={d8Icon}></img>
                        <img onClick={() => addDieToPool("d10", 1)} style={{width: "55px", height: "55px"}} src={d10Icon}></img>
                    </div>
                    <hr style={{width:"340px", margin: "10px: 0px"}} />
                    <div style={{display:"flex", alignItems: "center", marginTop: "10px"}}>
                        <div>Modify dice roll: </div>
                        <div style={{width: "100px", marginLeft: "10px"}}>
                            <EditBox number onChange={setModifier} value={modifier} placeholder="Modifier" />
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection: "column", border: "1px solid #676767", height: "300px", borderRadius: "10px", margin: "20px 0px", padding: "10px 0px"}}>
                        <div id="d4Pool">
                                {d4Pool}
                        </div>
                        <div id="d6Pool">
                                {d6Pool}
                        </div>
                        <div id="d8Pool">
                                {d8Pool}
                        </div>
                        <div id="d10Pool">
                                {d10Pool}
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"row-reverse"}}>
                        <Button disabled={props.disabled || false} onClick={rollDice} style={{width: "100px"}} color="primary" variant="contained">Roll</Button>
                        <Button onClick={clearDice} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Clear</Button>
                    </div>
                    </div>
            </div>
    )
}
export default withContext(withPageState(DicePoolContextPanel))