import React from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";

const CancelEditsDialog = (props) => {

    return (
        <Dialog open={props.open} maxWidth="xs" >
            <DialogTitle>Confirm Cancellation</DialogTitle>
            <DialogContent>
                <div>{"You are about to cancel all the changes you have just made to this " + props.type + " while in edit mode. Click Yes to go ahead and lose those changes, or No to keep your changes."}</div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => props.confirm(false)}>
                No
                </Button>
                <Button onClick={() => props.confirm(true)}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelEditsDialog;