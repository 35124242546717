import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withFirebase } from '../Firebase';
import { Button, Switch, FormGroup, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { withPageState } from '../Context/PageState';
import { getAllCults, getSkill, getWeapon } from '../../services/dataService';
import DescriptionDialog from './descriptionDialog';
import AddSpellEffectsDialog from './addSpellEffectsDialog';
import AddWeaponFilterDialog from './addWeaponFilterDialog';
import InfoButton from '../Controls/infoButton';

const targetTypes = [{name: "Character"}, {name: "Weapon"}, {name: "None"}];
const spellTypes = [{name: "Active"}, {name: "Passive"}, {name: "Ritual"}];
const durations = [{name: "Instant"}, {name: "Temporal"}, {name: "Other"}];
const ranges = [{name: "Ranged"}, {name: "Self"}, {name: "Touch"}, {name: "Other"}];

const CustomSpiritSpellContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [openEffects, setOpenEffects] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);
	const [spell, setSpell] = useState({name: "", points: 0, range: "", duration: "", variable: false, restricted: false, cult: {}});
	const [selectors, setSelectors] = useState({type: "", range: "", duration: ""});
    const [targetType, setTargetType] = useState({name: "Character"});
    const [enemy, setEnemy] = useState(false);
	const [info, setInfo] = useState("");
	const [customContent, setCampaign] = useState(props.customContent);
	const [restricted, setRestricted] = useState(false);

	const infoBox = useRef(null)

	useEffect(() => {
		if (props.panelItem) {
			setSpell(props.panelItem.item);
			setRestricted(props.panelItem.item.restricted)
		}
		if (props.panelItem.item.target) {
			const index = targetTypes.findIndex(obj => obj.name === props.panelItem.item.target.type)
			if (index > -1) {
				setTargetType(targetTypes[index]);
			}
		}
		if (props.panelItem.item.enemy !== undefined) {
			setEnemy(props.panelItem.item.enemy)
		}
		var selector = {type: "", range: "", duration: ""}
		if (props.panelItem.item.type) {
			selector.type = props.panelItem.item.type
		}
		if (["Instant", "Temporal"].indexOf(props.panelItem.item.duration) > -1) {
			selector.duration = props.panelItem.item.duration
		}
		else {
			selector.duration = "Other";
		}
		if (["Touch", "Ranged"].indexOf(props.panelItem.item.range) > -1) {
			selector.range = props.panelItem.item.range
		}
		else {
			selector.range = "Other";
		}
		setSelectors(selector)
	},[props.panelItem]);

    useEffect(() => {
        if (props.buttonClick === "ok") {
			props.update(customContent, true);
        }
    },[props.buttonClick])

	function updateTarget(newTarget) {
		setTargetType(newTarget);
		var updatedSpell = {...spell}
		updatedSpell.target.type = newTarget.name;
		setSpell(updatedSpell);
		props.update(updatedSpell, "spiritSpells")
	}

	function updateEnemy(event) {
		let enemy = event.target.value === "Foe";
		setEnemy(enemy);
		var updatedSpell = {...spell}
		updatedSpell.enemy = enemy;
		setSpell(updatedSpell);
		props.update(updatedSpell, "spiritSpells")
	}

	function setValue(field, value) {
		var updatedSpell = {...spell}
		updatedSpell[field] = value;
		setSpell(updatedSpell);
		props.update(updatedSpell, "spiritSpells")
	}

	function getEffectName(effect) {
		let name = effect.name;
		let item = null;
		let title = "";

		switch (name) {
			case "attackModifier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return effect.value < 0 ? (title + " attacks decreased by") : (title + " attacks increased by")
			case "parryModifier": 
				return "Parry Modifier";
			case "attackMultiplier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return title + " attacks multiplied by"
			case "parryMultiplier": 
				title = effect.item ? effect.item.name : spell.filter ? "Selected" : "All"
				return title + " parries multiplied by"
			case "damage": 
				return "Total Damage";
			case "damageModifier": 
				return "Damage modified by " + effect.value.quantity + "D" + effect.value.dieType + (effect.value.modifier < 0 ? effect.value.modifier : "+" + effect.value.modifier);
			case "damageMultiplier": 
				return "Weapon damage multiplied by";
			case "defenseModifier": 
				return "Incoming attacks reduced by";
			case "moveMultiplier": 
				return "Move multiplied by";
			case "moveModifier": 
				return effect.value < 0 ? "Move decreased by" : "Move increased by"
			case "strikeRankModifier": 
				title = effect.item.name + " strike rank"
				return effect.value < 0 ? title + " decreased by" : title + " increased by"
			case "statModifier": 
				return effect.item.name + (effect.value < 0 ? " decreased by" : " increased by")
			case "statMultiplier": 
				return effect.item.name + " multiplied by"
			case "armourModifier": 
				return effect.value < 0 ? "Armor decreased by" : "Armor increased by"
			case "spiritArmourModifier": 
				return effect.value < 0 ? "Spirit Combat armor decreased by" : "Spirit Combat armor increased by"
			case "skillMultiplier": 
				item = getSkill(effect.item.id, props.customContent)
				title = (item && item.name) + (effect.item.variant ? " (" + effect.item.variant + ")" : "") + " skill";
				return title + " multiplied by ";
			case "skillModifier": 
				item = getSkill(effect.item.id, props.customContent)
				title = (item && item.name) + (effect.item.variant ? " (" + effect.item.variant + ")" : "") + " skill";
				return title + (effect.value < 0 ? " decreased by" : " increased by")
			case "addWeapon": 
				item = getWeapon(effect.item.id, props.customContent)
				return ("Add " + item && item.name || "Weapon");
		}
	}

	function updateRestricted(event) {
		setRestricted(event.target.checked);
		setValue("restricted", event.target.checked);
	}

    function applyDescription(editorContent, title, index) {
        var updatedSpell = {...spell}
        updatedSpell.description = editorContent;
        setSpell(updatedSpell);
		props.update(updatedSpell, "spiritSpells")
    }

	function applyEffects(spellEffects) {
		let updatedSpell = {...spell};
		updatedSpell.effects = spellEffects.effects;
		setSpell(updatedSpell)
		props.update(updatedSpell, "spiritSpells")
	}

	function applyFilters(filters) {
		let updatedSpell = {...spell};
		let spellFilter = {};
		if (filters.classes.length > 0) {spellFilter.classes = filters.classes;}
		if (filters.damageTypes.length > 0) {spellFilter.damageTypes = filters.damageTypes;}
		if (filters.categories.length > 0) {spellFilter.categories = filters.categories;}
		if (filters.weapons.length > 0) {spellFilter.weapons = filters.weapons;}
		if (!updatedSpell.target) {updatedSpell.target = {};}
		updatedSpell.target.filter = spellFilter;
		setSpell(updatedSpell)
		props.update(updatedSpell, "spiritSpells")
	}

	function setChoice(name, item) {
		setValue(name, item.name);
		let updatedSelectors = {...selectors};
		updatedSelectors[name] = item.name;
		setSelectors(updatedSelectors)
	}

	if (spell) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: "800px", overflow: "auto", padding: "5px"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{width: "80px", fontWeight: "bold"}}>Name: </div>
									<EditBox size="sm" width="200px" height="23px" value={spell.name} onChange={(value) => setValue("name", value)} />
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{width: "120px", fontWeight: "bold"}}>Name: </div>
									<div>{spell.name}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{width: "80px", fontWeight: "bold"}}>Points: </div>
									<EditBox size="sm" number width="50px" height="23px" value={spell.points} onChange={(value) => setValue("points", value)} />
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{width: "120px", fontWeight: "bold"}}>Points: </div>
									<div>{spell.points}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<div style={{height: "28px"}}>
										<FormGroup>
											<FormControlLabel style={{marginLeft: "0px"}} labelPlacement='start' control={<Switch color="primary" checked={spell.variable} onChange={(event) => setValue("variable", event.target.checked)}></Switch>} label="Variable:"></FormControlLabel>
										</FormGroup>
									</div>
								:
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{width: "120px", fontWeight: "bold"}}>Variable: </div>
										<div>{spell.variable ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<div className={globalStyles.contentLine}>
									<div style={{height: "28px"}}>
										<Selector labelWidth="70px" label="Range:" style={{height: "28px"}} value={selectors.range} values={ranges} onChange={(item) => {setChoice("range", item)}} />
									</div>
									{selectors.range === "Other" ? 
										<div style={{marginLeft: "20px"}}>
											<EditBox placeholder="Enter range" size="sm" width="120px" height="23px" value={spell.range} onChange={(value) => setValue("range", value)} />
										</div>
									: null
									}
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{width: "120px", fontWeight: "bold"}}>Range: </div>
									<div>{spell.range}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<div className={globalStyles.contentLine}>
									<div style={{height: "28px"}}>
										<Selector labelWidth="70px" label="Duration:" style={{height: "28px"}} value={selectors.duration} values={durations} onChange={(item) => {setChoice("duration", item)}} />
									</div>
									{selectors.duration === "Other" ? 
										<div style={{marginLeft: "20px"}}>
											<EditBox placeholder="Enter duration" size="sm" width="120px" height="23px" value={spell.duration} onChange={(value) => setValue("duration", value)} />
										</div>
									: null
									}
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{width: "120px", fontWeight: "bold"}}>Duration: </div>
									<div>{spell.duration}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<div className={globalStyles.contentLine}>
									<div style={{height: "28px"}}>
										<Selector labelWidth="70px" label="Type:" style={{height: "28px"}} value={selectors.type} values={spellTypes} onChange={(item) => {setValue("type", item.name)}} />
									</div>
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{width: "120px", fontWeight: "bold"}}>Type: </div>
									<div>{spell.type}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<>
										<div style={{display: "flex", position: "relative", alignItems: "center", height: "28px"}}>
											<FormGroup>
												<FormControlLabel style={{marginLeft: "0px"}} labelPlacement='start' control={<Switch color="primary" checked={spell.restricted} onChange={updateRestricted}></Switch>} label="Restricted Spell:"></FormControlLabel>
											</FormGroup>
											<div style={{marginLeft: "10px"}}>
												<InfoButton title="Restricted:" info="Select this if the spirit spell is restricted to specific cults, and cannot be selected by characters outside of those cults e.g. Sleep for Chalana Arroy, Forget for Lanbril." />
											</div>
										</div>
									</>
								:
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{width: "120px", fontWeight: "bold"}}>Restricted Spell: </div>
										<div>{restricted ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						{restricted ? 
								props.pageState.editMode ? 
									<div style={{marginTop: "10px"}}>
										<Selector label="Select Cult:" style={{height: "28px", width: "200px"}} value={spell.cult && spell.cult.name} values={getAllCults(props.customContent).filter(function(cult) {return ! cult.restricted && !cult.parentCultId})} onChange={(item) => {setValue("cult", {name: item.name, parentId: item.id})}} />
									</div>
								: 
								<div style={{marginTop: "10px"}}>
									<div style={{display: "flex", alignItems: "center"}}>
										<div style={{width: "120px", fontWeight: "bold"}}>Restricted To:</div>
										<div>{spell.cult && spell.cult.name}</div>
									</div> 
								</div>
						: 
							null
						}
						<hr style={{marginTop: "10px"}} />
						{props.pageState.editMode ? 
							<>
								<div style={{display:"flex", alignItems: "center", position: "relative", marginTop: "10px", justifyContent:"space-between"}}>
									<div className={globalStyles.contentLine}>
										<div style={{fontWeight: "bold"}}>Target:</div>
										<InfoButton title="Target:" info="Select Friend if the spell is used to target yourself, other PCs, or allied characters. Select Foe if the spell is used to target enemies." />
									</div>
								<FormControl style={{marginRight: "-14px"}} variant="outlined">
									<RadioGroup aria-label="damage-type-label" row color="primary" value={enemy} onChange={updateEnemy}>
										<FormControlLabel key="friend" style={{fontSize: "14px", height: "28px"}} checked={!enemy} value={"Friend"} control={<Radio color='primary' />} label={<span style={{fontSize: "14px"}}>Friend</span>} />
										<FormControlLabel key="foe" style={{fontSize: "14px", height: "28px"}} checked={enemy} value={"Foe"} control={<Radio color='primary' />} label={<span style={{fontSize: "14px"}}>Foe</span>} />
									</RadioGroup>
								</FormControl>
								</div>
							</>
						:
							<div style={{display:"flex", alignItems: "center"}}>
								<div style={{fontWeight: "bold"}}>Target:&nbsp; </div>
								<div>{enemy ? "Enemy characters and creatures" : "Player characters & allies"}</div>
							</div>
						}
						<div style={{marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<>
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", position: "relative", height: "28px"}}>
										<div style={{display:"flex", alignItems: "center"}}>
											<div>Target Type: </div>
											<InfoButton title="Target Type:" info="Select Weapon if the spellcaster must target a specific weapon when casting the spell e.g. Bladesharp or Truesword. Select Character for all other spells." />
										</div>
										<Selector style={{width: "120px", height: "28px", marginLeft: "90px"}} value={targetType ? targetType.name : "Select"} values={targetTypes} onChange={updateTarget} />
									</div>
								</>
							:
								<div style={{display:"flex", alignItems: "center"}}>
									<div style={{fontWeight: "bold"}}>Target Type:&nbsp; </div>
									<div>{targetType.name}</div>
								</div>
							}

						</div>
						{props.pageState.editMode ? 
							<>
								<hr style={{margin: "10px 0px"}} />
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{display: "flex", alignItems: "center", position: "relative"}}>
										<div style={{fontWeight: "bold", fontSize: "15px"}}>Weapon Filters: </div>
										<InfoButton title="Weapon Filters:" info="Filters are used by weapon-based effects to determine which weapons are valid targets for the spell." />
									</div>
									<Button variant='contained' color='primary' onClick={() => setOpenFilters(true)}>Edit Filters</Button>
								</div>
							</>
						:
							spell.target && spell.target.filter ? 
								<>
								<hr style={{margin: "10px 0px"}} />
								<div style={{fontWeight: "bold", fontSize: "15px"}}>Weapon Filters: </div>
									{spell.target && spell.target.filter && spell.target.filter.classes ? 
										<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
											<div style={{fontWeight: "bold", width: "100px"}}>Classes: </div>
												{spell.target.filter.classes.map((weaponClass, index) => (weaponClass + (index < spell.target.filter.classes.length -1 ? ", " : "")))}
										</div>
									: null}
									{spell.target && spell.target.filter && spell.target.filter.damageTypes ? 
										<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
											<div style={{fontWeight: "bold", width: "100px"}}>Damage Types: </div>
											{spell.target.filter.damageTypes.map((damageType, dtIndex) => (damageType + (dtIndex < spell.target.filter.damageTypes.length -1 ? ", " : "")))}
											</div>
									: null}
									{spell.target && spell.target.filter && spell.target.filter.categories ? 
										<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
											<div style={{fontWeight: "bold", width: "100px"}}>Categories: </div>
											{spell.target.filter.categories.map((category, catIndex) => (category + (catIndex < spell.target.filter.categories.length -1 ? ", " : "")))}
										</div>
									: null}
									{spell.target && spell.target.filter && spell.target.filter.weapons ? 
										<div style={{display:"flex", alignItems: "center", margin: "10px 0px"}}>
											<div style={{fontWeight: "bold", width: "100px"}}>Specific Weapons: </div>
											{spell.target.filter.weapons.map((weapon, wIndex) => (weapon + (wIndex < spell.target.filter.weapons.length -1 ? ", " : "")))}
										</div>
									: null}
								</>
							: null
						}
						{props.pageState.editMode ? 
							<>
								<hr style={{margin: "10px 0px"}} />
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{display: "flex", alignItems: "center", position: "relative"}}>
										<div style={{fontWeight: "bold", fontSize: "15px"}}>Effects: </div>
										<InfoButton title="Effects:" info="Effects are the tool used to apply the actual effects of a spell to a character or weapon." />
									</div>
									<Button variant='contained' color='primary' onClick={() => setOpenEffects(true)}>Edit Effects</Button>
								</div>
							</>
						: 
							<>
								<hr style={{margin: "10px 0px"}} />
								{spell.effects && spell.effects.map((effect, index) => {
									return (
										<>
											<div style={{fontWeight: "bold", fontSize: "15px"}}>Effects: </div>
											<div key={index} style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
												<div>{getEffectName(effect)}&nbsp;</div>
												{effect.name !== "damageModifier" ? 
												<>
													<div>{effect.value}</div>
													<div>{effect.suffix === "pts" ? effect.value === 1 ? "pt" : "pts" : effect.suffix}</div>
												</>
												: null}
												<div style={{marginLeft: "5px"}}>{effect.ordinality.id === "total" ? "" : effect.ordinality.name}</div>
											</div>
										</>
									)
								})}
							</>
						}
						<div style={{marginTop: "10px"}}>
							{props.pageState.editMode ? 
								<>
									<hr style={{margin: "10px 0px"}} />
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px"}}>
										<div style={{fontWeight: "bold"}}>Description</div>
										<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
									</div>
									<div dangerouslySetInnerHTML={{__html: spell.description}}></div>
								</>
							: 
								<>
									<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
									<div dangerouslySetInnerHTML={{__html: spell.description}}></div>
								</>
							}
						</div>
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={spell.description} />
				</DialogBox>
				<DialogBox isOpen={openFilters} width="800px" title="Edit Weapon Filters">
					<AddWeaponFilterDialog customContent={props.customContent} close={() => setOpenFilters(false)} filter={spell.target && spell.target.filter} target={targetType && targetType.name} update={applyFilters} />
				</DialogBox>
				<DialogBox isOpen={openEffects} width="720px" title="Edit Spell Effects">
					<AddSpellEffectsDialog customContent={props.customContent} close={() => setOpenEffects(false)} effects={spell.effects} target={targetType && targetType.name} update={applyEffects} />
				</DialogBox>
			</>
		)
	}
	else {
		return null;
	}
}

export default withFirebase(withPageState(CustomSpiritSpellContextPanel));