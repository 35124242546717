import React from 'react';
import {withContext} from '../Context/context';

const CrushingDamageButton = (props) => {

    function calculateSpecialDice(damage) {
        var dicePool = [damage];
        if (props.entity.damageBonus) {
            var dice = {...props.entity.damageBonus}
            var maxDamageBonus = props.entity.damageBonus.dieType * props.entity.damageBonus.quantity;
            if (dice.modifier) {
                dice.modifier += maxDamageBonus;
            }
            else {
                dice.modifier = maxDamageBonus;
            }
            dicePool.push(dice);
        }
        return dicePool;
    }

    function calculateCriticalDice(damage) {
        var dicePool = [];
        var dice = {dieType: 0, quantity: 0, modifier: 0};

        var modifier = (damage.dieType * damage.quantity) + damage.modifier;
        if (props.entity.damageBonus) {
            var maxDamageBonus = (props.entity.damageBonus.dieType * props.entity.damageBonus.quantity) * 2;
            modifier += maxDamageBonus;
        }
        dice.modifier = modifier;
        dicePool.push(dice);
        return dicePool;
    }

    return (
            React.cloneElement(props.children, {...props, calculateSpecialDice, calculateCriticalDice})
    )
}

export default withContext(CrushingDamageButton)