import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import {withContext} from '../Context/context';
import { withPageState } from '../Context/PageState';
import { getEnchantmentsModifier } from '../../services/enchantmentsService';

const DiceButton = (props) => {

    const [target, setTarget] = useState(0);
    const [clicked, setClicked] = useState(false);
    const [disabled, setDisabled] = useState(props.disabled)

    useEffect(() => {
        if (props.disabled){
            setDisabled(true);
        }
        else setDisabled(target === 0);
    },[props.disabled, target])

    useEffect(() => {

        var value = props.target;
        var minimum = 5;
        if (props.minimum !== undefined) {
            minimum = props.minimum
        }
        if (value >= 0) {
            if (props.providerData.character.despondent) {
                value -= 10;
            }
            setTarget(value);
        }
        else if (props.item && props.item.value) {
            var categoryModifier = 0;
            if (props.entity?.categoryModifiers && props.item.skillCategory) {
                categoryModifier = props.entity.categoryModifiers[props.item.skillCategory];
            }
            value = props.item.value + categoryModifier;
            var enchantmentModifier = getEnchantmentsModifier(props.type, props.entity, props.item, value)
            value += enchantmentModifier;
            if (value === 0 && enchantmentModifier < 0) { // Managing zero multipliers
                minimum = 0;
                setDisabled(true);
                setTarget(0);
                return;
            }

            if (! props.noAugment) {
                value += props.providerData.augmenting?.modifier || 0;
            }

            var encPenalty = 0;
            if (props.providerData.character.maxEnc < props.providerData.currentEncumbrance) {
                encPenalty = props.providerData.character.maxEnc - props.providerData.currentEncumbrance
            }
            if (props.item.name === "Dodge") {
                if (props.providerData.currentEncumbrance <= props.providerData.character.maxEnc) {
                    value -= props.providerData.currentEncumbrance
                }
                else {
                    value -= props.providerData.character.maxEnc
                    value += (encPenalty * 5);
                }
            }
            else if (props.type === "Skill" && (["Agility", "Manipulation", "Stealth"].indexOf(props.item.skillCategory) > -1)) {
                value += (encPenalty * 5);
            } 
            if (props.providerData.character.despondent) {
                value -= 10;
            }
            var mod = props.modifier || 0;
            if (mod === "-") mod = 0;
            value += mod;
            if (value < minimum) {value = minimum}
            setTarget(value);
        }
        else {
            setTarget(0);
        }
    },[props.modifier, props.item, props.providerData?.augmenting, props.entity, props.target])

    useEffect(() => {
        if (clicked || props.diceRoller.processingOpposed) {
            setClicked(false);
            var title = props.item.name + " " + props.type || "";
            var result = -1;
            if (props.diceRoller.diceResults.d100 === 0 && props.diceRoller.diceResults.d10 === 10) {
                result = 100;
            }
            else if (props.diceRoller.diceResults.d10 === 10) {
                result = props.diceRoller.diceResults.d100;
            } 
            else {
                result = props.diceRoller.diceResults.d100 + props.diceRoller.diceResults.d10
            } 
            props.processResult({
                target: target, 
                result: result, 
                item: props.item, 
                augment: props.augment, 
                type: props.type,
                title: title
            });
        }
    },[props.diceRoller.diceResults])

    function rollDice(event) {
        event.stopPropagation();
        if (props.diceRoller) {
            setClicked(true);
            props.diceRoller.setDice({d100: 1, d10: 1})
        }
    }

    return(
        <div className={disabled ? globalStyles.disabledButton : globalStyles.diceButton} style={{...props.style}} onClick={props.pageState.editMode || disabled ? null : rollDice}>
            {React.cloneElement(props.children, {...props, target:target})}
        </div>
    )
}

export default withContext(withPageState(DiceButton));