import React, {useState, useEffect} from 'react'

const Geases = (props) => {

    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setSelected([...props.cult.selectedGeases]);
    },[props.cult.selectedGeases]);

    useEffect(() => {
        var updatedSelected = [];
        if (props.cult.selectedGeases){
            updatedSelected = [...props.cult.selectedGeases];
        }
        var count = props.count;
        if (count > updatedSelected.length) {
            while (count > updatedSelected.length) {
                var random = Math.ceil(Math.random() * 100);
                if (updatedSelected.indexOf(item => (random >= item.from) && (random <= item.to)) < 0) {
                    props.cult.geases.forEach(geas => {
                        if (random >= geas.from && random <= geas.to){
                            if (geas.reroll) {
                                count += geas.reroll;
                            }
                            else {
                                updatedSelected.push({...geas});
                            }
                        }
                    })
                }
            }
            setSelected(updatedSelected);
            props.update(updatedSelected);
        }
    },[props.count, selected])

    if (selected && selected.length > 0) {
        return (
            <div>
                <ul>
                    {selected.slice(0, props.count).map((geas, index) => {
                        return (
                            <li key={index}>{geas.description}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    else return null;
}

export default Geases