import React, { useState, useEffect } from 'react';
import StartingSkills from '../startingSkills';
import StartingWeapons from '../startingWeapons';
import OptionsDropdown from '../controls/optionsDropdown';
import FavouredPassion from '../favouredPassion';

const blankPrimary = {
    title: "Select primary cult skill", 
    bonus: 20,
    options: [],
    selected: {}
};
const blankSecondary ={
    title: "Select secondary cult skill", 
    bonus: 15,
    options: [],
    selected: {}
};
const blankTertiary ={
    title: "Select tertiary cult skill", 
    bonus: 10,
    options: [],
    selected: {}
};

const CultSkills = (props) => {

    const [selectedCult, setSelectedCult] = useState(props.cult);
    const [skillsComplete, setSkillsComplete] = useState(false);
    const [weaponsComplete, setWeaponsComplete] = useState(false);
    const [primaryOptions, setPrimaryOptions] = useState({...blankPrimary});
    const [secondaryOptions, setSecondaryOptions] = useState({...blankSecondary});
    const [tertiaryOptions, setTertiaryOptions] = useState({...blankTertiary});
    const [complete, setComplete] = useState(false);
    const [stats, setStats] = useState({STR: 0, DEX: 0})
    const [lowStats, setLowStats] = useState(false);

    useEffect(() => {
        if (props.cult) {
            setSelectedCult({...props.cult});
            if (!props.cult.weapons) {
                setWeaponsComplete(true);
            }
        }
    },[props.cult]);

    useEffect(() => {
        var updatedStats = {};
        props.builder.stats.details.forEach(stat => {
            if (stat.name === "STR" || stat.name === "DEX"){
                updatedStats[stat.name] = stat.baseValue + stat.statBonus;
            }
        });
        setStats(updatedStats);
    },[props.builder])

    useEffect(() => {
        if (props.cult.primarySkill) {
            var updatedPrimary = {...primaryOptions};
            updatedPrimary.selected = {...props.cult.primarySkill};
            setPrimaryOptions(updatedPrimary);
        }
        else {
            setPrimaryOptions({...blankPrimary});
        }
        if (props.cult.secondarySkill) {
            var updatedSecondary = {...secondaryOptions};
            updatedSecondary.selected = {...props.cult.secondarySkill};
            setSecondaryOptions(updatedSecondary);
        }
        else {
            setSecondaryOptions({...blankSecondary});
        }
        if (props.cult.tertiarySkill) {
            var updatedTertiary = {...tertiaryOptions};
            updatedTertiary.selected = {...props.cult.tertiarySkill};
            setTertiaryOptions(updatedTertiary);
        }
        else {
            setTertiaryOptions({...blankTertiary});
        }
    },[props.cult.name]);

    useEffect(() => {
        if (skillsComplete && weaponsComplete) {
            setOptions();
        }
    },[skillsComplete, weaponsComplete])

    useEffect(() => {
        if (skillsComplete && weaponsComplete && primaryOptions.selected.name && secondaryOptions.selected.name) {
            if (props.builder.occupation.name === "Priest"){
                if ( tertiaryOptions.selected.name){
                    setComplete(true);
                }
                else {
                    setComplete(false);
                }
            }
            else {
                setComplete(true);
            }
        }
        else {
            setComplete(false);
        }
    },[primaryOptions, secondaryOptions, tertiaryOptions])

    useEffect(() => {
        props.isComplete(complete);
    },[complete])

    function completeStartingSkills(selectedSkills, complete) {
        var updatedSelected = {...selectedCult};
        updatedSelected.skills = selectedSkills;
        setSelectedCult(updatedSelected)
        setSkillsComplete(complete);
        setOptions();
    }

    function completeStartingWeapons(selectedWeapons, complete) {
        var updatedSelected = {...selectedCult};
        updatedSelected.weapons = selectedWeapons;
        setSelectedCult(updatedSelected)
        setWeaponsComplete(complete);
        setOptions();
    }
    
    function setOptions() {
        var options = getOptionsFor(selectedCult.skills);
        if (selectedCult.weapons) {
            options = options.concat(getOptionsFor(selectedCult.weapons));
        }
        var updatedPrimary = {...primaryOptions};
        updatedPrimary.options = [...options];
        if (options.findIndex(option => option.name == updatedPrimary.selected.name) < 0) {
            updatedPrimary.selected = {};
        }
        setPrimaryOptions(updatedPrimary);
        var updatedSecondary = {...secondaryOptions};
        updatedSecondary.options = [...options];
        if (options.findIndex(option => option.name == updatedSecondary.selected.name) < 0) {
            updatedSecondary.selected = {};
        }
        setSecondaryOptions(updatedSecondary);
        var updatedTertiary = {...tertiaryOptions};
        updatedTertiary.options = [...options];
        if (options.findIndex(option => option.name == updatedTertiary.selected.name) < 0) {
            updatedTertiary.selected = {};
        }
        setTertiaryOptions(updatedTertiary);
    }

    function getOptionsFor(skills) {
        var options = [];
        skills.forEach(skill => {
            if (skill.options[0].name.includes("Worship") || skill.options[0].name.includes("Cult Lore") || skill.options[0].name == "Meditate") {}
            else {
                if (skill.selected) {
                    options.push({...skill.selected});
                }
                else {
                    options.push({...skill.options[0]});
                }
            }
        });
        return options;
    }

    function selectOption(option, index) {
        var updated = index === 1 ? {...primaryOptions} : index === 2 ? {...secondaryOptions} : {...tertiaryOptions};
        updated.selected = {...option};
        var updatedCult = {...selectedCult};
        if (index === 1) {
            setPrimaryOptions(updated);
            updatedCult.primarySkill = updated.selected;
        }
        else if (index === 2) {
            setSecondaryOptions(updated);
            updatedCult.secondarySkill = updated.selected;
        }
        else {
            setTertiaryOptions(updated);
            updatedCult.tertiarySkill = updated.selected;
        }
        props.update(updatedCult, false);
    }
    
    function selectPassion(passion) {
        var updatedCult = {...selectedCult};
        updatedCult.favouredPassion = passion;
        props.update(updatedCult);
    }


    if (selectedCult && selectedCult.skills) {
        return (
            <div style={{width: "100%"}}>
                <div style={{display:"flex", flexWrap: "wrap", backgroundColor: "#cdcdcd", border: "2px solid #2F588A", borderRadius: "10px", padding: "20px 5px"}}>
                    <div style={{marginRight: "10px", marginBottom: "10px"}}>
                        <StartingSkills title="Cult Starting Skills" builder={props.builder} skills={selectedCult.skills} complete={completeStartingSkills} />
                    </div>
                    {selectedCult.weapons ? 
                    <div>
                        <StartingWeapons builder={props.builder} occupation={selectedCult} title="Cult Starting Weapons" complete={completeStartingWeapons} />
                    </div>
                    : 
                    null
                    }
                    <div style={{marginLeft: "10px"}}>
                        <StartingSkills title="Cult Initiate Skills" builder={props.builder} skills={selectedCult.freeSkills} complete={()=> {}}/>
                    </div>
                </div>
                {skillsComplete && weaponsComplete ? 
                    props.builder.occupation.name === "Priest" ?
                    <>
                    <div style={{display:"flex", flexWrap: "wrap", backgroundColor: "#cdcdcd", border: "2px solid #2F588A", borderRadius: "10px", padding: "0px 5px 20px", marginTop: "20px"}}>
                        <div style={{marginRight: "10px", marginBottom: "10px"}}>
                            <div style={{padding: "10px", fontSize: "20px", }}>Primary Cult Skill</div>
                            <OptionsDropdown stats={stats} setLowStats={setLowStats} item={primaryOptions} selected={secondaryOptions.selected ? [secondaryOptions.selected.name] : []} update={selectOption} index={1}/>
                        </div>
                        <div style={{marginRight: "10px", marginBottom: "10px"}}>
                            <div style={{padding: "10px", fontSize: "20px", }}>Secondary Cult Skill</div>
                            <OptionsDropdown stats={stats} setLowStats={setLowStats} item={secondaryOptions} selected={primaryOptions.selected ? [primaryOptions.selected.name] : []} update={selectOption} index={2}/>
                        </div>
                        <div style={{marginBottom: "10px"}}>
                            <div style={{padding: "10px", fontSize: "20px", }}>Tertiary Cult Skill</div>
                            <OptionsDropdown stats={stats} setLowStats={setLowStats} item={tertiaryOptions} selected={[]} update={selectOption} index={3}/>
                        </div>
                    </div>
                    <div>
                        <div style={{padding: "10px", fontSize: "20px", }}>Select a favoured passion (optional)</div>
                        <FavouredPassion passions={selectedCult.passions} selected={props.cult.favouredPassion} selectPassion={selectPassion} update={props.update}/>
                    </div>
                    </> 
                    : 
                    <>
                        <div style={{display:"flex", flexWrap: "wrap", backgroundColor: "#cdcdcd", border: "2px solid #2F588A", borderRadius: "10px", padding: "0px 5px 20px", marginTop: "20px"}}>
                            <div style={{marginRight: "10px", width: "445px"}}>
                                <div style={{padding: "10px", fontSize: "20px", }}>Primary Cult Skill</div>
                                <OptionsDropdown stats={stats} setLowStats={setLowStats} item={primaryOptions} selected={secondaryOptions.selected ? [secondaryOptions.selected.name] : []} update={selectOption} index={1}/>
                            </div>
                            <div style={{width: "445px"}}>
                                <div style={{padding: "10px", fontSize: "20px", }}>Secondary Cult Skill</div>
                                <OptionsDropdown stats={stats} setLowStats={setLowStats} item={secondaryOptions} selected={primaryOptions.selected ? [primaryOptions.selected.name] : []} update={selectOption} index={2}/>
                            </div>
                        </div>
                        <div>
                            <FavouredPassion passions={selectedCult.passions} selected={props.cult.favouredPassion} selectPassion={selectPassion} update={props.update}/>
                        </div>
                    </>
                    : 
                    null
                }
            </div>
        );
    }
    else {
        return null;
    }
}

export default CultSkills;