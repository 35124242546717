import React, { useState, useEffect } from 'react';
import ElementalRunes from './elementalRunes';
import PowerRunes from './powerRunes';
import RuneBonuses from './runeBonuses';
import * as RunesData from '../../../data/runes.json';

const Runes = (props) => {
    const [elementalRunes, setElementalRunes] = useState([]);
    const [powerRunes, setPowerRunes] = useState([]);
    const [formRunes, setFormRunes] = useState();
    const [current, setCurrent] = useState("elemental");
    
    useEffect(() => {
        var elementalRunes = [];
        var powerRunes = [];
        var allRunes = {...RunesData}
        var data = allRunes.data;
        data.forEach(rune => {
            if (rune.category == "Elemental") {
                elementalRunes.push(rune);
            }
            else if (rune.category == "Power" || rune.category == "Form") {
                powerRunes.push(rune);
            }
        });
        setElementalRunes(elementalRunes);
        var powerPairs = [
            {first: {name: "Harmony"}, second: {name: "Disorder"}},
            {first: {name: "Stasis"}, second: {name: "Movement"}},
            {first: {name: "Truth"}, second: {name: "Illusion"}},
            {first: {name: "Fertility"}, second: {name: "Death"}},
            {first: {name: "Man"}, second: {name: "Beast"}},
        ];
        var formPair = {};

        powerRunes.forEach(powerRune => {
            powerPairs.forEach(pair => {
                if (pair.first.name === powerRune.name) {
                    pair.first = {...powerRune, value: 50};
                }
                else if (pair.second.name === powerRune.name) {
                    pair.second = {...powerRune, value: 50};
                }
            })
        });
        setPowerRunes(powerPairs);
        setFormRunes(formPair)
        props.setRuneState("elemental")
    },[]);

    useEffect(() => {
        if (props.builder.runes && props.builder.runes.pointsRemaining == 0) {
            props.disableNext(false);
        }
    },[props.builder]);

    function advance(updatedBuilder) {
        var newValue = "";
        if (current === "elemental") newValue ="power";
        if (current === "power") newValue = "bonuses";
        if (current === "bonuses") props.disableNext(false);
        setCurrent(newValue);
        props.update(updatedBuilder, true);
        props.setRuneState(newValue)
    }

    return (
        <div style={{width: "100%"}}>
            { elementalRunes && current === "elemental" ? 
                <ElementalRunes builder={props.builder} update={advance} elementalRunes = {elementalRunes} />
            : 
            null
            }
            { powerRunes && current === "power" ? 
                <PowerRunes builder={props.builder} update={advance} powerRunes = {powerRunes} formRunes={formRunes} />
            : 
            null
            }
            { current === "bonuses" ? 
                <RuneBonuses builder={props.builder} update={advance} advance={props.advance} powerRunes = {powerRunes} elementalRunes={elementalRunes} />
            : 
            null
            }
        </div>
    );
}

export default Runes;