import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import Abilities from '../Abilities/abilities';
import compareValues from '../../utils/compareValues';
import { withContext } from '../Context/context';

const Skills = (props) => {
    const [allSkills, setAllSkills] = useState();
    const [displaySkills, setDisplaySkills] = useState([]);
    const [sortOrder, setSortOrder] = useState("alphabetical");
    const [knownOnly, showKnownOnly] = useState(false);
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.skills.favourites)
            showKnownOnly(props.providerData.character.configuration.skills.known)
            setSortOrder(props.providerData.character.configuration.skills.sortOrder)
        }
        var skills = [];
        var sorted = props.entity.skills.sort(compareValues('name'));
        sorted = sorted.sort(compareValues('variant'));
        sorted.forEach(skill => {
            if ((!props.pageState.editMode) && skill.value === 0) return;
            if ((props.providerData.character.configuration && props.providerData.character.configuration.skills.known) && (skill.value <= skill.baseValue) && (!props.pageState.editMode)) {
                return;
            }
            if (props.providerData.character.configuration && props.providerData.character.configuration.skills.favourites && (!skill.favourite) && (!props.pageState.editMode)) {
                return;
            }
            skills.push({...skill});
        })
        setDisplaySkills(skills);
    },[props.providerData.character.configuration, props.entity.skills, props.pageState.editMode, sortOrder, knownOnly, favouritesOnly]);

    useEffect(() => {
        var sorted = props.entity.skills.sort(compareValues('name'));
        sorted = sorted.sort(compareValues('variant'));
        setAllSkills(sorted);
    },[props.entity.skills]);

    function handleValueChange(value, item) {
        var updatedCharacter = {...props.providerData.character};
        updatedCharacter.skills.forEach(skill => {
            if (skill.name === item.name) {
                skill.value = value;
                return;
            }
        });
        props.update(updatedCharacter);
    }

    if (!allSkills) return null;
        return(
            <Abilities diceRoller={props.diceRoller} style={{...props.style}} title={props.title} displayItem={props.displayItem} entity={props.entity} tile={props.tile} columnCount={props.columnCount} displayItems={displaySkills} type="Skill" items={allSkills} processResult={props.processResult} update={props.update} handleValueChange={handleValueChange} ></Abilities>
        );
    }

    export default withContext(withPageState(Skills));