import React from 'react';

const OptionsSingle = (props) => {
    
    return (
        <div key={props.index} style={{display:"flex", height: "30px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
            <div style={{display: "flex", margin: "0px 10px"}}>
                <div style={{display:"flex", minWidth: "320px", alignItems:"center"}}>
                    <div>{props.item.options[0].name}{props.item.options[0].variant ? " (" + props.item.options[0].variant + ")" : ""}</div>
                </div>
                {props.item.value ? 
                    <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>({props.item.value})</div>
                : 
                    <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{props.item.bonus}</div>
                }
            </div>
        </div>
    );
}

export default OptionsSingle;