import React, { useState, useEffect} from 'react';
import {withContext} from '../Context/context';
import { Button, Checkbox, Dialog } from '@mui/material';
import { calculateDamageBonus } from '../../services/attributeService';
import { getCategoryModifiers } from '../../services/skillsService';
import { calculateStrikeRanks } from '../../services/attributeService';
import { calculateHitPoints } from '../../services/hitPointsService';
import { calculateMaxEncumbrance } from '../../services/attributeService';
import { calculateSpiritCombatDamage } from '../../services/attributeService';
import { isEqual } from 'lodash';
import * as SAVE from '../../constants/save';
import AddEnchantmentDialog from './addEnchantmentDialog';
import DialogBox from '../Dialogs/dialogBox';

const EnchantmentsDialog = (props) => {
    const [enchantments, setEnchantments] = React.useState();
    const [selectAll, setSelectAll] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        var enchantments = [...props.enchantments];
        for(const element of enchantments) {
            element.valid = true;
        } 
        setEnchantments(enchantments)
    }, [props.enchantments]);

    function handleOkClick(event) {
        var updates = [];
        var updatedEnchantments = [];
        var updatedCharacteristics = {...props.providerData.character.characteristics};
        var statsUpdated = false;

        enchantments.forEach(selection => {
            if (selection.valid ) {
                updatedEnchantments.push({...selection});
            }
            else {
                selection.effects.forEach(effect => {
                    var statName = effect.item.id
                    if (statName && effect.name === "statModifier") {
                        statsUpdated = true;
                        var index = updatedCharacteristics[statName].enchantments.findIndex(item => item.name === selection.name);
                        if (index > -1) {
                            updatedCharacteristics[statName].enchantmentBonus -= effect.value;
                            updatedCharacteristics[statName].enchantments.splice(index, 1);
                        }
                    }
                    if (statName && effect.name === "statMultiplier") {
                        statsUpdated = true;
                        var index = updatedCharacteristics[statName].enchantments.findIndex(item => item.name === selection.name);
                        if (index > -1) {
                            updatedCharacteristics[statName].enchantmentBonus -= (updatedCharacteristics[statName].baseValue * (effect.value - 1));
                            updatedCharacteristics[statName].enchantments.splice(index, 1);
                        }
                    }
                    if (effect.name === "addWeapon") {
                        var updatedWeapons = [...props.providerData.character.weapons];
                        var index = updatedWeapons.findIndex(obj => obj.id === effect.item.id);
                        updatedWeapons[index].hidden = true;
                        updates.push({fieldName: "weapons", value: updatedWeapons})
                    }
                })
            }
        });
        updates.push({fieldName: "enchantments", value: updatedEnchantments})

        if (statsUpdated) {
            updates.push({fieldName: "characteristics", value: updatedCharacteristics});

            var newDamageBonus = calculateDamageBonus(updatedCharacteristics)
            if (! isEqual(props.providerData.character.damageBonus, newDamageBonus)) {
                updates.push({fieldName: "damageBonus", value: newDamageBonus})
            }

            var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
            if (! isEqual(props.providerData.character.categoryModifiers, updatedModifiers)) {
                updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
            }

            var updatedHitPoints = calculateHitPoints(updatedCharacteristics, props.providerData.character.hitPoints);
            if (! isEqual(props.providerData.character.hitPoints, updatedHitPoints)) {
                updates.push({fieldName: "hitPoints", value: updatedHitPoints})
            }

            var updatedStrikeRanks = calculateStrikeRanks(updatedCharacteristics);
            if (! isEqual(props.providerData.character.strikeRanks, updatedStrikeRanks)) {
                updates.push({fieldName: "strikeRanks", value: updatedStrikeRanks})
            }

            var updatedSpiritCombat = {...props.providerData.character.spiritCombat};
            updatedSpiritCombat.damage = calculateSpiritCombatDamage(updatedCharacteristics);
            if (! isEqual(props.providerData.character.spiritCombat, updatedSpiritCombat)) {
                updates.push({fieldName: "spiritCombat", value: updatedSpiritCombat})
            }
            
            var updatedEncumbrance = calculateMaxEncumbrance(updatedCharacteristics);
            if (! isEqual(props.providerData.character.maxEnc, updatedEncumbrance)) {
                updates.push({fieldName: "maxEnc", value: updatedEncumbrance});
            }
    }

        props.close();
        props.update(updates, SAVE.YES);
    }

    function handleCancelClick() {
        props.close();
    }

    function handleCheck(event, index) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments[index].valid = event.target.checked;
        setEnchantments(updatedEnchantments)
        
    }

    function displayEnchantment(item, index) {
        var target = item.target;
        if (["skill", "passion", "rune", "weapon"].indexOf(item.class) > -1 ) {
            var key = props.providerData.character[item.class + "s"].findIndex(obj => obj.id === item.target);
            if (key > -1) {
                var details = props.providerData.character[item.class + "s"][key];
                target = details.name + (details.variant ? " " + details.variant : "");
            }
        }
        return (
            <div key={index} style={{display:"flex", alignItems: "center"}}>
                <div style={{width:"20%"}}>
                    <Checkbox
                        checked={item.valid}
                        color="default"
                        onClick={(event) => handleCheck(event, index)}
                    />
                </div>
                <div style={{width:"40%"}}>
                    {item.name}
                </div>
                <div style={{width:"40%"}}>
                    {item.target}
                    {/* {(item.itemName || "Character") + (item.target === "Skill" ? " skill" : item.target === "Passion" ? " passion" : item.target === "Rune" ? " rune" : item.target === "Characteristic" ? " characteristic" : "")} */}
                </div>
            </div>
        )
    }

    function selectAllEnchantments(event) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments && updatedEnchantments.forEach(enchantment => {
            enchantment.valid = event.target.checked
        });
        setEnchantments(updatedEnchantments);
        setSelectAll(event.target.checked);
    }

    function addEnchantment(newEnchantment, updatedCharacteristics, updatedModifiers) {
        var updatedEnchantments = [...enchantments]
        updatedEnchantments.push(newEnchantment);
        var updates = [{fieldName: "enchantments", value: updatedEnchantments}];
        if (updatedCharacteristics) {
            updates.push({fieldName: "characteristics", value: updatedCharacteristics});
        }
        if (updatedModifiers) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers})
        }
        props.update(updates, SAVE.YES)
    }

    if (enchantments) {
        return (
            <div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px", backgroundColor:"#cdcdcd"}}>
                <div style={{position:"relative", height: "100%", display: "flex", flexDirection:"column", justifyContent: "space-between", padding: "10px"}}>
                {enchantments && enchantments.length > 0 ? 

                    <div>
                        <div style={{padding: "20px"}}>To remove an enchantment from your character, deselect it from the list below and click ok.</div>
                        <div style={{display:"flex", alignItems:"center"}}>
                            <Checkbox checked={selectAll} onClick={selectAllEnchantments}
                            />
                            <div>Select/Deselect all</div>
                        </div>
                        <div style={{fontWeight: "bold", display: "flex", alignItems: "center"}}>
                            <div style={{width: "20%"}}></div>
                            <div style={{width: "40%"}}>Enchantment name</div>
                            <div style={{width: "40%"}}>Target</div>
                        </div>
                        <div style={{marginBottom: "50px"}}>
                            {enchantments.map((item, index) => {
                                return (displayEnchantment(item, index))
                            })}
                        </div>
                    </div>
                    : 
                        <div>Enchantments added automatically by spells will appear here. Alternatively you can add an enchantment to your character manually by clicking the button below</div>
                    }
                </div>
                <hr style={{marginTop: "30px"}} />
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px"}}>
                    <Button color="primary" variant='contained' onClick={() => setOpen(true)}>Add Enchantment</Button>
                    <div style={{display: "flex", flexDirection: "row-reverse", alignItems: "center"}}>
                        <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
                        <Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"7px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
                    </div>
                </div>
                <DialogBox isOpen={open} handleClose={() => setOpen(false)} width="600px" title="Add Enchantment">
                    <AddEnchantmentDialog character={props.providerData.character} update={addEnchantment} close={() => setOpen(false)} />
                </DialogBox>

            </div>
        );
    }
    else {
        return null;
    }
}

export default withContext(EnchantmentsDialog)