import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { Button } from '@mui/material';
import deleteIcon from '../../icons/delete-icon-red.svg'
import MultiSelect from '../Controls/multiSelect';
import { getAllSkills, getSkillByName } from '../../services/dataService';
import EditBox from '../Controls/editBoxSingle';

const EditSkillsDialog = (props) => {
    
    const [skills, setSkills] = useState([]);
    const [skillItems, setSkillItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [variableSkills, setVariableSkills] = useState(false);
    const [bonus, setBonus] = useState(10)

    useEffect(() => {
        let existing = [];
        props.selected.forEach(item => {
            let skill = item.options[0];
            let existingSkill = {name: skill.name, parentId: skill.parentId}
            if (skill.variant) {
                existingSkill.variant = skill.variant;
                existingSkill.variable = true;
            };
            existingSkill.bonus = item.bonus || 0;
            existing.push(existingSkill);
        });
        setSelected([...props.selected]);
    }, [props.selected])
    
    const handleOkClick = () => {
        let updatedSelected = [...selected];
        updatedSelected.forEach(item => {
            if (item.options) {
                item.options.forEach(option => {
                    if (option.variable) {
                        delete option.variable;
                    }
                })
            }
        })
        props.update(updatedSelected)
        props.close();
}

	function getNames(items) {
		let names = [];
        if (items && items.length > 0) {
            items.forEach(item => {
                names.push(item.name + (item.variant ? " (" + item.variant + ")" : ""));
            })
        }
		return names;
	}

    function addBonus() {
        let updatedSelected = [...selected];
        let startingSkill = {bonus: bonus, options: []};
        skillItems.forEach(item => {
            startingSkill.options.push(item)
        })
        if (skills.length > 1) {
            startingSkill.selected = {};
        }
        updatedSelected.push(startingSkill);
        setSelected(updatedSelected);
        setSkills([]);
        setSkillItems([]);
    }

    function deleteSkill(index) {
        var updatedSelected = [...selected]
        updatedSelected.splice(index, 1);
        setSelected(updatedSelected)
    }

    function getSkillNames(skills) {
        if (skills && skills.options) {
            if (skills.options.length === 1) {
                return getNames(skills.options);
            }
            else {
                return "Select one of (" + getNames(skills.options).join(', ') + ")"
            }
        }
    }

    function setVariant(skill, value) {
        let updatedSkillItems = [...skillItems];
        let index = updatedSkillItems.findIndex(obj => obj.name === skill.name);
        if (index > -1) {
            updatedSkillItems[index].variant = value;
        }
        setSkillItems(updatedSkillItems)
    }

    function updateSkills(values) {
        setSkills(values);
        let skillItems = [];
        values.forEach(skillName => {
            let selectedSkill = getSkillByName(skillName, props.customContent)
            if (selectedSkill) {
                let skill = {name: skillName, parentId: selectedSkill.id}
                if (selectedSkill.variable) {
                    setVariableSkills(true);
                    skill.variable = true;
                }
                skillItems.push(skill)
            }
        })
        setSkillItems(skillItems);
    }

    function displaySpecializations() {
        let variableSkills = [];
        skillItems.forEach(skill => {
            if (skill.variable) {
                variableSkills.push(skill)
            }
        });
        return (
            <div>
                {variableSkills.map((skill,index) => {
                    return (
                        <div key={index} className={globalStyles.contentLine} style={{marginBottom: "10px"}}>
                            <div style={{width: "128px"}}>{skill.name}</div>
                            <EditBox height="24px" width="200px" onChange={(value) => setVariant(skill, value)} />
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={{height: "560px"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                <div style={{padding: "10px"}}>
                    <div>
                        <div className={globalStyles.contentLine} style={{justifyContent: "space-between"}}>
                            <div className={globalStyles.contentLine}>
                                <div style={{width: "120px"}}>Select skill(s):</div>
                                <MultiSelect style={{width: "360px"}} values={getNames(getAllSkills(props.customContent))} selectedValues={skills} onChange={updateSkills} />
                            </div>
                            <div className={globalStyles.contentLine}>
                                <div style={{margin: "0px 20px 0px 40px"}}>Bonus:</div>
                                <EditBox height="24px" width="60px" style={{height: "24px"}} number value={bonus} onChange={setBonus} />
                            </div>
                            <div style={{marginLeft: "10px"}}><Button disabled={skills.length === 0} style={{width: "74px"}} onClick={addBonus} variant='contained' color='primary'>Add</Button></div>
                        </div>
                    </div>
                    {variableSkills ? 
                    <>
                        <div>
                            <div>{displaySpecializations()}</div>
                        </div>
                        {variableSkills ? 
                            <div style={{display: "flex", flexDirection: "row-reverse"}}><Button disabled={skills.length === 0} onClick={addBonus} variant='contained' color='primary'>Add</Button></div>
                        : 
                        null}
                    </>
                    : 
                    null}
                    <hr style={{margin: "10px 0px"}} />
                    <div>
                        <div style={{fontWeight: "bold"}}>Cult Starting Skills</div>
                        <div className={globalStyles.contentLine} style={{marginTop: "20px", fontWeight: "bold"}}>
                            <div style={{width: "600px"}}>Skill options</div>
                            <div style={{width: "100px", textAlign: "center"}}>Bonus</div>
                        </div>
                        {
                            selected.map((skillOptions, index) => {
                                return (
                                    <div className={globalStyles.contentLine}>
                                        <div style={{width: "600px"}}>{getSkillNames(skillOptions)}</div>
                                        <div style={{width: "100px", textAlign: "center"}}>{skillOptions.bonus}</div>
                                        <div style={{marginRight: "20px", cursor: "pointer"}} onClick={() => deleteSkill(index)}><img src={deleteIcon} style={{height: "20px"}} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end", borderTop: "2px solid #2f588a"}}>
                    <Button onClick={handleOkClick} style={{minWidth: "100px"}} color="primary" variant="contained">OK</Button>
                    <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "40px"}} >Cancel</Button>
				</div>
            </div>
        </div>
    )
}

export default EditSkillsDialog;