import React from 'react';
import globalStyles from '../Styles/styles.module.css'
import PolyhedronIcon from '../../icons/die_icon.svg';

export default function DicePoolButton(props) {

    return (
        <div onClick={props.displayPanel} className={globalStyles.floatingButton}>
            <img style={{width:"40px"}} src={PolyhedronIcon} title="Roll some dice"/>
        </div>
    );
}