import React from 'react';
import {withContext} from '../Context/context';

const SlashingDamageButton = (props) => {

    function calculateSpecialDice(damage) {
        var dicePool = [];
        var dice = {...damage};
        dice.quantity *= 2;
        dice.modifier *= 2;
        dicePool.push(dice);
        if (props.entity.damageBonus) {
            var dmgBonus = {...props.entity.damageBonus};
            dmgBonus.dieType *= props.weapon.damage.bonus;
            dicePool.push(dmgBonus);
        }
        return dicePool;
    }

    function calculateCriticalDice(damage) {
        var dicePool = [];
        var dice = {dieType: 0, quantity: 0, modifier: 0};
        dice.modifier = ((damage.dieType * damage.quantity) + damage.modifier) *2;
        dicePool.push(dice);
        if (props.entity.damageBonus) {
            var dmgBonus = {...props.entity.damageBonus};
            dmgBonus.dieType *= props.weapon.damage.bonus;
            dicePool.push(dmgBonus);
        }
        return dicePool;
    }

    return (
            React.cloneElement(props.children, {...props, calculateSpecialDice, calculateCriticalDice})
    )
}

export default withContext(SlashingDamageButton)