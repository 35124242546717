import React, {useState, useEffect } from 'react';
import { withContext } from '../../Context/context';
import { withPageState } from '../../Context/PageState';
import GoldCoin from '../../../images/gold-coin.png'
import SilverCoin from '../../../images/silver-coin.png'
import CopperCoin from '../../../images/copper-coin.png'
import EditBox from '../../Controls/editBoxSingle';
import * as SAVE from '../../../constants/save';

const Wealth = (props) => {

    const [open, setOpen] = useState(false);
    const [wealth, setWealth] = useState(props.providerData.character.wealth)

    useEffect(() => {
        setWealth({...props.providerData.character.wealth});
    },[props.providerData.character.wealth])

    const updateWealth = (type, value) => {
        var updatedWealth = {...wealth};
        updatedWealth[type] = value;
        setWealth(updatedWealth);
        props.update([{fieldName: "wealth", value: updatedWealth}], SAVE.YES)
    }

    return (
        <div style={{position: "relative"}}>
            <div style={{justifyContent: "center", display:"flex", alignItems: "center", cursor: "pointer", padding:"5px", fontSize:"14px"}} onClick={() => setOpen(!open)}>
                <div style={{display:"flex", alignItems: "center"}}>
                    <img src={GoldCoin} style={{width: "24px"}} />
                    <div>{wealth.gold || 0}W</div>
                </div>
                <div style={{display:"flex", alignItems: "center"}}>
                    <img src={SilverCoin} style={{width: "24px"}} />
                    <div>{wealth.silver || 0}L</div>
                </div>
                <div style={{display:"flex", alignItems: "center"}}>
                    <img src={CopperCoin} style={{width: "24px"}} />
                    <div>{wealth.copper || 0}C</div>
                </div>
            </div>
                {open ? 
                    <div style={{backgroundColor: "#fff", padding: "5px", position: "absolute", top: 30, right: 0, zIndex: 99, color: "#121312", border: "2px solid #2f588a", borderRadius: "5px"}}>
                        <div style={{display:"flex", alignItems: "center", margin: "5px"}}>
                            <img src={GoldCoin} style={{width: "28px", marginRight: "10px"}} onClick={() => setOpen(!open)} />
                            <EditBox number value={wealth.gold} onChange={(value) => {updateWealth("gold", value)}} size="sm" width="60px" />
                            <div style={{marginLeft: "10px"}} onClick={() => setOpen(!open)}>wheels</div>
                        </div>
                        <div style={{display:"flex", alignItems: "center", margin: "5px"}}>
                            <img src={SilverCoin} style={{width: "28px", marginRight: "10px"}} onClick={() => setOpen(!open)} />
                            <EditBox number value={wealth.silver} onChange={(value) => {updateWealth("silver", value)}} size="sm" width="60px" />
                            <div style={{marginLeft: "10px"}} onClick={() => setOpen(!open)}>lunars</div>
                        </div>
                        <div style={{display:"flex", alignItems: "center", margin: "5px"}}>
                            <img src={CopperCoin} style={{width: "28px", marginRight: "10px"}} onClick={() => setOpen(!open)} />
                            <EditBox number value={wealth.copper} onChange={(value) => {updateWealth("copper", value)}} size="sm" width="60px" />
                            <div style={{marginLeft: "10px"}} onClick={() => setOpen(!open)}>clacks</div>
                        </div>
                    </div>
                : 
                null
                }
        </div>
    )
}

export default withPageState(withContext(Wealth));