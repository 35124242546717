import React, { useState, useEffect } from 'react';
import { withPageState } from '../../Context/PageState';
import Abilities from '../../Abilities/abilities';
import { withContext } from '../../Context/context';
import AddPassionDialog from "../dialogs/addPassionDialog";
import * as SAVE from '../../../constants/save'
import PanelHeader from '../../Controls/panelHeader';

const Passions = (props) => {
    const [allPassions, setAllPassions] = useState();
    const [displayPassions, setDisplayPassions] = useState();
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.passions.favourites)
        }
        var passions = [];
        props.providerData.character.passions.forEach(passion => {
            if (props.providerData.character.configuration && props.providerData.character.configuration.passions.favourites && (!passion.favourite)) {
                return;
            }
            passions.push({...passion});
        })
        setDisplayPassions(passions);
    },[props.providerData.character.configuration, props.pageState.editMode, props.providerData.character.passions]);

    useEffect(() => {
        setAllPassions(props.providerData.character.passions);
    },[props.providerData.character]);

    const addPassion = (newPassion) => {
        var currentPassions = [...props.providerData.character.passions]
        var index = currentPassions.findIndex(obj => obj.parentId === newPassion.parentId && (!newPassion.variant || (obj.variant && obj.variant.toLowerCase() === newPassion.variant.toLowerCase())))
        if (index < 0) {
            currentPassions.push(newPassion);
            props.update([{fieldName: "passions", value: currentPassions}], SAVE.NO)
        }
    }

    if (!allPassions) return null;
    return(
        <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginTop: "10px"}}>
            <PanelHeader title="Passions" addTitle="Add passion" addItem={addPassion}>
                <AddPassionDialog />
            </PanelHeader>
            <Abilities diceRoller={props.diceRoller} style={{...props.style}} title={props.title} columnCount={props.columnCount} entity={props.entity} displayItem={props.displayItem} display={props.display} tile={props.tile} favouritesOnly={favouritesOnly} displayItems={displayPassions} context={props.context} type="Passion" items={allPassions} processResult={props.processResult} update={props.update} ></Abilities>
        </div>
        );
}

    export default withContext(withPageState(Passions));