import React, {useState, useEffect} from "react";
import globalStyles from '../../Styles/styles.module.css';
import DamageButton from '../../Buttons/damageButton';
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import EditBox from "../../Controls/editBoxSingle";
import WeaponButton from "../../Combat/weaponButton";
import { strikeRankEnchantments } from "../../../services/enchantmentsService";
import calculateSR from "../../../services/strikeRankService";

const WeaponRow = (props) => {

    const [strikeRank, setStrikeRank] = useState(0);

    useEffect(() => {
        var sr = props.item.weapon.sr + calculateSR("DEX", props.entity);
        if (props.item.weapon.class !== "Missile") {
            sr += calculateSR("SIZ", props.entity);
        }
        setStrikeRank(sr)  
    },[props.entity, props.item]);

    function displayItem() {
        props.displayItem({type: "Weapon", item: props.item})
    }

    if (props.pageState.editMode) {
        return (
            <button style={{height: "80px", cursor:"default"}} disabled={props.disabled} className={props.disabled ? globalStyles.disabledButton : globalStyles.tile} onClick={props.rollDice}>
                <div style={{height: "64px", color: "#fff", borderRadius: "5px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "108px", padding: "4px 0px"}}>
                    <div style={{width: "100%"}}>
                        <div>{props.item.weapon.title}</div>
                    </div>
                    <div style={{display:"flex", alignItems: "center", fontWeight:700}}>
                        <EditBox onChange={(value) => props.handleValueChange(value)} value={props.item.skill.value} number>{props.item.skill.value}</EditBox>
                    </div>
                </div>
            </button>
        )
    }
    else {
        return(
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", padding: "2px"}} onClick={displayItem} >
                <div id={props.item.weapon.name} style={{width:"120px", marginLeft: "5px"}}>{props.item.weapon.name}</div>
                <div style={{width:"30px", textAlign:"center"}}>{strikeRank + strikeRankEnchantments(props.entity)}</div>
                <div style={{display:"flex",justifyContent:"center", alignItems:"center", width: "80px"}}>
                        <div className={globalStyles.diceButton} style={{padding: "2px 10px", cursor: "pointer"}}>
                        <WeaponButton diceRoller={props.diceRoller} entity={props.entity} augmenting={props.providerData.augmenting} type="Attack" weapon title={"" } disabled={props.item.skill.value === 0} processResult={props.processResult} item={props.item}>
                            <ButtonValue />
                        </WeaponButton>
                        </div>
                    </div>
    
                <div style={{width:"80px", display:"flex", justifyContent:"center"}}>
                    <div className={globalStyles.damageButton}>
                        <DamageButton diceRoller={props.diceRoller} noButton entity={props.entity} size="medium" processDamage={props.processDamage} weapon={props.item.weapon} style={{width: "80px", cursor: "pointer", border: "1px solid #851113", borderRadius: "3px", padding: "2px", textAlign:"center"}}/>
                    </div>
                </div>
            </div>
        );
    }

    function ButtonValue(props) {
        return (
            <div >{props.target || 0 + "%"}</div>
        )
    }
}

export default withPageState(withContext(WeaponRow));