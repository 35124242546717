import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../Firebase';
import { withContext } from '../../Context/context';
import globalStyles from '../../Styles/styles.module.css';
import { Button } from '@mui/material';
import ArmourList from '../../Lists/armourList';
import * as armourData from '../../../data/armour.json';

const types = ["Head", "Arms", "Chest", "Abdomen & Chest", "Abdomen", "Abdomen & Legs", "Legs"];

const AddArmourDialog = (props) => {

    const [selected, setSelected] = React.useState([]);
    const [allArmour, setAllArmour] = useState('');
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        var armour = [];
        const allArmour = {...armourData};
        allArmour.data.forEach(armourItem => {
            armour.push(armourItem);
        });
        setAllArmour(armour);
    }, []);

	useEffect(() => {
        if (props.currentArmour) {
            var existing = [];
            props.currentArmour.forEach(armourItem => {
                existing.push(armourItem.name);
            })
            setSelected(existing);
        }
	},[props.currentArmour])

    function selectArmour(armourItem, checked) {
        var updatedSelected = [...selected];
        if (checked) {
            updatedSelected.push(armourItem.name);
        }
        else {
            var index = updatedSelected.findIndex(obj => obj.id === armourItem.id)
            updatedSelected.splice(index, 1);
        }
        setSelected(updatedSelected);
    };

    function handleOkClick(){
        props.close();
    }

    function addArmour(armourItem) {
        props.addItem({equipped: true, ...armourItem});
    }

    return (
        <div>
            <div className={globalStyles.panel}  style={{height: "400px", overflow: "auto"}}>
                <ArmourList allArmour={allArmour} select={selectArmour} selected={selected} types={types} noSelect add={addArmour} setRestricted={setRestricted} />
            </div>
            <div style={{height: "80px", display:"flex", justifyContent: "space-between", flexDirection: "row-reverse", alignItems: "center", borderTop: "2px solid #2f588a", backgroundColor: "#cdcdcd"}}>
                <div style={{marginRight: "20px"}}>
                    <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Close</Button>
                </div>
                {
                    restricted ? 
                        <div style={{marginLeft: "20px", color: "#851113"}}>** Insufficient STR and/or DEX</div>
                    : 
                    null
                }                    
            </div>
        </div>
    );
}

export default withContext(withFirebase(AddArmourDialog));    