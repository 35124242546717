import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import Abilities from '../Abilities/abilities';
import { withContext } from '../Context/context';

const Passions = (props) => {
    const [open, setOpen] = useState(false);
    const [allPassions, setAllPassions] = useState();
    const [displayPassions, setDisplayPassions] = useState();
    const [favouritesOnly, showFavouritesOnly] = useState(false);

    useEffect(() => {
        if (props.providerData.character.configuration) {
            showFavouritesOnly(props.providerData.character.configuration.passions.favourites)
        }
        var passions = [];
        props.providerData.character.passions.forEach(passion => {
            if (props.providerData.character.configuration && props.providerData.character.configuration.passions.favourites && (!passion.favourite)) {
                return;
            }
            passions.push({...passion});
        })
        setDisplayPassions(passions);
    },[props.providerData.character.configuration]);

    useEffect(() => {
        setAllPassions(props.providerData.character.passions);
    },[props.providerData.character]);

    function handleValueChange(value, item) {
        var updatedCharacter = {...props.providerData.character};
        updatedCharacter.passions.forEach(passion => {
            if (passion.id === item.id) {
                passion.value = value;
                return;
            }
        });
        props.update(updatedCharacter);
    }

    function newSkill(selectedPassion) {
        var updatedCharacter = {...props.providerData.character};
        updatedCharacter.passions.push(selectedPassion);
        props.update(updatedCharacter);
        setOpen(false);
    }

    if (!allPassions) return null;
    return(
        <Abilities diceRoller={props.diceRoller} style={{...props.style}} title={props.title} columnCount={props.columnCount} entity={props.entity} displayItem={props.displayItem} display={props.display} tile={props.tile} favouritesOnly={favouritesOnly} displayItems={displayPassions} context={props.context} type="Passion" items={allPassions} processResult={props.processResult} update={props.update} handleValueChange={handleValueChange} ></Abilities>
        );
}

    export default withContext(withPageState(Passions));    