
export function strikeRankEnchantments(character) {
    var value = 0;
    if (character.enchantments) {
        character.enchantments.forEach(enchantment => {
            enchantment.effects && enchantment.effects.forEach(effect => {
                if (effect.name  === "strikeRankModifier"){
                    value += effect.value;
                }
            })
        });
    }
    return value;
}

export function statEnchantments(character, statName) {
    var value = 0;
    if (character.enchantments) {
        character.enchantments.forEach(enchantment => {
            if (enchantment.statModifier && enchantment.target === "Character" && enchantment.statName === statName) {
                value += enchantment.statModifier;
            }
        });
    }
    return value;
}

export function formatEnchantment(character, spellDetails, effects) {

    var enchantment = {
        name: spellDetails.spell.name + ((spellDetails.spell.variable || spellDetails.spell.stackable) ? " " + spellDetails.points : ""),
        points: spellDetails.points,
        spell: spellDetails.spell.name,
        valid: true,
        effects: [],
    };
    if (spellDetails.selection) {
        enchantment.target = spellDetails.selection.title ? spellDetails.selection.title : spellDetails.selection.name;
    }
    else {
        enchantment.target = "Character"
    }
    if (spellDetails.spell.target && spellDetails.spell.target.filter) {
        enchantment.filter = spellDetails.spell.target.filter;
    }

    effects.forEach(effect => {
        var enchantmentEffect = {name: effect.name};
        if (spellDetails.selection) {
            enchantmentEffect.item = {id: spellDetails.selection.id};
        }
        if (effect.weaponTypes) {
            enchantmentEffect.weaponTypes = [...effect.weaponTypes];
        }
        var pointsMultiplier = 1;
        if (effect.ordinality.id === "perMagicPoint" && spellDetails.runeSpellMagicPoints) {
            pointsMultiplier = spellDetails.runeSpellMagicPoints;
        }
        else if (["perMagicPoint", "perRunePoint"].indexOf(effect.ordinality.id) > -1) {
            pointsMultiplier = spellDetails.points;
        }

        let index = -1;
        switch (effect.name) {
            case "parryModifier": 
            case "parryMultiplier": 
            case "attackModifier": 
            case "attackMultiplier": 
            case "damageMultiplier": 
            case "defenseModifier": 
            case "moveMultiplier": 
            case "moveModifier": 
            case "armourModifier":
            case "spiritArmourModifier":
                enchantmentEffect.value = effect.value * pointsMultiplier;
                break; 
            case "condition": 
                enchantmentEffect.condition = effect.value;
                break;
            case "damage": 
            case "damageModifier": 
                enchantmentEffect.value = {dieType: effect.value.dieType, quantity: effect.value.quantity * pointsMultiplier, modifier: effect.value.modifier * pointsMultiplier};
                break;
            case "strikeRankModifier": 
                enchantmentEffect.value = effect.value * pointsMultiplier;
                enchantmentEffect.item = effect.item;
                break;
            case "statModifier":
                enchantmentEffect.value = effect.value * pointsMultiplier;
                enchantmentEffect.item = effect.item;
                break;
            case "statMultiplier":
                enchantmentEffect.value = effect.value * pointsMultiplier;
                enchantmentEffect.item = effect.item;
                break;
            case "skillMultiplier": 
                enchantmentEffect.value = effect.value * pointsMultiplier;
                if (effect.item.variant) {
                    index = character.skills.findIndex(obj => (obj.parentId === effect.item.id) && (obj.variant.toLowerCase() === effect.item.variant.toLowerCase()));
                }
                else {
                    index = character.skills.findIndex(obj => obj.parentId === effect.item.id);
                }
                if (index > -1) {
                    enchantmentEffect.item = {id: character.skills[index].id};
                }
                else {
                    enchantmentEffect.item = {id: "Not found"};
                }
                break;
            case "skillModifier": 
                enchantmentEffect.value = effect.value * pointsMultiplier;
                if (effect.item.variant) {
                    index = character.skills.findIndex(obj => (obj.parentId === effect.item.id) && (obj.variant.toLowerCase() === effect.item.variant.toLowerCase()));
                }
                else {
                    index = character.skills.findIndex(obj => obj.parentId === effect.item.id);
                }
                if (index > -1) {
                    enchantmentEffect.item = {id: character.skills[index].id};
                }
                else {
                    enchantmentEffect.item = {id: "Not found"};
                }
                break;
            case "addWeapon": 
                index = character.weapons.findIndex(obj => (obj.parentId === effect.item.id));
                if (index > -1) {
                    enchantmentEffect.item = {id: character.weapons[index].id};
                }
                else {}
                break;
            default:
                break;
            }
            enchantment.effects.push(enchantmentEffect);

            // if (spellDetails.spell.target && spellDetails.spell.target.weaponTypes) {
            //     var filteredWeapons = character.weapons.filter(function(obj) {
            //         return (spellDetails.spell.target.weaponTypes.indexOf(obj.category) > -1);
            //     });
            //     filteredWeapons.forEach(weapon => {
            //         enchantment.effects.push({...enchantmentEffect, item: {id: weapon.id}});
            //     })
            // }
            // else {
            //     enchantment.effects.push(enchantmentEffect);
            // }
    });
    return enchantment;
}

export function getEnchantmentsModifier(type, entity, item, value) {
    var modifier = 0;
    if (type && entity?.enchantments) {
        entity.enchantments.forEach(enchantment => {
            enchantment.effects.forEach(effect => {
                if (effect.item) {
                    if (effect.item.id === item.id || (type === "stat" && effect.item.id === item.name)) {
                        var fieldName = type.toLowerCase() + "Multiplier";
                        if (effect.name === fieldName) {
                            var newValue = Math.floor(value * effect.value);
                            modifier = newValue - value;
                        }
                    }
                }
                else if (enchantment.target === "Character") {
                    if (effect.name === "skillMultiplier" && type === "Skill" || effect.name === "passionMultiplier" && type === "Passion" || effect.name === "runeMultiplier" && type === "Rune" || (effect.name === "attackMultiplier" && type === "attack") || (effect.name === "parryMultiplier" && type === "parry")) {
                        var newValue = Math.floor(value * effect.value);
                        modifier = newValue - value;
                    }
                }
            });
        });
        entity.enchantments.forEach(enchantment => {
            enchantment.effects.forEach(effect => {
                if (effect.item) {
                    if (effect.item.id === item.id) {
                        var fieldName = type.toLowerCase() + "Modifier";
                        if (effect.name === fieldName) {
                            modifier += (effect.value || 0);
                        }
                    }
                }
                else if (enchantment.target === "Character") {
                    if (effect.name === "skillModifier" && type === "Skill" || effect.name === "attackModifier" && type === "attack" || effect.name === "parryModifier" && type === "parry") {
                        modifier += (effect.value || 0);
                    }
                    else if (effect.name === "passionModifier" && type === "Passion") {
                        modifier += (effect.value || 0);
                    }
                    else if (effect.name === "runeModifier" && type === "Rune") {
                        modifier += (effect.value || 0);
                    }
                }
            });
        });
    }
    return modifier;
}