import React, {useState, useEffect} from "react";
import globalStyles from '../../Styles/styles.module.css';
import styles from '../character.module.css';
import MagicIcon from '../../../icons/magic-icon.svg';
import NoMagicIcon from '../../../icons/no-magic-icon.svg'
import { FormControl, Checkbox } from '@mui/material';
import DamageButton from '../../Buttons/damageButton';
import WeaponButton from "../../Combat/weaponButton";
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import EditBox from "../../Controls/editBoxSingle";
import * as SAVE from '../../../constants/save';
import calculateSR from "../../../services/strikeRankService";

const WeaponRow = (props) => {

    const [strikeRank, setStrikeRank] = useState(0);
    const [checked, setChecked] = useState(props.item.weapon.equipped);
    const [enchanted, setEnchanted] = useState(false);

    useEffect(() => {
        var sr = calculateSR("DEX", props.entity) + props.item.weapon.sr;
        if (props.item.weapon.class !== "Missile") {
            sr += calculateSR("SIZ", props.entity)
        }
        setStrikeRank(sr);
        var result = false;
        props.entity.enchantments.forEach(enchantment => {
            enchantment.effects.forEach(effect => {
                if (effect.item) {
                    if (effect.item.id === props.item.weapon.id) {
                        result = true;
                    }
                }
                else if (effect.weaponTypes) {
                    if (effect.weaponTypes.indexOf(props.item.weapon.type) > -1) {
                        result = true;
                    }
                }
            })
        });
        setEnchanted(result)
    },[props.entity, props.item]);

    function displayItem() {
        props.displayItem({type: "Weapon", item: props.item})
    }
    
    function handleValueChange(value) {
        var updatedItem = {...props.item};
        updatedItem.skill.value = value;
        props.updateSkill(updatedItem, SAVE.NO);
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        var updatedWeapon = {...props.item.weapon};
        updatedWeapon.equipped = event.target.checked;
        props.update(updatedWeapon, SAVE.NO);
    };

    if (props.pageState.editMode) {
        return (
            <div className={globalStyles.expandableTile} style={{display:"flex", alignItems: "center", padding: "2px"}} >
                <div style={{display: "flex", alignItems: "center"}} onClick={displayItem} >
                    <div style={{width: "160px"}}>{props.item.skill.name}</div>
                    <div style={{width: "60px", textAlign: "center"}} />
                </div>
                <div style={{width: "60px", display:"flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "50px"}}>
                        <EditBox size="sm" number value={props.item.skill.value} onChange={handleValueChange} />
                    </div>
                </div>
                <div style={{width: "70px"}} />
                <div style={{width:"90px", display:"flex", alignItems: "center", justifyContent:"center"}}>
                    <FormControl variant="outlined" 	>
                        <Checkbox checked={checked} sx={{padding: "0px"}} color='primary' onChange={handleChange} />
                    </FormControl>
                </div>
            </div>

        )
    }
    else {
        var disabled=props.item.skill && props.item.skill.value == 0;
        var className = disabled ? globalStyles.disabledButton : globalStyles.activeButton;

        return (
            <div className={globalStyles.expandableTile} style={{display:"flex", justifyContent: "space-between", alignItems: "center", padding: "2px"}} onClick={displayItem} >
                <div style={{width: "160px", display: "flex", alignItems: "center"}}>
                    <div>{props.item.weapon.title}</div>
                    {enchanted ? 
                        <div className={styles.roundButton} style={{width: "20px", height: "20px", marginLeft: "10px"}} ><img style={{width: "20px", height: "20px"}} src={MagicIcon} /></div>
                    : null}
                </div>
                <div style={{width: "60px", textAlign: "center"}}>{strikeRank}</div>
                <div>
                    <WeaponButton className={globalStyles.diceButton} diceRoller={props.diceRoller} item={props.item} processResult={props.processResult} disabled={disabled} type="Attack" entity={props.entity} style={{border: "1px solid #2F588A", borderRadius: "3px", padding: "2px", width: "40px", textAlign: "right", cursor: "pointer"}}>
                        <ButtonValue />
                    </WeaponButton>
                </div>
                <div style={{marginLeft: "20px"}} >
                    <WeaponButton className={globalStyles.diceButton} diceRoller={props.diceRoller} item={props.item} processResult={props.processResult} disabled={disabled} type="Parry" entity={props.entity} style={{border: "1px solid #2F588A", borderRadius: "3px", padding: "2px", width: "40px", textAlign: "right", cursor: "pointer"}}>
                        <ButtonValue />
                    </WeaponButton>
                </div>
                <div style={{marginLeft: "20px"}} className={globalStyles.damageButton}>
                    <DamageButton diceRoller={props.diceRoller} processDamage={props.processDamageResult} entity={props.entity} weapon={props.item.weapon} style={{width: "100px", height: "18px", cursor: "pointer", border: "1px solid #851113", borderRadius: "3px", padding: "2px", display: "flex", alignItems:"center", justifyContent: "center"}}/>
                </div>                        
            </div>

        )
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}

export default withPageState(withContext(WeaponRow));