import { Component } from 'react';

class PageState extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            editMode: false,
        };
      }
    
    ChangeEditMode(mode) {
        this.setState({
            editMode: mode
        })
    }

    getEditMode() {
        return this.state.editMode;
    }
}

export default PageState; 