import React, { Component } from 'react';

class Encounter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            encounters: [{id: "RafmBBNENPY33ueSbeLL", name: "Troll Hunting Party", npcs:[{id: "7200d86a-1311-48ff-867b-012fcfdd3854", profileImage: "https://storage.googleapis.com/runequest/bestiary/dark-troll.png", characteristics:{POW: {baseValue: 16, statBonus: 0}}, description: "A large dark troll with a white scar running down his left cheek. He wields a maul that glows with magic"}, {id: "b676bd3f-366e-4841-ae15-ddf65203c5e1",  profileImage: "https://storage.googleapis.com/runequest/bestiary/dark-troll.png", characteristics:{POW: {baseValue: 12, statBonus: 0}}, description: "A short stocky dark troll with large tusks holding a heavy mace and shield"}, {id: "0e2bd026-97e7-4ab4-b965-59bcb071842b",  profileImage: "https://storage.googleapis.com/runequest/bestiary/dark-troll.png", characteristics:{POW: {baseValue: 14, statBonus: 0}}, description: "A dark troll with a large red darkness rune on his forehead"}, {id: "a8e68f92-301d-48e6-bf24-8fde6cb9e662",  profileImage: "https://storage.googleapis.com/runequest/bestiary/trollkin.png", characteristics:{POW: {baseValue: 10, statBonus: 0}}, description: "A sly looking trollkin with an evil grin"}, {id: "376aedb2-3ea6-4740-943c-3e72052d30a5",  profileImage: "https://storage.googleapis.com/runequest/bestiary/trollkin.png", characteristics:{POW: {baseValue: 11, statBonus: 0}}, description: "A burly trollkin holding a wicked looking spear"}, {id: "966652e4-d940-45fe-86bf-717e3ee45d30",  profileImage: "https://storage.googleapis.com/runequest/bestiary/trollkin.png", characteristics:{POW: {baseValue: 13, statBonus: 0}}, description: "An older trollkin with many scars from previous fights"}]}],
        }
      }
    
    ChangeEncounters(newEncounters) {
        this.setState({
            encounters: newEncounters
        })
    }

    getEncounters() {
        return this.state.encounters;
    }
}

export default Encounter; 