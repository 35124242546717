import React, { useEffect, useState } from 'react';
import globalStyles from '../Styles/styles.module.css';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DialogButtons from '../Dialogs/dialogButtons';
import DialogBox from '../Dialogs/dialogBox';
import DialogButtonSingle from '../Dialogs/dialogButtonSingle';
import Selector from '../Controls/selector';
import * as Calendar from '../../data/calendar.json'
import EditBoxMulti from '../Controls/editBoxMulti';

const calendarDates = {...Calendar}

export default function AdventuresDialog(props) {

	const [adventure, setAdventure] = useState({startDate: {year: "", season: "", week: "", day: ""}, id: "", name: "", summary: ""});

	useEffect(() => {
		if (props.adventure && props.adventure.id) {
			setAdventure({...props.adventure});
		}
	},[props.adventure])

	function update(field, value) {
		var updatedAdventure = {...adventure};
		updatedAdventure[field] = value;
		setAdventure(updatedAdventure);
	}

	function updateStartDate(field, value) {
		var updatedAdventure = {...adventure}
		updatedAdventure.startDate[field] = value;
		setAdventure(updatedAdventure);
	}

	// function updateAdventure() {
	// 	setOpen(false);
	// 	if (adventure && adventure.name) {
	// 		var updatedAdventures = [...adventures];
	// 		var index = updatedAdventures.findIndex(obj => obj.id === adventure.id);
	// 		if (index > -1) {
	// 			updatedAdventures[index] = {...adventure}
	// 			setAdventures(updatedAdventures);
	// 		}
	// 		else {
	// 			updatedAdventures.push({name: adventure.name, id: uuidv4(), adventures: [], });
	// 			setAdventures(updatedAdventures)
	// 		}
	// 	}
	// }

	function handleOkClick(){
		var updatedAdventures = [...props.adventures];
		var index = updatedAdventures.findIndex(obj => obj.id === adventure.id);
		if (index > -1) {
			updatedAdventures[index] = {...adventure};
			props.update(updatedAdventures);
		}
		else {
			var updatedAdventure = {...adventure}
			updatedAdventure.id = uuidv4();
			updatedAdventure.encounters = [];
			updatedAdventures.push(updatedAdventure);
			props.update(updatedAdventures);
		}
		props.close();
	}

	return (
		<div>
			<div style={{overflowX: "hidden", height: "360px", fontSize: "15px", padding: "10px"}}>
				<div style={{margin: "10px 0px"}}>
					<EditBox width="550px" value={adventure.name} onChange={(value) => update("name", value)} placeholder="Adventure name" autoFocus />
				</div>
				<fieldset style={{borderRadius:"5px"}}>
					<legend>Start Date:</legend>
					<div style={{display: "flex", marginTop: "10px"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							<div style={{marginRight: "18px"}}>Year: </div>
							<EditBox width="126px" value={adventure.startDate.year} onChange={(value) => updateStartDate("year", value)} placeholder="Year" />
						</div>
						<div style={{display: "flex", alignItems: "center", marginLeft: "28px"}}>
							<Selector style={{width: "150px"}} label="Season:" field="season" values={calendarDates.seasons} value={adventure.startDate.season} updateSelected={(value) => updateStartDate("season", value)} />
						</div>
					</div>
					<div style={{display: "flex", marginTop: "10px"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							<Selector style={{width: "180px"}} label="Week:" field="week" values={calendarDates.weeks} value={adventure.startDate.week} updateSelected={(value) => updateStartDate("week", value)} />
						</div>
						<div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
							<Selector style={{width: "150px"}} label="Day:" field="day" values={calendarDates.days} value={adventure.startDate.day} updateSelected={(value) => updateStartDate("day", value)} />
						</div>
					</div>
				</fieldset>
				<div style={{margin: "10px 0px"}}>
					<EditBoxMulti rows={6} width="550px" value={adventure.summary} onChange={(value) => update("summary", value)} placeholder="Adventure summary" />
				</div>
			</div>
			<div style={{borderTop: "2px solid #2f588a", marginTop: "10px"}}>
				<DialogButtons handleOkClick={handleOkClick} handleCancelClick={props.close} title="OK" />
			</div>
		</div>
	);
}