import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import styles from './builder.module.css'
import OptionsDropdown from './controls/optionsDropdown';
import * as WeaponsData from '../../data/weapons.json';

const StartingWeapons = (props) => {

    const [occupationalWeapons, setOccupationalWeapons] = useState([]);
    const [unitWeapons, setUnitWeapons] = useState([]);
    const [selectedUnitWeapons, setSelectedUnitWeapons] = useState("");
    const [selected, setSelected] = useState([]);
    const [stats, setStats] = useState({STR: 0, DEX: 0})
    const [lowStats, setLowStats] = useState(false);

    var allWeapons = {...WeaponsData};
    const data = allWeapons.data;

    useEffect(() => {
        var updatedStats = {};
        props.builder.stats.details.forEach(stat => {
            if (stat.name === "STR" || stat.name === "DEX"){
                updatedStats[stat.name] = stat.baseValue + stat.statBonus;
            }
        });
        setStats(updatedStats);
    },[props.builder])

    useEffect(() => {
        var weapons = [];
        if (props.occupation.weapons) {
            props.occupation.weapons.forEach(weaponGroup => {
                if (weaponGroup.selected && weaponGroup.selected.name) {
                    weapons.push(weaponGroup);
                }
                else if (weaponGroup.options.length == 1) {
                    weapons.push(weaponGroup);
                }
                else if (weaponGroup.options.length > 1) {
                    weaponGroup.selected = {};
                    weapons.push(weaponGroup);
                }
                else {
                    var culturalWeaponGroup = getCulturalWeapons(weaponGroup);
                    weapons.push({...culturalWeaponGroup});
                }
            });
        }
        setOccupationalWeapons(weapons);
        checkForCompleteness(weapons, props.occupation.selectedUnitWeapons);
    }, [props.occupation.name]);

    useEffect(() => {
        if (props.occupation.unitWeapons) {
            var weapons = [];
            props.occupation.unitWeapons.forEach(unitWeapon => {
                weapons.push(unitWeapon.title);
            });
            setUnitWeapons(weapons);
        }
        else {
            setUnitWeapons([]);
        }
        if (props.occupation.selectedUnitWeapons) {
            setSelectedUnitWeapons(props.occupation.selectedUnitWeapons)
        }
    },[props.occupation]);

    function getCulturalWeapons(weaponGroup) {
        var options = [];
        props.builder.homeland.weapons.forEach(weapon => {
            if (weaponGroup.class === "Melee") {
                if (weapon.class === "Melee") {
                    options.push(...weapon.options);
                }
            }
            else if (weaponGroup.class === "Missile") {
                if (weapon.class === "Missile") {
                    options.push(...weapon.options);
                }
            }
            else {
                options.push(...weapon.options);
            }
        });
        var weapon = {
            bonus: weaponGroup.bonus,
            options: options,
            selected: {},
            title: weaponGroup.title
        }
        return weapon;
    }

    function selectOption(option, index) {
        var updatedAvailable = [...occupationalWeapons];
        updatedAvailable[index].selected = option;
        checkForCompleteness(updatedAvailable, selectedUnitWeapons);

        var selected = [option.name];
        var index = data.findIndex(item => item.id === option.parentId);
        option.minDEX = data[index].minDEX;
        option.minSTR = data[index].minSTR;
        updatedAvailable.forEach((weaponGroup, i) => {
            if (i != index) {
                if (weaponGroup.selected && weaponGroup.selected.name) {
                    selected.push(weaponGroup.selected.name);
                }
            }
        })
        setSelected(selected);
    }

    function checkForCompleteness(updatedAvailable, unitWeapons) {
        var complete = true;
        updatedAvailable.forEach(weapon => {
            if (weapon.options.length > 1 && !weapon.selected.name) {
                complete = false;
            }
        });
        if (complete) {
             props.complete(updatedAvailable, complete, unitWeapons);
       }
    }

    function selectUnitWeapons(event) {
        setSelectedUnitWeapons(event.target.value);
        props.occupation.unitWeapons.forEach((option) => {
            var weapons = [...option.weapons];
            weapons.forEach(weapon => {
                weapon.selected = {};
            });
            if (option.title == event.target.value) {
                var updatedWeapons = [...occupationalWeapons]
                for (var i = updatedWeapons.length -1; i >= 0; i--) {
                    if (updatedWeapons[i] && updatedWeapons[i].type === "unit") {
                        updatedWeapons.splice(i, 1);
                    }
                }
                var updatedWeapons = [...weapons, ...updatedWeapons]
                setOccupationalWeapons(updatedWeapons);
            }
        });
    }

    var border = selectedUnitWeapons ? "3px solid transparent" : "3px solid #851113";
    return (
            <div style={{borderRadius: "10px", width: "370px"}}>
                <div>
                    {unitWeapons && unitWeapons.length > 0 ?
                    <div style={{marginBottom: "20px"}}>
                        <div style={{backgroundColor: "#8a612f", color: "#fff", paddingLeft: "5px", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent:"center", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>Select Unit Weapons</div>
                        <div className={styles.row} style={{display:"flex", minHeight: "40px", overflow: "hidden", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
                            <div style={{display: "flex", paddingLeft: "3px", margin: "2px", width: "100%", borderRadius: "5px", border: border}}>
                                <div  style={{width: "350px", height: "80px", display:"flex", alignItems: "center"}}>
                                    <FormControl style={{width:"350px"}}>
                                        <InputLabel shrink id="occupation-label">"Unit Weapons"</InputLabel>
                                        <Select labelId="occupation-label" id="occupation" style={{minWidth:"12em"}}  onChange={selectUnitWeapons} value={selectedUnitWeapons}>
                                        {
                                            unitWeapons.map(item => {
                                                return (
                                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                                )            
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    : 
                    null
                    }
                    <div>
                        <div style={{backgroundColor: "#8a612f", color: "#fff", paddingLeft: "5px", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent:"center", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>{props.title}</div>
                        {   
                            occupationalWeapons && occupationalWeapons.length > 0 ?                  
                                occupationalWeapons.map((item, index) => {
                                    if (item.options.length > 1) {
                                        return (
                                            <div className={styles.row} key={index}>
                                                <OptionsDropdown key={index} item={item} stats={stats} selected={selected} index={index} setLowStats={setLowStats} update={selectOption} />
                                            </div>
                                        )
                                    }
                                    else if (item.options.length == 1) {
                                        return (
                                            <div className={styles.row} key={index} style={{display:"flex", minHeight: "40px", overflow: "hidden", margin: "2px 0px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
                                                <div style={{display: "flex", margin: "0px 10px"}}>
                                                    <div style={{alignItems: "center", display: "flex"}}>
                                                        <div style={{display:"flex", minWidth: "380px"}}>
                                                        <div>{item.options[0].name}</div>
                                                    </div>
                                                    </div>
                                                    {item.options[0].value ? 
                                                        <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>({item.value})</div>
                                                    : 
                                                        <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{item.bonus}</div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            : 
                            <div style={{backgroundColor: "#BFD0CD", color: "#851113", fontSize: "20px", display: "flex", alignItems: "center", justifyContent:"center", marginTop: "2px", height: "83px"}}>None</div>
                        }                    
                        {
                            lowStats ? 
                                <div style={{marginTop: "20px", color: "#851113"}}>** Insufficient STR and/or DEX</div>
                            : 
                            null
                        }                    
                   </div>
                </div>
            </div>
        );
}

export default StartingWeapons;