import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import globalStyles from '../../Styles/styles.module.css'
import * as SAVE from '../../../constants/save';
import DiceButton from '../../Buttons/diceButton';
import { getStatValue } from '../../../services/attributeService';
import EditBox from "../../Controls/editBoxSingle";

const StatTile = (props) => {

    const [value, setValue] = useState(0);
    const [target, setTarget] = useState(0);

    useEffect(() => {
        var value = getStatValue(props.item);
        setValue(value);
        setTarget(value * 5);
    },[props.item])

    function displayItem() {
        props.displayItem({type: "Stat", item: {...props.item, value: value}})
    }

    function onChange(value) {
        setValue(value);
        var updatedStat = {...props.item}
        updatedStat.baseValue = value;
        props.update(updatedStat, SAVE.NO)
    }

    function processResult(roll) {
        roll.title = props.item.fullName + " Check";
        props.processResult(roll);
    }

    return (
        <>
        {props.pageState.editMode ? 
            <div style={{display: "flex", fontSize: "17px", alignItems: "center"}}>
                <div style={{fontWeight: "bold", padding: "0px 5px"}}>{props.item.name}</div>
                <div style={{width: "100px"}}>
                    <EditBox fontSize="20px" number value={value} onChange={onChange} />
                </div>
            </div>
        : 
            <div className={globalStyles.expandableTile}  style={{display:"flex", border: "2px solid #2F588A", borderRadius: "3px", alignItems: "center", justifyContent: "space-between", width: "120px", cursor: "zoom-in", fontSize: "17px", marginRight: "12px"}} onClick={displayItem} >
                <div style={{fontWeight: "bold", paddingLeft: "5px"}}>{props.item.name + ": " + value}</div>
                <div >
                    <DiceButton type="Stat" diceRoller={props.diceRoller} style={{height: "30px", padding: "2px", width: "40px", textAlign: "right", backgroundColor: "#2F588A", color: "#fff", cursor: "pointer", padding: "2px", display: "flex", alignItems: "center", justifyContent: "center"}} item={props.item} processResult={processResult} target={target} entity={props.entity} >
                        <ButtonValue />
                    </DiceButton>
                </div>
            </div>
        }
        </>
    )

    function ButtonValue(props) {
        return (
            <>{(props.target || 0) + "%"}</>
        )
    }
}

export default withPageState(StatTile);