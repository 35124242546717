import React, {useState, useEffect, useRef} from 'react';
import { withPageState } from '../Context/PageState';
import { withFirebase } from '../Firebase';
import globalStyles from '../Styles/styles.module.css';
import { v4 as uuidv4 } from 'uuid';
import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { getAllCults, getAllOccupations, getAllPassions, getAllPantheons, getAllRunes, getAllRuneSpells, getAllSkills, getAllSpiritSpells } from '../../services/dataService';
import MultiSelect from '../Controls/multiSelect';
import DialogBox from '../Dialogs/dialogBox';
import EditSkillsDialog from './editSkillsDialog';
import compareValues from '../../utils/compareValues';
import DescriptionDialog from './descriptionDialog';
import EditPassionsDialog from './editPassionsDialog';
import EditWeaponsDialog from './editWeaponsDialog';
import MultiSelectItems from '../Controls/multiSelectItems';

const CustomCultContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [cult, setCult] = useState(null);
	const [openSkills, setOpenSkills] = useState(false);
	const [openWeapons, setOpenWeapons] = useState(false);
	const [openPassions, setOpenPassions] = useState(false);
	const [subcult, setSubcult] = useState(false);
	const [parentCult, setParentCult] = useState();
	const [selected, setSelected] = useState({
		runes: [],
		commonRuneSpells: [],
		runeSpells: [],
		spiritSpells: [],
		passions: [],
		skills: [],
		weapons: [],
		prohibited: [],
		associated: [],
		occupations: [],
		pantheons: []
	})

	useEffect(() => {
		if (props.panelItem) {
			setCult(props.panelItem.item);
			let selectedDetails = {}
			selectedDetails.runes = props.panelItem.item.runes;
			if (props.panelItem.item.commonRuneSpells && props.panelItem.item.commonRuneSpells.length > 0) {
				selectedDetails.commonRuneSpells = getNames(props.panelItem.item.commonRuneSpells);
			}
			else {selectedDetails.commonRuneSpells = [];}
			selectedDetails.runeSpells = getNames(props.panelItem.item.runeSpells);
			selectedDetails.spiritSpells = getNames(props.panelItem.item.spiritSpells);
			selectedDetails.passions = props.panelItem.item.passions;
			selectedDetails.skills = props.panelItem.item.skills;
			selectedDetails.weapons = props.panelItem.item.weapons;
			selectedDetails.prohibited = getNames(props.panelItem.item.prohibited);
			selectedDetails.associated = getNames(props.panelItem.item.associated);
			selectedDetails.occupations = getNames(props.panelItem.item.occupations);
			selectedDetails.pantheons = props.panelItem.item.pantheons;
			setSelected(selectedDetails);
		}
	},[props.panelItem]);

	function getNames(items) {
		let names = [];
		items.forEach(item => {
			let name = item.name;
			if (item.variant) {
				name += " (" + item.variant + ")";
			}
			names.push(name);
		})
		return names;
	}

    function getOptionNames(items) {
		let values = [];
		items.forEach(item => {
			if (item && item.options) {
				if (item.options.length === 1) {
					values.push(getNames(item.options) + " +" + item.bonus + "%");
				}
				else {
					values.push("Select one of (" + getNames(item.options).join(', ') + ") +" + item.bonus + "%")
				}
			}
		})
		return values.join(", ");
    }

	function updateSkills(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.skills = [...selectedItems];
		setSelected(updatedSelected)
		setValue("skills", selectedItems);
	}

	function updateWeapons(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.weapons = [...selectedItems];
		setSelected(updatedSelected)
		setValue("weapons", selectedItems);
	}

	function updatePassions(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.passions = [...selectedItems];
		setSelected(updatedSelected)
		setValue("passions", selectedItems);
	}

	function setCultRunes(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.runes = selectedItems;
		setSelected(updatedSelected);
		setValue("runes", selectedItems);
	}

	function setCultPantheons(selectedItems) {
		let updatedSelected = {...selected};
		updatedSelected.pantheons = selectedItems;
		setSelected(updatedSelected);
		setValue("pantheons", selectedItems);
	}

	function updateCult(fieldName, selectedItems, getAllItems) {
		var allItems = getAllItems(props.customContent);
		let updatedSelected = {...selected};
		updatedSelected[fieldName] = selectedItems;
		let cultItems = []
		updatedSelected[fieldName].forEach(name => {
			const index = allItems.findIndex(obj => obj.name === name)
			if (index > -1) {
				let item = {...allItems[index]}
				cultItems.push({name: name, parentId: item.id});
			}
		})
		setSelected(updatedSelected)
		setValue(fieldName, cultItems);
	}

	function setValue(field, value) {
		var updatedCult = {...cult}
		updatedCult[field] = value;
		setCult(updatedCult);
		props.update(updatedCult, "cults"); 
	}

    function applyDescription(editorContent, title, index) {
		var updatedCult = {...cult}
        updatedCult.description = editorContent;
        setCult(updatedCult);
		props.update(updatedCult, "cults")
    }

	function setSubcultSwitch(event) {
		setSubcult(event.target.checked);
	}

	function getSkills() {
		
		var skills = [];
		cult.skills.forEach(skill => {
			if (skill.options.length > 1) {
				skills.push({...skill.options[0], bonus: skill.bonus})
			}
		});
		cult.freeSkills.forEach(skill => {
			skills.push({...skill.options[0], bonus: skill.bonus})
		});
		return skills.sort(compareValues("name", "asc"));
	}

	function getWeapons() {
		
		var weapons = [];
		cult.weapons.forEach(weapon => {
			if (weapon.options.length > 1) {
				weapons.push({...weapon.options[0], bonus: weapon.bonus})
			}
		});
		return weapons.sort(compareValues("name", "asc"));
	}

	if (cult) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: (props.height - 100) + "px", overflow: "auto", padding: "5px"}}>
						{/* {props.pageState.editMode ? 
							<div className="contentLine">
								<div style={{display: "flex", alignItems: "center"}}>
									<FormGroup>
										<FormControlLabel style={{marginLeft: "0px"}} labelPlacement="start" label="Subcult or hero cult" control={<Switch color="primary" checked={subcult} onChange={setSubcultSwitch}></Switch>}></FormControlLabel>
									</FormGroup>
								</div>
								{subcult ? 
									<Selector labelMargin="31px" label="Parent cult:" style={{height: "28px", margin: "10px 0px"}} value={parentCult} values={getAllCults(props.customContent).filter(function(cult) { return !cult.restricted && ! cult.parentCult})} onChange={(item) => {setValue("pantheon", item.name)}} />
								: 
								null}
							</div>
						: 
							subcult ? 
								<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
									<div style={{display: "flex", alignItems: "center", width: "166px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Pantheon: </div>
										<div>{cult.pantheon}</div>
									</div>
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Restricted: </div>
										<div>{cult.restricted ? "Yes": "No"}</div>
									</div> 
								</div>
							: 
								null
							
						} */}
						{props.pageState.editMode ? 
							<>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div>Cult name: </div>
									<EditBox size="sm" width="200px" height="24px" value={cult.name} onChange={(value) => setValue("name", value)} />
								</div>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px 0px"}}>
									<div>Cult subtitle: </div>
									<EditBox size="sm" width="200px" height="24px" value={cult.subtitle} onChange={(value) => setValue("subtitle", value)} />
								</div>
							</>
							: 
							<div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
								<div style={{fontWeight: "bold", marginRight: "20px"}}>Name: </div>
								<div>{cult.name + (cult.subtitle ? "(" + cult.subtitle + ")": "")}</div>
							</div>
						}
						{props.pageState.editMode ? 
							<>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Pantheons: </div>
							<MultiSelect values={getNames(getAllPantheons())} value={cult.pantheons} selectedValues={selected.pantheons || []} onChange={setCultPantheons} />
							</>
						: 
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Pantheon: </div>
								<div>{cult.pantheons.join(", ")}</div>
							</div>
						</div>
						}
						{props.pageState.editMode ? 
							<>
								<div style={{display: "flex", alignItems: "center"}}>
									<FormGroup>
										<FormControlLabel style={{marginLeft: "0px"}} labelPlacement="start" label="Playable cult" control={<Switch color="primary" checked={!cult.restricted} onChange={(event) => setValue("restricted", ! event.target.checked)}></Switch>}></FormControlLabel>
									</FormGroup>
								</div>
							</>
						: 
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "145px"}}>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Playable cult: </div>
								<div>{!cult.restricted ? "Yes": "No"}</div>
							</div> 
						</div>
						}
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Runes: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRunes(props.customContent))} selectedValues={selected.runes || []} onChange={setCultRunes} />
								: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.runes && cult.runes.length > 0 && cult.runes.map((rune, index) => (
									rune + (index < cult.runes.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Common Rune Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRuneSpells(props.customContent).filter(function (spell){return spell.common}))} selectedValues={selected.commonRuneSpells || []} onChange={(selected) => updateCult("commonRuneSpells", selected, getAllRuneSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.commonRuneSpells && cult.commonRuneSpells.length > 0 && cult.commonRuneSpells.sort(compareValues('name')).map((spell, index) => (
									spell.name + (index < cult.commonRuneSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px", }}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Special Rune Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllRuneSpells(props.customContent))} selectedValues={selected.runeSpells || []} onChange={(selected) => updateCult("runeSpells", selected, getAllRuneSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.runeSpells && cult.runeSpells.length > 0 && cult.runeSpells.map((spell, index) => (
									spell.name + (index < cult.runeSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						{/* <div style={{margin: "10px 0px", display: "flex", alignItems: "center"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Enchantments: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={commonSpells} selectedValues={selectedCommon} onChange={updateCommon} />
							: 
								<div>{cult.commonRuneSpells && cult.commonRuneSpells.length > 0 && cult.commonRuneSpells.map((spell, index) => (
									spell.name + (index < cult.commonRuneSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div> */}
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Spirit Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllSpiritSpells(props.customContent))} selectedValues={selected.spiritSpells || []} onChange={(selected) => updateCult("spiritSpells", selected, getAllSpiritSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.spiritSpells && cult.spiritSpells.length > 0 && cult.spiritSpells.map((spell, index) => (
									spell.name + (index < cult.spiritSpells.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Prohibited Spirit Magic: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllSpiritSpells(props.customContent))} selectedValues={selected.prohibited || []} onChange={(selected) => updateCult("prohibited", selected, getAllSpiritSpells)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.prohibited && cult.prohibited.length > 0 && cult.prohibited.map((spell, index) => (
									spell.name + (index < cult.prohibited.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Associated Cults: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllCults(props.customContent))} selectedValues={selected.associated || []} onChange={(selected) => updateCult("associated", selected, getAllCults)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.associated && cult.associated.length > 0 && cult.associated.map((assoc, index) => (
									assoc.name + (index < cult.associated.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Occupations: </div>
							{props.pageState.editMode ? 
								<MultiSelect style={{width: "325px"}} values={getNames(getAllOccupations(props.customContent))} selectedValues={selected.occupations} onChange={(selected) => updateCult("occupations", selected, getAllOccupations)} />
							: 
								<div style={{display: "flex", alignItems: "center"}}>{cult.occupations && cult.occupations.length > 0 && cult.occupations.map((occupation, index) => (
									occupation.name + (index < cult.occupations.length -1 ? ", " : "")
								))}</div>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Starting Skills <br/> (for new characters): </div>
									<Button style={{width: "140px"}} variant='contained' color='primary' onClick={() => setOpenSkills(true)}>Edit Skills</Button>
								</div>
							: 
							<>
							<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Starting Skills: </div>
							<div style={{display: "flex", alignItems: "center"}}>{getOptionNames(cult.skills)}</div>
							</>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Starting Weapons <br/> (for new characters): </div>
									<Button style={{width: "140px"}} variant='contained' color='primary' onClick={() => setOpenWeapons(true)}>Edit Weapons</Button>
								</div>
							: 
								<>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Starting Weapons: </div>
									<div style={{display: "flex", alignItems: "center"}}>{getOptionNames(cult.weapons)}</div>
								</>
							}
						</div>
						<hr />
						<div style={{margin: "10px 0px"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Favored Passions <br/> (for new characters): </div>
									<Button style={{width: "140px"}} variant='contained' color='primary' onClick={() => setOpenPassions(true)}>Edit Passions</Button>
								</div>
						: 
							<>
								<div style={{marginRight: "10px", fontWeight: "bold"}}>Favored Passions: </div>
								<div style={{display: "flex", alignItems: "center"}}>{cult.passions && cult.passions.length > 0 && cult.passions.map((passion, index) => (
									passion.name + (passion.variant ? " (" + passion.variant + ")" : "") + (index < cult.passions.length -1 ? ", " : "")
								))}</div>
							</>
							}
						</div>
						<hr />
						<div style={{marginTop: "20px"}}>
							{props.pageState.editMode ? 
								<>
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{fontWeight: "bold"}}>Description</div>
										<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
									</div>
									<div dangerouslySetInnerHTML={{__html: cult.description}}></div>
								</>
							: 
								<>
									<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
									<div dangerouslySetInnerHTML={{__html: cult.description}}></div>
								</>
							}
						</div>
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={cult.description} />
				</DialogBox>
				<DialogBox isOpen={openSkills} title="Cult Starting Skills" width="800px">
					<EditSkillsDialog update={(selected) => updateSkills(selected)} customContent={props.customContent} selected={selected.skills} close={() => setOpenSkills(false)} />
				</DialogBox>
				<DialogBox isOpen={openWeapons} title="Cult Starting Weapons" width="800px">
					<EditWeaponsDialog update={(selected) => updateWeapons(selected)} customContent={props.customContent} selected={selected.weapons} close={() => setOpenWeapons(false)} />
				</DialogBox>
				<DialogBox isOpen={openPassions} title="Cult Favored Passions" width="600px">
					<EditPassionsDialog update={(selected) => updatePassions(selected)} customContent={props.customContent} selected={selected.passions} close={() => setOpenPassions(false)} />
				</DialogBox>
			</>
		)
	}
	else {
		return null;
	}
}
export default withPageState(withFirebase(CustomCultContextPanel));