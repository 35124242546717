import React, { useState, useEffect } from 'react';
import OptionsDropdown from './controls/optionsDropdown';
import OptionsRadioGroup from './controls/optionsRadioGroup';
import OptionsVariant from './controls/optionsVariant';
import OptionsSingle from './controls/optionsSingle';
import styles from './builder.module.css'

const StartingSkills = (props) => {

    const [skills, setSkills] = useState([]);

    useEffect(() => {
        setSkills([...props.skills]);
        checkForCompleteness(props.skills)
    }, [props.skills, props.displayCharacter]);

    function selectOption(option, index) {
        var updatedSkills = [...skills];
        updatedSkills[index].selected = option;
        setSkills(updatedSkills);
        checkForCompleteness(updatedSkills)
    }

    function checkForCompleteness(skills) {
        var complete = true;
        skills.forEach(skill => {
            if (skill.options.length > 1) {
                if (!skill.selected.name) {
                    complete = false;
                }
            }
            else if (skill.options[0].variant === "" && !skill.options[0].postpone) {
                complete = false;
            }
        });
        props.complete(skills, complete);
    }

    function updateVariant(variant, index) {
        var updatedSkills = [...skills];
        updatedSkills[index].options[0].variant = variant;
        setSkills(updatedSkills);
        checkForCompleteness(updatedSkills)
    }

    if (skills.length > 0) {
        return (
            <div style={{borderRadius: "10px"}}>
            <div style={{backgroundColor: "#8a612f", color: "#fff", paddingLeft: "5px", height: "40px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent:"center", borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}>{props.title}</div>
            {                    
                skills.map((item, index) => {
                    if (item.options.length > 1) {
                        if (item.options.length > 3) {
                            return (
                                <div className={styles.row} key={index}>
                                    <OptionsDropdown key={index} item={item} selected={[]} update={selectOption} index={index} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className={styles.row} key={index}>
                                    <OptionsRadioGroup key={index} item={item} update={selectOption} index={index} />
                                </div>
                            )
                        }
                    }
                    else {
                        if (item.options[0].variable && ! item.options[0].postpone) {
                            return (
                                <div className={styles.row} key={index}>
                                    <OptionsVariant item={item} update={updateVariant} index={index} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className={styles.row} key={index}>
                                    <OptionsSingle item={item} index={index} />
                                </div>
                            )
                        }
                    }
                })
            }
        </div>
        ); 
    }
    else {return null;}       
}

export default StartingSkills;