import React, {useState, useEffect} from 'react';
import { withFirebase } from '../Firebase';
import {withContext} from '../Context/context';
import { Button, TextField,  } from '@mui/material';
import dayjs from 'dayjs';
import DialogBox from '../Dialogs/dialogBox';
import AddNoteDialog from './addNoteDialog';
import editIcon from '../../icons/edit-note-icon.svg';
import deleteIcon from '../../icons/delete-icon-blue.svg';
import DeleteDialog from './deleteDialog';
import DialogButtonSingle from './dialogButtonSingle';

const NotesDialog = (props) => {

    const [notes, setNotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentNote, setCurrentNote] = useState(null); 
    const [noteIndex, setNoteIndex] = useState(-1);

    useEffect(() => {
        var myNotes = [];
        const fetchData = async () => {
            props.firebase
            .notes()
            .where('campaignId', '==', props.campaignId)
            .orderBy('createdAt', 'desc')
            .get()
            .then(function(collection) {
                collection.forEach(function(doc) {
                    var note = {...doc.data()};
                    note.id = doc.id;
                    myNotes.push(note);
                });
                setNotes(myNotes);
            });
        };

        fetchData();
    }, [props.campaignId]);

    function handleChange(event) {
        setSearchTerm(event.target.value);
    }

    function update(content, privateNote, index) {
        if (index > -1) {
            var updatedNote = {...notes[index]}
            updatedNote.content = content;
            updatedNote.private = privateNote;
            props.firebase
            .note(updatedNote.id)
            .set(updatedNote)
            .then( function() {
                var updatedNotes = [...notes];
                updatedNotes[index] = updatedNote;
                setNotes(updatedNotes);
                setOpen(false);
              })
            }
        else {
            var newNote = {
                authorId: props.user.uid,
                authorName: props.user.firstName,
                campaignId: props.campaignId,
                content: content,
                createdAt: props.firebase.fieldValue.serverTimestamp(),
                private: privateNote,
                tags: []
            }
            props.firebase
            .notes()
            .add(newNote)
            .then( function(docRef) {
                newNote.id = docRef.id;
                var updatedNotes = [...notes];
                updatedNotes.splice(0, 0, newNote);
                setNotes(updatedNotes);
                setOpen(false);
            })
        }
      }

      function newNote() {
        setOpen(true);
        setEdit(true);
        setNoteIndex(-1);
      }

    function editNote(index) {
        setOpen(true);
        setEdit(true);
        var current = {...notes[index]};
        setCurrentNote(current);
        setNoteIndex(index);
    }

    function deleteNote(index) {
        setConfirm(true);
        setNoteIndex(index)
    }

    function confirmDelete() {
        if (noteIndex > -1) {
            var updatedNote = {...notes[noteIndex]}
            props.firebase
            .note(updatedNote.id)
            .delete()
            .then( function(docRef) {
                var updatedNotes = [...notes];
                updatedNotes.splice(noteIndex, 1);
                setNotes(updatedNotes);
                setConfirm(false);
            })
        }
    }

    return (
        <>
            <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between", height: "480px"}}>
                <div>
                    <div style={{display:"flex", padding: "10px", justifyContent: "space-between", alignItems:"center"}}>

                        <div style={{display:"flex"}}>
                            <Button style={{marginRight: "20px"}} onClick={newNote} color="primary" variant="contained">Add Note </Button>
                        </div> 
                        <div style={{display:"flex", alignItems: "center"}}>
                            <form>
                                <div style={{display:"flex", alignItems:"center"}}> 
                                    <TextField variant="outlined" label="Search" size="small" name="variant" value={searchTerm} color="primary" onChange={handleChange} style={{backgroundColor:"#f0f0f0", borderRadius:"5px", border: "1px solid #2f588a"}} >
                                        {searchTerm}
                                    </TextField>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div style={{maxHeight: "356px", overflowY: "auto", overflowX: "hidden"}}>
                        {notes.map((note, index) => {
                            if (note.content.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                                if (!note.private  || note.private && note.authorId == props.user.uid) {
                                    var noteDate = dayjs(note.createdAt.seconds * 1000);
                                    if (!noteDate.isValid()) {
                                        noteDate = dayjs();
                                    }
                                    return (
                                        <div key={index} style={{width: "100%", margin: "5px 0px"}}>
                                            <div style={{display: "flex", backgroundColor: note.private ? "#cae5ed": "#f0f0f0", padding: "5px", justifyContent: "space-between"}}>
                                                <div style={{display:'flex'}}>
                                                    <div style={{width:"220px"}}>{noteDate.toString()}</div>
                                                    <div style={{width:"240px"}}>{note.authorName}</div>
                                                    <div>{note.private ? "Private": ""}</div>
                                                </div>
                                                <div style={{display:'flex', marginRight: "20px"}}>
                                                    <img style={{width: "20px", cursor: "pointer"}} onClick={() => editNote(index)} src={editIcon} />
                                                    <img style={{width: "20px", cursor: "pointer", marginLeft: "10px"}} onClick={() => deleteNote(index)} src={deleteIcon} />
                                                </div>
                                            </div>
                                            <div style={{width: "100%", backgroundColor: note.private ? "#cae5ed": "#f0f0f0", padding: "0px 5px"}}>{note.content}</div>
                                        </div>
                                    )
                                }
                            }
                        })}
                    </div>
                </div>
                <DialogButtonSingle closeClick={props.close} />
            </div>
            <DialogBox isOpen={open} size="md" title={noteIndex > -1 ? "Edit Note" : "New Note"}>
                <AddNoteDialog update={update} close={() => setOpen(false)} currentNote={currentNote} index={noteIndex} />
            </DialogBox>
            <DeleteDialog confirm={confirm} setConfirm={setConfirm} delete={confirmDelete} type="note" />
        </>
    );
}

export default withFirebase(withContext(NotesDialog));