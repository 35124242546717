import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { withFirebase } from '../Firebase';
import DeleteIcon from '../../icons/delete-icon-red.svg';
import DialogBox from '../Dialogs/dialogBox';
import ConfirmDeleteDialog from '../Dialogs/confirmDeleteDialog';
import DeleteButton from '../Controls/deleteButton';

const CampaignCard = (props) => {
  const[campaign, setCampaign] = React.useState();
  const [dialog, setDialog] = useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      if (props.campaignId) {
        var selectedCampaign = {};
        props.firebase
        .campaign(props.campaignId)
        .get()
        .then((doc) => {
            if (doc.exists) {
                const id = doc.id;
                const data = doc.data();
                selectedCampaign = {id, ...data}
                setCampaign(selectedCampaign);
            }
          });     
      }
      else {
        setCampaign(props.campaign);
      }
  };
  
    fetchData();
  }, []);
  
  function openDialog(event) {
    event.preventDefault();
    setDialog(true);
  }

  function handleDialogClose(result) {
    setDialog(false);
  }

  function deleteCampaign(event) {
    event.preventDefault();
    props.deleteCampaign(campaign.id);
  }

  if (campaign) {
  var link = "/campaign/" + props.campaign.id;
  return (
    <a href={link}>
    <Card style={{marginTop:"30px", display: "flex"}}>
      <CardMedia
      style={{height:"240px", width:"240px"}}
        image={campaign.imageUrl}
        title={campaign.name}
      />
      <div style={{width: "280px", display: "flex", flexDirection: "column"}} >
        <CardContent style={{flex: '1 0 auto'}}>
          <Typography component="h5" variant="h5">
            <div style={{display:"flex", justifyContent: "space-between"}}>
              {campaign.name}
            </div>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          {campaign.description} 
          </Typography>
        </CardContent>
        {
          props.showDelete ? 
          <div>
            <div style={{display:"flex", flexDirection: "column-reverse", alignItems:"end", padding:"10px"}}><img onClick={openDialog} style={{width:"20px"}} src={DeleteIcon}></img></div>
            <DialogBox isOpen={dialog} handleClose={handleDialogClose} title='Delete campaign'>
              <ConfirmDeleteDialog itemName={campaign.name} type="campaign" innerHTML={props.innerHTML} delete={deleteCampaign} closeDialog={handleDialogClose}></ConfirmDeleteDialog>
            </DialogBox>
          </div>
          :null
        }
      </div>
    </Card>
    </a>
  );

  }
  else {return null;}
}

export default withFirebase(CampaignCard);
