import React from 'react';
import {withContext} from '../Context/context';
import { getCategoryModifiers } from '../../services/skillsService';
import { isEqual } from 'lodash';

const PowerTile = (props) => {

    function update(characteristic, save) {

        var updatedCharacteristics = {...props.entity.characteristics};
        updatedCharacteristics.POW = characteristic;
        var updates = [{fieldName: "characteristics", value: updatedCharacteristics}];

        var updatedModifiers = getCategoryModifiers(updatedCharacteristics);
        if (! isEqual(props.entity.categoryModifiers, updatedModifiers)) {
            updates.push({fieldName: "categoryModifiers", value: updatedModifiers});
        }

        var updatedMagicPoints = [...props.providerData.character.magicPoints]
        var index = updatedMagicPoints.findIndex(points => points.entityId == props.entity.id);
        if (index > -1) {
            updatedMagicPoints[index].total = updatedCharacteristics.POW.baseValue + (updatedCharacteristics.POW.statBonus || 0);
            updates.push({fieldName: "magicPoints", value: updatedMagicPoints});
        }

        props.update(updates, save);
    }

    return (
        <>
            {React.cloneElement(props.children, {...props, update: update})}
        </>  
    )
}

export default withContext(PowerTile)