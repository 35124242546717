import React, { useState, useEffect } from 'react';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';

const GiftsGeasesPanel = (props) => {

    const [gifts, setGifts] = useState([]);
    const [geases, setGeases] = useState([]);

    useEffect(() => {
        if (props.providerData.character) {
            var gifts = [];
            props.providerData.character.cults.forEach(cult => {
                if (cult.gifts) {
                    gifts = gifts.concat([...cult.gifts])
                }
            })
            setGifts(gifts);
            var geases = [];
            props.providerData.character.cults.forEach(cult => {
                if (cult.geases) {
                    geases = geases.concat([...cult.geases])
                }
            })
            setGeases(geases);
        }
    },[props.providerData.character])

    function deleteCrystal(item) {
        var updatedCrystals = [...crystals];
        var index = updatedCrystals.findIndex(crystal => crystal.id === item.id);
        updatedCrystals.splice(index, 1)
        setCrystals(updatedCrystals);
        props.update([{fieldName: "crystals", value: updatedCrystals}], SAVE.NO)
    }

    if (gifts.length > 0 || geases.length > 0) {
        return (
            <div style={{border: "2px solid #2F588A", borderRadius: "5px", marginLeft: "5px", marginTop: "10px", overflow: "hidden"}}>
                <div id="header" style={{background: "rgb(47,88,138)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "10px", textAlign: "center", color: "#fff"}}>Gifts & Geases</div>
                <div style={{padding: "10px"}}>
                    {gifts && gifts.length > 0 ? 
                        gifts.map((gift, index) => {
                            return (
                                <div key={index}>{gift}</div>
                            )
                        })
                    : 
                        null
                    }
                </div>
                <div style={{padding: "10px"}}>
                {geases && geases.length > 0 ? 
                        geases.map((geas, index) => {
                            return (
                                <div key={index}>{geas}</div>
                            )
                        })
                    : 
                        null
                    }
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}
export default withContext(GiftsGeasesPanel);