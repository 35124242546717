import React, { useState, useEffect} from 'react';
import MagicIcon from '../../icons/magic-icon.svg';
import NoMagicIcon from '../../icons/no-magic-icon.svg'
import EnchantmentsDialog from './enchantmentsDialog';
import DialogBox from '../Dialogs/dialogBox';
import styles from '../Characters/character.module.css';

export default function EnchantmentsButton(props) {
    const [open, setOpen] = useState(false);
    const [enchantments, setEnchantments] = useState();
    const [buttonClass, setButtonClass] = useState(styles.button);
    const [icon, setIcon] = useState(NoMagicIcon);
    const [backgroundColor, setBackgroundColor] = useState("#2F588A");

    useEffect(() => {

        if (props.character.enchantments && props.character.enchantments.length > 0 ) {
            setButtonClass(`${styles.glowingBlueButton} ${styles.roundButton}`);
            setIcon(MagicIcon);
            setBackgroundColor("#ffffff");
        }
        else {
            setButtonClass(styles.button);
            setIcon(NoMagicIcon);
            setBackgroundColor("#2F588A");
        }
        if (props.npc) {
            setButtonClass("")
        }
        setEnchantments(props.character.enchantments)

    }, [props.character]);

    const width = props.width || "50px";

    return (
        <>
            <div onClick={props.displayPanel} className={buttonClass} style={{backgroundColor: backgroundColor, cursor: "pointer", marginTop: props.npc ? "" : "10px", border: props.npc ? "1px solid #2f588a" : "", borderRadius: props.npc ? "15px": ""}}>
                <img style={{width:width, marginBottom: "-2px"}} src={icon} title="Manage enchantments & augmentations" alt="Manage enchantments" />
            </div>
            <DialogBox isOpen={open} handleClose={() => setOpen(false)} width="600px" title="Manage Enchantments">
                <EnchantmentsDialog update={props.update} displayMessage={props.displayMessage} enchantments={enchantments} user={props.user} close={() => setOpen(false)} />
            </DialogBox>
        </>
);
}