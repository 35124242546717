import { getRuneByName } from "./dataService";

export default function getElementalRune(runes) {
    let elementalRune;
    let spellRunes = [];

    runes.forEach(rune => {
        let runeDetails = getRuneByName(rune);
        if (runeDetails.category === "Elemental") {
            elementalRune = rune;
        }
    });
    if (elementalRune) {
        spellRunes = [elementalRune]
    }
    else {
        spellRunes = [...runes]
    }
    
    return spellRunes;
}
