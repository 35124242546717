import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';
import PageState, { PageStateContext } from './components/Context/PageState';
import ActiveEncounters, {EncounterContext} from './components/Context/ActiveEncounters';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<PageStateContext.Provider value={new PageState()}>
			<EncounterContext.Provider value={new ActiveEncounters()}>
				<App />
			</EncounterContext.Provider>
		</PageStateContext.Provider>
	</FirebaseContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
