import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import * as tribesData from '../../../data/tribes.json';

const SelectTribeDialog = (props) => {
	const [selectedTribe, setSelectedTribe] = useState(null);
    const [tribes, setTribes] = useState([]);

	useEffect(() => {
		setSelectedTribe(props.tribe);
	},[props.tribe]);

    useEffect(() => {
        var tribesList = [];
		var allTribes = {...tribesData}
		allTribes.data.forEach(tribe => {
			tribesList.push(tribe);
			setTribes(tribesList);
		})
    },[]);
	  
	function handleOkClick(){
        props.select({...selectedTribe});
	}

	return (
		<div style={{height: "100%", display:"flex", flexDirection:"column", justifyContent:"space-between", padding: "20px"}}>
			<div>
				<div style={{display:"flex", color: "#fff"}}>
					<div style={{display:"flex", flexWrap: "wrap", flexDirection:"column", height: "360px", width: "400px"}}>
						{tribes.map((tribe, index) => {
							return (
								<div key={index} style={{margin: "10px 15px", padding: "10px", marginBottom:"20px", backgroundColor: "#232423", border: "3px solid #cdcdcd", opacity: selectedTribe && tribe.name !== selectedTribe.name ? 0.5 : 1}} onClick={() => setSelectedTribe(tribe)}>
									<img src={tribe.imageLink} style={{height:"80px", borderRadius:"5px", width: "110px", padding: "10px"}} />
									<div style={{fontSize:"14px", textAlign:"center"}}>{tribe.name}</div>
								</div>
							)
						})}
					</div>
				</div>
				<div>{selectedTribe && selectedTribe.description}</div>
			</div>
			<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
				<Button type="submit" disabled={!selectedTribe} onClick={handleOkClick} style={{marginTop:"30px", width: "100px", color:"#fff"}} color="primary" variant="contained">Ok</Button>
				<Button type="submit" onClick={() => {props.close()}} style={{marginTop:"30px", width: "100px", marginRight: "20px"}} variant="contained">Cancel</Button>
			</div>
		</div>
	);
}

export default SelectTribeDialog;