import React, { useState, useEffect } from 'react';
import { withContext } from '../../Context/context';
import { TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import EditBox from '../../Controls/editBoxSingle';
import { getAllRunes } from '../../../services/dataService';

const AddRuneDialog = (props) => {

	const [items, setItems] = useState([]);
	const [selection, setSelection] = useState("Select");
	const [value, setValue] = useState(0);

	useEffect(() => {
		var runes = [];
		var allRunes = getAllRunes(props.providerData.campaign);
		allRunes.forEach(rune => {
			runes.push(rune);
		})
		var newRunes = runes.filter(function(e) {
			var pos = props.providerData.character.runes.findIndex(i => i.name === e.name);
			return pos < 0;
		});
		setItems(newRunes);
	},[])

	function handleChange(event) {
		setSelection(event.target.value)
	}
  
    function modifyValue(event, index){
        var previous = value || 0;
        var newValue = parseInt(event.target.value)
        if (! newValue) newValue = previous;
        setValue(newValue);
    }

	function handleCancelClick(){
		props.close();
	}
	  
	function handleOkClick(){
		var selectedItem = {};
		items.forEach(item => {
			if (item.name === selection) {
				selectedItem.id = uuidv4();
				selectedItem.value = value;
				selectedItem.parentId = item.id;
				selectedItem.hasSucceeded = false;
				selectedItem.type = item.category;
				selectedItem.name = item.name;
				selectedItem.description = item.description;
				return;
			}
		});
        props.addItem(selectedItem);
		props.close();
	}

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", padding: "10px"}}>
			{items && items.length > 0 ? 
				<div>
					<div style={{display:"flex"}}>
						<FormControl variant="outlined" >
							<Select value={selection} style={{backgroundColor:"#fff", minWidth:"10em"}} autoWidth={true} onChange={handleChange} color="primary">
								<MenuItem key={0} value="Select">
									<em>Select</em>
								</MenuItem>
								{ 
									items.map((item, index) => {
										return( <MenuItem key={index} value={item.name}>{item.name}</MenuItem> );
									})
								}
							</Select>
						</FormControl>
						<div style={{width:"100px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
							<EditBox number value={value} onChange={setValue} />
						</div>
					</div>
						<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px", alignItems:"flex-end"}}>
							<Button type="submit" disabled={selection === "Select" || value <= 0} onClick={handleOkClick} style={{marginTop:"30px", width: "100px", color:"#fff"}} variant="contained">Ok</Button>
							<Button onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
						</div>
				</div>
			:
				null
			}
			
		</div>
	);
};

export default withContext(AddRuneDialog);