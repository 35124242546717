import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import SpiritSpellPanel from './spiritSpellPanel';
import { getAllSpiritSpells } from '../../services/dataService';

const ManageSpiritSpellDialog = (props) => {

	const [allSpells, setAllSpells] = useState([]);
	const [selected, setSelected] = useState([]);
    const [availableStorage, setAvailableStorage] = useState(props.storage || 0);

	useEffect(() => {
		if (props.currentSpells && props.currentSpells.length > 0) {
			setSelected([...props.currentSpells])
		}
	},[])

	useEffect(() => {
		setAllSpells(getAllSpiritSpells(props.campaign).filter(function (spell) {return !spell.restricted || props.character.cults.findIndex(obj => obj.parentId === spell.cult.parentId) > -1}))
	},[props.currentSpells]);

	useEffect(() => {
		var usage = 0;
		if (selected && selected.length > 0) {
			selected.forEach(spell => {
				usage += spell.points;
			});
		}
		setAvailableStorage(props.storage - usage);
	},[selected, props.storage]);

	function handleCancelClick(){
		props.close();
	}
	  
	function handleOkClick(){
		props.setSpells([...selected]);
		props.close();
	}

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", padding: "5px"}}>
			<div style={{fontSize:"16px", margin: "10px"}}>{"Select the spirit spells known by this character"}</div>
			<hr />
			<div>
				<SpiritSpellPanel campaign={props.campaign} currentSpells={selected} availableSpells = {allSpells} setSpells={()=> {}} availablePoints={availableStorage} select={(updatedSelected) => {setSelected(updatedSelected)}} />
				<div style={{display:"flex", marginTop:"30px", padding: "20px", justifyContent:"space-between"}}>
					<div style={{display:"flex", backgroundColor: "#f0f0f0", borderRadius: "5px", border: "2px solid #851113", alignItems: "center", padding: "10px"}}>Available Points:&nbsp;{availableStorage}</div>
					<div style={{display:"flex", flexDirection: "row-reverse", alignItems:"flex-end"}}>
						<Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
						<Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageSpiritSpellDialog;