import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { pink } from '@mui/material/colors';

const OptionsRadioGroup = (props) => {
    
    const [selected, setSelected] = useState({name: ""});
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions([...props.item.options]);
    }, [props.item.options]);

    useEffect(() => {
        if (props.item.selected && props.item.selected.name) {
            setSelected(props.item.selected)
        }
        else {
            setSelected({name: ""})
        }
    }, [props.item.selected]);

    function handleChange(event) {
        options.forEach((option) => {
            if (option.name === event.target.value) {
                setSelected(option);
                props.update(option, props.index);
            }
        });
    }

    const controlProps = (item) => ({
        checked: selected.name === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });
    
    var border = selected.name ? "3px solid transparent" : "3px solid #851113";
    return (
            <div style={{display:"flex", alignItems: "center", overflow: "hidden", border: border, borderRadius: "5px", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px", paddingLeft: "10px"}}>
                <FormControl component="fieldset" style={{width: "320px"}}>
                    <RadioGroup aria-label={"Select" } row color="primary" value={selected.name} name="events" onChange={handleChange}>
                        {
                            options.map((option, index) => {
                                if (props.stats) {
                                    var minSTR = option.minSTR || 0;
                                    var minDEX = option.minDEX || 0;
                                    var lowStats = minSTR > props.stats.STR;
                                    if (!lowStats) {
                                        if (minDEX > props.stats.DEX) {
                                            if ((props.stats.STR - minSTR) < ((minDEX - props.stats.DEX) *2)) {
                                                lowStats = true;
                                            }
                                        }
                                    }
                                    if (lowStats) {
                                        props.setLowStats(lowStats);
                                    }
                                }
                                return (
                                    <FormControlLabel key={index} value={option.name} control={<Radio {...controlProps(option.name)} />} label={<div style={{color: lowStats ? "#851113" : "#121312"}}>{option.name + (lowStats ? "**" : "")}</div>} />
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
                <div style={{width:"5%", display:"flex", alignItems: "center", fontWeight:700}}>+{props.item.bonus}</div>
            </div>
    );
}

export default OptionsRadioGroup;