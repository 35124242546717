import React, {useState, useEffect} from 'react'
import { FormControl, Select, MenuItem } from '@mui/material';

const Selector = (props) => {
    
    const [value, setValue] = useState("Select");

    useEffect(() => {
      if (props.value) {
        setValue(props.value);
      }
    },[props.value])

    const update = (event) => {
        setValue(event.target.value);
        props.updateSelected(event.target.value);
    }

    if (props.values) {
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                {props.label ? 
                <div style={{marginRight: props.labelMargin ? props.labelMargin : "10px", width: "max-content", whiteSpace:"nowrap"}}>{props.label}</div>
                : 
                    null
                }
                <FormControl size="small">
                <Select
                    disabled={props.disabled}
                    value={value}
                    onChange={update}
                    style={{...props.style, backgroundColor:"white", borderRadius: "5px", border: props.disabled ? "2px solid #898989" :  "2px solid #2F588A", marginTop: "0px" }} 
                >
                    <MenuItem key={0} value="Select" >
                        <em>Select</em>
                    </MenuItem>
                    {
                        props.values.map((item, index) => {
                        var name = item.title ? item.title : item.name + (item.variant ? " " + item.variant : "");
                            return (
                                <MenuItem key={index + 1} value={name}>{name}</MenuItem>
                            )            
                        })
                    }
                    </Select>
                </FormControl>
            </div>
        )
    }
    else return null;
}

export default Selector