import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Styles from './styles.module.css'
import { withFirebase } from '../Firebase';
import DialogBox from '../Dialogs/dialogBox';
import ForgotPasswordDialog from './passwordForgotDialog';

const PasswordForget = (props) => {

  const [dialog, setDialog] = useState(false);
  const [message, setMessage] = useState();

  function openDialog() {
    setDialog(true);
  }

  function handleDialogClose(result) {
    if (typeof(result) === "string") {
      setMessage("A password reset link has been sent to " + result);
    }
    setDialog(false);
  }

  return (
    <div>
      <p className={Styles.link} > 
        <Link onClick={openDialog}>Forgot Password?</Link>
      </p>
      <DialogBox isOpen={dialog} handleClose={handleDialogClose} subtitle='' title='Reset Password'>
        <ForgotPasswordDialog firebase={props.firebase} closeDialog={handleDialogClose}></ForgotPasswordDialog>
      </DialogBox>

      {message ? 
        <div>{message}</div>
        :null
      }
    </div>
  );
}

export default withFirebase(PasswordForget);
