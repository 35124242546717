import React, {useState, useEffect} from 'react';
import ArmourListItem from './armourListItem';

export default function ArmourList(props) {

    const [types, setTypes] = useState([]);
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (props.types) {
            setTypes([...props.types]);
        }
    },[props.types]);
    
    const selectArmour = (armour, checked) => {
        var updatedArmours = [...props.allArmour];
        var index = updatedArmours.findIndex(item => item.id === armour.id)
        updatedArmours[index].equipped = checked;
        props.setSelectedArmours(updatedArmours);
    };

    if (props.allArmour) {
        return (
            <>
            <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "18px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Armour</div>
                <div>
                    <div style={{display:"flex", fontSize: "15px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                        <div style={{width:"220px", marginLeft:"5px", textAlign:"left"}}>Item</div>
                        <div style={{width:"90px", textAlign:"center"}}>Points</div>
                        <div style={{width:"90px", textAlign:"center"}}>ENC</div>
                        <div>Details</div>
                    </div>

                    {types && types.length > 0 ? 
                        types.map((type, typeIndex) => {
                            var items = props.allArmour.filter(function(armour) {
                                return armour.area === type;
                            })
                            return (
                                <div key={typeIndex}>
                                    <div style={{backgroundColor: "#676767", color: "#fff", height: "30px", width: "100%", paddingLeft: "5px", display: "flex", alignItems: "center"}}>{type}</div>
                                    {                        
                                        items.map((item, index) => {
                                            return (
                                                <ArmourListItem key={index} item={item} select={selectArmour} selected={props.selected} noSelect={props.noSelect} add={props.add} setRestricted={setRestricted} />
                                            )
                                        })
                                    }    
                                </div>                
                            )
                        })
                    : 
                        props.allArmour.map((item, index) => {
                            return (
                                <ArmourListItem key={index} item={item} select={selectArmour} selected={item.equipped} noSelect={props.noSelect} buttons={props.buttons} setRestricted={setRestricted} />
                            )
                        })
                    }
                </div>
                {restricted ?
                    <div style={{color: "#851113", padding: "5px"}}>** Insufficient STR and/or DEX</div>
                : 
                null}
            </>
        )
    }
    else {
        return null;
    }
}