import React, { useState } from 'react';
import { withPageState } from '../Context/PageState';
import * as SAVE from '../../constants/save';
import EditBox from '../Controls/editBoxSingle';
import ConfirmationDialog from '../Dialogs/confirmationDialog';

const StorageCrystal = (props) => {

    const [open, setOpen] = useState(false);

    function update(field, value) {
        var updates = [];
        var index = props.character.crystals.findIndex(item => item.id === props.crystal.id);
        if (index > -1) {
            var updatedCrystals = [...props.character.crystals]
            updatedCrystals[index][field] = value;
            updates.push({fieldName: "crystals", value: updatedCrystals});
        }
        if (field === "POW") {
            var updatedMagicPoints = [...props.character.magicPoints];
            updatedMagicPoints.forEach(points => {
                if (points.entityId === props.crystal.id) {
                    points.total = value;
                    if (points.current > value) {
                        points.current = value;
                    }
                }
            })
            updates.push({fieldName: "magicPoints", value: updatedMagicPoints})
        }
        props.update(updates, SAVE.NO)
    }

    function confirmDelete() {
        setOpen(false);
        props.delete(props.crystal);
    }

    if (props.crystal) {
        var index = props.character.magicPoints.findIndex(item => item.entityId === props.crystal.id)
        return (
            <>
                <div style={{padding: "7px"}}>
                    <div style={{display:"flex", alignItems: "center"}}>
                        {props.pageState.editMode ?
                            <>
                                <div>Location:&nbsp;</div>
                                <EditBox value={props.crystal.location} onChange={(value) => {update("location", value)}} width="300px" />
                            </>
                        : 
                        <div style={{display:"flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                            <div> {props.crystal.location}</div>
                            <div style={{display:"flex"}}>
                            <div>POW:&nbsp;</div>
                            {props.pageState.editMode ?
                                <div style={{marginLeft: "26px", width: "80px"}}>
                                    <EditBox number value={props.crystal.POW} onChange={(value) => {update("POW", value)}} />
                                </div>
                                :
                                <div> {props.crystal.POW}</div>
                            }
                        </div>
                        </div>
                        }
                    </div>
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                    </div>
                    {/* {props.pageState.editMode ?
                        <>
                            <div style={{marginTop: "10px"}}>Notes:&nbsp;</div>
                            <EditBox multiline rows={8} value={props.crystal.notes} onChange={(value) => {update("notes", value)}} width="340px" />
                        </>
                    : 
                        <div style={{marginTop: "10px"}}><i>{props.crystal.notes}</i></div>
                    } */}
                </div>
                <ConfirmationDialog confirm={confirmDelete} cancel={() => setOpen(false)} open={open} type="crystal" />
            </>
        )
    }
    else {
        return null;
    }
}

export default withPageState(StorageCrystal);