import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, FormControl, Select, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { getAllPassions } from '../../../services/dataService';
import { withContext } from '../../Context/context';

const AddPassionDialog = (props) => {

	const [items, setItems] = useState([]);
	const [selection, setSelection] = useState({name: "Select"});
	const [value, setValue] = useState(60);
	const [variant, setVariant] = useState("");

	useEffect(() => {
		var allPassions = getAllPassions(props.providerData.campaign)
		var passions = [];
		allPassions.forEach(passion => {
			passions.push({...passion, name: passion.name});
		})
		setItems(passions);
	},[])

	function handleChange(event) {
		items.forEach(item => {
			if (item.name === event.target.value) {
				setSelection(item);
				return;
			}
		})
	}
  
    function modifyValue(event){
        var previous = value || 0;
        var newValue = parseInt(event.target.value)
        if (! newValue) newValue = previous;
        setValue(newValue);
    }
  
    function modifyFocus(event){
        setVariant(event.target.value);
    }

	function handleCancelClick(){
		props.close();
	}
	  
	function handleOkClick(){
		var newItem;
		if (selection.name === "Select") return;
		items.forEach(item => {
			if (item.name === selection.name) {
				if (!item.variable || variant) {
					newItem = {
						id: uuidv4(), 
						value: value,
						name: item.name,
						parentId: item.id,
						hasSucceeded:false,
						favourite: false
					};
					if (variant) newItem.variant = variant;
				}
				return;
			}
		});
		if (newItem) {
			props.addItem(newItem);
			props.close();
		}
	}

	return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", padding: "10px"}}>
			{items && items.length > 0 ? 
				<div>
					<div style={{display:"flex"}}>
						<FormControl variant="outlined" >
							<Select value={selection.name} style={{backgroundColor:"#fff", minWidth:"10em"}} autoWidth={true} onChange={handleChange} color="primary">
								<MenuItem key={0} value="Select">
									<em>Select</em>
								</MenuItem>
								{ 
									items.map((item, index) => {
										return( <MenuItem key={index} value={item.name}>{item.name}</MenuItem> );
									})
								}
							</Select>
						</FormControl>
						{selection.variable ? 
							<div style={{width:"275px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
								<TextField size="small" inputProps={{style: {fontSize: "16px", width:"275px", height: "48px", padding: "3px", padding: "0px 5px", backgroundColor: "#fff", color: "#121312", borderRadius: "5px", }}} placeholder="Focus" color="primary" value={variant} onChange={modifyFocus} variant="outlined" ></TextField>
							</div>
						: 
							null
						}
							<div style={{width:"100px", display:"flex", alignItems: "center", marginLeft: "20px"}}>
							<TextField size="small" inputProps={{style: {fontSize: "16px", width:"100px", height: "48px", padding: "3px", textAlign:"center", backgroundColor: "#fff", color: "#121312", borderRadius: "5px", }}} placeholder="Modifier" color="primary" value={value} onChange={modifyValue} variant="outlined" ></TextField>
						</div>
					</div>
						<div style={{display:"flex", flexDirection: "row-reverse", padding: "10px", alignItems:"flex-end"}}>
							<Button type="submit" onClick={handleOkClick} style={{marginTop:"30px", width: "100px"}} variant="contained">Ok</Button>
							<Button type="submit" onClick={handleCancelClick} color="primary" variant="outlined" style={{marginTop:"30px", width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
						</div>
				</div>
			:
				null
			}
			
		</div>
	);
};

export default withContext(AddPassionDialog);