
const numberGenerator = (characteristic, minValue, maxValue) => {
    let returnValue = 0; 
            var failsafe = 0;
    while (returnValue < minValue) {
        failsafe ++;
        returnValue = 0;
        for (var i =0; i < characteristic.quantity; i++) {
            let generatedNumber = Math.ceil(Math.random()*(6));
            returnValue += generatedNumber;
        }
        if (returnValue > maxValue){
             returnValue = 0;
            }
            if (failsafe > 99){
                return ;
            }
        }
        return returnValue;
}

export default numberGenerator;