
import * as NamesJSON from '../../data/names.json';

const namesData = {...NamesJSON};

const generateName = (namesList, gender) => {
	var prefix, suffix;
	var index = namesData.data.findIndex(obj => obj.name === namesList)
	if (index > -1) {
		var prefixList = gender === "Male" ? namesData.data[index].malePrefix : namesData.data[index].femalePrefix
		var suffixList = gender === "Male" ? namesData.data[index].maleSuffix : namesData.data[index].femaleSuffix

		if (prefixList.length > 1) {
			var rnd = Math.floor(Math.random()*(prefixList.length));
			prefix = prefixList[rnd];
		}
		else {
			prefix = prefixList[0];
		}

		if (suffixList.length > 1) {
			var rnd = Math.floor(Math.random()*(suffixList.length));
			suffix = suffixList[rnd];
		}
		else {
			suffix = suffixList[0];
		}
		return {first: prefix + suffix, last: ""}
	}
}

export default generateName;