import React, { useState} from 'react';
import ResetIcon from '../../../icons/reset-icon.svg';
import globalStyles from '../../Styles/styles.module.css'
import ForestIcon from '@mui/icons-material/Forest';
export default function CampaignButton(props) {
   
    if (props.campaignId) {
        return (
            <a href={"/campaign/" + props.campaignId}>
                <div  className={globalStyles.floatingButton} title="View campaign">
                    <ForestIcon style={{width: "32px", height: "32px", color: "#2f588a"}} />
                </div>
            </a>
        );
    }
    else {
        return null;
    }
}