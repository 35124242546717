import * as weaponsData from '../data/weapons.json';
import { v4 as uuidv4 } from 'uuid';

const allWeapons = {...weaponsData};

export function newWeaponSkill(effect, character) {
    var skillIndex = character.weaponSkills.findIndex(obj => obj.parentId === effect.weaponSkill);
    var updatedWeaponSkills = [...character.weaponSkills];
    if (skillIndex < 0) {
        var weaponIndex = allWeapons.data.findIndex(obj => obj.id === effect.weaponSkill);
        var weaponDetails = allWeapons.data[weaponIndex];
        updatedWeaponSkills.push({
            category: weaponDetails.category,
            hasSucceeded: false,
            id: uuidv4(),
            name: weaponDetails.name,
            parentId: weaponDetails.id,
            skillCategory: "Manipulation",
            value: weaponDetails.baseValue
        });
    }
    return updatedWeaponSkills;
}

export function newWeapon(effect, character) {
    var index = character.weapons.findIndex(obj => obj.id === effect.item.id)
    var updatedWeapons = [...character.weapons];
    if (index < 0) {
        var weaponIndex = allWeapons.data.findIndex(obj => obj.id === effect.item.id);
        var details = allWeapons.data[weaponIndex];
        updatedWeapons.push({
            ENC: 0,
            baseValue: details.baseValue,
            category: details.category,
            class: details.class,
            damage: {...details.damage},
            equipped: true,
            hidden: true,
            hitPoints: {...details.hitPoints},
            id: uuidv4(),
            minDEX: details.minDEX,
            minSTR: details.minSTR,
            name: details.name,
            parentId: effect.weaponSkill,
            range: details.range,
            rate: details.rate,
            sr: details.sr,
            title: details.title || details.name,
            type: details.type
        });
    }
    return updatedWeapons
}

export function enchantmentEffectApplies(enchantment, effect, weapon) {
    let valid = true;
    if (effect.item) {
        if (effect.item.id !== weapon.id) {
            valid = false;
        }
    }
    else if (enchantment.filter) {
        if (enchantment.filter.classes) {
            if (enchantment.filter.classes.indexOf(weapon.class) < 0) {
                valid = false;
            }
        }
        if (valid && enchantment.filter.damageTypes) {
            if (enchantment.filter.damageTypes.indexOf(weapon.damage.type) < 0) {
                valid = false;
            }
        }
        if (valid && enchantment.filter.categories) {
            if (enchantment.filter.categories.indexOf(weapon.type) < 0) {
                valid = false;
            }
        }
    }
    return valid;
}

