import React, { useState, useEffect } from 'react';
import SelectHomelandDialog from '../dialogs/selectHomelandDialog';
import SelectOccupationDialog from '../dialogs/selectOccupationDialog';
import DialogBox from '../../Dialogs/dialogBox';
import SmallBuilderCard from './smallBuilderCard';
import SmallHomelandCard from './smallHomelandCard';

const GrandParent = (props) => {
    const [openHomeland, setOpenHomeland] = useState(false);
    const [openOccupation, setOpenOccupation] = useState(false);
    const [occupations, setOccupations] = useState([]);

    useEffect(() => {
        setOccupations([...props.occupations])
    },[props.occupations]);

    function selectGrandparent(selected){
        var gp = {...props.grandparent};
        gp.relative = selected;
        gp.title = (selected === ("pm" || "mm")) ? "grandmother" : "grandfather"; 
        props.update(gp);    
    };

    function changeHomeland() {
        setOpenHomeland(true);
    }

    function changeOccupation() {
        setOpenOccupation(true);
    }

    function selectHomeland(selectedHomeland, selectedTribe) {
        var updatedGrandparent = {...props.grandparent};
        updatedGrandparent.homeland = selectedHomeland
        updatedGrandparent.tribe = selectedTribe
        props.update(updatedGrandparent);
    }

    function selectOccupation(selectedOccupation) {
        var updatedGrandparent = {...props.grandparent};
        updatedGrandparent.occupation = selectedOccupation;
        props.update(updatedGrandparent);
    }

    if (props.grandparent) {
        return (
            <>
                <div style={{textAlign:"center", borderRadius: "10px", border: "5px solid #2F588A", width: "540px", padding: "10px 0px 10px 16px", marginBottom: "20px"}}>
                    <div style={{marginBottom: "10px", fontSize: "20px"}}>Select grandparent</div>
                    <div style={{display:"flex", margin: "10px"}}>
                        <SmallBuilderCard selected={props.grandparent && props.grandparent.relative === "pf"} select={() => selectGrandparent("pf")} title="Paternal Grandfather" imageSrc="https://storage.googleapis.com/runequest/builder/grandfather1.png" obscure={props.grandparent.relative && props.grandparent.relative != "pf"}/>
                        <div style={{marginLeft: "20px"}}>
                            <SmallBuilderCard selected={props.grandparent && props.grandparent.relative === "pm"} select={() => selectGrandparent("pm")} title="Paternal Grandmother" imageSrc="https://storage.googleapis.com/runequest/builder/grandmother1.png" obscure={props.grandparent.relative && props.grandparent.relative != "pm"} />
                        </div>
                    </div>
                    <div style={{display:"flex", margin: "10px"}}>
                        <SmallBuilderCard selected={props.grandparent && props.grandparent.relative === "mf"} select={() => selectGrandparent("mf")} title="Maternal Grandfather" imageSrc="https://storage.googleapis.com/runequest/builder/grandfather2.png" obscure={props.grandparent.relative && props.grandparent.relative != "mf"} />
                        <div style={{marginLeft: "20px"}}>
                            <SmallBuilderCard selected={props.grandparent && props.grandparent.relative === "mm"} select={() => selectGrandparent("mm")} title="Maternal Grandmother" imageSrc="https://storage.googleapis.com/runequest/builder/grandmother2.png" obscure={props.grandparent.relative && props.grandparent.relative != "mm"} />
                        </div>
                    </div>
                    <div style={{display:"flex", margin: "10px", marginTop: "40px"}}>
                        <SmallHomelandCard selected select={() => changeHomeland("props.grandparent")} ancestor="props.grandparent" title="Homeland" homeland={props.grandparent.homeland} tribe={props.grandparent.tribe} imageSrc={props.grandparent.homeland.imageLink} changeText />
                        <div style={{marginLeft: "20px"}}>
                            <SmallBuilderCard selected select={() => changeOccupation("props.grandparent")} occupation title="Occupation" name={props.grandparent.occupation.name} imageSrc={props.grandparent.occupation.imageLink} changeText />
                        </div>
                    </div>
                </div>
                <DialogBox isOpen={openHomeland} size="sm" title="Select Homeland">
                    <SelectHomelandDialog homelands={props.homelands} close={() => setOpenHomeland(false)} handleSelect={selectHomeland} homeland={props.grandparent.homeland} tribe={props.grandparent.tribe} />
                </DialogBox>
                <DialogBox isOpen={openOccupation} width="940px" title="Select Occupation">
                    <SelectOccupationDialog occupations={occupations} close={() => setOpenOccupation(false)} handleSelect={selectOccupation} occupation={props.grandparent.occupation} relative="grandparent" />
                </DialogBox>
            </>
        );
    }
    else {
        return null;
    }
}

export default GrandParent;