import React, {useState, useEffect} from "react";
import { withContext } from "../Context/context";

const ConditionPanel = (props) => {

    const [condition, setCondition] = useState();

    useEffect(() => {
        var newCondition = {name: "", severity: -1};
        props.providerData.character.conditions.forEach(condition => {
            if (condition.severity > newCondition.severity) {
                switch(condition.severity) {
                    case 1: 
                        newCondition = {name: "Demoralized", severity: condition.severity};
                        break; 
                    case 2: 
                        newCondition = {name: "Befuddled", severity: condition.severity};
                        break;
                    case 10: 
                        newCondition = {name: "Prone", severity: condition.severity};
                        break;
                    case 14: 
                        newCondition = {name: "Diseased", severity: condition.severity};
                        break;
                    case 16: 
                        newCondition = {name: "Poisoned", severity: condition.severity};
                        break;
                    case 20: 
                        newCondition = {name: "Incapacitated (may heal)", severity: condition.severity};
                        break;
                    case 30: 
                        newCondition = {name: "Incapacitated", severity: condition.severity};
                        break; 
                    case 40: 
                        newCondition = {name: "Coughing blood", severity: condition.severity};
                        break;
                    case 50: 
                        newCondition = {name: "Unconcious", severity: condition.severity};
                        break;
                    case 60: 
                        newCondition = {name: "Dead", severity: condition.severity};
                        break;
                }

            }
        })
        setCondition(newCondition);
    },[props.providerData.character.conditions]);


    if (condition && condition.severity > -1) {
        return (
            <div style={{position: "absolute", top: "35px", left: "30px", fontSize: "76px", color: "#851113", transform: "rotate(-12deg)"}}>{condition.name}</div>
        )
    }
    else return null;
}

export default withContext( ConditionPanel);