import React, { useState, useEffect } from 'react';
import DialogBox from '../../Dialogs/dialogBox';
import WeaponPickerDialog from '../../Dialogs/weaponPickerDialog';
import { withContext } from '../../Context/context';

const WeaponSpell = (props) => {

    const [open, setOpen] = useState(false);
    const [character, setCharacter] = useState();
    const [selection, setSelection] = useState();
    const [remember, setRemember] = useState(true);
    const [diceRoller, setDiceRoller] = useState({
        diceResults: {},
        setDice: setDice
    });

    useEffect(() => {
        if (props.diceRoller && Object.keys(props.diceRoller.diceResults).length > 0) {
            setDiceRoller(props.diceRoller);
        }
        else {setDiceRoller({
            diceResults: {},
            setDice: setDice
        });}
    },[props.diceRoller.diceResults])

    function setDice() {
        setOpen(true);
    }

    function processResult(dieRoll, spellDetails, title) {
        var updatedDieRoll = {...dieRoll};
        if (character) {
            if (character.id !== props.providerData.character.id) {
                updatedDieRoll.messageText.target = character.name.first;
            }
            else {
                var gender = character.personalInfo.gender === "Male" ? "him" : character.personalInfo.gender === "Female" ? "her" : "them";
                gender += "self";
                updatedDieRoll.messageText.target = gender;
            }
        }
        var updatedSpellDetails = {...spellDetails};
        if (remember && character) {
            updatedSpellDetails.remember = remember;
            updatedSpellDetails.targetCharacter = character;
            updatedSpellDetails.selection = selection;
        }
        setDiceRoller({
            diceResults: {},
            setDice: setDice
        })
        props.processResult(updatedDieRoll, updatedSpellDetails, title)
    }

    function processSpell(remember, character, selection) {
        setOpen(false);
        setCharacter(character);
        setRemember(remember);
        setSelection(selection)
        props.diceRoller.setDice({d100: 1, d10: 1})
    }
    
    return (
        <div>
            {React.cloneElement(props.children, {...props, diceRoller: diceRoller, processResult})}
            <DialogBox isOpen={open} width="600px" title="Select character and weapon">
                <WeaponPickerDialog title="Select Character &amp; Weapon" spell={props.item} selection="weapons" processSpell={processSpell} weaponSpell close={() => setOpen(false)} />
            </DialogBox>
        </div>
    )
}

export default withContext(WeaponSpell);