import React from 'react';
import InventoryArmourListItem from './inventoryArmourListItem';
import AddItemButton from '../Characters/buttons/addItemButton';
import AddArmourDialog from '../Characters/dialogs/addArmourDialog';
import { withPageState } from '../Context/PageState';

const InventoryArmourList = (props) => {
    
    const handleChange = (armour, checked) => {
        var updatedArmour = [...props.armour];
        var index = updatedArmour.findIndex(item => item.parentId === armour.parentId)
        updatedArmour[index].equipped = checked;
        props.setArmour(updatedArmour);
    };
    
    const deleteArmour =(armourPiece) => {
        var updatedArmour = [...props.armour];
        var index = updatedArmour.findIndex(obj => obj.id === armourPiece.id)
        updatedArmour.splice(index, 1);
        props.setArmour(updatedArmour);
    }

    return (
        <>
                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "18px", display:"flex", justifyContent: "space-between", alignItems: "center", padding: "0px 5px"}}>
                    <div>Armor</div>
                    {props.pageState.editMode ?
                        <AddItemButton title="Add armor" addTitle="Add armor" addItem={props.addArmour} edit={props.edit} width="900px" style={{marginRight: "5px"}}>
                            <AddArmourDialog />
                        </AddItemButton>
                    : 
                    null}
                </div>
            <div>
                <div style={{display:"flex", fontSize: "15px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                    <div style={{width:"150px", marginLeft:"5px", textAlign:"left"}}>Item</div>
                    <div style={{width:"60px", textAlign:"center"}}>Points</div>
                    <div style={{width:"60px", textAlign:"center"}}>ENC</div>
                    {props.pageState.editMode ?
                        <div style={{width:"80px"}}>Delete</div>
                        :
                        <div style={{width:"80px"}}>Equipped</div>
                    }
                </div>

                {props.armour.map((item, index) => {
                    return (
                        <InventoryArmourListItem key={index} item={item} select={handleChange} delete={deleteArmour} />
                    )
                })}
            </div>
        </>

    )
}

export default withPageState(InventoryArmourList);