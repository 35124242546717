import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button } from '@mui/material';

const SelectVariantDialog = (props) => {

	const [variant, setVariant] = useState("");

    function modifyValue(event){
        setVariant(event.target.value);
    }

	function handleOkClick(event){
		event.preventDefault();
		event.stopPropagation();
		props.setVariant(variant);
	}

	function close(event) {
		event.preventDefault();
		event.stopPropagation();
		props.close();
	}

	return (
		<form>
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
				<div style={{fontSize: "18px", padding: "10px"}}>Enter the skill specialization</div>
			<div style={{color: "#fff", alignItems:"center"}}>
				<div style={{display:"flex", alignItems: "center", padding: "10px", width: "360px"}}>
					<TextField autoFocus inputProps={{style: {width:"360px", height: "30px", padding: "3px", paddingRight:"10px", borderRadius: "5px", backgroundColor:"#fff"}}} placeholder="Enter skill" color="primary" value={variant} onChange={modifyValue} variant="outlined" size="small"></TextField>
				</div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "20px"}}>
					<Button type="submit"  disabled={!variant}  style={{width: "100px", backgroundColor:"#2F588A", color:"#fff"}} variant="contained" onClick={handleOkClick}>Ok</Button>
					<Button type="cancel" onClick={close} style={{width: "100px", backgroundColor:"#898989", color:"#fff", marginRight: "10px"}} variant="contained">Cancel</Button>
				</div>
			</div>
		</div>
		</form>
	);
}

export default SelectVariantDialog;