import React, {useState, useEffect, useRef} from 'react';
import { withFirebase } from '../Firebase';
import { Button, Switch, FormGroup, FormControlLabel, FormControl, Checkbox } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import EditBox from '../Controls/editBoxSingle';
import Selector from '../Controls/dropDown';
import { withPageState } from '../Context/PageState';
import { getAllCults, getAllSkills } from '../../services/dataService';
import DescriptionDialog from './descriptionDialog';

const skillCategories = [{name: "Agility"}, {name: "Communication"}, {name: "Knowledge"}, {name: "Magic"}, {name: "Manipulation"}, {name: "Perception"}, {name: "Stealth"}];

const CustomSkillContextPanel = (props) => {

	const [openDescription, setOpenDescription] = useState(false);
	const [skill, setSkill] = useState({name: "", baseValue: 0, skillCategory: "", canLearn: true, restricted: false, variable: false, cult: {}});
	const [customContent, setCampaign] = useState(props.customContent);
	const [restricted, setRestricted] = useState(false);
	const [variableSkills, setVariableSkills] = useState([]);

	const infoBox = useRef(null)

	useEffect(() => {
		if (props.panelItem) {
			setSkill(props.panelItem.item);
			setRestricted(props.panelItem.item.restricted);
		}
	},[props.panelItem]);

	useEffect(() => {
		let variable = [];
		getAllSkills(props.customContent).forEach(skill => {
			if (skill.variable) {
				variable.push({...skill});
			}
		});
		setVariableSkills(variable);
	},[])

    useEffect(() => {
        if (props.buttonClick === "ok") {
			props.update(customContent, true);
        }
    },[props.buttonClick])

	function setValue(field, value) {
		var updatedSkill = {...skill}
		updatedSkill[field] = value;
		setSkill(updatedSkill);
		props.update(updatedSkill, "skills")
	}

	function updateRestricted(event) {
		setRestricted(event.target.checked);
		setValue("restricted", event.target.checked)
	}

    function applyDescription(editorContent, title, index) {
        var updatedSkill = {...skill}
        updatedSkill.description = editorContent;
        setSkill(updatedSkill);
		props.update(updatedSkill, "skills")
    }

	if (skill) {
		return (
			<>            
				<div style={{fontSize: "14px", lineHeight: "1"}}>
					<div style={{height: "800px", overflow: "auto"}}>
						<div style={{display: "flex", alignItems: "center"}}>
							{props.pageState.editMode ? 
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{marginRight: "23px", fontWeight: "bold"}}>Skill name: </div>
									<EditBox size="sm" width="220px" value={skill.name} onChange={(value) => setValue("name", value)} />
								</div>
							: 
								<div style={{display: "flex", alignItems: "center"}}>
									<div style={{marginRight: "10px", fontWeight: "bold"}}>Skill name: </div>
									<div>{skill.name}</div>
								</div>
							}
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center", width: "50%"}}>
								<div style={{marginRight: "20px", fontWeight: "bold"}}>Base Value: </div>
								{props.pageState.editMode ? 
									<EditBox size="sm" number width="50px" value={skill.baseValue} onChange={(value) => setValue("baseValue", value)} />
									: 
									<div>{skill.baseValue}</div>
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel style={{marginLeft: "0px"}} control={<Switch color="primary" checked={skill.variable} onChange={(event) => setValue("variable", event.target.checked)}></Switch>} labelPlacement='start' label="Specialization Required"></FormControlLabel>
									</FormGroup>
								:
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Specialization Required: </div>
										<div>{skill.variable ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<FormGroup>
										<FormControlLabel style={{marginLeft: "0px"}} control={<Switch color="primary" checked={skill.canLearn} onChange={(event) => setValue("canLearn", event.target.checked)}></Switch>} labelPlacement='start' label="Can Learn from Experience"></FormControlLabel>
									</FormGroup>
								:
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Can Learn from Experience: </div>
										<div>{skill.canLearn ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<Selector labelMargin="4px" label="Skill Category:" style={{height: "28px", width: "160px"}} value={skill.skillCategory} values={skillCategories} onChange={(item) => {setValue("skillCategory", item.name)}} />
								: 
									<>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Skill Category: </div>
										<div>{skill.skillCategory}</div>
									</>
								}
							</div>
						</div>
						<div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
							<div style={{display: "flex", alignItems: "center"}}>
								{props.pageState.editMode ? 
									<>
										<FormGroup>
											<FormControlLabel style={{marginLeft: "0px"}} control={<Switch color="primary" checked={restricted} onChange={updateRestricted}></Switch>} labelPlacement="start" label="Cult Speciality Skill"></FormControlLabel>
										</FormGroup>
									</>
								:
									<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
										<div style={{marginRight: "10px", fontWeight: "bold"}}>Cult Speciality Skill: </div>
										<div>{restricted ? "Yes": "No"}</div>
									</div> 
								}
							</div>
						</div>
						{restricted ? 
								props.pageState.editMode ? 
									<div style={{marginTop: "10px"}}>
										<Selector label="Select Cult:" style={{height: "28px", width: "200px"}} value={skill.cult && skill.cult.name} values={getAllCults(props.customContent).filter(function(cult) {return ! cult.restricted && !cult.parentCultId})} onChange={(item) => {setValue("cult", {name: item.name, parentId: item.id})}} />
									</div>
								: 
								<div style={{marginTop: "10px"}}>
									<b>Restricted to:&nbsp;</b>{skill.cult && skill.cult.name}
								</div>
						: 
							null
						}

						<div style={{marginTop: "20px"}}>
							{props.pageState.editMode ? 
							<>
								<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
									<div style={{fontWeight: "bold"}}>Description</div>
									<Button variant='contained' color='primary' onClick={() => setOpenDescription(true)}>Edit Description</Button>
								</div>
								<div style={{marginTop: "20px"}} dangerouslySetInnerHTML={{__html: skill.description}}></div>
							</>
						: 
							<>
								<div style={{fontWeight: "bold", marginBottom: "10px"}}>Description</div>
								<div dangerouslySetInnerHTML={{__html: skill.description}}></div>
							</>
						}
						</div>
					</div>
				</div>
				<DialogBox isOpen={openDescription} width="800px" title="Edit Description">
					<DescriptionDialog close={() => setOpenDescription(false)}  update={applyDescription} description={skill.description} />
				</DialogBox>
 			</>
		)
	}
	else {
		return null;
	}
}

export default withFirebase(withPageState(CustomSkillContextPanel));