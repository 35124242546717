import React, {useState, useEffect} from 'react';
import globalStyles from '../Styles/styles.module.css';
import { withFirebase } from '../Firebase';
import { withPageState } from '../Context/PageState';
import NPCCard from './npcCard';
import d10Icon from '../../icons/d10-white-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import {getResultType, postMessage, postDamageMessage} from '../../services/dieRollService';

const Encounter =(props) => {

    const [NPCs, setNPCs] = useState([]);
    const [current, setCurrent] = useState(-1);

    useEffect(() => {
        if (props.encounter.id) {
            var npcs = [];
            props.firebase
            .npcs()
            .where("encounterId", "==", props.encounter.id)
            .orderBy("rank", "desc")
            .get()
            .then(querySnapshot => 
            {
            querySnapshot.forEach(function(doc) {
                const id = doc.id;
                const data = doc.data();
                var npc = {id, ...data}
                npcs.push(npc);
            });
            setNPCs(npcs);
            });
        }
    
    },[props.encounter]);

    const notify = (index) => { 
        setCurrent(index)
    }

    return (
        <>
            <div className={globalStyles.panel} style={{display: "flex", flexWrap: "wrap", paddingLeft: "2px", overflow: "auto"}}>
                {NPCs.map((item) => {
                    return (
                        <div key={item.id}>
                            <div style={{width:"500"}}>
                                <div style={{ height: 'auto', width:'auto'}}>
                                {
                                    <NPCCard saveMode={props.saveMode} saveNPC={props.saveNPC} diceRoller={props.diceRoller} augmenting={props.augmenting} activeEnemies={props.activeEnemies} displayItem={props.displayItem} closePanel={props.closePanel} context={{notify:notify, current:current, itemIndex:0}} alliedCharacters={NPCs} encounter={props.encounter} pageState={props.pageState} encounterId={props.encounter.id} npc={item} authUser={props.authUser} processResult={props.processResult} processDamage={props.processDamage}></NPCCard>
                                }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default withFirebase(withPageState(Encounter));