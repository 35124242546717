import React, { useEffect, useState } from 'react';
import { withPageState } from '../Context/PageState';
import DiceButton from '../Buttons/diceButton';
import styles from './character.module.css'
import {withContext} from '../Context/context';
import { ReputationTile } from './tiles/reputationTile';

const Reputation = (props) => {

    const [reputation, setReputation] = useState(-1);

    useEffect(() => {
        setReputation(props.entity.reputation);
    },[props.entity]);

    function processResult(roll){
        var dieRoll = {target: roll.target, result: roll.result, augmentRoll: false}
        var pronoun = props.entity.personalInfo.gender === "Male" ? "his " : props.entity.personalInfo.gender === "Female" ? "her " : "their ";
        var messageText={critical: " achieved a critical success with " + pronoun + " reputation", special: " achieved a special success with " + pronoun + " reputation", success: " succeeded with " + pronoun + " reputation", failure: " failed with " + pronoun + " reputation", fumble: " fumbled " + pronoun + " reputation"};
        dieRoll.messageText = messageText;
        props.processResult(dieRoll, {name: "Reputation", type: ""}, "Reputation Check");
    }

        return(
            <div>
                { props.pageState && props.pageState.editMode ? 
                    <div className={styles.row} style={{display:"flex", height: "40px", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px"}}>
                    </div>
                :
                <DiceButton style={{minWidth: "120px", borderRadius: "5px"}} minimum={0} diceRoller={props.diceRoller} entity={props.entity} disabled={reputation === 0} processResult={processResult} item={{name: "Reputation", value: reputation}} type="Reputation" >
                    <ReputationTile disabled={reputation === 0} />
                </DiceButton>
                }
            </div>
        );
    }


    export default withPageState(withContext(Reputation));