import React, {useState, useEffect, useRef} from 'react';
import globalStyles from '../Styles/styles.module.css';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PinnedIcon from '../../icons/pinned-icon.png';
import UnpinnedIcon from '../../icons/unpinned-icon.svg';
import CloseIcon from '../../icons/close-icon.svg';
import { withFirebase } from '../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import DialogBox from '../Dialogs/dialogBox';
import AdventuresDialog from './adventuresDialog';

const AdventureContextPanel = (props) => {

	const [adventures, setAdventures] = useState([]);
	const [adventure, setAdventure] = useState({startDate: {}});
	const [open, setOpen] = useState(false);
	const [currentChapter, setCurrentChapter] = useState("");

	useEffect(() => {
		if (props.campaign) {
			props.campaign.chapters.forEach(chapter => {
				if (chapter.id === props.campaign.currentChapterId) {
					setAdventures([...chapter.adventures])
					setCurrentChapter(chapter.name)
				}
			})
		}
	},[props.campaign])

	function updateAdventures(updatedAdventures) {
		var updatedCampaign = {...props.campaign}
		updatedCampaign.chapters.forEach(chapter => {
			if (chapter.id === props.campaign.currentChapterId) {
				chapter.adventures = [...updatedAdventures]
			}
		});
		props.update(updatedCampaign)
	}

	function editAdventure(adventure) {
		setAdventure(adventure);
		setOpen(true);
	}

	function addAdventure() {
		setOpen(true);
	}

    return (
		<>
            <div style={{fontSize: "14px", lineHeight: "1"}}>
                <div style={{height: "800px", overflow: "auto", padding: "5px"}}>
					<h3>Adventures</h3>
					<div>Each adventure is a collection of scenes and encounters. Use the Adventure Content section to organise the content of your adventure, and the encounters tab to manage the encounters that may occur as part of this adventure.</div>
					<div>
					<hr />
					<div style={{margin: "10px 0px"}}>
						{props.campaign.currentChapterId ? 
							<div>{"Current Chapter: " + currentChapter}</div>
						: 
						<div></div>}
					</div>
					<hr />
                    <div style={{margin: "10px 0px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
						<div>Add new adventure:</div>
						<Button disabled={!currentChapter} onClick={addAdventure} style={{width: "100px"}} color="primary" variant="contained">Add</Button>
						</div>
					<hr />

						{/* <div style={{margin: "10px 0px", display: "flex", flexDirection: "row-reverse"}}>
							<Button onClick={addAdventure} style={{width: "100px"}} color="primary" variant="contained">Add</Button>
						</div> */}
						<div id="header" style={{display: "flex", alignItems: "center", fontWeight: "bold", marginTop: "10px"}}>
							<div style={{width: "400px"}}>Name</div>
						</div>
						<div className={globalStyles.panel}  style={{height: "500px", overflow: "auto"}}>
							{adventures.map((adventure, index) => {
								return(
									<div key={index} id="row" style={{display: "flex", alignItems: "center", padding: "10px 0px"}}>
										<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
											<div style={{width: "400px"}}>{adventure.name}</div>
											<ModeEditIcon style={{color: "#2f588a", height: "24px", width: "24px", cursor: "pointer"}} onClick={() => editAdventure(adventure)} />
										</div>
									</div>
								)
							})}
						</div>
					</div>
                </div>
            </div>
            <DialogBox isOpen={open} width="600px" title={adventure.name ? "Edit Adventure": "Add Adventure"}>
                <AdventuresDialog adventures={adventures} adventure={adventure} close={() => setOpen(false)} update={updateAdventures} />
            </DialogBox>
        </>
    )
}
export default withFirebase(AdventureContextPanel);