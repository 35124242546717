import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

const EditBoxMulti = (props) => {

    const [value, setValue] = useState(props.number ? 0 : "");

    useEffect(() => {
        setValue(props.value);
    },[props.value]);

    function handleChange(event) {
        setValue(event.target.value);
        props.onChange(event.target.value);
  }

    var width = props.width || "100%";
    var editStyle = {width: width, border: "0px"};

    return (
        <div style={{...props.parentStyle, display:"flex", alignItems: "center", width: "100%"}}> 
            <div style={props.style ? props.style : {backgroundColor: "#fff", color: "#121312", borderRadius: "5px", width: "100%", border: "2px solid #2f588a"}}>
                <TextField
                    name="variant"
                    size="small"
                    placeholder={props.placeholder || ""}
                    label={props.label} 
                    value={value} 
                    multiline={true}
                    rows={props.rows || 6}
                    autoFocus={props.autoFocus}
                    onChange={handleChange} 
                    inputProps={{
                        style: editStyle 
                    }} 
                    sx={{width: "100%", border: "0px"}}                                  
                    >
                    {value}
                </TextField>

            </div>
        </div>
    )

};

export default EditBoxMulti;