import React, {useState, useEffect} from "react";
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import globalStyles from '../../Styles/styles.module.css';
import AddItemButton from "../buttons/addItemButton";
import AddRuneDialog from "../dialogs/addNewRuneDialog";
import { Checkbox } from "@mui/material";
import HideButton from "../controls/hideButton";
import EditBox from "../../Controls/editBoxSingle";
import * as SAVE from '../../../constants/save'
import compareValues from '../../../utils/compareValues';

const RunesPanel = (props) => {
    
    const [character, setCharacter] = useState(props.providerData.character);
    
    useEffect(() => {
        setCharacter({...props.providerData.character})
    },[props.providerData.character.runes]);

    const addRune = (newRune) => {
        var currentRunes = [...character.runes]
        var index = currentRunes.findIndex(obj => obj.parentId === newRune.parentId && (!newRune.variant || (obj.variant && obj.variant.toLowerCase() === newRune.variant.toLowerCase())))
        if (index < 0) {
            currentRunes.push(newRune);
            props.update([{fieldName: "runes", value: currentRunes}], SAVE.NO)
        }
    }

    function displayRune(item, index) {
        if (item.hidden && ! props.pageState.editMode) return null;
        return(
            <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                {props.pageState.editMode ? 
                    <HideButton item={item} type="runes" name={item.name + (item.variant ? " (" + item.variant + ")": "")} update={(hidden) => props.hideItem("runes", item, hidden)} />
                : 
                    <Checkbox style={{cursor: "default", width: "18px", padding: "1px"}} checked={false} />
                }
                <div style={{width: "200px", marginLeft: "5px"}}>{item.name}{item.variant ? " (" + item.variant + ")" : ""}{(item.hidden ? " (hidden)" : "")}</div>
                {props.pageState.editMode ? 
                    <div style={{width: "50px"}}><EditBox number style={props.buttonStyle} value={item.value} onChange={(value) => props.handleAbilityChange(value, item, "runes")}  /> </div>
                : 
                    <div style={{width: "25px", }}>{item.value}%</div>
                }
            </div>
        );
    }

    return (
        <>
        <div>
            <div style={{background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", justifyContent: "space-between", display:"flex", alignItems:"center"}}>
                <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Runes</div>
                <div style={{width: "30px"}}>
                    {props.pageState.editMode ?
                        <AddItemButton addItem={addRune} title="Add rune">
                            <AddRuneDialog />
                        </AddItemButton>
                    : 
                    null}
                </div>
            </div>
        </div>
            <div>
                <div style={{marginTop: "9px"}}>
                    <div style={{width: "100%", border: "2px solid #2f588a", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                        <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Elemental Runes</div>
                    </div>
                    { 
                        character.runes && character.runes.filter((item) => {return item.type === "Elemental"}).map((item, index) => {
                            return(displayRune(item, index))
                        })
                    }
                </div>
                <div style={{marginTop: "9px"}}>
                    <div style={{width: "100%", border: "2px solid #2f588a", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                        <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Form Runes</div>
                    </div>
                    { 
                        character.runes && character.runes.filter((item) => {return item.type === "Form"}).map((item, index) => {
                            return(displayRune(item, index))
                        })
                    }
                </div>
            </div>
            <div style={{marginTop: "9px"}}>
                <div>
                    <div style={{width: "100%", border: "2px solid #2f588a", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                        <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Power Runes</div>
                    </div>
                    { 
                        character.runes && character.runes.filter((item) => {return item.type === "Power"}).map((item, index) => {
                            return(displayRune(item, index))
                        })
                    }
                </div>
            </div>
    </>

    )
}

export default withPageState(withContext(RunesPanel));