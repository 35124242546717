import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import compareValues from '../../utils/compareValues';
import RuneSpellPanel from './runeSpellPanel';
import { getAllCults, getRuneSpell } from '../../services/dataService';

const ManageRuneSpellDialog = (props) => {

	const [selected, setSelected] = useState([]);
	const [cult, setCult] = useState();
	const [allCultSpells, setAllCultSpells] = useState([]);

	useEffect(() => {
		if (props.cult.runeSpells && props.cult.runeSpells.length > 0) {
			var spells = [];
			props.cult.runeSpells.forEach(spell => {
				var updatedSpell = getSpellData(spell);
				spells.push(updatedSpell);
			}) 
			setSelected(spells)
		}
	},[])

	useEffect(() => {
		var allCults = getAllCults(props.campaign)
		allCults.forEach(cult => {
			if (cult.id === props.cult.parentId) {
                setCult(cult);
			}
		})
	},[]);

	useEffect(() => {
		if (cult) {
			var cultRuneSpells = [];
			cult.runeSpells.forEach(spell => {
				var updatedSpell = getSpellData(spell);
				updatedSpell.id = spell.parentId
				cultRuneSpells.push(updatedSpell);
			})
			cultRuneSpells.forEach(spell => {
				if (spell.runes[0] === "Magic"){
					spell.runes = cult.runes;
				}
				spell.favourite = true;
			});
			setAllCultSpells(cultRuneSpells.sort(compareValues('name', 'asc')));
		}
	},[cult]);

	function getSpellData(cultSpell) {
		var spell = {...cultSpell};
		const spellDetails = getRuneSpell(cultSpell.parentId, props.campaign)
		if (spellDetails) {
			spell.description = spellDetails.description;
			spell.range = spellDetails.range;
			spell.duration = spellDetails.duration;
			spell.runes = spellDetails.runes;
			spell.points = spellDetails.points;
			spell.stackable = spellDetails.stackable;
		}
		return spell;
	}

	function handleCancelClick(){
		props.close();
	}
	  
	function handleOkClick(){
		props.setSpells([...selected]);
		props.close();
	}

	if (cult) {
		return (
			<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color: "#121312", padding: "5px"}}>
				<div style={{fontSize:"16px", margin: "10px"}}>{"Select the " + props.cult.name + " rune spells known by this character"}</div>
				<hr />
				<div>
					<RuneSpellPanel campaign={props.campaign} cult={props.cult} availableSpells={allCultSpells} characterRunes={props.character.runes} currentSpells={selected} setSpells={()=> {}} select={(updatedSelected) => {setSelected(updatedSelected)}} />
					<div style={{display:"flex", flexDirection: "row-reverse", marginTop:"30px", padding: "20px", alignItems:"flex-end"}}>
						<Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Ok</Button>
						<Button onClick={handleCancelClick} id="diceButton" color="primary" variant="outlined" style={{marginRight:"20px", backgroundColor: "#bbbbbb", width: "100px"}}>Cancel</Button>
					</div>
				</div>
			</div>
		);
	}
	else return null;
};

export default ManageRuneSpellDialog;