import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';
import { FormControl, Checkbox } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { withContext } from '../Context/context';
import Selector from '../Controls/selector';
import { getSpiritSpell } from '../../services/dataService';

const SpiritSpellRow = (props) => {

    const [checked, setChecked] = useState(false);
    const [spell, setSpell] = useState(props.spell);
    const [disabled, setDisabled] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        var spell = {...props.spell}
        if (!spell.description) {
            let spellDetails = getSpiritSpell(spell.parentId, props.providerData.campaign);
            if (spellDetails) {
                spell.description = spellDetails.description
                spell.duration = spellDetails.duration;
                spell.range = spellDetails.range;
                spell.variable = spellDetails.variable;
                spell.type = spellDetails.type;
                spell.target = spellDetails.target
            }
        }
        setSpell(spell);

        if (spell.target?.type === "Weapon") {
			var possibleItems = props.providerData.character.weapons;
			if (spell.target.filter) {
				possibleItems = possibleItems.filter(function(item) {
                    let valid = true;
					if (spell.target.filter.classes) {
						if ( ! ((item.equipped) && (spell.target.filter.classes.indexOf(item.class) > -1))) {
                            valid = false;
                        }
					}
					if (valid && spell.target.filter.damageTypes) {
						if ( ! ((item.equipped) && (spell.target.filter.damageTypes.indexOf(item.damage.type) > -1) && (!item.hidden))) {
                            valid = false;
                        }
					}
					if (valid && spell.target.filter.categories) {
						if (spell.target.filter.categories.indexOf(item.type) < 0) {
                            valid = false;
                        }
					}
                    return valid;
				});
			}
			setItems(possibleItems);
		}

    },[props.spell]);

    useEffect(() => {
        var selected = false;
        if (props.selected) {
            if (props.selected.id === spell.id) {
                setChecked(true);
                selected = true; 
            }
        }
        var spellDisabled = false;
        if (!selected) {
            setChecked(false);
            if (spell.points > props.availablePoints) {
                spellDisabled = true;
            }
        }
        setDisabled(spellDisabled)
    },[spell, props.availablePoints, props.selected])

    const selectSpell = (event) => {
        setChecked(event.target.checked);
        if (spell.variable) {
            amendVariablePoints(0 - (spell.points - 1))
        }
        props.select(event.target.checked, spell, selectedItem);
	}

    const selectItem = (value) => {
        setSelectedItem(value);
        var index = items.findIndex(obj => obj.title === value);
        if (index > -1) {
            props.selectItem(items[index]);
        }
    }
    
    function amendVariablePoints(value){
        var updatedSpell = {...spell};
        if (checked && ((updatedSpell.points + value) > 0)) {
            updatedSpell.points += value;
            setSpell(updatedSpell);
            props.amendSpell(updatedSpell);           
        }
   }

    if (spell) {
        return(
            <>
                <div style={{display:"flex", alignItems: "center", height: "28px", overflow: "hidden", padding: "0px 5px", margin: "2px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", color:"#121312"}}>
                    <div style={{flex: "0 0 50px"}}>
                        <FormControl variant="outlined" 	>
                            <Checkbox disabled={disabled} checked={checked} color='primary' onChange={selectSpell} />
                        </FormControl>
                    </div>
                    <div style={{fontWeight:700, display: "flex", alignItems: "center", flex: "0 0 180px", cursor:"pointer"}}>
                        <div>{spell.name}</div>
                    </div>
                    <div style={{flex:"0 0 80px", textAlign:"center", display:"flex", justifyContent:"center", cursor:"pointer"}}>
                        <div>{spell.points || "-"}</div>
                        {spell.variable ?
                            <div style={{display:"flex", marginLeft:"5px", alignItems:"center"}}>
                                <RemoveCircleOutlineIcon style={{height:"16px", width:"16px"}} onClick={() => amendVariablePoints(-1)}/>
                                {props.availablePoints > 0 && 
                                    <AddCircleOutlineIcon style={{height:"16px", width:"16px", marginLeft:"4px"}} onClick={() => amendVariablePoints(1)}/>
                                }
                            </div>
                        :
                            null
                        }
                    </div>
                    {checked && items.length > 0 ? 
                        <Selector style={{height: "28px"}} values={items} size="sm" updateSelected={selectItem} />
                    : null}
                </div>
            </>
        )
    }
    else return null;
};

export default withContext(withFirebase(SpiritSpellRow));