import React, { useState, useEffect } from 'react';
import { withFirebase } from '../../Firebase';
import { withContext } from '../../Context/context';
import globalStyles from '../../Styles/styles.module.css';
import { Button } from '@mui/material';
import WeaponList from '../../Lists/weaponList';
import * as WeaponsData from '../../../data/weapons.json';
import compareValues from '../../../utils/compareValues';
import { getAllWeapons } from '../../../services/dataService';

const types = ["Axe", "Dagger", "Sword", "Unarmed", "Hammer", "Mace", "Quarterstaff", "Spear", "Pole", "Bow", "Dart", "Thrown", "Sling", "Shield"];

const AddWeaponDialog = (props) => {

    const [selected, setSelected] = React.useState([]);
    const [allWeapons, setAllWeapons] = useState('');
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        var weapons = [];
        var allWeapons = getAllWeapons(props.providerData.campaign).sort(compareValues('name'))
        allWeapons.forEach(weapon => {
            weapons.push(weapon);
        });
        setAllWeapons(weapons);
    }, []);

	useEffect(() => {
        if (props.currentWeapons) {
            var existing = [];
            props.currentWeapons.forEach(weapon => {
                existing.push(weapon.name);
            })
            setSelected(existing);
        }
	},[props.currentWeapons])

    function selectWeapon(weapon, checked) {
        var updatedSelected = [...selected];
        if (checked) {
            updatedSelected.push(weapon.name);
        }
        else {
            var index = updatedSelected.findIndex(selectedWeapon => selectedWeapon === weapon.name)
            updatedSelected.splice(index, 1);
        }
        setSelected(updatedSelected);
    };

    function handleOkClick(){
        props.close();
    }

    function addWeapon(weapon) {
        var newWeapon = {equipped: true, ...weapon};
        var matches = props.providerData.character.weapons.filter(function (item) {
            return item.name === newWeapon.name;
        });
        if (matches.length > 0) {
            newWeapon.title = newWeapon.title + " " + (matches.length +1);
        }
        props.addItem(newWeapon);
    }

    return (
        <div>
            <div className={globalStyles.panel}  style={{height: "400px", overflow: "auto"}}>
                <WeaponList weapons={allWeapons} select={selectWeapon} selected={selected} types={types} noSelect add={addWeapon} setRestricted={setRestricted} />
            </div>
            <div style={{height: "80px", display:"flex", justifyContent: "space-between", flexDirection: "row-reverse", alignItems: "center", borderTop: "2px solid #2f588a", backgroundColor: "#cdcdcd"}}>
                <div style={{marginRight: "20px"}}>
                    <Button type="submit" onClick={handleOkClick} style={{width: "100px", }} color="primary" variant="contained">Close</Button>
                </div>
                {
                    restricted ? 
                        <div style={{marginLeft: "20px", color: "#851113"}}>** Insufficient STR and/or DEX</div>
                    : 
                    null
                }                    
            </div>
        </div>
    );
}

export default withContext(withFirebase(AddWeaponDialog));    