import React, {useState, useEffect} from "react";
import globalStyles from '../../Styles/styles.module.css';
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import { Checkbox } from "@mui/material";
import HideButton from "../controls/hideButton";
import EditBox from "../../Controls/editBoxSingle";
import AddItemButton from "../buttons/addItemButton";
import AddSkillDialog from "../dialogs/addNewSkillDialog";
import * as SAVE from '../../../constants/save'
import compareValues from '../../../utils/compareValues';

const SkillsPanel = (props) => {

    const [character, setCharacter] = useState(props.providerData.character);
    
    useEffect(() => {
        setCharacter({...props.providerData.character})
    },[props.providerData.character]);
    
    const addSkill = (newSkill) => {
        var currentSkills = [...props.providerData.character.skills]
        var index = currentSkills.findIndex(obj => obj.parentId === newSkill.parentId && (obj.variant && obj.variant.toLowerCase() === newSkill.variant.toLowerCase()))
        if (index < 0) {
            currentSkills.push(newSkill);
            props.update([{fieldName: "skills", value: currentSkills}], SAVE.NO)
        }
    }

    const getValue = (item) => {
        if ((item.value + character.categoryModifiers[item.skillCategory]) < 0) {
            return 0;
        }
        else {
            return item.value + character.categoryModifiers[item.skillCategory];
        }
    }

    const updateSkill = (value, item) => {
        var updatedValue = value - character.categoryModifiers[item.skillCategory];
        props.handleAbilityChange(updatedValue, item, "skills");
    }

    function displaySkill(item, index) {
        if (item.hidden && ! props.pageState.editMode) return null;
        if (item.value > 0 || props.pageState.editMode) {
            return(
                <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                    {item.canLearn ? 
                        props.pageState.editMode ? 
                            <HideButton item={item} type="skills" name={item.name + (item.variant ? " (" + item.variant + ")": "")} update={(hidden) => props.hideItem("skills", item, hidden)} />
                        : 
                            <Checkbox style={{cursor: "default", width: "18px", padding: "1px"}} checked={false} />
                    : 
                        props.pageState.editMode ? 
                            <HideButton item={item} type="skills" name={item.name + (item.variant ? " (" + item.variant + ")": "")} update={(hidden) => props.hideItem("skills", item, hidden)} />
                        : 
                        <div style={{width: "25px"}} /> 
                    }
                    <div style={{width: "200px", marginLeft: "5px"}}>{item.name}{item.variant ? " (" + item.variant + ")" : ""}{(item.hidden ? " (hidden)" : "")}</div>
                    {props.pageState.editMode ? 
                        <div style={{width: "50px"}}><EditBox number style={props.buttonStyle} value={getValue(item)} onChange={(value) => updateSkill(value, item)}  /> </div>
                        :
                        <div style={{width: "25px"}}>{getValue(item)}%</div>
                    }
                </div>
            );
        }
    }

    return (
        <>
        <div style={{marginTop: "20px", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", justifyContent: "space-between", display:"flex", alignItems:"center"}}>
            <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Skills</div>
            <div style={{width: "30px"}}>
                {props.pageState.editMode ?
                    <AddItemButton addItem={addSkill} title="Add skill">
                        <AddSkillDialog />
                    </AddItemButton>
                : 
                null}
            </div>
            </div>
                <div style={{marginTop: "10px", fontSize: "13px"}}>
                    <div style={{display:"flex"}}>
                        <div style={{width: "32%"}}>
                            <div>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Agility</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Agility < 0 ? "" : "+"}{character.categoryModifiers.Agility || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Agility"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Communication</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Communication < 0 ? "" : "+"}{character.categoryModifiers.Communication || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Communication"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                        </div>
                        <div style={{width: "33%", marginLeft: "10px"}}>
                            <div>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Knowledge</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Knowledge < 0 ? "" : "+"}{character.categoryModifiers.Knowledge || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Knowledge"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                        </div>
                        <div style={{width: "32%", marginLeft: "10px"}}>
                            <div>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Magic</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Magic < 0 ? "" : "+"}{character.categoryModifiers.Magic || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Magic"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <div style={{backgrwidth: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Manipulation</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Manipulation < 0 ? "" : "+"}{character.categoryModifiers.Manipulation || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Manipulation"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Perception</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Perception < 0 ? "" : "+"}{character.categoryModifiers.Perception || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Perception"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <div style={{width: "100%", border: "2px solid #2f588a", borderRadius: "5px", height: "30px", fontWeight:"bold", display:"flex", alignItems:"center"}}>
                                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Stealth</div>
                                    <div style={{marginLeft: "20px"}}>{character.categoryModifiers.Stealth < 0 ? "" : "+"}{character.categoryModifiers.Stealth || 0}%</div>
                                </div>
                                { 
                                    character.skills && character.skills.filter((item) => {return item.skillCategory === "Stealth"}).sort(compareValues('name')).map((item, index) => {
                                        return (displaySkill(item, index))
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}

export default withPageState(withContext(SkillsPanel))