import React from 'react';
import DialogButtons from '../../Dialogs/dialogButtons';
import EditBoxSingle from '../../Controls/editBoxSingle';
import EditBoxMulti from '../../Controls/editBoxMulti';

export default function AddReputationDialog(props) {
    const [state, setState] = React.useState({
        event: "",
        value: 0
    });

    function handleOkClick() {
        var updatedCharacter = {...props.character};
        updatedCharacter.reputation += parseInt(state.value);
        updatedCharacter.background.reputation.push({event: state.event, value: state.value});
        props.update(updatedCharacter, true);
        props.close();
    }

    return (
		<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"16px", backgroundColor:"#cdcdcd", color: "#121312"}}>
            <div style={{position:"relative"}}>
                <div style={{padding: "20px"}}>Enter the value of reputation gained together with a short summary of the event that triggered the increase</div>
                    <div style={{marginLeft: "20px", width: "80px"}}>
                        <EditBoxSingle number onChange={(value) => setState({...state, value: value})} value={state.value} />
                    </div>
                    <div style={{margin: "20px", width: "550px"}}>
                        <EditBoxMulti onChange={(value) => setState({...state, event: value})} value={state.event} />
                    </div>
                    <DialogButtons style={{borderTop: "2px solid #2f588a"}} handleOkClick={handleOkClick} handleCancelClick={() => props.close()} title="Add" />
            </div>
       </div>

    );
}