import React, {useState, useEffect} from 'react'
import Incrementor from './incrementor'
import * as SAVE from '../../constants/save';

const MagicPointIncrementor = (props) => {

    const [magicPoints, setMagicPoints] = useState(null);
    const [entityIndex, setEntityIndex] = useState(-1);

    useEffect(() => {
        var index = props.magicPoints.findIndex(item => item.entityId === props.entityId);
        setEntityIndex(index);
        setMagicPoints([...props.magicPoints]);
    },[props.magicPoints])
  
    function modifyMagicPoints(value, event) {
        event.stopPropagation();
        var updatedMagicPoints = [...magicPoints];
        updatedMagicPoints[entityIndex].current += value;
        if (updatedMagicPoints[entityIndex].current > updatedMagicPoints[entityIndex].total)  {
            updatedMagicPoints[entityIndex].current = updatedMagicPoints[entityIndex].total;
        }
        else if (updatedMagicPoints[entityIndex].current < 0) {
            updatedMagicPoints[entityIndex].current = 0;
        }
        else {
            setMagicPoints(updatedMagicPoints);
            props.update([{fieldName: "magicPoints", value: updatedMagicPoints}], SAVE.DELAYED)
        }
    }

    if (magicPoints && (entityIndex > -1)) {
        return (
            <Incrementor style={props.style} label="Magic points:" increment={modifyMagicPoints} currentValue={magicPoints[entityIndex].current} totalValue={magicPoints[entityIndex].total} />
        )
    }
    else return null;
}

export default MagicPointIncrementor