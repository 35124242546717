import React, { useState } from 'react';
import SignUpLink from '../SignUp';
import { withFirebase } from '../Firebase';
import SignInGoogleBase from './signInGoogleBase';
import SignInFacebookBase from './signInFacebookBase';
import SignInFormBase from './signInFormBase';
 
const Landing = () => (
	<div style={{display:"flex", width: "100%", color: "#fff"}}>
		<div style={{backgroundImage: "url('https://storage.googleapis.com/runequest/images/runequest-landing-3.jpg'", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100%"}}></div>
		<div style={{height: "100vh", width: "600px", backgroundColor: "#8a612f", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
			<div style={{width:"400px"}}>
				<SignInForm />
			</div>
			<SignInGoogle />
			<SignInFacebook />
			<SignUpLink />
			
		</div>
	</div>
);

const SignInForm = withFirebase(SignInFormBase);
const SignInGoogle = withFirebase(SignInGoogleBase);
const SignInFacebook = withFirebase(SignInFacebookBase);

export default Landing;

export { SignInForm, SignInGoogle, SignInFacebook }