import React, { useState, useEffect } from 'react';
import * as HeirloomData from '../../../data/heirlooms.json';
import EditBox from '../../Controls/editBoxSingle';
import Selector from '../../Controls/selector';
import compareValues from '../../../utils/compareValues';
import { formatBenefits } from '../../../services/benefitsService';
import { getAllSpiritSpells } from '../../../services/dataService';

const Heirloom = (props) => {
    const [heirlooms, setHeirlooms] = useState([]);
    const [selectedWeapon, setSelectedWeapon] = useState();

    useEffect(() => {
        if (props.builder.heirlooms && props.builder.heirlooms.length > 0) {
            setHeirlooms([...props.builder.heirlooms]);
        }
        else {
            var allHeirlooms = {...HeirloomData};
            var data = allHeirlooms.data;
            var count = 1;
            var results = []; 
            while (count > 0) {
                var random = Math.ceil(Math.random()*20);
                if (random === 20) {
                    count += 1;
                }
                else {
                    var index = data.findIndex(item => (item.from <= random && item.to >= random));
                    results.push(formatBenefits({...data[index]}, props.weapons))
                    count -=1;
                }
            }
            setHeirlooms(results);
        }
    }, [])

    useEffect(() => {
        checkForCompleteness();
    },[heirlooms]);

    function checkForCompleteness() {
        var disable = false;
        heirlooms.forEach(heirloom => {
            heirloom.benefits.forEach(benefit => {
                if (benefit.options && !benefit.selected) {
                    disable = true;
                }
            })
        })
        props.disableNext(disable);
        var updatedBuilder = {...props.builder};
        updatedBuilder.heirlooms = [...heirlooms];
        props.update(updatedBuilder, false);
    }

    function optionSelected(value, heirloomIndex, benefitIndex) {
        var updatedHeirlooms = [...heirlooms];
        var benefit = updatedHeirlooms[heirloomIndex].benefits[benefitIndex];
        var list = benefit.type === "Spirit spell matrix" ? [...getAllSpiritSpells(props.builder.campaign)] : [...benefit.options];
        var index = list.findIndex(obj => obj.name === value);
        benefit.selected = {...list[index]};
        if (benefit.type === "Spirit spell matrix") {
            var item = {...list[index]};
            benefit.selected = {name: item.name, parentId: item.id, points: item.variable ? benefit.gain : item.points, variable: item.variable || false, target: item.target || ""};
            if (benefit.location === "<selectedWeapon>") {
                benefit.location = selectedWeapon.name
            }
        };
        if (benefit.type === "hitPoints") {
            var index = props.weapons.findIndex(obj => obj.name === value);
            benefit.selected = {...props.weapons[index]};
            setSelectedWeapon(props.weapons[index]);
        }
        if (benefit.type === "skill") {
            benefit.variant = value;
            delete benefit.options;
        }
        setHeirlooms(updatedHeirlooms);
        var updatedBuilder = {...props.builder};
        updatedBuilder.heirlooms = updatedHeirlooms;
        props.update(updatedBuilder, false);
	}

    function handleEditBoxChange(value, heirloomIndex, benefitIndex) {
        var updatedHeirlooms = [...heirlooms];
        updatedHeirlooms[heirloomIndex].specify = value;
        setHeirlooms(updatedHeirlooms);
        var updatedBuilder = {...props.builder};
        updatedBuilder.heirlooms = updatedHeirlooms;
        props.update(updatedBuilder, false);
    }

    if (heirlooms.length > 0) {
        return (
            <div>
                <div style={{backgroundColor: "#fff", border: "5px solid #2F588A", borderRadius: "10px", padding: "0px 20px"}}>
                    <p>Finally, each beginning adventurer receives one (or possibly more than one) valuable and colorful possession as a family heirloom. Make up the where and why of this if necessary. </p>
                    <p>If you decide you don't want the item provided, ignore it, or you can try to sell it once play beings (if appropriate).</p>
                </div>
                <div style={{display:"flex", width: "100%"}}>
                    <div style={{paddingTop: "20px", color: "#121312", padding: "20px"}}>
                        <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 0px 10px 16px"}}>
                                <div style={{marginBottom: "20px"}}>{"Your heirloom" + (heirlooms.length > 1 ? "s are:" : " is")}</div>
                                {heirlooms.map((item, heirloomIndex) => {
                                    return(
                                        <div key={heirloomIndex} style={{marginBottom: "20px"}}>
                                            <div style={{marginBottom: "10px"}}>{item.display || item.description }</div>
                                            {item.benefits.map((benefit, benefitIndex) => {
                                                if (benefit.specify) {
                                                    return (
                                                        <div key={benefitIndex} style={{marginTop: "10px", display: "flex", alignItems: "center"}}>
                                                            {benefit.title ? 
                                                                <div style={{fontWeight: "bold"}}>{benefit.title}:&nbsp;</div> 
                                                            : null}
                                                            <EditBox onChange={(value) => handleEditBoxChange(value, heirloomIndex, benefitIndex)}  />
                                                        </div>
                                                    )
                                                }
                                                else if (benefit.options && benefit.options.length > 1) {
                                                    var values = [];
                                                    benefit.options.sort(compareValues('name')).forEach(option => {
                                                        values.push(option);
                                                    })
                                                    return (
                                                        <div key={benefitIndex} style={{marginTop: "10px", display: "flex", alignItems: "center"}}>
                                                            {benefit.title ? 
                                                                <div style={{marginTop: "10px", marginRight: "10px", fontWeight: "bold"}}>{benefit.title}</div> 
                                                            : null}
                                                            <div style={{width: "150px"}}>
                                                                <Selector values={values} updateSelected={(value) => optionSelected(value, heirloomIndex, benefitIndex)} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default Heirloom;