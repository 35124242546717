import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material';
import Selector from '../Controls/selector';
import { withFirebase } from '../Firebase';

const Storytelling = (props) => {

    const [currentChapter, setCurrentChapter] = useState(null)
    const [currentAdventure, setCurrentAdventure] = useState(null)
    const [encounters, setEncounters] = useState([])
    const [currentEncounter, setCurrentEncounter] = useState({})

    useEffect(() => {
        props.campaign.chapters.forEach(chapter => {
            if (chapter.id === props.campaign.currentChapterId) {
                setCurrentChapter({...chapter});
                chapter.adventures && chapter.adventures.forEach(adventure => {
                    if (adventure.id === props.campaign.currentAdventureId) {
                        setCurrentAdventure({...adventure})
                        setEncounters([...adventure.encounters])
                        if (props.campaign.currentEncounterId) {
                            adventure.encounters.forEach(encounter => {
                                if (encounter.id === props.campaign.currentEncounterId) {
                                    setCurrentEncounter({...encounter});
                                }
                            })
                        }
                    }
                })
            }
        })
    },[props.campaign])

    function displayChapters() {
        props.displayItem({type: "Chapter", item: {name: "Chapter"}})
    }

    function displayAdventures() {
        props.displayItem({type: "Adventure", item: {name: "Adventure"}})
    }

    function displayEncounters() {
        props.displayItem({type: "Encounters", item: {name: "Encounter"}})
    }

    function setChapter(name) {
        props.campaign.chapters.forEach(chapter => {
            if (chapter.name === name) {
                setCurrentChapter({...chapter});
                var updatedCampaign = {...props.campaign};
                updatedCampaign.currentChapterId = chapter.id;
                props.update(updatedCampaign, true);
            }
        })
    }

    function setAdventure(name) {
        currentChapter && currentChapter.adventures.forEach(adventure => {
            if (adventure.name === name) {
                setCurrentAdventure({...adventure});
                var updatedCampaign = {...props.campaign};
                updatedCampaign.currentAdventureId = adventure.id;
                props.update(updatedCampaign, true);
            }
        })
    }

    function setEncounter(item) {
        setCurrentEncounter({...item});
        var updatedCampaign = {...props.campaign}
        updatedCampaign.currentEncounterId = item.id;
        props.update(updatedCampaign);
    }

    return (
        <>
            <div style={{padding: "10px"}}>
                <>
                    <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>Adventures</div>
                    </div>
                    <div>
                        <div style={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row-reverse"}}>
                            <Button style={{width: "165px"}} color="primary" variant='contained' onClick={() => displayChapters()}>Edit Chapters</Button>
                            <Selector style={{width: "600px"}} labelMargin="26px" value={currentChapter && currentChapter.name} values={props.campaign.chapters} updateSelected={setChapter} label="Current chapter: " />
                        </div>
                    </div>
                    <div>
                        <div style={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row-reverse"}}>
                            <Button disabled={!props.campaign.currentChapterId} style={{width: "165px", display:"flex"}} color="primary" variant='contained' onClick={() => displayAdventures()}>Edit Adventures</Button>
                            <Selector disabled={!props.campaign.currentChapterId} style={{width: "600px"}} value={currentAdventure && currentAdventure.name} values={(currentChapter && currentChapter.adventures || [])} updateSelected={setAdventure} label="Current adventure: " />
                        </div>
                    </div>
                </>
            </div>
            <div style={{padding: "10px"}}>
                <>
                    <div id="header" style={{background: "rgba(47,117,137,1)", background: "linear-gradient(90deg, rgba(47,88,138,1) 35%, rgba(47,117,137,1) 100%)", fontSize: "18px", padding: "6px 10px", color: "#fff", position: "relative", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div style={{paddingLeft: "5px", fontWeight:"bold", height: "28px", display: "flex", alignItems: "center"}}>Adventure Content</div>
                    </div>
                    <h1>Coming Soon!</h1>
                </>
            </div>
        </>
    )
}

export default withFirebase(Storytelling);