import { Button } from "@mui/material";
import React from "react";

export default function fallbackComponent({ error, resetErrorBoundary }) {

	const refresh = () => {
		window.location.reload();
	}
    return (
		
		<div style={{display: "flex", backgroundColor: "#851113", justifyContent: "center", height:"100vh", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"15px"}}>
			<div style={{width: "100vw", maxWidth: "1200px", display:"flex", backgroundColor: "#f0f0f0", fontSize: "15px", flexDirection: "column", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-background.png)"}}>
			<div style={{padding: "50px 100px", display: "flex", flexDirection: "column", alignItems: "center", color: "#121312"}}>
				<h1 style={{}}>Oh no! Something has gone wrong</h1>
				<div style={{fontSize: "20px", padding: "30px"}}>But don't worry, our crack team of trollkin are on it. In the meantime, please click the button below to take you back to the site.</div>
				<img src="https://storage.googleapis.com/runequest/images/trolls.png" />
				<div style={{padding: "30px"}}>
					<Button onClick={refresh} style={{width: "100px"}} color="primary" variant="contained">Refresh</Button>
				</div>
			</div>
			</div>
		</div>
    )
  }