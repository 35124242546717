import React, { useState, useEffect } from 'react';
import StartingSkills from '../startingSkills';
import HomelandWeapons from './homelandWeapons';
import HomelandDetails from '../step1Homeland/homelandDetails';

const HomelandBonuses = (props) => {

    const [culturalSkills, setCulturalSkills] = useState([]);
    const [homeland, setHomeland] = useState();
    const [skillsComplete, setSkillsComplete] = useState(false);
    const [weaponsComplete, setWeaponsComplete] = useState(false);

    useEffect(() => {
        setHomeland({...props.builder.homeland});
        if (!props.builder.homeland.weapons) {
            setWeaponsComplete(true);
        }
    },[props.builder]);

    useEffect(() => {
        if (skillsComplete && weaponsComplete) {
            props.disableNext(false);
            var updatedBuilder = {...props.builder};
            updatedBuilder.homeland = {...homeland};
            props.update(updatedBuilder, false);
        }
        else {
            props.disableNext(true);
        }
    },[skillsComplete, weaponsComplete])

    useEffect(() => {
        var skills = [];
        skills = [...props.builder.homeland.skills];
        setCulturalSkills(skills);
    }, []);

    function completeSkills(selectedSkills, complete) {
        var updatedHomeland = {...homeland};
        updatedHomeland.skills = selectedSkills;
        setHomeland(updatedHomeland)
        setSkillsComplete(complete);
    }

    function completeWeapons(selectedWeapons) {
        var updatedHomeland = {...homeland};
        updatedHomeland.weapons = selectedWeapons;
        setHomeland(updatedHomeland)
        setWeaponsComplete(true);
    }

    if (homeland) {
        return (
            <div>
                <div style={{display:"flex", color: "#121312", flexWrap: "wrap", justifyContent: "center"}}>
                    <div style={{margin: "10px 5px"}}>
                        <HomelandDetails builder={props.builder} width="300px" />
                    </div>
                    <div style={{margin: "10px 5px"}}>
                        <StartingSkills title="Cultural Skills" builder={props.builder} skills={culturalSkills} complete={completeSkills} />
                    </div>
                    <div style={{margin: "10px 5px"}}>
                        <HomelandWeapons title="Cultural Weapons" complete={completeWeapons} builder={props.builder} homeland={homeland} />
                    </div>
                </div>
            </div>
        );
    }
    else {return null;}
}

export default HomelandBonuses;