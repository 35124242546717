import React from 'react';
import Campaign from './campaign';

const CampaignContext = React.createContext(new Campaign());

export const withCampaign = Component => props => (
  <CampaignContext.Consumer>
    {campaign => <Component {...props} campaign={campaign} />}
  </CampaignContext.Consumer>
);

export default CampaignContext;
