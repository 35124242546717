import React, {useState, useEffect} from 'react';
import InventoryWeapon from './inventoryWeapon';
import { withPageState } from '../Context/PageState';
import AddWeaponDialog from '../Characters/dialogs/addWeaponDialog';
import AddItemButton from '../Characters/buttons/addItemButton';

const WeaponList = (props) => {

    const [types, setTypes] = useState([]);
    const [restricted, setRestricted] = useState(false);

    useEffect(() => {
        if (props.types) {
            setTypes([...props.types]);
        }
    },[props.types]);
    
    const selectWeapon = (weapon, checked) => {
        var updatedWeapons = [...props.weapons];
        var index = updatedWeapons.findIndex(item => item.id === weapon.id)
        updatedWeapons[index].equipped = checked;
        props.updateWeapons(updatedWeapons);
    };
    
    const deleteWeapon =(weapon) => {
        var updatedWeapons = [...props.weapons];
        var index = updatedWeapons.findIndex(item => item.id === weapon.id)
        updatedWeapons.splice(index, 1);
        props.updateWeapons(updatedWeapons);
    }

    if (props.weapons) {
        return (
            <>
                <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "18px", display:"flex", justifyContent: "space-between", alignItems: "center", padding: "0px 5px"}}>
                    <div>Weapons</div>
                    {props.pageState.editMode ?
                        <AddItemButton title="Add weapon" addTitle="Add weapon" addItem={props.addWeapon} edit={props.edit} width="900px" style={{marginRight: "5px"}}>
                            <AddWeaponDialog />
                        </AddItemButton>
                    : 
                    null}
                </div>
                <div>
                    <div style={{display:"flex", fontSize: "15px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                        <div style={{width:"150px", marginLeft:"5px", textAlign:"left"}}>Item</div>
                        <div style={{width:"60px", textAlign:"center"}}>Skill</div>
                        <div style={{width:"60px", textAlign:"center"}}>ENC</div>
                        {props.noSelect ? 
                            null
                        : 
                        props.pageState.editMode ?
                            <div style={{width:"80px"}}>Delete</div>
                            :
                            <div style={{width:"80px"}}>Equipped</div>
                        }
                    </div>

                    { 
                        props.weapons.filter(function(obj) {return (!Object.hasOwn(obj, 'hidden'))}).map((item, index) => {
                            return (
                                <InventoryWeapon key={index} categoryModifier={props.categoryModifier} item={item} delete={deleteWeapon} select={selectWeapon} weaponSkills={props.weaponSkills} selected={item.equipped} noSelect={props.noSelect} buttons={props.buttons} setRestricted={setRestricted} />
                            )
                        })
                    }
                </div>
                {restricted ?
                    <div style={{color: "#851113", padding: "5px"}}>** Insufficient STR and/or DEX</div>
                : 
                null}
            </>
        )
    }
    else {
        return null;
    }
}

export default withPageState(WeaponList);   