import React from 'react'

const PowerRuneCard = (props) => {
    return (
        <div style={{padding: "20px", backgroundColor: "#cdcdcd", color: "#121312", border:"5px solid #2F588A", borderRadius: "15px", width: "500px"}}>
            <div id="props.item-rune" style={{fontSize:"24px"}}>
                {props.item.name}
            </div>
            <div style={{margin: "5px 0px", marginBottom: "20px"}}>{props.item.description}</div>
        </div>
    )
}

export default PowerRuneCard;