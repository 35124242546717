import generateCharacteristics from './characteristicsGenerator';
import generatePassions from './passionGenerator';
import generateSkills from './skillsGenerator';
import generateWeapons from './weaponsGenerator';
import generateCult from './cultGenerator';
import generateName from './nameGenerator';
import generateSpiritSpells from './spiritMagicGenerator';
import generateRunesFrom from './runesGenerator';
import {calculateMagicPoints, calculateDamageBonus, calculateStrikeRanks, calculateSpiritCombatDamage} from '../../services/attributeService';
import {calculateHitPoints, getBaseLocationHP} from '../../services/hitPointsService';
import { getCategoryModifiers } from '../../services/skillsService';
import * as EncounterJSON from '../../data/encounters.json';
import * as TemplateJSON from '../../data/templates.json';
import * as NamesJSON from '../../data/names.json';
import { v4 as uuidv4 } from 'uuid';

const encounterData = {...EncounterJSON};
const templateData = {...TemplateJSON};

function generateEncounter(groups, firebase, campaign, authUserId) {
    var encounterId = uuidv4();
    var npcs = [];
    // const url = "http://127.0.0.1:5001/whispers-of-chaos/us-central1/addNPCs"
    const url = "https://addnpcs-taifbsbw2a-uc.a.run.app"
    groups.forEach(group => {
        var newNPCs = generateGroup(group, encounterId, campaign);
        if (newNPCs && newNPCs.length > 0) {
            npcs = npcs.concat(newNPCs);
        }
    });

    if (npcs.length > 0) {
        var data = {
            data: npcs,
            authUserId: authUserId
        }
        return fetch(url, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=UTF-8"
            }
        }).then((response) => response.json())
            .then(data => {
                return encounterId;
            });
    }
}

function generateGroup(group, encounterId, campaign){
    var npcs = [];
    var expIndex = encounterData.experience.findIndex(obj => obj.name === group.experience);
    var temIndex = templateData.data.findIndex(obj => obj.name === group.template)
    var cultIndex = encounterData.cults.findIndex(obj => obj.name === group.cult);
    if (expIndex > -1 && temIndex > -1 && cultIndex > -1) {
        var template = templateData.data[temIndex];
        var experience = encounterData.experience[expIndex]
        var cult = encounterData.cults[cultIndex];
        for (var a = 0; a < group.count; a++){
            var npcId = uuidv4();
            var npc = {id: npcId, encounterId: encounterId, campaignId: campaign.id};
            npc.profileImage = template.profileImage;
            npc.rank = group.experience;
            npc.characteristics = generateCharacteristics(template, experience);
            npc.categoryModifiers = getCategoryModifiers(npc.characteristics)
            var magicPoints = calculateMagicPoints(npc.characteristics);
            npc.armour = createArmour(template);
            npc.magicPoints=[{current: magicPoints, total: magicPoints, type: "Character", entityId: npcId}];
            var locations = template.locations ? [...template.locations] : [...encounterData.locations];
            npc.hitPoints = {current: 0, total: 0, locations: locations}
            npc.hitPoints = calculateHitPoints(npc.characteristics, npc.hitPoints);
            npc.hitPoints.locations = createNPCLocations(npc.armour, template.naturalArmour, npc.hitPoints);
            npc.damageBonus = calculateDamageBonus(npc.characteristics);
            npc.strikeRanks = calculateStrikeRanks(npc.characteristics);
            npc.passions = generatePassions(template.passions.concat(cult.passions), experience);
            npc.runes = generateRunesFrom(template.runes.concat(cult.runes), experience);
            npc.skills = generateSkills(template.skills.concat(cult.skills), experience);
            var weaponDetails = generateWeapons(template.weapons.concat(cult.weapons), experience, npc.characteristics, npc.categoryModifiers.Manipulation);
            npc.weapons = [...weaponDetails.weapons];
            npc.weaponSkills = [...weaponDetails.skills]
            var maxSpells = npc.characteristics.CHA.baseValue;
            npc.spiritSpells = generateSpiritSpells(cult, experience, maxSpells, campaign);
            npc.cults = generateCult(cult, experience, npcId, campaign);
            npc.move = template.move;
            var genderCheck = Math.ceil(Math.random()*(100));
            npc.personalInfo = {gender: genderCheck <= template.femaleProbability ? "Female" : "Male"}
            var namesList = template.namesList;
            npc.name = generateName(namesList, npc.personalInfo.gender);
            npc.type = experience.name + " " + template.name + ". " + npc.personalInfo.gender;
            npc.spiritCombat = generateSpiritCombat(npc.characteristics);
            npc.boundSpirits = [];
            npc.crystals = [];
            npc.enchantments = [];
            npc.matrices = [];
            npcs.push(npc);
        }
    }
    return npcs;
}

function generateSpiritCombat(characteristics) {
    var damage = calculateSpiritCombatDamage(characteristics)
    return {
        title: "Spirit Combat", 
        damage: damage,
        armour: 0
    }
}

function createArmour(template) {
    var armour = [];
    template.armour.forEach(piece => {
        armour.push({
            id: uuidv4(),
            ENC: piece.ENC,
            covers: piece.covers,
            equipped: true,
            material: piece.material,
            parentId: piece.parentId,
            points: piece.points,
            stealth: piece.stealth,
            type: piece.type,
        })
    })
    return armour;
}

function createNPCLocations(armour, naturalArmour, hitPoints) {
    var baseHPs = getBaseLocationHP(hitPoints.current);
    var locations = []
    hitPoints.locations.forEach(location => {
        var newLocation ={...location};
        newLocation.hitPoints = baseHPs + location.modifier;
        locations.push(newLocation)
    })
    if (armour) {
        locations.forEach(location => {
            if (naturalArmour) {location.naturalArmour = {...naturalArmour}};
            const armourIndex = armour.findIndex(obj => obj.covers.indexOf(location.name) > -1);
            if (armourIndex > -1) {
                location.armour = {
                    id: armour[armourIndex].id, 
                    material: armour[armourIndex].material,
                    points: armour[armourIndex].points,
                    type: armour[armourIndex].type,
                }
            }
        })
    }
    return locations;
}

export default generateEncounter;