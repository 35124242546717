import React, { useState, useEffect } from 'react';
import ExperienceRow from './experienceRow';
import globalStyles from '../Styles/styles.module.css';

export default function ExperienceRows(props) {

    const [items, setItems] = useState();

    useEffect(() => {
        setItems(props.items);
    },[]);

    if (items) {
        return(
            <div>
                <div style={{display:"flex", fontSize: "14px", padding: "5px 0px", fontWeight:"bold", textAlign:"center"}}>
                    <div style={{width:"120px", marginLeft:"5px", textAlign:"left"}}>{props.title}</div>
                    <div style={{width:"66px", textAlign:"center"}}>Current %</div>
                    <div style={{width:"86px"}}>Roll 1D6</div>
                    <div style={{width:"80px"}}></div>
                </div>
                {items.map((item, index) => {
                    return (
                        <ExperienceRow diceRoller={props.diceRoller} key={index} item={item} processExperience={props.processExperience}></ExperienceRow>
                    )
                })}
            </div>
        );
    }
    else {
        return null;
    }
}