import React, {useState, useEffect} from "react";

const maleImages = ["male2", "male3", "male4", "male5", "male7", "male8", "male9", "male10", "male11", "male12", "male13", "male14", "male15", "male16", "male17", "male18", "male19", "male20", "male21", "male22", "male23", "male24"];
const femaleImages = ["female3", "female4", "female5", "female6", "female7", "female8", "female9", "female10", "female11", "female12", "female13", "female14", "female16", "female17", "female18", "female19", "female20", "female22", "female23", "female24", "female25"];

const ProfileImagesPanel = (props) => {

    const [images, setImages] = useState([]);
    const [hover, setHover] = useState(-1);
    const [selectedImage, setSelectedImage] = useState("");

    useEffect(() => {
        if (props.gender) {
            if (props.gender === "Male") {
                setImages([...maleImages]);
            }
            else if (props.gender === "Female") {
                setImages([...femaleImages]);
            }
            else {
                setImages([...maleImages, ...femaleImages]);
            }
        }
    }, [props.gender])

    function selectImage(image) {
        // if (selectedImage !== image) {
        //     setSelectedImage(image);
        // }
        // else {setSelectedImage("")}
        props.modifyImage("https://storage.googleapis.com/runequest/profiles/" + image +".png");
    }

    return (
        <div style={{backgroundColor: "#cdcdcd", borderRadius: "10px", border: "3px solid #2F588A", padding: "10px 10px", position: "relative", zIndex: 99}}>
            <div style={{display:"flex", flexWrap:"wrap"}}>
            {
                images.map((image, index) => {
                    var opacity = 1;
                    if (selectedImage && selectedImage != image){
                        opacity = 0.4;
                    }
                    return (
                        <div key={index} style={{margin:"10px"}} onClick={() => selectImage(image)} onMouseOver={() => setHover(index)}>
                            <img src = {"https://storage.googleapis.com/runequest/profiles/" + image +".png"} style={{opacity: opacity, height: "160px", width: "120px", cursor: "pointer", border: index == hover ? "5px solid #8a612f" : "5px solid #454645", borderRadius: "5px"}} />
                        </div>
                    )
                })
            }
            </div>
        </div>
    )

}

export default ProfileImagesPanel;