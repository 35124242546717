import React, {useState} from 'react';
import globalStyles from '../Styles/styles.module.css';
import Selector from '../Controls/selector';
import AbilityResultsTable from '../Tables/abilityResults';
import AttackResultsTable from '../Tables/attackResults';
import DodgeResultsTable from '../Tables/dodgeResults';
import DialogButtonSingle from './dialogButtonSingle';
import CombatFumblesTable from '../Tables/combatFumbles';
import SpiritCombatFumblesTable from '../Tables/spiritCombatFumbles';

const TablesDialog = (props) => {

    const tables=[{id: "Ability Results", name: "Ability Results"},{id: "Attack Results", name: "Attack Results"},{id: "Dodge Results", name: "Dodge Results"},{id: "Combat Fumbles", name: "Combat Fumbles"},{id: "Spirit Combat Fumbles", name: "Spirit Combat Fumbles"}];
    const [showTable, setShowTable] = useState("");

    return (
        <>
            <div style={{height: "480px", overflow: "auto", padding: "10px"}}>
                <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                    <div style={{marginRight: "10px"}}>Select table:</div>
                    <Selector values={tables} updateSelected={(value) => setShowTable(value)} />
                    <div style={{fontSize: "18px", marginLeft: "300px"}}>{showTable}</div>
                    {/* <div style={{backgroundColor: "#676767", color: "#ffffff", fontSize: "15px", border: "1px solid #090909", height: "32px", fontSize: "16px", display:"flex", alignItems: "center", padding: "0px 5px"}}>Select Table</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}} onClick={() => setShowTable("abilityResults")}>Ability Results Table</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}} onClick={() => setShowTable("attackResults")}>Attack &amp; Parry Results</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}} onClick={() => setShowTable("dodgeResults")}>Dodge Results</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Weapons Fumble Table</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Spirit Combat Fumble Table</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Resistance Table</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Strike Rank Modifiers</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Language Equivalency</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Reputation Modifiers</div>
                    <div className={globalStyles.row} style={{cursor:"pointer", padding: "5px"}}>Occupation Income</div> */}
                </div>
                {showTable === "Ability Results" ? 
                    <AbilityResultsTable />
                : 
                showTable === "Attack Results" ?
                    <AttackResultsTable />
                : 
                showTable === "Dodge Results" ?
                    <DodgeResultsTable />
                : 
                showTable === "Combat Fumbles" ?
                    <CombatFumblesTable />
                : 
                showTable === "Spirit Combat Fumbles" ?
                    <SpiritCombatFumblesTable />
                : 
                    null
                }
            </div>
            <DialogButtonSingle closeClick={props.close} />
        </>
    );
}

export default TablesDialog;