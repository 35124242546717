import React, { useState, useEffect } from 'react'
import globalStyles from '../Styles/styles.module.css';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import CustomCults from './customCults';
import CustomSpiritSpells from './customSpiritSpells';
import CustomRuneSpells from './customRuneSpells';
import CustomSkills from './customSkills';
import DetailsPanel from '../Controls/detailsPanel';
import CustomCultContextPanel from '../Custom/customCultContextPanel';
import CustomSpiritSpellContextPanel from '../Custom/customSpiritSpellContextPanel';
import CustomRuneSpellContextPanel from '../Custom/customRuneSpellContextPanel';
import CustomSkillContextPanel from '../Custom/customSkillContextPanel';

const CustomContent = (props) => {

    const [customContent, setCustomContent] = useState()
    const [panelItem, setPanelItem] = useState({
        type: "ability", 
        item: {
            name: "Battle", value: 60, variant: "", cult: null
        }
    })
    const [panelVisibility, setPanelVisibility] = useState("hidePanel");
    const [panelPosition, setPanelPosition] = useState(0);
    const [panelHeight, setPanelHeight] = useState(800);
    const [panelMargin, setPanelMargin] = useState(-400)

    useEffect(() => {
        var width = window.innerWidth;
        if (width > 1928) {
            var result = (((window.innerWidth -1200)/2))
            setPanelPosition(result);
        }
        else setPanelPosition(364);
    },[window.innerWidth])

    useEffect(() => {
        if (props.content) {
            setCustomContent(props.content)
        }
    },[props.content])

    useEffect(() => {
        if (! props.open) {
            closePanel();
        }
    },[props.open])

    const deleteItem = (item, type) => {
        let updatedContent = {...customContent};
        let index = updatedContent[type].findIndex(obj => obj.id === item.id);
        if (index > -1) {
            updatedContent[type].splice(index, 1);
        }
        setCustomContent(updatedContent);
        saveContent(updatedContent);
    }

    function saveContent(updatedContent) {
        updatedContent.modified = new Date();
        props.firebase
        .customContent(updatedContent.id)
        .set(updatedContent)
        .then( function() {
        });
    }

    const displayPanelItem = (displayDetails) => {
        console.log('displayDetails', displayDetails)
        if (panelItem.item && panelItem.item.id && displayDetails.item && panelItem.item.id === displayDetails.item.id) {
            closePanel();
        }
        else {
            showPanel(displayDetails)
        }
    }

    const showPanel = (details) => {
        setPanelItem(details)
        setPanelVisibility("showPanel")
}

    const closePanel = () => {
        setPanelVisibility("hidePanel");
        setPanelItem({type: "", item: {name: ""}})
    }

	function updateContent(updatedItem, field) {
		var updatedContent = {...customContent}
		if (!updatedContent[field]) {
			updatedContent[field] = [];
		}
		let index = updatedContent[field].findIndex(obj => obj.id === updatedItem.id)
		if (index > -1) {
			updatedContent[field][index] = updatedItem;
		}
		else {
			updatedContent[field].push(updatedItem);
		}
		setCustomContent(updatedContent)
	}

    if (customContent) {
        return (
            <>
                        <div style={{ padding: "10px" }}>
                            <div style={{ margin: "10px 0px" }}>
                                <CustomCults displayItem={displayPanelItem} customContent={customContent} delete={(item) => deleteItem(item, "cults")} />
                            </div>
                            <div style={{ margin: "10px 0px" }}>
                                <CustomRuneSpells displayItem={displayPanelItem} customContent={customContent} delete={(item) => deleteItem(item, "runeSpells")} />
                            </div>
                            <div style={{ margin: "10px 0px" }}>
                                <CustomSpiritSpells displayItem={displayPanelItem} customContent={customContent} delete={(item) => deleteItem(item, "spiritSpells")} />
                            </div>
                            <div style={{ margin: "10px 0px" }}>
                                <CustomSkills displayItem={displayPanelItem} customContent={customContent} delete={(item) => deleteItem(item, "skills")} />
                            </div>
                        </div>
                <div className={globalStyles.contextBar} style={{ right: panelPosition, top: "50vh" }}>
                    <div id="contextPanel" className={globalStyles[panelVisibility]} style={{ width: "360px", borderRadius: "5px", border: "2px solid #fff", fontSize: "15px", height: panelHeight + "px", left: "auto", marginTop: panelMargin + "px", backgroundImage: "url(https://storage.googleapis.com/runequest/images/runequest-panel-background.png)" }}>
                        {panelItem && panelItem.type === "CustomCult" ?
                            <>
                                <DetailsPanel panelItem={panelItem} panelHeight={panelHeight} close={closePanel}>
                                    <CustomCultContextPanel firebase={props.firebase} customContent={customContent} update={updateContent} />
                                </DetailsPanel>
                            </>
                            :
                            panelItem && panelItem.type === "CustomRuneSpell" ?
                                <>
                                    <DetailsPanel panelItem={panelItem} panelHeight={panelHeight} close={closePanel}>
                                        <CustomRuneSpellContextPanel firebase={props.firebase} customContent={customContent} update={updateContent} />
                                    </DetailsPanel>
                                </>
                                :
                                panelItem && panelItem.type === "CustomSpiritSpell" ?
                                    <>
                                        <DetailsPanel panelItem={panelItem} panelHeight={panelHeight} close={closePanel}>
                                            <CustomSpiritSpellContextPanel firebase={props.firebase} customContent={customContent} update={updateContent} />
                                        </DetailsPanel>
                                    </>
                                    :
                                    panelItem && panelItem.type === "CustomSkill" ?
                                        <>
                                            <DetailsPanel panelItem={panelItem} panelHeight={panelHeight} close={closePanel}>
                                                <CustomSkillContextPanel firebase={props.firebase} customContent={customContent} update={updateContent} />
                                            </DetailsPanel>
                                        </>
                                        :
                                    null
                        }
                    </div>
                </div>
            </>
        )
    }
    else {
        return null;
    }   
}

export default withFirebase(CustomContent);