import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import OccupationCards from './occupationCards';
import OccupationDetails from './occupationDetails';
import StartingSkills from '../startingSkills';
import StartingWeapons from '../startingWeapons';
import * as OccupationsData from '../../../data/occupations.json';
import compareValues from '../../../utils/compareValues';
import FavouredPassion from '../favouredPassion';

const Occupations = (props) => {

    const [occupations, setOccupations] = useState([]);
    const [selected, setSelected] = useState();
    const [skillsComplete, setSkillsComplete] = useState(false);
    const [weaponsComplete, setWeaponsComplete] = useState(false);

    useEffect(() => {
        var allOccupations = {...OccupationsData}
        var data = [...allOccupations.data].filter(function(occupation) {
            return props.builder.homeland.occupations.findIndex(item => item === occupation.name) > -1
        }).sort(compareValues('name'));
        setOccupations(data);
    },[]);

    useEffect(() => {
        if (props.builder.occupation) {
            setSelected({...props.builder.occupation});
            if (!props.builder.occupation.weapons) {
                setWeaponsComplete(true);
            }
        }
    },[props.builder]);

    useEffect(() => {
        if (skillsComplete && weaponsComplete) {
            props.disableNext(false);
            var updatedBuilder = _.cloneDeep(props.builder);
            updatedBuilder.occupation = {...selected};
            var wealth = 0;
            for (var i =0; i < updatedBuilder.occupation.wealth.quantity; i++) {
                wealth += Math.ceil(Math.random()*updatedBuilder.occupation.wealth.dieType);
            };
            wealth += updatedBuilder.occupation.wealth.modifier;
            updatedBuilder.wealth += wealth
            props.update(updatedBuilder, false);
        }
        else {
            props.disableNext(true);
        }
    },[skillsComplete, weaponsComplete])

    function handleSelect(occupation) {
        if (props.builder.occupation && props.builder.occupation.name === occupation.name) {
            setSelected({...props.builder.occupation});
            props.disableNext(false);
        }
        else {
            occupation.skills.forEach(skill => {
                skill.options.forEach(option => {
                    if (option.variant == "::Homeland::") {
                        option.variant = props.builder.homeland.name;
                    }
                    else if (option.variant == "::Language::") {
                        option.variant = props.builder.homeland.language;
                    }
                    else if (option.variant == "::Customs::") {
                        option.variant = props.builder.homeland.customs;
                    }
                    else if (option.variable) {
                        var index = props.displayCharacter.builder.skills.findIndex(obj => obj.parentId === option.parentId);
                        if (index > -1) {
                            option.variant = props.displayCharacter.builder.skills[index].variant;
                        }
                    }
                });
            });
            setSelected(occupation);
            var updatedBuilder = {...props.builder};
            updatedBuilder.occupation = occupation;
            props.update(updatedBuilder, false);
            setSkillsComplete(false);
            setWeaponsComplete(false);
           }
        }

    function completeSkills(selectedSkills, complete) {
        var updatedSelected = {...selected};
        updatedSelected.skills = selectedSkills;
        setSelected(updatedSelected)
        setSkillsComplete(complete);
    }

    function completeWeapons(selectedWeapons, complete, selectedUnitWeapons) {
        var updatedSelected = {...selected};
        updatedSelected.weapons = selectedWeapons;
        updatedSelected.selectedUnitWeapons = selectedUnitWeapons || "";
        setSelected(updatedSelected)
        setWeaponsComplete(complete);
    }
    
    function selectPassion(passion) {
        var updatedBuilder = {...props.builder};
        updatedBuilder.occupation.favouredPassion = passion;
        props.update(updatedBuilder);
    }

    return (
        <div>
            <div style={{marginTop: "10px"}}>
                <div style={{marginLeft: "-8px"}}>	
                    <OccupationCards occupations={occupations} occupation={selected} size={{width: "90px", height: "90px", fontSize: "14px"}} handleSelect={handleSelect} />
                </div>
                {selected ? 
                <>
                    <div style={{display:"flex", color: "#121312", flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{margin: "10px 5px"}}>
                            <StartingSkills builder={props.builder} title="Occupational Skills" skills={selected.skills} displayCharacter={props.displayCharacter} complete={completeSkills} />
                        </div>
                        <div style={{margin: "10px 5px"}}>
                            <StartingWeapons builder={props.builder} occupation={selected} title="Occupational Weapons" complete={completeWeapons} />
                        </div>
                        <div style={{width: "32%", marginLeft: "5px"}}>
                            <FavouredPassion passions={selected.passions} selected={selected.favouredPassion} selectPassion={selectPassion} update={props.update}/>
                        </div>
                    </div>
                    <div style={{margin: "10px 5px"}}>
                        <OccupationDetails item={selected} />
                    </div>
                </>
                : 
                null
                }
            </div>
        </div>
    );
}

export default Occupations;