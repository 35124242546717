import React, { useState, useEffect } from 'react';
import { withPageState } from '../Context/PageState';
import { strikeRankEnchantments } from '../../services/enchantmentsService';
import { setSpellDefaults } from '../../services/spellsService';
import EnemySpell from './spells/enemySpell';
import CharacterSpell from './spells/characterSpell';
import WeaponSpell from './spells/weaponSpell';
import SpiritSpellTile from './spiritSpellTile';
import * as SAVE from '../../constants/save';
import { withContext } from '../Context/context';
import calculateSR from '../../services/strikeRankService';
import { getSpiritSpell } from '../../services/dataService';

const SpiritSpell = (props) => {

    const [item, setItem] = useState(props.item);

    useEffect(() => {
        var POWStat = props.spellcaster.characteristics.POW;
        var value = (POWStat.baseValue + (POWStat.statBonus || 0)) * 5;
        var entity = props.spellcaster.strikeRanks ? props.spellcaster : props.providerData.character; // spellcaster can be a familiar with its own DEX SR
        var sr = calculateSR("DEX", entity) + props.item.points;
        let updatedItem = getSpiritSpell(props.item.parentId, props.providerData.campaign)
        setItem({...updatedItem, parentId: props.item.parentId, id: props.item.id, value: value, strikeRank: sr, default: props.item.default, points: props.item.points})
    },[props.spellcaster, props.item]);

    if (item.target && item.target.type === "enemy") {
        return (
            // <EnemySpell diceRoller={props.diceRoller} castingDetails={props.castingDetails} item={item} processResult={props.processResult} spellcaster={props.spellcaster} element={props.element} powGain={props.powGain} >
            //     <SpiritSpellTile matrix={props.matrix} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} />
            // </EnemySpell>
            <SpiritSpellTile diceRoller={props.diceRoller} matrix={props.matrix} availableMagicPoints={props.availableMagicPoints} processResult={props.processResult} item={item} displayItem={props.displayItem} element={props.element} spellcaster={props.spellcaster} />
        )
    }
    else if (item.target && item.target.type === "hitPoints" || item.target && item.target.type === "spiritCombat" || item.target && item.target.type === "move" || item.target && item.target.type === "characteristic"|| item.target && item.target.type === "Character" || item.target && item.target.type === "armour" || item.target && item.target.type === "spiritArmour" || item.target && item.target.type === "defense") {
        return (
            <CharacterSpell diceRoller={props.diceRoller} castingDetails={props.castingDetails} item={item} processResult={props.processResult} element={props.element} >
                <SpiritSpellTile matrix={props.matrix} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} spellcaster={props.spellcaster} />
            </CharacterSpell>
        )
    }
    else if (item.target && item.target.type === "Weapon") {
        return (
            <WeaponSpell diceRoller={props.diceRoller} castingDetails={props.castingDetails} item={item} processResult={props.processResult} element={props.element} >
                <SpiritSpellTile matrix={props.matrix} availableMagicPoints={props.availableMagicPoints} displayItem={props.displayItem} element={props.element} spellcaster={props.spellcaster} />
            </WeaponSpell>
        )
    }
    else {
        return (
            <SpiritSpellTile diceRoller={props.diceRoller} matrix={props.matrix} availableMagicPoints={props.availableMagicPoints} processResult={props.processResult} item={item} displayItem={props.displayItem} element={props.element} spellcaster={props.spellcaster} />
        )
    }
}

export default withContext(withPageState(SpiritSpell));