import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const Incrementor = (props) => {

    var width=props.totalValue ? "" : "20px";
    var iconSize = props.iconSize ? props.iconSize : "20px";

    return (
        <div style={{...props.style, display:"flex", alignItems: "center", justifyContent:"space-between"}}>
            <div style={{display:"flex", justifyContent:"center"}}>{props.label}</div>
            <div style={{display:"flex", alignItems:"center"}}>
                <div style={{margin:"0px 5px", width: width, textAlign:"center"}}>{props.currentValue}{props.totalValue ? "/" + props.totalValue: null}</div>
                <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                    <RemoveCircleOutlineIcon style={{height:iconSize, width:iconSize}} onClick={(event) => props.increment(-1, event)}/>
                    <AddCircleOutlineIcon style={{height:iconSize, width:iconSize, marginLeft:"4px"}} onClick={(event) => props.increment(1, event)}/>
                </div>
            </div>
        </div>
    )
}

export default Incrementor