import React, {useState, useEffect} from "react";
import { withPageState } from "../../Context/PageState";
import { withContext } from "../../Context/context";
import AddItemButton from "../buttons/addItemButton";
import MatrixDialog from "../../MagicItems/matrixDialog";
import * as SAVE from '../../../constants/save';
import DialogBox from "../../Dialogs/dialogBox";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const SpiritSpellMatrixPanel = (props) => {

    const [matrices, setMatrices] = useState([])
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(-1);

    useEffect(() => {
        setMatrices(props.providerData.character.matrices.filter(function(obj) {
            return obj.type === "Spirit spell matrix";
        }));
    },[props.providerData.character.matrices])

    function addMatrix(newMatrix) {
        var updatedMatrices = [...props.providerData.character.matrices];        
        var index = updatedMatrices.findIndex(item => item.id === newMatrix.id);
        if (index < 0) {
            newMatrix.active = true;
            updatedMatrices.push(newMatrix);
        }
        else {
            updatedMatrices[index] = newMatrix;
        }      
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO);
    }

    function openDialog(index) {
        setKey(index);
        setOpen(true);
    }

    function updateMatrix(matrix) {
        var updatedMatrices = [...props.providerData.character.matrices];        
        var index = updatedMatrices.findIndex(item => item.id === matrix.id);
        updatedMatrices[index] = matrix;
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO);
    }

    function deleteMatrix(matrix) {
        var updatedMatrices = [...props.providerData.character.matrices];        
        var index = updatedMatrices.findIndex(item => item.id === matrix.id);
        updatedMatrices.splice(index, 1);
        var updates = [{fieldName: "matrices", value: updatedMatrices}];
        props.update(updates, SAVE.NO);
        setOpen(false);
    }

        return (
            <div style={{border: "2px solid #676767", margin: "5px", width: "260px"}}>
                <div style={{backgroundColor: "#454645", width: "100%", color: "#fff", height: "30px", fontSize: "15px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between "}}>
                    <div style={{paddingLeft: "5px", fontWeight:"bold"}}>Spirit Magic Matrices</div>
                    <div style={{width: "30px"}}>
                        {props.pageState.editMode ?
                            <AddItemButton title="Add Spirit Spell Matrix" addTitle="Add matrix" width="700px" addItem={addMatrix}>
                                <MatrixDialog entity={props.providerData.character} type="Spirit spell matrix" />
                            </AddItemButton>
                        : 
                        null}
                    </div>
                </div>
                {matrices.length > 0 ? 
                matrices.map((matrix, index) => {
                    return (
                        <div key={index}>
                            <div style={{backgroundColor: "#2F588A", padding: "0px 5px", color: "#fff", position: "relative"}}>
                                    <div style={{display: "flex", alignItems: "center", height: "24px"}}>
                                        {props.pageState.editMode ? 
                                            <div style={{position: "absolute", right: "6px", top: "0px", cursor: "pointer"}} title="Edit matrix" onClick={() => openDialog(index)}>
                                                <ModeEditIcon style={{height:"22px", width:"22px", color: "#fff"}} />
                                            </div>
                                        : 
                                            null}
                                        <div>{matrix.location}</div>
                                    </div>
                                <DialogBox title="Edit Spirit Spell Matrix" isOpen={open} width="700px" >
                                    <MatrixDialog type="Spirit spell matrix" close={() => setOpen(false)} update={updateMatrix} matrix={matrices[key]} delete={deleteMatrix} />
                                </DialogBox>
                            </div>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px"}}>
                                <div>{matrix.spell.name} {matrix.spell.variant ? matrix.spell.variant : ""}</div>
                                <div>{matrix.spell.points + (!matrix.spell.variable && (matrix.spell.points === 1 ? " pt" : " pts"))}</div>
                            </div>
                        </div>

                    )
                })
                : 
                <div style={{padding: "10px"}}>None</div>}
            </div>
        )
}

export default withPageState(withContext(SpiritSpellMatrixPanel));