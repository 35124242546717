import React, { useEffect, useState } from 'react';
import { withActiveEncounters } from '../Context/ActiveEncounters';
import {withContext} from '../Context/context';
import { Button } from '@mui/material';
import ManRune from '../../images/man-rune-270x360.png'

const SelectEnemyDialog = (props) => {
	const [selectedNPC, setSelectedNPC] = useState(null);
	const [message, setMessage] = useState();
	const [remember, setRemember] = useState(true);
    const [open, setOpen] = useState(false);
    const [POW, setPOW] = useState(0);

    const [targets, setTargets] = useState([]);
    
    useEffect(() => {
        var POWStat = props.character.characteristics.POW;
        setPOW(POWStat.baseValue + (POWStat.statBonus || 0));
    },[props.character.characteristics]);

    useEffect(() => {
        var npcs = []
		var enemies = props.providerData ? props.providerData.activeEnemies : props.castingDetails.activeEnemies;
 		setTargets(enemies);
    }, [props.character.campaignId]);

	useEffect(() => {
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + ". Select the target of the spell.")
	},[]);

	useEffect(() => {
		if (props.spell.default) {
			if (props.spell.default.characterId === props.character.id) {
				selectNPC(props.character)
			}
			else {
				targets.forEach(character => {
					if (character.id === props.spell.default.characterId) {
						selectNPC(character);
					}
				});
			}
		}
	},[props.character, targets]);
	  
	function handleOkClick(){
        props.processSpell(selectedNPC);
	}

	function selectNPC(character){
		setSelectedNPC(character);
	}

	function selectOther(){
		var spellName = props.spell.variable ? props.spell.name + " " + props.spell.points : props.spell.name;
		setMessage("You are casting " + spellName + " on an enemy NPC. Click Ok to confirm.");
		setSelectedNPC(null);
	}

	function handleCancelClick(){
		props.close();
	}

    // function processSpell(remember, character) {
    //     var updatedSpellDetails = {...spellDetails};
    //     if (remember && character) {
    //         updatedSpellDetails.remember = remember;
    //         updatedSpellDetails.targetCharacter = character;
    //     }
    //     setOpen(false);
    //     var updatedDieRoll = {...dieRoll};
    //     console.log('updatedDieRoll', updatedDieRoll)
    //     if (character.id !== props.character.id) {
    //         updatedDieRoll.messageText.target = character.name.first;
    //     }
    //     else {
    //         var gender = character.personalInfo.gender === "Male" ? "him" : character.personalInfo.gender === "Female" ? "her" : "them";
    //         gender += "self";
    //         updatedDieRoll.messageText.target = gender;
    //     }
    //     props.processResult(updatedDieRoll, updatedSpellDetails);
    // }

	if (props.character && props.spell && targets) {
		var style = {border: "5px solid #454545", margin:"5px"};
		return (
			<div style={{fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif", fontSize:"14px", minHeight: "590px", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
	            <div style={{position:"relative", padding: "10px", height: "100%"}}>
					<div style={{marginTop:"20px", height: "40px"}}>{message}</div>
					<div style={{display:"flex"}}>
						<div style={{display:"flex", flexWrap: "wrap"}}>
							{targets.map((character, index) => {
								var border = selectedNPC && (character.id === selectedNPC.id) ? "2px solid #2F588A" : "";
								var backgroundColor = selectedNPC && (character.id === selectedNPC.id) ? "#ffffff" : "";
								var opacity = !selectedNPC || (selectedNPC && (character.id === selectedNPC.id)) ? 1 : 0.5;
								return (
									<div key={index} style={{margin: "10px", display:"flex", alignItems: "center", border: border, borderRadius: "5px", backgroundColor: backgroundColor, opacity: opacity}} onClick={() => selectNPC(character)}>
										<div style={{height: "100px", display:"flex", justifyContent:"center", alignItems: "center", marginRight: "10px", borderRadius: "5px" }}>
											<img style={{height: "100px"}} src={character.profileImage} />
										</div>
										<div style={{width: "120px"}}>
											<div style={{fontSize: "15px", fontWeight: "bold", marginBottom: "10px"}}>{character.name ? (character.name.first + " " + character.name?.last): ""}</div>
											<div>{character.description}</div>
										</div>
									</div>
								)
							})}
							<div key="other" style={{margin: "10px", height:"110px", textAlign:"center", display:"flex", flexDirection:"column", alignItems:"center"}} onClick={selectOther}>
								<div style={{width:"75px", minHeight: "100px", borderRadius:"5px", border: selectedNPC === null ? "5px solid #2F588A" : "3px solid #454545", backgroundColor: selectedNPC === null ? "#fff" : "#c0c0c0", display:"flex", alignItems:"center"}} >
									<img src={ManRune} style={{width: "75px" }}/>
								</div>
								<div style={{textAlign:"center", marginTop: "5px"}}>Other</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{display:"flex", flexDirection: "row-reverse", padding: "10px", alignItems:"flex-end"}}>
					<Button type="submit" onClick={handleOkClick} style={{width: "100px", color:"#fff"}} color="primary" variant="contained">Ok</Button>
					<Button type="submit" onClick={handleCancelClick} style={{width: "100px", marginRight: "20px"}} variant="contained">Cancel</Button>
				</div>
			</div>
		);
	}
	else {
		return null;
	}
}

export default withActiveEncounters(withContext(SelectEnemyDialog));