import { getStatValue } from "./attributeService";

export function calculateHitPoints(characteristics, existingHitPoints) {
    if (!characteristics  ) {return null};
    var CON = getStatValue(characteristics.CON);
    var POW = getStatValue(characteristics.POW);
    var SIZ = getStatValue(characteristics.SIZ);
    var totalHitPoints = CON;
    totalHitPoints += sizModifer(SIZ);
    totalHitPoints += powModifer(POW);

    var hitPoints = {...existingHitPoints};
    var diff = hitPoints.current - hitPoints.total;
    hitPoints.total = totalHitPoints;
    hitPoints.current = totalHitPoints + diff;

    var baseValue = getBaseLocationHP(totalHitPoints);
    hitPoints.locations.forEach(location => {
        location.hitPoints = baseValue + location.modifier;
    });
    return hitPoints;
}

    function sizModifer(SIZ) {
        var value = 4;
        var mod = -2;
        while (value < SIZ) {
            value += 4;
            mod ++;
        }
        return mod;
    }

    function powModifer(POW) {
        if (POW <= 4) return -1;

        var value = 16;
        var mod = 0;
        while (value < POW) {
            value += 4;
            mod ++;
        }
        return mod;
    }

    export function getBaseLocationHP(hitPoints) {
        if (!hitPoints) return 0;
        var value = 6;
        var baseHPs = 2; 

        while(value < hitPoints) {
            value += 3;
            baseHPs ++;
        }
        return baseHPs;
    }

    export function getHealingRate(characteristics) {
        if (!characteristics || characteristics.length == 0) return null;
        if (getStatValue(characteristics.CON) < 7) return 1;
        else if (getStatValue(characteristics.CON) < 13) return 2;
        else return 3;
    }

    export function applyArmour(character) {
        var updatedCharacter = {...character};
        updatedCharacter.hitPoints.locations.forEach(location => {
            var equippedArmour = {name: "", points: 0}
            updatedCharacter.armour.forEach(armourPiece => {
                if (armourPiece.equipped && armourPiece.covers.indexOf(location.name) > -1) {
                    equippedArmour.id = armourPiece.id;
                    equippedArmour.material = armourPiece.material;
                    equippedArmour.type = armourPiece.type;
                    equippedArmour.points = armourPiece.points;
                }
                location.armour = {...equippedArmour};
            });
        });
        return updatedCharacter;
    }

    export function applyDamage(hitPoints, item, change, ignoreArmour, fallingDamage, armourEnchantment) {
        var updatedHitPoints = {...hitPoints};
        updatedHitPoints.locations.forEach(location => {
            if (item.name === location.name) {
                if (!location.wounds) location.wounds = [];
                if (change < 0){
                    var damage = calculateDamage(location, change, ignoreArmour, fallingDamage, armourEnchantment)
                    location.wounds.push(damage * -1);
                    updatedHitPoints.current += damage;
                }
                else {
                    var updatedChange = change;
                    var currentDamage = 0;
                    if (location.wounds) {
                        currentDamage = location.wounds.reduce(function(a,b) {return a + b;})
                    }
                    if (updatedChange >= currentDamage) {
                        location.wounds = [];
                        updatedHitPoints.current += currentDamage;
                    }
                    else {
                        for (var i = location.wounds.length -1; i >= 0; i--) {
                            var wound = location.wounds[i];
                            if (updatedChange >= wound) {
                                location.wounds.splice(i, 1);
                                updatedChange -= wound;
                            }
                            else {
                                wound -= updatedChange;
                                updatedChange = 0;
                                location.wounds[i] = wound

                            }
                        }
                        updatedHitPoints.current += change;
                    }
                    if (updatedHitPoints.current > updatedHitPoints.total) {updatedHitPoints.current = updatedHitPoints.total};
                }
            }
        })
        return updatedHitPoints;
    }

    function calculateDamage(location, change, ignoreArmour, fallingDamage, armourEnchantment) {
        var armour = totalAPs(location, armourEnchantment);
        var maxdamage = location.hitPoints * -3;
        if (location.limb) {maxdamage = location.hitPoints * -2;}
        if (fallingDamage) maxdamage = change;
        if (!ignoreArmour) {
            change += armour;
        }
        if (change > 0) change = 0;
        if (change < maxdamage) change = maxdamage;
        return change;
    }

    const totalAPs = (location, armourEnchantment) => {
        var armour = location.armour?.points || 0;
        armour += armourEnchantment;
        armour += location.armourModifier || 0;
        return armour;
    }