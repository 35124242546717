import React, {useState, useEffect} from "react";
import globalStyles from '../../Styles/styles.module.css'
import * as SAVE from '../../../constants/save';
import DiceButton from '../../Buttons/diceButton';
import { getStatValue } from '../../../services/attributeService';

const StatTile = (props) => {

    const [value, setValue] = useState(0);
    const [target, setTarget] = useState(0);

    useEffect(() => {
        var value = getStatValue(props.item);
        setValue(value);
        setTarget(value * 5);
    },[props.item])

    function displayItem() {
        props.displayItem({type: "Stat", item: {...props.item, value: value}})
    }

    return (
        <div className={globalStyles.expandableTile}  style={{display:"flex", border: "2px solid #2F588A", margin: "2px 0px", borderRadius: "3px", alignItems: "center", justifyContent: "space-between", width: "98px", cursor: "zoom-in", fontSize: "14px", marginRight: "10px"}} onClick={displayItem} >
            <div style={{fontWeight: "bold", paddingLeft: "5px"}}>{props.item.name + ": " + value}</div>
            <div >
                <DiceButton diceRoller={props.diceRoller} type="Check" style={{height: "30px", padding: "2px", width: "30px", textAlign: "right", backgroundColor: "#2F588A", color: "#fff", cursor: "pointer", padding: "2px", display: "flex", alignItems: "center", justifyContent: "center"}} item={props.item} processResult={props.processResult} target={target} entity={props.entity} >
                    <ButtonValue />
                </DiceButton>
            </div>
        </div>
    )

    function ButtonValue() {
        return (
            <>{(target || 0) + "%"}</>
        )
    }
}

export default StatTile;