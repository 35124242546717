import React, { useEffect, useState } from 'react';
import { withPageState } from '../Context/PageState';
import globalStyles from '../Styles/styles.module.css';
import styles from '../Characters/character.module.css';
import MagicIcon from '../../icons/magic-icon.svg';
import EditBox from '../Controls/editBoxSingle';
import DiceButton from '../Buttons/diceButton';
import * as SAVE from '../../constants/save';

const AbilityRow = (props) => {

    const [value, setValue] = useState(props.item.value);
    const [enchanted, setEnchanted] = useState(false);

    useEffect(() => {
        setValue(props.item.value);
    },[props.item.value])

    useEffect(() => {
        var result = false;
        props.entity.enchantments.forEach(enchantment => {
            enchantment.effects.forEach(effect => {
                if (effect.item && effect.item.id === props.item.id) {
                    result = true;
                }
            })
        });
        setEnchanted(result)
    },[props.entity, props.item]);
    
    function handleValueChange(newValue) {
        setValue(newValue);
        var updatedItem = {...props.item};
        updatedItem.value = newValue;
        props.update(updatedItem, SAVE.NO);
    }


    function displayItem() {
        props.displayItem({type: props.type, item: props.item})
    }

    var name = props.item.variant ? props.item.name + " (" + props.item.variant + ")" : props.item.name;
    if (props.pageState.editMode) {
        return(
                <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", width: "200px", padding: "2px", cursor: "zoom-in"}} >
                    <div style={{width: "200px"}}>
                        <div onClick={displayItem}>{name}</div>
                    </div>
                    <EditBox size="sm" number name={name} value={value} width="40px" onChange={handleValueChange} />
                </div>
        )
    }
    else {
        return (
            <div className={globalStyles.expandableTile} style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "200px", padding: "2px", cursor: "zoom-in"}} onClick={displayItem} >
                <div style={{width: "160px", display: "flex", alignItems: "center"}}>
                    <div>{name}</div>
                    {enchanted ? 
                        <div className={styles.roundButton} style={{width: "20px", height: "20px", marginLeft: "10px"}} ><img style={{width: "20px", height: "20px"}} src={MagicIcon} /></div>
                    : null}
                </div>
                <DiceButton diceRoller={props.diceRoller} style={{border: "1px solid #2F588A", borderRadius: "3px", padding: "2px", minWidth: "40px", textAlign: "right", cursor: "pointer"}} item={props.item} processResult={props.processResult} disabled={props.item && props.item.value == 0} entity={props.entity} type={props.type}>
                    <ButtonValue />
                </DiceButton>
            </div>
        )
    }

    function ButtonValue(props) {
        return (
            <div >{(props.target || 0) + "%"}</div>
        )
    }
}
    export default withPageState(AbilityRow)