import React, { useState} from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import globalStyles from '../Styles/styles.module.css'
import { Person } from "@mui/icons-material";
import DialogBox from '../Dialogs/dialogBox';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DialogButtonSingle from '../Dialogs/dialogButtonSingle';

const AddUserButton = (props) => {
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setOpen(true);
    };

    return (
        <div onClick={handleClick} className={globalStyles.floatingButton} title="Add player">
            <PersonAddIcon style={{height:"30px", width:"30px", color: "#2f588a"}} />
            <DialogBox width="500px" isOpen={open} title="Invite player">
                <>
                    <div style={{border: "1px solid #8a612f", backgroundColor: "white", color:"#001e05", borderRadius: "5px", padding:"20px"}}>
                        <h3>To invite another player to this campaign, send them the following link</h3>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>https://runequesting.com/invite/{props.campaignId}</div>
                            <div title="Copy to clipboard" style={{marginLeft: "20px", cursor: "pointer"}} onClick={() => navigator.clipboard.writeText("https://runequesting.com/invite/" + props.campaignId)}><ContentCopyIcon /></div>
                        </div>
                    </div>
                    <DialogButtonSingle closeClick={() => setOpen(false)} />
                </>
            </DialogBox>
        </div>
    )
}

export default AddUserButton;