import React, {useState, useEffect} from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch } from '@mui/material';
import EditBoxMulti from '../Controls/editBoxMulti';
import { withFirebase } from '../Firebase';

const SendFeedbackDialog = (props) => {

    const [note, setNote] = useState("");
    const types = ["Error", "Suggestion", "General feedback"]
    const [selectedType, setSelectedType] = useState("Normal");
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (props.index > -1) {
            setNote(props.currentNote.content);
            setPrivateNote(props.currentNote.private)
        }
    },[props.currentNote])

    function addNote() {
        if (done) {
            props.close()
        }
        else {
            props.firebase
            .feedback()
            .add({
                type: selectedType,
                feedback: note || "",
                errorDate: new Date(),
                user: props.authUser.uid,
            })
            setDone(true);

        }
    }

    function handleChange(event, ) {
        var type = event.target.value;
        setSelectedType(type)
    }

    if (done) {
        return (
            <>
                <div style={{overflowX: "hidden", fontSize: "20px", padding: "10px", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                    <div>
                        <div>Thank you for providing feedback, it's very much appreciated.</div>
                        <div style={{marginTop: "15px"}}>If you want to learn more about the application, chat about any issues you have or future suggestions, please visit our <u><a target='_blank' style={{color: "#2f588a"}} href="https://discord.gg/RM6h6Ct2nH">Discord server.</a></u></div>
                    </div>
                    <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", marginTop:"30px", padding: "20px", justifyContent:"space-between"}}>
                        <div style={{display:"flex", flexDirection:"row-reverse"}}>
                            <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Close</Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <div style={{overflowX: "hidden", padding: "10px", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div>
                    <div>Thank you for providing feedback on the RuneQuesting application, we hope you are finding it useful. You can provide feedback by entering details in the form below, or alternatively by visiting our <u><a target='_blank' style={{color: "#2f588a"}} href="https://discord.gg/RM6h6Ct2nH">Discord server.</a></u></div>
                    <div style={{marginTop: "15px"}}>Unfortunately we are unable to respond directly to issues raised here - if you would like to discuss them in more detail, please raise them on our <u><a target='_blank' style={{color: "#2f588a"}} href="https://discord.gg/RM6h6Ct2nH">Discord server instead.</a></u></div>
                    <div style={{marginTop: "15px"}}>If you have hit an issue, please provide as much information as possible regarding the action you were taking when it occurred, such as the character name, the spell you were casting, weapon you were using etc. and we will endeavour to resolve it as soon as possible and get you playing once again.</div>
                    <FormControl variant="outlined">
                        <RadioGroup aria-label="damage-type-label" row color="primary" value={selectedType} onChange={handleChange}>
                            {types.map(type => {
                                return (
                                    <FormControlLabel key={type} style={{fontSize: "14px"}} checked={selectedType == type} value={type} control={<Radio color='primary' />} label={<span style={{fontSize: "14px"}}>{type}</span>} />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>

                    <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between", padding: "10px"}}>
                        <EditBoxMulti rows={8} multiline placeholder="Feedback" value={note} onChange={(value) => setNote(value)} />
                    </div>
                </div>
                <div style={{display:"flex", flexDirection:"row-reverse", backgroundColor:"#cdcdcd", borderTop: "2px solid #2f588a", marginTop:"20px", padding: "20px", justifyContent:"space-between"}}>
                    <div style={{display:"flex", flexDirection:"row-reverse"}}>
                        <Button onClick={addNote} style={{width: "100px"}} color="primary" variant="contained">Send</Button>
                        <Button onClick={props.close} color="primary" variant="outlined" style={{width: "100px", backgroundColor:"#bbbbbb", marginRight: "20px"}} >Cancel</Button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withFirebase(SendFeedbackDialog);