import React, {useState, useEffect} from "react";
import { withContext } from "../../Context/context";
import { withPageState } from "../../Context/PageState";
import { calculateSpellStorage } from '../../../services/magicPointsService';
import globalStyles from '../../Styles/styles.module.css';
import EditBox from "../../Controls/editBoxSingle";
import AddItemButton from "../buttons/addItemButton";
import ManageRuneSpellDialog from "../../Magic/manageRuneSpellDialog";
import compareValues from '../../../utils/compareValues';
import * as SAVE from '../../../constants/save'
import CultHeaderBar from "./cultHeaderBar";

const RuneMagicPanel = (props) => {

    const [storage, setStorage] = useState(-1);

    useEffect(() => {
        var storage = calculateSpellStorage(props.providerData.character);
        setStorage(storage);
    },[props.providerData.character]);
    
    function setSpells(newSpells) {
        var updatedSpells = [...newSpells]
        var updatedCults = [...props.providerData.character.cults];
        updatedCults[0].runeSpells = updatedSpells.sort(compareValues('name', 'asc'));
        props.update([{fieldName: "cults", value: updatedCults}], SAVE.NO)
    }

    return (
        <div style={{border: "2px solid #676767", margin: "5px", width: "260px"}}>
            {props.providerData.character.cults.map((cult, index) => {
                return (
                    <div key={index}>
                        <div style={{backgroundColor: "#454645", padding: "0px 5px", color: "#fff", height: "30px", fontSize: "14px", fontWeight:"bold", display:"flex", alignItems:"center", justifyContent: "space-between"}}>Rune Magic </div>
                        <CultHeaderBar handleValueChange={props.handleValueChange} buttonStyle={props.buttonStyle} storage={storage} setSpells={setSpells} entity={props.providerData.character} cult={cult} />
                        { 
                            props.providerData.character.cults && props.providerData.character.cults[index].runeSpells.map((item, index) => {
                                return(
                                    <div className={globalStyles.spellRow} key={index} style={{display:"flex", alignItems:"center", justifyContent:"space-between", overflow: "hidden", padding: "1px 5px", fontFamily:"Trebuchet MS, Roboto, Helvetica, Arial, sans-serif"}}>
                                        <div>{item.name}{item.variant ? " " + item.variant : ""}</div>
                                        <div style={{marginRight: "12px"}}>{item.points} {item.points == 1 ? "pt" : "pts"}</div>
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default withPageState(withContext(RuneMagicPanel))